import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { ApiProvider } from './ApiContext';
import {requestNotificationPermissions} from "./helpers/PermissionHelper";
import {disableLoggers, isDev} from "./helpers/common";

requestNotificationPermissions();

if(!isDev()){
    disableLoggers();
}

const Root = () => {
  return (
    <div className="App">
      <ApiProvider>
        <App />
      </ApiProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// ReactDOM.createRoot(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
