import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { getUserCompany } from '../../../api/AuthIndex';
import ApiContext from '../../../ApiContext';
import { Deutsch, English, Español, Français, Português, 简体中文 } from '../../../Constant';
// import defaultlogo from '../../assets/images/defaultlogo.png'
import defaultlogo from '../../../assets/images/defaultlogo.png'
import { Modal } from 'react-bootstrap';
import { H4, Progressbar } from '../../../AbstractElements';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Companies = () => {
    const { isUpdated, setUpdateStatus, allCompanies, setAllCompanies, setCompanyDetail, companyDetail, selectCompany, setSelectCompany } = useContext(ApiContext);

    let getCompanyInfo = companyDetail && companyDetail
    const userData = JSON.parse(localStorage.getItem('auth0_profile'));
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const [langdropdown, setLangdropdown] = useState(false);
    const [switchCompany, setSwitchCompany] = useState(false);
    const [progress, setProgress] = useState(1);
    const [selected, setSelected] = useState('en');
    const { i18n } = useTranslation();

    const location = useLocation()
    const pathName = location.pathname;
    console.log("🚀 ~ Companies ~ pathName:", pathName)

    const changeLanguage = (item) => {
        // i18n.changeLanguage(lng);
        setSelected(item);
        setSelectCompany(item)
        setLangdropdown(!langdropdown);
    };
    const LanguageSelection = (language) => {
        if (language) {
            setLangdropdown(!language);
        } else {
            setLangdropdown(!language);
        }
    };

    useEffect(() => {
        if (pathName === '/app/companies') {
            console.log("Companies /app/companies");
            getUserCompanyInfo()
        }
    }, [isUpdated]);

    const getUserCompanyInfo = async () => {
        let res = await getUserCompany(token, userData?.id);
        localStorage.setItem("companies", JSON.stringify(res.Company));
        setAllCompanies(res.Company)
    };

    const companies = allCompanies || []

    const handleChangeCompany = (item) => {
        setSwitchCompany(true)
        setProgress(1); // Reset progress to 1 when switching company

        let interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval); // Clear interval when progress reaches 100
                    return 100;
                }
                return prevProgress + 100 / 7; // Increment progress by 100 / 7 (~14.29% per second)
            });
        }, 1000);
        setTimeout(() => {
            setCompanyDetail(localStorage.removeItem("companyData"))
            changeLanguage(item)
            setUpdateStatus(!isUpdated);
            setCompanyDetail(localStorage.setItem("companyData", JSON.stringify(item)))
            setSwitchCompany(false)
            navigate('/app/dashboard');
        }, 7500);
    }

    return (
        <>
            <li className='language-nav'>
                <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
                    <div className='current_lang' style={{ border: '1px solid rgba(128, 128, 128, 0.5)', padding: '2px 5px', borderRadius: '5px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', marginTop: '5px' }} className='lang' onClick={() => LanguageSelection(langdropdown)}>
                                <img style={{ width: '30px', height: '30px', borderRadius: '5px' }} src={selectCompany?.upload_logo ? `${process.env.REACT_APP_IMAGE_URL}${selectCompany?.upload_logo}` : getCompanyInfo ? `${process.env.REACT_APP_IMAGE_URL}${getCompanyInfo?.upload_logo}` : defaultlogo} />
                                <p style={{ lineHeight: 1, marginTop: '8px', fontSize: '14px', fontWeight: '500', marginLeft: '-5px' }} className='lang-txt'>{selectCompany?.company_name ? selectCompany?.company_name : getCompanyInfo ? getCompanyInfo?.company_name : 'Select Company'}</p>
                            </div>
                            {langdropdown ? <i className='fa fa-angle-up' style={{ fontSize: '25px', marginLeft: '10px', marginTop: '-5px' }} onClick={() => LanguageSelection(langdropdown)} /> : <i className='fa fa-angle-down' style={{ fontSize: '25px', marginLeft: '10px', marginTop: '-5px' }} onClick={() => LanguageSelection(langdropdown)} />}
                        </div>
                    </div>
                    <div style={{ height: '300px', overflow: 'scroll' }} className={`more_lang ${langdropdown ? 'active' : ''}`}>
                        {companies?.map((item, index) => {
                            const currentBalance = item?.latest_package_payment?.current_balance || 0;
                            const lastAmount = item?.latest_package_payment?.last_amount || 1;

                            const progressValue = (currentBalance / lastAmount) * 100;

                            const progressColor = progressValue > 50 ? 'primary' : 'secondary';

                            return (
                                <div key={index} style={{ width: '330px', borderRadius: '5px' }} className='lang'
                                    onClick={() => handleChangeCompany(item)}>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <img style={{ width: '30px', height: '30px' }} src={`${process.env.REACT_APP_IMAGE_URL}${item?.upload_logo}`} />
                                            <p className='lang-txt' style={{ lineHeight: 1, marginTop: '10px', fontSize: '14px', fontWeight: '500' }}>
                                                {item?.company_name}
                                            </p>
                                        </div>
                                        <Progressbar attrProgress={{ color: progressColor, value: progressValue }} />
                                        <p style={{ fontSize: '14px', fontWeight: '500' }}>${Number(item?.latest_package_payment?.current_balance).toFixed(2)}/${Number(item?.latest_package_payment?.last_amount).toFixed(2)}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </li>
            <Modal show={switchCompany} centered onHide={() => setSwitchCompany(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Switching Company</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-20px' }}>
                        <div>
                            <Progressbar attrProgress={{ color: 'primary', value: progress, animated: true }} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Companies;
