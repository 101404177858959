import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Button, Col } from 'reactstrap'
import noCompany from '../../assets/images/noCompany.png'
import paymentSuccess from '../../assets/images/paymentSuccess.png'
import { FaRegCheckCircle } from "react-icons/fa";
import { getCompanyDetails } from '../../api/Company';
import { Color } from '../../helpers/colors';

const ThankYou = () => {
    const navigate = useNavigate();
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const getTokken = localStorage.getItem('token')

    useEffect(() => {
        getCompanyArray()
    }, [])


    const getCompanyArray = async () => {
        try {
            let res = await getCompanyDetails(getCompanyInfo?.id, getTokken)
            console.log("🚀 ~ getCompanyArray ~ res:", res)
            localStorage.setItem("companyData", JSON.stringify(res?.company?.company));
            localStorage.setItem("companyStatus", JSON.stringify(res?.company?.companyStatus))
            if (res?.company?.company) {
                setTimeout(() => {
                    navigate(`${process.env.PUBLIC_URL}/app/dashboard`);
                }, 5000);
            }
        } catch (error) {
            console.log("🚀 ~ getCompanyArray ~ error:", error)
        }
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Col sm='10'>
                <Col sm='12' style={{ marginTop: '10%' }}>
                    <div style={{ backgroundColor: Color.WHITE, padding: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img style={{ width: '30%' }} src={paymentSuccess} />
                        </div>
                        {/* <div style={{ padding: '20px 10px 10px 10px', display: 'flex', alignItems: 'center' }}> */}
                        {/* <div>
                                    <FaRegCheckCircle size={45} color='white' />
                                </div> */}

                        <p style={{ color: Color.BLACK, fontSize: '20px', fontWeight: '500', marginBottom: '10px', textAlign: 'center' }}>Payment Was Successful!</p>
                        <p style={{ color: Color.PRIMARY, marginTop: '5px', textAlign: 'center' }}>Your Account is active now and you can use your assistants</p>

                        {/* </div> */}
                    </div>
                </Col>
            </Col>
        </div>
    )
}

export default ThankYou