import {isEmpty} from "./utils";

export const letterCase = (params) => {
    let result = "";

    const pattern = /([;:,-.\/ X])/;
    const array = params?.str?.split(pattern).filter(Boolean);
    const toLowerCase = params?.toLowerCase ?? "Y";
    const skip = params?.skipArr ?? [
        "the",
        "a",
        "am",
        "an",
        "of",
        "and",
        "as",
        "but",
        "as",
        "if",
        "nor",
        "or",
        "so",
        "yet",
        "in",
        "on",
        "is",
        "are",
        "were",
        "was",
    ];


    array.forEach((v, k) => {
        if (skip.includes(v.toLowerCase())) {
            if (toLowerCase === "Y") {
                result += v.toLowerCase();
            }
        } else {
            if (v[0] === "(") {
                result += v
                    .toLowerCase()
                    .replace(/^\(|(\b[a-z])/g, (match) => match.toUpperCase());
            } else {
                result += v
                    .toLowerCase()
                    .replace(/\b\w/g, (match) => match.toUpperCase());
            }
        }
    });

    return result;
};


export const  explodeString = (str, symbol=',') => {
    if(isEmpty(str)) {
        return [];
    }
    return str?.split(symbol)?.map(item => item.trim());
}

export const  implodeArr = (arr, symbol=',') => {
    return arr?.join(symbol);
}
