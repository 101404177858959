import { call, put, takeLatest } from "redux-saga/effects";

import {
  getStripeSessionError,
  getStripeSessionSuccess,
  startGettingStripeSession,
} from "./stripeSessionSlice";
import { getStripeSessionApi } from "./getStripeSessionApi";
import { showToast } from "../../../../helpers/ToastHelper";
import { TOAST_TYPES } from "../../../../helpers/constants";
import { ActionTypes } from "../../../redux-store/ActionTypes";
import { isEmpty } from "../../../../helpers/utils";

function* getStripeSessionSaga(action) {
  try {
    yield put(startGettingStripeSession(action.payload));
    const resp = yield call(getStripeSessionApi, action.payload);
    yield put(getStripeSessionSuccess(action.payload));
    if (!isEmpty(resp?.data?.url)) {
      // window.location.href = resp?.data?.url;
      window.open(resp?.data?.url, "_blank").focus();
    }
    // showToast({ message: resp?.message });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Something went wrong.";
    yield put(getStripeSessionError(errorMessage));
    showToast({ type: TOAST_TYPES.DANGER, message: errorMessage });
  }
}

export function* getStripeSession() {
  yield takeLatest(ActionTypes.GET_STRIPE_SESSION, getStripeSessionSaga);
}
