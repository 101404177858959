import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Card, CardBody, Button, Input, Popover, PopoverBody } from "reactstrap";
import { Breadcrumbs, H4, H6 } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { getAllCompanyData, getCallsReport, getTranscript } from '../../api/AuthIndex';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../ManageAssistant/ManageAssistant.css';
import { Modal } from 'react-bootstrap';
import { RxChatBubble, RxHamburgerMenu } from 'react-icons/rx'
import * as feather from 'feather-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import { sassNull } from 'sass';
import botImage from '../../assets/images/user/botChatImg.png'
import neutral from '../../assets/images/user/natural.png'
import negative from '../../assets/images/user/negative.png'
import positive from '../../assets/images/user/positive.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import DropdownCommon from '../../Components/Common/Dropdown';
import Select from 'react-select'
import { formatDuration } from '../../Constant/GlobalFunctions'
import NoDataFound from '../NoDataFound/NoDataFound';
import { useSelector } from 'react-redux';
import { selectCompleteCompany } from '../../Store/modules/company/companySelectors';
import { debounceTime } from '../../helpers/constants';
import moonLogo from './golden-moon.svg';
import './style.css'
import { Color } from '../../helpers/colors';

const CallReports = ({ mainClass }) => {
    const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    const userData = JSON.parse(localStorage.getItem("auth0_profile"));
    const theme = localStorage.getItem("mix_background_layout")
    const startOfWeek = moment().startOf('week').format('MM/DD/YYYY');
    const endOfWeek = moment().endOf('week').format('MM/DD/YYYY');
    const [dateRange, setDateRange] = useState({ startDate: startOfWeek, endDate: endOfWeek });
    const token = localStorage?.getItem('token');
    const [tableData, setTableData] = useState();
    const [keywordValue, setKeywordValue] = useState('');
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [audio, setAudio] = useState()
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [botData, setBotData] = useState()
    const [callerData, setCallerData] = useState()
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedData, setSelectedData] = useState('All')
    const [selectedSentiment, setSelectedSentiment] = useState('all')

    const completeCompany = useSelector(selectCompleteCompany);
    console.log("🚀 ~ CallReports ~ completeCompany:", completeCompany)

    const isInitialRender = useRef(true);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (startOfWeek) {
                handleEventDate();
            }
        }, debounceTime);

        return () => clearTimeout(delayDebounceFn);
    }, [selectedKeywords, selectedData, selectedSentiment]);

    useEffect(() => {
        // handleGetAllCompany()

        const completeCompanyData = [completeCompany?.company].map((item) => {

            // Extract keywords from each assistant within the company data
            const allKeywords = item?.company?.assistant?.flatMap(assistant => {
                const keywordsString = assistant?.keywords || '';
                return keywordsString.split(',').map(keyword => {
                    return keyword
                        .trim()
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ');
                });
            });

            setBotData(allKeywords);

            return item;
        });
    }, [])

    const handleGetAllCompany = async () => {
        const formData = new FormData();
        formData.append("company_id", getCompanyInfo?.id);

        try {
            const res = await getAllCompanyData(token, formData);



            // const completeCompanyData = res.company.map((item) => {

            //     const allKeywords = item?.company?.assistant?.flatMap(assistant => {
            //         const keywordsString = assistant?.keywords || '';
            //         return keywordsString.split(',').map(keyword => keyword.trim());
            //     });

            //     setBotData(allKeywords);

            //     return item?.company;
            // });
            // setCompleteCompanyData(completeCompanyData);

        } catch (error) {
            console.error("Error fetching company data:", error);
        }
    };

    // const handleEventDate = async (event, picker) => {
    //     try {
    //         const newDateRange = {
    //             startDate: picker ? picker?.startDate?.format('MM/DD/YY') : dateRange.startDate,
    //             endDate: picker ? picker?.endDate?.format('MM/DD/YY') : dateRange.endDate,
    //         };
    //         setDateRange(newDateRange);

    //         const formData = new FormData();
    //         formData.append("company_id", getCompanyInfo.id);
    //         formData.append("start_date", picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(newDateRange.startDate).format('YYYY-MM-DD'));
    //         formData.append("end_date", picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(newDateRange.endDate).format('YYYY-MM-DD'));
    //         // formData.append("keyword", keywordValue);
    //         formData.append("keyword", selectedKeywords);

    //         // if (keywordValue) {
    //         //     formData.append("keyword", keywordValue);
    //         //     // setKeywordValue(''); // Clear keywordValue if used
    //         // } else if (selectedKeywords.length > 0) {
    //         //     formData.append("keyword", selectedKeywords.join(','));
    //         // }

    //         const res2 = await getCallsReport(token, formData);
    //         console.log("🚀 ~ handleEventDate ~ res2:", res2)
    //         const convertData = (res2) => {
    //             const records = Object.values(res2.records).flat(2);
    //             return { records };
    //         };
    //         let res = convertData(res2);
    //         if (res?.records.length === 0) {
    //             toast.info("No data found", {
    //                 position: "bottom-center",
    //                 autoClose: 3000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //                 className: 'custom-toast',
    //             });
    //         }
    //         setTableData(res?.records);
    //         setLoading(false)
    //     } catch (error) {
    //         console.log("Error in handleEventDate:", error);
    //         setLoading(false)
    //     }
    // };

    const handleEventDate = async (event, picker) => {
        try {
            const newDateRange = {
                startDate: picker ? picker?.startDate?.format('MM/DD/YYYY') : dateRange.startDate,
                endDate: picker ? picker?.endDate?.format('MM/DD/YYYY') : dateRange.endDate,
            };
            setDateRange(newDateRange);

            const formData = new FormData();
            formData.append("company_id", getCompanyInfo.id);
            formData.append("start_date", picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(newDateRange.startDate).format('YYYY-MM-DD'));
            formData.append("end_date", picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(newDateRange.endDate).format('YYYY-MM-DD'));
            formData.append("keyword", selectedKeywords);

            // Switch case to handle different values
            switch (selectedData) {
                case 'All':
                    // No need to append forward_status and call_status
                    break;
                case 'Customer Ended Calls':
                    if ("NONE" !== "NONE") formData.append("forward_status", "NONE");
                    formData.append("call_status", "customer-ended-call");
                    break;
                case 'Assistant Ended Calls':
                    if ("NONE" !== "NONE") formData.append("forward_status", "NONE");
                    formData.append("call_status", "assistant-ended-call");
                    break;
                case 'Forwarded But No Answered':
                    if ("no-answer" !== "NONE") formData.append("forward_status", "no-answer");
                    if ("assistant-forwarded-call" !== "NONE") formData.append("call_status", "assistant-forwarded-call");
                    break;
                case 'Forwarded And Answered':
                    if ("completed" !== "NONE") formData.append("forward_status", "completed");
                    break;
                case 'Forwarded But Busy':
                    if ("busy" !== "NONE") formData.append("forward_status", "busy");
                    break;
                case 'Forwarded And Failed':
                    if ("failed" !== "NONE") formData.append("forward_status", "failed");
                    break;
                case 'Forward And In-Progress':
                    if ("in-progress" !== "NONE") formData.append("forward_status", "in-progress");
                    break;
                case 'Unknown':
                    if ("NONE" !== "NONE") formData.append("forward_status", "NONE");
                    break;
                default:
                    break;
            }

            if (selectedSentiment !== 'all') {
                formData.append('sentiment', selectedSentiment);
            }

            const res2 = await getCallsReport(token, formData);
            const convertData = (res2) => {
                const records = Object.values(res2.records).flat(2);
                return { records };
            };
            let res = convertData(res2);
            if (res?.records.length === 0) {
                toast.info("No data found", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast',
                });
            }
            setTableData(res?.records);
            setLoading(false);
        } catch (error) {
            console.log("Error in handleEventDate:", error);
            setLoading(false);
        }
    };


    const formatCallDate = (dateString) => {
        return moment(dateString).format('MM-DD-YYYY hh:mm a');
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const truncateString = (str, num) => {
        return str.length > num ? str.slice(0, num) + "..." : str;
    };

    const handleShowModal = async (data) => {
        try {
            const formData = new FormData()
            formData.append('call_id', data?.call_id)
            const res = await getTranscript(token, formData)

            if (res) {
                let content = res?.transcript;
                let callerName = data?.customer

                if (typeof content === 'string') {
                    const chatMessages = content.split(/(?=AI:|User:)/g).map((msg, index) => {
                        const isAI = msg.startsWith('AI:');
                        const highlightedMsg = highlightKeywords(msg.replace(/^(AI:|User:)/, ''), selectedKeywords);
                        return isAI
                            ? `
                                <div class="ai-chat-container">
                                    <img src="${botImage}" alt="AI" class="ai-image" />
                                    <div class="chat-message ai">${highlightedMsg}</div>
                                </div>
                            `
                            : `
                                <div class="user-chat-container">
                                    <div class="initials-circle-name ${getRandomColor()}">${getInitials(callerName == null ? "Unknown" : callerName)}</div>
                                    <div class="chat-message user">${highlightedMsg}</div>
                                </div>
                            `;
                    }).join('');
                    setModalContent(chatMessages);
                    setAudio(res?.recording_url);
                    setCallerData(data);
                } else {
                    setModalContent(content ? content : "No record");
                }

                setModal(true);
            }


        } catch (error) {
            console.log("🚀 ~ handleShowModal ~ error:", error)
        }

    };

    const highlightKeywords = (text, keywords) => {
        const allKeywords = Array.isArray(keywords) ? keywords.filter(Boolean) : []; // Ensure it's an array
        if (allKeywords.length === 0) return text;

        const regex = new RegExp(`(${allKeywords.join('|')})`, 'gi');
        return text.replace(regex, (match) => `<mark>${match}</mark>`);
    };

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };


    const getRandomColor = () => {
        const colors = ['bg-color-1'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const getInitials = (name) => {
        if (!name) return '';
        const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
        return initials.slice(0, 2);
    };

    const [popoverOpen, setPopoverOpen] = useState({});

    const handlePopoverOpen = (id) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [id]: true,
        }));
    };

    // Handle popover close
    const handlePopoverClose = (id) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [id]: false,
        }));
    };

    const popoverRefs = useRef({});

    const setPopoverRef = (id, element) => {
        if (element) {
            popoverRefs.current[id] = element;
        }
    };




    const tableColumns = [
        {
            name: '',
            sortable: false,
            center: false,
            width: '50px',
            cell: row => (
                <>
                    {completeCompany?.company?.activeSubscription !== 'payg' &&
                        <>
                            {row.off_time === 1 ?
                                <></>
                                :
                                <>
                                    {row.avg_sentiment === "positive" ? (
                                        <div title="Positive">
                                            <img src={positive} style={{ width: '30px', height: '30px', borderRadius: '50px', marginLeft: '10px' }} />
                                        </div>
                                    ) : row.avg_sentiment === "negative" ? (
                                        <div title="Negative">
                                            <img src={negative} style={{ width: '30px', height: '30px', borderRadius: '50px', marginLeft: '10px' }} />
                                        </div>
                                    ) : row.avg_sentiment === "neutral" ? (
                                        <div title="Neutral">
                                            <img src={neutral} style={{ width: '30px', height: '30px', borderRadius: '50px', marginLeft: '10px' }} />
                                        </div>
                                    ) : null}
                                </>
                            }

                        </>
                    }
                </>
            ),
        },
        {
            name: 'Call Date',
            sortable: false,
            center: false,
            width: '160px',
            flex: 1,
            cell: row => (
                <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', color: `${row.off_time === 1 ? "#6200ee" : 'black'}` }}>{formatCallDate(row.call_date)} </p>
            ),
        },
        {
            name: 'Name/Number',
            // selector: row => formatPhoneNumber(row['customer_number']),
            sortable: false,
            center: false,
            width: '170px',
            flex: 1,
            cell: row => (
                <div>
                    {row.customer !== null &&
                        <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', width: '100px', lineHeight: 1 }}>
                            {row.customer == null ? "-" : row.customer}
                        </p>
                    }
                    <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', lineHeight: 1, marginTop: row.customer ? '-10px' : '0px', color: row.customer == null ? Color.BLACK : Color.DARK_THE_GREY_TEXT }}>{row.customer_number === 'anonymous' ? '-' : row.customer_number === 'Anonymous' ? '-' : formatPhoneNumber(row.customer_number)}</p>
                </div>
            ),
        },
        {
            name: 'Call Duration',
            // selector: row => formatPhoneNumber(row['customer_number']),
            sortable: false,
            center: false,
            width: '130px',
            // flex: 1,
            cell: row => (
                <div title={`${row.call_duration} seconds`}>
                    <p style={{ marginLeft: '-20px', fontSize: '12px', fontWeight: '400', color: `${row.off_time === 1 ? "#6200ee" : 'black'}` }}>{row?.call_duration ? `${formatDuration(row?.call_duration)}s` : row?.call_duration} </p>
                </div>
            ),
        },
        // {
        //     name: 'Customer Name',
        //     sortable: true,
        //     center: false,
        //     width: '100px',
        //     // flex: 1,
        //     cell: row => (
        //         <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', textAlign: 'left', width: '100px', color: `${row.off_time === 1 ? "#6200ee" : 'black'}` }}>{row.customer == null ? "Unknown" : row.customer} </p>
        //     ),
        // },
        {
            name: 'Transferred To',
            // selector: row => row['transferred_to'],
            sortable: false,
            center: false,
            width: '170px',
            // flex: 1,
            cell: row => (
                <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize', width: '120px', color: `${row.off_time === 1 ? "#6200ee" : 'black'}` }}>{row.transferred_to} </p>
            ),
        },
        {
            name: 'Forward Response',
            sortable: false,
            center: false,
            width: '160px',
            // flex: 1,
            cell: row => (
                <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize', color: `${row.off_time === 1 ? "#6200ee" : 'black'}` }}>{row.status === 'no-answer' ? 'No Answer' : row.status} </p>
            ),
        },
        {
            name: 'End Reason',
            sortable: false,
            center: false,
            width: '160px',
            // flex: 1,
            cell: row => (
                <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize', color: `${row.off_time === 1 ? "#6200ee" : 'black'}` }}>{row.end_reason === 'assistant-forwarded-call' ? 'Call Forwarded' : row.end_reason === 'assistant-ended-call' ? 'Assistant Ended' : row.end_reason === 'assistant-said-end-call-phrase' ? 'Assistant Ended' : row.end_reason === 'customer-ended-call' ? 'Customer Ended' : row.end_reason === 'silence-timed-out' ? 'Unknown' : ''} </p>
            ),
        },
        {
            name: ' ',
            sortable: false,
            center: false,
            // width: '160px',
            flex: 1,
            width: '50px',
            cell: row => {
                console.log("🚀 ~ CallReports ~ row:", row)
                return (
                    <div
                        id={`Popover-CallDate-default-${row.call_id}`}
                        onMouseEnter={() => handlePopoverOpen(`Popover-CallDate-default-${row.call_id}`)}
                        onMouseLeave={() => handlePopoverClose(`Popover-CallDate-default-${row.call_id}`)}
                        style={{ cursor: 'pointer', color: row.off_time === 1 ? '#6200ee' : 'black' }}
                    >
                        {row.off_time === 1 && <img src={moonLogo} alt="Off time" style={{ width: '20px', marginLeft: '5px' }} />}
                        <Popover
                            placement="top"
                            isOpen={popoverOpen[`Popover-CallDate-default-${row.call_id}`]}
                            target={`Popover-CallDate-default-${row.call_id}`}
                        >
                            <PopoverBody>Off Time Call</PopoverBody>
                        </Popover>
                    </div>
                )
            },

        },
        {
            name: 'Conversation',
            sortable: false,
            center: false,
            width: '140px',
            // flex: 1,
            cell: row => {
                console.log("🚀 ~ CallReports ~ row:", row)
                return (
                    <>
                        <div>
                            {(
                                (completeCompany?.company?.activeSubscription !== 'payg') ||
                                (completeCompany?.company?.activeSubscription === 'payg' && row.off_time !== 1)
                            ) && (
                                    <div
                                        onClick={() => handleShowModal(row)}
                                        style={{
                                            width: '40px',
                                            backgroundColor: 'rgba(222, 228, 251, 0.5)',
                                            padding: 5,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            cursor: 'pointer',
                                            borderRadius: '5px',
                                            marginLeft: '40px'
                                        }}
                                    >
                                        <RxChatBubble color="black" size={20} />
                                    </div>
                                )}
                        </div>

                    </>
                )
            },
        }
    ];

    const DailyDropdown = ['All', 'No Answered', 'Forwarded Calls', 'Customer Ended Calls', 'Assistant Ended Calls', 'Unknown']

    const select = [
        { value: 'all', label: 'All' },
        { value: 'customer-ended-calls', label: 'Customer Ended Calls' },
        { value: 'assistant-ended-calls', label: 'Assistant Ended Calls' },
        { value: 'forwarded-but-no-answered', label: 'Forwarded But No Answered' },
        { value: 'forwarded-and-answered', label: 'Forwarded And Answered' },
        { value: 'forwarded-but-busy', label: 'Forwarded But Busy' },
        { value: 'forwarded-and-failed', label: 'Forwarded And Failed' },
        { value: 'forward-and-in-progress', label: 'Forward And In-Progress' },
        { value: 'unknown', label: 'Unknown' },
    ]

    const sentimentData = [
        { value: 'all', label: 'All' },
        { value: 'positive', label: 'Positive' },
        { value: 'neutral', label: 'Neutral' },
        { value: 'negative', label: 'Negative' },
    ]


    const customStyles = {
        control: (provided) => ({
            ...provided,
            // backgroundColor: 'rgba(89, 120, 212, 0.2)',
            backgroundColor: 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                // border: 'none',
                border: '0.5px solid lightgrey',
            },
            height: '45px',
        }),
        singleValue: (provided) => ({
            ...provided,
            // color: '#5978d4',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            // color: 'white',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            // color: '#5978d4',
            color: 'black',
            '&:hover': {
                // color: '#5978d4',
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),

    };





    return (
        <Fragment>
            <Breadcrumbs mainTitle="Reports" />
            <Container fluid={true}>
                {loading ? (
                    <Col sm='12' md='12'>
                        <Card>
                            <CardBody style={{ padding: '10px' }}>
                                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Skeleton height={30} style={{ width: '150px' }} />
                                        <Skeleton height={30} style={{ width: '20vw', marginRight: '10px' }} />
                                        <Skeleton height={30} style={{ width: '10vw', marginRight: '10px' }} />
                                        <Skeleton height={30} style={{ width: '10vw', marginRight: '10px' }} />
                                        <Skeleton height={30} style={{ width: '200px' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <Skeleton height={18} count={20} />
                                    </div>
                                </SkeletonTheme>
                            </CardBody>
                        </Card>
                    </Col>) : (
                    <Fragment>
                        <Row>
                            <Col sm='12'>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="5">
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div style={{ width: '100px' }}>
                                                        <H4>All Calls</H4>
                                                    </div>
                                                    {/* Render there keywords */}

                                                    <Typeahead
                                                        id="keywords-typeahead"
                                                        labelKey="keywords"
                                                        multiple
                                                        options={botData || []}
                                                        placeholder="Choose keywords..."
                                                        selected={selectedKeywords}
                                                        onChange={(selected) => {
                                                            setSelectedKeywords(selected);
                                                            setKeywordValue(null)
                                                            console.log("Selected keywords:", selected);
                                                        }}
                                                        style={{ width: '75%' }}
                                                    />
                                                    {/* {botData.map((keyword, index) => (
                                                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p style={{ marginRight: '10px', width: '100px', textTransform: 'capitalize', fontSize: '14px', fontWeight: '500' }}>
                                                            {keyword}
                                                        </p>
                                                    </div>
                                                ))} */}

                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <Select options={select} styles={customStyles} onChange={(e) => {
                                                    // console.log("Selected Value", e.label);
                                                    setSelectedData(e.label)

                                                }}
                                                    placeholder={'End Reason'}
                                                // defaultValue={select.find(option => option.value === 'all')}
                                                />
                                            </Col>
                                            <Col md="2">
                                                <Select options={sentimentData} styles={customStyles} onChange={(e) => {
                                                    // console.log("Selected Value", e.label);
                                                    setSelectedSentiment(e.value)

                                                }}
                                                    placeholder={'Sentiment'}
                                                // defaultValue={sentimentData.find(option => option.value === 'all')}
                                                />
                                            </Col>
                                            <Col md="3">
                                                <DateRangePicker
                                                    initialSettings={{ startDate: dateRange.startDate, endDate: dateRange.endDate }}
                                                    onApply={handleEventDate}
                                                >
                                                    <input
                                                        type="text"
                                                        value={`${dateRange.startDate} - ${dateRange.endDate}`}
                                                        readOnly
                                                        className="form-control"
                                                    />
                                                </DateRangePicker>
                                            </Col>
                                            {/* <Col md="3">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={keywordValue}
                                            onChange={(e) => setKeywordValue(e.target.value)}
                                            placeholder="Enter Keyword"
                                        />
                                    </Col> */}
                                        </Row>
                                        <br />
                                        {tableData?.length > 0 ?
                                            <DataTable
                                                className='reportsDT'
                                                data={tableData}
                                                columns={tableColumns}
                                                defaultSortAsc={true}
                                                striped={true}
                                                center={true}
                                                pagination
                                                paginationPerPage={50}
                                                noDataComponent={
                                                    <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                        There are no records to display
                                                    </div>
                                                }
                                            />
                                            :
                                            <NoDataFound />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </Container>
            <Modal
                show={modal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {
                    setIsPlaying(false)
                    setModal(false)
                }}
                className="custom-modal"
            >
                <Modal.Header closeButton>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '8px' }} className={`initials-circle ${getRandomColor()}`}>{getInitials(callerData?.customer == null ? 'Unknown' : callerData?.customer)}</div>
                        <div>
                            <p style={{ color: theme === 'dark-only' ? 'grey' : '#013165', fontSize: '14px', padding: '0px', lineHeight: 0.7, fontWeight: 'bold' }}>{callerData?.customer == null ? 'Unknown' : callerData?.customer}</p>
                            <p style={{ marginTop: '-10px', fontSize: '12px', padding: '0px', color: 'grey', lineHeight: 0.7, }}>{callerData?.customer_number}</p>
                            <p style={{ marginTop: '-10px', fontSize: '12px', padding: '0px', color: 'grey', lineHeight: 0.7, }}>{formatCallDate(callerData?.call_date)}</p>
                        </div>
                    </div>
                    <button className={`play-pause-button ${isPlaying ? 'playing' : ''}`} onClick={handlePlayPause}>
                        {isPlaying ? (
                            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" /></svg>
                        ) : (
                            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M8 5v14l11-7z" /></svg>
                        )}
                    </button>
                    <audio ref={audioRef} src={audio} onEnded={() => setIsPlaying(false)}>
                        Your browser does not support the audio element.
                    </audio>
                </Modal.Header>
                <Modal.Body>
                    <div className="chat-container" dangerouslySetInnerHTML={{ __html: modalContent }} />
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setModal(false)}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </Fragment>
    );
};

export default CallReports;
