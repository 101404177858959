import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Card, Col, Input, Row} from 'reactstrap';
import '../../styles/Stepper.css';
import {H4} from '../../../../../AbstractElements';
import {ToastContainer} from "react-toastify";
import 'react-phone-number-input/style.css';
import parsePhoneNumberFromString, {isValidPhoneNumber} from 'libphonenumber-js';
import Select from 'react-select'
import {FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";
import {Spinner} from 'react-bootstrap';
import {getPhoneNumbersList, getStates} from '../../../../../api/Company';
import {debounce} from 'lodash';
import {setUpPhone} from '../../../../../api/AuthIndex';
import '../../../ManageAssistant.css'
import {getLocalStorage, removeLocalStorage, setLocalStorage} from '../../../../../helpers/LocalStorageHelper';
import {PhoneNumberTile} from "./PhoneNumberTile";
import {BookNumberModal} from "./BookNumberModal";
import {ExistingPhone} from "./ExistingPhone";
import {
    selectCreateAssistantSubmitting
} from "../../../../../Store/modules/assistant/create/form/createAssistantFormSelectors";
import {useSelector} from 'react-redux';

const SetupPhone = ({logoClassMain}) => {
    const isSubmitting = useSelector(selectCreateAssistantSubmitting);

    const theme = localStorage.getItem("mix_background_layout");
    const userInfo = JSON.parse(localStorage.getItem("register"))
    const companyData = JSON.parse(localStorage.getItem("signup_company"))
    const botData = JSON.parse(localStorage.getItem("bot_Data"))
    const location = useLocation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [phoneType, setPhoneType] = useState('new');
    const [accountSID, setAccountSID] = useState()
    const [authToken, setAuthToken] = useState();
    const [provider, setProvider] = useState('Twilio')
    const [phone, setPhone] = useState()
    const [prefixKey, setPrefixKey] = useState('')
    const [tollFree, setTollFree] = useState()
    const [cStates, setCStates] = useState('')
    const [cStatesValue, setCStatesValue] = useState('')
    const [tollLoader, setTollLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [states, setStates] = useState([])
    const [country, setCountry] = useState('US')
    const [twilioNumbersList, setTwilioNumbersList] = useState()
    const [tollButtonLoader, setTollButtonLoader] = useState(false)
    const [bookNumberData, setBookNumberData] = useState();
    const [bookNumberModal, setBookNumberModal] = useState();
    const [bookNumberModalData, setBookNumberModalData] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState('');

    const [phoneError, setPhoneError] = useState('');
    const [accountSIDError, setAccountSIDError] = useState('');
    const [authTokenError, setAuthTokenError] = useState('');
    const [phoneAlreadyTakenError, setPhoneAlreadyTakenError] = useState('');

    useEffect(() => {
        getState('226')
    }, [])

    const getTwilioNumberList = async () => {
        setTollLoader(true)
        try {
            const formData = new FormData();
            formData.append('country', country);
            formData.append('state', cStates ? cStates : '');
            formData.append('page', currentPage);
            formData.append('number_type', tollFree ? 'tollfree' : '');
            formData.append('contains', prefixKey);
            const token = localStorage.getItem('token');
            const res = await getPhoneNumbersList(token, formData);
            setTwilioNumbersList(res?.FinalNumbersList);
            setTollLoader(false)
        } catch (error) {
            setTwilioNumbersList([])
            setTollLoader(false)
        }
    };

    const debouncedGetTwilioNumberList = useCallback(
        debounce(getTwilioNumberList, 700),
        [currentPage, cStates, tollFree, prefixKey, phoneType, country]
    );

    useEffect(() => {
        debouncedGetTwilioNumberList();
        return () => {
            debouncedGetTwilioNumberList.cancel();
        };
    }, [debouncedGetTwilioNumberList]);

    const bookNumber = () => {
        alert('clicked');
    }

    const handleBookNumber = async (data) => {
        setTollButtonLoader(true)
        setPhone(null);

        try {
            // const formData = new FormData()
            // formData.append('company_id', companyData?.id)
            // formData.append('phone_number', data?.phone_number)
            // const res = await bookNumber(userInfo?.token, formData)
            // setBookNumberData(res)
            // setTollButtonLoader(false)
            // setBookNumberModal(false)


            // if (res.status) {
            //     setAccountSID(res.ACCOUNT_SID)
            //     setAuthToken(res.AUTH_TOKEN)
            //     setPhone(res.phone)
            //     setTimeout(() => {
            // handleHaveTwilioNumber()
            //     }, 1500);
            // }

            navigate(`${process.env.PUBLIC_URL}/payment`, {state: {bookedNumberData: data, haveNumber: false}});

        } catch (error) {
            setBookNumberModal(false)
            setTollButtonLoader(false)
        }
    }

    const handleGetNumber = (data) => {
        setBookNumberModal(true)
        setBookNumberModalData(data)
    }


    const handleNext = () => {
        navigate(`${process.env.PUBLIC_URL}/setup-phone`);
    };

    const handleBack = () => {
        removeLocalStorage('countryChange')
        navigate(`${process.env.PUBLIC_URL}/assistant`);
    };

    const handleOptionChange = (e) => {
        setPhoneType(e.target.value);
    };


    const numberOptions = [
        {value: 'Tw', label: 'Twilio'},
        {value: 'Vo', label: 'Vonage'},
    ];

    const handleNumberOptionChange = (e) => {
        setCStates(e.value)
        setCStatesValue(e.label)
        setProvider('')
    }

    const handleCountryChange = (e) => {
        setLocalStorage('countryChange', 'Y')
        setCountry(e.value)
        setCStates('');
        setCStatesValue('');
        setStates([]);
        getState(e.id);
    }

    const handleProviderOption = (e) => {
        setProvider(e.label);
    }


    const handleNextPage = () => {
        setCurrentPage(currentPage + 1)
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const getState = async (countryId) => {
        try {
            const res = await getStates(countryId);
            const mappedStates = res.states.map((item) => ({
                value: item.code,
                label: item.name
            }));

            setStates(mappedStates);

            const countryChange = getLocalStorage('countryChange')
            // Check if California exists and set it as the default
            const california = mappedStates.find(state => state.label === 'California');
            if (california && countryChange !== 'Y') {
                setCStatesValue(california.label);
                setCStates(california.value)
            }
        } catch (error) {
            console.log("🚀 ~ getState ~ error:", error);
        }
    };

    const countries = [
        {id: '226', value: 'US', label: 'United States'},
        {id: '38', value: 'CA', label: 'Canada'},
    ]

    const handleHaveTwilioNumber = async () => {
        let hasError = false;

        // Reset error states
        setPhoneError('');
        setAccountSIDError('');
        setAuthTokenError('');

        // Validate inputs
        if (!phone) {
            setPhoneError("Phone Number is required.");
            hasError = true;
        }
        if (provider === "Twilio" && !accountSID) {
            setAccountSIDError("Account SID is required.");
            hasError = true;
        }
        if (provider === "Twilio" && !authToken) {
            setAuthTokenError("AuthToken is required.");
            hasError = true;
        }

        if (hasError) return;

        setLoading(true)
        const providerKeys = `${accountSID},${authToken}`
        try {
            const formData = new FormData()
            formData.append("provider", provider === 'Twilio' ? 'Tw' : provider === 'Vonage' ? 'Vo' : 'Tw')
            formData.append("phone_number", phone)
            formData.append("provider_keys", providerKeys)
            formData.append("bot_name", botData?.bot_name)
            formData.append("company_id", companyData?.id)
            formData.append("bot_id", botData?.id)
            const res = await setUpPhone(userInfo.token, formData)
            if (res.data.original.error) {
                setPhoneAlreadyTakenError("The phone number has already been taken.")
                // toast.error("The phone number has already been taken.", {
                //     position: "top-left",
                //     autoClose: 3000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     className: 'custom-toast-error',
                // });
                setLoading(false)
            } else {
                setLoading(false)
                navigate(`${process.env.PUBLIC_URL}/payment`, {state: {haveNumber: true,}});
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const validatePhoneNumber = (value) => {
        setPhone(value); // Update the phone state
        setError(''); // Clear previous errors

        if (!value) return;

        try {
            const phoneNumber = parsePhoneNumberFromString(value);

            if (phoneNumber) {
                // Validate the number for US or Canada
                if (phoneNumber.country === 'US' || phoneNumber.country === 'CA') {
                    if (isValidPhoneNumber(value)) {
                        setError(''); // No error
                    } else {
                        setError('Invalid phone number.');
                    }

                } else {
                    setError('Only US and Canada numbers are allowed.');
                }
            }
        } catch (error) {
            // Handle possible errors, such as TOO_SHORT
            if (error.message === 'TOO_SHORT') {
                setError('Phone number is too short.');
            } else {
                setError('Invalid phone number.');
            }
        }
    };

    const TwilioNumberList = () => {
        return (
            <>
                {twilioNumbersList?.map((item) => (
                    <PhoneNumberTile item={item} handleGetNumber={handleGetNumber}/>
                ))}
            </>
        )
    }

    const boxShadowStyle = {
        boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.2), 0px 2px 4px -1px rgba(0, 0, 0, 0.14)',
    };

    const BuyNewPhone = () => {
        return (<Card
            style={{
                height: '380px',
                marginTop: '10px',
                border: theme === 'dark-only' ? '' : '1px solid #DCDBDB',
            }}
        >
            <Col sm='12'>
                <Row style={{padding: '10px'}}>
                    <Col md='6'>
                        <Select
                            options={countries}
                            placeholder="Country"
                            defaultValue={countries[0]}
                            onChange={handleCountryChange}
                        />
                    </Col>
                    <Col xs="6" sm="6">
                        <Select
                            options={states}
                            placeholder="State"
                            value={cStatesValue ? {
                                label: cStatesValue,
                                value: cStatesValue
                            } : null}
                            onChange={handleNumberOptionChange}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm='12'>
                <Row style={{padding: '0px 10px'}}>
                    <Col sm="6">
                        <Input
                            type="text"
                            id="name"
                            value={prefixKey}
                            onChange={(e) => setPrefixKey(e.target.value)}
                            placeholder="Prefix (if Any)"
                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                            style={{
                                backgroundColor: 'white',
                                padding: '6px 10px',
                                borderColor: 'hsl(0, 0%, 80%)',
                            }}
                        />
                    </Col>

                    {/* Tollfree Checkbox */}
                    <Col sm="6">
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '2px',
                                gap: '0.5em',
                                alignItems: "center"
                            }}>
                            <Input
                                type="checkbox"
                                value={tollFree}
                                onChange={(e) => setTollFree(e.target.checked)}
                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                style={{
                                    backgroundColor: theme === 'dark-only' ? '#1D1E26' : '',
                                    height: '25px',
                                    width: '25px',
                                    border: '1px solid hsl(0, 0%, 80%)',
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="name"
                                style={{
                                    color: theme === 'dark-only' ? 'grey' : 'black',
                                    fontSize: '16px',
                                    margin: 0,
                                    padding: 0,
                                    marginTop: '3px',
                                }}
                            >
                                Toll Free
                            </label>
                        </div>
                    </Col>
                    {/* </div> */}
                </Row>
            </Col>
            {/* Navigation Buttons */}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: '10px',
                paddingRight: '10px',
                ...boxShadowStyle
            }}>
                <FaAngleDoubleLeft
                    color={theme === 'dark-only' ? 'grey' : 'black'}
                    style={{cursor: 'pointer'}}
                    onClick={handlePreviousPage}
                />
                <p style={{
                    color: theme === 'dark-only' ? 'grey' : 'black',
                    marginTop: '15px'
                }}>
                    {twilioNumbersList?.length} Numbers
                </p>
                <FaAngleDoubleRight
                    color={theme === 'dark-only' ? 'grey' : 'black'}
                    style={{cursor: 'pointer'}}
                    onClick={handleNextPage}
                />
            </div>

            {/* Twilio Numbers List */}
            <div style={{overflow: 'scroll'}}>
                {tollLoader ? (
                    <div style={{
                        height: '180px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1em', marginBottom: '1em'
                    }}>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{color: theme === 'dark-only' ? '#F73164' : 'black'}}
                        />
                    </div>
                ) : (
                    <TwilioNumberList/>
                )}
            </div>
        </Card>);
    }

    return (
        <>
            <Fragment>
                <Card style={{
                    boxShadow: '1px 1px 5px 0px lightgrey',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <H4 attrH4={{style: {color: '#FF5757', fontSize: '1.3rem'}}}>Phone Setup</H4>

                    <>
                        <div style={{marginLeft: '10px', marginTop: '10px'}}>
                            <label style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="radio"
                                    value="new"
                                    checked={phoneType === 'new'}
                                    onChange={handleOptionChange}
                                    className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                    style={{backgroundColor: 'white'}}
                                />
                                <span style={{
                                    marginTop: '5px',
                                    marginLeft: '5px'
                                }}>I want a New Number</span>
                            </label>
                        </div>
                        <div style={{marginLeft: '10px'}}>
                            <label style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="radio"
                                    value="existing"
                                    checked={phoneType === 'existing'}
                                    onChange={handleOptionChange}
                                    className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                    style={{backgroundColor: 'white'}}
                                />
                                <span style={{marginTop: '5px', marginLeft: '5px'}}>I have Twilio phone number</span>
                            </label>
                        </div>

                        {phoneType === 'new' && (
                            <>
                                <BuyNewPhone/>
                                {isSubmitting ?
                                    <Button color='primary' disabled style={{width: '100%', margin: 0}}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Next...
                                    </Button>
                                    :
                                    <Button color='primary' style={{width: '100%', margin: 0}}
                                            onClick={() => {
                                                // const errors = getFormErrors();
                                                // if(!isEmpty(errors)) {
                                                //     return;
                                                // }
                                                handleHaveTwilioNumber();
                                            }}>
                                        Next
                                    </Button>
                                }
                            </>
                        )}

                        {phoneType === 'existing' && (
                            <ExistingPhone/>
                        )}
                    </>

                    <div style={{marginTop: 'auto', width: '100%'}}>
                    </div>
                </Card>
                <ToastContainer/>
                <BookNumberModal
                    bookNumberModal={bookNumberModal}
                    setBookNumberModal={setBookNumberModal}
                    bookNumberModalData={bookNumberModalData}
                    handleBookNumber={handleBookNumber}
                    tollFree={tollFree}
                    tollButtonLoader={tollButtonLoader}
                />
            </Fragment>
        </>
    );
};

export default SetupPhone;
