import {isEmpty} from "./utils";

export const formatServerDate = (serverDate) => {
    if(isEmpty(serverDate)) {
        return '';
    }

    const date = new Date(serverDate);
    const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
    });

    return formattedDate;
}

export function convert24HourTo12HourFormat(time) {
    if(isEmpty(time)) {
        return '';
    }
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    let formattedHours = date.getHours() % 12 || 12; // Convert to 12-hour format
    let formattedMinutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits
    let period = date.getHours() < 12 ? 'am' : 'pm';

    return `${formattedHours}:${formattedMinutes}${period}`;
}