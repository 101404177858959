import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Card, CardBody, Button, Input, Popover, PopoverBody } from "reactstrap";
import { Breadcrumbs, H4, P, H6, H5 } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { getCallsReport, getTranscript } from '../../api/AuthIndex';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../ManageAssistant/ManageAssistant.css';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { RxChatBubble } from 'react-icons/rx'
import { getInitials, formatDuration } from '../../Constant/GlobalFunctions';
import botImage from '../../assets/images/user/botChatImg.png'
import neutral from '../../assets/images/user/natural.png'
import negative from '../../assets/images/user/negative.png'
import positive from '../../assets/images/user/positive.png'
import logoImg from "../../assets/images/logo/logoImg.png";
import botImageMale from '../../assets/images/user/botImageMale.jpeg';
import offtimecall from '../../assets/images/user/offtimecall.png';
import './style.css'
import { debounceTime } from '../../helpers/constants';
import { Color } from '../../helpers/colors';
import { useSelector } from 'react-redux';
import { selectCompleteCompany } from '../../Store/modules/company/companySelectors';
import DropdownCommon from '../../Components/Common/Dropdown';
import { IoPlayCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router';

const WeeklyCallsData = ({ mainClass }) => {
    const completeCompany = useSelector(selectCompleteCompany);
    console.log("🚀 ~ WeeklyCallsData ~ completeCompany:", completeCompany)

    const navigate = useNavigate()

    const currentPackage = completeCompany?.company?.currentPackage?.package_name

    const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    const userData = JSON.parse(localStorage.getItem("auth0_profile"));
    const theme = localStorage.getItem("mix_background_layout")
    const endOfWeek = moment().format('MM/DD/YY'); // Today's date
    const startOfWeek = moment().subtract(7, 'days').format('MM/DD/YY');
    // const startOfWeek = moment().startOf('week').format('MM/DD/YY');
    // const endOfWeek = moment().endOf('week').format('MM/DD/YY');
    const [dateRange, setDateRange] = useState({ startDate: startOfWeek, endDate: endOfWeek });
    const token = localStorage?.getItem('token');
    const [tableData, setTableData] = useState();
    const [filteredData, setFilteredData] = useState();
    console.log("🚀 ~ WeeklyCallsData ~ filteredData:", filteredData)
    const [keywordValue, setKeywordValue] = useState('');
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [audio, setAudio] = useState()
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [callerData, setCallerData] = useState()
    const [callId, setCallId] = useState()
    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const [popoverOpen, setPopoverOpen] = useState({});

    const handlePopoverOpen = (id) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [id]: true
        }));
    };

    const handlePopoverClose = (id) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [id]: false
        }));
    };


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleEventDate();
        }, debounceTime);

        return () => clearTimeout(delayDebounceFn);
    }, [keywordValue]);

    const handleEventDate = async (event, picker) => {
        try {
            // Set the date range
            setDateRange({
                startDate: picker ? picker?.startDate?.format('MM/DD/YY') : startOfWeek,
                endDate: picker ? picker?.endDate?.format('MM/DD/YY') : endOfWeek,
            });

            // Prepare form data
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo.id);
            formData.append(
                "start_date",
                picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(startOfWeek).format('YYYY-MM-DD')
            );
            formData.append(
                "end_date",
                picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(endOfWeek).format('YYYY-MM-DD')
            );
            formData.append("keyword", keywordValue);

            // Fetch the data
            const res2 = await getCallsReport(token, formData);

            // Process the data
            const processData = (records) => {
                // Filter records with off_time_reason !== null
                const filteredRecords = records.filter(record => record.off_time_reason !== null);

                // Group by customer_number
                const groupedRecords = filteredRecords.reduce((acc, record) => {
                    const { customer_number } = record;
                    if (!acc[customer_number]) {
                        acc[customer_number] = [];
                    }
                    acc[customer_number].push(record);
                    return acc;
                }, {});

                // Get the most recent record for each customer_number
                const recentRecords = Object.keys(groupedRecords).map(customer_number => {
                    const allCalls = groupedRecords[customer_number];
                    const recentCall = allCalls[allCalls.length - 1]; // Assuming records are sorted by date
                    return { ...recentCall, allCalls };
                });

                return recentRecords;
            };

            // Convert and extract data
            const records = Object.values(res2.records).flat(2);
            const recentRecords = processData(records);

            // Set another state to store processed data
            setFilteredData(recentRecords);

            // Maintain the current table data logic
            setTableData(records.slice(0, 5));

            console.log("Recent records with all calls (saved to state):", recentRecords);
        } catch (error) {
            console.log("Error in handleEventDate:", error);
        }
    };


    // const handleEventDate = async (event, picker) => {
    //     try {
    //         setDateRange({
    //             startDate: picker ? picker?.startDate?.format('MM/DD/YY') : startOfWeek,
    //             endDate: picker ? picker?.endDate?.format('MM/DD/YY') : endOfWeek,
    //         });

    //         const formData = new FormData();
    //         formData.append("company_id", getCompanyInfo.id);
    //         formData.append("start_date", picker ? picker?.startDate?.format('YYYY-MM-DD') : moment(startOfWeek).format('YYYY-MM-DD'));
    //         formData.append("end_date", picker ? picker?.endDate?.format('YYYY-MM-DD') : moment(endOfWeek).format('YYYY-MM-DD'));
    //         formData.append("keyword", keywordValue);

    //         const res2 = await getCallsReport(token, formData);
    //         console.log("🚀 ~ handleEventDate ~ res2:", res2)

    //         const convertData = (res2) => {
    //             const records = Object.values(res2.records).flat(2);
    //             return { records };
    //         };

    //         let res = convertData(res2);
    //         setTableData(res.records.slice(0, 5));

    //         // also set there
    //     } catch (error) {
    //         console.log("Error in handleEventDate:", error);
    //     }
    // };


    const formatCallDate = (dateString) => {
        return moment(dateString).format('MM-DD-YY hh:mm a');
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const truncateString = (str, num) => {
        return str?.length > num ? str.slice(0, num) + "..." : str;
    };

    const handleShowModal = async (data) => {
        console.log("🚀 ~ handleShowModal ~ data:", data)
        try {
            const formData = new FormData()
            formData.append('call_id', data?.call_id)
            const res = await getTranscript(token, formData)
            console.log("🚀 ~ handleShowModal ~ res:", res)

            if (res) {
                let content = res?.transcript;
                let callerName = data?.customer

                if (typeof content === 'string') {
                    const chatMessages = content.split(/(?=AI:|User:)/g).map((msg, index) => {
                        const isAI = msg.startsWith('AI:');
                        const highlightedMsg = highlightKeywords(msg.replace(/^(AI:|User:)/, ''), selectedKeywords);
                        return isAI
                            ? `
                                <div class="ai-chat-container">
                                    <img src="${botImage}" alt="AI" class="ai-image" />
                                    <div class="chat-message ai">${highlightedMsg}</div>
                                </div>
                            `
                            : `
                                <div class="user-chat-container">
                                    <div class="initials-circle-name ${getRandomColor()}">${getInitials(callerName == null ? "Unknown" : callerName)}</div>
                                    <div class="chat-message user">${highlightedMsg}</div>
                                </div>
                            `;
                    }).join('');
                    setModalContent(chatMessages);
                    setAudio(res?.recording_url);
                    setCallerData(data);
                    setCallId(res?.call_id)
                } else {
                    setModalContent(content ? content : "No record");
                }

                setModal(true);
            }


        } catch (error) {
            console.log("🚀 ~ handleShowModal ~ error:", error)
        }

    };

    const highlightKeywords = (text, keywords) => {
        const allKeywords = [...keywords].filter(Boolean);
        if (allKeywords?.length === 0) return text;
        const regex = new RegExp(`(${allKeywords.join('|')})`, 'gi');
        return text.replace(regex, (match) => `<mark>${match}</mark>`);
    };

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const getRandomColor = () => {
        const colors = ['bg-color-1'];
        return colors[Math.floor(Math.random() * colors?.length)];
    };

    const popoverRefs = useRef({});

    const setPopoverRef = (id, element) => {
        if (element) {
            popoverRefs.current[id] = element;
        }
    };

    const tableColumns = [
        {
            name: 'Time/Date',
            sortable: true,
            center: false,
            width: '140px',
            cell: (row, rowIndex) => {
                const id = `default-${row.call_id}`;
                return (
                    <>
                        <div
                            ref={el => setPopoverRef(id, el)}
                            id={`Popover-CallDate-${id}`}
                            onMouseEnter={() => handlePopoverOpen(`Popover-CallDate-${id}`)}
                            onMouseLeave={() => handlePopoverClose(`Popover-CallDate-${id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p style={{ marginLeft: '10px', fontSize: '12px', fontWeight: '400' }}>{formatCallDate(row.call_date)}</p>
                        </div>
                        <Popover
                            placement="top"
                            isOpen={popoverOpen[`Popover-CallDate-${id}`]}
                            target={`Popover-CallDate-${id}`}
                            toggle={() => { }}
                        >
                            <PopoverBody>
                                {row.summary}
                            </PopoverBody>
                        </Popover>
                    </>
                );
            },
        },
        {
            name: 'Name/Number',
            sortable: true,
            center: false,
            // flex: 1,
            width: '140px',
            cell: (row, rowIndex) => {
                const id = `default-${row.call_id}`;
                return (
                    <>
                        <div
                            ref={el => setPopoverRef(id, el)}
                            id={`Popover-CustomerNumber-${id}`}
                            onMouseEnter={() => handlePopoverOpen(`Popover-CustomerNumber-${id}`)}
                            onMouseLeave={() => handlePopoverClose(`Popover-CustomerNumber-${id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            {row.customer !== null &&
                                <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', width: '100px', lineHeight: 1 }}>
                                    {row.customer == null ? "-" : row.customer}
                                </p>
                            }
                            <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', lineHeight: 1, marginTop: row.customer ? '-10px' : '0px', color: row.customer == null ? Color.BLACK : Color.DARK_THE_GREY_TEXT }}>{row.customer_number === 'anonymous' ? '-' : row.customer_number === 'Anonymous' ? '-' : formatPhoneNumber(row.customer_number)}</p>
                        </div>
                        <Popover
                            placement="top"
                            isOpen={popoverOpen[`Popover-CustomerNumber-${id}`]}
                            target={`Popover-CustomerNumber-${id}`}
                            toggle={() => { }}
                        >
                            <PopoverBody>
                                {row.summary}
                            </PopoverBody>
                        </Popover>
                    </>
                )
            },
        },
        {
            name: 'Duration',
            sortable: true,
            center: false,
            width: '105px',
            cell: (row, rowIndex) => {
                const id = `default-${row.call_id}`;
                return (
                    <>
                        <div
                            ref={el => setPopoverRef(id, el)}
                            id={`Popover-CallDuration-${id}`}
                            onMouseEnter={() => handlePopoverOpen(`Popover-CallDuration-${id}`)}
                            onMouseLeave={() => handlePopoverClose(`Popover-CallDuration-${id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p style={{ marginLeft: '18px', fontSize: '12px', fontWeight: '400' }}>
                                {row?.call_duration ? `${formatDuration(row?.call_duration)}s` : row?.call_duration}
                            </p>
                        </div>
                        <Popover
                            placement="top"
                            isOpen={popoverOpen[`Popover-CallDuration-${id}`]}
                            target={`Popover-CallDuration-${id}`}
                            toggle={() => { }}
                        >
                            <PopoverBody>
                                {row.summary}
                            </PopoverBody>
                        </Popover>
                    </>
                )
            }
        },
        // {
        //     name: 'Name',
        //     sortable: true,
        //     center: false,
        //     width: '100px',
        //     cell: (row, rowIndex) => {
        //         const id = `default-${row.call_id}`;
        //         return (
        //             <>
        //                 <div
        //                     ref={el => setPopoverRef(id, el)}
        //                     id={`Popover-CustomerName-${id}`}
        //                     onMouseEnter={() => handlePopoverOpen(`Popover-CustomerName-${id}`)}
        //                     onMouseLeave={() => handlePopoverClose(`Popover-CustomerName-${id}`)}
        //                     style={{ cursor: 'pointer' }}
        //                 >
        //                     <p style={{ marginLeft: '65px', fontSize: '12px', fontWeight: '400', width: '100px' }}>
        //                         {row.customer == null ? "Unknown" : row.customer}
        //                     </p>
        //                 </div>
        //                 <Popover
        //                     placement="top"
        //                     isOpen={popoverOpen[`Popover-CustomerName-${id}`]}
        //                     target={`Popover-CustomerName-${id}`}
        //                     toggle={() => { }}
        //                 >
        //                     <PopoverBody>
        //                         {row.summary}
        //                     </PopoverBody>
        //                 </Popover>
        //             </>
        //         )
        //     }
        // },
        // {
        //     name: 'Transferred',
        //     sortable: true,
        //     center: false,
        //     width: '130px',
        //     cell: (row, rowIndex) => {
        //         const id = `default-${row.call_id}`;
        //         return (
        //             <>
        //                 <div
        //                     ref={el => setPopoverRef(id, el)}
        //                     id={`Popover-TransferredTo-${id}`}
        //                     onMouseEnter={() => handlePopoverOpen(`Popover-TransferredTo-${id}`)}
        //                     onMouseLeave={() => handlePopoverClose(`Popover-TransferredTo-${id}`)}
        //                     style={{ cursor: 'pointer' }}
        //                 >
        //                     <p style={{ marginLeft: '18px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize', width: '120px' }}>
        //                         {row.transferred_to}
        //                     </p>
        //                 </div>
        //                 <Popover
        //                     placement="top"
        //                     isOpen={popoverOpen[`Popover-TransferredTo-${id}`]}
        //                     target={`Popover-TransferredTo-${id}`}
        //                     toggle={() => { }}
        //                 >
        //                     <PopoverBody>
        //                         {row.summary}
        //                     </PopoverBody>
        //                 </Popover>
        //             </>
        //         )
        //     }
        // },
        // {
        //     name: 'Transferred Reason',
        //     sortable: true,
        //     center: false,
        //     width: '175px',
        //     cell: (row, rowIndex) => {
        //         const id = `default-${row.call_id}`;
        //         return (
        //             <>
        //                 <div
        //                     ref={el => setPopoverRef(id, el)}
        //                     id={`Popover-ForwardResponse-${id}`}
        //                     onMouseEnter={() => handlePopoverOpen(`Popover-ForwardResponse-${id}`)}
        //                     onMouseLeave={() => handlePopoverClose(`Popover-ForwardResponse-${id}`)}
        //                     style={{ cursor: 'pointer' }}
        //                 >
        //                     <p style={{ marginLeft: '18px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize' }}>
        //                         {row.status === 'no-answer' ? 'No Answer' : row.status}
        //                     </p>
        //                 </div>
        //                 <Popover
        //                     placement="top"
        //                     isOpen={popoverOpen[`Popover-ForwardResponse-${id}`]}
        //                     target={`Popover-ForwardResponse-${id}`}
        //                     toggle={() => { }}
        //                 >
        //                     <PopoverBody>
        //                         {row.summary}
        //                     </PopoverBody>
        //                 </Popover>
        //             </>
        //         )
        //     }
        // },
        {
            name: 'End Reason',
            sortable: true,
            center: false,
            // flex: 1,
            width: '130px',
            cell: (row, rowIndex) => {
                const id = `default-${row.call_id}`;
                return (
                    <>
                        <div
                            ref={el => setPopoverRef(id, el)}
                            id={`Popover-EndReason-${id}`}
                            onMouseEnter={() => handlePopoverOpen(`Popover-EndReason-${id}`)}
                            onMouseLeave={() => handlePopoverClose(`Popover-EndReason-${id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p style={{ marginLeft: '18px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize' }}>
                                {row.end_reason === 'assistant-forwarded-call' ? 'Call Forwarded'
                                    : row.end_reason === 'assistant-ended-call' ? 'Assistant Ended'
                                        : row.end_reason === 'assistant-said-end-call-phrase' ? 'Assistant Ended'
                                            : row.end_reason === 'customer-ended-call' ? 'Customer Ended'
                                                : row.end_reason === 'silence-timed-out' ? 'Unknown' : ''}
                            </p>
                        </div>
                        <Popover
                            placement="top"
                            isOpen={popoverOpen[`Popover-EndReason-${id}`]}
                            target={`Popover-EndReason-${id}`}
                            toggle={() => { }}
                        >
                            <PopoverBody>
                                {row.summary}
                            </PopoverBody>
                        </Popover>
                    </>
                )
            }
        },
        {
            name: 'Conversation',
            sortable: true,
            center: false,
            width: '135px',
            cell: (row, rowIndex) => {
                const id = `default-${row.call_id}`;
                return (
                    <>
                        <div
                            ref={el => setPopoverRef(id, el)}
                            id={`Popover-Action-${id}`}
                            onMouseEnter={() => handlePopoverOpen(`Popover-Action-${id}`)}
                            onMouseLeave={() => handlePopoverClose(`Popover-Action-${id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            {/* {currentPackage !== 'Pay as you go' && */}
                            <div
                                onClick={() => handleShowModal(row)}
                                style={{ width: '40px', backgroundColor: 'rgba(222, 228, 251, 0.5)', padding: 5, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', borderRadius: '5px', marginLeft: '40px' }}
                            >
                                <RxChatBubble color='black' size={20} />
                            </div>
                            {/* } */}
                        </div>
                        <Popover
                            placement="top"
                            isOpen={popoverOpen[`Popover-Action-${id}`]}
                            target={`Popover-Action-${id}`}
                            toggle={() => { }}
                        >
                            <PopoverBody>
                                {row.summary}
                            </PopoverBody>
                        </Popover>
                    </>
                )
            }
        },
        {
            name: '',
            sortable: true,
            center: false,
            width: currentPackage == 'Pay as you go' ? '0px' : '30px',
            cell: (row, rowIndex) => {
                const id = `default-${rowIndex}`;
                return (
                    <>
                        {row.avg_sentiment && (
                            <div
                                ref={el => setPopoverRef(id, el)}
                                id={id}
                                onMouseEnter={() => handlePopoverOpen(id)}
                                onMouseLeave={() => handlePopoverClose(id)}
                                style={{}}
                            >
                                {currentPackage !== 'Pay as you go' &&
                                    <img
                                        src={
                                            row.avg_sentiment === "positive"
                                                ? positive
                                                : row.avg_sentiment === "negative"
                                                    ? negative
                                                    : neutral
                                        }
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                            // marginLeft: '50px'
                                        }}
                                        alt="Sentiment Icon"
                                    />
                                }
                            </div>
                        )}
                        {/* <Popover
                            placement="top"
                            isOpen={popoverOpen[id]}
                            target={id}
                            toggle={() => { }}
                        >
                            <PopoverBody>
                                {row.avg_sentiment === "positive" ? "Customer is satisfied." : row.avg_sentiment === "negative" ? "Customer is dissatisfied." : "Customer is neutral."}
                            </PopoverBody>
                        </Popover> */}
                    </>
                );
            },
        },

    ];

    // const tableColumnsPackage = [
    //     {
    //         name: 'Time/Date',
    //         sortable: true,
    //         center: false,
    //         width: '140px',
    //         cell: (row, rowIndex) => {
    //             const id = `default-${row.call_id}`;
    //             return (
    //                 <>
    //                     <div
    //                         ref={el => setPopoverRef(id, el)}
    //                         id={`Popover-CallDate-${id}`}
    //                         onMouseEnter={() => handlePopoverOpen(`Popover-CallDate-${id}`)}
    //                         onMouseLeave={() => handlePopoverClose(`Popover-CallDate-${id}`)}
    //                         style={{ cursor: 'pointer' }}
    //                     >
    //                         <p style={{ marginLeft: '10px', fontSize: '12px', fontWeight: '400' }}>{formatCallDate(row.call_date)}</p>
    //                     </div>
    //                     <Popover
    //                         placement="top"
    //                         isOpen={popoverOpen[`Popover-CallDate-${id}`]}
    //                         target={`Popover-CallDate-${id}`}
    //                         toggle={() => { }}
    //                     >
    //                         <PopoverBody>
    //                             {row.summary}
    //                         </PopoverBody>
    //                     </Popover>
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         name: 'Name/Number',
    //         sortable: true,
    //         center: false,
    //         // flex: 1,
    //         width: '140px',
    //         cell: (row, rowIndex) => {
    //             const id = `default-${row.call_id}`;
    //             return (
    //                 <>
    //                     <div
    //                         ref={el => setPopoverRef(id, el)}
    //                         id={`Popover-CustomerNumber-${id}`}
    //                         onMouseEnter={() => handlePopoverOpen(`Popover-CustomerNumber-${id}`)}
    //                         onMouseLeave={() => handlePopoverClose(`Popover-CustomerNumber-${id}`)}
    //                         style={{ cursor: 'pointer' }}
    //                     >
    //                         {row.customer !== null &&
    //                             <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', width: '100px', lineHeight: 1 }}>
    //                                 {row.customer == null ? "-" : row.customer}
    //                             </p>
    //                         }
    //                         <p style={{ marginLeft: '15px', fontSize: '12px', fontWeight: '400', lineHeight: 1, marginTop: row.customer ? '-10px' : '0px', color: Color.DARK_THE_GREY_TEXT }}>{row.customer_number === 'anonymous' ? '-' : row.customer_number === 'Anonymous' ? '-' : formatPhoneNumber(row.customer_number)}</p>
    //                     </div>
    //                     <Popover
    //                         placement="top"
    //                         isOpen={popoverOpen[`Popover-CustomerNumber-${id}`]}
    //                         target={`Popover-CustomerNumber-${id}`}
    //                         toggle={() => { }}
    //                     >
    //                         <PopoverBody>
    //                             {row.summary}
    //                         </PopoverBody>
    //                     </Popover>
    //                 </>
    //             )
    //         },
    //     },
    //     {
    //         name: 'Duration',
    //         sortable: true,
    //         center: false,
    //         width: '105px',
    //         cell: (row, rowIndex) => {
    //             const id = `default-${row.call_id}`;
    //             return (
    //                 <>
    //                     <div
    //                         ref={el => setPopoverRef(id, el)}
    //                         id={`Popover-CallDuration-${id}`}
    //                         onMouseEnter={() => handlePopoverOpen(`Popover-CallDuration-${id}`)}
    //                         onMouseLeave={() => handlePopoverClose(`Popover-CallDuration-${id}`)}
    //                         style={{ cursor: 'pointer' }}
    //                     >
    //                         <p style={{ marginLeft: '18px', fontSize: '12px', fontWeight: '400' }}>
    //                             {row?.call_duration ? `${formatDuration(row?.call_duration)}s` : row?.call_duration}
    //                         </p>
    //                     </div>
    //                     <Popover
    //                         placement="top"
    //                         isOpen={popoverOpen[`Popover-CallDuration-${id}`]}
    //                         target={`Popover-CallDuration-${id}`}
    //                         toggle={() => { }}
    //                     >
    //                         <PopoverBody>
    //                             {row.summary}
    //                         </PopoverBody>
    //                     </Popover>
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         name: 'End Reason',
    //         sortable: true,
    //         center: false,
    //         // flex: 1,
    //         width: '130px',
    //         cell: (row, rowIndex) => {
    //             const id = `default-${row.call_id}`;
    //             return (
    //                 <>
    //                     <div
    //                         ref={el => setPopoverRef(id, el)}
    //                         id={`Popover-EndReason-${id}`}
    //                         onMouseEnter={() => handlePopoverOpen(`Popover-EndReason-${id}`)}
    //                         onMouseLeave={() => handlePopoverClose(`Popover-EndReason-${id}`)}
    //                         style={{ cursor: 'pointer' }}
    //                     >
    //                         <p style={{ marginLeft: '18px', fontSize: '12px', fontWeight: '400', textTransform: 'capitalize' }}>
    //                             {row.end_reason === 'assistant-forwarded-call' ? 'Call Forwarded'
    //                                 : row.end_reason === 'assistant-ended-call' ? 'Assistant Ended'
    //                                     : row.end_reason === 'assistant-said-end-call-phrase' ? 'Assistant Ended'
    //                                         : row.end_reason === 'customer-ended-call' ? 'Customer Ended'
    //                                             : row.end_reason === 'silence-timed-out' ? 'Unknown' : ''}
    //                         </p>
    //                     </div>
    //                     <Popover
    //                         placement="top"
    //                         isOpen={popoverOpen[`Popover-EndReason-${id}`]}
    //                         target={`Popover-EndReason-${id}`}
    //                         toggle={() => { }}
    //                     >
    //                         <PopoverBody>
    //                             {row.summary}
    //                         </PopoverBody>
    //                     </Popover>
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         name: 'Conversation',
    //         sortable: true,
    //         center: false,
    //         width: '135px',
    //         cell: (row, rowIndex) => {
    //             const id = `default-${row.call_id}`;
    //             return (
    //                 <>
    //                     <div
    //                         ref={el => setPopoverRef(id, el)}
    //                         id={`Popover-Action-${id}`}
    //                         onMouseEnter={() => handlePopoverOpen(`Popover-Action-${id}`)}
    //                         onMouseLeave={() => handlePopoverClose(`Popover-Action-${id}`)}
    //                         style={{ cursor: 'pointer' }}
    //                     >
    //                         {currentPackage !== 'Pay as you go' &&
    //                             <div
    //                                 onClick={() => handleShowModal(row)}
    //                                 style={{ width: '40px', backgroundColor: 'rgba(222, 228, 251, 0.5)', padding: 5, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', borderRadius: '5px', marginLeft: '40px' }}
    //                             >
    //                                 <RxChatBubble color='black' size={20} />
    //                             </div>
    //                         }
    //                     </div>
    //                     <Popover
    //                         placement="top"
    //                         isOpen={popoverOpen[`Popover-Action-${id}`]}
    //                         target={`Popover-Action-${id}`}
    //                         toggle={() => { }}
    //                     >
    //                         <PopoverBody>
    //                             {row.summary}
    //                         </PopoverBody>
    //                     </Popover>
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         name: '',
    //         sortable: true,
    //         center: false,
    //         width: '30px',
    //         cell: (row, rowIndex) => {
    //             const id = `default-${rowIndex}`;
    //             return (
    //                 <>
    //                     {row.avg_sentiment && (
    //                         <div
    //                             ref={el => setPopoverRef(id, el)}
    //                             id={id}
    //                             onMouseEnter={() => handlePopoverOpen(id)}
    //                             onMouseLeave={() => handlePopoverClose(id)}
    //                             style={{ cursor: 'pointer' }}
    //                         >
    //                             <img
    //                                 src={
    //                                     row.avg_sentiment === "positive"
    //                                         ? positive
    //                                         : row.avg_sentiment === "negative"
    //                                             ? negative
    //                                             : neutral
    //                                 }
    //                                 style={{
    //                                     width: '30px',
    //                                     height: '30px',
    //                                     borderRadius: '50%',
    //                                     marginLeft: '10px'
    //                                 }}
    //                                 alt="Sentiment Icon"
    //                             />
    //                         </div>
    //                     )}
    //                     {/* <Popover
    //                         placement="top"
    //                         isOpen={popoverOpen[id]}
    //                         target={id}
    //                         toggle={() => { }}
    //                     >
    //                         <PopoverBody>
    //                             {row.avg_sentiment === "positive" ? "Customer is satisfied." : row.avg_sentiment === "negative" ? "Customer is dissatisfied." : "Customer is neutral."}
    //                         </PopoverBody>
    //                     </Popover> */}
    //                 </>
    //             );
    //         },
    //     },

    // ];

    const DropdownMenu = ["View All"];

    const handleActionSelect = (row, action) => {
        if (action === "View All") {
            navigate(`${process.env.PUBLIC_URL}/app/calls-report`);
        }
    };

    return (
        <Fragment>
            <Row>
                <Col sm='8'>
                    <Card style={{ height: '465px' }}>
                        <CardBody>
                            <Row>
                                <Col md="8">
                                    <H4>All Calls</H4>
                                </Col>
                            </Row>
                            <br />
                            <DataTable
                                className='reportsDT'
                                data={tableData}
                                columns={tableColumns}
                                striped={true}
                                center={true}
                                pagination
                                paginationPerPage={50}
                                noDataComponent={
                                    <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                        There are no records to display
                                    </div>
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='12' md='4'>
                    <Card style={{ height: '465px' }}>
                        <CardBody>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <H6>Off Time Calls</H6>
                                    <DropdownCommon
                                        dropdownMain={{ className: 'icon-dropdown', direction: 'start', }}
                                        options={DropdownMenu}
                                        iconName='fa-solid fa-ellipsis-vertical'
                                        btn={{ tag: 'span', style: { color: theme === 'dark-only' ? 'grey' : 'black', cursor: 'pointer', padding: '10px' } }}
                                        onSelect={(action) => handleActionSelect(filteredData, action)}
                                    />
                                </div>
                                <div>
                                    {filteredData?.map((item) => {
                                        const formattedDateTime = moment(item?.call_date).format('DD-MMM hh:mma');
                                        return (
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <img src={offtimecall} alt="" style={{ width: '40px', height: '40px', borderRadius: '100%' }} />
                                                <div className="talk-info" style={{ width: '100%', marginLeft: '10px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <H6>{formatPhoneNumber(item?.customer_number)}</H6>
                                                        <div style={{ backgroundColor: Color.PRIMARY, padding: '0px 10px', borderRadius: '5px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ fontWeight: '500', color: Color.WHITE, fontSize: '0.7rem' }}>{item?.allCalls?.length > 1 ? `${item?.allCalls?.length} Calls` : `${item?.allCalls?.length} Call`}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <p style={{ fontSize: '0.7rem', marginTop: '-5px' }}>Last call: {formattedDateTime}</p>
                                                        {currentPackage !== 'Pay as you go' &&
                                                            <IoPlayCircleOutline size={22} color={theme === 'dark-only' ? 'grey' : '#69788F'}
                                                                style={{ cursor: 'pointer' }} onClick={() => handleShowModal(item)} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Modal
                    show={modal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => {
                        setIsPlaying(false)
                        setModal(false)
                    }}
                    className="custom-modal"
                >
                    <Modal.Header closeButton>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '8px' }} className={`initials-circle ${getRandomColor()}`}>{getInitials(callerData?.customer == null ? 'Unknown' : callerData?.customer)}</div>
                            <div style={{}}>
                                <p style={{ color: theme === 'dark-only' ? 'grey' : '#013165', fontSize: '14px', padding: '0px', lineHeight: 0.7, fontWeight: 'bold' }}>{callerData?.customer == null ? 'Unknown' : callerData?.customer}</p>
                                <p style={{ marginTop: '-10px', fontSize: '12px', padding: '0px', color: 'grey', lineHeight: 0.7, }}>{formatPhoneNumber(callerData?.customer_number)}</p>
                                <p style={{ marginTop: '-10px', fontSize: '12px', padding: '0px', color: 'grey', lineHeight: 0.7, }}>{formatCallDate(callerData?.call_date)}</p>
                                <p style={{ marginTop: '-10px', fontSize: '10px', padding: '0px', color: 'grey', lineHeight: 1, }}>{callId}</p>
                            </div>
                        </div>
                        <button className={`play-pause-button ${isPlaying ? 'playing' : ''}`} onClick={handlePlayPause}>
                            {isPlaying ? (
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" /></svg>
                            ) : (
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M8 5v14l11-7z" /></svg>
                            )}
                        </button>
                        <audio ref={audioRef} src={audio} onEnded={() => setIsPlaying(false)}>
                            Your browser does not support the audio element.
                        </audio>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="chat-container" dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button onClick={() => setModal(false)}>Close</Button>
                </Modal.Footer> */}
                </Modal>
            </Row>
        </Fragment>
    );
};

export default WeeklyCallsData;
