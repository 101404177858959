import roboImg from "../../../../../assets/images/logo/roboImg.png";
import {P} from "../../../../../AbstractElements";
import {selectSelectedPrecastBot} from "../../../../../Store/modules/precastBots/precastBotSelectors";
import {IoPauseCircleOutline, IoPlayCircleOutline} from "react-icons/io5";
import {useSelector} from 'react-redux';
import {useRef, useState} from "react";
import {GENDER, SHORT_GENDER} from "../../../../../helpers/constants";
import {DetailsInfoTile} from "./DetailsInfoTile";

export const PrecastBotDetail = () => {
    const audioRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const selectedPrecastBot = useSelector(selectSelectedPrecastBot);
    const togglePrecastBotAudioPlay = () => {
        if (isPlaying) {
            setIsPlaying(false);
            audioRef.current.pause();
        } else {
            setIsPlaying(true);
            audioRef.current.play();
        }
    };

    return (
        <>
            <div style={{display: 'flex'}}>
                <img
                    src={selectedPrecastBot?.picture ? selectedPrecastBot?.picture : roboImg}
                    alt='loginpage' style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50px'
                }}/>
                <div style={{width: '100%'}}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <P attrPara={{
                            style: {
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                marginTop: '10px'
                            }
                        }}>{selectedPrecastBot?.bot_name}</P>
                        {selectedPrecastBot?.audio_file !== null && (
                            <>
                                <div style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    backgroundColor: '#DDE6FF',
                                    height: '30px',
                                    padding: '5px 10px',
                                    borderRadius: '50px'
                                }}>
                                    <div
                                        onClick={() => togglePrecastBotAudioPlay(selectedPrecastBot)}
                                        style={{cursor: 'pointer'}}>
                                        {isPlaying ? (
                                            <IoPauseCircleOutline
                                                size={20}
                                                color={'#5679D5'}/>
                                        ) : (
                                            <IoPlayCircleOutline
                                                size={20}
                                                color={'#5679D5'}/>
                                        )}
                                    </div>
                                    <P attrPara={{
                                        style: {
                                            color: '#5679D5',
                                            lineHeight: 1.2,
                                            fontSize: '16px',
                                            marginLeft: '5px'
                                        },
                                        onClick: () => togglePrecastBotAudioPlay(selectedPrecastBot)
                                    }}>Play</P>
                                    <audio
                                        ref={audioRef}
                                        src={selectedPrecastBot?.audio_file}
                                        type="audio/mpeg"
                                        onEnded={() => setIsPlaying(false)}
                                        style={{display: 'none'}}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <P attrPara={{
                        style: {
                            textAlign: 'left',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '-10px',
                            color: '#5679D5'
                        }
                    }}>{selectedPrecastBot?.gender === SHORT_GENDER.MALE ? GENDER.MALE : GENDER.FEMALE}</P>
                </div>
            </div>
            <div style={{
                marginTop: '20px',
                height: '450px',
                overflow: 'scroll'
            }}>
                <DetailsInfoTile heading={'Welcome Message To Customer'}
                                 description={selectedPrecastBot?.first_message}/>
                <DetailsInfoTile heading={'Ending Last Message to Customer'}
                                 description={selectedPrecastBot?.end_call_message}/>
                <DetailsInfoTile heading={'Ending the Phone Call Message'}
                                 description={selectedPrecastBot?.end_call_phrases}/>
                <DetailsInfoTile heading={'Instructions'} description={selectedPrecastBot?.prompt}/>
            </div>
        </>
    )
}