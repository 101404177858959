import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { getAllNotifications, getBotByCompany, getBotRaw } from '../../../api/AuthIndex';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import profileImage from '../../../assets/images/user/bot.png';
import misscalls from '../../../assets/images/user/missed-call.png';
import botImage from '../../../assets/images/user/meh.png';
import startTalk from '../../../assets/images/user/startTalk.png';
import { H5, P } from '../../../AbstractElements';
import Vapi from '@vapi-ai/web';
import { RxCross2 } from "react-icons/rx";
import ApiContext from '../../../ApiContext';
import { Color } from '../../../helpers/colors';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { setNotiData } from '../../../api/AuthIndex';


const Notification = () => {
    const navigate = useNavigate();
    const { isUpdated, setUpdateStatus, companyDetail } = useContext(ApiContext);
    const companies = JSON.parse(localStorage.getItem('companies'))
    const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    console.log("🚀 ~ Notification ~ getCompanyInfo:", getCompanyInfo)

    let token = localStorage?.getItem('token');
    const [notificationDropDown, setNotificationDropDown] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [notificationCalls, setNotificationCalls] = useState([]);

    useEffect(() => {
        allNotifications()
    }, [])

    const allNotifications = async () => {
        setNotificationList([]);
        try {
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo?.id);
            const res = await getAllNotifications(formData, token);
            console.log("🚀 ~ allNotifications ~ res:", res.notification)

            const totalCalls = res.notification?.filter(item =>
                item.message.includes("You have got 1 call(s) during off time.")
            );

            const otherMessages = res.notification?.filter(item =>
                !item.message.includes("You have got 1 call(s) during off time.")
            );
            setNotificationCalls(totalCalls)
            setNotificationList(otherMessages);
        } catch (error) {
            console.error('Error fetching bots:', error);
        }
    };

    const setNotificationCall = async (id) => {
        let setNotificationData = await setNotiData(token,id,getCompanyInfo.id);
        allNotifications()
        navigate('app/calls-report');
    }

    return (
        <>
            <li className='onhover-dropdown'>
                <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}>
                    <SvgIcon iconId='notification' />
                    {/* <img style={{ width: '28px', height: '28px', borderRadius: '10px', position: 'relative' }} src={botImage} /> */}
                    {notificationList?.length > 0 && notificationCalls?.length > 0 && <span style={{ position: 'absolute', right: '6px' }} className='badge rounded-pill badge-secondary'>{notificationList?.length + 1}</span>}
                    {notificationList?.length > 0 || notificationCalls?.length > 0 && <span style={{ position: 'absolute', right: '6px' }} className='badge rounded-pill badge-secondary'>{notificationList?.length + 1}</span>}
                </div>
                <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
                    <h6 style={{ textAlign: 'left' }} className='f-18 mb-0 dropdown-title'>Notifications</h6>
                    {/* <div style={{ padding: '10px 10px 0px 10px', display: 'flex', alignItems: 'center' }}>
                        <P attrPara={{ style: { backgroundColor: Color.PRIMARY_BACKGROUND, color: Color.PRIMARY, borderRadius: '20px', padding: '5px 10px' } }}>All Notifications</P>
                        <P attrPara={{ style: { backgroundColor: Color.LIGHT_GREY, color: Color.GREY, marginLeft: '10px', padding: '5px 10px', borderRadius: '20px', } }}>Unread</P>
                    </div> */}
                    <div>

                        {notificationCalls.length > 0 &&
                            <div style={{ margin: '5px' }}>
                                <div onClick={()=>{setNotificationCall('call')}}>
                                    <div style={{ padding: '10px', backgroundColor: Color.LIGHT_GREY, margin: '5px', display: 'flex', justifyContent: 'space-between', borderRadius: '10px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <img style={{ width: '45px', height: '45px', borderRadius: '50px', position: 'relative' }} src={misscalls} />
                                            <div>
                                                <P attrPara={{ style: { fontWeight: '500', paddingBottom: '0px' } }}>{notificationCalls.length} Missed Calls</P>
                                                <P attrPara={{ style: { fontWeight: '300', fontSize: '10px', lineHeight: 1, marginTop: '-10px' } }}> You missed {notificationCalls.length} calls in off timing.</P>
                                            </div>
                                        </div>
                                        <div>
                                            <P attrPara={{ style: { fontWeight: '300', paddingBottom: '0px', fontSize: '10px', } }}></P>
                                            <div style={{ backgroundColor: Color.PRIMARY, width: '8px', height: '8px', borderRadius: '50px', marginTop: '-10px', justifySelf: 'end' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {notificationList.length > 0 &&

                            <>
                                {notificationList?.slice(0, 3).map((item, i) => (
                                    <div key={i} style={{ margin: '5px' }} onClick={()=>{setNotificationCall(item.id)}}>
                                        <div style={{ padding: '10px', backgroundColor: Color.LIGHT_GREY, margin: '5px', display: 'flex', justifyContent: 'space-between', borderRadius: '10px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <img style={{ width: '45px', height: '45px', borderRadius: '50px', position: 'relative' }} src={`${process.env.REACT_APP_IMAGE_URL}${item?.company.upload_logo}`} />
                                                <div>
                                                    <P attrPara={{ style: { fontWeight: '500', paddingBottom: '0px' } }}>{item.company.company_name}</P>
                                                    <P attrPara={{ style: { fontWeight: '300', fontSize: '10px', lineHeight: 1, marginTop: '-10px' } }}>{item.message}</P>
                                                </div>
                                            </div>
                                            <div>
                                                <P attrPara={{ style: { fontWeight: '300', paddingBottom: '0px', fontSize: '10px', } }}>08:15</P>
                                                <div style={{ backgroundColor: Color.PRIMARY, width: '8px', height: '8px', borderRadius: '50px', marginTop: '-10px', justifySelf: 'end' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        
                        }

                        {notificationList.length === 0  && notificationCalls.length === 0 &&
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0px 0px 0px' }}>
                            <P>No notifications</P>
                         </div>
                        }

                        
                    </div>
                </div>
            </li>
        </>
    );
};

export default Notification;
