import { createSlice } from '@reduxjs/toolkit';
import {showToast} from "../../../helpers/ToastHelper";
import {AI_ASSISTANT_RIGHT_SIDEBAR} from "../../../helpers/constants";

const assistantSlice = createSlice({
    name: 'assistant',
    initialState: {
        rightSideBarContent: null,
        selectedAssistant: null,
    },
    reducers: {
        setRightSidebarContent: (state, action) => {
            state.rightSideBarContent = action.payload.sidebar;
        },
        setSelectedAssistant: (state, action) => {
            state.selectedAssistant = action.payload.assistant;
        },
    },
});

export const {
    setRightSidebarContent,
    setSelectedAssistant
} = assistantSlice.actions;

export default assistantSlice.reducer;
