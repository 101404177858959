import axios from 'axios';

const user = JSON.parse(localStorage.getItem("login"));
const getTokken = localStorage.getItem('token')
let token = user ? user.token : '';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': "Bearer " + token
    },
});

export const AxiosInstance2 = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'X-CSRF-TOKEN': csrfToken,
        "Access-Control-Allow-Origin": "*",
        'Accept': 'application/json',
        'Authorization': "Bearer " + getTokken
    },
});

