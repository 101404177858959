import { useState } from 'react';
import {apiService} from "./ApiService";
import {ApiEndpoints} from "../helpers/ApiEndpoints";

export const useHelpDeskService = () => {
    // const [helpDeskResp, setHelpDeskResp] = useState({});
    const [helpDeskErr, setHelpDeskErr] = useState(null);
    const [loadingHelpDeskResp, setLoadingHelpDeskResp] = useState(false);

    const getHelpDeskResp = async (data) => {
        setLoadingHelpDeskResp(true);
        try {
            const response = await apiService.post(ApiEndpoints.HELPDESK_CHAT, data);
            // setHelpDeskResp((prevResp) => {
            //     return {...prevResp, ...response }
            // });
            return response;
        } catch (err) {
            setHelpDeskErr(err.message);
        } finally {
            setLoadingHelpDeskResp(false);
        }
    };

    return { getHelpDeskResp, helpDeskErr, loadingHelpDeskResp };
};