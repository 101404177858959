import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_wordCloud from '@amcharts/amcharts4/plugins/wordCloud';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Card, CardBody } from 'reactstrap';
import { getKeywordsData } from '../../api/AuthIndex';
import moment from 'moment';
import wordsImg from '../../assets/images/logo/wordsImg.png'
import { H4 } from '../../AbstractElements';

const KeywordsChart = ({ refresh, firstDate }) => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    let token = localStorage?.getItem('token');
    const [extractedData, setExtractedData] = useState([]);

    useEffect(() => {
        // Function to fetch data
        const handleKeywordData = async () => {
            const weekAgo = moment().subtract(7, 'days');
            const week_date = weekAgo.format('YYYY-MM-DD');

            const start_date = firstDate();
            const end_date = moment().format('YYYY-MM-DD');

            const formData = new FormData();
            formData.append('company_id', getCompanyInfo?.id);
            formData.append('start_date', start_date ? start_date : week_date);
            formData.append('end_date', end_date);
            formData.append('period', 'weekly');

            const res = await getKeywordsData(token, formData);
            const callData = res?.data;

            const extractedDataArray = callData
                ?.map(item => ({
                    tag: item.keyword,
                    count: Number(item.frequency)
                }))
                .filter(item => item.count > 0);

            setExtractedData(extractedDataArray);
            console.log("🚀 ~ handleKeywordData ~ extractedDataArray:", extractedDataArray)
        };

        handleKeywordData();
    }, [refresh]);

    useEffect(() => {
        if (extractedData.length > 0) {
            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("chartdiv", am4plugins_wordCloud.WordCloud);
            chart.fontFamily = "Rubik, sans-serif";
            let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
            series.maxCount = 100;
            series.minWordLength = 2;
            series.randomness = 0.5;
            series.rotationThreshold = 0.5;
            series.data = extractedData;
            series.dataFields.word = "tag";
            series.dataFields.value = "count";

            series.heatRules.push({
                "target": series.labels.template,
                "property": "fill",
                "min": am4core.color("#5979D4"),
                "max": am4core.color("#5979D4"),
                "dataField": "value"
            });

            series.labels.template.tooltipText = "{word}:{value}";

            let hoverState = series.labels.template.states.create("hover");
            hoverState.properties.fill = am4core.color("#FF5757");

            chart.logo.disabled = true;

            return () => {
                if (chart) {
                    chart.dispose();
                }
            };
        }
    }, [extractedData]);

    return (
        <>
            {extractedData.length > 0 ? (
                <div id="chartdiv" style={{ width: '100%', height: '350px' }}></div>
            ) : (
                <img
                    src={wordsImg}
                    alt="Random Placeholder"
                    style={{
                        width: '100%', height: '300px', objectFit: 'fill', filter: 'blur(5px)', opacity: 0.8
                    }}
                />
            )}
        </>
    );
};

export default KeywordsChart;
