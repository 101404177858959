import React, { Fragment, useState, useEffect, useRef } from 'react';
import OTPInput from 'react-otp-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H4, P, Image } from '../AbstractElements';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import roboImg from '../assets/images/logo/roboImg.png';
import { getResendOTP, resendOTP, verificationOTP, verifyOTPPass } from '../api/AuthIndex';
import { ToastContainer, toast } from "react-toastify";
import '../component2/ManageAssistant/ManageAssistant.css';
import solarArrow from '../assets/images/logo/solarArrow.png';
import tickCircle from '../assets/images/logo/tickCircle.png';
import { FaArrowLeft } from 'react-icons/fa';
import { Color } from '../helpers/colors';

const OTPView = ({ logoClassMain }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location?.state?.email ? location?.state?.email : location?.state?.values?.email;
    const token = location?.state?.token;
    // const [otp, setOtp] = useState(['', '', '']);
    const [otp, setOtp] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [otpErrors, setOtpErrors] = useState();
    const [countdown, setCountdown] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendAttempts, setResendAttempts] = useState(0);

    useEffect(() => {
        if (otp?.length === 6) {
            handleSubmit();
        }
    }, [otp]);

    // useEffect(() => {
    //     if (otp.join('').length === 6) {
    //         handleSubmit();
    //     }
    // }, [otp]);

    // useEffect(() => {
    //     if (countdown > 0) {
    //         const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    //         return () => clearTimeout(timer);
    //     } else {
    //         setIsResendDisabled(false);
    //     }
    // }, [countdown]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(prevCountdown => prevCountdown - 1); // Use functional update to ensure the latest state is used
            }, 1000);
            return () => clearTimeout(timer); // Clean up the timer
        } else {
            setIsResendDisabled(false); // Enable resend when countdown finishes
        }
    }, [countdown]);

    const maskEmail = (email) => {
        if (!email) return '';
        const [localPart, domain] = email.split('@');
        const maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart?.length - 2)}${localPart[localPart?.length - 1]}`;
        return `${maskedLocalPart}@${domain}`;
    };

    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Invalid email format.';
        }
        if (otp?.length !== 6) {
            errors.otp = 'Check email for 6-digit code.';
        }
        return errors;
    };

    const handleSubmit = async () => {
        // navigate(`${process.env.PUBLIC_URL}/business`);
        const validationErrors = validate();
        if (Object.keys(validationErrors)?.length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        setIsSubmitting(true);
        try {
            const res = location?.state?.email ? await verifyOTPPass({ email, otp: otp }) : await verificationOTP({ email, otp: otp })
            // toast.info("Passcode verified.", {
            //     position: "bottom-center",
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     className: 'custom-toast',
            // });
            localStorage.setItem("companyInfo", null);
            localStorage.setItem("bot_Data", null);
            setTimeout(() => {
                if (location?.state?.email) {
                    navigate(`${process.env.PUBLIC_URL}/change-password`, { state: { res } });
                } else {
                    navigate(`${process.env.PUBLIC_URL}/business`);
                }
            }, 1500);
        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error);
            setOtpErrors("You have entered wrong 6-digit code.")
            // toast.error("You have entered wrong 6-digit code.", {
            //     position: 'top-center',
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     className: 'custom-toast-error',
            // });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleResend = async () => {
        if (isResendDisabled || resendAttempts >= 3) { // Disable after 3 attempts
            return;
        }
        setIsResendDisabled(true);
        try {
            const res = await resendOTP(token);
            if (res) {
                setCountdown(59); // Start countdown from 60 seconds
                setResendAttempts(prev => prev + 1);
                // toast.info("6-digit code has been sent to your email.", {
                //     position: "bottom-center",
                //     autoClose: 3000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     className: 'custom-toast',
                // });
            }
        } catch (error) {
            console.log("🚀 ~ handleResend ~ error:", error)
        }

    };

    const inputRefs = [useRef(null), useRef(null), useRef(null)];

    const handleOTPChange = (index, value) => {
        const updatedOtp = [...otp];

        // Move forward when two digits are entered
        if (value.length === 2 && index < otp.length - 1) {
            updatedOtp[index] = value;  // Update the current input
            setOtp(updatedOtp);  // Set updated OTP state
            inputRefs[index + 1].current.focus();  // Move to next input
        }
        // Allow removal and moving back when deleting
        else if (value.length === 0 && index > 0) {
            updatedOtp[index] = value;  // Clear the current input
            setOtp(updatedOtp);  // Set updated OTP state
            inputRefs[index - 1].current.focus();  // Move to previous input
        } else {
            updatedOtp[index] = value;  // Just update current input without moving focus
            setOtp(updatedOtp);
        }
    };

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                    minHeight: '100vh',
                }}>
                <Container className='p-0 login-page' fluid={true}>
                    <Row className='m-0'>
                        <Link style={{ marginTop: '1%' }} className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                            </div>
                        </Link>
                        <Col className='p-0'>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '2%',
                                }}>
                                <Card style={{ width: '1000px', padding: '0px' }}>
                                    <CardBody style={{ padding: '0px' }}>
                                        <div>
                                            <Row>
                                                <Col sm='6' style={{ position: 'relative', overflow: 'hidden' }}>
                                                    <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                                                    <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                        {location?.state?.email &&
                                                            <div onClick={() => { navigate(`${process.env.PUBLIC_URL}/forgotpassword`) }} style={{ cursor: 'pointer', backgroundColor: Color.SECONDARY, padding: '10px', width: '35px', height: '35px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <FaArrowLeft size={20} color={Color.WHITE} alt='loginpage' />
                                                            </div>
                                                        }
                                                        <div style={{ marginTop: '20px' }}>
                                                            <H4>{location?.state?.email ? 'Forgot Password' : 'Your AI Assistant, A Few Clicks Away'}</H4>
                                                            <div style={{ display: 'flex', marginTop: '5px' }}>
                                                                <P attrPara={{ style: { fontSize: '18px' } }} >Enter your registered email address and click on the reset password button.</P>
                                                            </div>
                                                            <div style={{ display: 'flex', marginTop: '15px' }}>
                                                                <P attrPara={{ style: { fontSize: '18px' } }} >If you don’t see the email, be sure to check your spam folder or try resending the request.</P>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm='6'>
                                                    <div style={{ padding: '20px 20px 0px 0px' }}>
                                                        <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: '600px', display: 'flex', flexDirection: 'column' }}>
                                                            <Form className='theme-form login-form' onSubmit={e => { e.preventDefault(); handleSubmit(); }} style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                                                <H4 attrH4={{ style: { color: '#FF5757', textAlign: 'center' } }}>Validate your account</H4>
                                                                <P>Enter your 6-digit One Time Passcode that was sent to your registered email address.</P>
                                                                <P attrPara={{ style: { fontSize: '14px', fontWeight: '400', textAlign: 'center', textDecorationLine: 'underline' } }}>{maskEmail(location?.state?.email ? location?.state?.email : email)}</P>
                                                                <FormGroup>
                                                                    <OTPInput
                                                                        value={otp}
                                                                        // onChange={setOtp}
                                                                        onChange={(e) => {
                                                                            setOtp(e)
                                                                            setOtpErrors('')
                                                                        }}
                                                                        numInputs={6}
                                                                        separator={<span>-</span>}
                                                                        renderInput={(props) => <input {...props} />}
                                                                        inputStyle={{
                                                                            width: '3rem',
                                                                            height: '3rem',
                                                                            margin: '0 0.4rem',
                                                                            fontSize: '2rem',
                                                                            borderRadius: 4,
                                                                            // border: '1px solid rgba(0,0,0,0.3)'
                                                                            backgroundColor: otp ? '#5979D4' : '#F3F6FF',
                                                                            border: '1px solid rgba(86, 121, 213, 0.2)',
                                                                            color: otp ? 'white' : '',
                                                                        }}
                                                                        containerStyle={{
                                                                            justifyContent: 'center',
                                                                            marginBottom: '1rem'
                                                                        }}
                                                                    />
                                                                    {errors.otp && <div className='text-danger'>{errors.otp}</div>}

                                                                </FormGroup>

                                                                <FormGroup style={{ width: '100%' }}>
                                                                    <Button
                                                                        color='primary'
                                                                        disabled={isSubmitting}
                                                                        // attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit', disabled: isSubmitting || otp.join('').length !== 6 }}
                                                                        style={{ width: '100%', backgroundColor: '#FF5757', border: 'none' }}
                                                                    >
                                                                        {isSubmitting ? 'Verifying...' : 'Verify'}
                                                                    </Button>
                                                                    {otpErrors && <div className='text-danger' style={{ marginLeft: '15px' }}>{otpErrors}</div>}
                                                                </FormGroup>

                                                                {!location?.state?.email &&
                                                                    <>
                                                                        <FormGroup className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <span className='reset-password-link'>
                                                                                Didn't receive code?{' '}
                                                                            </span>
                                                                            { }
                                                                            <a
                                                                                onClick={handleResend}
                                                                                disabled={isResendDisabled || countdown > 0}
                                                                                style={{ color: isResendDisabled || resendAttempts >= 3 ? 'gray' : '#FF5757', textDecorationLine: 'underline', cursor: 'pointer', fontWeight: '500', marginLeft: '5px' }}
                                                                            >
                                                                                {'Resend'}
                                                                            </a>
                                                                        </FormGroup>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
                                                                            {countdown > 0 &&
                                                                                <a
                                                                                    onClick={handleResend}
                                                                                    disabled={isResendDisabled || countdown > 0}
                                                                                    style={{ color: isResendDisabled || resendAttempts >= 3 ? 'gray' : '#FF5757', cursor: 'pointer', fontWeight: '500', width: countdown ? '110px' : '60px', marginLeft: '5px', textAlign: 'right' }}
                                                                                >
                                                                                    {`Resend in ${countdown}s`}
                                                                                </a>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </Form>
                                                        </Card>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </Fragment >
    );
};

export default OTPView;
