import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Row, FormGroup, Label, Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { useFormik } from "formik";
import * as Yup from "yup";
import { createCompanyData, getAllCompanyPackages, getAllCompanyTypes, getAllCountries, getStates } from "../../api/Company";
import '../Company/CreateCompany.css';
import '../ManageAssistant/ManageAssistant.css'
import '../ManageDirectory/styles.css'
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import ApiContext from "../../ApiContext";
import { getUserCompany } from '../../api/AuthIndex';


const CreateCompany = () => {
  const { isUpdated, setUpdateStatus, setAllCompanies, setSelectCompany } = useContext(ApiContext);
  const location = useLocation();
  const { goto } = location.state || {};
  let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
  const theme = localStorage.getItem("mix_background_layout");
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [companyPackages, setCompanyPackages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('auth0_profile'));
  const token = localStorage.getItem('token');

  useEffect(() => {
    getCountry();
    getCompanyTypes();
    getCompanyPackages()
  }, []);

  const getState = async (countryId) => {
    const res = await getStates(countryId);
    setStates(res.states);
  };

  useEffect(() => {
    if (selectedCountry) {
      setStates([]);
      getState(selectedCountry);
    }
  }, [selectedCountry]);

  const getCountry = async () => {
    let res = await getAllCountries();
    setCountries(res.countries);
  };

  const getCompanyTypes = async () => { 
    try {
      let res = await getAllCompanyTypes(token);
      setCompanyTypes(res.cType);
    } catch (error) {
      console.log("🚀 ~ getCompanyTypes ~ error:", error)
    }
  };

  const getCompanyPackages = async () => {
    try {
      let res = await getAllCompanyPackages(token);
      const payAsYouGoPackage = res.packages.filter(pkg => pkg.package_name === 'Pay so you go');
      setCompanyPackages(payAsYouGoPackage);
    } catch (error) {
      console.log("🚀 ~ getCompanyTypes ~ error:", error)
    }
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const getUserCompanyInfo = async () => {
    let res = await getUserCompany(token, userData?.id);
    localStorage.setItem("companies", JSON.stringify(res.Company));
    setAllCompanies(res.Company)
  };

  const formik = useFormik({
    initialValues: {
      company_name: "",
      email: "",
      address: "",
      address2: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      website: "",
      company_intro: "",
      products_and_services: "",
      package: "",
      official_phone: "",
      upload_logo: null,
      company_type: ''
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Company name is required."),
      email: Yup.string()
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address.")
        .required("Email is required."),
      address: Yup.string().required("Address is required."),
      address2: Yup.string().required("Address 2 is required."),
      country: Yup.string().required("Country is required."),
      state: Yup.string().required("State is required."),
      city: Yup.string().required("City is required."),
      zip: Yup.string().required("Zip Code is required."),
      website: Yup.string().url("Invalid website URL.").required("Website is required."),
      company_intro: Yup.string().required("Company Introduction is required."),
      products_and_services: Yup.string().required("Products and Services are required."),
      package: Yup.string().required("Package is required."),
      official_phone: Yup.string().required("Phone is required."),
      upload_logo: Yup.mixed().required("Company Logo is required.")
    }),
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true)
      const formData = new FormData();
      // formData.append('company_id', formdata?.id);
      formData.append('company_name', values?.company_name);
      formData.append('email', values?.email);
      formData.append('introduction', values?.company_intro);
      formData.append('website', values?.website);
      console.log(values?.upload_logo);
      formData.append('upload_logo', values?.upload_logo) // Use uploadLogo state
      formData.append('official_phone', values?.official_phone);
      formData.append('package', values?.package);
      formData.append('country', values?.country);
      formData.append('city', values?.city);
      formData.append('zip', values?.zip);
      formData.append('state', values?.state);
      formData.append('address', values?.address);
      formData.append('address2', values?.address2);
      formData.append('products_and_services', values?.products_and_services);
      formData.append('type', values?.company_type);
      formData.append('info', 'Contact Us anytime');
      formData.append('status', 'Inactive-Payment');

      const packageStartDate = moment().format('YYYY-MM-DD');
      const packageEndDate = moment().add(30, 'days').format('YYYY-MM-DD');

      formData.append('package_start', packageStartDate);
      formData.append('package_end', packageEndDate);
      formData.append('last_invoice_date', packageEndDate);

      // let uploadData = {}

      try {
        const res = await createCompanyData(formData, token);
        console.log("🚀 ~ onSubmit: ~ res:", res)
        setUpdateStatus(!isUpdated)
        localStorage.setItem("companyData", JSON.stringify(res?.company[0]));
        setSelectCompany(res?.company[0])

        if (res) {
          setLoading(false)
          toast.info("Company Created Successfully", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'custom-toast',
          });

          getUserCompanyInfo();

          navigate(`${process.env.PUBLIC_URL}/app/package`);
        }
      } catch (error) {
        setLoading(false)
      }


    },
  })

  const handleFileChange = (event) => {
    console.log(event.currentTarget.files[0], "870987908908089080980");
    formik.setFieldValue("upload_logo", event.currentTarget.files[0]);
  };

  return (
    <Fragment>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <Row style={{ padding: goto ? '40px 20px 20px 20px' : '' }}>
        <Col sm="12">
          <h4>Create Company</h4>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="company_name" >Company Name</Label>
                  <input
                    className={`form-control ${formik.touched.company_name && formik.errors.company_name ? "is-invalid" : ""}`}
                    id="company_name"
                    type="text"
                    name="company_name"
                    placeholder="Enter Company Name"
                    {...formik.getFieldProps('company_name')}
                  />
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <span className="text-danger">{formik.errors.company_name}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="email" >Email</Label>
                  <input
                    className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span className="text-danger">{formik.errors.email}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="official_phone" >Phone Number</Label>
                  <div className={`phone-input-container ${theme === 'dark-only' ? 'dark-theme-phone-input' : ''}`}>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="US"
                      value={phone}
                      onChange={(value) => {
                        setPhone(value);
                        formik.setFieldValue('official_phone', value);
                      }}
                      className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                      onBlur={() => formik.setFieldTouched('official_phone', true)}
                      countries={['US', 'CA']}
                      limitMaxLength={12}
                    />
                  </div>
                  {formik.touched.official_phone && formik.errors.official_phone ? (
                    <span className="text-danger">{formik.errors.official_phone}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="address" >Address</Label>
                  <input
                    className={`form-control ${formik.touched.address && formik.errors.address ? "is-invalid" : ""}`}
                    id="address"
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <span className="text-danger">{formik.errors.address}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="address2" >Address 2</Label>
                  <input
                    className={`form-control ${formik.touched.address2 && formik.errors.address2 ? "is-invalid" : ""}`}
                    id="address2"
                    type="text"
                    name="address2"
                    placeholder="Enter Address 2"
                    {...formik.getFieldProps('address2')}
                  />
                  {formik.touched.address2 && formik.errors.address2 ? (
                    <span className="text-danger">{formik.errors.address2}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="country" >Country</Label>
                  <select
                    className={`form-control ${formik.touched.country && formik.errors.country ? "is-invalid" : ""}`}
                    id="country"
                    name="country"
                    {...formik.getFieldProps('country')}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setSelectedCountry(e.target.value);
                    }}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                  </select>
                  {formik.touched.country && formik.errors.country ? (
                    <span className="text-danger">{formik.errors.country}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="state" >State</Label>
                  <select
                    className={`form-control ${formik.touched.state && formik.errors.state ? "is-invalid" : ""}`}
                    id="state"
                    name="state"
                    {...formik.getFieldProps('state')}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.code}>{state.name}</option>
                    ))}
                  </select>
                  {formik.touched.state && formik.errors.state ? (
                    <span className="text-danger">{formik.errors.state}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="city" >City</Label>
                  <input
                    className={`form-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""}`}
                    id="city"
                    type="text"
                    name="city"
                    placeholder="Enter City"
                    {...formik.getFieldProps('city')}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <span className="text-danger">{formik.errors.city}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="zip" >Zip Code</Label>
                  <input
                    className={`form-control ${formik.touched.zip && formik.errors.zip ? "is-invalid" : ""}`}
                    id="zip"
                    type="text"
                    name="zip"
                    maxLength={5}
                    placeholder="Enter Zip Code"
                    {...formik.getFieldProps('zip')}
                  />
                  {formik.touched.zip && formik.errors.zip ? (
                    <span className="text-danger">{formik.errors.zip}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="company_type" >Company Type</Label>
                  <select
                    className={`form-control ${formik.touched.company_type && formik.errors.company_type ? "is-invalid" : ""}`}
                    id="company_type"
                    name="company_type"
                    {...formik.getFieldProps('company_type')}
                    onChange={(e) => {
                      formik.handleChange(e);
                      // setSelectedType(e.target.value);
                    }}
                  >
                    <option value="">Select Type</option>
                    {companyTypes.map((item) => (
                      <option key={item.id} value={item.id}>{item.type}</option>
                    ))}
                  </select>
                  {formik.touched.company_type && formik.errors.company_type ? (
                    <span className="text-danger">{formik.errors.company_type}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="upload_logo" >Company Logo</Label>
                  <input
                    className={`form-control ${formik.touched.upload_logo && formik.errors.upload_logo ? "is-invalid" : ""}`}
                    id="upload_logo"
                    type="file"
                    name="upload_logo"
                    onChange={handleFileChange}
                  />
                  {formik.touched.upload_logo && formik.errors.upload_logo ? (
                    <span className="text-danger">{formik.errors.upload_logo}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="website" >Website</Label>
                  <input
                    className={`form-control ${formik.touched.website && formik.errors.website ? "is-invalid" : ""}`}
                    id="website"
                    type="text"
                    name="website"
                    placeholder="Enter Website"
                    {...formik.getFieldProps('website')}
                  />
                  {formik.touched.website && formik.errors.website ? (
                    <span className="text-danger">{formik.errors.website}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="company_intro" >Company Introduction</Label>
                  <textarea
                    className={`form-control ${formik.touched.company_intro && formik.errors.company_intro ? "is-invalid" : ""}`}
                    id="company_intro"
                    name="company_intro"
                    placeholder="Enter Company Introduction"
                    {...formik.getFieldProps('company_intro')}
                  ></textarea>
                  {formik.touched.company_intro && formik.errors.company_intro ? (
                    <span className="text-danger">{formik.errors.company_intro}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="products_and_services" >Products and Services</Label>
                  <textarea
                    className={`form-control ${formik.touched.products_and_services && formik.errors.products_and_services ? "is-invalid" : ""}`}
                    id="products_and_services"
                    name="products_and_services"
                    placeholder="Enter Products and Services"
                    {...formik.getFieldProps('products_and_services')}
                  ></textarea>
                  {formik.touched.products_and_services && formik.errors.products_and_services ? (
                    <span className="text-danger">{formik.errors.products_and_services}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="package" >Package</Label>
                  <select
                    className={`form-control ${formik.touched.package && formik.errors.package ? "is-invalid" : ""}`}
                    id="package"
                    name="package"
                    {...formik.getFieldProps('package')}
                  >
                    <option value="">Select Package</option>
                    {companyPackages?.map((item) => {
                      return (
                        <option value={item?.id}>{item?.package_name}</option>
                      )
                    })}

                  </select>
                  {formik.touched.package && formik.errors.package ? (
                    <span className="text-danger">{formik.errors.package}</span>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="12">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {loading ?
                    <Button disabled={loading}>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Submiting...
                    </Button>
                    :
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  }

                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CreateCompany;
