import {FEATURE, PACKAGE_TYPE, packageLimits} from "./constants";
import {isEmpty} from "./utils";


const checkPayAsYouGoFeature = (params) => {
    const {featureName, existingQuantity} = params;

    switch (featureName) {
        case FEATURE.ASSISTANTS:
            return Number(existingQuantity) < Number(packageLimits[PACKAGE_TYPE.PAY_AS_YOU_GO][FEATURE.ASSISTANTS])
            break;
        case FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS:
            return Number(existingQuantity) < Number(packageLimits[PACKAGE_TYPE.PAY_AS_YOU_GO][FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS])
            break;
        default:
            return false;
    }
}

const checkStartupFeature = (params) => {
    const {featureName, existingQuantity} = params;

    switch (featureName) {
        case FEATURE.ASSISTANTS:
            return Number(existingQuantity) < Number(packageLimits[PACKAGE_TYPE.STARTUP][FEATURE.ASSISTANTS])
            break;
        case FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS:
            return true; // unlimited
            break;
        default:
            return false;
    }
}

const checkProFeature = (params) => {
    const {featureName, existingQuantity} = params;

    switch (featureName) {
        case FEATURE.ASSISTANTS:
            return Number(existingQuantity) < Number(packageLimits[PACKAGE_TYPE.PRO][FEATURE.ASSISTANTS])
            break;
        case FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS:
            return true; // unlimited
            break;
        default:
            return false;
    }
}

export const hasFeatureAccess = (params) => {
    const {featureName, packageType, existingQuantity} = params;

    if(isEmpty(packageType)) {
        return false;
    }

    switch (packageType?.toString()) {
        case PACKAGE_TYPE.PAY_AS_YOU_GO:
            return checkPayAsYouGoFeature({featureName, packageType, existingQuantity});
            break;
        case  PACKAGE_TYPE.STARTUP:
            return checkStartupFeature({featureName, packageType, existingQuantity});
            break;
        case   PACKAGE_TYPE.PRO:
            return checkProFeature({featureName, packageType, existingQuantity});
            break;
    }
}