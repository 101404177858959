import {Client, TokenProvider} from '@pusher/push-notifications-web';


export const requestNotificationPermissions = () => {
    const userData = JSON.parse(localStorage.getItem('auth0_profile'));

    if(userData?.id) {
        const beamsTokenProvider = new TokenProvider({
            url: `${process.env.REACT_APP_API_URL}/pusher/beams-auth`,
            queryParams: {
                user_id: userData?.id, // URL query params your auth endpoint needs
            },
            headers: {
                Authorization: "Bearer " +  localStorage.getItem('token'), // Headers your auth endpoint needs
            },
        });

        const beamsClient = new Client({
            instanceId: '5e3f14fe-c69a-42a7-a958-285849fa6892',
        });
        beamsClient
            .start()
            .then(() => beamsClient.setUserId(userData?.id?.toString(), beamsTokenProvider))
            .then(() => console.log('Push notifications has been setup.'))
            .catch(console.error);
    }
}
