import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import {NoAssistants} from "./NoAssistants/NoAssistants";
import {selectCompleteCompany} from "../../Store/modules/chat/companySelectors";
import {useDispatch, useSelector} from 'react-redux';
import {AssistantList} from "./AssistantList/AssistantList";
import {CreateAssistant} from "./CreateAssistant/CreateAssistant";
import {AssistantDetail} from "./AssistantList/AssistantDetail";
import {EditAssistant} from "./EditAssistant/EditAssistant";
import {AI_ASSISTANT_RIGHT_SIDEBAR} from "../../helpers/constants";
import {selectRightSideBarContent} from "../../Store/modules/assistant/assistantSelectors";
import {useEffect} from "react";
import {ActionTypes} from "../../Store/redux-store/ActionTypes";
import {getCurrentCompany} from "../../helpers/LocalStorageHelper";


export const AiAssistants = () => {
    const dispatch = useDispatch();
    const rightSideBarContent = useSelector(selectRightSideBarContent);
    const completeCompany = useSelector(selectCompleteCompany);
    const assistantCount = completeCompany?.company?.company?.assistants;

    const RightSidebar = ({type}) => {
        switch (type) {
            case AI_ASSISTANT_RIGHT_SIDEBAR.CREATE_ASSISTANT:
                return <CreateAssistant/>;
            case  AI_ASSISTANT_RIGHT_SIDEBAR.EDIT_ASSISTANT:
                return <EditAssistant/>;
            case  AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL:
                return <AssistantDetail/>;
            default:
                return <div>Right sidebar content here please</div>;
        }
    };

    useEffect(() => {
        const company = getCurrentCompany();
        dispatch({type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: {company_id: company?.id}});
    }, []);

    return (
        <Container fluid className="rubik-font min-vh-100"
                   style={{padding: "1em", paddingTop: '2em', paddingBottom: '2em'}}>
            <Card className="w-100" style={{minHeight: 'inherit'}}>
                {/* if there will be assistants we will use a different cardbody with 0 padding and margins for edit and details purposes  */}
                {assistantCount > 0 && rightSideBarContent !== AI_ASSISTANT_RIGHT_SIDEBAR.CREATE_ASSISTANT ? (
                    <CardBody style={{margin: 0, padding: 0, minHeight: 'inherit'}} className={'h-100'}>
                        <Row style={{minHeight: 'inherit'}}>
                            <Col sm={4} style={{minHeight: 'inherit', margin: 0, padding: 0}}>
                                <Card style={{minHeight: '100%', margin: 0}}>
                                    <CardBody style={{
                                        minHeight: 'inherit',
                                        overflow: 'scroll',
                                        padding: '0px',
                                        boxShadow: 'none',
                                        backgroundColor: '#F6F8FF'
                                    }}>
                                        <AssistantList/>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={8}>
                                <RightSidebar type={rightSideBarContent}/>
                            </Col>
                        </Row>
                    </CardBody>
                ) : (
                    <CardBody style={
                        rightSideBarContent === AI_ASSISTANT_RIGHT_SIDEBAR.CREATE_ASSISTANT
                            ? {margin: 0, padding: 0}
                            : {}
                    }>   {/* if there will be no assistants or user is not viewing or editing an assistant,  we will use a different card body with default margin and paddings*/}
                        <Row>
                            {rightSideBarContent === AI_ASSISTANT_RIGHT_SIDEBAR.CREATE_ASSISTANT ? (
                                <CreateAssistant/>
                            ) : (
                                <NoAssistants/>
                            )}
                        </Row>
                    </CardBody>
                )}
            </Card>
        </Container>
    );
}