import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { H4, P } from '../AbstractElements';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import { ToastContainer } from "react-toastify";
import '../component2/ManageAssistant/ManageAssistant.css';
import { MdCancel } from "react-icons/md";

const TermsCondition = ({ logoClassMain }) => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                    minHeight: '100vh',
                }}>
                <Container className='p-0 login-page' fluid={true}>
                    <Row className='m-0'>
                        <Link
                            style={{ marginTop: '1%' }}
                            className={`logo ${logoClassMain ? logoClassMain : ''}`}
                            to={process.env.PUBLIC_URL}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '10px 0px 10px 0px'
                                }}>
                                <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                            </div>
                        </Link>
                        <Col className='p-0'>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '2%',
                                }}>
                                <Card style={{ width: '1000px', padding: '0px', backgroundColor: 'white' }}>
                                    <CardBody style={{ padding: '20px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div></div>
                                            <H4 attrH4={{ style: { margin: 0, color: '#FC5455' } }}>Terms & Condition</H4>
                                            <MdCancel style={{ cursor: 'pointer' }} size={24} color='#FC5455' onClick={() => navigate(`${process.env.PUBLIC_URL}/create-account`)} />
                                        </div>
                                        <P attrPara={{ style: { marginTop: '10px' } }} >PLEASE READ ALL OF THE FOLLOWING TERMS OF SERVICE (“TOS”) FOR THE BLOOMBERG INDUSTRY GROUP SUBSCRIPTION PRODUCTS AND THE WEB SITES ON WHICH THEY ARE LOCATED (each, a “SITE,” and together, the “SITE”) BEFORE USING THIS SITE. By continuing to access or use this Site, or any product or service on this Site, you signify YOUR ACCEPTANCE OF THE TOS. Bloomberg Industry Group, Inc. (“Bloomberg Industry Group”) reserves the right to amend, remove, or add to the TOS at any time. Such modifications shall be effective immediately. Accordingly, please continue to review the TOS whenever using this Site. Your access to or use of this Site, or any service on this Site, after the posting of modifications to the TOS will constitute YOUR ACCEPTANCE OF THE TOS, as modified. If, at any time, you do not wish to accept the TOS, you may not access or use this Site. Any terms and conditions proposed by you which are in addition to or which conflict with the TOS are expressly rejected by Bloomberg Industry Group and shall be of no force or effect.</P>
                                        <P attrPara={{ style: { lineHeight: 1 } }} >For general use of the Site, please see the terms located at fluten.ai</P>
                                        <H4 attrH4={{ style: { margin: 0, fontSize: '16px' } }}>A. GENERAL TERMS</H4>
                                        <P attrPara={{ style: {} }} >Definitions: <br /> Service: “Service” means any of the paid subscription publications and products available on the Site in one of the Bloomberg Industry Group Practice Areas, including, for example, but not limited to, the products currently known as Bloomberg Law, Bloomberg Government, Bloomberg Tax, Bloomberg Environment, as well as the information, data, materials, software, functionality, services, and content contained therein.</P>
                                        <H4 attrH4={{ style: { margin: 0, fontSize: '16px' } }}>Bloomberg Industry Group Practice Area: <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: 1.8 }}>“Bloomberg Industry Group Practice Area” means the subject area of practice for the professionals to whom the Services are directed, and which are comprised of, but are not necessarily limited to: Legal and Business; Government, Tax and Accounting; Environment, Health and Safety; and Human Resources.</span> </H4>
                                        <H4 attrH4={{ style: { margin: 0, fontSize: '16px', marginTop: '13px' } }}>License: <span style={{ fontWeight: '400', fontSize: '14px', lineHeight: 1.8 }}>“License” means a current and valid license to access one or more Service on the Site, and which may include purchased subscription to one or more Service for one or more Users associated with a Licensee, a trial subscription to a Service (or beta version thereof) granted for one or more Users associated with a Licensee, or any other valid and authorized right/license.</span> </H4>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </Fragment>
    );
};

export default TermsCondition;
