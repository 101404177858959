import React from "react";
import { Progress } from "reactstrap";
import { LI, UL, H5 } from "../../../../AbstractElements";
const SocialData = ({ completed, noAnswer, busy }) => {
  console.log("🚀 ~ SocialData ~ failed:", busy);
  console.log("🚀 ~ SocialData ~ noAnswer:", noAnswer);
  console.log("🚀 ~ SocialData ~ completed:", completed);
  return (
    <>
      <h6>Total Calls: {completed?.sumOfCalls}</h6>
      <div>
        <p style={{ fontSize: "14px", fontWeight: "500" }}>
          Failed: {noAnswer?.average}%
        </p>
        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "-10px" }}>
          Successful: {completed?.average}%
        </p>
        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "-10px" }}>
          Busy: {busy?.average}%
        </p>
      </div>
      <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
        Calls Success Ratio: {completed?.average}%
      </p>
      <Progress
        className="sm-progress-bar"
        striped
        color="primary"
        value={completed?.average}
        style={{ height: "5px" }}
      />
    </>
    // <UL attrUL={{ className: 'social-follow dC' }}>
    //   <LI>
    //     <p>{completed.total_calls}</p>
    //     <span className='f-light'>Completd</span>
    //   </LI>
    //   <LI>
    //     <H5 attrH5={{ className: 'mb-0' }}>{failed.this_status} / {failed.total_calls}</H5>
    //     <span className='f-light'>Failed</span>
    //   </LI>
    // </UL>
  );
};

export default SocialData;
