import { call, put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../redux-store/ActionTypes";
import {upgradePackage} from "./upgradePackage";
import {upgradePackageError, upgradePackageSuccess, upgradingPackage} from "./packageSlice";
import {showToast} from "../../../helpers/ToastHelper";
import {TOAST_TYPES} from "../../../helpers/constants";
import {getCurrentCompany} from "../../../helpers/LocalStorageHelper";

const companyData = getCurrentCompany();
function* upgradePackageSaga(action) {
    try {
        yield put(upgradingPackage(action.payload));
        const data = yield call(upgradePackage, action.payload);
        yield put({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: companyData?.id } });
        yield put(upgradePackageSuccess(data));
        showToast({message: data?.message});
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(upgradePackageError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* upgradeUserPackage() {
    yield takeLatest(ActionTypes.UPGRADE_PACKAGE, upgradePackageSaga);
}


