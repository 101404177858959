import { isEmpty } from "./utils";
import { JsonDecode } from "./JsonHelper";

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getUserProfile = () => {
  const profile = getLocalStorage("auth0_profile");
  return JsonDecode(profile);
};

export const getCurrentCompany = () => {
  const companyData = getLocalStorage("companyData");
  return JsonDecode(companyData);
};

export const getCurrentUser = () => {
  const profile = getLocalStorage("auth0_profile");
  return JsonDecode(profile);
};