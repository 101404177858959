import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Btn, H4, P, H6, Image } from '../AbstractElements';
import { Card, Form as ReactstrapForm, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Facebook, Linkedin, Twitter } from 'react-feather';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import logoWhite from '../assets/images/logo/logo.png';
import roboImg from '../assets/images/logo/roboImg.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import CustomizerContext from '../_helper/Customizer';
import man from "../assets/images/dashboard/profile.png";
import { ToastContainer, toast } from "react-toastify";
import { loginUser } from '../api/AuthIndex';
import { CardBody, Col, Spinner } from 'react-bootstrap';
import { getUserCompany } from "../api/AuthIndex"
import '../component2/ManageAssistant/ManageAssistant.css'
import ApiContext from '../ApiContext';
import tickCircle from '../assets/images/logo/tickCircle.png';
import { FcGoogle } from "react-icons/fc";
import { GoogleSignIn } from './GoogleAuth';
import { useCompany } from '../Store/providers/Company/useCompany';
import { useCompanyContext } from "../Store/providers/Company/CompanyProvider";
import { ActionTypes } from "../Store/redux-store/ActionTypes";

// Validation schema for the form
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  password: Yup.string().required('Password is required.')
});

const LoginForm = ({ logoClassMain }) => {
  const navigate = useNavigate();
  const { setAllCompanies } = useContext(ApiContext)
  const { layoutURL } = useContext(CustomizerContext);

  const [loading, setLoading] = useState();
  const [togglePassword, setTogglePassword] = useState(false);
  const [companies, setCompanies] = useState([])

  const [value, setValue] = useState(localStorage.getItem("profileURL") || man);
  const [name, setName] = useState(localStorage.getItem("Name"));
  const theme = localStorage.getItem("mix_background_layout")

  let getUserCompanyInfo = async (token, id) => {
    let res = await getUserCompany(token, id);
    localStorage.setItem("companyStatus", JSON.stringify(res.companyStatus))
  }
  const dispatch = useDispatch();

  const loginAuth = async (values) => {
    setLoading(true)
    try {
      const res = await loginUser(values)

      if (res.user) {
        localStorage.setItem("companies", JSON.stringify(res.companies))
        localStorage.setItem("companyData", JSON.stringify(res?.companies[0]))
        // localStorage.setItem("companyStatus", JSON.stringify(res.companyStatus))
        setCompanies(res.companies)
        setAllCompanies(res.companies)
        await getUserCompanyInfo(res.token, res.companies[0].id);
        setValue(man);
        setName(res.user.name);
        localStorage.setItem("profileURL", man);
        localStorage.setItem("Name", res.user.name);
        localStorage.setItem("token", res.token);
        localStorage.setItem("auth0_profile", JSON.stringify(res.user));
        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem("authenticated", JSON.stringify(true));
        localStorage.setItem("tokken", res.token);

        if (res.companyCount === 1) {
          // handleGetUserCompleteCompany()
          dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: res.companies[0].id } });


          navigate(`${process.env.PUBLIC_URL}/app/dashboard`);
        } else {
          navigate(`${process.env.PUBLIC_URL}/app/companies`);
        }
        toast.info("Successfully logged in!..", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: 'custom-toast',
        });
      } else {
        toast.error("User Email Is not Verfied", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: 'custom-toast-error',
        });
      }
      setLoading(false)
    } catch (error) {
      console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error)
      toast.error(error?.response?.data?.error, {
        position: 'top-left',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: 'custom-toast-error',
      });
      if (error?.response?.data?.message == 'Unauthenticated') {
        toast.error("Email or Password is invalid", {
          position: 'top-left',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: 'custom-toast-error',
        });
      }

      if (error?.response?.data?.message == 'Your account is connected to google. Please sign in with Google') {
        toast.error("Your account is connected to google. Please sign in with Google", {
          position: 'top-left',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: 'custom-toast-error',
        });
      }

      setLoading(false)
    }

  };

  return (
    <Fragment>
      <div
        style={{
          backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
          backgroundSize: 'cover', // Adjusts the image to cover the entire area
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          display: 'block',
          minHeight: '100vh',
        }}>
        <div>

          <Row>
            <Link style={{ marginTop: '1%' }} className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
              </div>
            </Link>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '2%',
              }}>

              <Card style={{ width: '1000px', padding: '0px' }}>
                <CardBody style={{ padding: '0px' }}>
                  <Row>
                    <Col sm='6' style={{ position: 'relative', overflow: 'hidden' }}>
                      <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                      <div style={{ position: 'relative', zIndex: 2, display: 'flex', padding: '20px 0px 0px 20px' }}>
                        <div style={{ marginTop: '8px' }}>
                          <H4>Welcome to Fluten!</H4>
                          <div style={{ display: 'flex', marginTop: '25px' }}>
                            <P attrPara={{ style: { fontSize: '18px' } }} >Transform the way you serve your customers with Fluten’s innovative AI Assistant solutions. By signing up or logging into your Fluten account, you'll unlock full control over your AI Assistant, empowering you to deliver exceptional customer service effortlessly.</P>
                          </div>
                          {/* <div style={{ display: 'flex', marginTop: '15px' }}>
                            <P attrPara={{ style: { fontSize: '18px' } }} >Sign up or log in to gain full control of your AI assistant. </P>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div style={{ padding: '30px 20px 0px 0px' }}>
                        <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px' }}>
                          <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            onSubmit={loginAuth}
                          >
                            {({ values, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
                              <Form className='theme-form login-form'>
                                <H4 attrH4={{ style: { color: '#FC5455' } }}>Sign in</H4>
                                {/* <P>Enter your email & password to login</P> */}
                                <FormGroup>
                                  <Label className='col-form-label m-0'>Email Address</Label>
                                  <Field
                                    className='form-control'
                                    type='email'
                                    name='email'
                                    placeholder='Test@gmail.com'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />
                                  <div style={{ height: '20px' }}>
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                  </div>
                                </FormGroup>
                                <FormGroup className='position-relative'>
                                  <Label className='col-form-label m-0'>Password</Label>
                                  <div className='position-relative'>
                                    <Field
                                      className='form-control'
                                      type={togglePassword ? 'text' : 'password'}
                                      name='password'
                                      placeholder='*********'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                    <div style={{ height: '45%' }} className='position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer' onClick={() => setTogglePassword(!togglePassword)}>
                                      {togglePassword ? <EyeOff size={20} style={{ marginTop: '-10px', cursor: 'pointer' }} /> : <Eye size={20} style={{ marginTop: '-10px', cursor: 'pointer' }} />}
                                    </div>
                                    <div style={{ height: '20px' }}>
                                      <ErrorMessage name="password" component="div" className="text-danger" />
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup className='position-relative' style={{ marginTop: '-10px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {/* <div className='checkbox'>
                                      <Input id='checkbox1' type='checkbox' />
                                      <Label className='text-muted' for='checkbox1'>
                                        Remember password
                                      </Label>
                                    </div> */}
                                    <Link className='link'
                                      to={`${process.env.PUBLIC_URL}/forgotpassword`}
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                </FormGroup>
                                <FormGroup>
                                  <div style={{ display: 'flex' }}>
                                    {loading ?
                                      <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'button', disabled: isSubmitting }}>
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        Loading...
                                      </Btn>
                                      :
                                      <Btn attrBtn={{ className: 'd-block w-100 mt-2', color: 'primary', type: 'submit', disabled: isSubmitting }}>Sign In</Btn>
                                    }
                                  </div>
                                </FormGroup>
                                <div className='login-social-title' style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                  <H6 attrH6={{ className: 'text-muted or' }}>OR</H6>
                                </div>

                                <div className='social' style={{ display: 'flex', justifyContent: 'center' }}>
                                  {/* <div className='btn btn-light' style={{ display: 'flex', justifyContent: 'center', width: '95%', marginLeft: '10px' }}>
                                    <a
                                      // href='https://www.facebook.com/'
                                      rel='noreferrer'
                                      target='_blank'
                                      style={{ fontSize: '11px', marginTop:'5px' }}
                                    >
                                      <FcGoogle size={18} />
                                    </a>
                                    <p style={{ marginLeft: '5px', marginTop: '2px' }}>Google</p>
                                  </div> */}
                                  <GoogleSignIn />
                                </div>

                                <P attrPara={{ className: 'text-center mb-0 mt-2 ' }}>
                                  Don’t have an account?
                                  <Link className='ms-2' to={`${process.env.PUBLIC_URL}/create-account`}>
                                    Create new account
                                  </Link>
                                </P>
                              </Form>
                            )}
                          </Formik>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

            </div>
          </Row>
        </div>

      </div >
      <ToastContainer />
    </Fragment >
  );
};

export default LoginForm;
