import { createSlice } from '@reduxjs/toolkit';

const operationSettingsSlice = createSlice({
    name: 'operationSettingsSlice',
    initialState: {
        operationSettings: [],
        loading: false,
        error: null,
    },
    reducers: {
        startGettingOperationalSettings: (state) => {
            state.loading = true;
            state.error = null;
        },
        getOperationalSettingsSuccess: (state, action) => {
            state.loading = false;
            state.operationSettings = action.payload?.data;
        },
        operationSettingsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    startGettingOperationalSettings,
    getOperationalSettingsSuccess,
    operationSettingsError,
} = operationSettingsSlice.actions;

export default operationSettingsSlice.reducer;