import React, { Fragment } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Image } from '../AbstractElements'
import LoginForm from './LoginForm'

const Login = () => {

    return (
        <Fragment>
            <section>
                <Container fluid={true}>
                    <Row>
                        {/* <Col xl='8' className='b-center bg-size order-1'
                            style={{
                                backgroundImage: `url(${require('../assets/images/login/login_side1.png')})`,
                                backgroundSize: 'cover', // Adjusts the image to cover the entire area
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                                display: 'block'
                            }}>
                        </Col> */}
                        <Col xl='12 p-0'>
                            <LoginForm logoClassMain='text-start' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Login