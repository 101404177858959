import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User, DollarSign } from "react-feather";
import { BiDollar } from "react-icons/bi";
import man from "../../../assets/images/dashboard/profile.png";

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import ApiContext from '../../../ApiContext';
import { getCurrentCompany, removeLocalStorage } from "../../../helpers/LocalStorageHelper";
import { isEmpty } from "../../../helpers/utils";
import { ActionTypes } from "../../../Store/redux-store/ActionTypes";

const UserHeader = () => {
  const { setUpdateStatus, setCompanyDetail, setAllCompanies, setCompaniesStatus } = useContext(ApiContext);
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Emay Walter");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const dispatch = useDispatch();

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name") ? localStorage.getItem("Name") : name);
  }, []);

  const companyData = getCurrentCompany();

  const getCompleteCompany = () => {
    const companyId = companyData?.id;
    if (!isEmpty(companyId)) {
      dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: companyId } });
    }
  }

  const Logout = () => {
    setUpdateStatus(false)
    setCompanyDetail(null)
    setAllCompanies(null)
    setCompaniesStatus(null)
    localStorage.removeItem("profileURL");
    localStorage.removeItem("tokken");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("email");
    localStorage.removeItem("Name");
    localStorage.setItem("authenticated", false);
    localStorage.removeItem("companyData");
    localStorage.removeItem("companies");
    localStorage.removeItem("companyInfo");
    localStorage.removeItem("register");
    localStorage.setItem("login", false);
    removeLocalStorage('userNumber')
    // history(`${process.env.PUBLIC_URL}/login`);
    window.location.reload(history(`${process.env.PUBLIC_URL}/login`))
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0" id={'dashboard-step2'}>
      <div className="media profile-media">
        <Image
          attrImage={{
            className: "b-r-10 m-0",
            src: `${authenticated ? auth0_profile?.picture : profile}`,
            alt: "",
          }}
        />
        <div className="media-body">
          <span style={{
            textTransform: 'capitalize',
            maxWidth: '200px',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>{authenticated ? `${auth0_profile?.name}` : name}</span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              overflow: 'hidden',
            }}>
            <P attrPara={{ className: "mb-0 font-roboto", style: { marginLeft: 'auto' } }}>
              {Admin} <i className="middle fa fa-angle-down"></i>
            </P>
          </div>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            // onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile/${layoutURL}`),
            onClick: () => {
              getCompleteCompany();
              UserMenuRedirect(`${process.env.PUBLIC_URL}/app/settings`);
            },
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI> */}
        <LI
          attrLI={{
            onClick: () => {
              getCompleteCompany();
              UserMenuRedirect(`${process.env.PUBLIC_URL}/app/billing`);
            },
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DollarSign />
            <p>Billing</p>
          </div>
        </LI>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
