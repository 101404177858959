// export const formatMobileNumber = (mobileNumber) => {
//     // Remove any non-digit characters except the leading plus
//     const cleanedNumber = mobileNumber.replace(/[^\d+]/g, '');

//     // Check if the cleaned number is in the correct format
//     const regex = /^\+1\d{10}$/; // Assuming the format +1 followed by 10 digits
//     if (!regex.test(cleanedNumber)) {
//         throw new Error('The mobile number must be in the format +18891828414.');
//     }

//     // Extract the parts of the number
//     const countryCode = cleanedNumber.slice(0, 2); // +1
//     const areaCode = cleanedNumber.slice(2, 5); // 889
//     const middlePart = cleanedNumber.slice(5, 8); // 182
//     const lastPart = cleanedNumber.slice(8); // 414

//     // Format the number in the desired pattern
//     const formattedNumber = `${countryCode} (${areaCode}) ${middlePart} ${lastPart}`;

//     return formattedNumber;
// };

export const formatMobileNumber = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    // separate country code (assuming country code is 1-3 digits long)
    let countryCode = '';
    let phoneNumber = currentValue;

    if (cvLength > 10) {
        countryCode = currentValue.slice(0, cvLength - 10);
        phoneNumber = currentValue.slice(cvLength - 10);
    }

    const phoneLength = phoneNumber.length;

    let formattedNumber = '';

    if (!previousValue || value.length > previousValue.length) {
        // returns: "x", "xx", "xxx"
        if (phoneLength < 4) formattedNumber = phoneNumber;
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (phoneLength < 7) formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        else formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    return countryCode ? `+${countryCode} ${formattedNumber}` : formattedNumber;
};

export const getRandomColor = () => {
    const colors = ['bg-color-1', 'bg-color-2', 'bg-color-3', 'bg-color-4', 'bg-color-5'];
    return colors[Math.floor(Math.random() * colors.length)];
};

export const getInitials = (name) => {
    if (!name) return '';
    const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
    return initials.slice(0, 2);
};

export const formatDuration = (seconds) => {
    if (!seconds) return '';

    // Round the seconds to avoid floating point precision issues
    const roundedSeconds = Math.floor(seconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;

    // Pad minutes and seconds to always display two digits
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

export const formatProductsServices = (text) => {
    return text
        .split(',')
        .map(sentence => sentence.trim())
        .join('\n'); // Join with newline instead of commas
};

export const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
};
