import { createSlice } from '@reduxjs/toolkit';

const deleteAssistantSlice = createSlice({
    name: 'deleteAssistantSlice',
    initialState: {
        botVoice: null,
        loading: false,
        error: null,
    },
    reducers: {
        startDeletingAssistant: (state) => {
            state.loading = true;
            state.error = null;
        },
        deleteAssistantSuccess: (state, action) => {
            state.loading = false;
             // action.payload;
        },
        deleteAssistantError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    startDeletingAssistant,
    deleteAssistantSuccess,
    deleteAssistantError,
} = deleteAssistantSlice.actions;

export default deleteAssistantSlice.reducer;