export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
};

export const TOAST_TYPES = {
  SUCCESS: "SUCCESS",
  NOTIFICATION: "NOTIFICATION",
  WARNING: "WARNING",
  DANGER: "DANGER",
};

export const AI_ASSISTANT_RIGHT_SIDEBAR = {
  CREATE_ASSISTANT: "CREATE_ASSISTANT",
  EDIT_ASSISTANT: "EDIT_ASSISTANT",
  ASSISTANT_DETAIL: "ASSISTANT_DETAIL",
};

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
};
export const SHORT_GENDER = {
  MALE: "M",
  FEMALE: "F",
};

export const GENDER_ARR = [
  {
    title: GENDER.MALE, label: GENDER.MALE,
    short_name: SHORT_GENDER.MALE,
    value: SHORT_GENDER.MALE,
  },
  {
    title: GENDER.FEMALE, label: GENDER.FEMALE,
    short_name: SHORT_GENDER.FEMALE,
    value: SHORT_GENDER.FEMALE
  },
];

export const MALE_VOICES = [
  { value: "29vD33N1CtxCmqQRPOHJ", label: "Alex" },
  { value: "TX3LPaxmHKxFdv7VOQHJ", label: "Brian" },
  { value: "ErXwobaYiN019PkySvjV", label: "Kim" },
  { value: "5Q0t7uMcjvnagumLfvZi", label: "Jim" },
  { value: "VR6AewLTigWG4xSOukaG", label: "Chris" },
];

export const FEMALE_VOICES = [
  { value: "AZnzlk1XvdvUeBnXmlld", label: "Nikki" },
  { value: "MF3mGyEYCl7XYWbV9V6O", label: "Olivia" },
  { value: "FGY2WhTYpPnrIDTdsKH5", label: "Hannah" },
  { value: "jsCqWAovK2LkecY7zXl4", label: "Chrissy" },
];

export const BACKGROUND_VOICES = {
  OFFICE: "Office",
  NONE: "None",
};

export const PROVIDERS = {
  TWILIO: "Twilio",
  VONAGE: "Vonage",
};

export const PROVIDERS_ARR = [PROVIDERS.TWILIO, PROVIDERS.VONAGE];

export const BACKGROUND_VOICE_OPTIONS = [
  BACKGROUND_VOICES.OFFICE,
  BACKGROUND_VOICES.NONE,
];

export const BACKGROUND_VOICE_OPTIONS_ARR = [
  {label: BACKGROUND_VOICES.OFFICE, value: BACKGROUND_VOICES.OFFICE},
  {label: BACKGROUND_VOICES.NONE, value: BACKGROUND_VOICES.NONE}
];

export const PACKAGE_TYPE = {
  PAY_AS_YOU_GO: "payg",
  STARTUP: "strtup",
  PRO: "pro",
};

export const FEATURE = {
  ASSISTANTS: "assistants",
  CALLS_TRANSFER_TO_OTHER_NUMBERS: "callTransferToOtherNumbers",
};

export const packageLimits = {
  [PACKAGE_TYPE.PAY_AS_YOU_GO]: {
    [FEATURE.ASSISTANTS]: "1",
    [FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS]: "5",
  },
  [PACKAGE_TYPE.STARTUP]: {
    [FEATURE.ASSISTANTS]: "2",
    [FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS]: "unlimited",
  },
  [PACKAGE_TYPE.PRO]: {
    [FEATURE.ASSISTANTS]: "5",
    [FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS]: "unlimited",
  },
};

export const LANGUAGES_MAP = [
  {
    title: "English (US)",
    lang_name: "en-US",
    flag_name: "us",
  },
  {
    title: "French",
    lang_name: "fr",
    flag_name: "fr",
  },
  {
    title: "Spanish",
    lang_name: "es",
    flag_name: "es",
  },
  {
    title: "German",
    lang_name: "de",
    flag_name: "de",
  },
  {
    title: "Mandarin",
    lang_name: "zh",
    flag_name: "cn",
  },
];

export const CREATE_ASSISTANT_SCREENS = {
  PRECAST_ASSISTANTS_LIST: 1,
  CUSTOM_PRECAST_ASSISTANT_BASIC_INFO: 2,
  CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO: 3,
  CHOOSE_PHONE_PROVIDER: 4,
}

export const debounceTime = 500;

export const STRIPE_PUBLIC_KEY =
    "pk_test_51Q5LPmFvMLQywX8LkHhigcl4fNU6pbTp5hwNsHqjWkWid9j9QHUg4rztSSTFzk6K7HGgrgNwsvT2OJKwG3EMldoD00OE5KjCTb";
  // "pk_test_51Q5LPmFvMLQywX8LkHhigcl4fNU6pbTp5hwNsHqjWkWid9j9QHUg4rztSSTFzk6K7HGgrgNwsvT2OJKwG3EMldoD00OE5KjCTb";
