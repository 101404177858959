import authSaga from "../modules/auth/authSaga";
import {fetchUserCompleteCompany} from "../modules/company/companySaga";
import {upgradeUserPackage} from "../modules/packages/packageSaga";
import {getPaymentMethodData, updatePaymentMethod} from "../modules/stripe/stripeSaga";
import {selectAssistant, setAssistantSidebar} from "../modules/assistant/assistantSaga";
import {getBotVoice} from "../modules/precastBots/botVoice/botVoiceSaga";
import {updateAssistant} from "../modules/assistant/update/updateAssistantSaga";
import {deleteAssistant} from "../modules/assistant/delete/deleteAssistantSaga";
import {getDefaultBots} from "../modules/precastBots/getDefaultBots/defaultBotsSaga";
import {selectPrecastBot} from "../modules/precastBots/precastBotSaga";
import createAssistantForm, {
    createPrecastBot,
    getSelectedBot, setupAssistantPhone
} from "../modules/assistant/create/form/createAssistantFormSaga";
import { fetchCallLogCompany } from "../modules/callLog/callLogSaga";
import { fetchKeywordsAnalysisReportCompany } from "../modules/keywordsAnalysis/keywordsAnalysisSaga";
import {getStripeSession} from "../modules/stripe/customerPortal/stripeSessionSaga";
import {getOperationSettings} from "../modules/settings/operationSettings/operationSettingsSaga";

const packages = [
    upgradeUserPackage(),
    getPaymentMethodData(),
    updatePaymentMethod(),
    getStripeSession()
];

const precastBots = [
    createPrecastBot(),
    getDefaultBots(),
    selectPrecastBot(),
    getBotVoice(),
    getSelectedBot(),
]

const assistants = [
    updateAssistant(),
    deleteAssistant(),
    createAssistantForm(),
    setAssistantSidebar(),
    selectAssistant(),
    setupAssistantPhone(),
];

const company = [
    fetchUserCompleteCompany(),
];

const operationSettings = [
    getOperationSettings(),
]

export const sagas = [
    authSaga(),
    fetchCallLogCompany(),
    fetchKeywordsAnalysisReportCompany(),
    ...operationSettings,
    ...company,
    ...packages,
    ...precastBots,
    ...assistants
];
