import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { IoPlayCircleOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import Vapi from '@vapi-ai/web';
import { getBotRaw } from '../../../api/AuthIndex';
import { formatMobileNumber } from '../../../Constant/GlobalFunctions';
import profileImage from '../../../assets/images/user/bot.png';
import botImageMale from '../../../assets/images/user/botImageMale.jpeg';
import botImageFemale from '../../../assets/images/user/botImageFemale.jpeg';
import callIcon from '../../../assets/images/logo/callIcon.png';
import forwardCallIcon from '../../../assets/images/logo/forwardCallIcon.png';
import './../ManageAssistant.css'
import { H5 } from '../../../AbstractElements';
import { selectSelectedAssistant } from "../../../Store/modules/assistant/assistantSelectors";
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from "../../../Store/redux-store/ActionTypes";
import { AI_ASSISTANT_RIGHT_SIDEBAR } from "../../../helpers/constants";
import { getTitleByLangName } from "../../../helpers/common";
import { DeleteAssistantModal } from "../DeleteAssistantModal/DeleteAssistantModal";

export const AssistantDetail = () => {
    const [botModal, setBotModal] = useState(false);
    const [vapiModal, setVapiModal] = useState(false);
    const [botRawData, setBotRawData] = useState(null);
    const [deleteBotModal, setDeleteBotModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const botData = useSelector(selectSelectedAssistant);

    const vapiRef = useRef(null);

    const theme = localStorage.getItem("mix_background_layout")
    let token = localStorage?.getItem('token')
    const keywordsArray = botData?.keywords ? botData?.keywords?.split(',')?.map(keyword => keyword?.trim()) : []

    const handleEdit = () => {
        dispatch({
            type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
            payload: { sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.EDIT_ASSISTANT }
        });
        dispatch({
            type: ActionTypes.GET_BOT_VOICE_MODEL,
            payload: { text: botData?.first_message, voice: botData?.voice_id }
        });
    };
    const handleDelete = () => {
        setDeleteBotModal(true);
    };

    const handleBotModal = (data) => {
        setBotModal(true);
    };


    let forwardedCallCount = 0;
    let totalCallsCount = 0;
    if (botData.calls && Array.isArray(botData.calls)) {
        botData.calls.forEach(call => {
            if (call.end_reason === "assistant-forwarded-call") {
                forwardedCallCount += call.total_calls;
            }

            if (call.total_calls) {
                totalCallsCount += call.total_calls;
            }
        });
    }

    const handleStartTalking = async (data) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append("bot_id", data?.bot_id);
            formData.append("customer_phone", data?.bot_id);
            const res = await getBotRaw(formData, token);
            setBotRawData(res);
            setLoading(false)
            setBotModal(false);
            setVapiModal(true);
        } catch (error) {
            console.error('Error starting Vapi:', error);
            setLoading(false)
        }
    };

    const handleEndedCall = () => {
        if (vapiRef.current) {
            vapiRef.current.stop();
        }
        setVapiModal(false);
    };

    useEffect(() => {
        if (vapiModal && botRawData) {
            vapiRef.current = new Vapi("83e82473-a8f5-45a9-9607-8bb239c672c3");
            try {
                vapiRef.current.start(botRawData);
            } catch (error) {
                console.error('Error starting Vapi:', error);
            }
        }
    }, [vapiModal, botRawData]);

    return (
        <div style={{ marginTop: '1em', marginRight: '0.9em', marginBottom: '1em' }}>
            <div style={{ display: 'flex', }}>
                <img
                    src={botData?.picture ? botData?.picture : botData.gender === 'M' ? botImageMale : botImageFemale}
                    alt={botData?.bot_name}
                    style={{
                        width: '75px',
                        height: '75px',
                        borderRadius: '50%',
                        // border: '2px solid #eee', // Dark border for the avatar
                        border: '3px solid rgb(226 226 228)',
                        // marginRight: '15px',
                    }}
                />
                <div style={{ width: '100%', marginLeft: '15px' }}>
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <h4 style={{
                            marginBottom: '0px',
                            textAlign: 'left',
                            color: '#FF3464',
                            marginTop: '5px',
                            textTransform: 'capitalize'
                        }}>{botData?.bot_name}</h4>
                        <div style={{ display: 'flex', width: '140px', justifyContent: 'space-between' }} onClick={() => {
                        }}>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    padding: '10px',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px solid rgba(105, 120, 143, 0.3)',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleBotModal(botData)}
                            >
                                <IoPlayCircleOutline size={20} color={theme === 'dark-only' ? 'grey' : '#69788F'}
                                    style={{ cursor: 'pointer' }} />
                            </div>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    padding: '10px',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px solid rgba(105, 120, 143, 0.3)',
                                    cursor: 'pointer'
                                }}
                                onClick={handleEdit}
                            >
                                <FiEdit3 size={20} color={theme === 'dark-only' ? 'grey' : '#69788F'}
                                    style={{ cursor: 'pointer' }} />
                            </div>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    padding: '10px',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px solid rgba(105, 120, 143, 0.3)',
                                    cursor: 'pointer'
                                }}
                                onClick={handleDelete}
                            >
                                <RiDeleteBin6Line size={20} color={'#69788F'} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='someMargin ' style={{ display: 'flex' }}>
                        <p className='divider2' style={{
                            fontSize: '12px',
                            marginBottom: '0px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            color: theme === 'dark-only' ? 'grey' : '#52526C',
                            paddingRight: '0.5em'
                        }}>{formatMobileNumber(botData?.phone_number)}</p>
                        <p className='divider2' style={{
                            fontSize: '12px',
                            marginBottom: '0px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            color: theme === 'dark-only' ? 'grey' : '#52526C',
                            paddingRight: '0.5em',
                            marginLeft: '0.5em'
                        }}>{botData?.gender == 'F' ? "Female" : "Male"}</p>
                        <p className='divider2' style={{
                            fontSize: '12px',
                            marginBottom: '0px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            color: theme === 'dark-only' ? 'grey' : '#52526C',
                            paddingRight: '0.5em',
                            marginLeft: '0.5em'
                        }}>{botData?.purpose}</p>
                        <p style={{
                            fontSize: '12px',
                            marginBottom: '0px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            color: theme === 'dark-only' ? 'grey' : '#52526C',
                            marginLeft: '0.5em',
                            paddingRight: '0.5em'
                        }}>{getTitleByLangName(botData?.language)}</p>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div style={{ borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                            <img style={{ width: '20px', height: '20px', borderRadius: '50px' }} src={callIcon} />
                            <p style={{
                                textAlign: 'left',
                                color: theme === 'dark-only' ? 'grey' : '#5679D5',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>Total Calls :</p>
                            <p style={{
                                textAlign: 'left',
                                color: theme === 'dark-only' ? 'grey' : '#5679D5',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>{totalCallsCount}</p>
                        </div>
                        <div style={{ borderRadius: '5px', display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                            <img style={{ width: '15px', height: '15px' }} src={forwardCallIcon} />
                            <p style={{
                                textAlign: 'left',
                                color: theme === 'dark-only' ? 'grey' : '#FC5455',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>Forwarded :</p>
                            <p style={{
                                textAlign: 'left',
                                color: theme === 'dark-only' ? 'grey' : '#FC5455',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>{forwardedCallCount}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Row>
                <Col sm="9">

                </Col>
                <Col sm='12'>
                    <div class="contact-info" style={{ marginTop: '2em', marginBottom: '3%' }}>
                        <div class="info-item">
                            <h4
                                style={{
                                    color: '#777',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}
                            >Welcome Message</h4>
                            <p
                                style={{
                                    color: '#000', /* Black for main content */
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}
                            >{botData?.first_message}</p>
                        </div>
                        <div class="info-item">
                            <h4
                                style={{
                                    color: '#777',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}
                            >End Conversation</h4>
                            <p
                                style={{
                                    color: '#000', /* Black for main content */
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}
                            >{botData?.end_call_message}</p>
                        </div>
                        <div class="info-item">
                            <h4
                                style={{
                                    color: '#777',
                                    fontSize: '12px',
                                    marginBottom: '5px'
                                }}
                            >End Call Words</h4>
                            <p
                                style={{
                                    color: '#000', /* Black for main content */
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}
                            >{botData?.end_call_phrases}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <div class="contact-info" style={{
                marginTop: '10px',
                marginBottom: '3%',
                padding: '10px',
                backgroundColor: '#f7f9fc',
                borderRadius: '8px',
                width: '100%',
                margin: '0 auto',
            }}>
                <Row>
                    <Col sm="12">
                        <p style={{
                            fontSize: '16px',
                            marginBottom: '0px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            color: theme === 'dark-only' ? '#1D1E26' : '#212529',
                            paddingRight: '4px',
                            textTransform: 'uppercase'
                        }}>Your AI Assistant Personality</p>
                        <div><p style={{
                            fontSize: '14px',
                            fontWeight: '300',
                            textAlign: 'left',
                            color: 'grey',
                            marginTop: '5px'
                        }} dangerouslySetInnerHTML={{ __html: botData?.prompt?.replace(/\n/g, '<br/>') }}></p></div>
                    </Col>
                    <Col sm="12">
                        {keywordsArray[0] !== "undefined" && keywordsArray[0] !== "None" && keywordsArray[0]?.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                <p
                                    style={{
                                        fontSize: '16px', // Adjusting font size to make it more visible
                                        marginBottom: '10px',
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        color: theme === 'dark-only' ? '#1D1E26' : '#212529', // Darker color for the title
                                        paddingRight: '4px',
                                    }}
                                >
                                    Keywords
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        marginTop: '10px',
                                        padding: '5px 0',
                                        backgroundColor: theme === 'dark-only' ? '#1D1E26' : '#F8FAFC', // Adjust the background to a subtle light color
                                        borderRadius: '10px',
                                    }}
                                >
                                    {keywordsArray?.map((keyword, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                backgroundColor: '#ECF2FF', // Light blue background similar to the image
                                                borderRadius: '20px',
                                                marginBottom: '10px',
                                                marginRight: '10px',
                                                padding: '6px 12px', // More padding for a cleaner look
                                                width: 'auto',
                                            }}
                                        >
                                            <p
                                                key={index}
                                                style={{
                                                    fontSize: '12px', // Slightly larger font size for better readability
                                                    textAlign: 'center',
                                                    fontWeight: '500',
                                                    color: '#456BFF', // Blue color for the text to match the image
                                                    textTransform: 'capitalize',
                                                    margin: 0, // Remove margin for better alignment
                                                }}
                                            >
                                                {keyword}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Col>

                </Row>
            </div>

            <DeleteAssistantModal deleteBotModal={deleteBotModal} setDeleteBotModal={setDeleteBotModal} />

            <Modal
                show={botModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setBotModal(false)}
                dialogClassName="custom-modal-position"
                backdrop={false}
            >
                <Modal.Body
                    style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#5679D5',
                        padding: '10px 20px 0px 20px'
                    }}>
                        <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
                        <RxCross2 color='white' size={20} cursor={'pointer'} onClick={() => setBotModal(false)} />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0px'
                    }}>
                        <img style={{ width: '250px', height: '250px', borderRadius: '50%', justifySelf: 'center' }}
                            src={botData?.picture ? botData?.picture : botData?.gender === 'M' ? botImageMale : botImageFemale} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button disabled={loading} color='primary'
                            style={{ height: '43px', width: '100%', fontSize: '16px' }}
                            onClick={() => handleStartTalking(botData)}>
                            Start talking
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={vapiModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setVapiModal(false)}
                dialogClassName="custom-modal-position"
                backdrop={false}
            >
                <Modal.Body
                    style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#5679D5',
                        padding: '10px 20px 0px 20px'
                    }}>
                        <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
                        <RxCross2 color='white' cursor={'pointer'} size={20} onClick={() => handleEndedCall()} />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0px'
                    }}>
                        <iframe
                            src="https://gifer.com/embed/LCPT"
                            width={450}
                            height={300}
                            frameBorder="0"
                            style={{ pointerEvents: 'none' }}
                        ></iframe>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button color='primary' style={{ height: '43px', width: '100%', fontSize: '16px' }}
                            onClick={() => handleEndedCall()}>
                            Stop talking
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
