import LanguageSelect from "../../LanguageSelect";
import {CREATE_ASSISTANT_SCREENS, LANGUAGES_MAP} from "../../../../helpers/constants";
import {PrecastBotTile} from "./components/PrecastBotTile";
import {P} from '../../../../AbstractElements';
import {useCreateAssistantContext} from "../context/CreateAssistantContext";
import {Button, Col, Spinner,} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectDefaultBots,
    selectGettingDefaultBots
} from "../../../../Store/modules/precastBots/getDefaultBots/defaultBotsSelectors";
import {isEmpty} from "../../../../helpers/utils";
import {getCurrentCompany} from "../../../../helpers/LocalStorageHelper";
import {ActionTypes} from "../../../../Store/redux-store/ActionTypes";
import {useEffect, useState} from "react";
import {selectSelectedPrecastBot} from "../../../../Store/modules/precastBots/precastBotSelectors";

export const SelectPrecastBotStep1 = () => {
    const [language, setLanguage] = useState(LANGUAGES_MAP?.[0]);
    const dispatch = useDispatch();
    const botList = useSelector(selectDefaultBots);
    const isGettingBotList = useSelector(selectGettingDefaultBots);
    const selectedPrecastBot = useSelector(selectSelectedPrecastBot);

    const {currentScreen, setCurrentScreen, handleNext} = useCreateAssistantContext();
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        const company = getCurrentCompany();
        dispatch({
            type: ActionTypes.GET_PRECAST_BOTS,
            payload: {companyTypeId: company?.company_type?.id, lang: lang.lang_name}
        });
    };

    useEffect(() => { // default bot selected
        if (isEmpty(selectedPrecastBot) && !isEmpty(botList)) {
            dispatch({type: ActionTypes.SET_SELECTED_PRECAST_BOT, payload: {precastBot: botList?.[0]}})
        }
    }, [botList]);

    return (
        <>
            <Col sm="12">
                <LanguageSelect
                    languageMap={LANGUAGES_MAP}
                    selectedLanguage={language}
                    onLanguageChange={handleLanguageChange}
                />
            </Col>
            <div style={{
                height: '380px',
                width: '100%',
                overflowY: 'scroll',
                overflowX: 'hidden',
            }}>
                {!isGettingBotList && botList?.length > 0 ?
                    <>
                        {botList?.map((precastBotData, i) => {
                            return (
                                <PrecastBotTile key={i++} precastBotData={precastBotData}/>
                            );
                        })}
                    </>
                    :
                    <div style={{
                        display: 'flex',
                        height: 'inherit',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <P attrPara={{
                            style: {
                                fontSize: '16px',
                                fontWeight: '500'
                            }
                        }}>{isGettingBotList ?
                            <Spinner color="primary">
                                Loading...
                            </Spinner> : 'Assistant not found'}</P>
                    </div>
                }
            </div>

            <div style={{marginLeft: '10px'}}>
                <input
                    type="radio"
                    value="scan"
                    checked={currentScreen === CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO}
                    onChange={() => {
                        setCurrentScreen(CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO);
                    }}
                    className={`form-check-input `}
                    style={{backgroundColor: 'white'}}
                />
                <label style={{
                    marginTop: '5px',
                    marginLeft: '5px'
                }} onClick={() => {
                    setCurrentScreen(CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO);
                }}>Create my own assistant</label>
            </div>

            <div style={{
                marginTop: '0.5em',
                width: '100%'
            }}>
                <Button color='primary'
                        style={{width: '100%', margin: 0}}
                        onClick={handleNext}>
                    Next
                </Button>
            </div>
        </>);
}
