import React, { useEffect, useState } from 'react';
import { H4, H5 } from '../../../AbstractElements';
import Select from 'react-select';
import { Button, Col, Input, Row } from 'reactstrap';
import { Color } from '../../../helpers/colors';
import { getCurrentCompany, getCurrentUser } from "../../../helpers/LocalStorageHelper";
import { isEmpty } from "../../../helpers/utils";
import {
    Link,
    useSearchParams
} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from "moment";
import { selectCompleteCompany } from '../../../Store/modules/company/companySelectors';
import { AxiosInstance } from "../../../api/AxiosInstance";
import { useNavigate } from "react-router";
import { showToast } from "../../../helpers/ToastHelper";
import { ActionTypes } from "../../../Store/redux-store/ActionTypes";
import { useDispatch, useSelector } from 'react-redux';
import { selectOperationSettings } from "../../../Store/modules/settings/operationSettings/operationSettingsSelectors";
import { convert24HourTo12HourFormat } from "../../../helpers/DateHelper";


const onConnectAccount = () => {
    const user = getCurrentUser();
    const emailToAuthenticate = user?.email;
    const responseType = 'code';
    const scopes = 'calendar.read_only';

    const CLIENT_ID = '7951ee62-4526-4631-be85-b76fa582e48c';
    // const REDIRECT_URI = `${process.env.REACT_APP_API_URL}/calender/callback`;
    // http://127.0.0.1:3000/app/schedule-assistant
    const REDIRECT_URI = `http://127.0.0.1:3000/app/schedule-assistant`;

    window.location = `https://api.us.nylas.com/v3/connect/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${responseType}&login_hint=${emailToAuthenticate}&scopes=${scopes}`
};


const CreateScheduleBot = ({ formData, googleData }) => {
    const dispatch = useDispatch();
    const operationSettings = useSelector(selectOperationSettings);
    const [selectedNotifications, setSelectedNotifications] = useState(["fluten_calendar", "google_calendar", "email"]);
    const navigate = useNavigate()

    const [gData, setgData] = useState(googleData)

    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [timeSlot, setTimeSlot] = useState(null);
    const [guidelines, setGuidelines] = useState('');
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const completeCompany = useSelector(selectCompleteCompany);
    const [allAIList, setAllAIList] = useState([]);
    const [selectedBot, setSelectedBot] = useState(null);
    const [schaduleData, setSchaduleData] = useState({});
    const timeSlotOptions = [
        { value: 15, label: '15 minutes' },
        { value: 30, label: '30 minutes' },
        { value: 60, label: '1 hour' },
        { value: 120, label: '2 hours' },
    ];
    const [id, setId] = useState(0);
    const [inputValue, setInputValue] = useState(''); // State to store input data
    const [errorINp, setErrorINp] = useState(false); // State to store error state
    const [dateRangeList, setDateRangeList] = useState([]); // List of date ranges
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const [availability, setAvailability] = useState([
        { day: 'Monday', checked: true, startTime: '08:00', endTime: '18:00' },
        { day: 'Tuesday', checked: true, startTime: '08:00', endTime: '18:00' },
        { day: 'Wednesday', checked: true, startTime: '08:00', endTime: '18:00' },
        { day: 'Thursday', checked: true, startTime: '08:00', endTime: '18:00' },
        { day: 'Friday', checked: true, startTime: '08:00', endTime: '18:00' },
        { day: 'Saturday', checked: false, startTime: '08:00', endTime: '18:00' },
        { day: 'Sunday', checked: false, startTime: '08:00', endTime: '18:00' },
    ]);

    const notificationOptions = [
        { value: 'email', label: 'Email' },
        { value: 'calendar', label: 'Calendar' },
    ];
    const botOptions = completeCompany?.company?.company?.assistant?.map(bot => ({
        value: bot?.bot_id,
        label: bot?.bot_name,
    })) || [];

    useEffect(() => {
        setDatatoAI();
        // getScheduleData();
    }, [completeCompany]);

    useEffect(() => {
        changeINBot();
    }, [selectedBot])

    const setDatatoAI = async () => {
        setAllAIList(botOptions);
        if (botOptions?.length === 1) {
            console.log(botOptions[0], "===---=-=-==--=-=-=-==-=-=-")
            setSelectedBot(botOptions[0]);
        } else {
            setSelectedBot(botOptions[0]);
        }
        if (formData?.length !== 0) {
            const bo = botOptions.find(bot => bot.value === formData[0]?.bot_id);
            setSelectedBot(bo);
            const index = formData.findIndex(item => item.bot_id === formData[0]?.bot_id);
            setSchaduleData(formData[index]);
            const bot = botOptions?.find(item => item.value === formData[index]?.bot_id);
            setSelectedBot(bot)
            const time = timeSlotOptions?.find(item => item.value === formData[index]?.slot_size);
            setTimeSlot(time)
            setSelectedNotifications(formData[index]?.notifications)
            setGuidelines(formData[index]?.prompt)
            setAvailability(formData[index]?.schedule)
            const result = formData[index]?.overrides.map(item => {
                const startDateFormatted = item?.daterange[0]?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");
                const endDateFormatted = item.daterange[item?.daterange?.length - 1].replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");

                return {
                    startDate: new Date(startDateFormatted + "T00:00:00.000Z").toISOString(),
                    endDate: new Date(endDateFormatted + "T00:00:00.000Z").toISOString(),
                    key: "selection",
                    name: item.title
                };
            });
            setDateRangeList(result);
            setId(formData[index]?.id);
        }
    };


    const changeINBot = () => {
        const index = formData.findIndex(item => item.bot_id === 8988098);
        // console.log(index,"8789897787889798798988977789897")
        // return false
        // setSchaduleData(formData[index]);
        //     const bot = botOptions?.find(item => item.value === formData[index]?.bot_id);
        //     setSelectedBot(bot)
        //     const time = timeSlotOptions?.find(item => item.value === formData[index]?.slot_size);
        //     setTimeSlot(time)
        //     setSelectedNotifications(formData[index]?.notifications)
        //     setGuidelines(formData[index]?.prompt)
        //     setAvailability(formData[index]?.schedule)
        //     const result = formData[index]?.overrides.map(item => {
        //         const startDateFormatted = item?.daterange[0]?.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");
        //         const endDateFormatted = item.daterange[item?.daterange?.length - 1].replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2");

        //         return {
        //             startDate: new Date(startDateFormatted + "T00:00:00.000Z").toISOString(),
        //             endDate: new Date(endDateFormatted + "T00:00:00.000Z").toISOString(),
        //             key: "selection",
        //             name: item.title
        //         };
        //     });
        //     setDateRangeList(result);
        //     setId(formData[index]?.id);

    }

    const handleChange = (selectedOption) => {
        setSelectedBot(selectedOption);
    };




    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
            // fontWeight: '400',
            height: '45px',
            marginTop: '5px',
            fontSize: '16px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#5978d4' : 'white',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            // fontSize: '16px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            '&:hover': {
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const handleNotificationChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSelectedNotifications((prev) => [...prev, value]);
        } else {
            setSelectedNotifications((prev) =>
                prev.filter((notification) => notification !== value)
            );
        }
    };




    const handleCheckboxChange = (index) => {
        const updatedAvailability = [...availability];
        updatedAvailability[index].checked = !updatedAvailability[index].checked;
        setAvailability(updatedAvailability);
    };

    const handleTimeChange = (index, type, value) => {
        const updatedAvailability = [...availability];
        updatedAvailability[index][type] = value;
        setAvailability(updatedAvailability);
    };

    const handleSave = () => {
        console.log('Availability Data:', availability);
        // You can send this data to the server or process it further
    };




    const handleInputChange = (event) => {
        setErrorINp(false);
        setInputValue(event.target.value); // Update the state with the input value
    };

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
    };

    const saveOverRide = () => {
        if (inputValue === '') {
            setErrorINp(true);
            return false;
        } else {
            setErrorINp(false);
        }

        let data = {
            startDate: dateRange[0].startDate,
            endDate: dateRange[0].endDate,
            key: 'selection',
            name: inputValue,
        };

        setDateRangeList((prevRanges) => [
            ...prevRanges, // Retain previous ranges
            data, // Add the new range
        ]);

        // Reset input and date range
        setInputValue('');
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
        ]);
    };

    const deleteRange = (index) => {
        // Filter out the item to delete
        setDateRangeList((prevRanges) =>
            prevRanges.filter((_, i) => i !== index)
        );
    };


    // console.log(assistantants,"0-990-990-09-0-90-90-909-0-9")

    const connectGoogleCalender = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/google/auth`;
    }


    // Handlers
    const handleBotChange = (selectedOption) => setSelectedBot(selectedOption);
    const handleTimeSlotChange = (selectedOption) => setTimeSlot(selectedOption);
    const handleGuidelinesChange = (e) => setGuidelines(e.target.value);
    function convertData(input) {
        return input.map(item => {
            const startDate = new Date(item.startDate);
            const endDate = new Date(item.endDate);

            // Create an array of date strings formatted as 'MM-DD-YYYY' for the range
            const dateRange = [];
            for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
                const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
                const day = d.getDate().toString().padStart(2, '0');
                const year = d.getFullYear();
                const formattedDate = `${month}-${day}-${year}`;
                dateRange.push(formattedDate);
            }

            return {
                title: item.name,
                daterange: dateRange
            };
        });
    }

    // Submit function
    const saveData = async () => {

        let payload;
        let conDateRange = convertData(dateRangeList);
        if (id !== 0) {
            payload = {
                id: id,
                company_id: getCompanyInfo?.id,
                bot_id: selectedBot?.value,
                slot_size: timeSlot?.value,
                notifications: selectedNotifications,
                prompt: guidelines,
                schedule: availability,
                overrides: conDateRange,
                status: "active"
            };
        } else {
            payload = {
                company_id: getCompanyInfo?.id,
                bot_id: selectedBot?.value,
                slot_size: timeSlot?.value,
                notifications: selectedNotifications,
                prompt: guidelines,
                schedule: availability,
                overrides: conDateRange,
                status: "active"
            };
        }
        if (!payload.bot_id) {
            showToast({ message: "please select the AI Assistant" })
            return false
        }
        if (!payload.slot_size) {
            showToast({ message: "please select the slot size" })
            return false
        }
        if (payload.notifications.length === 0) {
            showToast({ message: "please select the Anyone Bookking Notification" })
            return false
        }

        // if (payload.prompt === "") {
        //     showToast({ message: "please enter the AI Assistant Guidelines" })
        //     return false
        // }

        if (payload.overrides.length === 0) {
            showToast({ message: "please add overrides" })
            return false
        }

        setLoading(true)

        try {
            const headers = AxiosInstance.defaults.headers;
            headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;;
            const response = await AxiosInstance.post('/schedule/create', payload);
            console.log('Response:', response.data);
            navigate("../../app/schedule-assistant")
            if (id !== 0) {
                showToast({ message: "Schedule Assistant update successfully" })
            } else {
                showToast({ message: "Schedule Assistant save successfully" })
            }

            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error saving data:', error);
            // alert('Failed to save data. Please try again.');
        }
    };


    useEffect(() => {
        const company = getCurrentCompany();
        dispatch({ type: ActionTypes.OPERATIONAL_SETTINGS_BY_COMPANY, payload: { companyId: company?.id } })
    }, []);

    return (
        <>
            <Row>
                <Col sm='6'>
                    <H4>Define Schedule</H4>
                    <br />
                    <Row>
                        <Col sm="6">
                            <div style={{ marginBottom: '10px' }}>
                                <label>Select AI Assistant</label>
                                <Select
                                    options={allAIList}
                                    styles={customStyles}
                                    placeholder="Select Bot"
                                    value={selectedBot}
                                    onChange={handleBotChange}
                                    isClearable={allAIList.length > 1}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div style={{ marginBottom: '10px' }}>
                                <label>Time Slot</label>
                                <Select
                                    options={timeSlotOptions}
                                    styles={customStyles}
                                    placeholder="Select Time Slot"
                                    value={timeSlot}
                                    onChange={handleTimeSlotChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div style={{ marginBottom: '10px' }}>
                                <label>Booking Notifications</label>
                                <div
                                    style={{
                                        display: 'flex',
                                        backgroundColor: Color.WHITE,
                                        padding: '10px 10px 0px 10px',
                                        borderRadius: '5px',
                                        marginTop: '5px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <label
                                        style={{
                                            marginRight: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Input
                                            type="checkbox"
                                            name="notification"
                                            value="fluten_calendar"
                                            checked={selectedNotifications.includes('fluten_calendar')}
                                            onChange={handleNotificationChange}
                                            style={{ marginRight: '5px', marginTop: '-1px', border: `1px solid ${Color.DARK_THE_GREY_TEXT}` }}
                                        />
                                        Fluten Calendar
                                    </label>
                                    <label
                                        style={{
                                            marginRight: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Input
                                            type="checkbox"
                                            name="notification"
                                            value="google_calendar"
                                            checked={selectedNotifications.includes('google_calendar')}
                                            onChange={handleNotificationChange}
                                            style={{ marginRight: '5px', marginTop: '-1px', border: `1px solid ${Color.DARK_THE_GREY_TEXT}` }}
                                        />
                                        Google Calendar
                                    </label>
                                    {/* <label
                                        style={{
                                            marginRight: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Input
                                            type="checkbox"
                                            name="notification"
                                            value="push_notification"
                                            checked={selectedNotifications.includes('push_notification')}
                                            onChange={handleNotificationChange}
                                            style={{ marginRight: '5px', marginTop: '-1px', border: `1px solid ${Color.DARK_THE_GREY_TEXT}` }}
                                        />
                                        Push Notification
                                    </label> */}
                                    <label
                                        style={{
                                            marginRight: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Input
                                            type="checkbox"
                                            name="notification"
                                            value="email"
                                            checked={selectedNotifications.includes('email')}
                                            onChange={handleNotificationChange}
                                            style={{ marginRight: '5px', marginTop: '-1px', border: `1px solid ${Color.DARK_THE_GREY_TEXT}` }}
                                        />
                                        Email
                                    </label>
                                </div>
                            </div>
                        </Col>


                        <Col sm="12">
                            <div style={{ marginBottom: '10px' }}>
                                <label>AI Assistant Guidelines</label>
                                <Input
                                    type="textarea"
                                    placeholder="Enter Prompt"
                                    value={guidelines}
                                    onChange={handleGuidelinesChange}
                                    style={{
                                        backgroundColor: 'white',
                                        border: '0.5px solid lightgrey',
                                        height: '307px',
                                        marginTop: '5px',
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        {gData ?
                            <p >{gData.email} Google Calendar Connected</p>
                            :
                            <Button
                                style={{
                                    borderColor: Color.PRIMARY,
                                    color: 'white',
                                }}
                                onClick={connectGoogleCalender}
                            >
                                Connect Google Calendar
                            </Button>
                        }





                    </div>
                </Col>



                <Col sm="6">
                    <h4>Availability Time</h4>
                    <br />
                    <Row>
                        <Col sm="12">
                            <span>Timezone: <strong>{operationSettings?.time_zone}</strong></span>  &nbsp;&nbsp;&nbsp; <Link to={"../app/settings"} style={{ color: "#6969ed", border: "none", backgroundColor: "transparent" }}>[Edit]</Link><br />
                            <span>Working Days: <strong>Monday - Friday</strong></span> <br />
                            <span>Working Time: <strong>{convert24HourTo12HourFormat(operationSettings?.office_start_time)} To {convert24HourTo12HourFormat(operationSettings?.office_end_time)}</strong></span> <br />
                        </Col>
                    </Row>
                    <br />

                    {availability.map((dayData, index) => (
                        <Row key={index}>
                            <Col sm="3" >
                                <div style={{ marginBottom: '10px', paddingTop: '12px' }}>
                                    <input
                                        type="checkbox"
                                        checked={dayData.checked}
                                        onChange={() => handleCheckboxChange(index)}
                                        className="cus_check"
                                    />
                                    <span style={{ fontSize: '16px' }}> {dayData.day}</span>
                                </div>
                            </Col>
                            <Col sm="3" style={{ padding: "0px" }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={availability[index].checked ? dayData.startTime : ''}
                                        onChange={(e) => handleTimeChange(index, 'startTime', e.target.value)}
                                        disabled={!dayData.checked}
                                    />
                                </div>
                            </Col>
                            <Col sm="1" style={{ paddingTop: '12px' }}>
                                To
                            </Col>
                            <Col sm="3" style={{ padding: "0px" }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <input
                                        type="time"
                                        className="form-control"
                                        value={availability[index].checked ? dayData.endTime : ''}
                                        onChange={(e) => handleTimeChange(index, 'endTime', e.target.value)}
                                        disabled={!dayData.checked}
                                    />
                                </div>
                            </Col>
                        </Row>
                    ))}

                    <Row>
                        <Col sm="12" style={{ marginTop: "20px" }}>
                            {dateRangeList.map((data, index) => {
                                return (
                                    <div style={{ display: 'flex' }} key={index}>
                                        <div>
                                            {data.startDate
                                                ? moment(data.startDate).format("DD MMM")
                                                : "N/A"}
                                            -
                                            {data.endDate
                                                ? moment(data.endDate).format("DD MMM")
                                                : "N/A"}
                                            &nbsp;[
                                            {data.name ? data.name : "N/A"}]
                                        </div>
                                        <div style={{ marginLeft: '10px' }}>
                                            <i
                                                className="fa-solid fa-trash-alt"
                                                style={{ cursor: "pointer", color: "red" }}
                                                onClick={() => deleteRange(index)}
                                            ></i>
                                        </div>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>

                    <br />
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            style={{
                                borderColor: Color.PRIMARY,
                                color: 'white',
                                padding: '10px 20px',
                                margin: '0px',
                            }}

                            onClick={handleShow}
                        >
                            Add Overrides
                        </Button>
                        <br />
                        <Button
                            disabled={loading}
                            color="primary"
                            style={{
                                borderColor: Color.PRIMARY,
                                color: 'white',
                                margin: "10px 0px 10px 0px",
                                float: "right"

                            }}
                            onClick={saveData}
                        >
                            Save Schedule
                        </Button>
                    </div>
                </Col>
            </Row >

            <Modal show={show} onHide={handleClose} className="modelCustomWidth">
                <Modal.Header closeButton>
                    <Modal.Title>Overrides</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="6" style={{ paddingLeft: "40px" }} >
                            <p>
                                Your Al Assistant will refrain from accepting an appointment booking on these overridden dates
                            </p>
                            <DateRangePicker
                                ranges={dateRange}
                                onChange={handleSelect}
                                locale={enUS} // Set the locale here
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                direction="horizontal"
                            />

                            <H4  >Title</H4>
                            <input type="text" className="form-control" style={{ width: "80%" }} value={inputValue} onChange={handleInputChange} />
                            {errorINp &&
                                <p>Please enter the Override Name</p>
                            }

                            <Button color='primary'
                                style={{
                                    borderColor: Color.PRIMARY,
                                    color: 'white',
                                    padding: '10px 20px',
                                    margin: "10px 0px 0px 0px"
                                }}

                                onClick={saveOverRide}
                                disabled={errorINp}
                            >
                                Save Overrides
                            </Button>


                        </Col>
                        <Col sm="6">
                            {dateRangeList.map((data, index) => {
                                return (
                                    <Row key={index}>
                                        <Col sm="8">
                                            {data.startDate
                                                ? moment(data.startDate).format("DD MMM")
                                                : "N/A"}
                                            -
                                            {data.endDate
                                                ? moment(data.endDate).format("DD MMM")
                                                : "N/A"}
                                            &nbsp;[
                                            {data.name ? data.name : "N/A"}]
                                        </Col>
                                        <Col sm="2">
                                            <i
                                                className="fa-solid fa-xmark"
                                                style={{ cursor: "pointer", color: "red" }}
                                                onClick={() => deleteRange(index)} // Delete the specific range
                                            ></i>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className='btn cusBTW' style={{ backgroundColor: "white", border: "none", color: "black" }}>
                        Close
                    </button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default CreateScheduleBot;
