import {call, put, select, takeLatest} from 'redux-saga/effects';
import {ActionTypes} from "../../../../redux-store/ActionTypes";
import {setErrors, setSelectedBotData, setSubmitting} from "./createAssistantFormSlice";
import {createAssistantApi} from "./createAssistantApi";
import {selectCreateAssistantFormValues, selectCustomPrecastBot} from "./createAssistantFormSelectors";
import {selectSelectedPrecastBot} from "../../../precastBots/precastBotSelectors";
import {getCurrentCompany} from "../../../../../helpers/LocalStorageHelper";
import {AI_ASSISTANT_RIGHT_SIDEBAR, TOAST_TYPES} from "../../../../../helpers/constants";
import {getSelectedBotApi} from "./getSelectedBotApi";
import {createPrecastBotApi} from "./createPrecastBotApi";
import {updateAssistantError} from "../../update/updateAssistantSlice";
import {showToast} from "../../../../../helpers/ToastHelper";
import {setupPhoneApi} from "./setupPhoneApi";
import {isEmpty} from "../../../../../helpers/utils";

function* getSelectedBotSaga(action) {
    const selectedPrecastBot = yield select(selectSelectedPrecastBot);
    const selectedBotData = yield call(getSelectedBotApi, {botId: selectedPrecastBot?.id});
    yield put(setSelectedBotData(selectedBotData));
}

function* createPrecastBotPayload() {
    const createAssistantFormValues = yield select(selectCreateAssistantFormValues);
    const company = getCurrentCompany();
    return {
        background_sound: createAssistantFormValues?.background_sound?.value?.toLowerCase(),
        name: createAssistantFormValues?.bot_name,
        company_id: company?.id,
        end_call_message: createAssistantFormValues?.end_call_message,
        end_call_phrases: createAssistantFormValues?.end_call_phrases,
        first_message: createAssistantFormValues?.first_message,
        gender: createAssistantFormValues?.gender?.short_name,
        keywords: createAssistantFormValues?.keywords,
        language: createAssistantFormValues?.language?.lang_name,
        phone_number: createAssistantFormValues?.phone_number,
        make_assist_bio: createAssistantFormValues?.prompt,
        assist_job: 'Incoming',
        voice_id: createAssistantFormValues?.voice?.value,
    };
}

function* createPrecastBotSaga(action) {
    const apiPayload = yield call(createPrecastBotPayload)
    const selectedBotData = yield call(createPrecastBotApi, apiPayload);
    yield put(setSelectedBotData(selectedBotData));
}

function* setUpAssistantPhone(action) {
    try {
        yield put(setSubmitting(true));
        const createAssistantFormValues = yield select(selectCreateAssistantFormValues);
        const company = getCurrentCompany();
        const apiPayload = {
            provider: 'Tw',
            bot_id: createAssistantFormValues?.bot_id?.toString(),
            company_id: company?.id?.toString(),
            phone_number: createAssistantFormValues?.phone_number,
            bot_name: 'null',
            provider_keys: `${createAssistantFormValues?.provider_sid}, ${createAssistantFormValues?.provider_auth_token}`,
        }
        const response = yield call(setupPhoneApi, apiPayload);
        yield put({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: company?.id } });
        yield put({ type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR, payload:  { sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL }  });
        // yield put(resetForm());
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(setErrors(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    } finally {
        yield put(setSubmitting(false));
    }
}

function* createApiPayload() {
    const createAssistantFormValues = yield select(selectCreateAssistantFormValues);
    const customPrecastBot = yield select(selectCustomPrecastBot);
    const company = getCurrentCompany();

    let payload = {};
    if(customPrecastBot) { // get form values and make payload
        payload = {
            background_sound: createAssistantFormValues?.background_sound?.value?.toLowerCase(),
            bot_name: createAssistantFormValues?.bot_name,
            company_id: company?.id,
            end_call_message: createAssistantFormValues?.end_call_message,
            end_call_phrases: createAssistantFormValues?.end_call_phrases,
            first_message: createAssistantFormValues?.first_message,
            gender: createAssistantFormValues?.gender?.short_name,
            keywords: createAssistantFormValues?.keywords,
            language: createAssistantFormValues?.language?.lang_name,
            phone_number: createAssistantFormValues?.phone_number,
            prompt: createAssistantFormValues?.prompt,
            provider: 'Tw',
            provider_keys: `${createAssistantFormValues?.provider_sid}, ${createAssistantFormValues?.provider_auth_token}`,
            purpose: 'Incoming',
            voice_id: createAssistantFormValues?.voice?.value,
        }
    } else { // get selected bot and make payload
        const selectedPrecastBot = yield select(selectSelectedPrecastBot);
        payload = {
            bot_id: selectedPrecastBot?.id,
            background_sound: selectedPrecastBot?.background_sound,
            bot_name: selectedPrecastBot?.bot_name,
            company_id: company?.id?.toString(),
            end_call_message: selectedPrecastBot?.end_call_message,
            end_call_phrases: selectedPrecastBot?.end_call_phrases,
            first_message: selectedPrecastBot?.first_message,
            gender: selectedPrecastBot?.gender,
            keywords: selectedPrecastBot?.keywords,
            language: selectedPrecastBot?.language,
            phone_number: createAssistantFormValues?.phone_number,
            prompt: selectedPrecastBot?.prompt,
            provider: 'Tw',
            provider_keys: `${createAssistantFormValues?.provider_sid}, ${createAssistantFormValues?.provider_auth_token}`,
            purpose: selectedPrecastBot?.purpose,
            voice_id: selectedPrecastBot?.voice_id,
        }
    }

    return payload;
}

function* handleFormSubmit(action) {
    try {
        const company = getCurrentCompany();
        yield put(setSubmitting(true));
        const apiPayload = yield call(createApiPayload);
        const response = yield call(createAssistantApi, apiPayload);
        yield put({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: company?.id } });
        yield put({ type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR, payload:  { sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL }  });
        // yield put(resetForm());
    } catch (error) {
        yield put(setErrors({ general: error.message }));
    } finally {
        yield put(setSubmitting(false));
    }
}

function* createAssistantForm() {
    yield takeLatest(ActionTypes.CREATE_ASSISTANT, handleFormSubmit);
}

export function* getSelectedBot() {
    yield takeLatest(ActionTypes.GET_SELECTED_PRECAST_BOT, getSelectedBotSaga);
}

export function* createPrecastBot() {
    yield takeLatest(ActionTypes.CREATE_PRECAST_BOT, createPrecastBotSaga);
}

export function* setupAssistantPhone() {
    yield takeLatest(ActionTypes.SETUP_ASSISTANT_PHONE, setUpAssistantPhone);
}

export default createAssistantForm;
