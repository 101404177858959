import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showToast } from "../../../helpers/ToastHelper";
import { ActionTypes } from "../../redux-store/ActionTypes";
import {
    selectKewordReportChartData,
  selectKewordReportTableData,
  selectKeywordsAnalysisLoading,
} from "./keywordsAnalysisSelector";
import {
  fetchkeywordsAnalysisLog,
  fetchkeywordsAnalysisLogError,
  fetchkeywordsAnalysisLogSuccess,
  setTableData,
  setChartData
} from "./keywordsAnalysisSlice";
import { getKeywordsAnalysisReport } from "./keywordsReport";

function* fetchKeywordsAnalysisReportSaga(action) {
  const isLoading = yield select(selectKeywordsAnalysisLoading);
  if (isLoading) return;

  try {
    yield put(fetchkeywordsAnalysisLog());
    const data = yield call(getKeywordsAnalysisReport, action.payload);
    console.log("🚀 ~ function*fetchKeywordsAnalysisReportSaga ~ data:", data)

    const tableData = data?.records;
    console.log("🚀 ~ function*fetchKeywordsAnalysisReportSaga ~ setTableData:", setTableData)

    const data1 = data?.records?.map((item) => item.keyword);
    const data2 = data?.records?.map((item) => item.frequency);

    const apexBarChartData = {
      series: [
        {
          data: data2,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#7366ff"],
        xaxis: {
          categories: data1,
        },
      },
    };

    if (data?.records?.length === 0) {
      showToast({ message: "No data found." });
    }

    yield put(fetchkeywordsAnalysisLogSuccess(data));
    yield put(setTableData(tableData));
    yield put(setChartData(apexBarChartData));
  } catch (error) {
    yield put(fetchkeywordsAnalysisLogError(error.message));
  }
}

export function* fetchKeywordsAnalysisReportCompany() {
  yield takeEvery(
    ActionTypes.FETCH_KEYWORDS_ANALYSIS_REPORT,
    fetchKeywordsAnalysisReportSaga
  );
}
