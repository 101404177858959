import {DetailsInfoTile} from "./DetailsInfoTile";
import {H4} from "../../../../../AbstractElements";
import { Color } from "../../../../../helpers/colors";


const basicInfoDetails = [
    {
        heading: 'Steps to Create your AI Assistant',
        description: 'Provide all the required information to make an AI Assistant specially designed to deal with clients in your industry.'
    },
    {
        heading: 'Define Your AI Assistant Personality',
        description: 'Select a name, gender, write a brief biography, and select a voice.  The biography can be AI generated for you that can be edited.'
    },
    {
        heading: 'Welcome Message To Customer',
        description: 'The greeting message your AI Assistant will convey on picking up the customer phone.'
    },
    {
        heading: 'AI Assistant Voice Should Sound Like',
        description: 'Choose a voice that will be associated with your AI Assistant.'
    },
]

export const BasicInfoDetails = () => {
    return (
        <div style={{
            marginTop: '20px', height: '450px', overflow: 'scroll'
        }}>
            <H4 attrH4={{
                style: {
                    fontSize: '20px',
                    color: Color.SECONDARY
                }
            }}>Define Your AI Assistant Personality</H4>
            {basicInfoDetails?.map((detail, i) => (
                <DetailsInfoTile key={i++} heading={detail?.heading} description={detail?.description}/>
            ))}
        </div>
    );
}