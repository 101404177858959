// src/services/apiService.js
import {AxiosInstance} from "../api/AxiosInstance";
import {HTTP_STATUS_CODES} from "../helpers/constants";
import axios from 'axios';

// const headers = AxiosInstance.defaults.headers;

const handleResponse = async (response) => {
    if (response.status !== HTTP_STATUS_CODES.SUCCESS) {
        throw new Error(response.message || 'Something went wrong');
    }
    return response.data;
};


export const apiService = {
    get: async (endpoint) => {
        const response = await AxiosInstance.get(endpoint);
        return handleResponse(response);
    },

    post: async (endpoint, body) => {
        const response = await AxiosInstance.post(endpoint, body);
        return handleResponse(response);
    },

    postWithBlobResponse: async (endpoint, body) => {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const token = localStorage.getItem('token')
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${endpoint}`,
                body,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': "Bearer " + token
                    },
                    responseType: 'blob',
                }
            );
        return handleResponse(response);
    },


    // put: async (url, body) => {
    //     const response = await fetch(`${API_BASE_URL}${url}`, {
    //         method: 'PUT',
    //         headers,
    //         body: JSON.stringify(body),
    //     });
    //     return handleResponse(response);
    // },
    //
    // delete: async (url) => {
    //     const response = await fetch(`${API_BASE_URL}${url}`, {
    //         method: 'DELETE',
    //         headers,
    //     });
    //     return handleResponse(response);
    // },
};
