import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Breadcrumbs, H5 } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { getCompanyCustomers } from "../../api/Company";
import moment from 'moment';  // Import moment

const PromationList = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    const [tableData, setTableData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedConvo, setSelectedConvo] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);  // State to store the selected row

    // useEffect(() => {
    //     getCustomers();
    // }, []);

    const tableColumns = [
        {
            name: 'Initials',
            selector: row => getInitials(row['full_name']),
            cell: row => <div className={`initials-circle ${getRandomColor()}`}>{getInitials(row['full_name'])}</div>,
            center: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: row => row['full_name'],
            sortable: true,
            center: false,
            width: '130px',
        },
        {
            name: 'Phone',
            selector: row => formatPhoneNumber(row['phone']), // Use the formatting function here
            sortable: true,
            center: false,
            width: '180px',
        },
        {
            name: 'Last call date',
            selector: row => formatDate(row['last_convo_date']),
            sortable: true,
            center: false,
            width: '200px',
        },
        {
            name: 'Last conversation',
            selector: row => row['last_convo'],
            sortable: true,
            center: false,
            cell: row => (
                <div>
                    {truncateText(row['last_convo'], 70)}
                </div>
            ),
        },
        {
            name: 'Action',
            cell: row => (
                <Button color="primary" onClick={() => handleViewClick(row)}>
                   <i class="fa-solid fa-eye"></i>
                </Button>
            ),
            center: true,
            width: '100px',
        },
    ];

    // const getCustomers = async () => {
    //     let res = await getCompanyCustomers(getCompanyInfo?.id);
    //     if (res.customer) {
    //         setTableData(res.customer);
    //     }
    // };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumberString;
    };

    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do, YY");
    };

    const handleConvoClick = (convo) => {
        setSelectedConvo(convo);
        setShowModal(true);
    };

    const handleViewClick = (row) => {
        setSelectedRow(row);
        setShowModal(true);
    };

    const getInitials = (name) => {
        if (!name) return '';
        const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
        return initials.slice(0, 2);
    };

    const getRandomColor = () => {
        const colors = ['bg-color-1', 'bg-color-2', 'bg-color-3', 'bg-color-4', 'bg-color-5'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
    };

    return (
        <>
            <Fragment>
                <Breadcrumbs parent="Forms" mainTitle="Manage Customer" subParent="Customer" title="Manage Customer" />
                <Container fluid={true} className="rubik-font">
                    <Row>
                        <Col md="12">
                            <Card>
                                <div style={{ paddingTop: '20px', paddingRight: '20px', paddingLeft: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {/* <Button style={{ backgroundColor: 'red' }}>
                                        Add Customer
                                    </Button> */}
                                </div>
                                <CardBody className="custTableComp">
                                    <DataTable
                                        data={tableData}
                                        columns={tableColumns}
                                        striped={true}
                                        center={false}
                                        pagination
                                        paginationPerPage={50}
                                        paginationRowsPerPageOptions={[50]}
                                        className="border-dashed"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Customer Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div>
                                <p><strong>Name:</strong> {selectedRow.full_name}</p>
                                <p><strong>Phone:</strong> {formatPhoneNumber(selectedRow.phone)}</p>
                                <p><strong>Last Call Date:</strong> {formatDate(selectedRow.last_convo_date)}</p>
                                <p><strong>Last Conversation:</strong> {selectedRow.last_convo}</p>
                                {/* Add more fields as needed */}
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        </>
    );
};

export default PromationList;
