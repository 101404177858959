import React, { Fragment, useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Button, FormGroup, Label } from 'reactstrap';
import { Breadcrumbs, Btn, H4 } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { Modal, Spinner } from 'react-bootstrap';
import { getCompanyCustomers, updateCustomerInfo } from "../../api/Company";
import moment from 'moment';
import DropdownCommon from "../../Components/Common/Dropdown";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { ToastContainer, toast } from "react-toastify";
import '../ManageAssistant/ManageAssistant.css'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import '../ManageAssistant/ManageAssistant.css'
import NoDataFound from "../NoDataFound/NoDataFound";
import { debounceTime } from "../../helpers/constants";
import { HiUserCircle } from "react-icons/hi2";
import { Color } from "../../helpers/colors";


const CustomerList = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    const theme = localStorage.getItem("mix_background_layout")
    let token = localStorage?.getItem('token')
    const [tableData, setTableData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedConvo, setSelectedConvo] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);  // State to store the selected row

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;

            const delayDebounceFn = setTimeout(() => {
                getCustomers();
            }, debounceTime);

            return () => clearTimeout(delayDebounceFn);
        }
    }, []);

    const handleActionSelect = (row, action) => {
        if (action === "Edit") {
            console.log("Clicked Edit");
            handleEditModal(row)
        } else if (action === "View Detail") {
            // handleViewDetail(row);
            handleViewClick(row)
            console.log("Clicked Detail");
        }
    };

    const tableColumns = [
        {
            name: 'Initials',
            selector: row => getInitials(row['full_name']),
            cell: row => <div style={{ marginLeft: '10px' }} className={`initials-circle ${getRandomColor()}`}>{getInitials(row['full_name'])}</div>,
            center: true,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: row => row['full_name'],
            sortable: true,
            center: false,
            width: '130px',
            cell: row => (
                <div style={{ marginLeft: '15px' }}>
                    {row['full_name']}
                </div>
            ),
        },
        {
            name: 'Phone',
            // selector: row => formatPhoneNumber(row['phone']),
            sortable: true,
            center: false,
            width: '180px',
            cell: row => (
                <div style={{ marginLeft: '15px' }}>
                    {formatPhoneNumber(row['phone'])}
                </div>
            ),
        },
        {
            name: 'Last call date',
            // selector: row => formatDate(row['last_convo_date']),
            sortable: true,
            center: false,
            width: '200px',
            cell: row => {
                const date = moment(row?.last_convo_date).format('MM-DD-YYYY');
                const formattedDate = moment(date, 'MMM-DD-YYYY').format('MMMM Do, YYYY');
                return (
                    <>
                        <p style={{ fontSize: '12px', lineHeight: 0.5, marginLeft: '15px' }}>{date}</p>
                    </>
                )
            },
        },
        {
            name: 'Last conversation',
            selector: row => row['last_convo'],
            sortable: true,
            center: false,
            cell: row => (
                <div style={{ marginLeft: '15px' }}>
                    {truncateText(row['last_convo'], 70)}
                </div>
            ),
        },
        {
            name: 'Action',
            cell: row => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginLeft: '25px' }}>
                    <DropdownCommon
                        dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
                        options={[`Edit`, "View Detail"]}
                        iconName='icon-more-alt'
                        btn={{ tag: 'span' }}
                        onSelect={(action) => handleActionSelect(row, action)}
                    />
                </div>
            ),
            center: true,
            width: '100px',
        },
    ];

    const getCustomers = async () => {
        setLoader(true)
        try {
            let res = await getCompanyCustomers(getCompanyInfo?.id);
            console.log("🚀 ~ getCustomers ~ res:", res)
            if (res.customer) {
                setTableData(res.customer);
            }
            setLoader(false)
        } catch (error) {
            console.log("🚀 ~ getCustomers ~ error:", error)
            setLoader(false)
        }
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumberString;
    };

    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do, YY");
    };

    const handleConvoClick = (convo) => {
        setSelectedConvo(convo);
        setShowModal(true);
    };

    const handleViewClick = (row) => {
        setSelectedRow(row);
        setShowModal(true);
    };

    const handleEditModal = (row) => {
        console.log("🚀 ~ handleEditModal ~ row:", row)
        setSelectedRow(row);
        setEditModal(true);
    };

    const getInitials = (name) => {
        if (!name) return '';
        const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
        return initials.slice(0, 2);
    };

    const getRandomColor = () => {
        const colors = ['bg-color-1', 'bg-color-2', 'bg-color-3', 'bg-color-4', 'bg-color-5'];
        return colors[Math.floor(Math.random() * colors?.length)];
    };

    const truncateText = (text, length) => {
        if (text?.length <= length) return text;
        return text?.substring(0, length) + '...';
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required.'),
        // email: Yup.string().email('Invalid email format').required('Email is required'),
        phone: Yup.string().required('Phone is required.'),
    });

    const handleUpdateCustomer = async (data) => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('customer_id', selectedRow?.id)
            formData.append('full_name', data?.name)
            formData.append('email', data?.email)
            formData.append('phone', data?.phone)
            const res = await updateCustomerInfo(token, formData)
            console.log("🚀 ~ handleUpdateCustomer ~ res:", res)
            toast.info("Updated Customer", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });

            if (res) {
                getCustomers()
                setEditModal(false);
            }
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ handleUpdateCustomer ~ error:", error)
            setLoading(false)
        }
    }

    return (
        <>
            <Fragment>
                <Breadcrumbs parent="Forms" mainTitle="Manage Customer" subParent="Customer" title="Manage Customer" />
                <Container fluid={true} className="rubik-font">
                    <Row>
                        {loader ? (
                            <Col md="12">
                                <Card>
                                    <CardBody style={{ padding: '10px' }}>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <Skeleton height={10} count={22} />
                                        </SkeletonTheme>
                                    </CardBody>
                                </Card>
                            </Col>
                        ) : (
                            <Col md="12">
                                <Card>
                                    <CardBody className="custTableComp">
                                        {tableData?.length > 0 ?
                                            <DataTable
                                                data={tableData}
                                                columns={tableColumns}
                                                striped={true}
                                                center={false}
                                                pagination
                                                paginationPerPage={50}
                                                paginationRowsPerPageOptions={[50]}
                                                className="border-dashed"
                                                noDataComponent={
                                                    <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                        No customers found
                                                    </div>
                                                }
                                            />
                                            :
                                            <NoDataFound message={'No customers found'} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        )}

                    </Row>
                </Container>

                <Modal size="lg" show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <H4>
                                Customer Information
                            </H4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRow && (
                            <div style={{ lineHeight: '1.8' }}>
                                <Row>
                                    {/* Placeholder for profile image */}
                                    <Col sm='3'>
                                        <HiUserCircle size={200} style={{ marginTop: '-15px' }} color={Color.GREY} />
                                    </Col>
                                    <Col sm='9'>
                                        <h5 style={{ margin: 0, color: Color.PRIMARY, marginBottom: '15px' }}>{selectedRow.full_name}</h5>

                                        <p style={{ marginBottom: '0px' }}>
                                            <strong>Phone</strong>
                                        </p>
                                        <p style={{ margin: 0, color: '#6C757D', fontSize: '0.9rem', marginBottom: '15px' }}>
                                            {selectedRow.phone === 'Anonymous' ? 'Anonymous' : formatPhoneNumber(selectedRow.phone)}
                                        </p>

                                        <p style={{ marginBottom: '0px' }}>
                                            <strong>Last Call Date</strong>
                                        </p>
                                        <p style={{ marginBottom: '0px', marginBottom: '15px' }}>
                                            {formatDate(selectedRow.last_convo_date)}
                                        </p>

                                        <p style={{ marginBottom: '0px' }}>
                                            <strong>Last Conversation</strong>
                                        </p>
                                        <p style={{ marginBottom: '0px' }}>
                                            {selectedRow.last_convo}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{
                                backgroundColor: '#E74C3C',
                                borderColor: '#E74C3C',
                                fontWeight: 'bold',
                            }}
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={editModal} onHide={() => setEditModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <H4>Update Customer</H4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                name: selectedRow ? selectedRow.full_name : '',
                                email: selectedRow ? selectedRow.email : '',
                                phone: selectedRow ? selectedRow.phone : '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                handleUpdateCustomer(values);
                            }}
                            enableReinitialize
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <FormGroup>
                                        <Label className="col-sm-12 col-form-label" style={{ fontWeight: '500', fontSize: '18px' }} htmlFor="name">Name</Label>
                                        <Col sm="12">
                                            <Field
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`form-control ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            />
                                            <ErrorMessage name="name" component="div" className="text-danger" />
                                        </Col>
                                    </FormGroup>
                                    {/* <FormGroup>
                                        <Label className="col-sm-12 col-form-label" style={{ fontWeight: '500', fontSize: '18px' }} htmlFor="email">Email</Label>
                                        <Col sm="12">
                                            <Field
                                                type="email"
                                                name="email"
                                                id="email"
                                                className={`form-control ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            />
                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                        </Col>
                                    </FormGroup> */}
                                    <FormGroup>
                                        <Label className="col-sm-12 col-form-label" style={{ fontWeight: '500', fontSize: '18px' }} htmlFor="phone">Phone</Label>
                                        <Col sm="12">
                                            <Field
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className={`form-control ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            />
                                            <ErrorMessage name="phone" component="div" className="text-danger" />
                                        </Col>
                                    </FormGroup>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '5px 0px', marginTop:'50px' }}>
                                        {loading ?
                                            <Button color="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Updating...
                                            </Button>
                                            :
                                            <Button style={{ width: '40%', height: '40px' }} type="submit" color="primary">Update</Button>
                                        }
                                        <Button style={{ width: '40%', height: '40px' }} onClick={() => setEditModal(!editModal)} color="secondary" >Close</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
                <ToastContainer />
            </Fragment>
        </>
    );
};

export default CustomerList;
