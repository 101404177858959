import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, Popover, PopoverBody, } from 'reactstrap';
import { H4, H6, Image, H5, LI, UL, P } from '../../AbstractElements';
import './ManageAssistant.css';
import {
    defaultBots,
    deleteBot,
    editBotNumber,
    editUpdateBot,
    generateBotPersona,
    getAllCompanyData,
    getAllPersonas,
    getBotRaw,
    getCreateBot,
    getViewCompanyPhoneNumber
} from '../../api/AuthIndex';
import { Form, Modal, Spinner } from 'react-bootstrap';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import profileImage from '../../assets/images/user/bot.png';
import botImageMale from '../../assets/images/user/botImageMale.jpeg';
import botImageFemale from '../../assets/images/user/botImageFemale.jpeg';
import calling from '../../assets/images/user/calling.png';
import calendar from '../../assets/images/user/calendar.png';
import { OverlayTrigger, Popover as ReactBootstrapPopover } from 'react-bootstrap';
import phoneIcon from '../../assets/images/user/phoneIcon.png';
import noAssistant from '../../assets/images/user/assistantBot.png';
import startTalk from '../../assets/images/user/startTalk.png';
import callIcon from '../../assets/images/logo/callIcon.png';
import forwardCallIcon from '../../assets/images/logo/forwardCallIcon.png';
import inCallsIcon from '../../assets/images/logo/inCallsIcon.png';
import { ImCross } from "react-icons/im";
import { FaArrowLeft, FaExpandArrowsAlt } from "react-icons/fa";


import HeaderCard from '../../Components/Common/Component/HeaderCard';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import Select from 'react-select';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { bookNumber, getStates, getTwilioNumbers } from '../../api/Company';

import { debounce } from 'lodash';

import { AiOutlineDollar, AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { FaChevronDown, FaChevronUp, FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoIosHelpCircleOutline } from 'react-icons/io';

import Vapi from '@vapi-ai/web';
import BotDetailComponent from './BotDetailComponent';
import LanguageSelect from './LanguageSelect';
import axios from 'axios';
import { IoPlayOutline, IoPlayCircleOutline, IoPauseCircleOutline } from "react-icons/io5";
import { SlLocationPin } from "react-icons/sl";
import { Color } from '../../helpers/colors';
import { FEATURE, LANGUAGES_MAP } from '../../helpers/constants';
import { hasFeatureAccess } from "../../helpers/PackagesHelper";
import { useCompanyContext } from "../../Store/providers/Company/CompanyProvider";
import UpgradePopup from "../../Components/UpgradePopup/UpgradePopup";
import PrepareAssistant from "../../Auth/PrepareAssistant";
import roboImg from "../../assets/images/logo/roboImg.png";
import botImage from "../../assets/images/user/meh.png";
import expand from "../../assets/images/user/expand.png";
import { getCurrentCompany } from "../../helpers/LocalStorageHelper";
import { isEmpty } from "../../helpers/utils";

// const LeftDrawer = ({ show, handleClose }) => {
//     return (
//         <Offcanvas show={show} onHide={handleClose} placement="start">
//             <OffcanvasHeader closeButton>
//                 <OffcanvasTitle>Left Drawer</OffcanvasTitle>
//             </OffcanvasHeader>
//             <OffcanvasBody>
//                 {/* Add your drawer content here */}
//                 <p>This is the content of the left drawer</p>
//             </OffcanvasBody>
//         </Offcanvas>
//     );
// };

const ManageAssistant = ({ }) => {
    const navigate = useNavigate()

    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    let getUserData = JSON.parse(localStorage.getItem('auth0_profile'))
    const theme = localStorage.getItem("mix_background_layout")
    let token = localStorage?.getItem('token')
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)
    const [step4, setStep4] = useState(false)
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectedSimpleOption, setSelectedSimpleOption] = useState(null)
    const [ownBotCreate, setOwnBotCreate] = useState(false)
    const [createSimpleOption, setCreateSimpleOption] = useState(true)
    const [personasData, setPersonasData] = useState()
    const [gender, setGender] = useState()
    const [assistantModal, setAssistantModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [numberLoader, setNumberLoader] = useState(false)
    const [tollLoader, setTollLoader] = useState(false)
    const [tollButtonLoader, setTollButtonLoader] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [botData, setBotData] = useState()
    const [botInformation, setBotInformation] = useState()
    const [selectedPersonaIndex, setSelectedPersonaIndex] = useState(null)
    const [completeCompanyData, setCompleteCompanyData] = useState()
    const [forLoader, setForLoader] = useState(false)
    const [phoneLength, setPhoneLength] = useState()
    const [assistantVoice, setAssistantVoice] = useState()
    const [assistantEditVoice, setAssistantEditVoice] = useState()
    const [botVoice, setBotVoice] = useState()
    const [editBotVoice, setEditBotVoice] = useState()
    const [audioSrc, setAudioSrc] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);

    const [createBot, setCreateBot] = useState(false);

    const [isRoboImageLoaded, setIsRoboImageLoaded] = useState(false);
    const [assistantType, setAssistantType] = useState(null);
    const [ownLanguage, setOwnLanguage] = useState();
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('')



    const [botName, setBotName] = useState();
    const [purpose, setPurpose] = useState();
    const [havingNumber, setHavingNumber] = useState('');
    const [firstMessage, setFirstMessage] = useState();
    const [officeVoice, setOfficeVoice] = useState();
    const [endCallPhrases, setEndCallPhrases] = useState();
    const [endCallGreetings, setEndCallGreetings] = useState();
    const [prompt, setPrompt] = useState();
    const [keywords, setKeywords] = useState();

    const [phone, setPhone] = useState();
    const [bot, setBot] = useState();
    const [provider, setProvider] = useState();
    const [providerKeys, setProviderKeys] = useState();
    const [accountSID, setAccountSID] = useState();
    const [authToken, setAuthToken] = useState();
    const [language, setLanguage] = useState(LANGUAGES_MAP?.[0]);

    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [editLanguage, setEditLanguage] = useState(false)


    const [phoneData, setPhoneData] = useState()

    const [prefixKey, setPrefixKey] = useState('')
    const [tollFree, setTollFree] = useState()
    const [cStates, setCStates] = useState('')


    const [data, setData] = useState()

    const [staffData, setStaffData] = useState()

    const [receivedCallLength, setReceivedCallLength] = useState()
    const [forwardedCallLength, setForwardedCallLength] = useState()

    const [readBioModal, setReadBioModal] = useState(false)
    const [readBioData, setReadBioData] = useState()

    const [deleteItem, setDeleteItem] = useState('')
    const [deleteBotModal, setDeleteBotModal] = useState(false)

    const [selectedBot, setSelectedBot] = useState(null);
    const [botList, setBotList] = useState()
    const [agentData, setAgentData] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const roboRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [assistantBio, setAssistantBio] = useState();
    const [previewAssistant, setPreviewAssistant] = useState(false);
    const [precastSelectedBot, setPrecastSelectedBot] = useState(null);
    const [precaseBotData, setPrecastBotData] = useState(null);



    const [popoverOpen, setPopoverOpen] = useState({
        firstMessage: false,
        endCallWords: false,
        endCallGreetings: false,
        backVoice: false,
        keywords: false,
        addAssistantButton: false
    });

    const handleMouseEnter = (target) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [target]: true
        }));
    };

    const handleSelectBot = (item) => {
        // if(isEmpty(item)) {
        //     return;
        // }
        // setSelectedBot(item.id)
        setPrecastSelectedBot(item.id);
        setFirstMessage(item.first_message);
        setPrecastBotData(item);

        if (precastSelectedBot != item?.id) {
            setIsRoboImageLoaded(false);
            setIsPlaying(null);
            audioElement?.pause();
        }

        setAssistantType(null);

        setBotName(item?.bot_name);
        setEndCallGreetings(item?.end_call_message);
        setEndCallPhrases(item?.end_call_phrases);
        setPrompt(item?.prompt);
        setGender(item?.gender);
        setPurpose(item?.purpose);
        setBotVoice(item?.voice_id);
        setKeywords(item?.keywords);
    }

    const handleDeselectBot = () => {
        setIsRoboImageLoaded(false);
        setIsPlaying(null);
        audioElement?.pause();
        setSelectedBot(null)
        setFirstMessage(null)
        setAgentData(null);
        setAssistantType(null);
        setBotName(null);
        setGender(null);
        setPurpose(null);
        setBotVoice(null);
        setKeywords(null);
    }

    const handleOwnLanguageChange = (lang) => {
        setOwnLanguage(lang);
    };

    const handleScanOptionChange = () => {
        setAssistantType('custom');
        setSelectedBot(null);
        setAudioSrc(null);
        // setFirstMessage(null);
    };


    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const customVoiceStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
            fontWeight: '300',
            width: '100%'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px',
            fontWeight: '300'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            '&:hover': {
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const generateBioPopover = (
        <ReactBootstrapPopover id="popover-generate-bio">
            <ReactBootstrapPopover.Body>Generate Bio Through AI</ReactBootstrapPopover.Body>
        </ReactBootstrapPopover>
    );

    const viewBioPopover = (
        <ReactBootstrapPopover id="popover-view-bio">
            <ReactBootstrapPopover.Body>View Bio</ReactBootstrapPopover.Body>
        </ReactBootstrapPopover>
    );

    const handleMouseLeave = (target) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [target]: false
        }));
    };

    const togglePopover = (target) => {
        setPopoverOpen(prevState => ({
            ...prevState,
            [target]: !prevState[target]
        }));
    };

    const handleReadBioModal = (data) => {
        setReadBioData(data)
        setReadBioModal(true)
    }

    // Edit Modal

    const [editName, setEditName] = useState('');
    const [editPrompt, setEditPrompt] = useState('')
    const [editFirstMessage, setEditFirstMessage] = useState('')
    const [editEndCall, setEditEndCall] = useState('')
    const [callGreeting, setCallGreeting] = useState('')
    const [editGender, setEditGender] = useState('')
    const [editBotId, setEditBotId] = useState('')
    const [backVoice, setBackVoice] = useState('')
    const [changePhoneNumber, setChangePhoneNumber] = useState('')
    const [editProvider, setEditProvider] = useState('')
    const [editAccountSID, setEditAccountSID] = useState('')
    const [editAuthToken, setEditAuthToken] = useState('')
    const [editKeywords, setEditKeywords] = useState('')
    const [states, setStates] = useState()
    const [twilioNumbersList, setTwilioNumbersList] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [bookNumberModal, setBookNumberModal] = useState();
    const [bookNumberModalData, setBookNumberModalData] = useState();
    const [bookNumberData, setBookNumberData] = useState();

    const [botModal, setBotModal] = useState(false);
    const [vapiModal, setVapiModal] = useState(false);
    const [botRawData, setBotRawData] = useState(null);
    const [editComponent, setEditComponent] = useState(false);

    const [showAccountSID, setShowAccountSID] = useState(false);
    const [showAuthToken, setShowAuthToken] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const vapiRef = useRef(null);

    const { completeCompany } = useCompanyContext();

    const [upgradeModal, setUpgradeModal] = useState(false);
    const [talkLoader, setTalkLoader] = useState(false);

    // useEffect(() => {
    //     getAllBots();
    // }, []);

    useEffect(() => {
        if (vapiModal && botRawData) {
            vapiRef.current = new Vapi("83e82473-a8f5-45a9-9607-8bb239c672c3");
            try {
                vapiRef.current.start(botRawData);
            } catch (error) {
                console.error('Error starting Vapi:', error);
            }
        }
    }, [vapiModal, botRawData]);

    const canCreateAssistants = hasFeatureAccess({
        featureName: FEATURE.ASSISTANTS,
        packageType: completeCompany?.company?.activeSubscription,
        existingQuantity: completeCompany?.company?.company?.assistants
    });

    const sentimentData = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
    ];

    const backSound = [
        { value: 'office', label: 'Office' },
        { value: 'off', label: 'Off' }
    ];

    // const getAllBots = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append("company_id", getCompanyInfo?.id);
    //         const res = await getBotByCompany(formData, token);
    //         setBotList(res?.bots);
    //     } catch (error) {
    //         console.error('Error fetching bots:', error);
    //     }
    // };

    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const UpgradeRequiredPopup = () => (
        <UpgradePopup
            isOpen={showUpgradePopup}
            toggler={setShowUpgradePopup}
            title="Upgrade Required"
            description="To unlock additional assistants and other features, please upgrade your package."
        />
    )


    const handleStartTalking = async (data) => {
        setTalkLoader(true)
        console.log("🚀 ~ handleStartTalking ~ data:", data)
        try {
            const formData = new FormData();
            formData.append("bot_id", data?.bot_id);
            formData.append("customer_phone", data?.bot_id);
            const res = await getBotRaw(formData, token);
            setBotRawData(res);
            setTalkLoader(false)
            setBotModal(false);
            setVapiModal(true);
        } catch (error) {
            console.error('Error starting Vapi:', error);
            setTalkLoader(false)
        }
    };

    const handleEndedCall = () => {
        if (vapiRef.current) {
            vapiRef.current.stop();
        }
        setVapiModal(false);
    };

    useEffect(() => {
        getAllDefaultBots()
    }, [language])

    const handleLanguage = (e) => {
        setLanguage(e.target.value);
    };
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };
    const handleEditLanguageChange = (lang) => {
        setEditLanguage(lang);
    };
    const handleEditLanguage = (e) => {
        setEditLanguage(e.target.value);
    };

    const handleProviderChange = (e) => {
        setProvider(e.target.value);
    };

    const handleEditProviderChange = (e) => {
        setEditProvider(e.target.value);
    };

    const handleRadioChange = (role) => {
        console.log("🚀 ~ handleRadioChange ~ role:", role)
        setSelectedRole(role);
    }

    const handleSimpleOptionChange = (event) => {
        console.log("🚀 ~ handleSimpleOptionChange ~ event:", event.target.value)
        setSelectedSimpleOption(event.target.value);
        setCreateSimpleOption(false)
        setBotInformation(null)
        setOwnBotCreate(true)
    };

    const handleCreateSimpleOption = (event) => {
        setOwnBotCreate(false)
        setSelectedSimpleOption(null);
        setCreateSimpleOption(event.target.value)
    }

    const handleGenderSelection = (event) => {
        console.log("🚀 ~ handleGenderSelection ~ event:", event.target.value)
        setGender(event.target.value)
    }

    useEffect(() => {
        getAllPersonasData()
        handleViewCompanyPhoneNumber()
        if (getCompanyInfo !== null) {
            handleGetAllCompany()
            getState()
        }
    }, [])

    const getTwilioNumberList = async () => {
        setTollLoader(true)
        try {
            const formData = new FormData();
            formData.append('country', 'US');
            formData.append('state', cStates);
            formData.append('page', currentPage);
            formData.append('number_type', tollFree ? 'tollfree' : '');
            formData.append('contains', prefixKey);
            const res = await getTwilioNumbers(token, formData);
            setTwilioNumbersList(res); // Assuming res is the array of objects
            setTollLoader(false)
        } catch (error) {
            console.log("🚀 ~ getTwilioNumberList ~ error:", error)
            setTwilioNumbersList([])
            setTollLoader(false)
        }

    };

    const debouncedGetTwilioNumberList = useCallback(
        debounce(getTwilioNumberList, 700),
        [currentPage, cStates, tollFree, prefixKey]
    );

    useEffect(() => {
        debouncedGetTwilioNumberList();
        return () => {
            debouncedGetTwilioNumberList.cancel();
        };
    }, [debouncedGetTwilioNumberList]);

    const getState = async (countryId) => {
        const res = await getStates('226');
        setStates(res.states);
    };


    const handleNextPage = () => {
        setCurrentPage(currentPage + 1)
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const startIndex = (currentPage - 1) * 20;
    const endIndex = startIndex + 20;
    const currentNumbers = twilioNumbersList?.slice(startIndex, endIndex);

    const handleGetAllCompany = async () => {
        const formData = new FormData();
        formData.append("company_id", getCompanyInfo?.id);

        try {
            const res = await getAllCompanyData(token, formData);

            // Since res.company is an object, no need to map, just access the company directly
            const company = res?.company?.company;

            // Update phone length (assuming setPhoneLength is needed for the company)
            setPhoneLength(company?.phone);

            // Set the company data
            setCompleteCompanyData(company);
            setForLoader(company);

            // Flatten assistants and calculate their call data
            const assistantsWithCalls = [];
            if (company.assistant && Array.isArray(company.assistant)) {
                company.assistant.forEach(assistant => {
                    let forwardedCallCount = 0;
                    let totalCallsCount = 0;

                    if (assistant.calls && Array.isArray(assistant.calls)) {
                        assistant.calls.forEach(call => {
                            // Count forwarded calls
                            if (call.end_reason === "assistant-forwarded-call") {
                                forwardedCallCount += call.total_calls;
                            }
                            // Sum total calls
                            if (call.total_calls) {
                                totalCallsCount += call.total_calls;
                            }
                        });
                    }

                    // Add the counts to the assistant object
                    assistant.totalCallsCount = totalCallsCount;
                    assistant.forwardedCallCount = forwardedCallCount;

                    assistantsWithCalls.push(assistant);
                });
            }

            // Update state with processed assistants
            setStaffData(assistantsWithCalls);
            if (assistantsWithCalls.length > 0) {
                setSelectedBot(assistantsWithCalls[0]);
            }

        } catch (error) {
            console.error("Error fetching company data:", error);
        }
    };

    const getAllPersonasData = async () => {
        setSpinner(true)
        try {
            const res = await getAllPersonas(token)
            setPersonasData(res.persona)
            setSpinner(false)
        } catch (error) {
            console.log("🚀 ~ getAllPersonasData ~ error:", error)
            setSpinner(false)
        }
    }

    const handleAssistantModal = (data) => {
        console.log("🚀 ~ handleAssistantModal ~ data:", data)
        setBotData(data)
        setAssistantModal(true)
    }

    const handleSelectedAssitant = (data) => {
        console.log("🚀 ~ handleSelectedAssitant ~ item:", data)
        setSelectedPersonaIndex(data?.index)
        setBotInformation(data?.item)
        setBotName(data?.item?.name)
        setGender(data?.item?.gender)
        setFirstMessage(data?.item?.first_message)
        setOfficeVoice(data?.item?.background_sound)
        setEndCallPhrases(data?.item?.end_call_phrases)
        setEndCallGreetings(data?.item?.end_call_message)
        setPrompt(parseLines(data?.item?.persona_prompt))
    }

    const handleStepFirstToSecond = () => {
        if (!language) {
            toast.error("Please select the Language", {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            })
        } else {
            if (!purpose) {
                toast.error("Please select the Assistant Main Job", {
                    position: 'top-left',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast-error',
                })
            } else {
                if (selectedPersonaIndex == null && selectedSimpleOption == null) {
                    toast.error("Please select the assistant or design your own", {
                        position: 'top-left',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'custom-toast-error',
                    })
                } else {
                    setStep1(false)
                    setStep2(true)
                    setStep3(false)
                    setStep4(false)
                }
            }
        }

    }

    const handlePreviousFirstStep = () => {
        setStep1(true)
        setStep2(false)
        setStep3(false)
    }

    const handlePreviousSecondStep = () => {
        setStep1(false)
        setStep2(true)
        setStep3(false)
        setStep4(false)
    }

    const handlePreviousThirdStep = () => {
        setStep1(false)
        setStep2(false)
        setStep3(true)
        setStep4(false)
    }

    const handleStepTwoToThree = () => {
        setStep1(false)
        setStep2(false)
        setStep3(true)
        setStep4(false)
    }

    const handleStepThreeToFour = () => {
        setStep1(false)
        setStep2(false)
        setStep3(false)
        setStep4(true)
    }

    const handleBotNameChange = (e) => {
        console.log("🚀 ~ handleBotNameChange ~ e:", e.target.value)
        setBotName(e.target.value)
    }

    const handlePurpose = (e) => {
        console.log("🚀 ~ handlePurpose ~ e:", e.target.value)
        setPurpose(e.target.value)
    }

    const handleHavingNumber = (e) => {
        console.log("🚀 ~ handlePurpose ~ e:", e.target.value)
        setHavingNumber(e.target.value)
    }

    const handleFirstMessage = (e) => {
        console.log("🚀 ~ handleFirstMessage ~ e:", e.target.value)
        setFirstMessage(e.target.value)
    }

    const handleCreateBot = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("bot_name", botName);
            formData.append("company_id", getCompanyInfo?.id);
            formData.append("gender", gender);
            formData.append("purpose", purpose);
            formData.append("first_message", firstMessage);
            formData.append("prompt", prompt.replaceAll('\\n', '<br />'));
            formData.append("end_call_message", endCallGreetings);
            formData.append("end_call_phrases", endCallPhrases);
            formData.append("background_sound", officeVoice);
            formData.append("provider", provider === 'Twilio' ? 'Tw' : bookNumberData ? 'Tw' : 'Vo');
            formData.append("phone_number", bookNumberData ? bookNumberData?.phone : phone);
            formData.append("keywords", keywords);
            formData.append("provider_keys", bookNumberData ? `${bookNumberData?.ACCOUNT_SID}, ${bookNumberData?.AUTH_TOKEN}` : `${accountSID}, ${authToken}`);
            formData.append("language", language?.lang_name);
            formData.append("voice_id", botVoice);

            const res = await getCreateBot(token, formData);
            console.log("🚀 ~ handleCreateBot Error Bots ~ res:", res);
            setCreateBot(false)

            if (res.error) {
                setLoading(false);
                toast.error(res?.error?.phone_number[0], {
                    position: 'top-left',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast-error',
                });
                return;
            }

            handleGetAllCompany();
            toast.info("Your AI assistant is ready to work now", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });

            setStep1(true);
            setStep2(false);
            setStep3(false);
            setStep4(false);
            setLoading(false);

            // Clear form states
            setAssistantType(null);
            setBotName('');
            setGender('');
            setPurpose('');
            setFirstMessage('');
            setPrompt('');
            setEndCallGreetings('');
            setEndCallPhrases('');
            setOfficeVoice('');
            setPhone('');
            setProvider('');
            setAccountSID('');
            setAuthToken('');
            setProviderKeys('');
            setBotInformation(null);

        } catch (error) {
            console.log("🚀 ~ handleCreateBot ~ error:", error);
            toast.error(error?.error?.phone_number[0], {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
            setLoading(false);
        }
    };

    const handleBotPersona = async () => {
        setLoader(true)
        try {
            const formData = new FormData()
            if (!isEmpty(botInformation) && assistantType !== 'custom') {
                formData.append("name", botInformation?.name)
                formData.append("gender", botInformation?.gender)
                formData.append("role", purpose)
            } else {
                formData.append("name", botName)
                formData.append("gender", gender)
                formData.append("role", purpose ?? "Incoming")
            }
            const res = await generateBotPersona(token, formData)
            console.log("🚀 ~ handleBotPersona ~ res:", res)
            setPrompt(res[1])
            toast.info("Update the generated description as per your needs", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            })
            setLoader(false)
        } catch (error) {
            console.log("🚀 ~ handleBotPersona ~ error:", error)
            setLoader(false)
        }

    }

    const handleBotPersona2 = async (editName, editGender) => {
        setLoader(true)
        try {
            const formData = new FormData()
            formData.append("name", editName)
            formData.append("gender", editGender)
            formData.append("role", purpose ?? "Incoming")
            const res = await generateBotPersona(token, formData)
            console.log("🚀 ~ handleBotPersona ~ res:", res)
            setEditPrompt(res[1])
            toast.info("Update the generated description as per your needs", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            })
            setLoader(false)
        } catch (error) {
            console.log("🚀 ~ handleBotPersona ~ error:", error)
            setLoader(false)
        }

    }

    const handleViewCompanyPhoneNumber = async () => {
        try {
            const formData = new FormData()
            formData.append('company_id', getCompanyInfo?.id)
            const res = await getViewCompanyPhoneNumber(token, formData)
            setData(res.CompanyPhone)
        } catch (error) {
            console.log("🚀 ~ handleViewCompanyPhoneNumber ~ error:", error)
        }

    }

    const handleActionSelect = (row, action) => {
        if (action === "Edit") {
            handleEdit(row);
        } else if (action === "Delete") {
            handleDelete(row);
        }
    };

    const handleEdit = (data) => {
        console.log("🚀 ~ handleEdit ~ data:", data)
        const values = data?.provider_keys?.split(',');
        if (values?.length === 2) {
            // Set the SID and authToken state variables
            setEditAccountSID(values[0]);
            setEditAuthToken(values[1]);
        }
        setPhoneData(data)
        setEditName(data?.bot_name)
        setEditGender(data?.gender == 'M' ? 'Male' : 'Female')
        setEditFirstMessage(data?.first_message)
        setEditPrompt(data?.prompt)
        setEditEndCall(data?.end_call_message)
        setCallGreeting(data?.end_call_phrases)
        setEditBotId(data?.bot_id)
        setBackVoice(data?.background_sound == "office" ? "Office" : "None")
        setChangePhoneNumber(data?.phone_number)
        setEditProvider(data?.provider == "Tw" ? "Twilio" : "Vonage")
        // setEditModal(true)
        setEditKeywords(data?.keywords)
        // setEditLanguage(data?.language == 'es' ? 'Spanish' : data?.language == 'en-US' ? 'English (US)' : data?.language == 'en-AU' ? 'English (Aus)' : data?.language == 'en-GB' ? 'English (UK)' : data?.language == 'en-IN' ? 'English (Ind)' : data?.language == 'fr' ? 'French' : data?.language == 'de' ? 'German' : data?.language == 'zh-CN' ? 'Chinese' : "")
        setEditLanguage(data?.language)
        setPurpose(data?.purpose)
        setEditComponent(true)
        setFirstMessage(null)
        setEditBotVoice(data?.voice_id)


        // setBasicOpen(true)
    }

    const handleDelete = (data) => {
        console.log("🚀 ~ handleDelete ~ data:", data)
        setPhoneData(data)
        // setDeleteModal(true)
        setDeleteBotModal(true)
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false)
    }

    const handleCloseEditModal = () => {
        setEditModal(false)
    }

    const handleDeleteNumber = async () => {
        if (deleteItem == "Delete" || deleteItem == "delete") {
            console.log("🚀 ~ handleDeleteNumber ~ deleteItem:", phoneData)
            try {
                const formData = new FormData()
                formData.append("bot_id", phoneData?.bot_id)
                const res = await deleteBot(token, formData)
                toast.info("Successfully deleted!..", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast',
                });
                // handleViewCompanyPhoneNumber()
                handleGetAllCompany()
                setLoading(false)
                setDeleteBotModal(false)
            } catch (error) {
                console.log("🚀 ~ handleDeleteNumber ~ error:", error)
                toast.error(error?.response?.data?.error?.phone_id[0]);
                setLoading(false)
            }
        } else {
            // setDeleteBotModal(false)
            toast.error('Please type "Delete"', {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
        }
    }


    const maleVoices = [
        { value: "29vD33N1CtxCmqQRPOHJ", label: "Alex", },
        { value: "TX3LPaxmHKxFdv7VOQHJ", label: "Brian", },
        { value: "ErXwobaYiN019PkySvjV", label: "Kim", },
        { value: "5Q0t7uMcjvnagumLfvZi", label: "Jim", },
        { value: "VR6AewLTigWG4xSOukaG", label: "Chris", }
    ]

    const femaleVoices = [
        { value: "AZnzlk1XvdvUeBnXmlld", label: "Nikki" },
        { value: "MF3mGyEYCl7XYWbV9V6O", label: "Olivia" },
        { value: "FGY2WhTYpPnrIDTdsKH5", label: "Hannah" },
        { value: "jsCqWAovK2LkecY7zXl4", label: "Chrissy" },
        // { value: "4Zz6vJeLG1xnOhlak6XR", label: "Maya" }
    ]

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: theme == 'dark-only' ? 'black' : 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
            marginTop: '5px',
            padding: '5px',
            // width: isCollapsed ? '350px' : audioSrc ? '490px' : '490px'
            width: '100%'
            // fontWeight: '300'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px',
            // fontWeight: '300'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            '&:hover': {
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const customCollapseStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: theme == 'dark-only' ? 'black' : 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
            // width: audioSrc ? '350px' : '390px'
            // fontWeight: '300'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px',
            // fontWeight: '300'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            '&:hover': {
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const handleVoiceChange = (selectedOption) => {
        console.log("🚀 ~ handleVoiceChange ~ selectedOption:", selectedOption)
        setAssistantVoice(selectedOption);
        setBotVoice(selectedOption.value)
    };

    const getSelectedVoice = (value) => {
        const voices = editGender === 'Male' ? maleVoices : femaleVoices;
        return voices.find(voice => voice.value === value) || null;
    };

    useEffect(() => {
        if (editBotVoice) {
            const selectedVoice = getSelectedVoice(editBotVoice);
            setAssistantEditVoice(selectedVoice);
        }
    }, [editBotVoice, editGender]);

    const handleEditVoiceChange = (selectedOption) => {
        setAssistantEditVoice(selectedOption);
        setEditBotVoice(selectedOption.value)
    };

    const toggleAudioPlay = () => {
        if (isPlaying) {
            audioElement?.pause(); // Pause the audio
            setIsPlaying(false);
        } else {
            audioElement?.play(); // Play the audio
            setIsPlaying(true);
        }
    };

    useEffect(() => {
        if (botVoice) {
            getSelectedAssistantVoice(); // Fetch audio once botVoice is updated
        }
    }, [botVoice]);

    useEffect(() => {
        setBotVoice(null)
        if (editBotVoice) {
            getSelectedAssistantVoice(); // Fetch audio once botVoice is updated
        }
    }, [editBotVoice]);

    const getSelectedAssistantVoice = async () => {
        setAudioSrc(null)
        const text = firstMessage ? firstMessage : editFirstMessage;
        const voice = botVoice ? botVoice : editBotVoice;
        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            const response = await axios.post(
                'https://elk.fluten.ai/api/getBotVoiceModel',
                {
                    text,
                    voice,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': `Bearer ${token}`,
                    },
                    responseType: 'blob',
                }
            );

            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(audioBlob);

            setAudioSrc(audioUrl);
        } catch (error) {
            console.error('Error fetching audio:', error);
        }
    };

    const handleEditNumber = async (e) => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("bot_name", editName)
            formData.append("bot_id", editBotId)
            formData.append("company_id", getCompanyInfo?.id)
            formData.append("gender", editGender == 'Male' ? "M" : "F")
            formData.append("first_message", editFirstMessage)
            formData.append("end_call_message", editEndCall)
            formData.append("end_call_phrases", callGreeting)
            formData.append("background_sound", backVoice)
            formData.append("prompt", editPrompt)
            formData.append("provider", editProvider == 'Twilio' ? "Tw" : "Vo")
            formData.append("phone_number", changePhoneNumber)
            formData.append("keywords", editKeywords)
            formData.append("provider_keys", `${editAccountSID}, ${editAuthToken}`)
            formData.append("language", editLanguage.lang_name)
            formData.append("purpose", purpose)
            formData.append("voice_id", editBotVoice)

            const res = await editUpdateBot(token, formData)
            toast.info("Updated successfully", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            handleGetAllCompany()
            setEditName('');
            setEditGender('')
            setEditFirstMessage('')
            setEditPrompt('')
            setEditEndCall('')
            setCallGreeting('')
            setEditBotId('')
            setBackVoice('')
            setEditModal(false)
            setLoading(false)
            setEditComponent(false)
        } catch (error) {
            console.log("🚀 ~ handleEditSubmit ~ error:", error)
            setLoading(false)
        }
    };

    const hadleEditPhoneNumber = async () => {
        setNumberLoader(true)
        try {
            const formData = new FormData()
            formData.append('bot_id', editBotId)
            formData.append('company_id', getCompanyInfo?.id)
            formData.append('bot_name', editName)
            formData.append('purpose', purpose)
            formData.append('provider', editProvider == 'Twilio' ? "Tw" : "Vo")
            formData.append('phone_number', changePhoneNumber)
            formData.append('provider_keys', `${editAccountSID}, ${editAuthToken}`)
            formData.append('voice_id', botVoice)
            const res = await editBotNumber(token, formData)
            setNumberLoader(false)

            handleGetAllCompany()
            setEditName('');
            setEditBotId('')
            setBackVoice('')
            setEditModal(false)
            setLoading(false)
            setEditComponent(false)
        } catch (error) {
            console.log("🚀 ~ hadleEditPhoneNumber ~ error:", error)
            setNumberLoader(false)
        }

    }

    const parseLines = (value) => value.replace(/(\\n)/g, "\n");

    const DropdownMenu = ["Edit", "Delete"];

    const handleGetNumber = (data) => {
        setBookNumberModal(true)
        setBookNumberModalData(data)
    }

    const handleBookNumber = async (data) => {
        setTollButtonLoader(true)
        try {
            const formData = new FormData()
            formData.append('company_id', getCompanyInfo?.id)
            formData.append('phone_number', data?.phone_number)
            const res = await bookNumber(token, formData)
            setBookNumberData(res)
            setTollButtonLoader(false)
            setBookNumberModal(false)

        } catch (error) {
            console.log("🚀 ~ handleBookNumber ~ error:", error)
            setBookNumberModal(false)
            setTollButtonLoader(false)
        }

    }

    const handleBotClick = (botData) => {
        setSelectedBot(botData);
        setEditComponent(null)
        setAudioSrc(null)
        setAssistantVoice(null)
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };
    const companyInfo = getCurrentCompany();
    const ID = companyInfo?.company_type?.id;
    const getAllDefaultBots = async () => {
        setBotList(null)
        setAgentData(null)
        setLoading(true)
        try {
            const res = await defaultBots(localStorage.getItem('token'), ID, language.lang_name)
            setBotList(res.bots)
            if (!isEmpty(res?.bots)) {
                handleSelectBot(res?.bots?.[0]);
            }
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ getAllDefaultBots ~ error:", error)
            setLoading(false)
        }
    }

    const togglePrecastBotAudioPlay = (bot) => {
        if (isPlaying === bot.id) {
            // If the selected bot is already playing, pause it
            setIsPlaying(null);
            audioElement.pause();
        } else {
            // Play the new bot's audio
            setIsPlaying(bot.id);
            audioElement.play();
        }
    };

    return (
        <>
            {spinner ? (
                <Row style={{ paddingTop: '2%' }}>
                    <Col sm='12'>
                        <Card>
                            <CardBody style={{ padding: '10px' }}>
                                <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""}
                                    highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Skeleton height={30} style={{ width: '150px' }} />

                                        {/* <Skeleton height={30} style={{ width: '150px' }} /> */}
                                    </div>
                                </SkeletonTheme>
                                <Row>
                                    <Col sm='4'>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""}
                                            highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <Skeleton height={20} count={20} />
                                        </SkeletonTheme>
                                    </Col>
                                    <Col sm='8'>
                                        <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""}
                                            highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                            <Skeleton height={20} count={20} />
                                        </SkeletonTheme>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            ) : (
                <Card style={{ boxShadow: 'none', marginTop: '8%' }}>
                    <CardBody style={{ padding: '0px' }}>
                        {!createBot &&
                            <Row>
                                <>
                                    {staffData?.length > 0 ? (
                                        <Col sm="12">
                                            <Row>
                                                <Col sm='4'>
                                                    <Card style={{
                                                        height: '722px',
                                                        overflow: 'scroll',
                                                        padding: '0px',
                                                        boxShadow: 'none',
                                                        backgroundColor: '#F6F8FF',
                                                        margin: '1%',
                                                        marginLeft: '2%',
                                                    }}>
                                                        <CardBody style={{ padding: '0px' }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                padding: '20px'
                                                            }}>
                                                                <h4 style={{
                                                                    marginBottom: '0px',
                                                                    textAlign: 'left',
                                                                    color: theme === 'dark-only' ? 'grey' : '#013165',
                                                                    marginTop: '5px'
                                                                }}>Assistant</h4>
                                                                <div>
                                                                    <div
                                                                        id="addAssistantButton"
                                                                        style={{
                                                                            border: '1px solid #EBEBEB',
                                                                            width: '30px',
                                                                            height: '30px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            borderRadius: '5px',
                                                                            backgroundColor: theme === 'dark-only' ? "#F9F8F8" : '#F9F8F8',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            if (!canCreateAssistants) {
                                                                                setShowUpgradePopup(true);
                                                                                return;
                                                                            }
                                                                            // setAssistantType(null);
                                                                            // setSelectedBot();
                                                                            // handleSelectBot(botList?.[0]);
                                                                            setSelectedBot(null);
                                                                            setEditComponent(null);
                                                                            setAudioSrc(null)
                                                                            setAssistantVoice(null)
                                                                            setEditFirstMessage(null)
                                                                        }}
                                                                        onMouseEnter={() => handleMouseEnter('addAssistantButton')}
                                                                        onMouseLeave={() => handleMouseLeave('addAssistantButton')}
                                                                    >
                                                                        <AiOutlinePlus />
                                                                    </div>

                                                                    <Popover
                                                                        placement="top"
                                                                        isOpen={popoverOpen?.addAssistantButton}
                                                                        target="addAssistantButton"
                                                                        toggle={() => {
                                                                        }}
                                                                    >
                                                                        <PopoverBody>Add new assistant</PopoverBody>
                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {staffData?.map((item, index) => {
                                                                    let data = item
                                                                    const isSelected = selectedBot === data;
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                margin: '10px 10px 0px 10px',
                                                                                borderBottom: '2px solid #D3E9FF',
                                                                                backgroundColor: isSelected ? 'white' : 'transparent',
                                                                                cursor: 'pointer',
                                                                                padding: '10px 10px',
                                                                                borderRadius: '15px',
                                                                            }}
                                                                            onClick={() => {
                                                                                handleBotClick(data);
                                                                            }}
                                                                        >
                                                                            <div style={{ display: 'flex', }}>
                                                                                <div style={{
                                                                                    width: '18%',
                                                                                    marginTop: '10px',
                                                                                    position: 'relative'
                                                                                }}>
                                                                                    <div
                                                                                        className={data?.purpose === "Scheduling" ? 'social-img-scheduling' : 'social-img-in'}
                                                                                        style={{ position: 'relative' }}>
                                                                                        <img style={{
                                                                                            width: '52px',
                                                                                            height: '52px',
                                                                                            borderRadius: '50px',
                                                                                            position: 'relative'
                                                                                        }}
                                                                                            src={data.picture ? data.picture : data?.gender === 'M' ? botImageMale : botImageFemale} />
                                                                                        {/* <img
                                                                                            style={{
                                                                                                width: '20px',
                                                                                                height: '20px',
                                                                                                borderRadius: '50px',
                                                                                                position: 'absolute',
                                                                                                bottom: data.purpose === 'Scheduling' ? '0%' : '0%',
                                                                                                left: '70%'
                                                                                            }}
                                                                                            src={data.purpose === 'Scheduling' ? calendar : calling}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div style={{ marginLeft: '5px' }}>
                                                                                    <div style={{
                                                                                        justifyContent: 'space-between',
                                                                                        width: '100%',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }} className='someMargin'>
                                                                                        <h4 style={{
                                                                                            marginBottom: '3px',
                                                                                            textAlign: 'left',
                                                                                            fontSize: '16px',
                                                                                            color: '#FF3464',
                                                                                            marginTop: '10px',
                                                                                            fontWeight: 'bold',
                                                                                            cursor: 'pointer',
                                                                                            textTransform: 'capitalize'
                                                                                        }}>{data?.bot_name} </h4>
                                                                                        <p style={{
                                                                                            fontSize: '12px',
                                                                                            color: '#5679D5',
                                                                                            fontWeight: '500'
                                                                                        }}>{data?.language == 'en-US' ? 'English (US)' : data?.language == 'en-AU' ? 'English (Aus)' : data?.language == 'en-GB' ? 'English (UK)' : data?.language == 'en-IN' ? 'English (Ind)' : data?.language == 'fr' ? 'French' : data?.language == 'es' ? 'Spanish' : data?.language == 'de' ? 'German' : data?.language == 'zh-CN' ? 'Chinese' : ''}</p>
                                                                                    </div>

                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <img style={{
                                                                                            width: '20px',
                                                                                            height: '20px',
                                                                                            borderRadius: '50px'
                                                                                        }} src={inCallsIcon} />
                                                                                        <p style={{
                                                                                            color: '#FC5455',
                                                                                            fontWeight: '500',
                                                                                            fontSize: '12px',
                                                                                            marginLeft: '5px'
                                                                                        }}>{data?.purpose === "Scheduling" ? 'Scheduling' : 'Incoming'}</p>
                                                                                    </div>

                                                                                    <p style={{
                                                                                        fontSize: '14px',
                                                                                        fontWeight: '300',
                                                                                        textAlign: 'left',
                                                                                        height: '35px',
                                                                                        overflow: 'hidden',
                                                                                        color: 'grey',
                                                                                        marginTop: '5px',
                                                                                        lineHeight: 1.2
                                                                                    }}
                                                                                        dangerouslySetInnerHTML={{ __html: data?.prompt?.replaceAll('\\n', '<br />') }}></p>
                                                                                    <p style={{
                                                                                        fontSize: '12px',
                                                                                        textAlign: 'left',
                                                                                        fontWeight: '500',
                                                                                        textDecorationLine: 'underline',
                                                                                        color: theme === 'dark-only' ? 'grey' : 'grey',
                                                                                        cursor: 'pointer',
                                                                                        marginTop: '-15px'
                                                                                    }}
                                                                                        onClick={() => handleReadBioModal(data)}>More...</p>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <div style={{
                                                                                            borderRadius: '5px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                            <img style={{
                                                                                                width: '20px',
                                                                                                height: '20px',
                                                                                                borderRadius: '50px'
                                                                                            }} src={callIcon} />
                                                                                            <p style={{
                                                                                                textAlign: 'left',
                                                                                                color: theme === 'dark-only' ? 'grey' : '#5679D5',
                                                                                                fontSize: '12px',
                                                                                                fontWeight: 'bold',
                                                                                                marginBottom: '0px',
                                                                                                marginLeft: '5px'
                                                                                            }}>Total Calls :</p>
                                                                                            <p style={{
                                                                                                textAlign: 'left',
                                                                                                color: theme === 'dark-only' ? 'grey' : '#5679D5',
                                                                                                fontSize: '12px',
                                                                                                fontWeight: 'bold',
                                                                                                marginBottom: '0px',
                                                                                                marginLeft: '5px'
                                                                                            }}>{item?.totalCallsCount}</p>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            borderRadius: '5px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            marginLeft: '15px'
                                                                                        }}>
                                                                                            <img style={{
                                                                                                width: '15px',
                                                                                                height: '15px'
                                                                                            }} src={forwardCallIcon} />
                                                                                            <p style={{
                                                                                                textAlign: 'left',
                                                                                                color: theme === 'dark-only' ? 'grey' : '#FC5455',
                                                                                                fontSize: '12px',
                                                                                                fontWeight: 'bold',
                                                                                                marginBottom: '0px',
                                                                                                marginLeft: '5px'
                                                                                            }}>Forwarded :</p>
                                                                                            <p style={{
                                                                                                textAlign: 'left',
                                                                                                color: theme === 'dark-only' ? 'grey' : '#FC5455',
                                                                                                fontSize: '12px',
                                                                                                fontWeight: 'bold',
                                                                                                marginBottom: '0px',
                                                                                                marginLeft: '5px'
                                                                                            }}>{item?.forwardedCallCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                {editComponent ?
                                                    (
                                                        <Col sm="7">
                                                            <Card style={{ boxShadow: 'none' }}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col sm="12">

                                                                            <H4>Edit Assistant - <span
                                                                                style={{ textTransform: 'capitalize' }}>{editName}</span></H4>
                                                                            <Row>
                                                                                <Col sm='4'>
                                                                                    <FormGroup>
                                                                                        <Label
                                                                                            className="col-sm-12 col-form-label"
                                                                                            htmlFor="inputEmail3"
                                                                                            style={{
                                                                                                fontWeight: '500',
                                                                                                color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                            }}>AI Assistant Name</Label>
                                                                                        <Col sm="12">
                                                                                            <Input
                                                                                                type="text"
                                                                                                id="name"
                                                                                                value={editName}
                                                                                                onChange={(e) => setEditName(e.target.value)}
                                                                                                placeholder="Enter name"
                                                                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                            />
                                                                                        </Col>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col sm='4'>
                                                                                    <FormGroup>
                                                                                        <Label
                                                                                            className="col-sm-12 col-form-label"
                                                                                            htmlFor="provider" style={{
                                                                                                fontWeight: '500',
                                                                                                color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                marginTop: '5%'
                                                                                            }}>Gender</Label>
                                                                                        <Col sm="9">
                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'space-between'
                                                                                            }}>
                                                                                                {["Male", "Female"]?.map((option) => (
                                                                                                    <div key={option}
                                                                                                        className="form-check form-check-inline"
                                                                                                        style={{ marginTop: '5px' }}>
                                                                                                        <Input
                                                                                                            type="radio"
                                                                                                            id={`gender-${option}`}
                                                                                                            value={option}
                                                                                                            checked={editGender === option}
                                                                                                            onChange={(e) => setEditGender(e.target.value)}
                                                                                                            name="gender"
                                                                                                            className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                        />
                                                                                                        <Label style={{
                                                                                                            marginTop: '5px',
                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                        }}
                                                                                                            className="form-check-label"
                                                                                                            htmlFor={`gender-${option}`}>
                                                                                                            {option}
                                                                                                        </Label>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        </Col>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col sm='4'>
                                                                                    <FormGroup>
                                                                                        <Label
                                                                                            className="col-sm-12 col-form-label"
                                                                                            htmlFor="language" style={{
                                                                                                fontWeight: '500',
                                                                                                color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                            }}>Language</Label>
                                                                                        <Col sm="12">
                                                                                            <LanguageSelect
                                                                                                languageMap={LANGUAGES_MAP}
                                                                                                selectedLanguage={editLanguage}
                                                                                                onLanguageChange={handleEditLanguageChange}
                                                                                            />
                                                                                        </Col>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col sm='6'>
                                                                                    <FormGroup>
                                                                                        <Label
                                                                                            className="col-sm-12 col-form-label"
                                                                                            htmlFor="firstMessage"
                                                                                            style={{
                                                                                                fontWeight: '500',
                                                                                                color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                            }}>
                                                                                            Welcome Message
                                                                                            <IoIosHelpCircleOutline
                                                                                                id="PopoverFirstMessage"
                                                                                                size={20}
                                                                                                style={{
                                                                                                    marginLeft: 8,
                                                                                                    cursor: 'pointer',
                                                                                                    marginBottom: '-5px'
                                                                                                }}
                                                                                                onMouseEnter={() => handleMouseEnter('firstMessage')}
                                                                                                onMouseLeave={() => handleMouseLeave('firstMessage')}
                                                                                            />
                                                                                            <Popover
                                                                                                placement="top"
                                                                                                isOpen={popoverOpen.firstMessage}
                                                                                                target="PopoverFirstMessage"
                                                                                                toggle={() => {
                                                                                                }}
                                                                                            >
                                                                                                <PopoverBody>When a call
                                                                                                    is connected, the
                                                                                                    assistant should
                                                                                                    start with a
                                                                                                    friendly greeting,
                                                                                                    such as, "Hello,
                                                                                                    this is Amanda
                                                                                                    speaking on behalf
                                                                                                    of Fluten.ai. How
                                                                                                    can I assist you
                                                                                                    today?"</PopoverBody>
                                                                                            </Popover>
                                                                                        </Label>
                                                                                        <Col sm="12">
                                                                                            <Input
                                                                                                type="textarea"
                                                                                                id="name"
                                                                                                value={editFirstMessage}
                                                                                                onChange={(e) => setEditFirstMessage(e.target.value)}
                                                                                                placeholder="Enter name"
                                                                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                            />
                                                                                        </Col>
                                                                                    </FormGroup>

                                                                                    {isCollapsed &&
                                                                                        <>
                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="endCallWords"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    End Call Words
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverEndCallWords"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('endCallWords')}
                                                                                                        onMouseLeave={() => handleMouseLeave('endCallWords')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.endCallWords}
                                                                                                        target="PopoverEndCallWords"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>The
                                                                                                            assistant
                                                                                                            will
                                                                                                            conclude the
                                                                                                            conversation
                                                                                                            with a
                                                                                                            closing
                                                                                                            message
                                                                                                            before
                                                                                                            ending the
                                                                                                            call. For
                                                                                                            instance,
                                                                                                            they might
                                                                                                            say, 'Have a
                                                                                                            great
                                                                                                            day!'</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="name"
                                                                                                        value={editEndCall}
                                                                                                        onChange={(e) => setEditEndCall(e.target.value)}
                                                                                                        placeholder="Enter call words"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="endCallGreetings"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    End Call Greetings
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverEndCallGreetings"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('endCallGreetings')}
                                                                                                        onMouseLeave={() => handleMouseLeave('endCallGreetings')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.endCallGreetings}
                                                                                                        target="PopoverEndCallGreetings"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>The
                                                                                                            assistant
                                                                                                            will
                                                                                                            conclude the
                                                                                                            conversation
                                                                                                            with a
                                                                                                            closing
                                                                                                            message
                                                                                                            before
                                                                                                            ending the
                                                                                                            call. For
                                                                                                            instance,
                                                                                                            the
                                                                                                            assistant
                                                                                                            will say,
                                                                                                            “If you have
                                                                                                            any other
                                                                                                            questions,
                                                                                                            feel free to
                                                                                                            call back.
                                                                                                            Have a
                                                                                                            wonderful
                                                                                                            day.
                                                                                                            Bye.”</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>

                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="name"
                                                                                                        value={callGreeting}
                                                                                                        onChange={(e) => setCallGreeting(e.target.value)}
                                                                                                        placeholder="Enter call greetings"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                            <div
                                                                                                style={{ marginTop: '3%' }}>
                                                                                                <FormGroup>
                                                                                                    <Label
                                                                                                        className="col-sm-12 col-form-label"
                                                                                                        htmlFor="assistantVoice"
                                                                                                        style={{
                                                                                                            fontWeight: '500',
                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                        }}>
                                                                                                        Assistant Voice
                                                                                                        Should Sound
                                                                                                        Like
                                                                                                        <IoIosHelpCircleOutline
                                                                                                            id="PopoverAssistantVoice"
                                                                                                            size={20}
                                                                                                            style={{
                                                                                                                marginLeft: 8,
                                                                                                                cursor: 'pointer',
                                                                                                                marginBottom: '-5px'
                                                                                                            }}
                                                                                                            onMouseEnter={() => handleMouseEnter('assistantVoice')}
                                                                                                            onMouseLeave={() => handleMouseLeave('assistantVoice')}
                                                                                                        />
                                                                                                        <Popover
                                                                                                            placement="top"
                                                                                                            isOpen={popoverOpen.assistantVoice}
                                                                                                            target="PopoverAssistantVoice"
                                                                                                            toggle={() => {
                                                                                                            }}
                                                                                                        >
                                                                                                            <PopoverBody>Select
                                                                                                                the
                                                                                                                voice
                                                                                                                for the
                                                                                                                assistant.
                                                                                                                Different
                                                                                                                voices
                                                                                                                provide
                                                                                                                distinct
                                                                                                                styles
                                                                                                                and
                                                                                                                tones
                                                                                                                for the
                                                                                                                assistant’s
                                                                                                                responses.</PopoverBody>
                                                                                                        </Popover>
                                                                                                    </Label>
                                                                                                    <Col sm="12">
                                                                                                        <div style={{
                                                                                                            display: 'flex',
                                                                                                            alignItems: 'center'
                                                                                                        }}>
                                                                                                            <div
                                                                                                                style={{ width: '100%' }}>
                                                                                                                <Select
                                                                                                                    id="edit_voice"
                                                                                                                    name="voice_id"
                                                                                                                    options={editGender == 'Male' ? maleVoices : femaleVoices}
                                                                                                                    value={assistantEditVoice}
                                                                                                                    onChange={handleVoiceChange}
                                                                                                                    styles={customCollapseStyles}
                                                                                                                    placeholder={'Select Voice'}
                                                                                                                />
                                                                                                            </div>
                                                                                                            {/* {audioSrc && */}
                                                                                                            <div
                                                                                                                onClick={toggleAudioPlay}
                                                                                                                style={{
                                                                                                                    marginLeft: '10px',
                                                                                                                    cursor: 'pointer'
                                                                                                                }}>
                                                                                                                {isPlaying ? (
                                                                                                                    <IoPauseCircleOutline
                                                                                                                        size={25}
                                                                                                                        style={{ marginTop: '5px' }} />
                                                                                                                ) : (
                                                                                                                    <IoPlayCircleOutline
                                                                                                                        size={25}
                                                                                                                        style={{ marginTop: '5px' }} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                            {/* } */}
                                                                                                        </div>
                                                                                                        <audio
                                                                                                            ref={(audio) => setAudioElement(audio)}
                                                                                                            src={audioSrc}
                                                                                                            type="audio/mpeg"
                                                                                                            onEnded={() => setIsPlaying(false)}
                                                                                                            style={{ display: 'none' }}
                                                                                                        />
                                                                                                    </Col>
                                                                                                </FormGroup>
                                                                                            </div>

                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="backVoice"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    Background Voice
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverBackVoice"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('backVoice')}
                                                                                                        onMouseLeave={() => handleMouseLeave('backVoice')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.backVoice}
                                                                                                        target="PopoverBackVoice"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>Selecting
                                                                                                            the "Office"
                                                                                                            option will
                                                                                                            allow the
                                                                                                            caller to
                                                                                                            experience a
                                                                                                            realistic
                                                                                                            work
                                                                                                            environment
                                                                                                            with office
                                                                                                            background
                                                                                                            sounds,
                                                                                                            creating a
                                                                                                            more
                                                                                                            authentic
                                                                                                            interaction
                                                                                                            with the
                                                                                                            assistant.</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="select"
                                                                                                        value={backVoice}
                                                                                                        onChange={(e) => setBackVoice(e.target.value)}
                                                                                                        name="backVoice"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    >
                                                                                                        {!backVoice &&
                                                                                                            <option
                                                                                                                value="">Select
                                                                                                                Voice</option>}
                                                                                                        {["Office", "None"]?.map(option => (
                                                                                                            <option
                                                                                                                key={option}
                                                                                                                value={option}>{option}</option>
                                                                                                        ))}
                                                                                                    </Input>
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="keywords"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    Keywords (Catch
                                                                                                    Phrases)
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverKeywords"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('keywords')}
                                                                                                        onMouseLeave={() => handleMouseLeave('keywords')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.keywords}
                                                                                                        target="PopoverKeywords"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>Fluten.ai
                                                                                                            is designed
                                                                                                            to capture
                                                                                                            keywords
                                                                                                            used during
                                                                                                            conversations
                                                                                                            between the
                                                                                                            assistant
                                                                                                            and the
                                                                                                            caller. This
                                                                                                            feature will
                                                                                                            provide
                                                                                                            valuable
                                                                                                            insights
                                                                                                            into the
                                                                                                            topics being
                                                                                                            discussed
                                                                                                            and the
                                                                                                            frequency of
                                                                                                            keyword
                                                                                                            usage in
                                                                                                            each call.
                                                                                                            Each keyword
                                                                                                            needs to be
                                                                                                            separated
                                                                                                            with a comma
                                                                                                            (,).</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="textarea"
                                                                                                        id="name"
                                                                                                        value={editKeywords == 'undefined' ? "" : editKeywords}
                                                                                                        onChange={(e) => setEditKeywords(e.target.value)}
                                                                                                        placeholder="Business, Email, Sale, Insurance"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </>
                                                                                    }
                                                                                </Col>

                                                                                <Col sm={'6'}>
                                                                                    <CardBody style={{
                                                                                        borderRadius: '10px',
                                                                                        border: '1px solid #DDDBDB',
                                                                                        padding: '10px',
                                                                                        // marginTop:isCollapsed ? "" : '40px' 
                                                                                    }}>

                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                            <H5>Advanced Settings</H5>
                                                                                            <div
                                                                                                onClick={() => setIsCollapsed(!isCollapsed)}
                                                                                                style={{
                                                                                                    cursor: 'pointer',
                                                                                                    marginLeft: 'auto'
                                                                                                }}
                                                                                            >
                                                                                                {!isCollapsed ?
                                                                                                    <FaChevronDown
                                                                                                        color={theme === 'dark-only' ? 'grey' : 'black'} /> :
                                                                                                    <FaChevronUp
                                                                                                        color={theme === 'dark-only' ? 'grey' : 'black'} />}
                                                                                            </div>
                                                                                        </div>

                                                                                        {isCollapsed &&
                                                                                            <>
                                                                                                <FormGroup>
                                                                                                    <Label
                                                                                                        className="col-sm-12 col-form-label"
                                                                                                        htmlFor="inputEmail3"
                                                                                                        style={{
                                                                                                            fontWeight: '500',
                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                        }}>Phone
                                                                                                        Number</Label>
                                                                                                    <Col sm="12">
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            id="name"
                                                                                                            value={formatPhoneNumber(changePhoneNumber)}
                                                                                                            onChange={(e) => setChangePhoneNumber(e.target.value)}
                                                                                                            placeholder="Enter phone"
                                                                                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                        />
                                                                                                    </Col>
                                                                                                </FormGroup>
                                                                                                <FormGroup>
                                                                                                    <Label
                                                                                                        className="col-sm-12 col-form-label"
                                                                                                        htmlFor="provider"
                                                                                                        style={{
                                                                                                            fontWeight: '500',
                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                        }}>Provider</Label>
                                                                                                    <Col sm="12">
                                                                                                        <Input
                                                                                                            type="select"
                                                                                                            value={editProvider}
                                                                                                            onChange={handleEditProviderChange}
                                                                                                            name="provider"
                                                                                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                        >
                                                                                                            {!editProvider &&
                                                                                                                <option
                                                                                                                    value="">Select
                                                                                                                    Provider</option>}
                                                                                                            {["Twilio", "Vonage"]?.map(option => (
                                                                                                                <option
                                                                                                                    key={option}
                                                                                                                    value={option}>{option}</option>
                                                                                                            ))}
                                                                                                        </Input>
                                                                                                    </Col>
                                                                                                </FormGroup>

                                                                                                {editProvider && (
                                                                                                    <>
                                                                                                        <FormGroup>
                                                                                                            <Col sm="12"
                                                                                                                style={{ position: 'relative' }}>
                                                                                                                <div
                                                                                                                    style={{ display: 'flex' }}>
                                                                                                                    <Label
                                                                                                                        className="col-sm-11 col-form-label"
                                                                                                                        htmlFor="inputEmail3"
                                                                                                                        style={{
                                                                                                                            fontWeight: '500',
                                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                                        }}>Account
                                                                                                                        SID</Label>
                                                                                                                    <div
                                                                                                                        onClick={() => setShowAccountSID(!showAccountSID)}
                                                                                                                        style={{
                                                                                                                            cursor: 'pointer',
                                                                                                                            marginTop: '10px'
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {showAccountSID ?
                                                                                                                            <FaEyeSlash
                                                                                                                                color={theme === 'dark-only' ? 'grey' : 'black'} /> :
                                                                                                                            <FaEye
                                                                                                                                color={theme === 'dark-only' ? 'grey' : 'black'} />}
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <Input
                                                                                                                    type={showAccountSID ? 'text' : 'password'} // Toggle between text and password
                                                                                                                    id="accountSID"
                                                                                                                    value={editAccountSID}
                                                                                                                    onChange={(e) => setEditAccountSID(e.target.value)}
                                                                                                                    placeholder="Enter Account SID"
                                                                                                                    className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                                    style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                                />

                                                                                                            </Col>
                                                                                                        </FormGroup>

                                                                                                        <FormGroup>
                                                                                                            <div
                                                                                                                style={{ display: 'flex' }}>
                                                                                                                <Label
                                                                                                                    className="col-sm-11 col-form-label"
                                                                                                                    htmlFor="inputEmail3"
                                                                                                                    style={{
                                                                                                                        fontWeight: '500',
                                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                                    }}>AuthToken</Label>
                                                                                                                <div
                                                                                                                    onClick={() => setShowAuthToken(!showAuthToken)}
                                                                                                                    style={{
                                                                                                                        cursor: 'pointer',
                                                                                                                        marginTop: '10px'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {showAuthToken ?
                                                                                                                        <FaEyeSlash
                                                                                                                            color={theme === 'dark-only' ? 'grey' : 'black'} /> :
                                                                                                                        <FaEye
                                                                                                                            color={theme === 'dark-only' ? 'grey' : 'black'} />}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <Col sm="12"
                                                                                                                style={{ position: 'relative' }}>
                                                                                                                <Input
                                                                                                                    type={showAuthToken ? 'text' : 'password'} // Toggle between text and password
                                                                                                                    id="authToken"
                                                                                                                    value={editAuthToken}
                                                                                                                    onChange={(e) => setEditAuthToken(e.target.value)}
                                                                                                                    placeholder="Enter AuthToken"
                                                                                                                    className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                                    style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                                />
                                                                                                            </Col>
                                                                                                        </FormGroup>
                                                                                                        <div style={{
                                                                                                            display: 'flex',
                                                                                                            alignItems: 'center',
                                                                                                            justifyContent: 'flex-end',
                                                                                                            width: '100%'
                                                                                                        }}>
                                                                                                            {loading ?
                                                                                                                <Button
                                                                                                                    disabled>
                                                                                                                    <Spinner
                                                                                                                        as="span"
                                                                                                                        animation="grow"
                                                                                                                        size="sm"
                                                                                                                        role="status"
                                                                                                                        aria-hidden="true"
                                                                                                                    />
                                                                                                                    Saving...
                                                                                                                </Button>
                                                                                                                :
                                                                                                                <Button
                                                                                                                    style={{ height: '40px' }}
                                                                                                                    onClick={hadleEditPhoneNumber}>Save</Button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        }
                                                                                    </CardBody>

                                                                                </Col>
                                                                                <>
                                                                                    {!isCollapsed &&
                                                                                        <Col sm='12'>
                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="endCallWords"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    End Call Words
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverEndCallWords"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('endCallWords')}
                                                                                                        onMouseLeave={() => handleMouseLeave('endCallWords')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.endCallWords}
                                                                                                        target="PopoverEndCallWords"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>The
                                                                                                            assistant
                                                                                                            will
                                                                                                            consider
                                                                                                            this as a
                                                                                                            sign of
                                                                                                            closing the
                                                                                                            call.
                                                                                                            Assistant
                                                                                                            will speak
                                                                                                            the 'End
                                                                                                            call
                                                                                                            Message' and
                                                                                                            disconnects
                                                                                                            the
                                                                                                            call</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="name"
                                                                                                        value={editEndCall}
                                                                                                        onChange={(e) => setEditEndCall(e.target.value)}
                                                                                                        placeholder="Enter call words"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="endCallGreetings"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    End Call Greetings
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverEndCallGreetings"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('endCallGreetings')}
                                                                                                        onMouseLeave={() => handleMouseLeave('endCallGreetings')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.endCallGreetings}
                                                                                                        target="PopoverEndCallGreetings"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>The
                                                                                                            assistant
                                                                                                            will
                                                                                                            conclude the
                                                                                                            conversation
                                                                                                            with a
                                                                                                            closing
                                                                                                            message
                                                                                                            before
                                                                                                            ending the
                                                                                                            call. For
                                                                                                            instance,
                                                                                                            the
                                                                                                            assistant
                                                                                                            will say,
                                                                                                            “If you have
                                                                                                            any other
                                                                                                            questions,
                                                                                                            feel free to
                                                                                                            call back.
                                                                                                            Have a
                                                                                                            wonderful
                                                                                                            day.
                                                                                                            Bye.”</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="name"
                                                                                                        value={callGreeting}
                                                                                                        onChange={(e) => setCallGreeting(e.target.value)}
                                                                                                        placeholder="Enter call greetings"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                            {/* <div style={{ marginTop: '3%' }}>
                                                                                            <FormGroup>
                                                                                                <Label className="col-sm-12 col-form-label" htmlFor="endCallGreetings" style={{ fontWeight: '500', color: theme === 'dark-only' ? 'grey' : 'black' }}>Assistant Voice</Label>
                                                                                                <Col sm="9">
                                                                                                    <Select
                                                                                                        id="edit_voice"
                                                                                                        name="voice_id"
                                                                                                        options={voiceOptions}
                                                                                                        value={assistantVoice}
                                                                                                        onChange={handleVoiceChange}
                                                                                                        styles={customStyles}
                                                                                                        placeholder={'Select Voice'}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </div> */}

                                                                                            <div
                                                                                                style={{ marginTop: '3%' }}>
                                                                                                <FormGroup>
                                                                                                    <Label
                                                                                                        className="col-sm-12 col-form-label"
                                                                                                        htmlFor="assistantVoice"
                                                                                                        style={{
                                                                                                            fontWeight: '500',
                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                        }}>
                                                                                                        Assistant Voice
                                                                                                        Should Sound
                                                                                                        Like
                                                                                                        <IoIosHelpCircleOutline
                                                                                                            id="PopoverAssistantVoice"
                                                                                                            size={20}
                                                                                                            style={{
                                                                                                                marginLeft: 8,
                                                                                                                cursor: 'pointer',
                                                                                                                marginBottom: '-5px'
                                                                                                            }}
                                                                                                            onMouseEnter={() => handleMouseEnter('assistantVoice')}
                                                                                                            onMouseLeave={() => handleMouseLeave('assistantVoice')}
                                                                                                        />
                                                                                                        <Popover
                                                                                                            placement="top"
                                                                                                            isOpen={popoverOpen.assistantVoice}
                                                                                                            target="PopoverAssistantVoice"
                                                                                                            toggle={() => {
                                                                                                            }}
                                                                                                        >
                                                                                                            <PopoverBody>Select
                                                                                                                the
                                                                                                                voice
                                                                                                                for the
                                                                                                                assistant.
                                                                                                                Different
                                                                                                                voices
                                                                                                                provide
                                                                                                                distinct
                                                                                                                styles
                                                                                                                and
                                                                                                                tones
                                                                                                                for the
                                                                                                                assistant’s
                                                                                                                responses.</PopoverBody>
                                                                                                        </Popover>
                                                                                                    </Label>
                                                                                                    <Col sm="12">
                                                                                                        <div style={{
                                                                                                            display: 'flex',
                                                                                                            alignItems: 'center'
                                                                                                        }}>
                                                                                                            <div
                                                                                                                style={{ width: '100%' }}>
                                                                                                                <Select
                                                                                                                    id="edit_voice"
                                                                                                                    name="voice_id"
                                                                                                                    options={editGender == 'Male' ? maleVoices : femaleVoices}
                                                                                                                    value={assistantEditVoice}
                                                                                                                    onChange={handleEditVoiceChange}
                                                                                                                    styles={customStyles}
                                                                                                                    placeholder={'Select Voice'}
                                                                                                                />
                                                                                                            </div>
                                                                                                            {audioSrc &&
                                                                                                                <div
                                                                                                                    onClick={toggleAudioPlay}
                                                                                                                    style={{
                                                                                                                        marginLeft: '10px',
                                                                                                                        cursor: 'pointer'
                                                                                                                    }}>
                                                                                                                    {isPlaying ? (
                                                                                                                        <IoPauseCircleOutline
                                                                                                                            size={25}
                                                                                                                            style={{ marginTop: '5px' }} />
                                                                                                                    ) : (
                                                                                                                        <IoPlayCircleOutline
                                                                                                                            size={25}
                                                                                                                            style={{ marginTop: '5px' }} />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <audio
                                                                                                            ref={(audio) => setAudioElement(audio)}
                                                                                                            src={audioSrc}
                                                                                                            type="audio/mpeg"
                                                                                                            onEnded={() => setIsPlaying(false)}
                                                                                                            style={{ display: 'none' }}
                                                                                                        />
                                                                                                    </Col>
                                                                                                </FormGroup>
                                                                                            </div>


                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="backVoice"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    Background Voice
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverBackVoice"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('backVoice')}
                                                                                                        onMouseLeave={() => handleMouseLeave('backVoice')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.backVoice}
                                                                                                        target="PopoverBackVoice"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>Selecting
                                                                                                            the "Office"
                                                                                                            option will
                                                                                                            allow the
                                                                                                            caller to
                                                                                                            experience a
                                                                                                            realistic
                                                                                                            work
                                                                                                            environment
                                                                                                            with office
                                                                                                            background
                                                                                                            sounds,
                                                                                                            creating a
                                                                                                            more
                                                                                                            authentic
                                                                                                            interaction
                                                                                                            with the
                                                                                                            assistant.</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="select"
                                                                                                        value={backVoice}
                                                                                                        onChange={(e) => setBackVoice(e.target.value)}
                                                                                                        name="backVoice"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    >
                                                                                                        {!backVoice &&
                                                                                                            <option
                                                                                                                value="">Select
                                                                                                                Voice</option>}
                                                                                                        {["Office", "None"]?.map(option => (
                                                                                                            <option
                                                                                                                key={option}
                                                                                                                value={option}>{option}</option>
                                                                                                        ))}
                                                                                                    </Input>
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="keywords"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    Keywords (Catch
                                                                                                    Phrases)
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverKeywords"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('keywords')}
                                                                                                        onMouseLeave={() => handleMouseLeave('keywords')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.keywords}
                                                                                                        target="PopoverKeywords"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>Fluten.ai
                                                                                                            is designed
                                                                                                            to capture
                                                                                                            keywords
                                                                                                            used during
                                                                                                            conversations
                                                                                                            between the
                                                                                                            assistant
                                                                                                            and the
                                                                                                            caller. This
                                                                                                            feature will
                                                                                                            provide
                                                                                                            valuable
                                                                                                            insights
                                                                                                            into the
                                                                                                            topics being
                                                                                                            discussed
                                                                                                            and the
                                                                                                            frequency of
                                                                                                            keyword
                                                                                                            usage in
                                                                                                            each call.
                                                                                                            Each keyword
                                                                                                            needs to be
                                                                                                            separated
                                                                                                            with a comma
                                                                                                            (,).</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="12">
                                                                                                    <Input
                                                                                                        type="textarea"
                                                                                                        id="name"
                                                                                                        value={editKeywords === 'undefined' ? "" : editKeywords === 'null' ? "" : editKeywords}
                                                                                                        onChange={(e) => setEditKeywords(e.target.value)}
                                                                                                        placeholder="Business, Email, Sale, Insurance"
                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    }
                                                                                </>
                                                                            </Row>
                                                                            <Col sm='12'>
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        className="col-sm-12 col-form-label"
                                                                                        htmlFor="keywords" style={{
                                                                                            fontWeight: '500',
                                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                        }}>
                                                                                            Define AI Assistant Personality                                                                                        
                                                                                        <IoIosHelpCircleOutline
                                                                                            id="InstructionsDetail"
                                                                                            size={20}
                                                                                            style={{
                                                                                                marginLeft: 8,
                                                                                                cursor: 'pointer',
                                                                                                marginBottom: '-5px'
                                                                                            }}
                                                                                            onMouseEnter={() => handleMouseEnter('instructions')}
                                                                                            onMouseLeave={() => handleMouseLeave('instructions')}
                                                                                        />
                                                                                        <Popover
                                                                                            placement="top"
                                                                                            isOpen={popoverOpen.instructions}
                                                                                            target="InstructionsDetail"
                                                                                            toggle={() => {
                                                                                            }}
                                                                                        >
                                                                                            <PopoverBody>
                                                                                                To define your
                                                                                                assistant's customer
                                                                                                relations with all
                                                                                                incoming callers, it's
                                                                                                important to outline the
                                                                                                assistant's personality
                                                                                                traits, behavior, and
                                                                                                response strategy in
                                                                                                your instructions.
                                                                                            </PopoverBody>
                                                                                        </Popover>

                                                                                        <div style={{
                                                                                            display: 'inline',
                                                                                            alignItems: 'center',
                                                                                            float:"right"
                                                                                            }}>
                                                                                            <OverlayTrigger
                                                                                                trigger="hover"
                                                                                                placement="top"
                                                                                                overlay={generateBioPopover}>
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: '20px',
                                                                                                        height: '20px',
                                                                                                        marginRight: '10px',
                                                                                                        cursor: 'pointer'
                                                                                                    }}
                                                                                                    src={botImage}
                                                                                                    alt="Generate Bio Through AI"
                                                                                                    onClick={((e)=>{handleBotPersona2(editName, editGender)})}
                                                                                                />
                                                                                            </OverlayTrigger>

                                                                                            <OverlayTrigger
                                                                                                trigger="hover"
                                                                                                placement="top"
                                                                                                overlay={viewBioPopover}>
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: '15px',
                                                                                                        height: '15px',
                                                                                                        marginRight: '10px',
                                                                                                        cursor: 'pointer'
                                                                                                    }}
                                                                                                    src={expand}
                                                                                                    alt="View Bio"
                                                                                                    onClick={handleShow} // Open modal on click
                                                                                                />
                                                                                            </OverlayTrigger>
                                                                                        </div>

                                                                                    </Label>

                                                                                    

                                                                                    <Col sm="12">
                                                                                        <Input
                                                                                            type="textarea"
                                                                                            id="editPrompt"
                                                                                            value={editPrompt}
                                                                                            onChange={(e) => setEditPrompt(e.target.value)}
                                                                                            placeholder="Enter prompt"
                                                                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                            style={{
                                                                                                backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white',
                                                                                                minHeight: '100px'
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </FormGroup>
                                                                            </Col>

                                                                        </Col>

                                                                        <div style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'flex-end',
                                                                                width: '100%'
                                                                            }}>
                                                                                {loading ?
                                                                                    <Button disabled>
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            animation="grow"
                                                                                            size="sm"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        Saving...
                                                                                    </Button>
                                                                                    :
                                                                                    <Button style={{ height: '40px' }}
                                                                                        onClick={handleEditNumber}>Save</Button>
                                                                                }

                                                                                <Button color='primary'
                                                                                    style={{ height: '40px' }}
                                                                                    onClick={() => {
                                                                                        setEditComponent(false)
                                                                                    }}>Cancel</Button>
                                                                            </div>
                                                                        </div>
                                                                    </Row>
                                                                    {/* <EditFormComponent
                                                    editName={editName}
                                                    setEditName={setEditName}
                                                    editGender={editGender}
                                                    setEditGender={setEditGender}
                                                    editFirstMessage={editFirstMessage}
                                                    setEditFirstMessage={setEditFirstMessage}
                                                    editEndCall={editEndCall}
                                                    setEditEndCall={setEditEndCall}
                                                    callGreeting={callGreeting}
                                                    setCallGreeting={setCallGreeting}
                                                    changePhoneNumber={changePhoneNumber}
                                                    setChangePhoneNumber={setChangePhoneNumber}
                                                    editKeywords={editKeywords}
                                                    setEditKeywords={setEditKeywords}
                                                    editProvider={editProvider}
                                                    handleEditProviderChange={handleEditProviderChange}
                                                    editAccountSID={editAccountSID}
                                                    setEditAccountSID={setEditAccountSID}
                                                    editAuthToken={editAuthToken}
                                                    setEditAuthToken={setEditAuthToken}
                                                    backVoice={backVoice}
                                                    setBackVoice={setBackVoice}
                                                    languageMap={languageMap}
                                                    editLanguage={editLanguage}
                                                    handleEditLanguageChange={handleEditLanguageChange}
                                                    editPrompt={editPrompt}
                                                    setEditPrompt={setEditPrompt}
                                                    theme={theme}
                                                    loading={loading}
                                                    handleEditNumber={handleEditNumber}
                                                    handleCloseEditModal={handleCloseEditModal}
                                                /> */}

                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ) : (
                                                        <>
                                                            {selectedBot ?
                                                                <>
                                                                    <BotDetailComponent botData={selectedBot}
                                                                        handleEdit={() => {
                                                                            handleEdit(selectedBot)
                                                                            setEditComponent(true)
                                                                        }}
                                                                        handleDelete={() => handleDelete(selectedBot)} />
                                                                </>
                                                                :
                                                                <Col sm="8">
                                                                    <Card style={{ boxShadow: 'none' }}>
                                                                        <CardBody>
                                                                            <div style={{
                                                                                paddingTop: '20px',
                                                                                paddingRight: '20px',
                                                                                paddingLeft: '20px'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '300px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between'
                                                                                }}>
                                                                                    <h5 style={{ color: step1 ? '#FF5757' : '#CCCCCC' }}>Step
                                                                                        1</h5>
                                                                                    <h5 style={{ color: step2 ? '#FF5757' : '#CCCCCC' }}>Step
                                                                                        2</h5>
                                                                                    <h5 style={{ color: step3 ? '#FF5757' : '#CCCCCC' }}>Step
                                                                                        3</h5>
                                                                                    <h5 style={{ color: step4 ? '#FF5757' : '#CCCCCC' }}>Step
                                                                                        4</h5>
                                                                                </div>
                                                                                {step1 &&
                                                                                    <Row>
                                                                                        <Col sm="6">
                                                                                            <div style={{ padding: '20px 20px 0px 0px' }}>
                                                                                                <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                                                                                                    <Form className="theme-form login-form" style={{
                                                                                                        flex: '1 1 auto',
                                                                                                        display: 'flex',
                                                                                                        flexDirection: 'column'
                                                                                                    }}>
                                                                                                        {assistantType === 'custom' && (
                                                                                                            <div onClick={() => {
                                                                                                                handleSelectBot(botList?.[0]);
                                                                                                            }} style={{
                                                                                                                cursor: 'pointer',
                                                                                                                backgroundColor: Color.SECONDARY,
                                                                                                                padding: '10px',
                                                                                                                width: '35px',
                                                                                                                height: '35px',
                                                                                                                borderRadius: '10px',
                                                                                                                display: 'flex',
                                                                                                                alignItems: 'center',
                                                                                                                justifyContent: 'center'
                                                                                                            }}>
                                                                                                                <FaArrowLeft size={20}
                                                                                                                    color={Color.WHITE} />
                                                                                                            </div>
                                                                                                        )}

                                                                                                        <H4 attrH4={{ style: { color: '#FF5757' } }}>Prepare
                                                                                                            your AI Assistant Persona</H4>
                                                                                                        {/*{scanOption !== 'scan' && <H6 attrH6={{ style: { color: '#FF5757', fontWeight: '500', fontSize: '14px' } }}>{botList?.length} Pre-trained AI Assistants tailored to your (<span style={{ color: Color.PRIMARY }}>{industryName}</span>) business profile, ready for seamless integration.</H6>}*/}

                                                                                                        {assistantType === 'custom' ? (
                                                                                                            <>
                                                                                                                <Col sm="12">
                                                                                                                    <label>Language</label>
                                                                                                                    <LanguageSelect
                                                                                                                        languageMap={LANGUAGES_MAP}
                                                                                                                        selectedLanguage={ownLanguage}
                                                                                                                        onLanguageChange={handleOwnLanguageChange}
                                                                                                                    />
                                                                                                                    {errors.ownLanguage && <span
                                                                                                                        style={{
                                                                                                                            color: 'red',
                                                                                                                            fontSize: '12px'
                                                                                                                        }}>{errors.ownLanguage}</span>}
                                                                                                                </Col>
                                                                                                                <Form>
                                                                                                                    <div style={{
                                                                                                                        display: 'flex',
                                                                                                                        gap: '10px',
                                                                                                                        marginBottom: '10px',
                                                                                                                        marginTop: '10px'
                                                                                                                    }}>
                                                                                                                        {/* Name Field on the Left */}
                                                                                                                        <div style={{ flex: 1 }}>
                                                                                                                            <label>AI Assistant Name</label>
                                                                                                                            <input
                                                                                                                                type="text"
                                                                                                                                className="form-control"
                                                                                                                                placeholder="Enter Name"
                                                                                                                                style={{ marginTop: '5px' }}
                                                                                                                                onChange={(e) => {
                                                                                                                                    setBotName(e.target.value);
                                                                                                                                    if (e.target.value) setErrors(prev => ({
                                                                                                                                        ...prev,
                                                                                                                                        name: ''
                                                                                                                                    }));
                                                                                                                                }}
                                                                                                                                value={botName}
                                                                                                                            />
                                                                                                                            {errors.name && <span
                                                                                                                                style={{
                                                                                                                                    color: 'red',
                                                                                                                                    fontSize: '12px'
                                                                                                                                }}>{errors.name}</span>}
                                                                                                                        </div>

                                                                                                                        {/* Gender Dropdown on the Right */}
                                                                                                                        <div style={{ flex: 1 }}>
                                                                                                                            <label>Gender</label>
                                                                                                                            <Select
                                                                                                                                options={sentimentData}
                                                                                                                                styles={customStyles}
                                                                                                                                onChange={(e) => {
                                                                                                                                    setGender(e.value);
                                                                                                                                    if (e.value) setErrors(prev => ({
                                                                                                                                        ...prev,
                                                                                                                                        gender: ''
                                                                                                                                    }));
                                                                                                                                }}
                                                                                                                                placeholder="Select Gender"
                                                                                                                                value={sentimentData?.find(option => option.value === gender)}
                                                                                                                            />
                                                                                                                            {errors.gender && <span
                                                                                                                                style={{
                                                                                                                                    color: 'red',
                                                                                                                                    fontSize: '12px'
                                                                                                                                }}>{errors.gender}</span>}
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        style={{ marginBottom: '10px' }}>
                                                                                                                        <div style={{
                                                                                                                            display: 'flex',
                                                                                                                            alignItems: 'center',
                                                                                                                            justifyContent: 'space-between',
                                                                                                                            marginTop: '4%'
                                                                                                                        }}>
                                                                                                                            <label>Define AI Assistant Personality</label>
                                                                                                                            <div style={{
                                                                                                                                display: 'flex',
                                                                                                                                alignItems: 'center'
                                                                                                                            }}>
                                                                                                                                <OverlayTrigger
                                                                                                                                    trigger="hover"
                                                                                                                                    placement="top"
                                                                                                                                    overlay={generateBioPopover}>
                                                                                                                                    <img
                                                                                                                                        style={{
                                                                                                                                            width: '20px',
                                                                                                                                            height: '20px',
                                                                                                                                            marginRight: '10px',
                                                                                                                                            cursor: 'pointer'
                                                                                                                                        }}
                                                                                                                                        src={botImage}
                                                                                                                                        alt="Generate Bio Through AI"
                                                                                                                                        onClick={handleBotPersona}
                                                                                                                                    />
                                                                                                                                </OverlayTrigger>

                                                                                                                                <OverlayTrigger
                                                                                                                                    trigger="hover"
                                                                                                                                    placement="top"
                                                                                                                                    overlay={viewBioPopover}>
                                                                                                                                    <img
                                                                                                                                        style={{
                                                                                                                                            width: '15px',
                                                                                                                                            height: '15px',
                                                                                                                                            marginRight: '10px',
                                                                                                                                            cursor: 'pointer'
                                                                                                                                        }}
                                                                                                                                        src={expand}
                                                                                                                                        alt="View Bio"
                                                                                                                                        onClick={handleShow} // Open modal on click
                                                                                                                                    />
                                                                                                                                </OverlayTrigger>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <textarea
                                                                                                                            className="form-control"
                                                                                                                            rows="3"
                                                                                                                            placeholder="Enter Define AI Assistant Personality"
                                                                                                                            value={prompt}
                                                                                                                            onChange={(e) => {
                                                                                                                                setPrompt(e.target.value);
                                                                                                                                if (e.target.value) setErrors(prev => ({
                                                                                                                                    ...prev,
                                                                                                                                    assistantBio: ''
                                                                                                                                }));
                                                                                                                            }}
                                                                                                                            style={{
                                                                                                                                fontSize: '14px',
                                                                                                                                fontWeight: '300'
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        {errors.assistantBio &&
                                                                                                                            <span style={{
                                                                                                                                color: 'red',
                                                                                                                                fontSize: '12px'
                                                                                                                            }}>{errors.assistantBio}</span>}
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        style={{ marginBottom: '10px' }}>
                                                                                                                        <label>Welcome Message To
                                                                                                                            Customer</label>
                                                                                                                        <input
                                                                                                                            className="form-control"
                                                                                                                            placeholder="Enter Welcome Message To Customer"
                                                                                                                            value={firstMessage}
                                                                                                                            onChange={(e) => {
                                                                                                                                setFirstMessage(e.target.value);
                                                                                                                                if (e.target.value) setErrors(prev => ({
                                                                                                                                    ...prev,
                                                                                                                                    firstMessage: ''
                                                                                                                                }));
                                                                                                                            }}
                                                                                                                            style={{
                                                                                                                                fontSize: '14px',
                                                                                                                                fontWeight: '300'
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        {errors.firstMessage &&
                                                                                                                            <span style={{
                                                                                                                                color: 'red',
                                                                                                                                fontSize: '12px'
                                                                                                                            }}>{errors.firstMessage}</span>}
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        style={{ marginBottom: '10px' }}>
                                                                                                                        <label>AI Assistant Voice
                                                                                                                            Should Sound
                                                                                                                            Like</label>
                                                                                                                        <Row className="d-flex align-items-center w-100">
                                                                                                                            <Col md={'11'}>
                                                                                                                                <Select
                                                                                                                                    id="edit_voice"
                                                                                                                                    name="voice_id"
                                                                                                                                    // options={voiceOptions}
                                                                                                                                    options={gender === 'M' ? maleVoices : femaleVoices}
                                                                                                                                    value={assistantVoice}
                                                                                                                                    onChange={handleVoiceChange}
                                                                                                                                    placeholder={'Select voice'}
                                                                                                                                    styles={customVoiceStyles}
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                            <Col md={'1'}>
                                                                                                                                <div
                                                                                                                                    onClick={audioSrc ? toggleAudioPlay : () => {
                                                                                                                                    }} style={{
                                                                                                                                        marginLeft: '10px',
                                                                                                                                        cursor: 'pointer'
                                                                                                                                    }}>
                                                                                                                                    {isPlaying ? (
                                                                                                                                        <IoPauseCircleOutline
                                                                                                                                            size={25}
                                                                                                                                        />
                                                                                                                                    ) : (
                                                                                                                                        <IoPlayCircleOutline
                                                                                                                                            size={25}
                                                                                                                                        />
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <audio
                                                                                                                            ref={(audio) => setAudioElement(audio)}
                                                                                                                            src={audioSrc}
                                                                                                                            type="audio/mpeg"
                                                                                                                            onEnded={() => setIsPlaying(false)}
                                                                                                                            style={{ display: 'none' }}
                                                                                                                        />
                                                                                                                        {errors.assistantVoice &&
                                                                                                                            <span style={{
                                                                                                                                color: 'red',
                                                                                                                                fontSize: '12px'
                                                                                                                            }}>{errors.assistantVoice}</span>}
                                                                                                                    </div>

                                                                                                                    <Button color='primary'
                                                                                                                        style={{ width: '100%', margin: '0', marginTop: '5px' }}
                                                                                                                        onClick={() => {
                                                                                                                            handlePreviousSecondStep();
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Next
                                                                                                                    </Button>

                                                                                                                </Form>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <Col sm="12">
                                                                                                                    <LanguageSelect
                                                                                                                        languageMap={LANGUAGES_MAP}
                                                                                                                        selectedLanguage={language}
                                                                                                                        onLanguageChange={handleLanguageChange}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                                <div style={{
                                                                                                                    height: '380px',
                                                                                                                    width: '100%',
                                                                                                                    overflowY: 'scroll',
                                                                                                                    overflowX: 'hidden',
                                                                                                                }}>
                                                                                                                    {botList?.length > 0 ?
                                                                                                                        <>
                                                                                                                            {botList?.map((item) => {
                                                                                                                                return (
                                                                                                                                    <div
                                                                                                                                        key={item.id}
                                                                                                                                        onClick={() => {
                                                                                                                                            handleSelectBot(item)
                                                                                                                                        }}
                                                                                                                                        style={{
                                                                                                                                            width: '96%',
                                                                                                                                            // height: '140px',
                                                                                                                                            border: precastSelectedBot === item.id ? '2px solid #5679D5' : 'none', // Check if this is the selected bot
                                                                                                                                            // boxShadow: selectedBot === item.id ? '2px 2px 5px lightgrey' : 'none',
                                                                                                                                            cursor: 'pointer',
                                                                                                                                            display: 'flex',
                                                                                                                                            // alignItems: 'center',
                                                                                                                                            // justifyContent: 'center',
                                                                                                                                            borderRadius: '10px',
                                                                                                                                            margin: '5px 5px 10px 5px',
                                                                                                                                            padding: '15px 10px',
                                                                                                                                            backgroundColor: precastSelectedBot === item.id ? 'rgba(236, 241, 254, 1)' : 'white'
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <div
                                                                                                                                            style={{ display: 'flex' }}>
                                                                                                                                            <div
                                                                                                                                                style={{
                                                                                                                                                    width: '80px',
                                                                                                                                                    height: '80px',
                                                                                                                                                    borderRadius: '50px',
                                                                                                                                                    border: precastSelectedBot === item.id ? '4px solid #5679D5' : 'none'
                                                                                                                                                }}>
                                                                                                                                                <img
                                                                                                                                                    loading='lazy'
                                                                                                                                                    src={item.picture && !item.picture.includes('/tmp/') ? item.picture : roboImg}
                                                                                                                                                    alt='loginpage'
                                                                                                                                                    style={{
                                                                                                                                                        width: '71px',
                                                                                                                                                        height: '71px',
                                                                                                                                                        borderRadius: '50px',
                                                                                                                                                    }} />
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style={{ marginLeft: '5px' }}>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        display: 'flex',
                                                                                                                                                        justifyContent: 'space-between'
                                                                                                                                                    }}>
                                                                                                                                                    <P attrPara={{
                                                                                                                                                        style: {
                                                                                                                                                            textAlign: 'left',
                                                                                                                                                            fontWeight: 'bold',
                                                                                                                                                            fontSize: '18px'
                                                                                                                                                        }
                                                                                                                                                    }}>{item?.bot_name}</P>
                                                                                                                                                </div>
                                                                                                                                                <P attrPara={{
                                                                                                                                                    style: {
                                                                                                                                                        textAlign: 'left',
                                                                                                                                                        fontWeight: '300',
                                                                                                                                                        // marginTop: '-15px',
                                                                                                                                                        display: '-webkit-box',
                                                                                                                                                        WebkitLineClamp: 3,
                                                                                                                                                        WebkitBoxOrient: 'vertical',
                                                                                                                                                        overflow: 'hidden',
                                                                                                                                                        textOverflow: 'ellipsis',
                                                                                                                                                        lineHeight: 1.2,
                                                                                                                                                    }
                                                                                                                                                }}>
                                                                                                                                                    {item?.prompt}
                                                                                                                                                </P>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                );
                                                                                                                            })}
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <div style={{
                                                                                                                            width: '430px',
                                                                                                                            height: '320px',
                                                                                                                            display: 'flex',
                                                                                                                            justifyContent: 'center',
                                                                                                                            alignItems: 'center'
                                                                                                                        }}>
                                                                                                                            <P attrPara={{
                                                                                                                                style: {
                                                                                                                                    fontSize: '16px',
                                                                                                                                    fontWeight: '500'
                                                                                                                                }
                                                                                                                            }}>Assistant not
                                                                                                                                found</P>
                                                                                                                        </div>
                                                                                                                    }

                                                                                                                </div>

                                                                                                                <div style={{ marginLeft: '10px' }}>
                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        value="scan"
                                                                                                                        checked={assistantType === 'custom'}
                                                                                                                        onChange={handleScanOptionChange}
                                                                                                                        className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                                    />
                                                                                                                    <label style={{
                                                                                                                        marginTop: '5px',
                                                                                                                        marginLeft: '5px'
                                                                                                                    }} onClick={() => {
                                                                                                                        handleScanOptionChange();
                                                                                                                        // setSelectedBot(null)
                                                                                                                        // setAssistantType('custom');
                                                                                                                        // handleDeselectBot();
                                                                                                                        // setAudioSrc(null);
                                                                                                                        // setFirstMessage(null);
                                                                                                                    }}>Create my own assistant</label>
                                                                                                                </div>

                                                                                                                <div style={{
                                                                                                                    marginTop: 'auto',
                                                                                                                    width: '100%'
                                                                                                                }}>
                                                                                                                    {submitLoader ?
                                                                                                                        <Button color='primary'
                                                                                                                            style={{ width: '95%', }}
                                                                                                                            disabled={submitLoader}>
                                                                                                                            Next...
                                                                                                                        </Button>
                                                                                                                        :
                                                                                                                        <Button color='primary'
                                                                                                                            style={{ width: '95%', }}
                                                                                                                            onClick={() => {
                                                                                                                                // handleNext();
                                                                                                                                handlePreviousSecondStep();
                                                                                                                            }}>
                                                                                                                            {selectedBot ? "Next" : "Next"}
                                                                                                                        </Button>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )}


                                                                                                    </Form>
                                                                                                </Card>
                                                                                            </div>
                                                                                        </Col>

                                                                                        {assistantType === 'custom' ?
                                                                                            <>
                                                                                                <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Steps to Create your AI Assistant</P>
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >Provide all the required information to make an AI Assistant specially designed to deal with clients in your industry.</P>
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Define AI Assistant Personality</P>
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >Select a name, gender, write a brief biography, and select a voice.  The biography can be AI generated for you that can be edited.</P>
                                                                                                    </div>

                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Welcome Message To Customer</P>
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >The greeting message your AI Assistant will convey on picking up the customer phone.</P>
                                                                                                    </div>

                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >AI Assistant Voice Should Sound Like</P>
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                                        <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >Choose a voice that will be associated with your AI Assistant.</P>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </> :

                                                                                            <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                                                                <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />

                                                                                                <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                                                                    {/* <img className='for-light' src={solarArrow} alt='loginpage' style={{ width: '30px', height: '30px', marginTop: '8px', cursor: 'pointer' }} onClick={handleBack} /> */}

                                                                                                    <div style={{ marginTop: '8px' }}>
                                                                                                        <H4 attrH4={{ style: { color: Color.SECONDARY } }}>AI Assistant Persona</H4>
                                                                                                        {loading ?
                                                                                                            <div style={{ width: '430px', height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                <Spinner as="span" animation="grow" size="md" role="status" aria-hidden="true" />
                                                                                                            </div>
                                                                                                            :
                                                                                                            <>
                                                                                                                {precaseBotData !== null ?
                                                                                                                    <>
                                                                                                                        <div style={{ display: 'flex' }}>
                                                                                                                            <img
                                                                                                                                src={isRoboImageLoaded && precaseBotData?.picture ? precaseBotData.picture : roboImg}
                                                                                                                                alt='Agent'
                                                                                                                                style={{
                                                                                                                                    width: '80px',
                                                                                                                                    height: '80px',
                                                                                                                                    borderRadius: '50%'
                                                                                                                                }}
                                                                                                                                onLoad={() => setIsRoboImageLoaded(true)}
                                                                                                                                onError={() => {
                                                                                                                                    setIsRoboImageLoaded(false);
                                                                                                                                }}
                                                                                                                                loading="lazy"
                                                                                                                            />
                                                                                                                            <div
                                                                                                                                style={{ width: '100%' }}>
                                                                                                                                <div style={{
                                                                                                                                    display: 'flex',
                                                                                                                                    justifyContent: 'space-between',
                                                                                                                                    width: '100%',
                                                                                                                                    alignItems: 'center'
                                                                                                                                }}>
                                                                                                                                    <P attrPara={{
                                                                                                                                        style: {
                                                                                                                                            textAlign: 'left',
                                                                                                                                            fontWeight: 'bold',
                                                                                                                                            fontSize: '18px',
                                                                                                                                            marginTop: '10px'
                                                                                                                                        }
                                                                                                                                    }}>{precaseBotData?.bot_name}</P>
                                                                                                                                    {precaseBotData?.audio_file !== null && (
                                                                                                                                        <>
                                                                                                                                            {/* {audioLoader ?
                                                                                                            <div style={{ display: 'flex', cursor: 'pointer', backgroundColor: '#DDE6FF', height: '30px', width: '80px', padding: '5px 10px', borderRadius: '50px', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" color='red' />
                                                                                                            </div>
                                                                                                            : */}
                                                                                                                                            <div
                                                                                                                                                style={{
                                                                                                                                                    display: 'flex',
                                                                                                                                                    cursor: 'pointer',
                                                                                                                                                    backgroundColor: '#DDE6FF',
                                                                                                                                                    height: '30px',
                                                                                                                                                    padding: '5px 10px',
                                                                                                                                                    borderRadius: '50px'
                                                                                                                                                }}
                                                                                                                                                onClick={() => {
                                                                                                                                                    togglePrecastBotAudioPlay(precaseBotData);
                                                                                                                                                }}>
                                                                                                                                                <div>
                                                                                                                                                    {isPlaying ? (
                                                                                                                                                        <IoPauseCircleOutline
                                                                                                                                                            size={20}
                                                                                                                                                            color={'#5679D5'} />
                                                                                                                                                    ) : (
                                                                                                                                                        <IoPlayCircleOutline
                                                                                                                                                            size={20}
                                                                                                                                                            color={'#5679D5'} />
                                                                                                                                                    )}
                                                                                                                                                </div>
                                                                                                                                                <P attrPara={{
                                                                                                                                                    style: {
                                                                                                                                                        color: '#5679D5',
                                                                                                                                                        lineHeight: 1.2,
                                                                                                                                                        fontSize: '16px',
                                                                                                                                                        marginLeft: '5px'
                                                                                                                                                    }
                                                                                                                                                }}>Play</P>
                                                                                                                                                <audio
                                                                                                                                                    ref={(audio) => setAudioElement(audio)}
                                                                                                                                                    src={precaseBotData?.audio_file}
                                                                                                                                                    type="audio/mpeg"
                                                                                                                                                    onEnded={() => setIsPlaying(false)}
                                                                                                                                                    style={{ display: 'none' }}
                                                                                                                                                />
                                                                                                                                            </div>
                                                                                                                                            {/* } */}
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                </div>

                                                                                                                                <P attrPara={{
                                                                                                                                    style: {
                                                                                                                                        textAlign: 'left',
                                                                                                                                        fontWeight: '500',
                                                                                                                                        fontSize: '16px',
                                                                                                                                        marginTop: '-10px',
                                                                                                                                        color: '#5679D5'
                                                                                                                                    }
                                                                                                                                }}>{precaseBotData?.gender === 'M' ? 'Male' : precaseBotData?.gender === 'F' ? 'Female' : ''}</P>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div style={{
                                                                                                                            marginTop: '20px',
                                                                                                                            height: '450px',
                                                                                                                            overflow: 'scroll'
                                                                                                                        }}>
                                                                                                                            <div style={{
                                                                                                                                display: 'flex',
                                                                                                                                alignItems: 'center',
                                                                                                                                marginTop: '15px'
                                                                                                                            }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Welcome Message To Customer</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px' } }} >{precaseBotData?.first_message}</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Ending Last Message to Customer</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px' } }} >{precaseBotData?.end_call_message}</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Ending the Phone Call Message</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px' } }} >{precaseBotData?.end_call_phrases}</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                                                <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Instruction</P>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                                                <p style={{ marginTop: '5px', fontSize: '15px', }} dangerouslySetInnerHTML={{ __html: precaseBotData?.prompt?.replace(/\n/g, '<br/>') }}></p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <div style={{ width: '430px', height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                        <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }}>Assistant not found</P>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>}
                                                                                    </Row>
                                                                                }

                                                                                {step2 &&
                                                                                    <div>
                                                                                        <div style={{ marginTop: '1%' }}>
                                                                                            <H4>Step 2 - Define Your
                                                                                                Assistant</H4>
                                                                                        </div>
                                                                                        <FormGroup>
                                                                                            <H6>Welcome Message</H6>
                                                                                            <Col sm="9">
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id="name"
                                                                                                    value={firstMessage}
                                                                                                    onChange={handleFirstMessage}
                                                                                                    placeholder="Enter Welcome Message"
                                                                                                    className='custom-placeholder'
                                                                                                // required
                                                                                                />
                                                                                                {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                                            </Col>
                                                                                        </FormGroup>
                                                                                        <h6 style={{
                                                                                            fontWeight: '400',
                                                                                            fontStyle: 'italic',
                                                                                            fontSize: '16px',
                                                                                            color: '#727272'
                                                                                        }}>Make your welcome Message sweet
                                                                                            and short </h6>
                                                                                        <div style={{ marginTop: '1%' }}>
                                                                                            {/* <FormGroup className='m-t-15 custom-radio-ml'> */}
                                                                                            <H6>Background Office
                                                                                                Voice</H6>
                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center'
                                                                                            }}>
                                                                                                <div
                                                                                                    className='radio radio-primary'
                                                                                                    style={{ marginTop: '5px' }}>
                                                                                                    <Input id='yes'
                                                                                                        type='radio'
                                                                                                        name='voice'
                                                                                                        value='office'
                                                                                                        checked={officeVoice ? true : false}
                                                                                                        onChange={(e) => setOfficeVoice(e.target.value)} />
                                                                                                    <Label style={{
                                                                                                        fontSize: '16px',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }} for='yes'>
                                                                                                        Yes
                                                                                                    </Label>
                                                                                                </div>
                                                                                                <div
                                                                                                    className='radio radio-primary'
                                                                                                    style={{
                                                                                                        marginTop: '5px',
                                                                                                        marginLeft: '20px'
                                                                                                    }}>
                                                                                                    <Input id='no'
                                                                                                        type='radio'
                                                                                                        name='voice'
                                                                                                        value='off'
                                                                                                        onChange={(e) => setOfficeVoice(e.target.value)} />
                                                                                                    <Label style={{
                                                                                                        fontSize: '16px',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}
                                                                                                        for='no'>No</Label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* </FormGroup> */}
                                                                                        </div>
                                                                                        <div style={{ marginTop: '3%' }}>
                                                                                            <FormGroup>
                                                                                                <H6>End the call if the
                                                                                                    assistant get any of
                                                                                                    these words </H6>
                                                                                                <Col sm="9">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="name"
                                                                                                        value={endCallPhrases}
                                                                                                        onChange={(e) => setEndCallPhrases(e.target.value)}
                                                                                                        placeholder="Goodbye, Take Care, Bye"
                                                                                                        className='custom-placeholder'
                                                                                                    // required
                                                                                                    />
                                                                                                    {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                        <div style={{ marginTop: '3%' }}>
                                                                                            <FormGroup>
                                                                                                <H6>End call
                                                                                                    greetings</H6>
                                                                                                <Col sm="9">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="name"
                                                                                                        value={endCallGreetings}
                                                                                                        onChange={(e) => setEndCallGreetings(e.target.value)}
                                                                                                        placeholder="Enter greeting"
                                                                                                        className='custom-placeholder'
                                                                                                    // required
                                                                                                    />
                                                                                                    {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                                                </Col>
                                                                                            </FormGroup>

                                                                                        </div>


                                                                                        <div style={{ marginTop: '3%' }}>
                                                                                            <FormGroup>
                                                                                                <Label
                                                                                                    className="col-sm-12 col-form-label"
                                                                                                    htmlFor="assistantVoice"
                                                                                                    style={{
                                                                                                        fontWeight: '500',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }}>
                                                                                                    Assistant Voice
                                                                                                    Should Sound Like
                                                                                                    <IoIosHelpCircleOutline
                                                                                                        id="PopoverAssistantVoice"
                                                                                                        size={20}
                                                                                                        style={{
                                                                                                            marginLeft: 8,
                                                                                                            cursor: 'pointer',
                                                                                                            marginBottom: '-5px'
                                                                                                        }}
                                                                                                        onMouseEnter={() => handleMouseEnter('assistantVoice')}
                                                                                                        onMouseLeave={() => handleMouseLeave('assistantVoice')}
                                                                                                    />
                                                                                                    <Popover
                                                                                                        placement="top"
                                                                                                        isOpen={popoverOpen.assistantVoice}
                                                                                                        target="PopoverAssistantVoice"
                                                                                                        toggle={() => {
                                                                                                        }}
                                                                                                    >
                                                                                                        <PopoverBody>Select
                                                                                                            the voice
                                                                                                            for the
                                                                                                            assistant.
                                                                                                            Different
                                                                                                            voices
                                                                                                            provide
                                                                                                            distinct
                                                                                                            styles and
                                                                                                            tones for
                                                                                                            the
                                                                                                            assistant’s
                                                                                                            responses.</PopoverBody>
                                                                                                    </Popover>
                                                                                                </Label>
                                                                                                <Col sm="9">
                                                                                                    <div style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{ width: '100%' }}>
                                                                                                            <Select
                                                                                                                id="edit_voice"
                                                                                                                name="voice_id"
                                                                                                                options={gender === 'M' ? maleVoices : femaleVoices}
                                                                                                                value={assistantVoice}
                                                                                                                onChange={handleVoiceChange}
                                                                                                                styles={customStyles}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {audioSrc &&
                                                                                                            <div
                                                                                                                onClick={toggleAudioPlay}
                                                                                                                style={{
                                                                                                                    marginLeft: '10px',
                                                                                                                    cursor: 'pointer'
                                                                                                                }}>
                                                                                                                {isPlaying ? (
                                                                                                                    <IoPauseCircleOutline
                                                                                                                        size={25}
                                                                                                                        style={{ marginTop: '5px' }} />
                                                                                                                ) : (
                                                                                                                    <IoPlayCircleOutline
                                                                                                                        size={25}
                                                                                                                        style={{ marginTop: '5px' }} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <audio
                                                                                                        ref={(audio) => setAudioElement(audio)}
                                                                                                        src={audioSrc}
                                                                                                        type="audio/mpeg"
                                                                                                        onEnded={() => setIsPlaying(false)}
                                                                                                        style={{ display: 'none' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </div>


                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'end'
                                                                                        }}>
                                                                                            <Button
                                                                                                onClick={handlePreviousFirstStep}
                                                                                                color='primary'>Previous</Button>
                                                                                            <Button
                                                                                                onClick={handleStepTwoToThree}
                                                                                                color='primary'>Next</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                                {step3 &&
                                                                                    <div>
                                                                                        <div style={{ marginTop: '4%' }}>
                                                                                            <H4>Step 3- Guide Your
                                                                                                Assistant</H4>
                                                                                        </div>
                                                                                        <Form.Group className="mb-3"
                                                                                            controlId="exampleForm.ControlTextarea1">
                                                                                            <H6>Train your bot for the
                                                                                                job</H6>
                                                                                            <Form.Control as="textarea"
                                                                                                rows={13}
                                                                                                value={prompt}
                                                                                                onChange={(e) => setPrompt(e.target.value)} />
                                                                                        </Form.Group>

                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'end'
                                                                                        }}>
                                                                                            {/* <Button onClick={handlePreviousSecondStep} color='primary' >Previous</Button> */}
                                                                                            {loader ?
                                                                                                <Button color="primary"
                                                                                                    disabled>
                                                                                                    <Spinner
                                                                                                        as="span"
                                                                                                        animation="grow"
                                                                                                        size="sm"
                                                                                                        role="status"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                    Generating...
                                                                                                </Button>
                                                                                                :
                                                                                                <Button
                                                                                                    onClick={handleBotPersona}>Generate
                                                                                                    through AI</Button>
                                                                                            }
                                                                                        </div>
                                                                                        <FormGroup>
                                                                                            <H6>Keywords to catch in
                                                                                                conversation</H6>
                                                                                            <Col sm="9">
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id="name"
                                                                                                    value={keywords}
                                                                                                    onChange={(e) => setKeywords(e.target.value)}
                                                                                                    placeholder="Business, Email, Sale, Insurance"
                                                                                                    className='custom-placeholder'
                                                                                                // required
                                                                                                />
                                                                                                {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                                            </Col>
                                                                                        </FormGroup>
                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'end',
                                                                                            padding: '0px'
                                                                                        }}>
                                                                                            <Button
                                                                                                onClick={handlePreviousSecondStep}
                                                                                                color='primary'>Previous</Button>
                                                                                            <Button
                                                                                                style={{ margin: '0px' }}
                                                                                                onClick={handleStepThreeToFour}
                                                                                                color='primary'>Next</Button>
                                                                                        </div>

                                                                                    </div>
                                                                                }

                                                                                {step4 &&
                                                                                    <Row style={{ paddingTop: '2%' }}>
                                                                                        <Col sm="12">
                                                                                            <div
                                                                                                className='form-group mt-3'>
                                                                                                <div
                                                                                                    className='radio radio-primary d-flex align-items-center'
                                                                                                    style={{ marginTop: '1%' }}>
                                                                                                    <Input id='radio1'
                                                                                                        type='radio'
                                                                                                        name='number'
                                                                                                        value='havingNumber'
                                                                                                        onChange={handleHavingNumber} />
                                                                                                    <Label style={{
                                                                                                        fontSize: '14px',
                                                                                                        marginLeft: '10px',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }} htmlFor='radio1'>
                                                                                                        I have Twilio
                                                                                                        phone number
                                                                                                    </Label>
                                                                                                </div>
                                                                                                <div
                                                                                                    className='radio radio-primary d-flex align-items-center'
                                                                                                    style={{ marginTop: '1%' }}>
                                                                                                    <Input id='radio2'
                                                                                                        type='radio'
                                                                                                        name='number'
                                                                                                        value='notHavingNumber'
                                                                                                        onChange={handleHavingNumber} />
                                                                                                    <Label style={{
                                                                                                        fontSize: '14px',
                                                                                                        marginLeft: '10px',
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black'
                                                                                                    }} htmlFor='radio2'>
                                                                                                        I want to
                                                                                                        register a new
                                                                                                        phone number
                                                                                                    </Label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {havingNumber === 'havingNumber' ?
                                                                                                (
                                                                                                    <Card style={{
                                                                                                        marginTop: '10px',
                                                                                                        border: theme == 'dark-only' ? '' : '1px solid #DCDBDB'
                                                                                                    }}>
                                                                                                        <HeaderCard
                                                                                                            title="Manage bot phone" />
                                                                                                        <CardBody>
                                                                                                            <Form>
                                                                                                                <FormGroup
                                                                                                                    className="row">
                                                                                                                    <Label
                                                                                                                        style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                                                        className="col-sm-3 col-form-label"
                                                                                                                        htmlFor="inputEmail3">Phone</Label>
                                                                                                                    <Col
                                                                                                                        sm="9">
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                padding: '5px',
                                                                                                                                border: '1px solid #DEE2E6',
                                                                                                                                borderColor: '#DEE2E6',
                                                                                                                                borderRadius: '5px'
                                                                                                                            }}>
                                                                                                                            <PhoneInput
                                                                                                                                international
                                                                                                                                countryCallingCodeEditable={false}
                                                                                                                                defaultCountry="US"
                                                                                                                                value={phone}
                                                                                                                                onChange={(e) => {
                                                                                                                                    setPhone(e)
                                                                                                                                }}
                                                                                                                                className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                                                                                                countries={['US', 'CA']}
                                                                                                                                // limitMaxLength={12}
                                                                                                                                limitMaxLength={12}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </FormGroup>
                                                                                                                <FormGroup
                                                                                                                    className="row">
                                                                                                                    <Label
                                                                                                                        style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                                                        className="col-sm-3 col-form-label"
                                                                                                                        htmlFor="provider">Provider</Label>
                                                                                                                    <Col
                                                                                                                        sm="9">
                                                                                                                        <Input
                                                                                                                            type="select"
                                                                                                                            value={provider}
                                                                                                                            onChange={handleProviderChange}
                                                                                                                            name="provider"
                                                                                                                            className="form-control input-air-primary digits"
                                                                                                                        >
                                                                                                                            {!provider &&
                                                                                                                                <option
                                                                                                                                    value="">Select
                                                                                                                                    Provider</option>}
                                                                                                                            {["Twilio", "Vonage"]?.map(option => (
                                                                                                                                <option
                                                                                                                                    key={option}
                                                                                                                                    value={option}>{option}</option>
                                                                                                                            ))}
                                                                                                                        </Input>
                                                                                                                    </Col>
                                                                                                                </FormGroup>

                                                                                                                {provider === "Twilio" && (
                                                                                                                    <>
                                                                                                                        <FormGroup
                                                                                                                            className="row">
                                                                                                                            <Label
                                                                                                                                style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                                                                className="col-sm-3 col-form-label"
                                                                                                                                htmlFor="accountSID">Account
                                                                                                                                SID</Label>
                                                                                                                            <Col
                                                                                                                                sm="9">
                                                                                                                                <Input
                                                                                                                                    type="text"
                                                                                                                                    id="accountSID"
                                                                                                                                    value={accountSID}
                                                                                                                                    onChange={(e) => setAccountSID(e.target.value)}
                                                                                                                                    placeholder="Enter Account SID"
                                                                                                                                    className='custom-placeholder'
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </FormGroup>
                                                                                                                        <FormGroup
                                                                                                                            className="row">
                                                                                                                            <Label
                                                                                                                                style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                                                                className="col-sm-3 col-form-label"
                                                                                                                                htmlFor="authToken">Auth
                                                                                                                                Token</Label>
                                                                                                                            <Col
                                                                                                                                sm="9">
                                                                                                                                <Input
                                                                                                                                    type="text"
                                                                                                                                    id="authToken"
                                                                                                                                    value={authToken}
                                                                                                                                    onChange={(e) => setAuthToken(e.target.value)}
                                                                                                                                    placeholder="Enter AuthToken"
                                                                                                                                    className='custom-placeholder'
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        </FormGroup>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </Form>
                                                                                                        </CardBody>
                                                                                                    </Card>
                                                                                                ) :
                                                                                                havingNumber === 'notHavingNumber' ?
                                                                                                    (
                                                                                                        <Card style={{
                                                                                                            height: '400px',
                                                                                                            marginTop: '10px',
                                                                                                            border: theme == 'dark-only' ? '' : '1px solid #DCDBDB'
                                                                                                        }}>
                                                                                                            <Row
                                                                                                                style={{ padding: '10px' }}>
                                                                                                                <Col
                                                                                                                    xs='4'
                                                                                                                    sm='4'>
                                                                                                                    <Input
                                                                                                                        type="select"
                                                                                                                        value={cStates}
                                                                                                                        onChange={(e) => setCStates(e.target.value)}
                                                                                                                        name="provider"
                                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                                    >
                                                                                                                        {!cStates &&
                                                                                                                            <option
                                                                                                                                style={{ color: 'grey' }}
                                                                                                                                value="">Select
                                                                                                                                state</option>}
                                                                                                                        {states?.map(option => (
                                                                                                                            <option
                                                                                                                                key={option.id}
                                                                                                                                value={option.code}>{option.name}</option>
                                                                                                                        ))}
                                                                                                                    </Input>
                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    sm="4">
                                                                                                                    <Input
                                                                                                                        type="text"
                                                                                                                        id="name"
                                                                                                                        value={prefixKey}
                                                                                                                        onChange={(e) => setPrefixKey(e.target.value)}
                                                                                                                        placeholder="Prefix (if Any)"
                                                                                                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                                                    // required
                                                                                                                    />
                                                                                                                </Col>
                                                                                                                <Col
                                                                                                                    sm="4">
                                                                                                                    <div
                                                                                                                        style={{ display: 'flex', }}
                                                                                                                        className="form-check">
                                                                                                                        <Input
                                                                                                                            type="checkbox"
                                                                                                                            value={tollFree}
                                                                                                                            onChange={(e) => setTollFree(e.target.checked)}
                                                                                                                            // onSelect={(e) => setTollFree(e.target.value)}
                                                                                                                            // onChange={(e) => console.log("tollFree",e.target.value)}
                                                                                                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                                            style={{
                                                                                                                                backgroundColor: theme === 'dark-only' ? '#1D1E26' : '',
                                                                                                                                height: '30px',
                                                                                                                                width: '30px',
                                                                                                                                border: theme === 'dark-only' ? '1px solid #808080' : '1px solid #DEE2E6'
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <label
                                                                                                                            style={{
                                                                                                                                color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                                                marginTop: '8px',
                                                                                                                                marginLeft: '5px',
                                                                                                                                fontSize: '16px'
                                                                                                                            }}
                                                                                                                            className="form-check-label"
                                                                                                                            htmlFor="name">
                                                                                                                            Tollfree
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display: 'flex',
                                                                                                                    alignItems: 'center',
                                                                                                                    justifyContent: 'space-between',
                                                                                                                    paddingLeft: '10px',
                                                                                                                    paddingRight: '10px'
                                                                                                                }}>
                                                                                                                <FaAngleDoubleLeft
                                                                                                                    color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                                    onClick={handlePreviousPage}
                                                                                                                />
                                                                                                                <p style={{
                                                                                                                    color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                                    marginTop: '15px'
                                                                                                                }}>
                                                                                                                    {twilioNumbersList?.length} Numbers
                                                                                                                </p>
                                                                                                                <FaAngleDoubleRight
                                                                                                                    color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                                    onClick={handleNextPage}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{ overflow: 'scroll' }}>
                                                                                                                {tollLoader ? (
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            height: '180px',
                                                                                                                            display: 'flex',
                                                                                                                            alignItems: 'center',
                                                                                                                            justifyContent: 'center'
                                                                                                                        }}>
                                                                                                                        <Spinner
                                                                                                                            as="span"
                                                                                                                            animation="grow"
                                                                                                                            size="sm"
                                                                                                                            role="status"
                                                                                                                            aria-hidden="true"
                                                                                                                            style={{ color: theme === 'dark-only' ? '#F73164' : 'black' }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {twilioNumbersList?.map((item) => (
                                                                                                                            <div
                                                                                                                                key={item.id} // Ensure each item has a unique key
                                                                                                                                style={{
                                                                                                                                    display: 'flex',
                                                                                                                                    alignItems: 'center',
                                                                                                                                    justifyContent: 'space-between',
                                                                                                                                    margin: '10px',
                                                                                                                                    paddingLeft: '10px',
                                                                                                                                    paddingRight: '10px',
                                                                                                                                    backgroundColor: theme === 'dark-only' ? '#262933' : '#FBFBFB',
                                                                                                                                    height: '40px',
                                                                                                                                    borderRadius: '10px',
                                                                                                                                    border: theme === 'dark-only' ? '1px solid grey' : '1px solid #DCDBDB'
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <p style={{
                                                                                                                                    color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                                                    marginTop: '15px'
                                                                                                                                }}>
                                                                                                                                    {item.friendly_name}
                                                                                                                                </p>
                                                                                                                                <div
                                                                                                                                    style={{ width: '130px' }}>
                                                                                                                                    <p style={{
                                                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                                                        textAlign: 'left',
                                                                                                                                        lineHeight: 1
                                                                                                                                    }}>
                                                                                                                                        {item.locality === null ? 'N/A' : item.locality}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                                                            border: '1px solid #F73164',
                                                                                                                                            paddingLeft: '10px',
                                                                                                                                            paddingRight: '10px',
                                                                                                                                            borderRadius: '5px',
                                                                                                                                            cursor: 'pointer',
                                                                                                                                            color: '#F73164'
                                                                                                                                        }}
                                                                                                                                        onClick={() => handleGetNumber(item)}
                                                                                                                                    >
                                                                                                                                        Get
                                                                                                                                        Number
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        ))}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </Card>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Row>
                                            <Row>
                                                <Col sm='11'>
                                                    {step4 &&
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'end'
                                                        }}>
                                                            {loading ?
                                                                <Button color="primary" disabled>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Saving...
                                                                </Button>
                                                                :
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'end',
                                                                    padding: '0px'
                                                                }}>
                                                                    <Button onClick={handlePreviousThirdStep}
                                                                        color='primary'>Previous</Button> <Button
                                                                            onClick={handleCreateBot}
                                                                            color='primary'>Save</Button>
                                                                </div>

                                                            }

                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    ) : (
                                        <Row>
                                            <Col sm='12'>
                                                <Card style={{ height: '80vh', boxShadow: 'none' }}>
                                                    <CardBody>
                                                        <div>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <H4>Automated Assistant Staff</H4>
                                                                {staffData?.length > 0 && <Button
                                                                    onClick={() => setCompleteCompanyData(null)}>Add
                                                                    Assistant</Button>}
                                                            </div>

                                                            <div style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                borderRadius: '10px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                marginTop: '3%'
                                                            }}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <img style={{ width: '250px', height: '250px' }}
                                                                            src={noAssistant} />
                                                                    </div>
                                                                    <h4 style={{
                                                                        textAlign: 'center',
                                                                        color: '#FF3364'
                                                                    }}>OOPS!</h4>
                                                                    <h4 style={{ color: 'black', textAlign: 'center' }}>No
                                                                        assistant yet</h4>
                                                                    <p style={{
                                                                        color: 'black',
                                                                        textAlign: 'center',
                                                                        width: '450px'
                                                                    }}>Looks like no one is there to wow your customers
                                                                        with AI magic. Create your agent to unleash the
                                                                        full potential of AI!</p>
                                                                    <Button onClick={() => {
                                                                        setCreateBot(true)
                                                                    }}
                                                                    >
                                                                        Create Assistant
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                        </Row>
                                    )}
                                </>
                            </Row>
                        }
                        <Row style={{ paddingTop: '2%' }}>
                            {createBot &&
                                <>
                                    <Col sm="12">
                                        <Card style={{ boxShadow: 'none' }}>
                                            <CardBody>
                                                <div style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                                                    <div style={{
                                                        width: '300px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <h5 style={{ color: step1 ? '#FF5757' : '#CCCCCC' }}>Step 1</h5>
                                                        <h5 style={{ color: step2 ? '#FF5757' : '#CCCCCC' }}>Step 2</h5>
                                                        <h5 style={{ color: step3 ? '#FF5757' : '#CCCCCC' }}>Step 3</h5>
                                                        <h5 style={{ color: step4 ? '#FF5757' : '#CCCCCC' }}>Step 4</h5>
                                                    </div>


                                                    {step1 &&
                                                        <Row>
                                                            <Col sm="6">
                                                                <div style={{ padding: '20px 20px 0px 0px' }}>
                                                                    <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                                                                        <Form className="theme-form login-form" style={{
                                                                            flex: '1 1 auto',
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            {assistantType === 'custom' && (
                                                                                <div onClick={() => {
                                                                                    handleSelectBot(botList?.[0]);
                                                                                }} style={{
                                                                                    cursor: 'pointer',
                                                                                    backgroundColor: Color.SECONDARY,
                                                                                    padding: '10px',
                                                                                    width: '35px',
                                                                                    height: '35px',
                                                                                    borderRadius: '10px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}>
                                                                                    <FaArrowLeft size={20}
                                                                                        color={Color.WHITE} />
                                                                                </div>
                                                                            )}

                                                                            <H4 attrH4={{ style: { color: '#FF5757' } }}>Prepare
                                                                                your AI Assistant Persona</H4>
                                                                            {/*{scanOption !== 'scan' && <H6 attrH6={{ style: { color: '#FF5757', fontWeight: '500', fontSize: '14px' } }}>{botList?.length} Pre-trained AI Assistants tailored to your (<span style={{ color: Color.PRIMARY }}>{industryName}</span>) business profile, ready for seamless integration.</H6>}*/}

                                                                            {assistantType === 'custom' ? (
                                                                                <>
                                                                                    <Col sm="12">
                                                                                        <label>Language</label>
                                                                                        <LanguageSelect
                                                                                            languageMap={LANGUAGES_MAP}
                                                                                            selectedLanguage={ownLanguage}
                                                                                            onLanguageChange={handleOwnLanguageChange}
                                                                                        />
                                                                                        {errors.ownLanguage && <span
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                fontSize: '12px'
                                                                                            }}>{errors.ownLanguage}</span>}
                                                                                    </Col>
                                                                                    <Form>
                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            gap: '10px',
                                                                                            marginBottom: '10px',
                                                                                            marginTop: '10px'
                                                                                        }}>
                                                                                            {/* Name Field on the Left */}
                                                                                            <div style={{ flex: 1 }}>
                                                                                                <label>AI Assistant Name</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Name"
                                                                                                    style={{ marginTop: '5px' }}
                                                                                                    onChange={(e) => {
                                                                                                        setBotName(e.target.value);
                                                                                                        if (e.target.value) setErrors(prev => ({
                                                                                                            ...prev,
                                                                                                            name: ''
                                                                                                        }));
                                                                                                    }}
                                                                                                    value={botName}
                                                                                                />
                                                                                                {errors.name && <span
                                                                                                    style={{
                                                                                                        color: 'red',
                                                                                                        fontSize: '12px'
                                                                                                    }}>{errors.name}</span>}
                                                                                            </div>

                                                                                            {/* Gender Dropdown on the Right */}
                                                                                            <div style={{ flex: 1 }}>
                                                                                                <label>Gender</label>
                                                                                                <Select
                                                                                                    options={sentimentData}
                                                                                                    styles={customStyles}
                                                                                                    onChange={(e) => {
                                                                                                        setGender(e.value);
                                                                                                        if (e.value) setErrors(prev => ({
                                                                                                            ...prev,
                                                                                                            gender: ''
                                                                                                        }));
                                                                                                    }}
                                                                                                    placeholder="Select Gender"
                                                                                                    value={sentimentData?.find(option => option.value === gender)}
                                                                                                />
                                                                                                {errors.gender && <span
                                                                                                    style={{
                                                                                                        color: 'red',
                                                                                                        fontSize: '12px'
                                                                                                    }}>{errors.gender}</span>}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{ marginBottom: '10px' }}>
                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'space-between',
                                                                                                marginTop: '4%'
                                                                                            }}>
                                                                                                <label>Define AI Assistant Personality</label>
                                                                                                <div style={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center'
                                                                                                }}>
                                                                                                    <OverlayTrigger
                                                                                                        trigger="hover"
                                                                                                        placement="top"
                                                                                                        overlay={generateBioPopover}>
                                                                                                        <img
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                height: '20px',
                                                                                                                marginRight: '10px',
                                                                                                                cursor: 'pointer'
                                                                                                            }}
                                                                                                            src={botImage}
                                                                                                            alt="Generate Bio Through AI"
                                                                                                            onClick={handleBotPersona}
                                                                                                        />
                                                                                                    </OverlayTrigger>

                                                                                                    <OverlayTrigger
                                                                                                        trigger="hover"
                                                                                                        placement="top"
                                                                                                        overlay={viewBioPopover}>
                                                                                                        <img
                                                                                                            style={{
                                                                                                                width: '15px',
                                                                                                                height: '15px',
                                                                                                                marginRight: '10px',
                                                                                                                cursor: 'pointer'
                                                                                                            }}
                                                                                                            src={expand}
                                                                                                            alt="View Bio"
                                                                                                            onClick={handleShow} // Open modal on click
                                                                                                        />
                                                                                                    </OverlayTrigger>
                                                                                                </div>
                                                                                            </div>
                                                                                            <textarea
                                                                                                className="form-control"
                                                                                                rows="3"
                                                                                                placeholder="Enter Define AI Assistant Personality"
                                                                                                value={prompt}
                                                                                                onChange={(e) => {
                                                                                                    setPrompt(e.target.value);
                                                                                                    if (e.target.value) setErrors(prev => ({
                                                                                                        ...prev,
                                                                                                        assistantBio: ''
                                                                                                    }));
                                                                                                }}
                                                                                                style={{
                                                                                                    fontSize: '14px',
                                                                                                    fontWeight: '300'
                                                                                                }}
                                                                                            />
                                                                                            {errors.assistantBio &&
                                                                                                <span style={{
                                                                                                    color: 'red',
                                                                                                    fontSize: '12px'
                                                                                                }}>{errors.assistantBio}</span>}
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: '10px' }}>
                                                                                            <label>Welcome Message To
                                                                                                Customer</label>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                placeholder="Enter Welcome Message To Customer"
                                                                                                value={firstMessage}
                                                                                                onChange={(e) => {
                                                                                                    setFirstMessage(e.target.value);
                                                                                                    if (e.target.value) setErrors(prev => ({
                                                                                                        ...prev,
                                                                                                        firstMessage: ''
                                                                                                    }));
                                                                                                }}
                                                                                                style={{
                                                                                                    fontSize: '14px',
                                                                                                    fontWeight: '300'
                                                                                                }}
                                                                                            />
                                                                                            {errors.firstMessage &&
                                                                                                <span style={{
                                                                                                    color: 'red',
                                                                                                    fontSize: '12px'
                                                                                                }}>{errors.firstMessage}</span>}
                                                                                        </div>
                                                                                        <div
                                                                                            style={{ marginBottom: '10px' }}>
                                                                                            <label>AI Assistant Voice
                                                                                                Should Sound
                                                                                                Like</label>
                                                                                            <Row className="d-flex align-items-center w-100">
                                                                                                <Col md={'11'}>
                                                                                                    <Select
                                                                                                        id="edit_voice"
                                                                                                        name="voice_id"
                                                                                                        // options={voiceOptions}
                                                                                                        options={gender === 'M' ? maleVoices : femaleVoices}
                                                                                                        value={assistantVoice}
                                                                                                        onChange={handleVoiceChange}
                                                                                                        placeholder={'Select voice'}
                                                                                                        styles={customVoiceStyles}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col md={'1'}>
                                                                                                    <div
                                                                                                        onClick={audioSrc ? toggleAudioPlay : () => {
                                                                                                        }} style={{
                                                                                                            marginLeft: '10px',
                                                                                                            cursor: 'pointer'
                                                                                                        }}>
                                                                                                        {isPlaying ? (
                                                                                                            <IoPauseCircleOutline
                                                                                                                size={25}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <IoPlayCircleOutline
                                                                                                                size={25}
                                                                                                            />
                                                                                                        )}
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <audio
                                                                                                ref={(audio) => setAudioElement(audio)}
                                                                                                src={audioSrc}
                                                                                                type="audio/mpeg"
                                                                                                onEnded={() => setIsPlaying(false)}
                                                                                                style={{ display: 'none' }}
                                                                                            />
                                                                                            {errors.assistantVoice &&
                                                                                                <span style={{
                                                                                                    color: 'red',
                                                                                                    fontSize: '12px'
                                                                                                }}>{errors.assistantVoice}</span>}
                                                                                        </div>

                                                                                        <Button color='primary'
                                                                                            style={{ width: '100%', margin: '0', marginTop: '5px' }}
                                                                                            onClick={() => {
                                                                                                handlePreviousSecondStep();
                                                                                            }}
                                                                                        >
                                                                                            Next
                                                                                        </Button>

                                                                                    </Form>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Col sm="12">
                                                                                        <LanguageSelect
                                                                                            languageMap={LANGUAGES_MAP}
                                                                                            selectedLanguage={language}
                                                                                            onLanguageChange={handleLanguageChange}
                                                                                        />
                                                                                    </Col>
                                                                                    <div style={{
                                                                                        height: '380px',
                                                                                        width: '100%',
                                                                                        overflowY: 'scroll',
                                                                                        overflowX: 'hidden',
                                                                                    }}>
                                                                                        {botList?.length > 0 ?
                                                                                            <>
                                                                                                {botList?.map((item) => {
                                                                                                    return (
                                                                                                        <div
                                                                                                            key={item.id}
                                                                                                            onClick={() => {
                                                                                                                handleSelectBot(item)
                                                                                                            }}
                                                                                                            style={{
                                                                                                                width: '96%',
                                                                                                                // height: '140px',
                                                                                                                border: precastSelectedBot === item.id ? '2px solid #5679D5' : 'none', // Check if this is the selected bot
                                                                                                                // boxShadow: selectedBot === item.id ? '2px 2px 5px lightgrey' : 'none',
                                                                                                                cursor: 'pointer',
                                                                                                                display: 'flex',
                                                                                                                // alignItems: 'center',
                                                                                                                // justifyContent: 'center',
                                                                                                                borderRadius: '10px',
                                                                                                                margin: '5px 5px 10px 5px',
                                                                                                                padding: '15px 10px',
                                                                                                                backgroundColor: precastSelectedBot === item.id ? 'rgba(236, 241, 254, 1)' : 'white'
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                style={{ display: 'flex' }}>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        width: '80px',
                                                                                                                        height: '80px',
                                                                                                                        borderRadius: '50px',
                                                                                                                        border: precastSelectedBot === item.id ? '4px solid #5679D5' : 'none'
                                                                                                                    }}>
                                                                                                                    <img
                                                                                                                        loading='lazy'
                                                                                                                        src={item.picture && !item.picture.includes('/tmp/') ? item.picture : roboImg}
                                                                                                                        alt='loginpage'
                                                                                                                        style={{
                                                                                                                            width: '71px',
                                                                                                                            height: '71px',
                                                                                                                            borderRadius: '50px',
                                                                                                                        }} />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{ marginLeft: '5px' }}>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            display: 'flex',
                                                                                                                            justifyContent: 'space-between'
                                                                                                                        }}>
                                                                                                                        <P attrPara={{
                                                                                                                            style: {
                                                                                                                                textAlign: 'left',
                                                                                                                                fontWeight: 'bold',
                                                                                                                                fontSize: '18px'
                                                                                                                            }
                                                                                                                        }}>{item?.bot_name}</P>
                                                                                                                    </div>
                                                                                                                    <P attrPara={{
                                                                                                                        style: {
                                                                                                                            textAlign: 'left',
                                                                                                                            fontWeight: '300',
                                                                                                                            // marginTop: '-15px',
                                                                                                                            display: '-webkit-box',
                                                                                                                            WebkitLineClamp: 3,
                                                                                                                            WebkitBoxOrient: 'vertical',
                                                                                                                            overflow: 'hidden',
                                                                                                                            textOverflow: 'ellipsis',
                                                                                                                            lineHeight: 1.2,
                                                                                                                        }
                                                                                                                    }}>
                                                                                                                        {item?.prompt}
                                                                                                                    </P>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                            </>
                                                                                            :
                                                                                            <div style={{
                                                                                                width: '430px',
                                                                                                height: '320px',
                                                                                                display: 'flex',
                                                                                                justifyContent: 'center',
                                                                                                alignItems: 'center'
                                                                                            }}>
                                                                                                <P attrPara={{
                                                                                                    style: {
                                                                                                        fontSize: '16px',
                                                                                                        fontWeight: '500'
                                                                                                    }
                                                                                                }}>Assistant not
                                                                                                    found</P>
                                                                                            </div>
                                                                                        }

                                                                                    </div>

                                                                                    <div style={{ marginLeft: '10px' }}>
                                                                                        <input
                                                                                            type="radio"
                                                                                            value="scan"
                                                                                            checked={assistantType === 'custom'}
                                                                                            onChange={handleScanOptionChange}
                                                                                            className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                        />
                                                                                        <label style={{
                                                                                            marginTop: '5px',
                                                                                            marginLeft: '5px'
                                                                                        }} onClick={() => {
                                                                                            handleScanOptionChange();
                                                                                            // setSelectedBot(null)
                                                                                            // setAssistantType('custom');
                                                                                            // handleDeselectBot();
                                                                                            // setAudioSrc(null);
                                                                                            // setFirstMessage(null);
                                                                                        }}>Create my own assistant</label>
                                                                                    </div>

                                                                                    <div style={{
                                                                                        marginTop: 'auto',
                                                                                        width: '100%'
                                                                                    }}>
                                                                                        {submitLoader ?
                                                                                            <Button color='primary'
                                                                                                style={{ width: '95%', }}
                                                                                                disabled={submitLoader}>
                                                                                                Next...
                                                                                            </Button>
                                                                                            :
                                                                                            <Button color='primary'
                                                                                                style={{ width: '95%', }}
                                                                                                onClick={() => {
                                                                                                    // handleNext();
                                                                                                    handlePreviousSecondStep();
                                                                                                }}>
                                                                                                {selectedBot ? "Next" : "Next"}
                                                                                            </Button>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )}


                                                                        </Form>
                                                                    </Card>
                                                                </div>
                                                            </Col>

                                                            {assistantType === 'custom' ?
                                                                <>
                                                                    <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Steps to Create your AI Assistant</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >Provide all the required information to make an AI Assistant specially designed to deal with clients in your industry.</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >AI Assistant Information</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >Select a name, gender, write a brief biography, and select a voice.  The biography can be AI generated for you that can be edited.</P>
                                                                        </div>

                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Welcome Message To Customer</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >The greeting message your AI Assistant will convey on picking up the customer phone.</P>
                                                                        </div>

                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >AI Assistant Voice Should Sound Like</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                            <P attrPara={{ style: { fontSize: '14px', marginLeft: '10px' } }} >Choose a voice that will be associated with your AI Assistant.</P>
                                                                        </div>
                                                                    </Col>
                                                                </> :

                                                                <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                                    <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />

                                                                    <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                                        {/* <img className='for-light' src={solarArrow} alt='loginpage' style={{ width: '30px', height: '30px', marginTop: '8px', cursor: 'pointer' }} onClick={handleBack} /> */}

                                                                        <div style={{ marginTop: '8px' }}>
                                                                            <H4 attrH4={{ style: { color: Color.SECONDARY } }}>AI Assistant Persona</H4>
                                                                            {loading ?
                                                                                <div style={{ width: '430px', height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <Spinner as="span" animation="grow" size="md" role="status" aria-hidden="true" />
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    {precaseBotData !== null ?
                                                                                        <>
                                                                                            <div style={{ display: 'flex' }}>
                                                                                                <img
                                                                                                    src={isRoboImageLoaded && precaseBotData?.picture ? precaseBotData.picture : roboImg}
                                                                                                    alt='Agent'
                                                                                                    style={{
                                                                                                        width: '80px',
                                                                                                        height: '80px',
                                                                                                        borderRadius: '50%'
                                                                                                    }}
                                                                                                    onLoad={() => setIsRoboImageLoaded(true)}
                                                                                                    onError={() => {
                                                                                                        setIsRoboImageLoaded(false);
                                                                                                    }}
                                                                                                    loading="lazy"
                                                                                                />
                                                                                                <div
                                                                                                    style={{ width: '100%' }}>
                                                                                                    <div style={{
                                                                                                        display: 'flex',
                                                                                                        justifyContent: 'space-between',
                                                                                                        width: '100%',
                                                                                                        alignItems: 'center'
                                                                                                    }}>
                                                                                                        <P attrPara={{
                                                                                                            style: {
                                                                                                                textAlign: 'left',
                                                                                                                fontWeight: 'bold',
                                                                                                                fontSize: '18px',
                                                                                                                marginTop: '10px'
                                                                                                            }
                                                                                                        }}>{precaseBotData?.bot_name}</P>
                                                                                                        {precaseBotData?.audio_file !== null && (
                                                                                                            <>
                                                                                                                {/* {audioLoader ?
                                                                                                            <div style={{ display: 'flex', cursor: 'pointer', backgroundColor: '#DDE6FF', height: '30px', width: '80px', padding: '5px 10px', borderRadius: '50px', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" color='red' />
                                                                                                            </div>
                                                                                                            : */}
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        display: 'flex',
                                                                                                                        cursor: 'pointer',
                                                                                                                        backgroundColor: '#DDE6FF',
                                                                                                                        height: '30px',
                                                                                                                        padding: '5px 10px',
                                                                                                                        borderRadius: '50px'
                                                                                                                    }}
                                                                                                                    onClick={() => {
                                                                                                                        togglePrecastBotAudioPlay(precaseBotData);
                                                                                                                    }}>
                                                                                                                    <div>
                                                                                                                        {isPlaying ? (
                                                                                                                            <IoPauseCircleOutline
                                                                                                                                size={20}
                                                                                                                                color={'#5679D5'} />
                                                                                                                        ) : (
                                                                                                                            <IoPlayCircleOutline
                                                                                                                                size={20}
                                                                                                                                color={'#5679D5'} />
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    <P attrPara={{
                                                                                                                        style: {
                                                                                                                            color: '#5679D5',
                                                                                                                            lineHeight: 1.2,
                                                                                                                            fontSize: '16px',
                                                                                                                            marginLeft: '5px'
                                                                                                                        }
                                                                                                                    }}>Play</P>
                                                                                                                    <audio
                                                                                                                        ref={(audio) => setAudioElement(audio)}
                                                                                                                        src={precaseBotData?.audio_file}
                                                                                                                        type="audio/mpeg"
                                                                                                                        onEnded={() => setIsPlaying(false)}
                                                                                                                        style={{ display: 'none' }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                {/* } */}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    <P attrPara={{
                                                                                                        style: {
                                                                                                            textAlign: 'left',
                                                                                                            fontWeight: '500',
                                                                                                            fontSize: '16px',
                                                                                                            marginTop: '-10px',
                                                                                                            color: '#5679D5'
                                                                                                        }
                                                                                                    }}>{precaseBotData?.gender === 'M' ? 'Male' : precaseBotData?.gender === 'F' ? 'Female' : ''}</P>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{
                                                                                                marginTop: '20px',
                                                                                                height: '450px',
                                                                                                overflow: 'scroll'
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    marginTop: '15px'
                                                                                                }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Welcome Message To Customer</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px' } }} >{precaseBotData?.first_message}</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Ending Last Message to Customer</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px' } }} >{precaseBotData?.end_call_message}</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Ending the Phone Call Message</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px' } }} >{precaseBotData?.end_call_phrases}</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                                    <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Instruction</P>
                                                                                                </div>
                                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                                    <p style={{ marginTop: '5px', fontSize: '15px', }} dangerouslySetInnerHTML={{ __html: precaseBotData?.prompt?.replace(/\n/g, '<br/>') }}></p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <div style={{ width: '430px', height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                            <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }}>Assistant not found</P>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </Col>}
                                                        </Row>
                                                    }

                                                    {step2 &&
                                                        <div>
                                                            <div style={{ marginTop: '1%' }}>
                                                                <H4>Step 2 - Define Your Assistant</H4>
                                                            </div>
                                                            <FormGroup>
                                                                <Label style={{
                                                                    fontWeight: '500',
                                                                    fontSize: '16px',
                                                                    color: '#5978D4'
                                                                }} className="col-sm-3 col-form-label"
                                                                    htmlFor="inputEmail3">Welcome Message</Label>
                                                                <Col sm="9">
                                                                    <Input
                                                                        type="text"
                                                                        id="name"
                                                                        value={firstMessage}
                                                                        onChange={handleFirstMessage}
                                                                        placeholder="Enter welcome message"
                                                                        className='custom-placeholder'
                                                                    // required
                                                                    />
                                                                    {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                </Col>
                                                            </FormGroup>
                                                            <h6 style={{
                                                                fontWeight: '400',
                                                                fontStyle: 'italic',
                                                                fontSize: '16px',
                                                                color: '#727272'
                                                            }}>Make your Welcome Message sweet and short </h6>
                                                            <div style={{ marginTop: '1%' }}>
                                                                {/* <FormGroup className='m-t-15 custom-radio-ml'> */}
                                                                <H6>Background Office Voice</H6>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div className='radio radio-primary'
                                                                        style={{ marginTop: '5px' }}>
                                                                        <Input id='yes' type='radio' name='voice'
                                                                            value='office'
                                                                            checked={officeVoice ? true : false}
                                                                            onChange={(e) => setOfficeVoice(e.target.value)} />
                                                                        <Label style={{
                                                                            fontSize: '16px',
                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                        }} for='yes'>
                                                                            Yes
                                                                        </Label>
                                                                    </div>
                                                                    <div className='radio radio-primary'
                                                                        style={{ marginTop: '5px', marginLeft: '20px' }}>
                                                                        <Input id='no' type='radio' name='voice'
                                                                            value='off'
                                                                            onChange={(e) => setOfficeVoice(e.target.value)} />
                                                                        <Label style={{
                                                                            fontSize: '16px',
                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                        }} for='no'>No</Label>
                                                                    </div>
                                                                </div>
                                                                {/* </FormGroup> */}
                                                            </div>
                                                            <div style={{ marginTop: '3%' }}>
                                                                <FormGroup>
                                                                    <H6>End the call if the assistant get any of these
                                                                        words </H6>
                                                                    <Col sm="9">
                                                                        <Input
                                                                            type="text"
                                                                            id="name"
                                                                            value={endCallPhrases}
                                                                            onChange={(e) => setEndCallPhrases(e.target.value)}
                                                                            placeholder="Goodbye, Take Care, Bye"
                                                                            className='custom-placeholder'
                                                                        // required
                                                                        />
                                                                        {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                    </Col>
                                                                </FormGroup>
                                                            </div>
                                                            <div style={{ marginTop: '3%' }}>
                                                                <FormGroup>
                                                                    <H6>End call greetings</H6>
                                                                    <Col sm="9">
                                                                        <Input
                                                                            type="text"
                                                                            id="name"
                                                                            value={endCallGreetings}
                                                                            onChange={(e) => setEndCallGreetings(e.target.value)}
                                                                            placeholder="Enter greeting"
                                                                            className='custom-placeholder'
                                                                        // required
                                                                        />
                                                                        {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                    </Col>
                                                                </FormGroup>

                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'end'
                                                            }}>
                                                                <Button onClick={handlePreviousFirstStep}
                                                                    color='primary'>Previous</Button> <Button
                                                                        onClick={handleStepTwoToThree}
                                                                        color='primary'>Next</Button>
                                                            </div>
                                                        </div>
                                                    }

                                                    {step3 &&
                                                        <div>
                                                            <div style={{ marginTop: '4%' }}>
                                                                <H4>Step 3- Guide Your Assistant</H4>
                                                            </div>
                                                            <Form.Group className="mb-3"
                                                                controlId="exampleForm.ControlTextarea1">
                                                                <H6>Train your bot for the job</H6>
                                                                <Form.Control as="textarea" rows={13} value={prompt}
                                                                    onChange={(e) => setPrompt(e.target.value)} />
                                                            </Form.Group>

                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'end'
                                                            }}>
                                                                {/* <Button onClick={handlePreviousSecondStep} color='primary' >Previous</Button> */}
                                                                {loader ?
                                                                    <Button color="primary" disabled>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Generating...
                                                                    </Button>
                                                                    :
                                                                    <Button onClick={handleBotPersona}>Generate through
                                                                        AI</Button>
                                                                }
                                                            </div>
                                                            <FormGroup>
                                                                <H6>Keywords to catch in conversation</H6>
                                                                <Col sm="9">
                                                                    <Input
                                                                        type="text"
                                                                        id="name"
                                                                        value={keywords}
                                                                        onChange={(e) => setKeywords(e.target.value)}
                                                                        placeholder="Business, Email, Sale, Insurance"
                                                                        className='custom-placeholder'
                                                                    // required
                                                                    />
                                                                    {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                                                </Col>
                                                            </FormGroup>
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'end',
                                                                padding: '0px'
                                                            }}>
                                                                <Button onClick={handlePreviousSecondStep}
                                                                    color='primary'>Previous</Button> <Button
                                                                        style={{ margin: '0px' }} onClick={handleStepThreeToFour}
                                                                        color='primary'>Next</Button>
                                                            </div>

                                                        </div>
                                                    }

                                                    {step4 &&
                                                        <Row style={{ paddingTop: '2%' }}>
                                                            <Col sm="12">
                                                                <div className='form-group mt-3'>
                                                                    <div
                                                                        className='radio radio-primary d-flex align-items-center'
                                                                        style={{ marginTop: '1%' }}>
                                                                        <Input id='radio1' type='radio' name='number'
                                                                            value='havingNumber'
                                                                            onChange={handleHavingNumber} />
                                                                        <Label style={{
                                                                            fontSize: '14px',
                                                                            marginLeft: '10px',
                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                        }} htmlFor='radio1'>
                                                                            I have Twilio phone number
                                                                        </Label>
                                                                    </div>
                                                                    <div
                                                                        className='radio radio-primary d-flex align-items-center'
                                                                        style={{ marginTop: '1%' }}>
                                                                        <Input id='radio2' type='radio' name='number'
                                                                            value='notHavingNumber'
                                                                            onChange={handleHavingNumber} />
                                                                        <Label style={{
                                                                            fontSize: '14px',
                                                                            marginLeft: '10px',
                                                                            color: theme === 'dark-only' ? 'grey' : 'black'
                                                                        }} htmlFor='radio2'>
                                                                            I want to register a new phone number
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                                {havingNumber === 'havingNumber' ?
                                                                    (
                                                                        <Card style={{
                                                                            marginTop: '10px',
                                                                            border: theme == 'dark-only' ? '' : '1px solid #DCDBDB'
                                                                        }}>
                                                                            <HeaderCard title="Manage bot phone" />
                                                                            <CardBody>
                                                                                <Form>
                                                                                    <FormGroup className="row">
                                                                                        <Label
                                                                                            style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                            className="col-sm-3 col-form-label"
                                                                                            htmlFor="inputEmail3">Phone</Label>
                                                                                        <Col sm="9">
                                                                                            <div style={{
                                                                                                padding: '5px',
                                                                                                border: '1px solid #DEE2E6',
                                                                                                borderColor: '#DEE2E6',
                                                                                                borderRadius: '5px'
                                                                                            }}>
                                                                                                <PhoneInput
                                                                                                    international
                                                                                                    countryCallingCodeEditable={false}
                                                                                                    defaultCountry="US"
                                                                                                    value={phone}
                                                                                                    onChange={(e) => {
                                                                                                        setPhone(e)
                                                                                                    }}
                                                                                                    className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                                                                    countries={['US', 'CA']}
                                                                                                    limitMaxLength={12}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </FormGroup>
                                                                                    <FormGroup className="row">
                                                                                        <Label
                                                                                            style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                            className="col-sm-3 col-form-label"
                                                                                            htmlFor="provider">Provider</Label>
                                                                                        <Col sm="9">
                                                                                            <Input
                                                                                                type="select"
                                                                                                value={provider}
                                                                                                onChange={handleProviderChange}
                                                                                                name="provider"
                                                                                                className="form-control input-air-primary digits"
                                                                                            >
                                                                                                {!provider && <option
                                                                                                    value="">Select
                                                                                                    Provider</option>}
                                                                                                {["Twilio", "Vonage"]?.map(option => (
                                                                                                    <option key={option}
                                                                                                        value={option}>{option}</option>
                                                                                                ))}
                                                                                            </Input>
                                                                                        </Col>
                                                                                    </FormGroup>

                                                                                    {provider === "Twilio" && (
                                                                                        <>
                                                                                            <FormGroup className="row">
                                                                                                <Label
                                                                                                    style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                                    className="col-sm-3 col-form-label"
                                                                                                    htmlFor="accountSID">Account
                                                                                                    SID</Label>
                                                                                                <Col sm="9">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="accountSID"
                                                                                                        value={accountSID}
                                                                                                        onChange={(e) => setAccountSID(e.target.value)}
                                                                                                        placeholder="Enter Account SID"
                                                                                                        className='custom-placeholder'
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                            <FormGroup className="row">
                                                                                                <Label
                                                                                                    style={{ color: theme === 'dark-only' ? 'grey' : 'black' }}
                                                                                                    className="col-sm-3 col-form-label"
                                                                                                    htmlFor="authToken">AuthToken</Label>
                                                                                                <Col sm="9">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        id="authToken"
                                                                                                        value={authToken}
                                                                                                        onChange={(e) => setAuthToken(e.target.value)}
                                                                                                        placeholder="Enter AuthToken"
                                                                                                        className='custom-placeholder'
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </>
                                                                                    )}
                                                                                </Form>
                                                                            </CardBody>
                                                                        </Card>
                                                                    ) :
                                                                    havingNumber === 'notHavingNumber' ?
                                                                        (
                                                                            <Card style={{
                                                                                height: '400px',
                                                                                marginTop: '10px',
                                                                                border: theme == 'dark-only' ? '' : '1px solid #DCDBDB'
                                                                            }}>
                                                                                <Row style={{ padding: '10px' }}>
                                                                                    <Col xs='4' sm='4'>
                                                                                        <Input
                                                                                            type="select"
                                                                                            value={cStates}
                                                                                            onChange={(e) => setCStates(e.target.value)}
                                                                                            name="provider"
                                                                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                        >
                                                                                            {!cStates && <option
                                                                                                style={{ color: 'grey' }}
                                                                                                value="">Select
                                                                                                state</option>}
                                                                                            {states?.map(option => (
                                                                                                <option key={option.id}
                                                                                                    value={option.code}>{option.name}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                    </Col>
                                                                                    <Col sm="4">
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="name"
                                                                                            value={prefixKey}
                                                                                            onChange={(e) => setPrefixKey(e.target.value)}
                                                                                            placeholder="Prefix (if Any)"
                                                                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                        // required
                                                                                        />
                                                                                    </Col>
                                                                                    <Col sm="4">
                                                                                        <div style={{ display: 'flex', }}
                                                                                            className="form-check">
                                                                                            <Input
                                                                                                type="checkbox"
                                                                                                value={tollFree}
                                                                                                onChange={(e) => setTollFree(e.target.checked)}
                                                                                                // onSelect={(e) => setTollFree(e.target.value)}
                                                                                                // onChange={(e) => console.log("tollFree",e.target.value)}
                                                                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                style={{
                                                                                                    backgroundColor: theme === 'dark-only' ? '#1D1E26' : '',
                                                                                                    height: '30px',
                                                                                                    width: '30px',
                                                                                                    border: theme === 'dark-only' ? '1px solid #808080' : '1px solid #DEE2E6'
                                                                                                }}
                                                                                            />
                                                                                            <label style={{
                                                                                                color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                marginTop: '8px',
                                                                                                marginLeft: '5px',
                                                                                                fontSize: '16px'
                                                                                            }}
                                                                                                className="form-check-label"
                                                                                                htmlFor="name">
                                                                                                Tollfree
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between',
                                                                                    paddingLeft: '10px',
                                                                                    paddingRight: '10px'
                                                                                }}>
                                                                                    <FaAngleDoubleLeft
                                                                                        color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={handlePreviousPage}
                                                                                    />
                                                                                    <p style={{
                                                                                        color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                        marginTop: '15px'
                                                                                    }}>
                                                                                        {twilioNumbersList?.length} Numbers
                                                                                    </p>
                                                                                    <FaAngleDoubleRight
                                                                                        color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={handleNextPage}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ overflow: 'scroll' }}>
                                                                                    {tollLoader ? (
                                                                                        <div style={{
                                                                                            height: '180px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center'
                                                                                        }}>
                                                                                            <Spinner
                                                                                                as="span"
                                                                                                animation="grow"
                                                                                                size="sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                                style={{ color: theme === 'dark-only' ? '#F73164' : 'black' }}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            {twilioNumbersList?.map((item) => (
                                                                                                <div
                                                                                                    key={item.id} // Ensure each item has a unique key
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'space-between',
                                                                                                        margin: '10px',
                                                                                                        paddingLeft: '10px',
                                                                                                        paddingRight: '10px',
                                                                                                        backgroundColor: theme === 'dark-only' ? '#262933' : '#FBFBFB',
                                                                                                        height: '40px',
                                                                                                        borderRadius: '10px',
                                                                                                        border: theme === 'dark-only' ? '1px solid grey' : '1px solid #DCDBDB'
                                                                                                    }}
                                                                                                >
                                                                                                    <p style={{
                                                                                                        color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                        marginTop: '15px'
                                                                                                    }}>
                                                                                                        {item.friendly_name}
                                                                                                    </p>
                                                                                                    <div
                                                                                                        style={{ width: '130px' }}>
                                                                                                        <p style={{
                                                                                                            color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                            textAlign: 'left',
                                                                                                            lineHeight: 1
                                                                                                        }}>
                                                                                                            {item.locality === null ? 'N/A' : item.locality}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: theme === 'dark-only' ? 'grey' : 'black',
                                                                                                                border: '1px solid #F73164',
                                                                                                                paddingLeft: '10px',
                                                                                                                paddingRight: '10px',
                                                                                                                borderRadius: '5px',
                                                                                                                cursor: 'pointer',
                                                                                                                color: '#F73164'
                                                                                                            }}
                                                                                                            onClick={() => handleGetNumber(item)}
                                                                                                        >
                                                                                                            Get Number
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </Card>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Row>
                                        <Col sm='11'>
                                            {step4 &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'end'
                                                }}>
                                                    {loading ?
                                                        <Button color="primary" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            Saving...
                                                        </Button>
                                                        :
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'end',
                                                            padding: '0px'
                                                        }}>
                                                            <Button onClick={handlePreviousThirdStep}
                                                                color='primary'>Previous</Button> <Button
                                                                    onClick={handleCreateBot} color='primary'>Save</Button>
                                                        </div>

                                                    }

                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Row>
                    </CardBody>
                </Card>
            )
            }

            <UpgradeRequiredPopup />


            <Modal
                show={upgradeModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {
                    setUpgradeModal(false)
                }}
            >
                <Modal.Body>
                    <H4>Please Upgrade</H4>
                    <P>To create more than one assistants please upgrade to Startup or Pro Plan.</P>
                    <Button color='primary' onClick={() => {
                        setUpgradeModal(false)
                    }}>Upgrade Now</Button>
                </Modal.Body>
            </Modal>


            <Modal
                show={assistantModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {
                    setAssistantModal(false)
                }}
            >

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '20px'
                }}>
                    <div>
                        <H4>{botData?.name}</H4>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ borderRight: '4px solid #5978D4', paddingRight: '10px' }}>
                                <H6>{botData?.gender == 'M' ? "Male" : botData?.gender == "F" ? "Female" : ''}</H6>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <H6>{botData?.jobs_to_do}</H6>
                            </div>
                        </div>
                    </div>
                    <img style={{
                        borderRadius: '10px',
                        width: '5rem',
                        height: '8rem',
                        boxShadow: '0 0 -5px rgba(0, 0, 0, 0.3)'
                    }} src={process.env.REACT_APP_IMAGE_URL + botData?.avatar} />
                </div>

                <Modal.Body>
                    <H6>Bio</H6>
                    <div style={{
                        height: '120px',
                        overflow: 'scroll',
                        marginBottom: '20px',
                        border: ' 2px solid #EEE',
                        padding: '10px',
                        borderRadius: '10px',
                        borderStyle: 'dashed'
                    }}>
                        <p style={{ fontWeight: '300', fontSize: '14px' }}
                            dangerouslySetInnerHTML={{ __html: botData?.persona_prompt?.replaceAll('\\n', '<br />') }}></p>
                    </div>

                    <H6>Welcome Message</H6>
                    <p style={{ fontWeight: '300', fontSize: '14px', }}> {botData?.first_message} </p>

                    <H6>Suitable For</H6>
                    <p style={{ fontWeight: '300', fontSize: '14px', }}> {botData?.jobs_to_do} </p>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            width: '60%'
                        }}>
                            <Button style={{ width: '200px' }} color='primary' onClick={() => {
                                setAssistantModal(false)
                            }}>Select {botData?.name}</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={deleteModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleCloseDeleteModal}
            >
                <Modal.Header closeButton>
                    {/* <H4> Delete Company Directory</H4> */}
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: '500', fontSize: '16px', textAlign: 'center' }}>Are you sure you want to
                        delete this number?</p>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            width: '60%'
                        }}>
                            {loading ?
                                <Button color="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Deleting...
                                </Button>
                                :
                                <Button style={{ width: '100px' }} type="submit" color="primary"
                                    onClick={handleDeleteNumber}>Yes</Button>
                            }
                            <Button style={{ width: '100px', height: '43px' }}
                                onClick={handleCloseDeleteModal}>Close</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={editModal}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleCloseEditModal}
            >
                <Modal.Header closeButton>
                    {/* <H4> Edit</H4> */}
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '20px', paddinRight: '20px' }}>
                        <Col sm="6" style={{ padding: '10px' }}>
                            <CardBody>
                                <FormGroup className="row">
                                    <Label className="col-sm-12 col-form-label" htmlFor="inputEmail3">AI Assistant Name</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={editName}
                                            onChange={(e) => setEditName(e.target.value)}
                                            placeholder="Enter name"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        // required
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="provider">Gender</Label>
                                    <Col sm="9">
                                        <Input
                                            type="select"
                                            value={editGender}
                                            onChange={(e) => setEditGender(e)}
                                            name="provider"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        >
                                            {!gender && <option value="">Select Gender</option>}
                                            {["Male", "Female"]?.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">First
                                        Message</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={editFirstMessage}
                                            onChange={(e) => setEditFirstMessage(e.target.value)}
                                            placeholder="Enter name"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        // required
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">End Call
                                        Words</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={editEndCall}
                                            onChange={(e) => setEditEndCall(e.target.value)}
                                            placeholder="Enter call words"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        // required
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">End Call
                                        Greetings</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={callGreeting}
                                            onChange={(e) => setCallGreeting(e.target.value)}
                                            placeholder="Enter call greetings"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        // required
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Phone
                                        Number</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={changePhoneNumber}
                                            onChange={(e) => setChangePhoneNumber(e.target.value)}
                                            placeholder="Enter phone"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        // required
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Keywords to catch
                                        in conversation</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={editKeywords}
                                            onChange={(e) => setEditKeywords(e.target.value)}
                                            placeholder="Business, Email, Sale, Insurance"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        // required
                                        />
                                        {/* {nameError && <div className="text-danger">{nameError}</div>} */}
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Col>
                        <Col sm='6'>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="provider">Provider</Label>
                                <Col sm="9">
                                    <Input
                                        type="select"
                                        value={editProvider}
                                        onChange={handleEditProviderChange}
                                        name="provider"
                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                    >
                                        {!editProvider && <option value="">Select Provider</option>}
                                        {["Twilio", "Vonage"]?.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </Input>

                                </Col>
                            </FormGroup>

                            {editProvider && (
                                <>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label" htmlFor="accountSID">Account
                                            SID</Label>
                                        <Col sm="9">
                                            <Input
                                                type="text"
                                                id="accountSID"
                                                value={editAccountSID}
                                                onChange={(e) => setEditAccountSID(e.target.value)}
                                                placeholder="Enter Account SID"
                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label" htmlFor="authToken">AuthToken</Label>
                                        <Col sm="9">
                                            <Input
                                                type="text"
                                                id="authToken"
                                                value={editAuthToken}
                                                onChange={(e) => setEditAuthToken(e.target.value)}
                                                placeholder="Enter AuthToken"
                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                            />
                                        </Col>
                                    </FormGroup>

                                    {/* <FormGroup className="row">
                                                                        <Label className="col-sm-3 col-form-label" htmlFor="authToken">Provider Keys</Label>
                                                                        <Col sm="9">
                                                                            <Input
                                                                                type="text"
                                                                                id="authToken"
                                                                                value={providerKeys}
                                                                                onChange={(e) => setProviderKeys(e.target.value)}
                                                                                placeholder="Enter Provider Keys"
                                                                            />
                                                                        </Col>
                                                                    </FormGroup> */}
                                </>
                            )}
                            <CardBody>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="provider">Background
                                        Voice</Label>
                                    <Col sm="9">
                                        <Input
                                            type="select"
                                            value={backVoice}
                                            onChange={(e) => setBackVoice(e)}
                                            name="provider"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        >
                                            {!backVoice && <option value="">Select Voice</option>}
                                            {["Office", "None"]?.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="provider">Language</Label>
                                    <Col sm="9">
                                        {/* <Input
                                            type="select"
                                            value={editLanguage}
                                            onChange={handleEditLanguage}
                                            name="language"
                                            className="form-control input-air-primary digits"
                                        >
                                            {!editLanguage && <option value="">Select Language</option>}
                                            {Object.keys(languageMap).map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </Input> */}
                                        <LanguageSelect
                                            languageMap={LANGUAGES_MAP}
                                            selectedLanguage={editLanguage}
                                            onLanguageChange={handleEditLanguageChange}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Label className="col-sm-4 col-form-label" htmlFor="provider">Train Your Bot For The
                                        Job</Label>
                                    <Form.Control as="textarea" rows={6}
                                        style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                        className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                        value={editPrompt} onChange={(e) => setEditPrompt(e.target.value)} />
                                </FormGroup>
                            </CardBody>
                        </Col>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                width: '60%'
                            }}>
                                {loading ?
                                    <Button color="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Updating...
                                    </Button>
                                    :
                                    <Button onClick={() => handleEditNumber()} color="primary">Update</Button>
                                }

                                <Button style={{ height: '43px' }} onClick={handleCloseEditModal}>Close</Button>
                            </div>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={readBioModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setReadBioModal(false)}
            >
                <Modal.Header closeButton>
                    {/* <H4> Delete Company Directory</H4> */}
                </Modal.Header>
                <Modal.Body>
                    <div style={{ justifyContent: 'center' }}>
                        <div className='borderReadBio' style={{ height: '200px', overflow: 'scroll' }}>
                            <p style={{ fontWeight: '500', fontSize: '16px', textAlign: 'left' }}
                                dangerouslySetInnerHTML={{ __html: readBioData?.prompt?.replace(/\n/g, '<br/>') }}></p>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '20px'
                        }}>
                            <Button style={{ width: '150px', height: '43px' }}
                                onClick={() => setReadBioModal(false)}>Close</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={deleteBotModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setDeleteBotModal(false)}
            >
                <Modal.Header closeButton>
                    {/* <H4>Removing your assistant completely?</H4> */}
                </Modal.Header>
                <Modal.Body style={{ marginTop: '-20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <H4>Deleting your assistant {phoneData?.bot_name}?</H4>
                    </div>
                    <p style={{ fontWeight: '300', fontSize: '14px', textAlign: 'center' }}>By removing your
                        assistant {phoneData?.bot_name}, you will no longer be able to receive inbound and make outbound
                        calls. To confirm the removal, please type "Delete" in the text box.</p>
                    {/* <p style={{ fontWeight: '300', fontSize: '14px', textAlign: 'center' }}>To proceed, please enter 'Delete' here.</p> */}
                    <FormGroup className="row">
                        {/* <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Password</Label> */}
                        <Col sm="12">
                            <Input
                                type="text"
                                id="name"
                                value={deleteItem}
                                onChange={(e) => setDeleteItem(e.target.value)}
                                placeholder="Type delete here"
                                className='custom-placeholder'
                            />
                        </Col>
                    </FormGroup>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            width: '60%'
                        }}>
                            {loading ?
                                <Button color="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Deleting...
                                </Button>
                                :
                                <Button style={{ width: '100px' }} type="submit" color="primary"
                                    onClick={handleDeleteNumber}>Delete</Button>
                            }
                            <Button style={{ width: '100px', height: '43px' }}
                                onClick={() => setDeleteBotModal(false)}>Close</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={bookNumberModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setBookNumberModal(false)}
                className='modal-adjust'

            >
                <Modal.Body style={{}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <RxCross2 size={20} cursor={'pointer'} onClick={() => {
                                    setBookNumberModal(false)
                                }} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <img src={phoneIcon} style={{ width: '100px', height: '100px', }} />
                            </div>
                            <H4 attrH4={{ style: { textAlign: 'center', marginTop: '10px' } }}>Your Assigned Phone Number</H4>
                            <P attrPara={{ style: { textAlign: 'center', marginTop: '10px' } }}>Your chosen phone number will be linked to your AI Assistant upon purchase.</P>
                            <div style={{ marginLeft: '8px' }}>
                                <p style={{ fontWeight: '500', fontSize: '22px', textAlign: 'center', marginTop: '10px', color: Color.SECONDARY }}>{bookNumberModalData?.friendly_name}</p>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', justifySelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <SlLocationPin size={20} color={Color.SECONDARY} />
                                        <p style={{ fontWeight: '400', fontSize: '14px', width: '200px', marginLeft: '5px', marginTop: '-5px' }}>{bookNumberModalData?.locality === null ? 'N/A' : bookNumberModalData?.locality}</p>
                                    </div>
                                    <div style={{ display: 'flex', width: '600px', }}>
                                        <AiOutlineDollar size={20} color={Color.SECONDARY} />
                                        <p style={{ fontWeight: '400', fontSize: '14px', marginLeft: '5px', marginTop: '-3px' }}>{tollFree ? 'Phone Number Fee: $2.50/Month' : 'Phone Number Fee: $1.50/Month'}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {tollButtonLoader ?
                                        <P attrPara={{ style: { color: Color.PRIMARY, textDecorationLine: 'underline', fontWeight: '500', textAlign: 'center', fontSize: '18px' } }}>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Booking...</P>
                                        :
                                        // <Button style={{ marginTop: '-2px', marginLeft: '-1px' }} type="submit" color="primary" onClick={() => handleBookNumber(bookNumberModalData)}>Book Number</Button>
                                        // <Button color="primary"  onclick={() => handleBookNumber(bookNumberModalData)}> Book Number</Button>

                                        <Button color="link" style={{ color: Color.PRIMARY, textDecorationLine: 'underline', fontWeight: '500', textAlign: 'center', fontSize: '18px' }} onClick={() => handleBookNumber(bookNumberModalData)}>Book Number</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                show={botModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setBotModal(false)}
                dialogClassName="custom-modal-position"
                backdrop={false}
            >
                <Modal.Body
                    style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#5679D5',
                        padding: '10px 20px 0px 20px'
                    }}>
                        <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
                        <RxCross2 color='white' size={20} cursor={'pointer'} onClick={() => setBotModal(false)} />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0px'
                    }}>
                        <img style={{ width: '250px', height: '250px', borderRadius: '50%', justifySelf: 'center' }}
                            src={botData?.picture ? botData?.picture : botData?.gender === 'M' ? botImageMale : botImageFemale} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button disabled={talkLoader} color='primary'
                            style={{ height: '43px', width: '100%', fontSize: '16px' }}
                            onClick={() => handleStartTalking(botData)}>
                            Start talking
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={vapiModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setVapiModal(false)}
                dialogClassName="custom-modal-position"
                backdrop={false}
            >
                <Modal.Body
                    style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#5679D5',
                        padding: '10px 20px 0px 20px'
                    }}>
                        <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
                        <RxCross2 color='white' cursor={'pointer'} size={20} onClick={() => handleEndedCall()} />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0px'
                    }}>
                        <iframe
                            src="https://gifer.com/embed/LCPT"
                            width={450}
                            height={300}
                            frameBorder="0"
                            style={{ pointerEvents: 'none' }}
                        ></iframe>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button color='primary' style={{ height: '43px', width: '100%', fontSize: '16px' }}
                            onClick={() => handleEndedCall()}>
                            Stop talking
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModal} centered size='lg' onHide={handleClose}>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Modal.Title style={{ textAlign: 'left' }}>Assistant Bio</Modal.Title>
                        <ImCross size={15} onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                    <textarea
                        className="form-control"
                        rows="20"
                        placeholder="Enter Bio"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                    />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
    )
}

export default ManageAssistant