import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Modal } from "reactstrap";
import OTPInput from "react-otp-input";
import { Btn } from "../../AbstractElements";
import { Color } from "../../helpers/colors";

export const UpgradeConfirm = ({
  isOpen,
  toggler,
  title = "Modal title",
  description,
  closeLabel = "Cancel",
  onConfirm = () => {},
  bodyClass = "",
  onCancel = () => {},
  onGenerate = () => {},
  size = "md",
  otp = "",
  setOtp = "",
}) => {
  const [otpErrors, setOtpErrors] = useState("");
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setOtp('')
      onGenerate();
      setCountdown(59);
    }
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleGenerateClick = () => {
    onGenerate();
    setCountdown(59); // Start 59-second countdown
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggler(!isOpen)} size={size} centered>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <h5 style={{ color: Color.PRIMARY, margin: 0 }}>{title}</h5>
        <IoClose
          onClick={() => {
            toggler(false);
          }}
          style={{ cursor: "pointer", fontSize: "1.5rem", color: "black" }}
        />
      </div>
      <div className={bodyClass} style={{ padding: "1rem" }}>
        {description}
      </div>

      <div style={{ textAlign: "center" }}>
        <OTPInput
          value={otp}
          onChange={(e) => {
            setOtp(e);
            setOtpErrors("");
          }}
          numInputs={6}
          separator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            margin: "0 0.4rem",
            fontSize: "2rem",
            borderRadius: 4,
            backgroundColor: otp ? "#5979D4" : "#F3F6FF",
            border: "1px solid rgba(86, 121, 213, 0.2)",
            color: otp ? "white" : "",
          }}
          containerStyle={{
            justifyContent: "center",
          }}
        />
        {otpErrors && <p style={{ color: "red" }}>{otpErrors}</p>}
      </div>

      <p
        style={{
          color: countdown > 0 ? "grey" : Color.PRIMARY,
          marginTop: "10px",
          textAlign: "center",
          fontSize: "18px",
          textDecorationLine: "underline",
          cursor: countdown > 0 ? "not-allowed" : "pointer",
        }}
        onClick={countdown === 0 ? handleGenerateClick : null}
      >
        {countdown > 0 ? `Regenerate OTP in ${countdown}s` : "Generate OTP"}
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: "1rem",
          alignItems: "center",
        }}
      >
        <Btn
          attrBtn={{
            color: "primary",
            onClick: onConfirm,
            disabled: otp.length < 6,
            style: { height: "40px" },
          }}
        >
          Confirm
        </Btn>
        <Btn
          attrBtn={{
            backgroundColor: Color.SECONDARY,
            onClick: onCancel,
            style: { height: "40px" },
          }}
        >
          {closeLabel}
        </Btn>
      </div>
    </Modal>
  );
};
