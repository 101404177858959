import { toast } from 'react-toastify';
import {TOAST_TYPES} from "./constants";
import '../component2/ManageAssistant/ManageAssistant.css'


export const showToast = (params) => {
    const {type = TOAST_TYPES.SUCCESS, message="Great! Your request has been processed successfully.", position= toast.POSITION.BOTTOM_CENTER} = params;
        switch (type) {
            case TOAST_TYPES.SUCCESS:
                toast.info(message, {
                    position: position,
                    className: 'custom-toast',
                });
                break;
            case TOAST_TYPES.NOTIFICATION:
                toast.info(message, {
                    position: position,
                    className: 'custom-toast',
                });
                break;
            case TOAST_TYPES.WARNING:
                toast.warn(message, {
                    position: position
                });
                break;
            case TOAST_TYPES.DANGER:
                toast.error(message, {
                    position: position,
                    className: 'custom-toast-error',
                });
                break;
            default:
                break;
    }
}