import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, } from 'reactstrap';
import { Form, Modal, Spinner, Offcanvas, OffcanvasHeader, OffcanvasBody, OffcanvasTitle } from 'react-bootstrap';


import { AiOutlinePlus } from "react-icons/ai";
import { IoPlayCircleOutline } from "react-icons/io5";
import { GoQuestion } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";

import Vapi from '@vapi-ai/web';
import { getBotByCompany, getBotRaw } from '../../api/AuthIndex';
import { formatMobileNumber } from '../../Constant/GlobalFunctions';

import startTalk from '../../assets/images/user/startTalk.png';
import profileImage from '../../assets/images/user/bot.png';
import botImageMale from '../../assets/images/user/botImageMale.jpeg';
import botImageFemale from '../../assets/images/user/botImageFemale.jpeg';
import callIcon from '../../assets/images/logo/callIcon.png';
import forwardCallIcon from '../../assets/images/logo/forwardCallIcon.png';

import './ManageAssistant.css'
import { H5 } from '../../AbstractElements';

const BotDetailComponent = ({ botData, handleEdit, handleDelete }) => {
    console.log("🚀 ~ BotDetailComponent ~ botData:", botData)
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const theme = localStorage.getItem("mix_background_layout")
    let token = localStorage?.getItem('token')

    const keywordsArray = botData?.keywords ? botData?.keywords?.split(',')?.map(keyword => keyword?.trim()) : []
    const provideKeys = botData?.provider_keys?.split(',').map(keys => keys?.trim())

    const [visibleKeys, setVisibleKeys] = useState(Array(provideKeys?.length).fill(false));

    const [botModal, setBotModal] = useState(false);
    const [vapiModal, setVapiModal] = useState(false);
    const [botRawData, setBotRawData] = useState(null);
    const [botListData, setBotListData] = useState(null);
    const [botList, setBotList] = useState([]);
    const vapiRef = useRef(null);

    const [loading, setLoading] = useState(false)

    const toggleVisibility = (index) => {
        setVisibleKeys(visibleKeys.map((visible, i) => i === index ? !visible : visible));
    };

    useEffect(() => {
        getAllBots();
    }, []);

    useEffect(() => {
        if (vapiModal && botRawData) {
            vapiRef.current = new Vapi("83e82473-a8f5-45a9-9607-8bb239c672c3");
            try {
                vapiRef.current.start(botRawData);
            } catch (error) {
                console.error('Error starting Vapi:', error);
            }
        }
    }, [vapiModal, botRawData]);

    const getAllBots = async () => {
        try {
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo?.id);
            const res = await getBotByCompany(formData, token);
            setBotList(res?.bots);
        } catch (error) {
            console.error('Error fetching bots:', error);
        }
    };

    const handleBotModal = (data) => {
        console.log("🚀 ~ handleBotModal ~ data:", data)
        setBotListData(data);
        setBotModal(true);
    };

    const handleStartTalking = async (data) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append("bot_id", data?.bot_id);
            formData.append("customer_phone", data?.bot_id);
            const res = await getBotRaw(formData, token);
            setBotRawData(res);
            setLoading(false)
            setBotModal(false);
            setVapiModal(true);
        } catch (error) {
            console.error('Error starting Vapi:', error);
            setLoading(false)
        }
    };

    const handleEndedCall = () => {
        if (vapiRef.current) {
            vapiRef.current.stop();
        }
        setVapiModal(false);
    };
    return (
        <Col sm="8">
            <Card style={{ height: '722px', overflow: 'scroll', boxShadow: 'none' }}>
                <CardBody>
                    <div style={{ display: 'flex', }}>
                        <img
                            src={botData?.picture ? botData?.picture : botData?.gender === 'M' ? botImageMale : botImageFemale}
                            alt={botData?.bot_name}
                            style={{
                                width: '75px',
                                height: '75px',
                                borderRadius: '50%',
                                border: '2px solid #1D1E26', // Dark border for the avatar
                                // marginRight: '15px',
                            }}
                        />
                        <div style={{ width: '100%', marginLeft: '15px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <h4 style={{ marginBottom: '0px', textAlign: 'left', color: '#FF3464', marginTop: '5px', textTransform: 'capitalize' }}>{botData?.bot_name}</h4>
                                <div style={{ display: 'flex', width: '140px', justifyContent: 'space-between' }} onClick={() => { }}>
                                    <div
                                        style={{
                                            borderRadius: '50%',
                                            padding: '10px',
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid rgba(105, 120, 143, 0.3)',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleBotModal(botData)}
                                    >
                                        <IoPlayCircleOutline size={20} color={theme === 'dark-only' ? 'grey' : '#69788F'} style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: '50%',
                                            padding: '10px',
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid rgba(105, 120, 143, 0.3)',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleEdit}
                                    >
                                        <FiEdit3 size={20} color={theme === 'dark-only' ? 'grey' : '#69788F'} style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: '50%',
                                            padding: '10px',
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid rgba(105, 120, 143, 0.3)',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleDelete}
                                    >
                                        <RiDeleteBin6Line size={20} color={'#69788F'} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='someMargin ' style={{ display: 'flex' }}>
                                <p className='divider2' style={{ fontSize: '12px', marginBottom: '0px', textAlign: 'left', fontWeight: 'bold', color: theme === 'dark-only' ? 'grey' : '#52526C', paddingRight: '4px' }}>{formatMobileNumber(botData?.phone_number)}</p>
                                <p className='divider2' style={{ fontSize: '12px', marginBottom: '0px', textAlign: 'left', fontWeight: 'bold', color: theme === 'dark-only' ? 'grey' : '#52526C', paddingRight: '4px', marginLeft: '4px' }}>{botData?.gender == 'F' ? "Female" : "Male"}</p>
                                <p className='divider2' style={{ fontSize: '12px', marginBottom: '0px', textAlign: 'left', fontWeight: 'bold', color: theme === 'dark-only' ? 'grey' : '#52526C', paddingRight: '4px', marginLeft: '4px' }}>{botData?.purpose}</p>
                                <p style={{ fontSize: '12px', marginBottom: '0px', textAlign: 'left', fontWeight: 'bold', color: theme === 'dark-only' ? 'grey' : '#52526C', marginLeft: '4px', paddingRight: '4px' }}>{botData?.language == 'en-US' ? 'English (US)' : botData?.language == 'en-AU' ? 'English (Aus)' : botData?.language == 'en-GB' ? 'English (UK)' : botData?.language == 'en-IN' ? 'English (Ind)' : botData?.language == 'fr' ? 'French' : botData?.language == 'es' ? 'Spanish' : botData?.language == 'de' ? 'German' : botData?.language == 'zh-CN' ? 'Chinese' : ''}</p>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                                    <img style={{ width: '20px', height: '20px', borderRadius: '50px' }} src={callIcon} />
                                    <p style={{ textAlign: 'left', color: theme === 'dark-only' ? 'grey' : '#5679D5', fontSize: '12px', fontWeight: 'bold', marginBottom: '0px', marginLeft: '5px' }}>Total Calls :</p>
                                    <p style={{ textAlign: 'left', color: theme === 'dark-only' ? 'grey' : '#5679D5', fontSize: '12px', fontWeight: 'bold', marginBottom: '0px', marginLeft: '5px' }}>{botData?.totalCallsCount}</p>
                                </div>
                                <div style={{ borderRadius: '5px', display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                                    <img style={{ width: '15px', height: '15px' }} src={forwardCallIcon} />
                                    <p style={{ textAlign: 'left', color: theme === 'dark-only' ? 'grey' : '#FC5455', fontSize: '12px', fontWeight: 'bold', marginBottom: '0px', marginLeft: '5px' }}>Forwarded :</p>
                                    <p style={{ textAlign: 'left', color: theme === 'dark-only' ? 'grey' : '#FC5455', fontSize: '12px', fontWeight: 'bold', marginBottom: '0px', marginLeft: '5px' }}>{botData?.forwardedCallCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col sm="9">

                        </Col>
                        <Col sm='12'>
                            <div class="contact-info" style={{ marginTop: '10px', marginBottom: '3%' }}>
                                <div class="info-item">
                                    <h4
                                        style={{
                                            color: '#777',
                                            fontSize: '12px',
                                            marginBottom: '5px'
                                        }}
                                    >Welcome Message</h4>
                                    <p
                                        style={{
                                            color: '#000', /* Black for main content */
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >{botData?.first_message}</p>
                                </div>
                                <div class="info-item">
                                    <h4
                                        style={{
                                            color: '#777',
                                            fontSize: '12px',
                                            marginBottom: '5px'
                                        }}
                                    >End Conversation</h4>
                                    <p
                                        style={{
                                            color: '#000', /* Black for main content */
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >{botData?.end_call_message}</p>
                                </div>
                                <div class="info-item">
                                    <h4
                                        style={{
                                            color: '#777',
                                            fontSize: '12px',
                                            marginBottom: '5px'
                                        }}
                                    >End Call Words</h4>
                                    <p
                                        style={{
                                            color: '#000', /* Black for main content */
                                            fontSize: '14px',
                                            fontWeight: 'bold'
                                        }}
                                    >{botData?.end_call_phrases}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div class="contact-info" style={{
                        marginTop: '10px',
                        marginBottom: '3%',
                        padding: '10px',
                        backgroundColor: '#f7f9fc',
                        borderRadius: '8px',
                        width: '100%',
                        maxWidth: '1200px',
                        margin: '0 auto',
                    }}>
                        <Row>
                            <Col sm="12">
                                <p style={{ fontSize: '16px', marginBottom: '0px', textAlign: 'left', fontWeight: 'bold', color: theme === 'dark-only' ? '#1D1E26' : '#212529', paddingRight: '4px', textTransform: 'uppercase' }}>Instructions</p>
                                <div><p style={{ fontSize: '14px', fontWeight: '300', textAlign: 'left', color: 'grey', marginTop: '5px' }} dangerouslySetInnerHTML={{ __html: botData?.prompt?.replace(/\n/g, '<br/>') }}></p></div>
                            </Col>
                            <Col sm="12">
                                {keywordsArray[0] !== "undefined" && keywordsArray[0] !== "None" && keywordsArray[0]?.length > 0 && (
                                    <div style={{ marginTop: '10px' }}>
                                        <p
                                            style={{
                                                fontSize: '16px', // Adjusting font size to make it more visible
                                                marginBottom: '10px',
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                color: theme === 'dark-only' ? '#1D1E26' : '#212529', // Darker color for the title
                                                paddingRight: '4px',
                                            }}
                                        >
                                            Keywords
                                        </p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                maxHeight: '138px',
                                                overflow: 'auto',
                                                marginTop: '10px',
                                                padding: '5px 0',
                                                backgroundColor: theme === 'dark-only' ? '#1D1E26' : '#F8FAFC', // Adjust the background to a subtle light color
                                                borderRadius: '10px',
                                            }}
                                        >
                                            {keywordsArray?.map((keyword, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        backgroundColor: '#ECF2FF', // Light blue background similar to the image
                                                        borderRadius: '20px',
                                                        marginBottom: '10px',
                                                        marginRight: '10px',
                                                        padding: '6px 12px', // More padding for a cleaner look
                                                        width: 'auto',
                                                    }}
                                                >
                                                    <p
                                                        key={index}
                                                        style={{
                                                            fontSize: '12px', // Slightly larger font size for better readability
                                                            textAlign: 'center',
                                                            fontWeight: '500',
                                                            color: '#456BFF', // Blue color for the text to match the image
                                                            textTransform: 'capitalize',
                                                            margin: 0, // Remove margin for better alignment
                                                        }}
                                                    >
                                                        {keyword}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Col>

                        </Row>
                    </div>
                </CardBody>
            </Card>
            <Modal
                show={botModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setBotModal(false)}
                dialogClassName="custom-modal-position"
                backdrop={false}
            >
                <Modal.Body style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#5679D5', padding: '10px 20px 0px 20px' }}>
                        <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
                        <RxCross2 color='white' size={20} cursor={'pointer'} onClick={() => setBotModal(false)} />
                    </div>
                    <div style={{ display: 'flex', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', padding: '10px 0px' }}>
                        <img style={{ width: '250px', height: '250px', borderRadius: '50%', justifySelf: 'center' }} src={botData?.picture ? botData?.picture : botData?.gender === 'M' ? botImageMale : botImageFemale} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button disabled={loading} color='primary' style={{ height: '43px', width: '100%', fontSize: '16px' }} onClick={() => handleStartTalking(botData)}>
                            Start talking
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={vapiModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setVapiModal(false)}
                dialogClassName="custom-modal-position"
                backdrop={false}
            >
                <Modal.Body style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#5679D5', padding: '10px 20px 0px 20px' }}>
                        <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
                        <RxCross2 color='white' cursor={'pointer'} size={20} onClick={() => handleEndedCall()} />
                    </div>
                    <div style={{ display: 'flex', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', padding: '10px 0px' }}>
                        <iframe
                            src="https://gifer.com/embed/LCPT"
                            width={450}
                            height={300}
                            frameBorder="0"
                            style={{ pointerEvents: 'none' }}
                        ></iframe>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button color='primary' style={{ height: '43px', width: '100%', fontSize: '16px' }} onClick={() => handleEndedCall()}>
                            Stop talking
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Col>
    )
}

export default BotDetailComponent