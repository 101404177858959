// src/features/auth/authSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginApi } from './authApi';
import { loginRequest, loginSuccess, loginFailure } from './authSlice';

function* handleLogin(action) {
    try {
        const response = yield call(loginApi, action.payload);
        yield put(loginSuccess(response.data));
    } catch (error) {
        yield put(loginFailure(error.message));
    }
}

export default function* authSaga() {
    yield takeLatest(loginRequest.type, handleLogin);
}
