import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Image } from '../AbstractElements';
import RegisterFrom from './RegisterForm';

const SignUp = () => {
  return (
    <Fragment>
      <section>
        <Container fluid={true} className='p-0'>
          <Row className='m-0'>
            {/* <Col xl='8' className='b-center bg-size'
              style={{
                backgroundImage: `url(${require('../assets/images/login/login_side1.png')})`,
                backgroundSize: 'cover', // Adjusts the image to cover the entire area
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                display: 'block'
              }}>
            </Col> */}
            <Col xl='12 p-0'>
              <RegisterFrom logoClassMain='text-start' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default SignUp;
