import {DetailsInfoTile} from "./DetailsInfoTile";
import {H4} from "../../../../../AbstractElements";
import { Color } from "../../../../../helpers/colors";

export const KeywordInfoDetails = () => {
    const basicInfoDetails = [
        {
            heading: 'End Call Phrase',
            description: 'Include phrases to help the AI assistant recognize when a customer intends to conclude the call. For example, \'Goodbye.’ (Note: You can add multiple phrases, separated by comma).'
        },
        {
            heading: 'End Call Message',
            description: 'Select a name, gender, write a brief biography, and select a voice.  The biography can be AI generated for you that can be edited.'
        },
        {
            heading: 'Background Sound',
            description: 'For a more realistic experience, you can select a background office ambiance for your Assistant. Alternatively, you may choose to have no background sound at all.'
        },
        {
            heading: 'Keywords',
            description: 'Enter the keywords you wish to filter in conversations between the customer and the Assistant.(Note: You can add multiple keywords, separated by a comma or hitting the Enter key)'
        },
    ]
    return (
        <div style={{
            marginTop: '20px', height: '450px', overflow: 'scroll'
        }}>
            <H4 attrH4={{
                style: {
                    fontSize: '20px',
                    color: Color.SECONDARY
                }
            }}>Define Your AI Assistant Personality</H4>
            {basicInfoDetails?.map((detail, i) => (
                <DetailsInfoTile key={i++} heading={detail?.heading} description={detail?.description}/>
            ))}
        </div>
    );
}