import React, { useEffect, useState } from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import { BrowserRouter } from "react-router-dom";
import { store } from './Store/redux-store/store';
import { Provider } from 'react-redux';
import { CompanyProvider } from "./Store/providers/Company/CompanyProvider";
import { TourProvider, useTour, components } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import './component2/Dashboard/Dashboard.css'
// import { Navigation } from 'reactour';
import { Color } from './helpers/colors';


const steps = [
  {
    selector: "#chatDiv",
    content: "For any questions about Fluten, feel free to ask Zoey, our AI Assistant. Zoey is always available to help you find answers quickly and efficiently.",
    styles: {
      arrow: (base) => ({
        ...base,
        color: 'white'
      }),
    },
  },
  {
    selector: "#dashboard-step2",
    content: "Access all your account and billing information here. Click to view billing, add funds to your account, or change your account settings.",
    styles: {
      arrow: (base) => ({
        ...base,
        color: 'white'
      }),
    },
  },
  {
    selector: "#dashboard-step3",
    content: "Curious to test your AI Assistant without making a phone call? Click here to interact with your AI Assistant. Note, responses will be time-bound and based on your set availability.",
    styles: {
      arrow: (base) => ({
        ...base,
        color: 'white'
      }),
    },
  },
  {
    selector: "#dashboard-step4",
    content: "Modify your assistant behavior and response to the customers over the phone.",
    styles: {
      arrow: (base) => ({
        ...base,
        color: 'white'
      }),
    },
  },
  {
    selector: "#dashboard-step5",
    content: "Upload knowledge base files here to keep your AI Assistant updated on your company, products, and procedures. Multiple files can be uploaded.",
    styles: {
      arrow: (base) => ({
        ...base,
        color: 'white'
      }),
    },
  },
];

function Badge({ children }) {
  return (
    <components.Badge
      styles={{ badge: (base) => ({ ...base, backgroundColor: Color.PRIMARY, left: '10rem' }) }}
    >
      {children}
    </components.Badge>
  )
}

function Arrow({ children }) {
  return (
    <components.Arrow
    // styles={{ arrow: (base) => ({ ...base, color: Color.WHITE }) }}
    >
      {children}
    </components.Arrow>
  )
}

function Arrow2({ children }) {
  return (
    <components.Arrow
      styles={{ arrow: (base) => ({ ...base, color: Color.WHITE }) }}

    >
      {children}
    </components.Arrow>
  )
}

function Navigation({ children }) {
  return (
    <components.Navigation
      styles={{ navigation: (base) => ({ ...base, color: Color.WHITE }) }}
      Arrow={{ color: 'white' }}
    >
      {children}
    </components.Navigation>
  );
}

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const App = () => {
  const { setIsOpen } = useTour();
  const [currentStep, setCurrentStep] = useState(0);


  return <BrowserRouter basename={"/"}>
    <Provider store={store}>
      <CompanyProvider>
        <CustomizerProvider>
          <AnimationThemeProvider>
            <TourProvider
              steps={steps || []}
              isOpen={() => setIsOpen(true)}
              showPrevNextButtons={true}
              showCloseButton={true}
              // afterOpen={disableBody}
              // beforeClose={enableBody}
              onStepChange={(step) => setCurrentStep(step)}
              components={{ Badge }}

            >
              <Routers />
            </TourProvider>
          </AnimationThemeProvider>
        </CustomizerProvider>
      </CompanyProvider>
    </Provider>
  </BrowserRouter>
};

export default App;
