import React, { Fragment, useContext } from 'react';

import Language from './Language';
import Searchbar from './Searchbar';
import Notificationbar from './Notificationbar';
import MoonLight from './MoonLight';
import Notifications from './Notifications';
import CartHeader from './CartHeader';
import BookmarkHeader from './BookmarkHeader';
import UserHeader from './UserHeader';
import { UL } from '../../../AbstractElements';
import { Col } from 'reactstrap';
import Chatbots from './Chatbot';
import Chat from './Chat';
import Companies from '../Companies/Companies';
import ApiContext from '../../../ApiContext';

const RightHeader = () => {
  const { allCompanies, isUpdated } = useContext(ApiContext);
  let bots = JSON.parse(localStorage.getItem('botData'))
  return (
    <Fragment>
      <Col xxl='3' xl='3' md='3' className='nav-right pull-right right-header col-8 p-0 ms-auto'>
        {/* <Col md="8"> */}
        <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
          {/* <Chatbots /> */}
          {allCompanies?.length > 1 && <Companies />}
          <Chat />
          <MoonLight />
          {/* <Language /> */}
          {/* <Searchbar />
          <BookmarkHeader /> */}

          {/* <CartHeader /> */}
          {/* {bots.length > 0 &&  */}

          <Notificationbar />
          <Notifications/>
          {/* } */}
          <UserHeader />
        </UL>
        {/* </Col> */}
      </Col>
    </Fragment>
  );
};

export default RightHeader;
