import { createSlice } from '@reduxjs/toolkit';

const defaultBotsSlice = createSlice({
    name: 'getDefaultBotsSlice',
    initialState: {
        defaultBots: [],
        loading: false,
        error: null,
    },
    reducers: {
        startGettingDefaultBots: (state) => {
            state.loading = true;
            state.error = null;
        },
        getDefaultBotsSuccess: (state, action) => {
            state.loading = false;
            state.defaultBots = action.payload?.bots;
        },
        getDefaultBotsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    startGettingDefaultBots,
    getDefaultBotsSuccess,
    getDefaultBotsError,
} = defaultBotsSlice.actions;

export default defaultBotsSlice.reducer;