import {useDispatch, useSelector} from 'react-redux';
import {useState} from "react";
import {setFieldValue} from "../../../../../Store/modules/assistant/create/form/createAssistantFormSlice";
import {
    selectCreateAssistantFormValues,
    selectCreateAssistantSubmitting
} from "../../../../../Store/modules/assistant/create/form/createAssistantFormSelectors";
import {isEmpty} from "../../../../../helpers/utils";
import {Button, Col, Form, FormGroup, Input, Label} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import {useCreateAssistantContext} from "../../context/CreateAssistantContext";
import {Spinner} from 'react-bootstrap';


export const ExistingPhone = () => {
    const dispatch = useDispatch();
    const values = useSelector(selectCreateAssistantFormValues);
    const isSubmitting = useSelector(selectCreateAssistantSubmitting);
    const {handleNext} = useCreateAssistantContext();

    const handleChange = (field, value) => {
        setFormErrors({});
        dispatch(setFieldValue({field, value}));
    }

    const [formErrors, setFormErrors] = useState({
        phone_number: '',
        provider_sid: '',
        provider_auth_token: '',
    });

    const getFormErrors = () => {
        let errors = {};

        if (isEmpty(values?.phone_number)) {
            errors.phone_number = 'Phone number is required';
        }

        if (isEmpty(values?.provider_sid)) {
            errors.provider_sid = 'Provider sid is required';
        }

        if (isEmpty(values?.provider_auth_token)) {
            errors.provider_auth_token = 'Phone auth token is required';
        }

        setFormErrors({...formErrors, ...errors});

        return errors;
    }


    return (
        <>
            <Form style={{marginTop: '10px', height: '410px'}}>
                <FormGroup className="row">
                    <Label style={{color: 'black'}}
                           className="col-sm-3 col-form-label"
                           htmlFor="inputPhone">Phone</Label>
                    <Col sm="9">
                        <div style={{
                            padding: '5px',
                            border: '1px solid #DEE2E6',
                            borderRadius: '5px'
                        }}>
                            <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                value={values?.phone_number}
                                onChange={(phone) => {
                                    handleChange('phone_number', phone);
                                }}
                                countries={['US', 'CA']}
                                limitMaxLength={12}
                            />
                        </div>
                        {formErrors?.phone_number && <span style={{
                            color: 'red',
                            fontSize: '0.9em'
                        }}>{formErrors.phone_number}</span>}
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label
                        style={{color: 'black'}}
                        className="col-sm-3 col-form-label"
                        htmlFor="accountSID">Account SID</Label>
                    <Col sm="9">
                        <Input
                            type="text"
                            id="accountSID"
                            name={'provider_sid'}
                            value={values?.provider_sid}
                            onChange={(e) => {
                                handleChange('provider_sid', e.target.value);
                            }}
                            placeholder="Enter Account SID"
                            className='custom-placeholder'
                        />
                        {formErrors.provider_sid && <span style={{
                            color: 'red',
                            fontSize: '0.9em'
                        }}>{formErrors.provider_sid}</span>}
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label
                        style={{color: 'black'}}
                        className="col-sm-3 col-form-label"
                        htmlFor="authToken">AuthToken</Label>
                    <Col sm="9">
                        <Input
                            type="text"
                            id="authToken"
                            name={'provider_auth_token'}
                            value={values?.provider_auth_token}
                            onChange={(e) => {
                                handleChange('provider_auth_token', e.target.value);
                            }}
                            placeholder="Enter AuthToken"
                            className='custom-placeholder'
                        />
                        {formErrors.provider_auth_token && <span style={{
                            color: 'red',
                            fontSize: '0.9em'
                        }}>{formErrors.provider_auth_token}</span>}
                    </Col>
                </FormGroup>
            </Form>

            {isSubmitting ?
                <Button color='primary' disabled style={{width: '100%', margin: 0}}>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Next...
                </Button>
                :
                <Button color='primary' style={{width: '100%', margin: 0}}
                        onClick={() => {
                            const errors = getFormErrors();
                            if (!isEmpty(errors)) {
                                return;
                            }
                            handleNext();
                            // dispatch({type: ActionTypes.CREATE_ASSISTANT, payload: values});
                            // handleHaveTwilioNumber();
                        }}>
                    Next
                </Button>
            }


        </>
    );
}
