import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Popover, PopoverBody, Row } from 'reactstrap';
import Chart from 'react-apexcharts';
import { getWeekCallData } from '../../api/AuthIndex';
import moment from 'moment';
import { H5 } from '../../AbstractElements';
import Select from 'react-select';
import KeywordsChart from './KeywordsChart';
import styled from 'styled-components';
import ReactApexChart from 'react-apexcharts';
import { Color } from '../../helpers/colors';
import { H4, H6 } from '../../AbstractElements'
import { IoIosInformationCircleOutline } from 'react-icons/io';

const TimeButton = styled.button`
  padding: 8px 16px;
  margin: 0 5px;
  background-color: ${({ isActive }) => (isActive ? '#eef1ff' : '#f7f7f8')};
  border: none;
  border-radius: 8px;
  color: ${({ isActive }) => (isActive ? '#696cff' : '#333')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #eef1ff;
    color: #696cff;
  }
`;

const TimeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f7f8;
  padding: 8px;
  border-radius: 12px;
`;

const BasicAreaChartClass = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));
    let token = localStorage?.getItem('token');
    const theme = localStorage.getItem("mix_background_layout");

    const [selectedTime, setSelectedTime] = useState('1M');

    const [popoverOpen, setPopoverOpen] = useState(false);

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    // const [chartData] = React.useState({
    //     series: [
    //         {
    //             name: '',
    //             type: 'column',
    //             data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320],
    //         },
    //         {
    //             name: '',
    //             type: 'line',
    //             data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22],
    //         },
    //     ],
    //     options: {
    //         chart: {
    //             height: 350,
    //             type: 'line',
    //             toolbar: { show: false },
    //         },
    //         stroke: {
    //             width: [0, 4],
    //             curve: 'smooth', // Smooth curve for the line chart
    //         },
    //         colors: ['rgba(115, 102, 255, 1)', 'rgba(255, 51, 100, 1)'],
    //         fill: {
    //             type: 'gradient',
    //             gradient: {
    //                 shadeIntensity: 1,
    //                 type: 'horizontal',
    //                 gradientToColors: ['lightgrey', 'lightgrey'],
    //                 opacityFrom: 1,
    //                 opacityTo: 1,
    //                 stops: [0, 100],
    //             },
    //         },
    //         markers: {
    //             size: 5,
    //             colors: ['#fff'],
    //             strokeColors: ['#5679D5'],
    //             strokeWidth: 2,
    //             hover: { size: 7 },
    //         },
    //         dataLabels: {
    //             enabled: false, // Hide data labels on the line
    //         },
    //         tooltip: {
    //             enabled: false,
    //             y: {
    //                 formatter: function (val) {
    //                     return `$${val.toFixed(2)}`;
    //                 },
    //             },
    //         },
    //         labels: [
    //             '01 Jan 2001',
    //             '02 Jan 2001',
    //             '03 Jan 2001',
    //             '04 Jan 2001',
    //             '05 Jan 2001',
    //             '06 Jan 2001',
    //             '07 Jan 2001',
    //             '08 Jan 2001',
    //             '09 Jan 2001',
    //             '10 Jan 2001',
    //         ],
    //         yaxis: [
    //             {
    //                 show: false,
    //             },
    //             {
    //                 opposite: false,
    //                 // Y-axis for the line chart
    //             },
    //         ],
    //         grid: {
    //             borderColor: '#e0e0e0', // Light grey grid lines for the background
    //             strokeDashArray: 1,
    //         },
    //         plotOptions: {
    //             bar: {
    //                 columnWidth: '30%', // Adjust bar width
    //                 colors: {
    //                     ranges: [
    //                         {
    //                             from: 0,
    //                             to: 1000,
    //                             color: '#e0e0e0',
    //                         },
    //                     ],
    //                 },
    //             },
    //         },
    //     },
    // });

    const handleTimeSelection = (value) => {
        setSelectedData(value);
        setReload(prev => !prev)
    };

    const [selectedData, setSelectedData] = useState('Today');

    const [options, setOptions] = useState({
        chart: {
            height: 300,
            type: "bar",
            toolbar: {
                show: false,
            },
            dropShadow: {
                enabled: true,
                top: 10,
                left: 0,
                blur: 5,
                color: Color.PRIMARY,
                opacity: 0.35,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 6,
                columnWidth: "30%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    fontSize: "12px",
                    fontFamily: "Rubik, sans-serif",
                    colors: "var(--chart-text-color)",
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                formatter: function (val) {
                    return val;
                },
                style: {
                    fontSize: "12px",
                    fontFamily: "Rubik, sans-serif",
                    colors: "var(--chart-text-color)",
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        grid: {
            borderColor: "var(--chart-dashed-border)",
            strokeDashArray: 5,
        },
        colors: [Color.PRIMARY, Color.PRIMARY],
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    chart: {
                        height: 200,
                    },
                },
            },
        ],
    });

    const [series, setSeries] = useState([{
        name: "Activity",
        data: [],
    }]);

    const [reload, setReload] = useState(false);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        handleWeekGraphData(selectedData ? selectedData : 'Today'); // Default to 'last-week' on initial load
    }, [selectedData]);

    // useEffect(() => {
    //     handleWeekGraphData(selectedData);
    // }, []);

    const handleWeekGraphData = async (selectedValue) => {
        const today = moment();
        let start_date;

        // Determine the start_date based on selectedValue
        switch (selectedValue) {
            case 'Today':
                start_date = today.format('YYYY-MM-DD');
                break;
            case 'Week':
                start_date = today.subtract(7, 'days').format('YYYY-MM-DD');
                break;
            case 'Month':
                start_date = today.subtract(1, 'month').format('YYYY-MM-DD');
                break;
            case '3 Months':
                start_date = today.subtract(3, 'months').format('YYYY-MM-DD');
                break;
            case 'Year':
                start_date = today.subtract(1, 'year').format('YYYY-MM-DD');
                break;
            default:
                return;  // Exit if no valid selectedValue is provided
        }

        const end_date = moment().format('YYYY-MM-DD');

        const formData = new FormData();
        formData.append('company_id', getCompanyInfo?.id);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);

        const res = await getWeekCallData(token, formData);

        const callData = res?.calls || []; // Ensure callData is an empty array if res?.calls is undefined or null
        const dates = callData.map(item => moment(item.call_date).format("D-MMM"));
        const calls = callData.map(item => item.calls);

        // Reset chart data if the response is empty
        if (callData.length === 0) {
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    categories: []
                }
            }));

            setSeries([{
                name: 'Calls',
                data: []
            }]);
        } else {
            // Update chart with new data
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    categories: dates
                }
            }));

            setSeries([{
                name: 'Calls',
                data: calls
            }]);
        }
    };

    const select = [
        { value: 'today', label: 'Today' },
        { value: 'last-week', label: 'Last Week' },
        { value: 'last-month', label: 'Last Month' },
        { value: 'last-3-months', label: 'Last 3 Months' },
        { value: 'last-year', label: 'Last Year' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(89, 120, 212, 0.2)',
            color: '#5978d4',
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
            },
            minWidth: '120px'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#5978d4',
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#5978d4',
            '&:hover': {
                color: '#5978d4',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const getStartDate = (selectedValue) => {
        console.log("🚀 ~ getStartDate ~ selectedValue:", selectedValue)
        const today = moment();
        switch (selectedValue) {
            case 'Today':
                return today.format('YYYY-MM-DD');
            case 'Week':
                return today.subtract(7, 'days').format('YYYY-MM-DD');
            case 'Month':
                return today.subtract(1, 'month').format('YYYY-MM-DD');
            case '3 Months':
                return today.subtract(3, 'months').format('YYYY-MM-DD');
            case 'Year':
                return today.subtract(1, 'year').format('YYYY-MM-DD');
            default:
                return
        }
    };

    return (
        <Fragment>
            <Row>
                <Col sm='8' xl='8'>
                    <Card>
                        <CardBody>
                            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <H5>Calls Stats</H5>
                                <Select
                                    options={select}
                                    styles={customStyles}
                                    onChange={(e) => {
                                        console.log("Selected Value", e.label);
                                        setSelectedData(e.value); // Update state with the selected value
                                        setReload(prev => !prev)
                                    }}
                                    defaultValue={select.find(option => option.value === 'last-week')}
                                />
                            </div> */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <H4>Call Stats</H4>
                                <TimeButtonContainer>
                                    {['Today', 'Week', 'Month', '3 Months', 'Year'].map((time) => (
                                        <TimeButton
                                            key={time}
                                            isActive={selectedData === time}
                                            onClick={() => handleTimeSelection(time)}
                                        >
                                            {time}
                                        </TimeButton>
                                    ))}
                                </TimeButtonContainer>
                            </div>
                            <div id="chart">
                                <ReactApexChart
                                    // options={chartData.options}
                                    // series={chartData.series}
                                    options={options}
                                    series={series}
                                    type="bar"
                                    height={300}
                                />
                            </div>
                            {/* <div id='basic-apex'>
                                <Chart options={options} series={series} type="bar" height={250} />
                            </div> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='4' xl='4' >
                    <Card style={{ height: '430px' }}>
                        <CardBody>
                            <div style={{ display: 'flex', marginBottom:'5px' }}>
                                <H6>Catchphrases Frequency</H6>
                                <IoIosInformationCircleOutline
                                    id="info-icon"
                                    size={20}
                                    style={{ cursor: 'pointer', color: Color.BLACK, marginLeft:'8px' }}
                                    onMouseEnter={() => setPopoverOpen(true)} // Show on hover
                                    onMouseLeave={() => setPopoverOpen(false)} // Hide when not hovering
                                />
                                <Popover
                                    placement="top"
                                    isOpen={popoverOpen}
                                    target="info-icon"
                                    toggle={togglePopover}
                                >
                                    <PopoverBody>
                                        Catchphrases used during the calls with AI Assistants.
                                    </PopoverBody>
                                </Popover>
                            </div>
                            <KeywordsChart refresh={reload} firstDate={() => getStartDate(selectedData)} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default BasicAreaChartClass;
