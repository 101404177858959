import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P, Image } from '../AbstractElements';
import { forgotPassword } from '../api/AuthIndex';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import tickCircle from '../assets/images/logo/tickCircle.png';
import roboImg from '../assets/images/logo/roboImg.png';
import { FaArrowLeft } from "react-icons/fa";
import { Color } from '../helpers/colors';
import { ToastContainer, toast } from "react-toastify";

const ForgetPassword = ({ logoClassMain }) => {
  const navigate = useNavigate()
  const theme = localStorage.getItem("mix_background_layout");
  const [email, setEmail] = useState('');
  console.log("🚀 ~ ForgetPassword ~ email:", email)
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === '') {
      setError('Please enter your email');
    } else {
      try{
        setLoading(true)
        console.log('Email submitted:', email);
        const formData = new FormData()
        formData.append('email', email)
        const res = await forgotPassword(formData);
        if (res) {
          setLoading(false)
          navigate(`${process.env.PUBLIC_URL}/verify-otp`, { state: { email } })
        }
        setError(''); // Clear error if email is provided
        // Submit the form or send email logic
      }catch(error){
        setLoading(false)
        if (error?.response?.data?.message == 'Your account is connected to google. Please sign in with Google') {
          toast.error("Your account is connected to google. Please sign in with Google", {
            position: 'top-left',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'custom-toast-error',
          });
        }
      }

    }
  };

  return (

    <Fragment>
      <div
        style={{
          backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'block',
          minHeight: '100vh',
        }}>
        <section>

          <Row className='m-0'>
            <Col className='p-0'>
              <div>
                <div>
                  <div style={{ marginTop: '1%' }}>
                    <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                        <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                        <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                      </div>
                    </Link>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '2%',
                    }}>
                    <Card style={{ width: '1000px', padding: '0px' }}>
                      <CardBody style={{ padding: '0px' }}>
                        <Row>
                          <Col sm='6' style={{ position: 'relative', overflow: 'hidden' }}>
                            <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                            <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                              <div onClick={() => { navigate(`${process.env.PUBLIC_URL}/login`) }} style={{ cursor: 'pointer', backgroundColor: Color.SECONDARY, padding: '10px', width: '35px', height: '35px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaArrowLeft size={20} color={Color.WHITE} alt='loginpage' />
                              </div>
                              <div style={{ marginTop: '20px' }}>
                                <H4>Forgot Your Password?</H4>
                                <div style={{ display: 'flex', marginTop: '5px' }}>
                                  <P attrPara={{ style: { fontSize: '18px' } }} >Enter your registered email address and click on the reset password button.</P>
                                </div>
                                <div style={{ display: 'flex', marginTop: '15px' }}>
                                  <P attrPara={{ style: { fontSize: '18px' } }} >If you don’t see the email, be sure to check your spam folder or try resending the request.</P>
                                </div>
                              </div>

                            </div>
                          </Col>
                          <Col sm='6'>
                            <div style={{ padding: '20px 20px 0px 0px' }}>
                              <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: '500px', display: 'flex', flexDirection: 'column' }}>
                                <Form className='theme-form login-form' onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                  <H4 attrH4={{ style: { color: '#FC5455' } }}>Forgot Password</H4>
                                  <P>Enter the email address associated with your account and we will send you a One Time Passcode.</P>
                                  <FormGroup>
                                    <Label className='m-0 col-form-label'>Enter Your Email</Label>
                                    <Input
                                      className='form-control'
                                      type='email'
                                      placeholder='Enter Your Email'
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {error && <span className="text-danger">{error}</span>}
                                  </FormGroup>
                                  <FormGroup style={{ marginTop: 'auto', marginLeft: '-15px' }}>
                                    {loading ?
                                      <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit', style: { width: '100%' }, disabled: loading }}>
                                        Resetting...
                                      </Btn>
                                      :
                                      <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit', style: { width: '100%' } }}>
                                        Reset Password
                                      </Btn>
                                    }

                                  </FormGroup>
                                </Form>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ForgetPassword;
