export const PhoneNumberTile = (props) => {
    const {item: phoneNumber, handleGetNumber} = props;
    return (
        <div
            key={phoneNumber.id}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: '#FBFBFB',
                height: '40px',
                borderRadius: '5px',
                border: '1px solid #DCDBDB',
            }}
        >
            <p style={{color: 'black', marginTop: '15px'}}>
                {phoneNumber?.friendly_name}
            </p>
            <div style={{width: '130px'}}>
                <p style={{color: 'black', textAlign: 'left', lineHeight: 1}}>
                    {phoneNumber?.locality === null ? 'N/A' : phoneNumber?.locality}
                </p>
            </div>
            <div>
                <p
                    style={{
                        border: '1px solid #F73164',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        color: '#F73164',
                    }}
                    onClick={() => handleGetNumber(phoneNumber)}
                >
                    Get Number
                </p>
            </div>
        </div>
    );
}
