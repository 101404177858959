const assistants = {
  SET_ASSISTANT_RIGHT_SIDEBAR: "SET_ASSISTANT_RIGHT_SIDEBAR",
  SET_SELECTED_ASSISTANT: "SET_SELECTED_ASSISTANT",
  UPDATE_ASSISTANT: "UPDATE_ASSISTANT",
  DELETE_ASSISTANT: "DELETE_ASSISTANT",
  CREATE_ASSISTANT: "CREATE_ASSISTANT",
  GET_SELECTED_PRECAST_BOT: "GET_SELECTED_PRECAST_BOT",
  CREATE_PRECAST_BOT: "CREATE_PRECAST_BOT",
  SETUP_ASSISTANT_PHONE: "SETUP_ASSISTANT_PHONE",
};

const precastBots = {
  GET_BOT_VOICE_MODEL: "GET_BOT_VOICE_MODEL",
  GET_PRECAST_BOTS: "GET_PRECAST_BOTS",
  SET_SELECTED_PRECAST_BOT: "SET_SELECTED_PRECAST_BOT",
};

const callLogs = {
  FETCH_CALL_LOG_COMPANY: "FETCH_CALL_LOG_COMPANY",
};

const keywordsAnalysisReport = {
  FETCH_KEYWORDS_ANALYSIS_REPORT: "FETCH_KEYWORDS_ANALYSIS_REPORT",
};

const packages = {
  UPGRADE_PACKAGE: "UPGRADE_PACKAGE",
  GET_STRIPE_SESSION: 'GET_STRIPE_SESSION',
  GET_USER_PAYMENT_METHOD: "GET_USER_PAYMENT_METHOD",
  UPDATE_USER_PAYMENT_METHOD: "UPDATE_USER_PAYMENT_METHOD",
}

const operationSettings = {
  OPERATIONAL_SETTINGS_BY_COMPANY: 'OPERATIONAL_SETTINGS_BY_COMPANY'
}

const company = {
  FETCH_USER_COMPLETE_COMPANY: "FETCH_USER_COMPLETE_COMPANY",
}

export const ActionTypes = {
  ...company,
  ...operationSettings,
  ...packages,
  ...assistants,
  ...precastBots,
  ...callLogs,
  ...keywordsAnalysisReport,
};
