import { AxiosInstance, AxiosInstance2 } from './AxiosInstance';

let getuseInfo = JSON.parse(localStorage.getItem("auth0_profile"));

export const loginUser = async (credentials) => {
    const response = await AxiosInstance.post('/login', { ...credentials });
    localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const registerUser = async (credentials) => {
    const response = await AxiosInstance.post('/register', { ...credentials });
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${response.token}`;
    // localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const verificationOTP = async (values) => {
    const response = await AxiosInstance.post('/veripyOTP', values);
    return response.data;
};

export const verifyOTPPass = async (values) => {
    const response = await AxiosInstance.post('/veripyOTPPass', values);
    return response.data;
};

export const resendOTP = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/resendOTP`);
    return response.data;
};

export const forgotPassword = async (formData) => {
    const response = await AxiosInstance.post(`/forGetPassword`, formData);
    return response.data;
};

export const resetPassword = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/resetPassword`, formData);
    return response.data;
};

export const changePassword = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/changePassword`, formData);
    return response.data;
};

export const getUserCompany = async (res, id) => {
    let company_id = {
        company_id: id
    }
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${res}`;
    const response = await AxiosInstance.post(`/getUserCompany`, company_id);
    return response.data;
};

export const getWeeksData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/companyStats`, formData);
    return response.data;
};

export const getDashboard = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/dashboadAPI`, formData);
    return response.data;
};

export const getDataTable = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getCompanyDirectory`, formData);
    return response.data;
};

export const keywordReport = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/keywordsReport`, formData);
    return response.data;
};

export const singleItemDirectory = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/AddSingleItemToDirectory`, formData);
    return response.data;
};

export const updateSingleItemDirectory = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/updateCompanyDirectory`, formData);
    return response.data;
};

export const deleteSingleItemDirectory = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/deleteSingleCompanyDirectoryItem`, formData);
    return response.data;
};

export const addConnectBotToPhone = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/ConnectBotToPhone`, formData);
    return response.data;
};

export const getViewCompanyPhoneNumber = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getCompanyPhoneDetail`, formData);
    return response.data;
};

export const deletePhoneDirectory = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/deleteCompanyDirectory`, formData);
    return response.data;
};

export const deletePhoneNumber = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/DeletePhone`, formData);
    return response.data;
};

export const deleteBot = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/deleteBot`, formData);
    return response.data;
};

export const editPhoneNumber = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/upateCompanyPhone`, formData);
    return response.data;
};

export const editUpdateBot = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/updateBot`, formData);
    return response.data;
};

export const editBotNumber = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/updateBotPhoneNumber`, formData);
    return response.data;
};


export const getCreateBot = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/createBot`, formData);
    return response.data;
};

export const generateBotPersona = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/generateBotPersona`, formData);
    return response.data;
};

export const generateOTP = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get(`/generateOTP`);
    return response.data;
};

export const getAssistantVoice = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getBotVoiceModel`, formData);
    return response.data;
};

export const getPaymentHistory = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/paymentHistory`, formData);
    return response.data;
};
export const getUsageSummary = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/callSummryReport`, formData);
    return response.data;
};

export const getAllCompanyData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getCompleteCompanyByUser`, formData);
    return response.data;
};

export const getAllCompanyCustomers = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getCompanyCustomers`, formData);
    return response.data;
};

export const getWeekCallData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/callsCount`, formData);
    return response.data;
};

export const getKeywordsData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/keywordsReportDateWise`, formData);
    return response.data;
};

export const getSingleKeywordDetails = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/singleKyewordDetail`, formData);
    return response.data;
};

export const getChildCallsStatusSummary = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getChildCallsStatusSummary`, formData);
    return response.data;
};

export const getCallsReport = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/allCallsReport`, formData);
    return response.data;
};

export const getTranscript = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getCallTranscript`, formData);
    return response.data;
};

export const getChildCallsDetail = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/childCallsDetail`, formData);
    return response.data;
};

export const getDepartmentStaffSumry = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/DepartmentStaffSummary`, formData);
    return response.data;
};


export const getColorList = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get(`/getColorsList`);
    return response.data;
};


export const childCallsDetail = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/childCallsDetail`, formData);
    return response.data;
};


export const personWiseChildDetail = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getPersonWiseCallDetail`, formData);
    return response.data;
};



export const getDepartments = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getAllDepartments`, formData);
    return response.data;
};

export const getDepartmentsWiseStaff = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getDeptWiseStaff`, formData);
    return response.data;
};

export const getCreateDepartment = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/createDepartment`, formData);
    return response.data;
};

export const getAllPersonas = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/getAllPersonas`);
    return response.data;
};


export const getBotByCompany = async (formData, token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getBotByCompany', formData);
    return response.data;
};

export const getAllNotifications = async (formData, token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getNotification', formData);
    return response.data;
};

export const getBotRaw = async (formData, token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getBotRaw', formData);
    return response.data;
};


export const uploadFile = async (formData, token) => {
    const headers = AxiosInstance2.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance2.post('/uploadkb', formData);
    return response.data;
};

export const replaceFile = async (formData, token) => {
    const headers = AxiosInstance2.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance2.post('/replacekb', formData);
    return response.data;
};

export const getAllFileData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getCompanyKB', formData);
    return response.data;
};

export const getFileByIdData = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getkb', formData);
    return response.data;
};

export const deleteFileById = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/deletekb', formData);
    return response.data;
};

export const companySchedule = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getCompanySchedule', formData);
    return response.data;
};

export const confirmBooking = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/markConfirmBooking', formData);
    return response.data;
};

export const setupBussiness = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/setupBussiness', formData);
    return response.data;
};

export const getWebContent = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/getWebsiteContent', formData);
    return response.data;
};

export const createManualBot = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/setUpBot', formData);
    return response.data;
};

export const setUpPhone = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/setUpPhone', formData);
    return response.data;
};

export const defaultBots = async (token, id, lang) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get(`/getDefaultBots/` + `${id}/` + lang);
    return response.data;
};

export const createSelectedBot = async (token, id, lang) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get(`/SelectedBot/` + id);
    return response.data;
};


export const setNotiData = async  (token, id, companyId) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get(`/update_notif?id=${id}&companyId=${companyId}`);
    return response.data;
};




