import { createSlice } from '@reduxjs/toolkit';
import {showToast} from "../../../helpers/ToastHelper";

const packageSlice = createSlice({
    name: 'package',
    initialState: {
        package_type: '',
        loading: false,
        error: null,
    },
    reducers: {
        upgradingPackage: (state, action) => {
            state.loading = true;
            state.error = null;
            state.package_type = action.payload.package_type;
        },
        upgradePackageSuccess: (state, action) => {
            state.loading = false;
        },
        upgradePackageError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    upgradingPackage,
    upgradePackageSuccess,
    upgradePackageError,
} = packageSlice.actions;

export default packageSlice.reducer;
