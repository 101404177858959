import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';
import { FaComments, FaWindowClose } from 'react-icons/fa';
import { AiOutlineSend } from "react-icons/ai";
import { VscPlayCircle } from "react-icons/vsc";
import { isEmpty } from "../../../helpers/utils";
import { useHelpDeskService } from "../../../Services/useHelpDeskService";
import botImage from '../../../assets/images/user/newBot.jpeg'
import { getInitials } from '../../../Constant/GlobalFunctions';
import { H4 } from '../../../AbstractElements';

const Chat = () => {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const messagesEndRef = useRef(null);
  const { getHelpDeskResp, loadingHelpDeskResp } = useHelpDeskService();
  const firstMessage = 'Hi i am Zoey, how can I help you today?';
  const chatboxRef = useRef(null);


  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  const submitRef = useRef(null);

  useEffect(() => {
    if (showChat) {
      if (isEmpty(messages)) {
        appendMsg(firstMessage);
      }
      document.querySelector("[name='msg']").focus();
    }
  }, [showChat]);

  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleIconClick = () => {
    setShowChat(true);
  };

  const handleCloseClick = () => {
    setShowChat(false);
  };

  const userMsg = (msg) => {
    const timestamp = new Date().toISOString(); // Get the current timestamp
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: msg, user: true, timestamp } // Add timestamp
    ]);
    setInputValue("");
  };

  const appendMsg = (msg) => {
    const timestamp = new Date().toISOString(); // Get the current timestamp
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: msg, user: false, timestamp } // Add timestamp
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loadingHelpDeskResp) {
      return;
    }

    if (inputValue == "") {
      return
    }

    const mainval = inputValue;

    userMsg(mainval);
    scrollBottom();

    const helpDeskResp = await getHelpDeskResp({ question: mainval });
    appendMsg(helpDeskResp?.reply);
    scrollBottom();

    // messagesEndRef.current?.scroll({ top: messagesEndRef.scrollHeight, behavior: 'smooth' });
  };

  const scrollBottom = () => {
    var objDiv = document.querySelector(".chat-container-bot");
    objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' });
  }

  return (
    <div id={'chatDiv'} className={`chat-container-bot ${showChat ? 'chat-open' : ''}`}>
      <div className="botIcon" onClick={handleIconClick}>
        <FaComments />
      </div>
      {showChat && (
        <div className="chat-box">
          <div className="chat-header">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={botImage} style={{ width: '45px', height: '45px', borderRadius: '50%', marginRight: '10px' }}
                alt="Bot avatar" />
              <div style={{}}>
                <H4 attrH4={{ style: { color: "white", marginTop: '5px', fontSize: '16px' } }}>Zoey</H4>
                {/* <p style={{ fontSize: '12px' }}>+1 (912) 3921 9421</p> */}
              </div>
            </div>
            <div className="chat-close" style={{ display: 'flex' }}>
              {/* <VscPlayCircle size={22} style={{ marginRight: '10px' }} /> */}
              <FaWindowClose size={22} onClick={handleCloseClick} />
            </div>
          </div>
          <div ref={chatboxRef} className="chat-messages">
            <div className="message-list">
              {messages.map((msg, index) => {
                return (
                  <div key={index} className={`message ${msg.user ? 'user' : 'bot'}`}>
                    <div className="avatarImg">
                      <div style={{ maxWidth: '80%', justifySelf: msg.user ? 'flex-end' : '', minWidth: '100px' }}>
                        <p className={msg.user ? "message-text-user" : "message-text"}
                          dangerouslySetInnerHTML={{ __html: msg?.text }}></p>
                        <p style={{
                          color: '#888d93',
                          marginLeft: msg.user ? '0px' : '40px',
                          fontSize: '10px',
                          marginBottom: '0px'
                        }}>
                          {msg.timestamp ? new Date(msg.timestamp).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          }) : 'N/A'}
                        </p>
                      </div>
                      {msg.user ? (
                        <p style={{
                          margin: '0px',
                          backgroundColor: '#5678D5',
                          padding: '5px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          width: '32px',
                          height: '32px',
                          justifySelf: 'flex-end',
                          marginTop: '-15px',
                          fontSize: '14px'
                        }}>
                          {getInitials(auth0_profile.name)}
                        </p>
                      ) : (
                        <img src={botImage} style={{
                          width: '35px',
                          height: '35px',
                          borderRadius: '50%',
                          marginRight: '10px',
                          marginTop: '-15px'
                        }} alt="Bot avatar" />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div ref={messagesEndRef} />
          <div style={{ backgroundColor: "white", color: 'black' }}>
            {loadingHelpDeskResp &&
              (
                <div className={`message bot}`}>
                  <div className="avatarImg">
                    <div style={{ maxWidth: '80%', minWidth: '100px' }}>
                      <p className={"message-text"}
                        dangerouslySetInnerHTML={{ __html: 'Typing...' }}></p>
                      <p style={{
                        color: '#888d93',
                        marginLeft: '40px',
                        fontSize: '10px',
                        marginBottom: '0px'
                      }}>
                        {new Date().toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </p>
                    </div>
                    <img src={botImage} style={{
                      width: '35px',
                      height: '35px',
                      borderRadius: '50%',
                      marginRight: '10px',
                      marginTop: '-15px'
                    }} alt="Bot avatar" />
                  </div>
                </div>
              )}
            {/*(<span>Typing... </span>)}*/}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <textarea
                disabled={loadingHelpDeskResp}
                name="msg"
                className="input-field"
                placeholder="Type your message here and ..."
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.shiftKey && e.key === 'Enter') {
                    e.preventDefault();
                    submitRef?.current?.click();
                  }
                }}
              />
              <button ref={submitRef} type="submit" className="input-button">
                <AiOutlineSend size={20} />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chat;
