import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { CardBody, Col } from 'reactstrap';
import { H5, H6, UL, LI, P } from '../../AbstractElements';
import { AxiosInstance } from '../../api/AxiosInstance';
import { ActivityData } from '../../Data/DefaultDashboard';
import { Color } from '../../helpers/colors';
import { debounceTime } from '../../helpers/constants';
import './Dashboard.css'


const UpcomingMeetings = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))

    const location = useLocation();
    const pathName = location.pathname;

    const [calendarEvents, setCalendarEvents] = useState([])
    console.log("🚀 ~ calendarEvents:", calendarEvents)

    const isInitialRender = useRef(true);

    useEffect(() => {
        // handleGetAllCompany();
        // getWeeksDataInfo(token);
        if (pathName === "/app/dashboard") {
            if (isInitialRender.current) {
                isInitialRender.current = false;

                const delayDebounceFn = setTimeout(() => {
                    getSchaduleData();
                }, debounceTime);

                return () => clearTimeout(delayDebounceFn);
            }
        }
    }, []);

    const getSchaduleData = async () => {
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        const response = await AxiosInstance.get('/company/bookings?company_id=' + getCompanyInfo.id);
        const firstFiveEvents = response?.data?.data?.slice(0, 5) || [];
        setCalendarEvents(firstFiveEvents);
    }
    return (
        <Col xxl='12' xl='12' md='12' sm='12' className='notification box-col-6'>
            <div className='height-equal'>
                <CardBody className='pt-0' style={{ height: '250px', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                    <UL>
                        {calendarEvents?.map((item, i) => {
                            const dateStr = item.start_time;
                            const dateEnd = moment(item.end_time)

                            const date = moment(dateStr);

                            const formattedDate = date.format("MMM Do");
                            const formattedStartTime = date.format("hh:mma");
                            const formattedEndTime = dateEnd.format("hh:mma");
                            return (
                                <LI key={i} attrLI={{ className: 'd-flex' }}>
                                    <div className={`activity-dot-primary`} />
                                    <div className='w-100 ms-3'>
                                        <H6 attrH6={{ style: { marginTop: '5px' }, className: 'd-flex justify-content-between mb-0' }}>
                                            {item.name}
                                            <span className='dot-notification' />
                                        </H6>
                                        <P attrPara={{className: 'mb-0' }}>
                                            <span style={{ fontSize: '12px', color: Color.GREY }}>{formattedDate} {formattedStartTime} - {formattedEndTime}</span>
                                        </P>
                                    </div>
                                </LI>
                            )
                        })}
                    </UL>
                </CardBody>
            </div>
        </Col>
    );
};

export default UpcomingMeetings;
