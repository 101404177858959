export const Color = {
  PRIMARY: "#5679D5",
  SECONDARY: "#FC5455",
  GREEN: "#54BA4A",
  LIGHT_GREEN: "#00FF57",
  BUTTON_GREEN: "#5fc28e",
  PRIMARY_BACKGROUND: "#EFF6FF",
  WHITE: "#FFFFFF",
  BLACK: "black",
  LIGHT_GREY: "#F1F5F9",
  GREY: "#94A3B8",
  LIGHT_BLUE: "#dbf7ff",
  DARK_THE_GREY_TEXT: "#A6A8B2",
  TILES_ORANGE: "#f39c12",
  BUTTON_TEXT_GREY:'#5D6679'
};
