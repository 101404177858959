import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Row, FormGroup, Label, Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllCompanyPackages, getAllCompanyTypes, getAllCountries, getStates, updateCompanyData } from "../../api/Company";
import '../Company/CreateCompany.css';
import '../ManageAssistant/ManageAssistant.css'
import '../ManageDirectory/styles.css'
import { useNavigate } from "react-router";
import moment from "moment";
import ApiContext from "../../ApiContext";
import { getUserCompany } from "../../api/AuthIndex";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../Store/redux-store/ActionTypes";

const EditCompany = ({ companyData, cancelButton }) => {
    let getCompanyInfo = companyData ? companyData : JSON.parse(localStorage.getItem('companyData'))
    console.log("🚀 ~ getCompanyInfo:", getCompanyInfo)
    const theme = localStorage.getItem("mix_background_layout");

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const { isUpdated, setUpdateStatus, setAllCompanies, setSelectCompany } = useContext(ApiContext);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [companyPackages, setCompanyPackages] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(getCompanyInfo?.country);
    const [selectedType, setSelectedType] = useState("");
    const [phone, setPhone] = useState(getCompanyInfo?.official_phone ? getCompanyInfo?.official_phone : "");
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem('auth0_profile'));
    const token = localStorage.getItem('token');

    useEffect(() => {
        getCountry();
        getCompanyTypes();
        getCompanyPackages()
    }, []);

    const getState = async (countryId) => {
        const res = await getStates(countryId);
        setStates(res.states);
    };

    useEffect(() => {
        if (selectedCountry) {
            setStates([]);
            getState(selectedCountry);
        }
    }, [selectedCountry]);

    const getCountry = async () => {
        let res = await getAllCountries();
        setCountries(res.countries);
    };

    const getCompanyTypes = async () => {
        let res = await getAllCompanyTypes(token);
        setCompanyTypes(res.cType);
    };

    const getCompanyPackages = async () => {
        try {
            let res = await getAllCompanyPackages(token);
            console.log("🚀 ~ getCompanyPackages ~ res:", res)
            const payAsYouGoPackage = res.packages.filter(pkg => pkg.package_name === 'Pay so you go');
            setCompanyPackages(payAsYouGoPackage);
        } catch (error) {
            console.log("🚀 ~ getCompanyTypes ~ error:", error)
        }
    };

    const getUserCompanyInfo = async () => {
        let res = await getUserCompany(token, getCompanyInfo?.id);
        localStorage.setItem("companies", JSON.stringify(res.Company));
        setAllCompanies(res.Company)
    };

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const formik = useFormik({
        initialValues: {
            company_name: getCompanyInfo?.company_name,
            email: getCompanyInfo?.email,
            location: getCompanyInfo?.location,
            address: getCompanyInfo?.address,
            address2: getCompanyInfo?.address2,
            country: getCompanyInfo?.country,
            state: getCompanyInfo?.state,
            city: getCompanyInfo?.city,
            zip: getCompanyInfo?.zip,
            website: getCompanyInfo?.website,
            company_intro: getCompanyInfo?.introduction,
            products_and_services: getCompanyInfo?.products_and_services,
            package: getCompanyInfo?.package == 1 ? "Gold" : getCompanyInfo?.package == 2 ? "Silver" : getCompanyInfo?.package == 3 ? "Bronze" : "",
            official_phone: getCompanyInfo?.official_phone,
            company_type: getCompanyInfo?.company_type,
        },
        validationSchema: Yup.object({
            company_name: Yup.string().required("Company name is required."),
            // email: Yup.string()
            //     .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
            //     .required("Email is required"),
            // address: Yup.string().required("Address is required"),
            // address2: Yup.string().required("Address 2 is required"),
            // country: Yup.string().required("Country is required"),
            // // state: Yup.string().required("State is required"),
            // city: Yup.string().required("City is required"),
            // zip: Yup.string().required("Zip Code is required"),
            // zip: Yup.string().required("Zip Code is required"),
            website: Yup.string()
                .matches(
                    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                    "Invalid URL. e.g. https://www.example.com or www.example.com"
                )
                .required("Website is required."),
            company_intro: Yup.string().required("About Company is required."),
            products_and_services: Yup.string().required("Products and Services are required."),
            // package: Yup.string().required("Package is required"),
            official_phone: Yup.string().required("Phone is required"),
            location: Yup.string().required("Location is required."),
        }),
        onSubmit: async (values) => {
            setLoading(true)
            try {
                const formData = new FormData();
                formData.append('company_id', getCompanyInfo.id);
                formData.append('company_name', values?.company_name);
                formData.append('email', values?.email);
                formData.append('introduction', values?.company_intro);

                let formattedUrl = values?.website.trim();

                // Check if URL starts with "https://"
                if (!formattedUrl.startsWith('https://')) {
                    formattedUrl = `https://${formattedUrl}`;
                }

                // Check if URL contains "www." after "https://"
                if (!formattedUrl.includes('www.', 8)) {
                    const urlWithoutProtocol = formattedUrl.replace('https://', '');
                    formattedUrl = `https://www.${urlWithoutProtocol}`;
                }

                // Append to formData
                formData.append("website", formattedUrl === 'https://www.' ? "" : formattedUrl);

                if (values?.upload_logo) {
                    formData.append('upload_logo', values?.upload_logo)
                }

                // if (values?.upload_logo === undefined || values?.upload_logo === null) {
                //     if (getCompanyInfo?.upload_logo) {
                //         formData.append('upload_logo', getCompanyInfo.upload_logo);
                //     } else {
                //         formData.append('upload_logo', "");
                //     }
                // } else {
                //     formData.append('upload_logo', values?.upload_logo);
                // }

                formData.append('official_phone', values?.official_phone);
                // formData.append('package', values?.package ? values?.package : getCompanyInfo.package == 1 ? "Gold" : getCompanyInfo.package == 2 ? "Silver" : getCompanyInfo.package == 3 ? "Bronze" : "");
                const selectedPackage = values?.package ||
                    (getCompanyInfo?.latest_package_payment?.package == 1 ? 1 : getCompanyInfo?.latest_package_payment?.package == 2 ? 2 : getCompanyInfo?.latest_package_payment?.package == 3 ? 3 : "");
                formData.append('package', 3);

                formData.append('Country', values?.country);
                formData.append('city', '');
                formData.append('zip', '');
                formData.append('state', '');
                formData.append('location', values?.location);
                formData.append('address', '');
                formData.append('address2', '');
                formData.append('products_and_services', values?.products_and_services);
                formData.append('type', values.company_type);
                formData.append('info', 'Contact Us anytime');

                const packageStartDate = moment().format('YYYY-MM-DD');
                const packageEndDate = moment().add(30, 'days').format('YYYY-MM-DD');

                formData.append('package_start', packageStartDate);
                formData.append('package_end', packageEndDate);
                formData.append('last_invoice_date', packageEndDate);


                const res = await updateCompanyData(token, formData);
                console.log("🚀 ~ onSubmit: ~ res:", res)
                setUpdateStatus(true)
                localStorage.setItem("companyData", JSON.stringify(res?.company[0]));
                setSelectCompany(res?.company[0])

                if (res.company) {
                    toast.info("Company Updated Successfully", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'custom-toast',
                    });

                    setUpdateStatus(true);
                    setSelectCompany(res.company[0])
                    setLoading(false)

                    // getUserCompanyInfo();
                    // dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: getCompanyInfo.id } });

                    navigate(`${process.env.PUBLIC_URL}/app/manage-company`);
                }
            } catch (error) {
                console.log("🚀 ~ onSubmit: ~ error:", error)
                setLoading(false)
            }
        },
    })

    const handleFileChange = (event) => {
        formik.setFieldValue("upload_logo", event.currentTarget.files[0]);
    };

    return (
        <Fragment>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
            <Row>
                <Col sm="12">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h4>Edit Company</h4>
                        <div
                            style={{
                                borderRadius: '50%',
                                padding: '5px',
                                backgroundColor: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid rgba(105, 120, 143, 0.3)',
                                cursor: 'pointer'
                            }}
                        >
                            <RxCross2 size={20} onClick={cancelButton} color={theme === 'dark-only' ? 'grey' : '#69788F'} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="company_name" >Company Name</Label>
                                        <input
                                            className={`form-control ${formik.touched.company_name && formik.errors.company_name ? "is-invalid" : ""}`}
                                            id="company_name"
                                            type="text"
                                            name="company_name"
                                            placeholder="Enter Company Name"
                                            {...formik.getFieldProps('company_name')}
                                            maxLength={30}
                                        />
                                        {formik.touched.company_name && formik.errors.company_name ? (
                                            <span className="text-danger">{formik.errors.company_name}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="email" >Email</Label>
                                    <input
                                        className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <span className="text-danger">{formik.errors.email}</span>
                                    ) : null}
                                </FormGroup>
                            </Col> */}

                            <Row>
                                <Col sm="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="official_phone" >Phone Number</Label>
                                        <div className={`phone-input-container ${theme === 'dark-only' ? 'dark-theme-phone-input' : ''}`}>
                                            <PhoneInput
                                                international
                                                countryCallingCodeEditable={false}
                                                defaultCountry="US"
                                                value={phone}
                                                onChange={(value) => {
                                                    setPhone(value);
                                                    formik.setFieldValue('official_phone', value);
                                                }}
                                                className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                onBlur={() => formik.setFieldTouched('official_phone', true)}
                                                countries={['US', 'CA']}
                                                limitMaxLength={12}
                                            />
                                        </div>
                                        {formik.touched.official_phone && formik.errors.official_phone ? (
                                            <span className="text-danger">{formik.errors.official_phone}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="website" >Website</Label>
                                        <input
                                            className={`form-control ${formik.touched.website && formik.errors.website ? "is-invalid" : ""}`}
                                            id="website"
                                            type="text"
                                            name="website"
                                            placeholder="Enter Website"
                                            {...formik.getFieldProps('website')}
                                        />
                                        {formik.touched.website && formik.errors.website ? (
                                            <span className="text-danger">{formik.errors.website}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="address" >Location</Label>
                                        <textarea
                                            className={`form-control ${formik.touched.location && formik.errors.location ? "is-invalid" : ""}`}
                                            id="location"
                                            type="text"
                                            name="location"
                                            rows={'2'}
                                            placeholder="Enter Location"
                                            {...formik.getFieldProps('location')}
                                        />
                                        {formik.touched.location && formik.errors.location ? (
                                            <span className="text-danger">{formik.errors.location}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="address" >Address</Label>
                                    <input
                                        className={`form-control ${formik.touched.address && formik.errors.address ? "is-invalid" : ""}`}
                                        id="address"
                                        type="text"
                                        name="address"
                                        placeholder="Enter Address"
                                        {...formik.getFieldProps('address')}
                                    />
                                    {formik.touched.address && formik.errors.address ? (
                                        <span className="text-danger">{formik.errors.address}</span>
                                    ) : null}
                                </FormGroup>
                            </Col> */}

                            {/* <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="address2" >Address 2</Label>
                                    <input
                                        className={`form-control ${formik.touched.address2 && formik.errors.address2 ? "is-invalid" : ""}`}
                                        id="address2"
                                        type="text"
                                        name="address2"
                                        placeholder="Enter Address 2"
                                        {...formik.getFieldProps('address2')}
                                    />
                                    {formik.touched.address2 && formik.errors.address2 ? (
                                        <span className="text-danger">{formik.errors.address2}</span>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="country" >Country</Label>
                                    <select
                                        className={`form-control ${formik.touched.country && formik.errors.country ? "is-invalid" : ""}`}
                                        id="country"
                                        name="country"
                                        {...formik.getFieldProps('country')}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            setSelectedCountry(e.target.value);
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                            <option key={country.id} value={country.id}>{country.name}</option>
                                        ))}
                                    </select>
                                    {formik.touched.country && formik.errors.country ? (
                                        <span className="text-danger">{formik.errors.country}</span>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="state" >State</Label>
                                    <select
                                        className={`form-control ${formik.touched.state && formik.errors.state ? "is-invalid" : ""}`}
                                        id="state"
                                        name="state"
                                        {...formik.getFieldProps('state')}
                                    >
                                        <option value="">Select State</option>
                                        {states.map((state) => (
                                            <option key={state.id} value={state.code}>{state.name}</option>
                                        ))}
                                    </select>
                                    {formik.touched.state && formik.errors.state ? (
                                        <span className="text-danger">{formik.errors.state}</span>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="city" >City</Label>
                                    <input
                                        className={`form-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""}`}
                                        id="city"
                                        type="text"
                                        name="city"
                                        placeholder="Enter City"
                                        {...formik.getFieldProps('city')}
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <span className="text-danger">{formik.errors.city}</span>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="zip" >Zip Code</Label>
                                    <input
                                        className={`form-control ${formik.touched.zip && formik.errors.zip ? "is-invalid" : ""}`}
                                        id="zip"
                                        type="text"
                                        name="zip"
                                        maxLength={5}
                                        placeholder="Enter Zip Code"
                                        {...formik.getFieldProps('zip')}
                                    />
                                    {formik.touched.zip && formik.errors.zip ? (
                                        <span className="text-danger">{formik.errors.zip}</span>
                                    ) : null}
                                </FormGroup>
                            </Col> */}

                            {/* <Col sm="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="zip" >Location</Label>
                                    <textarea
                                        className={`form-control ${formik.touched.zip && formik.errors.zip ? "is-invalid" : ""}`}
                                        id="zip"
                                        type="text"
                                        name="zip"
                                        rows={"2"}
                                        // maxLength={5}
                                        placeholder="Enter Location"
                                        {...formik.getFieldProps('zip')}
                                    />
                                    {formik.touched.zip && formik.errors.zip ? (
                                        <span className="text-danger">{formik.errors.zip}</span>
                                    ) : null}
                                </FormGroup>
                            </Col> */}

                            <Row>
                                <Col sm="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="company_type">Company Type</Label>
                                        <select
                                            className={`form-control ${formik.touched.company_type && formik.errors.company_type ? "is-invalid" : ""}`}
                                            id="company_type"
                                            name="company_type"
                                            value={formik.values.company_type} // Ensure value is set from formik
                                            {...formik.getFieldProps('company_type')}
                                            onChange={(e) => {
                                                console.log("🚀 ~ e:", e.target.value)
                                                formik.handleChange(e);
                                                // setSelectedType(e.target.value); // You can manage this if needed
                                            }}
                                        >
                                            {/* <option value="">Select Type</option> */}
                                            {companyTypes.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.type}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.company_type && formik.errors.company_type ? (
                                            <span className="text-danger">{formik.errors.company_type}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="upload_logo" >Company Logo</Label>
                                        <input
                                            className={`form-control ${formik.touched.upload_logo && formik.errors.upload_logo ? "is-invalid" : ""}`}
                                            id="upload_logo"
                                            type="file"
                                            name="upload_logo"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                        />
                                        {formik.touched.upload_logo && formik.errors.upload_logo ? (
                                            <span className="text-danger">{formik.errors.upload_logo}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="company_intro" >About Company</Label>
                                        <textarea
                                            rows={5}
                                            className={`form-control ${formik.touched.company_intro && formik.errors.company_intro ? "is-invalid" : ""}`}
                                            id="company_intro"
                                            name="company_intro"
                                            placeholder="Enter About Company"
                                            {...formik.getFieldProps('company_intro')}
                                        ></textarea>
                                        {formik.touched.company_intro && formik.errors.company_intro ? (
                                            <span className="text-danger">{formik.errors.company_intro}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="products_and_services">Products and Services</Label>
                                        <textarea
                                            rows={5}
                                            className={`form-control ${formik.touched.products_and_services && formik.errors.products_and_services
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                            id="products_and_services"
                                            name="products_and_services"
                                            placeholder="Enter Products and Services"
                                            {...formik.getFieldProps("products_and_services")}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();

                                                    const textarea = e.target;
                                                    const cursorPosition = textarea.selectionStart;
                                                    const currentValue = textarea.value;

                                                    const newValue =
                                                        currentValue.slice(0, cursorPosition) +
                                                        "\n" +
                                                        currentValue.slice(cursorPosition);

                                                    formik.setFieldValue("products_and_services", newValue, false);

                                                    // Reset cursor position
                                                    setTimeout(() => {
                                                        textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1;
                                                    }, 0);
                                                }
                                            }}
                                        ></textarea>
                                        {formik.touched.products_and_services && formik.errors.products_and_services ? (
                                            <span className="text-danger">{formik.errors.products_and_services}</span>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Col sm="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="package" >Package</Label>
                                    <select
                                        className={`form-control ${formik.touched.package && formik.errors.package ? "is-invalid" : ""}`}
                                        id="package"
                                        name="package"
                                        {...formik.getFieldProps('package')}
                                    >
                                        {companyPackages?.map((item) => {
                                            console.log("🚀 ~ {companyPackages?.map ~ item:", item)
                                            return (
                                                <option value={item?.id}>{item?.package_name}</option>
                                            )
                                        })}
                                    </select>
                                    {formik.touched.package && formik.errors.package ? (
                                        <span className="text-danger">{formik.errors.package}</span>
                                    ) : null}
                                </FormGroup>
                            </Col> */}

                            <Col sm="12">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button color="primary" type="submit" disabled={loading}>
                                        {loading ? 'Submiting...' : 'Submit'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </Fragment>
    );
};

export default EditCompany;
