import { useState } from 'react';
import {apiService} from "./ApiService";
import {ApiEndpoints} from "../helpers/ApiEndpoints";
import {showToast} from "../helpers/ToastHelper";
import {TOAST_TYPES} from "../helpers/constants";


export const useUpdateUserService = () => {
    const [updateUserErr, setUpdateUserErr] = useState(null);
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);

    const updateUser = async (data) => {
        setIsUpdatingUser(true);
        try {
            const resp =   await apiService.post(ApiEndpoints.UPDATE_USER, data);
            showToast({message: resp.message});
        } catch (err) {
            setUpdateUserErr(err.message);
            showToast({ type: TOAST_TYPES.DANGER, message:err.message});
        } finally {
            setIsUpdatingUser(false);
        }
    };

    return { updateUser, updateUserErr, isUpdatingUser };
};