import React, { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import CustomizerContext from '../../_helper/Customizer';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import AssistantTiles from '../../component2/AssistantTiles/AssistantTiles';

const Breadcrumbs = (props) => {
  const { layoutURL } = useContext(CustomizerContext);
  const location = useLocation()
  const pathname = location.pathname
  return (
    <Fragment>
      <Container fluid={true}>
        <div className='page-title'>
          <Row>
            <Col xs='5' style={{padding:'0px'}}>
              <div style={{ marginTop: '20px', padding:'0px', marginLeft:'-3px' }}>
                <H3>{props.mainTitle}</H3>
              </div>
            </Col>
            <Col xs='7'>
              {pathname === '/app/dashboard' &&
                <div style={{ marginTop: '20px' }}>
                  <AssistantTiles />
                </div>
              }
              {/* <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to={`${process.env.PUBLIC_URL}/app/dashboard`}>
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>{props.parent}</li>
                {props.subParent ? <li className='breadcrumb-item'>{props.subParent}</li> : ''}
                <li className='breadcrumb-item active'>{props.title}</li>
              </ol> */}
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
