import React, { useState, Fragment, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Eye, EyeOff, Facebook, Linkedin, Twitter } from 'react-feather';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Btn, H4, P, H6, Image } from '../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import roboImg from '../assets/images/logo/roboImg.png';
import { registerUser } from '../api/AuthIndex';
import { ToastContainer, toast } from "react-toastify";
import { Modal, Spinner } from 'react-bootstrap';
import '../component2/ManageAssistant/ManageAssistant.css'
import solarArrow from '../assets/images/logo/solarArrow.png';
import tickCircle from '../assets/images/logo/tickCircle.png';
import { FcGoogle } from "react-icons/fc";
import { GoogleSignIn } from './GoogleAuth';
import ApiContext from '../ApiContext';

const PasswordStrengthMeter = ({ password }) => {
    const [strength, setStrength] = useState(0);

    // Function to determine password strength based on the given rules
    const calculateStrength = (password) => {
        let score = 0;

        // Rule 1: Check if password has a lowercase letter
        if (/[a-z]/.test(password)) score += 1;

        // Rule 2: Check if password has an uppercase letter
        if (/[A-Z]/.test(password)) score += 1;

        // Rule 3: Check if password has a number
        if (/\d/.test(password)) score += 1;

        // Rule 4: Check if password has a special character
        if (/\W/.test(password)) score += 1;

        // Rule 5: Check if password length is 8 or more
        // if (password.length >= 8) score += 1;

        setStrength(score);
    };

    // Calculate password strength on every change
    React.useEffect(() => {
        calculateStrength(password);
    }, [password]);

    return (
        <div>
            <div style={{ display: 'flex', height: '5px', marginBottom: '5px' }}>
                {[...Array(4)].map((_, i) => (
                    <div
                        key={i}
                        style={{
                            flex: 1,
                            marginRight: i < 3 ? '5px' : '0', // Add space between boxes
                            backgroundColor: i < strength ? getStrengthColor(strength) : '#ddd',
                            transition: 'background-color 0.5s ease',
                        }}
                    ></div>
                ))}
            </div>
            <span style={{ color: getStrengthColor(strength), fontSize: '12px' }}>
                {getStrengthLabel(strength)}
            </span>
        </div>
    );
};

// Helper function to get color based on strength level
const getStrengthColor = (strength) => {
    switch (strength) {
        case 1:
            return 'orange';
        case 2:
            return 'yellowgreen';
        case 3:
            return 'green';
        case 4:
            return 'darkgreen';
        default:
            return '#ddd';
    }
};

// Helper function to get label based on strength level
const getStrengthLabel = (strength) => {
    switch (strength) {
        case 1:
            return 'Weak';
        case 2:
            return 'Medium';
        case 3:
            return 'Strong';
        case 4:
            return 'Very Strong';
        default:
            return '';
    }
};

const RegisterFrom = ({ logoClassMain }) => {
    const { setBackNavigation } = useContext(ApiContext)
    const navigate = useNavigate()

    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string().required('Full name is required.'),
        email: Yup.string().email('Invalid email address.').required('Email is required.'),
        password: Yup.string().min(8, 'Password must be at least 8 characters.').required('Password is required.'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match.')
            .required('Confirm password is required.'),
    });

    const handleSignUp = async (values) => {
        if (!agree) {
            setError('To create an account, you must agree to the Terms & Conditions.')
            // toast.error("To create an account, you must agree to the Terms & Conditions.", {
            //     position: 'top-left',
            //     autoClose: 3000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     className: 'custom-toast-error',
            // });
            return;
        }
        setLoading(true)
        try {
            const res = await registerUser(values)
            const token = res.token
            setLoading(false);
            localStorage.setItem("email", values.email)
            localStorage.setItem("register", JSON.stringify(res))
            setBackNavigation(false)
            setTimeout(() => {
                navigate(`${process.env.PUBLIC_URL}/verify-otp`, { state: { values, token } });
            }, 1500);
        } catch (error) {
            setLoading(false)
            console.log("🚀 ~ handleSignUp ~ error:", error)
            toast.error(error?.response?.data?.error?.email[0], {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
        }
    }

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                    backgroundSize: 'cover', // Adjusts the image to cover the entire area
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    display: 'block',
                    minHeight: '100vh',
                }}>
                <div>
                    <Row>
                        <div style={{ marginTop: '1%' }}>
                            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                    <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                    <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                                </div>
                            </Link>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '2%',
                            }}>

                            <Card style={{ width: '1000px', padding: '0px' }}>
                                <CardBody style={{ padding: '0px' }}>
                                    <Row>
                                        <Col sm='6' style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                                            <div style={{ position: 'relative', zIndex: 2, display: 'flex', padding: '20px 0px 0px 20px' }}>
                                                <div style={{ marginTop: '8px', }}>
                                                    <H4>Welcome to Fluten!</H4>
                                                    <div style={{ display: 'flex', marginTop: '25px' }}>
                                                        <P attrPara={{ style: { fontSize: '18px' } }} >Transform the way you serve your customers with Fluten’s innovative AI Assistant solutions. By signing up or logging into your Fluten account, you'll unlock full control over your AI Assistant, empowering you to deliver exceptional customer service effortlessly.</P>
                                                    </div>
                                                    {/* <div style={{ display: 'flex', marginTop: '15px' }}>
                                                        <P attrPara={{ style: { fontSize: '18px' } }} >Sign up or log in to gain full control of your AI assistant. </P>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm='6'>
                                            <div style={{ padding: '20px 20px 0px 0px' }}>
                                                <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px' }}>
                                                    <Formik
                                                        initialValues={{
                                                            name: '',
                                                            email: '',
                                                            password: '',
                                                            password_confirmation: '',
                                                        }}
                                                        validationSchema={validationSchema}
                                                        onSubmit={handleSignUp}
                                                    >
                                                        {({ handleSubmit, values }) => (
                                                            <Form className='theme-form login-form' onSubmit={handleSubmit}>
                                                                <H4 attrH4={{ style: { color: '#FC5455' } }}>Create your new account</H4>
                                                                <P>Simple and fast setup.</P>
                                                                <div className='form-group'>
                                                                    <label className='col-form-label m-0 pt-0'>Full Name</label>
                                                                    <Row className='g-2'>
                                                                        <Col xs='12'>
                                                                            <Field
                                                                                className='form-control'
                                                                                type='text'
                                                                                name='name'
                                                                                placeholder='Full Name'
                                                                                maxLength={20}
                                                                            />
                                                                            <div style={{ height: '20px' }}>
                                                                                <ErrorMessage name='name' component='div' className='text-danger' />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className='form-group'>
                                                                    <label className='col-form-label m-0 pt-0'>Email Address</label>
                                                                    <Field
                                                                        className='form-control'
                                                                        type='email'
                                                                        name='email'
                                                                        placeholder='Test@gmail.com'
                                                                    />
                                                                    <div style={{ height: '20px' }}>
                                                                        <ErrorMessage name='email' component='div' className='text-danger' />
                                                                    </div>
                                                                </div>
                                                                {/* <div className='form-group position-relative'>
                                                                    <label className='col-form-label m-0 pt-0'>Password</label>
                                                                    <div className='position-relative'>
                                                                        <Field
                                                                            className='form-control'
                                                                            type={togglePassword ? 'text' : 'password'}
                                                                            name='password'
                                                                            placeholder='*********'
                                                                        />
                                                                        <div style={{ height: '45%' }} className='position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer' onClick={() => setTogglePassword(!togglePassword)}>
                                                                            {togglePassword ? <EyeOff size={20} style={{ marginTop: '-10px', cursor: 'pointer' }} /> : <Eye size={20} style={{ marginTop: '-10px', cursor: 'pointer' }} />}
                                                                        </div>
                                                                        <div style={{ height: '20px' }}>
                                                                            <ErrorMessage name='password' component='div' className='text-danger' />
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className='form-group position-relative'>
                                                                    <label className='col-form-label m-0 pt-0'>Password</label>
                                                                    <div className='position-relative'>
                                                                        <Field
                                                                            className='form-control'
                                                                            type={togglePassword ? 'text' : 'password'}
                                                                            name='password'
                                                                            placeholder='*********'
                                                                        />
                                                                        <div
                                                                            style={{ height: '45%' }}
                                                                            className='position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer'
                                                                            onClick={() => setTogglePassword(!togglePassword)}
                                                                        >
                                                                            {togglePassword ? (
                                                                                <EyeOff size={20} style={{ marginTop: '-10px', cursor: 'pointer' }} />
                                                                            ) : (
                                                                                <Eye size={20} style={{ marginTop: '-10px', cursor: 'pointer' }} />
                                                                            )}
                                                                        </div>
                                                                        <div style={{ height: '20px' }}>
                                                                            <ErrorMessage name='password' component='div' className='text-danger' />
                                                                        </div>
                                                                    </div>

                                                                    {/* Custom Password Strength Meter */}
                                                                    <PasswordStrengthMeter password={values.password} />
                                                                </div>
                                                                <div className='form-group position-relative'>
                                                                    <label className='col-form-label m-0 pt-0'>Confirm Password</label>
                                                                    <div className='position-relative'>
                                                                        <Field
                                                                            className='form-control'
                                                                            type={toggleConfirmPassword ? 'text' : 'password'}
                                                                            name='password_confirmation'
                                                                            placeholder='*********'
                                                                        />
                                                                        <span className='position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer' onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                                            {toggleConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                                                        </span>
                                                                        <div style={{ height: '20px' }}>
                                                                            <ErrorMessage name='password_confirmation' component='div' className='text-danger' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='form-group m-0' style={{ marginTop: '-20px' }}>
                                                                    <div className='checkbox'>
                                                                        <Field
                                                                            type='checkbox'
                                                                            name='agree'
                                                                            id='checkbox1'
                                                                            checked={agree}
                                                                            onChange={() => {
                                                                                setAgree(!agree)
                                                                                setError('')
                                                                            }}
                                                                        />
                                                                        <label className='text-muted' htmlFor='checkbox1'>
                                                                            I agree with the <span style={{ color: '#567ad5', cursor: 'pointer' }} onClick={handleShow}>Terms & Conditions</span> of Fluten
                                                                        </label>
                                                                        <p style={{ color: 'red', fontSize: '12px', marginLeft: '22px', marginTop: '-10px' }}>{error}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='form-group'>
                                                                    <div style={{ display: 'flex' }}>
                                                                        {loading ?
                                                                            <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'button', disabled: loading, }}>
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="grow"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Loading...
                                                                            </Btn>
                                                                            :
                                                                            // <Link className='ms-2' to={`${process.env.PUBLIC_URL}/verify-otp`}>
                                                                            //     Create Account
                                                                            // </Link>
                                                                            <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'submit' }}>
                                                                                Create New Account
                                                                            </Btn>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='login-social-title mt-3' style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                                                    <H6 attrH6={{ className: 'text-muted or' }}>OR</H6>
                                                                </div>
                                                                <div className='social' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    {/* <div className='btn-showcase'> */}
                                                                    {/* <div className='btn btn-light' style={{ display: 'flex', justifyContent: 'center', width:'95%', marginLeft:'10px' }}>
                                                                        <a
                                                                            // href='https://www.facebook.com/'
                                                                            rel='noreferrer'
                                                                            target='_blank'
                                                                            style={{ fontSize: '11px', marginTop:'5px' }}
                                                                        >
                                                                            <FcGoogle size={18} />
                                                                        </a>
                                                                        <p style={{ marginLeft: '5px', marginTop:'2px'}}>Google</p>
                                                                    </div> */}
                                                                    <GoogleSignIn />
                                                                </div>
                                                                <P attrPara={{ className: 'mb-0 text-start mt-3' }}>
                                                                    Already have an account?
                                                                    <Link className='ms-2' to={`${process.env.PUBLIC_URL}/login`}>
                                                                        Sign in
                                                                    </Link>
                                                                </P>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </div>
            </div>
            <Modal show={showModal} centered size='xl' onHide={handleClose}>
                <Modal.Header closeButton>
                    <H4 atrH4={{ style: { textAlign: 'center' } }} > Fluten.ai Terms and Conditions</H4>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '500px', overflow: 'scroll' }}>
                        <p style={{ fontWeight: '500' }}>1. Introduction</p>
                        <p>
                            Fluten.ai (the "Company") provides a platform (the "Platform") for users to access and utilize
                            its artificial intelligence-powered tools and services (the "Services"). By accessing or using
                            the Platform, you ("User") agree to be bound by these Terms and Conditions (the "Terms").
                        </p>
                        <p style={{ fontWeight: '500' }}>2. Acceptance</p>
                        <p>
                            By accessing or using the Platform, User acknowledges that they have read, understood, and agree
                            to be bound by these Terms.
                        </p>
                        <p style={{ fontWeight: '500' }}>3. Changes to Terms</p>
                        <p>
                            The Company reserves the right to modify or update these Terms at any time without notice.
                            User's continued use of the Platform after any such modification or update will constitute acceptance of the modified or updated Terms.
                        </p>
                        <p style={{ fontWeight: '500' }}>4. User Account</p>
                        <p>
                            To access certain features of the Platform, User must create a user account (the "Account").
                            User is responsible for maintaining the confidentiality of their Account login credentials and ensuring that they are not shared with any third party. User agrees to notify the Company immediately if they become aware of any unauthorized use of their Account.
                        </p>
                        <p style={{ fontWeight: '500' }}>5. User Conduct</p>
                        <p>
                            User agrees to use the Platform only for lawful purposes and in accordance with these Terms. User shall not:
                        </p>
                        <p>
                            * Violate any applicable laws or regulations;
                        </p>
                        <p>
                            * Engage in any illegal or harmful activities;
                        </p>
                        <p>
                            * Post or transmit any content that is obscene, defamatory, or otherwise objectionable;
                        </p>
                        <p>
                            * Attempt to hack, crack, or otherwise compromise the security of the Platform or any other system;
                        </p>
                        <p>
                            * Use the Platform to transmit any viruses, worms, or other malicious code;
                        </p>
                        <p>
                            * Use the Platform to solicit or collect personal data about other users without their consent;
                        </p>
                        <p>
                            * Use the Platform to engage in any commercial activities, including but not limited to advertising, soliciting funds, or selling products or services.
                        </p>
                        <p style={{ fontWeight: '500' }}>6. Intellectual Property</p>
                        <p>
                            The Company owns all intellectual property rights in and to the Platform, including but not limited to copyrights, trademarks, and patents. User agrees not to reproduce, modify, adapt, or distribute the Platform or any part thereof without the Company's prior written consent.
                        </p>
                        <p style={{ fontWeight: '500' }}>7. Data and Confidentiality</p>
                        <p>
                            The Company collects and uses certain data and information from User's use of the Platform (the "Data"). User agrees that the Company may use the Data for its internal purposes, including but not limited to improving the Platform and providing services to User. User acknowledges that the Company may disclose the Data to third parties, including but not limited to its affiliates, partners, and service providers, for the purpose of providing services to User.
                        </p>
                        <p style={{ fontWeight: '500' }}>8. Disclaimers and Limitations of Liability</p>
                        <p>
                            The Company disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. The Company shall not be liable for any damages, including but not limited to incidental, consequential, or punitive damages, arising out of or related to User's use of the Platform.
                        </p>
                        <p style={{ fontWeight: '500' }}>9. Indemnification</p>
                        <p>
                            User agrees to indemnify and hold the Company, its affiliates, and its officers, directors, employees, and agents harmless from and against any claims, damages, losses, or expenses (including but not limited to attorneys' fees) arising out of or related to User's use of the Platform.
                        </p>
                        <p style={{ fontWeight: '500' }}>10. Governing Law and Jurisdiction</p>
                        <p>
                            These Terms shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law. Any disputes arising out of or related to these Terms shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.
                        </p>
                        <p style={{ fontWeight: '500' }}>11. Entire Agreement</p>
                        <p>
                            These Terms constitute the entire agreement between the parties and supersede all prior or contemporaneous agreements or understandings.
                        </p>
                        <p style={{ fontWeight: '500' }}>12. Amendments</p>
                        <p>
                            The Company reserves the right to amend or modify these Terms at any time without notice.
                        </p>
                        <p style={{ fontWeight: '500' }}>13. Severability</p>
                        <p>
                            If any provision of these Terms is held invalid or unenforceable, such provision shall be severed and the remaining provisions shall remain in full force and effect.
                        </p>
                        <p style={{ fontWeight: '500' }}>14. Waiver</p>
                        <p>
                            The failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
                        </p>
                        <p style={{ fontWeight: '500' }}>15. Contact Information</p>
                        <p>
                            If User has any questions or concerns about these Terms, please contact the Company at [insert contact information].
                        </p>
                        <p>
                            By accessing or using the Platform, User acknowledges that they have read, understood, and agree to be bound by these Terms.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Fragment >
    );
};

export default RegisterFrom;
