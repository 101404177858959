import { createSlice } from "@reduxjs/toolkit";

const keywordsAnalysisLog = createSlice({
  name: "keywordsAnalysis",
  initialState: {
    keywordsData: null,
    tableData: [],
    chartData: {
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#7366ff"],
        xaxis: {
          categories: [],
        },
      },
    },
    loading: false,
    error: null,
  },
  reducers: {
    fetchkeywordsAnalysisLog: (state) => {
      state.loading = true;
      state.callLogError = null;
    },
    fetchkeywordsAnalysisLogSuccess: (state, action) => {
      state.loading = false;
      state.keywordsAnalysis = action.payload;
    },
    setTableData: (state, action) => {
      state.loading = false;
      state.tableData = action.payload;
    },
    setChartData: (state, action) => {
      state.loading = false;
      state.chartData = action.payload;
    },
    fetchkeywordsAnalysisLogError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setTableData,
  setChartData,
  fetchkeywordsAnalysisLog,
  fetchkeywordsAnalysisLogSuccess,
  fetchkeywordsAnalysisLogError,
} = keywordsAnalysisLog.actions;

export default keywordsAnalysisLog.reducer;
