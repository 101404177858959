import {createContext, useContext} from 'react';
import {useCompany} from "./useCompany";

const CompanyContext = createContext(null);

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyProvider = ({children}) => {
    const values = useCompany();

    return (
        <CompanyContext.Provider value={values}>
            {children}
        </CompanyContext.Provider>
    );
}