import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Container, Col, Row, Popover, OverlayTrigger, Card, CardBody } from 'react-bootstrap';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import './ScheduleDetail.css'; // Add custom styles in a CSS file
import { H4 } from '../../../AbstractElements';
import { FaRegUser } from 'react-icons/fa';
import { GrDocumentText } from "react-icons/gr";
import { FiPhone } from 'react-icons/fi';
import { Color } from '../../../helpers/colors';
import { AxiosInstance } from "../../../api/AxiosInstance";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IoTimeOutline } from 'react-icons/io5';

const ScheduleDetail = () => {
    const calendarComponentRef = useRef(null);

    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const [calendarEvents, setCalendarEvents] = useState([])
    useEffect(() => {
        getSchaduleData();
    }, [])
    const getSchaduleData = async (code) => {
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        const response = await AxiosInstance.get('/company/bookings?company_id=' + getCompanyInfo.id);
        console.log("🚀 ~ getSchaduleData ~ response:", response)
        const result = response.data.data.map(item => ({
            title: item.name,
            start: item.start_time.split(" ")[0], // Extract the date part
            end: item.end_time,
            extendedProps: {
                name: item.name,
                phone: item.phone_number,
                email: item.email,
                start: item.start_time,
                end: item.end_time,
            }
        }));
        setCalendarEvents(result);
    }

    // Dummy events
    // const calendarEvents = [
    //     { title: 'Initial consultation', start: '2024-11-15', extendedProps: { name: 'John Doe', phone: '1234567890' } },
    //     { title: 'Follow-up meeting', start: '2024-11-28', extendedProps: { name: 'Jane Smith', phone: '9876543210' } }
    // ];

    const renderEventContent = (eventInfo) => {
        console.log("🚀 ~ renderEventContent ~ eventInfo:", eventInfo)
        const { title, extendedProps } = eventInfo.event;
        const { name, phone, email, start, end } = extendedProps;

        const dateStr = start;
        const dateEnd = moment(end)
        const date = moment(dateStr);

        const formattedDate = date.format("MMM Do");
        const formattedStartTime = date.format("hh:mma");
        const formattedEndTime = dateEnd.format("hh:mma");

        const popover = (
            <Popover id="popover-basic">
                {/* <Popover.Header as="h3">{title}</Popover.Header> */}
                <Popover.Body>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaRegUser size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{name}</p>
                        </div>
                        <div style={{ display: 'flex', marginLeft: '25px', alignItems: 'center' }}>
                            <GrDocumentText size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{email}</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <FiPhone size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{phone}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginLeft: '25px' }}>
                            <IoTimeOutline size={18} color={Color.PRIMARY} />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }}>{formattedStartTime} - {formattedEndTime}</p>
                        </div>
                    </div>
                </Popover.Body>
            </Popover>
        );

        const getInitials = (name) => {
            if (!name) return '';
            const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
            return initials.slice(0, 2);
        };

        return (
            <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={popover}
                delay={{ show: 200, hide: 200 }} // Add a slight delay for better UX
            >
                <div className="event-content">
                    <p style={{ marginTop: '-2px' }}>{getInitials(title)}</p>
                </div>
            </OverlayTrigger>
        );
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Calendar" />
            <Container fluid={true} className="rubik-font">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="custTableComp">
                                <Row>
                                    <Col sm="12" >
                                        <Link to={"../app/schedule-assistant"}
                                            className='btn btn-primary'
                                            style={{
                                                borderColor: Color.PRIMARY,
                                                color: 'white',
                                                float: "right",
                                                marginTop: "0px",
                                                marginRight: "0px",
                                                marginBottom: "20px"
                                            }} >
                                            Manage Schedule
                                        </Link>
                                    </Col>
                                    <Col sm="3" className="sidebar2">
                                        <H4>Upcoming Events</H4>
                                        {calendarEvents.map((event, index) => {
                                            console.log("🚀 ~ {calendarEvents.map ~ event:", event)

                                            const dateStr = event.start;
                                            const dateEnd = moment(event.end)
                                            const date = moment(dateStr);

                                            const formattedDate = date.format("MMM Do");
                                            const formattedStartTime = date.format("hh:mma");
                                            const formattedEndTime = dateEnd.format("hh:mma");
                                            return (
                                                <div key={index} className="event-card">
                                                    <div style={{}}>
                                                        <h6 style={{ paddingBottom: '0px', marginBottom:'0px' }}>{event?.title}</h6>
                                                        <p>{formatPhoneNumber(event?.extendedProps?.phone)}</p>
                                                    </div>
                                                    <p style={{ fontSize: '12px' }}>{formattedDate} {formattedStartTime} - {formattedEndTime}</p>
                                                </div>
                                            )
                                        })}
                                    </Col>

                                    {/* Calendar */}
                                    <Col sm="9">
                                        <FullCalendar
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            initialView="dayGridMonth"
                                            ref={calendarComponentRef}
                                            events={calendarEvents}
                                            eventContent={renderEventContent}
                                            weekends={true}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
            {/*<button onClick={onConnectAccount1}>  </button>*/}
        </Fragment>

    );
};

export default ScheduleDetail;
