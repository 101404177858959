import {FEMALE_VOICES, GENDER_ARR, LANGUAGES_MAP, MALE_VOICES, SHORT_GENDER} from "./constants";
import {isEmpty} from "./utils";

export const disableLoggers = () => {
    try {
        if (typeof(window.console) != "undefined") {
            window.console = {};
            window.console.log = function () {
            };
            window.console.debug = function () {
            };
            window.console.info = function () {
            };
            window.console.warn = function () {
            };
            window.console.error = function () {
            };
        }

        if (typeof(alert) !== "undefined") {
            alert = function ()
            {

            }
        }

    } catch (ex) {

    }
}

export const getTitleByLangName = (lang_name) => {
    const language = LANGUAGES_MAP.find(language => language.lang_name === lang_name);
    return language ? language.title : '';
}

export const getLangByLangName = (lang_name) => {
    return LANGUAGES_MAP.find(language => language.lang_name === lang_name) || LANGUAGES_MAP[0];
}


export const botVoice = (gender, voice_id) => {
    if (isEmpty(gender) || isEmpty(voice_id)) {
        return;
    }

    if (gender === SHORT_GENDER.MALE) {
        return MALE_VOICES.find(voice => voice.value === voice_id);
    } else if (gender === SHORT_GENDER.FEMALE) {
        return FEMALE_VOICES.find(voice => voice.value === voice_id);
    }
}

export const getGenderByShortName = (shortName)  => {
    if (isEmpty(shortName)) {
        return;
    }

    return GENDER_ARR.find(gender => gender.value === shortName);
}

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const isDev = () => {
    return development;
}