import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import './Stepper.css';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import roboImg from '../assets/images/logo/roboImg.png';
import solarArrow from '../assets/images/logo/solarArrow.png';
import tickCircle from '../assets/images/logo/tickCircle.png';
import phoneIcon from '../assets/images/user/phoneIcon.png';
import { H4, P, Btn } from '../AbstractElements';
import { ToastContainer, toast } from "react-toastify";
import 'react-phone-number-input/style.css';
import { TiTick } from "react-icons/ti";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import parsePhoneNumberFromString, { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import Select from 'react-select'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowLeft } from "react-icons/fa";
import { Modal, Spinner } from 'react-bootstrap';
import { bookNumber, getPhoneNumbersList, getStates } from '../api/Company';
import { debounce } from 'lodash';
import { setUpPhone } from '../api/AuthIndex';
import ApiContext from '../ApiContext';
import '../component2/ManageAssistant/ManageAssistant.css'
import { RxCross2 } from "react-icons/rx";
import { SlLocationPin } from "react-icons/sl";
import { AiOutlineDollar } from "react-icons/ai";
import { Color } from '../helpers/colors';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../helpers/LocalStorageHelper';

const SetupPhone = ({ logoClassMain }) => {
    // const { botData } = useContext(ApiContext);
    const theme = localStorage.getItem("mix_background_layout");
    const userInfo = JSON.parse(localStorage.getItem("register"))
    const companyData = JSON.parse(localStorage.getItem("signup_company"))
    const botData = JSON.parse(localStorage.getItem("bot_Data"))
    const location = useLocation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState('select');
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [accountSID, setAccountSID] = useState()
    const [authToken, setAuthToken] = useState();
    const [provider, setProvider] = useState('Twilio')
    const [phone, setPhone] = useState()
    const [prefixKey, setPrefixKey] = useState('')
    const [tollFree, setTollFree] = useState()
    const [cStates, setCStates] = useState('')
    const [cStatesValue, setCStatesValue] = useState('')
    const [tollLoader, setTollLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [states, setStates] = useState([])
    const [country, setCountry] = useState('US')
    const [twilioNumbersList, setTwilioNumbersList] = useState()
    const [tollButtonLoader, setTollButtonLoader] = useState(false)
    const [bookNumberData, setBookNumberData] = useState();
    const [bookNumberModal, setBookNumberModal] = useState();
    const [bookNumberModalData, setBookNumberModalData] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState('');

    const [phoneError, setPhoneError] = useState('');
    const [accountSIDError, setAccountSIDError] = useState('');
    const [authTokenError, setAuthTokenError] = useState('');
    const [phoneAlreadyTakenError, setPhoneAlreadyTakenError] = useState('');

    useEffect(() => {
        getState('226')
    }, [])

    const getTwilioNumberList = async () => {
        setTollLoader(true)
        try {
            const formData = new FormData();
            formData.append('country', country);
            formData.append('state', cStates ? cStates : '');
            formData.append('page', currentPage);
            formData.append('number_type', tollFree ? 'tollfree' : '');
            formData.append('contains', prefixKey);
            const res = await getPhoneNumbersList(userInfo?.token, formData);
            setTwilioNumbersList(res?.FinalNumbersList);
            setTollLoader(false)
        } catch (error) {
            setTwilioNumbersList([])
            setTollLoader(false)
        }
    };

    const debouncedGetTwilioNumberList = useCallback(
        debounce(getTwilioNumberList, 700),
        [currentPage, cStates, tollFree, prefixKey, selectedOption, country]
    );

    useEffect(() => {
        debouncedGetTwilioNumberList();
        return () => {
            debouncedGetTwilioNumberList.cancel();
        };
    }, [debouncedGetTwilioNumberList]);

    const bookNumber = () => {
        alert('clicked');
    }

    const handleBookNumber = async (data) => {
        setTollButtonLoader(true)
        setPhone(null);

        try {
            // const formData = new FormData()
            // formData.append('company_id', companyData?.id)
            // formData.append('phone_number', data?.phone_number)
            // const res = await bookNumber(userInfo?.token, formData)
            // setBookNumberData(res)
            // setTollButtonLoader(false)
            // setBookNumberModal(false)


            // if (res.status) {
            //     setAccountSID(res.ACCOUNT_SID)
            //     setAuthToken(res.AUTH_TOKEN)
            //     setPhone(res.phone)
            //     setTimeout(() => {
            // handleHaveTwilioNumber()
            //     }, 1500);
            // }

            navigate(`${process.env.PUBLIC_URL}/payment`, { state: { bookedNumberData: data, haveNumber: false } });

        } catch (error) {
            setBookNumberModal(false)
            setTollButtonLoader(false)
        }
    }

    const handleGetNumber = (data) => {
        setBookNumberModal(true)
        setBookNumberModalData(data)
    }


    const handleNext = () => {
        navigate(`${process.env.PUBLIC_URL}/setup-phone`);
    };

    const handleBack = () => {
        removeLocalStorage('countryChange')
        navigate(`${process.env.PUBLIC_URL}/assistant`);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };


    const numberOptions = [
        { value: 'Tw', label: 'Twilio' },
        { value: 'Vo', label: 'Vonage' },
    ];

    const handleNumberOptionChange = (e) => {
        setCStates(e.value)
        setCStatesValue(e.label)
        setProvider('')
    }

    const handleCountryChange = (e) => {
        console.log("🚀 ~ handleCountryChange ~ e:", e)
        setLocalStorage('countryChange', 'Y')
        setCountry(e.value)
        setCStates('');
        setCStatesValue('');
        setStates([]);
        getState(e.id);
    }

    const handleProviderOption = (e) => {
        setProvider(e.label);
    }


    const handleNextPage = () => {
        setCurrentPage(currentPage + 1)
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const getState = async (countryId) => {
        try {
            const res = await getStates(countryId);
            const mappedStates = res.states.map((item) => ({
                value: item.code,
                label: item.name
            }));

            setStates(mappedStates);

            const countryChange = getLocalStorage('countryChange')
            // Check if California exists and set it as the default
            const california = mappedStates.find(state => state.label === 'California');
            if (california && countryChange !== 'Y') {
                setCStatesValue(california.label);
                setCStates(california.value)
            }
        } catch (error) {
            console.log("🚀 ~ getState ~ error:", error);
        }
    };

    const countries = [
        { id: '226', value: 'US', label: 'United States' },
        { id: '38', value: 'CA', label: 'Canada' },
    ]

    const handleHaveTwilioNumber = async () => {
        let hasError = false;

        // Reset error states
        setPhoneError('');
        setAccountSIDError('');
        setAuthTokenError('');

        // Validate inputs
        if (!phone) {
            setPhoneError("Phone Number is required.");
            hasError = true;
        }
        if (provider === "Twilio" && !accountSID) {
            setAccountSIDError("Account SID is required.");
            hasError = true;
        }
        if (provider === "Twilio" && !authToken) {
            setAuthTokenError("AuthToken is required.");
            hasError = true;
        }

        if (hasError) return;

        setLoading(true)
        const providerKeys = `${accountSID},${authToken}`
        try {
            const formData = new FormData()
            formData.append("provider", provider === 'Twilio' ? 'Tw' : provider === 'Vonage' ? 'Vo' : 'Tw')
            formData.append("phone_number", phone)
            formData.append("provider_keys", providerKeys)
            formData.append("bot_name", botData?.bot_name)
            formData.append("company_id", companyData?.id)
            formData.append("bot_id", botData?.id)
            const res = await setUpPhone(userInfo.token, formData)
            if (res.data.original.error) {
                setPhoneAlreadyTakenError("The phone number has already been taken.")
                // toast.error("The phone number has already been taken.", {
                //     position: "top-left",
                //     autoClose: 3000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     className: 'custom-toast-error',
                // });
                setLoading(false)
            } else {
                setLoading(false)
                navigate(`${process.env.PUBLIC_URL}/payment`, { state: { haveNumber: true, } });
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const validatePhoneNumber = (value) => {
        setPhone(value); // Update the phone state
        setError(''); // Clear previous errors

        if (!value) return;

        try {
            const phoneNumber = parsePhoneNumberFromString(value);

            if (phoneNumber) {
                // Validate the number for US or Canada
                if (phoneNumber.country === 'US' || phoneNumber.country === 'CA') {
                    if (isValidPhoneNumber(value)) {
                        setError(''); // No error
                    } else {
                        setError('Invalid phone number.');
                    }

                } else {
                    setError('Only US and Canada numbers are allowed.');
                }
            }
        } catch (error) {
            // Handle possible errors, such as TOO_SHORT
            if (error.message === 'TOO_SHORT') {
                setError('Phone number is too short.');
            } else {
                setError('Invalid phone number.');
            }
        }
    };

    return (
        <>
            <Fragment>
                <div
                    style={{
                        backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        display: 'block',
                        minHeight: '100vh',
                    }}>
                    <Container className='p-0 login-page' fluid={true}>
                        <Row className='m-0'>
                            <Link style={{ marginTop: '1%' }} className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                    <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                    <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                                </div>
                            </Link>
                            <Col className='p-0'>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '2%',
                                    }}>
                                    <Card style={{ width: '1000px', padding: '0px' }}>
                                        <CardBody style={{ padding: '0px' }}>

                                            <div>
                                                <Row>
                                                    <Col sm='12'>
                                                        <div className="stepper-container">
                                                            <div className={`step ${location.pathname === '/setup-phone' || location.pathname === '/step1' ? 'active' : ''}`}>
                                                                <div className="circle"> {location.pathname === '/setup-phone' || location.pathname === '/step1' ? <TiTick size={25} /> : ''}</div>
                                                                <span>Define Business</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/setup-phone' || location.pathname === '/step2' ? 'active' : ''}`}>
                                                                <div className="circle"> {location.pathname === '/setup-phone' || location.pathname === '/step2' ? <TiTick size={25} /> : ''}</div>
                                                                <span>Prepare Assistant</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/setup-phone' || location.pathname === '/step3' ? 'active' : ''}`}>
                                                                <div className="circle">03</div>
                                                                <span>Phone Setup</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/step4' ? 'active' : ''}`}>
                                                                <div className="circle">04</div>
                                                                <span>Payment</span>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                        <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                                                        <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                            {/* <img className='for-light' src={solarArrow} alt='loginpage' style={{ width: '30px', height: '30px', marginTop: '8px', cursor: 'pointer' }} onClick={handleBack} /> */}
                                                            <div onClick={handleBack} style={{ cursor: 'pointer', backgroundColor: Color.SECONDARY, padding: '10px', width: '35px', height: '35px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <FaArrowLeft size={20} color={Color.WHITE} alt='loginpage' />
                                                            </div>
                                                            <div style={{ marginTop: '8px' }}>
                                                                {/* <H4 attrH4={{ style: { fontSize: '18px', marginTop: '5px', color: Color.SECONDARY } }}>Choose a new dedicated number for your AI assistant or enter an existing Twilio phone number.</H4> */}
                                                                <H4 attrH4={{ style: { fontSize: '18px', marginTop: '5px', color: Color.SECONDARY } }}>Phone Setup</H4>
                                                                <div style={{ marginTop: '8px', height: '420px', overflow: 'scroll' }}>
                                                                    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { marginLeft: '10px', fontSize: '16px', fontWeight: '500' } }} >Select a new dedicated number for your AI assistant or enter an existing Twilio phone number.</P>
                                                                    </div> */}
                                                                    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '16px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Guidelines for a New Number:</P>
                                                                    </div> */}
                                                                    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '16px', } }} >Provide all the required information to make an assistant specially designed to deal with clients in your industry.</P>
                                                                    </div> */}
                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '16px', } }} >Choose a new dedicated number for your AI assistant or enter an existing Twilio phone number.</P>
                                                                    </div>
                                                                    <div style={{ marginTop: '15px' }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >New Number Request</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                            <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Select a state and phone prefix. Fluten will match or find a similar prefix. The phone number cost per month is shown below:</P>
                                                                        </div>
                                                                        {/* <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }} >New Number Request: <span style={{ fontWeight: '400' }}>Select a state and phone prefix. Fluten will match or find a similar prefix. The phone number cost per month is shown below:</span></P> */}
                                                                        <ul style={{ listStyleType: 'disc', paddingLeft: '25px' }}>
                                                                            <li style={{ fontSize: '15px', marginLeft: '10px' }}>Regular Phone Number = $1.50/Month</li>
                                                                            <li style={{ fontSize: '15px', marginLeft: '10px' }}>Toll-Free Phone Number = $2.50/Month</li>
                                                                        </ul>
                                                                    </div>
                                                                    {/* <div style={{ marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }} >With this information, your AI assistant can answer customer questions, like:</P>
                                                                    </div> */}
                                                                    <div style={{ marginTop: '15px' }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Transfer Your Existing Twilio Number</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                            <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Move your current Twilio number to Fluten’s platform. To complete onboarding, please provide:</P>
                                                                        </div>
                                                                        {/* <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }} >Transfer Your Existing Twilio Number: <span style={{ fontWeight: '400' }}>Move your current Twilio number to Fluten’s platform. To complete onboarding, please provide:</span></P> */}
                                                                        <ul style={{ listStyleType: 'disc', paddingLeft: '25px' }}>
                                                                            <li style={{ fontSize: '15px', marginLeft: '10px' }}>Account SID</li>
                                                                            <li style={{ fontSize: '15px', marginLeft: '10px' }}>Auth Token</li>
                                                                        </ul>
                                                                    </div>
                                                                    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '16px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Requirements for Twilio Number:</P>
                                                                    </div>
                                                                    <div style={{ marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }} >For Twilio number, you’ll need to provide</P>
                                                                    </div> */}
                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '12px' }}>
                                                                        <P attrPara={{ style: { fontSize: '15px', } }} >You can get this information on your Twilio dashboard.</P>
                                                                    </div>
                                                                    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '14px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Note</P>
                                                                    </div>
                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '12px' }}>
                                                                        <P attrPara={{ style: { fontSize: '14px', } }} >*All the information is encrypted and secured for your safety.</P>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div style={{ position: 'absolute', bottom: '0', left: '2%', width: '100%' }}>
                                                                <img className='for-light' src={roboImg} alt='loginpage' style={{ width: '80%' }} />
                                                                <img className='for-dark' src={roboImg} alt='loginpage' style={{ width: '80%' }} />
                                                            </div> */}
                                                    </Col>
                                                    <Col sm="6">
                                                        <div style={{ padding: '20px 20px 0px 0px' }}>
                                                            <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: step === 2 ? 'auto' : '550px', display: 'flex', flexDirection: 'column' }}>
                                                                <Form className="theme-form login-form" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                                                    <H4 attrH4={{ style: { color: '#FF5757' } }}>Phone Setup</H4>

                                                                    <>
                                                                        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="select"
                                                                                    checked={selectedOption === 'select'}
                                                                                    onChange={handleOptionChange}
                                                                                    className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                    style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                />
                                                                                <span style={{ marginTop: '5px', marginLeft: '5px' }}>I want a New Number</span>
                                                                            </label>
                                                                        </div>
                                                                        <div style={{ marginLeft: '10px' }}>
                                                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="auto"
                                                                                    checked={selectedOption === 'auto'}
                                                                                    onChange={handleOptionChange}
                                                                                    className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                    style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                />
                                                                                <span style={{ marginTop: '5px', marginLeft: '5px' }}>I have Twilio phone number</span>
                                                                            </label>
                                                                        </div>

                                                                        {selectedOption === 'select' && (
                                                                            <Card
                                                                                style={{
                                                                                    height: '300px',
                                                                                    marginTop: '10px',
                                                                                    border: theme === 'dark-only' ? '' : '1px solid #DCDBDB',
                                                                                }}
                                                                            >
                                                                                <Col sm='12'>
                                                                                    <Row style={{ padding: '10px' }}>
                                                                                        <Col md='6'>
                                                                                            <Select
                                                                                                options={countries}
                                                                                                placeholder="Country"
                                                                                                defaultValue={countries[0]}
                                                                                                onChange={handleCountryChange}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col xs="6" sm="6">
                                                                                            <Select
                                                                                                options={states}
                                                                                                placeholder="State"
                                                                                                value={cStatesValue ? { label: cStatesValue, value: cStatesValue } : null}
                                                                                                onChange={handleNumberOptionChange}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                <Col sm='12'>
                                                                                    <Row style={{ padding: '0px 10px' }}>
                                                                                        <Col sm="6">
                                                                                            <Input
                                                                                                type="text"
                                                                                                id="name"
                                                                                                value={prefixKey}
                                                                                                onChange={(e) => setPrefixKey(e.target.value)}
                                                                                                placeholder="Prefix (if Any)"
                                                                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white', padding: '8px 10px' }}
                                                                                            />
                                                                                        </Col>

                                                                                        {/* Tollfree Checkbox */}
                                                                                        <Col sm="6">
                                                                                            <div className="form-check" style={{ display: 'flex', marginTop: '2px' }}>
                                                                                                <Input
                                                                                                    type="checkbox"
                                                                                                    value={tollFree}
                                                                                                    onChange={(e) => setTollFree(e.target.checked)}
                                                                                                    className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                                                                                    style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : '', height: '25px', width: '20px', border: theme === 'dark-only' ? '1px solid #808080' : '1px solid #DEE2E6' }}
                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor="name"
                                                                                                    style={{ color: theme === 'dark-only' ? 'grey' : 'black', marginTop: '8px', marginLeft: '5px', fontSize: '16px' }}
                                                                                                >
                                                                                                    Tollfree
                                                                                                </label>
                                                                                            </div>
                                                                                        </Col>
                                                                                        {/* </div> */}
                                                                                    </Row>
                                                                                </Col>
                                                                                {/* Navigation Buttons */}
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px', marginTop: "-20px" }}>
                                                                                    <FaAngleDoubleLeft
                                                                                        color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={handlePreviousPage}
                                                                                    />
                                                                                    <p style={{ color: theme === 'dark-only' ? 'grey' : 'black', marginTop: '15px' }}>
                                                                                        {twilioNumbersList?.length} Numbers
                                                                                    </p>
                                                                                    <FaAngleDoubleRight
                                                                                        color={theme === 'dark-only' ? 'grey' : 'black'}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={handleNextPage}
                                                                                    />
                                                                                </div>

                                                                                {/* Twilio Numbers List */}
                                                                                <div style={{ overflow: 'scroll' }}>
                                                                                    {tollLoader ? (
                                                                                        <div style={{ height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                            <Spinner
                                                                                                as="span"
                                                                                                animation="grow"
                                                                                                size="sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                                style={{ color: theme === 'dark-only' ? '#F73164' : 'black' }}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        twilioNumbersList?.map((item) => (
                                                                                            <div
                                                                                                key={item.id}
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'space-between',
                                                                                                    margin: '10px',
                                                                                                    paddingLeft: '10px',
                                                                                                    paddingRight: '10px',
                                                                                                    backgroundColor: theme === 'dark-only' ? '#262933' : '#FBFBFB',
                                                                                                    height: '40px',
                                                                                                    borderRadius: '10px',
                                                                                                    border: theme === 'dark-only' ? '1px solid grey' : '1px solid #DCDBDB',
                                                                                                }}
                                                                                            >
                                                                                                <p style={{ color: theme === 'dark-only' ? 'grey' : 'black', marginTop: '15px' }}>
                                                                                                    {item?.friendly_name}
                                                                                                </p>
                                                                                                <div style={{ width: '130px' }}>
                                                                                                    <p style={{ color: theme === 'dark-only' ? 'grey' : 'black', textAlign: 'left', lineHeight: 1 }}>
                                                                                                        {item?.locality === null ? 'N/A' : item?.locality}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            border: '1px solid #F73164',
                                                                                                            paddingLeft: '10px',
                                                                                                            paddingRight: '10px',
                                                                                                            borderRadius: '5px',
                                                                                                            cursor: 'pointer',
                                                                                                            color: '#F73164',
                                                                                                        }}
                                                                                                        onClick={() => handleGetNumber(item)}
                                                                                                    >
                                                                                                        Get Number
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    )}
                                                                                </div>
                                                                            </Card>

                                                                        )}

                                                                        {selectedOption === 'auto' && (
                                                                            <Form style={{ marginTop: '10px' }}>
                                                                                <FormGroup className="row">
                                                                                    <Label style={{ color: theme === 'dark-only' ? 'grey' : 'black' }} className="col-sm-3 col-form-label" htmlFor="inputPhone">Phone</Label>
                                                                                    <Col sm="9">
                                                                                        <div style={{ padding: '5px', border: '1px solid #DEE2E6', borderRadius: '5px' }}>
                                                                                            <PhoneInput
                                                                                                international
                                                                                                countryCallingCodeEditable={false}
                                                                                                defaultCountry="US"
                                                                                                value={phone}
                                                                                                onChange={(e) => {
                                                                                                    setPhone(e)
                                                                                                    setError('')
                                                                                                    setPhoneError('')
                                                                                                    setPhoneAlreadyTakenError('')
                                                                                                }}
                                                                                                className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                                                                countries={['US', 'CA']}
                                                                                                limitMaxLength={12}
                                                                                            />
                                                                                        </div>
                                                                                        {phoneError && <span style={{ color: 'red', fontSize: '0.9em' }}>{phoneError}</span>}
                                                                                        {phoneAlreadyTakenError && <span style={{ color: 'red', fontSize: '0.9em' }}>{phoneAlreadyTakenError}</span>}
                                                                                    </Col>
                                                                                </FormGroup>

                                                                                {provider === "Twilio" && (
                                                                                    <>
                                                                                        <FormGroup className="row">
                                                                                            <Label style={{ color: theme === 'dark-only' ? 'grey' : 'black' }} className="col-sm-3 col-form-label" htmlFor="accountSID">Account SID</Label>
                                                                                            <Col sm="9">
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id="accountSID"
                                                                                                    value={accountSID}
                                                                                                    onChange={(e) => {
                                                                                                        setAccountSID(e.target.value)
                                                                                                        setAccountSIDError('')
                                                                                                    }}
                                                                                                    placeholder="Enter Account SID"
                                                                                                    className='custom-placeholder'
                                                                                                />
                                                                                                {accountSIDError && <span style={{ color: 'red', fontSize: '0.9em' }}>{accountSIDError}</span>}
                                                                                            </Col>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="row">
                                                                                            <Label style={{ color: theme === 'dark-only' ? 'grey' : 'black' }} className="col-sm-3 col-form-label" htmlFor="authToken">AuthToken</Label>
                                                                                            <Col sm="9">
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id="authToken"
                                                                                                    value={authToken}
                                                                                                    onChange={(e) => {
                                                                                                        setAuthToken(e.target.value)
                                                                                                        setAuthTokenError('')
                                                                                                    }}
                                                                                                    placeholder="Enter AuthToken"
                                                                                                    className='custom-placeholder'
                                                                                                />
                                                                                                {authTokenError && <span style={{ color: 'red', fontSize: '0.9em' }}>{authTokenError}</span>}
                                                                                            </Col>
                                                                                        </FormGroup>
                                                                                    </>
                                                                                )}
                                                                            </Form>
                                                                        )}
                                                                    </>

                                                                    <div style={{ marginTop: 'auto', width: '100%' }}>
                                                                        {loading ?
                                                                            <Button color='primary' disabled style={{ width: '95%' }}>
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="grow"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Next...
                                                                            </Button>
                                                                            :
                                                                            <Button color='primary' style={{ width: '95%' }} onClick={handleHaveTwilioNumber}>
                                                                                Next
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                </Form>
                                                            </Card>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <ToastContainer />
                </div>
                <Modal
                    show={bookNumberModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => setBookNumberModal(false)}
                    className='modal-adjust'

                >
                    <Modal.Body style={{}} >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <RxCross2 size={20} cursor={'pointer'} onClick={() => { setBookNumberModal(false) }} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <img src={phoneIcon} style={{ width: '100px', height: '100px', }} />
                                </div>
                                <H4 attrH4={{ style: { textAlign: 'center', marginTop: '10px' } }}>Your Assigned Phone Number</H4>
                                <P attrPara={{ style: { textAlign: 'center', marginTop: '10px' } }}>Your chosen phone number will be linked to your AI Assistant upon purchase.</P>
                                <div style={{ marginLeft: '8px' }}>
                                    <p style={{ fontWeight: '500', fontSize: '22px', textAlign: 'center', marginTop: '10px', color: Color.SECONDARY }}>{bookNumberModalData?.friendly_name}</p>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', justifySelf: 'center' }}>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <SlLocationPin size={20} color={Color.SECONDARY} />
                                            <p style={{ fontWeight: '400', fontSize: '14px', width: '200px', marginLeft: '5px', marginTop: '-5px' }}>{bookNumberModalData?.locality === null ? 'N/A' : bookNumberModalData?.locality}</p>
                                        </div>
                                        <div style={{ display: 'flex', width: '600px', }}>
                                            <AiOutlineDollar size={20} color={Color.SECONDARY} />
                                            <p style={{ fontWeight: '400', fontSize: '14px', marginLeft: '5px', marginTop: '-3px' }}>{tollFree ? 'Phone Number Fee: $2.50/Month' : 'Phone Number Fee: $1.50/Month'}</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {tollButtonLoader ?
                                            <P attrPara={{ style: { color: Color.PRIMARY, textDecorationLine: 'underline', fontWeight: '500', textAlign: 'center', fontSize: '18px' } }}>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Booking...</P>
                                            :
                                            // <Button style={{ marginTop: '-2px', marginLeft: '-1px' }} type="submit" color="primary" onClick={() => handleBookNumber(bookNumberModalData)}>Book Number</Button>
                                            // <Button color="primary"  onclick={() => handleBookNumber(bookNumberModalData)}> Book Number</Button>

                                            <Button color="link" style={{ color: Color.PRIMARY, textDecorationLine: 'underline', fontWeight: '500', textAlign: 'center', fontSize: '18px' }} onClick={() => handleBookNumber(bookNumberModalData)}>Book Number</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </Fragment>
        </>
    );
};

export default SetupPhone;
