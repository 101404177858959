import {call, put, select, takeEvery} from 'redux-saga/effects';
import {ActionTypes} from "../../../redux-store/ActionTypes";
import {TOAST_TYPES} from "../../../../helpers/constants";
import {showToast} from "../../../../helpers/ToastHelper";
import {operationSettingsApi} from "./operationSettingsApi";
import {getOperationalSettingsSuccess, startGettingOperationalSettings} from "./operationSettingsSlice";
import {selectGetOperationSettingsError, selectLoadingOperationSettings} from "./operationSettingsSelectors";

function* getOperationSettingsSaga(action) {
    try {
        const isLoading = yield select(selectLoadingOperationSettings);
        if (isLoading) return;
        yield put(startGettingOperationalSettings());
        const data = yield call(operationSettingsApi, action.payload);
        yield put(getOperationalSettingsSuccess(data));
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(selectGetOperationSettingsError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* getOperationSettings() {
    yield takeEvery(ActionTypes.OPERATIONAL_SETTINGS_BY_COMPANY, getOperationSettingsSaga);
}

