import { put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../redux-store/ActionTypes";
import {setSelectedPrecastBot} from "./precastBotSlice";

function* setSelectedPrecastBotSaga(action) {
    yield put(setSelectedPrecastBot(action.payload));
}


export function* selectPrecastBot() {
    yield takeLatest(ActionTypes.SET_SELECTED_PRECAST_BOT, setSelectedPrecastBotSaga);
}


