import authReducer from "../modules/auth/authSlice";
import companySlice from "../modules/company/companySlice";
import packageSlice from "../modules/packages/packageSlice";
import stripeSlice from "../modules/stripe/stripeSlice";
import assistantSlice from "../modules/assistant/assistantSlice";
import botVoiceSlice from "../modules/precastBots/botVoice/botVoiceSlice";
import updateAssistantSlice from "../modules/assistant/update/updateAssistantSlice";
import deleteAssistantSlice from "../modules/assistant/delete/deleteAssistantSlice";
import defaultBotsSlice from "../modules/precastBots/getDefaultBots/defaultBotsSlice";
import precastBotSlice from "../modules/precastBots/precastBotSlice";
import createAssistantFormSlice from "../modules/assistant/create/form/createAssistantFormSlice";
import callLogSlice from "../modules/callLog/callLogSlice";
import keywordsAnalysisSlice from "../modules/keywordsAnalysis/keywordsAnalysisSlice";
import stripeSessionSlice from "../modules/stripe/customerPortal/stripeSessionSlice";
import operationSettingsSlice from "../modules/settings/operationSettings/operationSettingsSlice";


const operationSettings = {
    operationSettings: operationSettingsSlice
}

export const reducers = {
    auth: authReducer,
    company: companySlice,
    package: packageSlice,
    stripe: stripeSlice,
    assistant: assistantSlice,
    getBotVoiceModel: botVoiceSlice,
    updateAssistant: updateAssistantSlice,
    deleteAssistant: deleteAssistantSlice,
    getDefaultBots: defaultBotsSlice,
    precastBot: precastBotSlice,
    createAssistantForm: createAssistantFormSlice,
    callLog: callLogSlice,
    keywordsAnalysis: keywordsAnalysisSlice,
    stripeSession: stripeSessionSlice,
    ...operationSettings
};