import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useLocation } from 'react-router';

const LanguageSelect = ({ languageMap = {}, selectedLanguage, onLanguageChange }) => {
    const theme = localStorage.getItem("mix_background_layout");
    const [langdropdown, setLangdropdown] = useState(false);
    const [english, setEnglish] = useState('English');
    const { i18n } = useTranslation();
    const location = useLocation();

    const changeLanguage = (lng) => {
        onLanguageChange(lng);
        setLangdropdown(false); // Close the dropdown after selection
    };

    const toggleDropdown = () => {
        setLangdropdown(!langdropdown);
    };

    const isValidLanguageMap = languageMap && typeof languageMap === 'object';

    // const selectedLanguageName = isValidLanguageMap ? Object.keys(languageMap).find(
    //     key => languageMap[key] === selectedLanguage
    // ) : '';

    // const flagCode = (selectedLanguage || '').split('-')[1] || selectedLanguage;

    // English display and dropdown logic for "/business"
    if (location.pathname === "/business") {
        return (
            <div className="language-select">
                <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`} style={{ border: '1px solid lightgrey', borderRadius: '4px', padding: '8px', position: 'relative' }}>
                    <div className="current_lang" onClick={toggleDropdown} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                        <div className="lang">
                            <i className="flag-icon flag-icon-gb"></i> {/* English Flag */}
                            <span className="lang-txt" style={{ marginLeft: '8px', textTransform: 'capitalize', color: theme === 'dark-only' ? 'grey' : 'black' }}>English</span>
                        </div>
                        {langdropdown ? <FaChevronUp color={theme === 'dark-only' ? 'grey' : 'black'} /> : <FaChevronDown color={theme === 'dark-only' ? 'grey' : 'black'} />}
                    </div>
                    {/* Toggle dropdown for English */}
                    {langdropdown && (
                        <div className="more_lang active" style={{ position: 'absolute', top: '100%', left: '0', zIndex: 9999, background: 'white', border: '1px solid lightgrey', borderRadius: '4px', marginTop: '8px', width: '100%' }}>
                            <div className="lang" onClick={() => changeLanguage(english)} style={{ padding: '10px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <i className="flag-icon flag-icon-gb"></i> {/* English Flag */}
                                <span className="lang-txt" style={{ marginLeft: '8px' }}>English</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    // Standard behavior when not on "/business"
    return (
        <div className="language-select">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`} style={{ border: '1px solid lightgrey', borderRadius: '4px', padding: '8px', position: 'relative' }}>
                <div className="current_lang" onClick={toggleDropdown} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                    <div className="lang">
                        {selectedLanguage?.flag_name && <i className={`flag-icon flag-icon-${selectedLanguage?.flag_name}`}></i>}
                        <span className="lang-txt" style={{ marginLeft: '8px', textTransform: 'capitalize', color: theme === 'dark-only' ? 'grey' : 'black' }}>{selectedLanguage?.title || 'Select Language'}</span>
                    </div>
                    {langdropdown ? <FaChevronUp color={theme === 'dark-only' ? 'grey' : 'black'} /> : <FaChevronDown color={theme === 'dark-only' ? 'grey' : 'black'} />}
                </div>
                {langdropdown && isValidLanguageMap && (
                    <div className="more_lang active" style={{ position: 'absolute', top: '100%', left: '0', zIndex: 9999, background: 'white', border: '1px solid lightgrey', borderRadius: '4px', marginTop: '8px', width: '100%' }}>
                        {languageMap.map((lang, i) => (
                            <div key={i++} className="lang" onClick={() => changeLanguage(lang)} style={{ padding: '10px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <i className={`flag-icon flag-icon-${lang.flag_name || lang.lang_name}`}></i>
                                <span className="lang-txt" style={{ marginLeft: '8px' }}>{lang.title}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LanguageSelect;
