import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import { Breadcrumbs, H5, Alerts, P, H4, H6 } from "../../AbstractElements";
import GreetingCard from "./GreetingCard";
import {
  getAllCompanyData,
  getAllCompanyCustomers,
  getWeeksData,
  getDashboard,
} from "../../api/AuthIndex";
import totalMembers from "../../assets/images/user/totalMembers.png";
import cusImg from "../../assets/images/user/cusImg.png";
import transfer from "../../assets/images/user/transfer.png";
import transImg from "../../assets/images/user/transImg.png";
import transPer from "../../assets/images/user/transPer.png";
import tickSuc from "../../assets/images/user/tickSuc.png";
import { TourProvider, useTour } from "@reactour/tour";
import inboundCalls from "../../assets/images/user/inboundCalls.png";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import inBoundImg from "../../assets/images/user/inBoundImg.png";
import inBoundPer from "../../assets/images/user/inBoundPer.png";
import busyIconImg from "../../assets/images/user/busyIconImg.png";
import failIcon from "../../assets/images/user/failIcon.png";
import alertBot from "../../assets/images/user/alertBot.png";
import busyCir from "../../assets/images/user/busyCir.png";
import failedCir from "../../assets/images/user/failedCir.png";
import noAnsCir from "../../assets/images/user/noAnsCir.png";
import suc from "../../assets/images/user/suc.png";
import leftImg from "../../assets/images/logo/leftImg.jpeg";
import logoImg from "../../assets/images/logo/logoImg.png";
import moment from "moment";
import "../ManageAssistant/ManageAssistant.css";
import BasicAreaChartClass from "./BasicAreaChartClass";
import { useLocation, useNavigate } from "react-router";
import { getCompanyCallStats, getCompanyDetails } from "../../api/Company";
import { getChildCallsStatusSummary } from "../../api/AuthIndex";
import WeeklyCallsData from "./WeeklyCallsData";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import StripePayment from "../Stripe/StripePayment";
import { Bell, AlertCircle } from "react-feather";
import ApiContext from "../../ApiContext";
import ReactApexChart from "react-apexcharts";
import "./Dashboard.css";
import { RxCross2 } from "react-icons/rx";
import Widgets1 from "../../Components/Common/CommonWidgets/Widgets1";
import { WidgetsData } from "../../Data/DefaultDashboard";
import {
  createCookie,
  eraseCookie,
  readCookie,
} from "../../helpers/CookieHelper";
import { isEmpty } from "../../helpers/utils";
import { Color } from "../../helpers/colors";
import { useSelector } from "react-redux";
import { selectCompleteCompany } from "../../Store/modules/company/companySelectors";
import { debounceTime } from "../../helpers/constants";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { FaRegQuestionCircle } from "react-icons/fa";
import ActivityCard from "../../Components/Dashboard/Default/ActivityCard";
import UpcomingMeetings from "./UpcomingMeetings";
import DropdownCommon from "../../Components/Common/Dropdown";
import { DailyDropdown } from "../../Constant";

const DashboardContent = ({ mainClass }) => {
  const { companyDetail } = useContext(ApiContext);
  let getCompanyInfo = companyDetail
  ? JSON.parse(companyDetail)
  : JSON.parse(localStorage.getItem("companyData"));
  console.log("🚀 ~ getCompanyInfo: Dashboard", getCompanyInfo)
  let token = localStorage?.getItem("token");
  const theme = localStorage.getItem("mix_background_layout");

  const isInitialRender = useRef(true);

  const navigate = useNavigate();
  const completeCompany = useSelector(selectCompleteCompany);
  console.log("🚀 ~ completeCompany:", completeCompany)

  const location = useLocation();
  console.log("🚀 ~ location:", location);

  const pathName = location.pathname;

  const [completedData, setCompletedData] = useState();
  console.log("🚀 ~ completedData:", completedData);
  const [topFiveCalls, setTopFiveCalls] = useState();
  const [modal, setModal] = useState(false);
  const [stripeModal, setStripeModal] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [customerList, setCustomerLIst] = useState();
  console.log("🚀 ~ customerList:", customerList);
  const [totalCustomer, setTotalCustomer] = useState();
  const audioRef = useRef(null);
  const [completeCompanyData, setCompleteCompanyData] = useState();
  const [staffData, setStaffData] = useState();
  const [receivedCallLength, setReceivedCallLength] = useState();
  const [forwardedCallLength, setForwardedCallLength] = useState();
  const [companyData, setCompanyData] = useState();
  const [callsStatsData, setCallsStatsData] = useState();
  const startOfWeek = moment()
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfWeek = moment()
    .endOf("month")
    .format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState({
    startDate: startOfWeek,
    endDate: endOfWeek,
  });
  const [chartData, setChartData] = useState();

  const [pieChartData, setPieChartData] = useState({
    series: [33, 33, 34],
    options: {
      chart: {
        type: "donut",
        width: 400,
        height: 400,
      },
      labels: ["Successful", "Busy", "Failed"], // Legend labels
      colors: ["#62BD5B", "#FF0000", "#7E0000"], // Custom colors for each section
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "",
                fontSize: "16px",
                color: "#263238",
                formatter: function() {
                  return ""; // Center text value (total)
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return Number(val).toFixed(0) + "%"; // Percentage outside slices
        },
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["#fff"], // White text on colored background
        },
      },
      legend: {
        show: true,
        position: "bottom",
        labels: {
          colors: "#000",
          useSeriesColors: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [completedCalls, setCompletedCalls] = useState(0);
  const [completedCallsPer, setCompletedCallsPer] = useState(0);
  const [noAnswerCalls, setNoAnswerCalls] = useState(0);
  const [noAnswerCallsPer, setNoAnswerCallsPer] = useState(0);
  const [inProgressCalls, setInProgressCalls] = useState(0);
  const [inProgressCallsper, setInProgressCallsPer] = useState(0);
  const [failedCalls, setFailedCalls] = useState(0);
  const [failedCallsPer, setFailedCallsPer] = useState(0);
  const [highestCalls, setHighestCalls] = useState(0);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [helpModal, setHelpModal] = useState(
    isEmpty(readCookie("showWelcomeDialog"))
  );
  const [selectedData, setSelectedData] = useState("Today");

  const [departmentWiseSummary, setDepartmentWiseSummary] = useState();

  const [Open, setOpen] = useState(true);
  const Toggle = () => setOpen(!Open);

  const [series, setSeries] = useState([70]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [""],
  });

  useEffect(() => {
    // handleGetAllCompany();
    // getWeeksDataInfo(token);
    if (pathName === "/app/dashboard") {
      if (isInitialRender.current) {
        isInitialRender.current = false;

        const delayDebounceFn = setTimeout(() => {
          getDashboardData("today");
        }, debounceTime);

        return () => clearTimeout(delayDebounceFn);
      }
      // const delayDebounceFn = setTimeout(() => {
      // getDashboardData("today");
      // }, debounceTime);

      // return () => clearTimeout(delayDebounceFn);

      // handleCompanyCustomers();
      // getCompanyArray();
      if (getCompanyInfo?.id) {
        // childSummry();
        // getCompanyStats()
      }
      // getCompanyStats("last-week");

      const companyByUser = completeCompany;

      setCompleteCompanyData(companyByUser);

      setCompanyData(companyByUser?.company?.company);

      const assistants = Array.isArray(companyByUser)
        ? companyByUser.reduce((acc, company) => {
            if (company?.assistant) {
              return acc.concat(company?.assistant);
            }
            return acc;
          }, [])
        : []; // Fallback to an empty array if companyByUser is not an array

      localStorage.setItem("companyStatus", JSON.stringify(companyByUser));

      setStaffData(assistants);

      let forwardedCallCount = 0;
      let totalCallsCount = 0;

      assistants.forEach((assistant) => {
        if (assistant.calls && Array.isArray(assistant.calls)) {
          assistant.calls.forEach((call) => {
            if (call.end_reason) {
              forwardedCallCount++;
            }
            if (call.total_calls) {
              totalCallsCount += call.total_calls;
            }
          });
        }
      });

      setForwardedCallLength(forwardedCallCount);
      setReceivedCallLength(totalCallsCount);
    }
  }, []);

  // useEffect(() => {}, []);

  // useEffect(() => {
  //     var timer = setTimeout(() => {
  //         setIsOpen(true);
  //     }, 2000);
  //     return () => {
  //         clearTimeout(timer);
  //     };
  // }, []);

  const convertData = (res2) => {
    const records = Object.values(res2.records).flat(2);
    return { records };
  };

  const childSummry = async () => {
    try {
      const formData = new FormData();
      formData.append("company_id", getCompanyInfo?.id);
      formData.append("start_date", dateRange.startDate);
      formData.append("end_date", dateRange.endDate);
      let res2 = await getChildCallsStatusSummary(token, formData);
      let res = convertData(res2);
      const data1 = res?.records?.map((item) => item.keyword);
      const data2 = res?.records?.map((item) => item.frequency);
      const filteredRecords = res?.records?.filter(
        (record) => record.status === "no-answer"
      );
      let sumTotalCalls = 0;
      let sumThisStatus = 0;
      filteredRecords?.forEach((item) => {
        sumTotalCalls += item.total_calls;
        sumThisStatus += item.this_status;
      });
      let percentage = (sumThisStatus / sumTotalCalls) * 100;
      percentage = isNaN(percentage) ? 0 : percentage;
      setSeries([percentage]);
      // setChartData(filteredRecords);
    } catch (error) {
      console.log("🚀 ~ childSummry ~ error:", error);
    }
  };

  const handleModal = (data) => {
    // console.log("🚀 ~ handleModal ~ data:", data)
    setUserInfo(data);
    setModal(true);
  };

  const getCompanyArray = async () => {
    let res = await getCompanyDetails(getCompanyInfo?.id, token);
    console.log("🚀 ~ getCompanyArray ~ res:", res);
    setCompanyData(res?.company?.company);
    localStorage.setItem("companyData", JSON.stringify(res?.company?.company));
  };

  // const getCompanyStats = async () => {
  //   const formData = new FormData();
  //   formData.append("company_id", getCompanyInfo?.id);
  //   formData.append("start_date", moment().subtract(1, 'month').format('YYYY-MM-DD'));
  //   formData.append("end_date", moment().format('YYYY-MM-DD'));

  //   let res = await getCompanyCallStats(token, formData);
  //   console.log("🚀 ~ getCompanyStats ~ res:", res);

  //   const departmentWiseSummary = res?.records?.department_wide_failed_summary || [];
  //   const filteredDepartmentWiseSummary = departmentWiseSummary.filter(item => item.status === 'no-answer');
  //   setDepartmentWiseSummary(filteredDepartmentWiseSummary);

  //   const statusCounts = res?.records?.company_wide_status_count || [];

  //   const highestCallsHours = res?.records?.highest_calls_hours[0]
  //   console.log("🚀 ~ getCompanyStats ~ highestCallsHours:", highestCallsHours)

  //   setHighestCalls(highestCallsHours)

  //   statusCounts.forEach(item => {
  //     switch (item.status) {
  //       case 'completed':
  //         setCompletedCalls(item.total_calls);
  //         setCompletedCallsPer(item.percentage_calls);
  //         break;
  //       case 'no-answer':
  //         setNoAnswerCalls(item.total_calls);
  //         setNoAnswerCallsPer(item.percentage_calls);
  //         break;
  //       case 'in-progress':
  //         setInProgressCalls(item.total_calls);
  //         setInProgressCallsPer(item.percentage_calls);
  //         break;
  //       case 'failed':
  //         setFailedCalls(item.total_calls);
  //         setFailedCallsPer(item.percentage_calls);
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  // };

  const getCompanyStats = async (selectedValue) => {
    setLoading(true);
    setCompletedCalls(0);
    const formData = new FormData();
    const currentDate = moment().format("YYYY-MM-DD");
    let startDate;

    switch (selectedValue) {
      case "today":
        startDate = currentDate;
        break;
      case "last-week":
        startDate = moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        break;
      case "last-month":
        startDate = moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        break;
      case "last-3-months":
        startDate = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD");
        break;
      case "last-year":
        startDate = moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        break;
      default:
        return;
    }

    formData.append("company_id", getCompanyInfo?.id);
    formData.append("start_date", startDate);
    formData.append("end_date", currentDate);

    let res = await getCompanyCallStats(token, formData);

    setCompletedCalls(0);
    setCompletedCallsPer(0);
    setNoAnswerCalls(0);
    setNoAnswerCallsPer(0);
    setInProgressCalls(0);
    setInProgressCallsPer(0);
    setFailedCalls(0);
    setFailedCallsPer(0);

    const departmentWiseSummary =
      res?.records?.department_wide_failed_summary || [];
    const filteredDepartmentWiseSummary = departmentWiseSummary.filter(
      (item) => item.status === "no-answer"
    );
    setDepartmentWiseSummary(filteredDepartmentWiseSummary);

    const statusCounts = res?.records?.company_wide_status_count || [];

    const highestCallsHours = res?.records?.highest_calls_hours[0];

    setHighestCalls(highestCallsHours);

    setLoading(false);

    statusCounts.forEach((item) => {
      console.log("Total Calls", item);
      switch (item.status) {
        case "completed":
          setCompletedCalls(item.total_calls);
          setCompletedCallsPer(item.percentage_calls);
          break;
        case "no-answer":
          setNoAnswerCalls(item.total_calls);
          setNoAnswerCallsPer(item.percentage_calls);
          break;
        case "in-progress":
          setInProgressCalls(item.total_calls);
          setInProgressCallsPer(item.percentage_calls);
          break;
        case "failed":
          setFailedCalls(item.total_calls);
          setFailedCallsPer(item.percentage_calls);
          break;
        default:
          break;
      }
    });
  };

  const getWeeksDataInfo = async (token) => {
    const formData = new FormData();
    formData.append("company_id", getCompanyInfo?.id);
    // formData.append("company_id", 21)
    let res = await getWeeksData(token, formData);
    setTopFiveCalls(res.TopFiveCalls);
    // console.log("🚀 ~ getWeeksDataInfo ~ res:", res)
  };

  const getDashboardData = async (selectedValue) => {
    const formData = new FormData();
    const currentDate = moment().format("YYYY-MM-DD");
    let startDate;
    switch (selectedValue) {
      case "today":
        startDate = currentDate;
        break;
      case "last-week":
        startDate = moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        break;
      case "last-month":
        startDate = moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        break;
      case "last-3-months":
        startDate = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD");
        break;
      case "last-year":
        startDate = moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        break;
      default:
        return;
    }

    formData.append("company_id", getCompanyInfo?.id);
    formData.append("start_date", startDate);
    formData.append("end_date", currentDate);

    try {
      let res = await getDashboard(token, formData);

      let completedPercentage = parseFloat(
        Number(
          res[0].transferCallComplted.transfer_call_completed_persantage
        ).toFixed(2)
      );
      let busyPercentage = parseFloat(
        Number(res[0].transferCallsBusy.transfer_call_busy_persantage).toFixed(
          2
        )
      );
      let failedPercentage = parseFloat(
        Number(
          res[0].transferCallsFaild.transfer_call_Faild_persantage
        ).toFixed(2)
      );

      // if (
      //   completedPercentage === 0 &&
      //   busyPercentage === 0 &&
      //   failedPercentage === 0
      // ) {
      //   // Set default values when all are zero
      //   completedPercentage = 33.3;
      //   busyPercentage = 33.3;
      //   failedPercentage = 33.3;
      // }

      const newSeries = [completedPercentage, busyPercentage, failedPercentage];

      setPieChartData((prevData) => ({
        ...prevData,
        series: newSeries,
      }));

      setCompletedData(res[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const handleCompanyCustomers = async () => {
    try {
      const formData = new FormData();
      formData.append("company_id", getCompanyInfo?.id);

      const res = await getAllCompanyCustomers(token, formData);
      // console.log("🚀 ~ handleCompanyCustomers ~ res:", res);

      setTotalCustomer(res.customer);

      // Assuming res.customer is an array
      if (Array.isArray(res.customer)) {
        // Sort customers if necessary, e.g., by some 'score' property
        const sortedCustomers = res.customer.sort((a, b) => b.score - a.score);

        // Get the top 5 customers
        const topCustomers = sortedCustomers.slice(0, 5);

        setCustomerLIst(topCustomers);
      } else {
        // console.error("Expected res.customer to be an array");
      }
    } catch (error) {
      // console.error("Error handling company customers:", error);
    }
  };

  const handleAudioEnded = () => {
    setCurrentAudio("ended");
  };

  const handleCloseModal = () => {
    setModal(false);
    handleAudioEnded();
  };
  const handleCloseStripeModal = () => {
    setStripeModal(false);
  };

  const getRandomColor = () => {
    const colors = [
      "bg-color-1",
      "bg-color-2",
      "bg-color-3",
      "bg-color-4",
      "bg-color-5",
    ];
    return colors[Math.floor(Math.random() * colors?.length)];
  };

  const getInitials = (name) => {
    if (!name) return "";
    const initials = name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    return initials.slice(0, 2);
  };

  // const handleGetAllCompany = async () => {
  //   const formData = new FormData();
  //   formData.append("company_id", getCompanyInfo?.id);

  //   try {
  //     const res = await getAllCompanyData(token, formData);

  //     // const completeCompanyData = res.company.map((item) => item?.company);
  //     const completeCompanyData = res.company.company;

  //     setCompleteCompanyData(completeCompanyData);

  //     const assistants = completeCompanyData.reduce((acc, company) => {
  //       if (company.assistant) {
  //         return acc.concat(company.assistant);
  //       }
  //       return acc;
  //     }, []);

  //     localStorage.setItem(
  //       "companyStatus",
  //       JSON.stringify(completeCompanyData)
  //     );

  //     setStaffData(assistants);

  //     let forwardedCallCount = 0;
  //     let totalCallsCount = 0;

  //     assistants.forEach((assistant) => {
  //       if (assistant.calls && Array.isArray(assistant.calls)) {
  //         assistant.calls.forEach((call) => {
  //           if (call.end_reason) {
  //             forwardedCallCount++;
  //           }
  //           if (call.total_calls) {
  //             totalCallsCount += call.total_calls;
  //           }
  //         });
  //       }
  //     });

  //     setForwardedCallLength(forwardedCallCount);
  //     setReceivedCallLength(totalCallsCount);
  //     setLoading(false);
  //   } catch (error) {
  //     // console.error("Error fetching company data:", error);
  //     setLoading(false);
  //   }
  // };

  const handleActionSelect = (row, action) => {
    if (action === "Edit") {
      handleEdit(row);
    } else if (action === "View Detail") {
      navigate("/app/manage-assistants");
    }
  };

  const handleEdit = (data) => {
    // console.log("Edit", data);
  };

  const handleViewDetail = (data) => {
    // console.log("View Detail", data);
  };

  const select = [
    { value: "today", label: "Today" },
    { value: "last-week", label: "Last Week" },
    { value: "last-month", label: "Last Month" },
    { value: "last-3-months", label: "Last 3 Months" },
    { value: "last-year", label: "Last Year" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(89, 120, 212, 0.2)",
      color: "#5978d4",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
      minWidth: "120px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#5978d4",
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected && "#5978d4",
      // color: 'white',
      "&:hover": {
        backgroundColor: "rgba(89, 120, 212, 0.2)",
      },
      fontSize: "11px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#5978d4",
      "&:hover": {
        color: "#5978d4",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const handleHelpModalShow = () => setHelpModal(true);
  const handleHelpModalClose = () => setHelpModal(false);

  const handleUpdateAccount = () => {
    setHelpModal(false);
    navigate(`${process.env.PUBLIC_URL}/app/settings`, {
      state: { tab: "subscription" },
    });
  };
  const { setIsOpen } = useTour();

  const handleStartTour = () => {
    setIsOpen(true);
    setHelpModal(false);
  };
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  return (
    <Fragment>
      <Breadcrumbs mainTitle={`Dashboard - ${selectedData} Summary`} />
      <Container fluid={true}>
        {loading ? (
          // Skeleton for the entire dashboard
          <Row>
            <Row>
              <Col md={8}>
                <Row>
                  <Col xs="12" sm="12" md="4">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} style={{}} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="12" sm="12" md="4">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="12" sm="12" md="4">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <H5></H5>
                <Row sm={"9"}>
                  <Col xs="3">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="3">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="3">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xs="3">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={3} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Col md={12}>
                  <Col sm="12">
                    <Card>
                      <CardBody style={{ padding: "10px" }}>
                        <SkeletonTheme
                          baseColor={theme == "dark-only" ? "#202020" : ""}
                          highlightColor={theme == "dark-only" ? "#444" : ""}
                        >
                          <Skeleton height={10} count={9} />
                        </SkeletonTheme>
                      </CardBody>
                    </Card>
                  </Col>
                </Col>
              </Col>
              <H5></H5>
              <div>
                <Col
                  xs="12"
                  style={{
                    marginBottom: "5%",
                    height: "100px",
                    borderRadius: "5px",
                  }}
                >
                  <Card>
                    <CardBody style={{ padding: "10px" }}>
                      <SkeletonTheme
                        baseColor={theme == "dark-only" ? "#202020" : ""}
                        highlightColor={theme == "dark-only" ? "#444" : ""}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Skeleton
                            height={50}
                            style={{ width: "50px", borderRadius: "50px" }}
                          />
                          <Skeleton
                            height={10}
                            count={5}
                            style={{ width: "70vw", marginLeft: "10px" }}
                          />
                        </div>
                      </SkeletonTheme>
                    </CardBody>
                  </Card>
                </Col>
              </div>

              <Col sm="6">
                <Card>
                  <CardBody style={{ padding: "10px" }}>
                    <SkeletonTheme
                      baseColor={theme == "dark-only" ? "#202020" : ""}
                      highlightColor={theme == "dark-only" ? "#444" : ""}
                    >
                      <Skeleton height={10} count={18} />
                    </SkeletonTheme>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card>
                  <CardBody style={{ padding: "10px" }}>
                    <SkeletonTheme
                      baseColor={theme == "dark-only" ? "#202020" : ""}
                      highlightColor={theme == "dark-only" ? "#444" : ""}
                    >
                      <Skeleton height={10} count={18} />
                    </SkeletonTheme>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <H5></H5>
            <Col sm="12" md="12">
              <Card>
                <CardBody style={{ padding: "10px" }}>
                  <SkeletonTheme
                    baseColor={theme == "dark-only" ? "#202020" : ""}
                    highlightColor={theme == "dark-only" ? "#444" : ""}
                  >
                    <Skeleton count={8} />
                  </SkeletonTheme>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Fragment>
            <Row style={{ marginTop: "-30px", marginBottom: "10px" }}>
              <Col sm="12">
                {/* <Alerts attrAlert={{
                  className: 'alert-dismissible', color: 'primary dark',
                  isOpen: Open,
                  target: 'Alert-1',
                }}
                  closeBtn={false}
                  attrBtn={{ className: 'btn-close', color: 'default', id: 'Alert-1', onClick: Toggle }}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ width: '45px', height: '45px', borderRadius: '10px', marginRight: '10px' }} src={alertBot} />
                    <div>
                      <P>Important Notice:</P>
                      <P>Congratulations, you have successfully made your company.</P>
                    </div>
                  </div>
                </Alerts> */}
                {completeCompany?.company?.company?.latest_package_payment?.current_balance < 5 && completeCompany?.company?.activeSubscription === 'payg' && (
                  <Alerts
                    attrAlert={{
                      className: "alert-dismissible",
                      color: "warning dark",
                      isOpen: Open,
                      target: "Alert-3",
                    }}
                    closeBtn={false}
                    attrBtn={{
                      className: "btn-close",
                      color: "default",
                      id: "Alert-3",
                      onClick: Toggle,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {/* <Bell style={{ marginRight: '10px' }} size={45} /> */}
                      <Bell
                        size={45}
                        color={"white"}
                        style={{ marginRight: "10px" }}
                      />
                      <div>
                        <P>Important Notice:</P>
                        <P>
                          One of your company's accounts has a low balance.
                          Update your balance now to avoid call-receiving
                          disruptions. Click{" "}
                          <strong
                            style={{
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }}
                            onClick={() =>
                              navigate(`${process.env.PUBLIC_URL}/app/billing`)
                            }
                          >
                            here
                          </strong>{" "}
                          to update.
                        </P>
                      </div>
                    </div>
                  </Alerts>
                )}

                {/* <Alerts attrAlert={{
                  className: 'alert-dismissible', color: 'secondary dark',
                  isOpen: Open,
                  target: 'Alert-2',
                }}
                  closeBtn={false}
                  attrBtn={{ className: 'btn-close', color: 'default', id: 'Alert-2', onClick: Toggle }}>
                  <div style={{ display: 'flex' }}>
                    <AlertCircle style={{ marginRight: '10px' }} size={45} />
                    <div>
                      <P>Important Notice:</P>
                      <P>One of your company is not active due to payments. Click <strong style={{ cursor: 'pointer', textDecorationLine: 'underline' }}>here</strong> to update.</P>
                    </div>
                  </div>
                </Alerts> */}

                {/* <div style={{ backgroundColor: 'rgba(255,51,100,0.19)', padding: '10px 20px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                  <IoWarningOutline size={45} color={'#F61212'} />
                  <div style={{ marginTop: '15px', marginLeft: '20px' }}>
                    <p style={{ lineHeight: 0.5, fontWeight: 'bold', fontSize: '16px' }}>Important Notice: Low Balance Alert</p>
                    <p style={{ lineHeight: 0.5 }}>One of your company's accounts has a low balance. Update your balance now to avoid call-receiving disruptions. Click <strong style={{ cursor: 'pointer', textDecorationLine: 'underline' }} onClick={() => setStripeModal(true)}>here</strong> to update.</p>
                  </div>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col sm="12" style={{}}>
                {getCompanyInfo === null && (
                  <Col sm="12" md="12">
                    <GreetingCard />
                  </Col>
                )}

                {getCompanyInfo !== null && (
                  <Row>
                    <Col md={8} className={"dashboard-step1"}>
                      <Row>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <H5>Overall Summary</H5>
                          {/* <i className='icofont icofont-emo-heart-eyes font-danger f-20'></i>
                        <i className='icofont icofont-emo-nerd-smile font-danger f-20'></i> */}
                          <Select
                            options={select}
                            styles={customStyles}
                            onChange={(e) => {
                              console.log("Selected Value", e.label);
                              setSelectedData(e.label);
                              // getCompanyStats(e.value);
                              getDashboardData(e.value);
                            }}
                            defaultValue={select.find(
                              (option) => option.value === "today"
                            )}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "25px",
                            marginBottom: "15px",
                          }}
                        >
                          {/* <div
                            style={{
                              padding: "10px 15px",
                              backgroundColor:
                                theme === "dark-only"
                                  ? "transparent"
                                  : "#EBF1FF",
                              borderRadius: "50px",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Total Calls:{" "}
                            </span>
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {completedData?.total_calls}
                            </span>
                          </div> */}
                          {/* <div
                            style={{
                              padding: "10px 15px",
                              backgroundColor:
                                theme === "dark-only"
                                  ? "transparent"
                                  : "#EBF1FF",
                              borderRadius: "50px",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              New Number:{" "}
                            </span>
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {completedData?.common_calls}
                            </span>
                          </div>
                          <div
                            style={{
                              padding: "10px 15px",
                              backgroundColor:
                                theme === "dark-only"
                                  ? "transparent"
                                  : "#EBF1FF",
                              borderRadius: "50px",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Repeat Calls:{" "}
                            </span>
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              {completedData?.common_calls}
                            </span>
                          </div> */}
                        </div>
                        <Col xs="12" sm="12" md="4">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: "#FF3364", // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />
                                    {/* Icon inside the circular progress */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: "green", // Match icon color with the path color
                                      }}
                                    >
                                      <img
                                        src={inBoundImg}
                                        alt="icon"
                                        style={{
                                          width: "54px",
                                          height: "54px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color:
                                          theme === "dark-only"
                                            ? Color.DARK_THE_GREY_TEXT
                                            : "rgba(82,82, 108,0.8)",
                                      }}
                                    >
                                      Calls
                                    </p>
                                    {/* <H5 attrH5={{ style: { marginTop: '-15px' } }}>{receivedCallLength ? receivedCallLength : 0}</H5> */}
                                    <H5
                                      attrH5={{
                                        style: {
                                          marginTop: "-15px",
                                          color:
                                            theme === "dark-only"
                                              ? Color.DARK_THE_GREY_TEXT
                                              : "",
                                        },
                                      }}
                                    >
                                      {
                                        completedData?.inbound
                                          ?.total_inboundCalls
                                      }
                                    </H5>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20%",
                                  }}
                                >
                                  <img
                                    src={inBoundPer}
                                    alt="icon"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                  <P
                                    attrPara={{
                                      style: {
                                        textAlign: "end",
                                        fontSize: "12px",
                                        color: "#FF3364",
                                      },
                                    }}
                                  >
                                    +
                                    {Number(
                                      completedData?.inbound
                                        ?.total_inboundCalls_prevoius_persantage
                                    ).toFixed(0)}
                                    %
                                  </P>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: "#54BA4A", // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: "green", // Match icon color with the path color
                                      }}
                                    >
                                      <img
                                        src={transImg}
                                        alt="icon"
                                        style={{
                                          width: "54px",
                                          height: "54px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color:
                                          theme === "dark-only"
                                            ? Color.DARK_THE_GREY_TEXT
                                            : "rgba(82,82, 108,0.8)",
                                      }}
                                    >
                                      Transferred
                                    </p>
                                    <H5
                                      attrH5={{
                                        style: {
                                          marginTop: "-15px",
                                          color:
                                            theme === "dark-only"
                                              ? Color.DARK_THE_GREY_TEXT
                                              : "",
                                        },
                                      }}
                                    >
                                      {Number(
                                        completedData?.transferCalls
                                          ?.transfer_call
                                      ).toFixed(0)}
                                    </H5>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20%",
                                  }}
                                >
                                  <img
                                    src={transPer}
                                    alt="icon"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                  <P
                                    attrPara={{
                                      style: {
                                        textAlign: "end",
                                        fontSize: "12px",
                                        color: "#54BA4A",
                                      },
                                    }}
                                  >
                                    {Number(
                                      completedData?.transferCalls
                                        ?.transfer_call_persantage
                                    ) < 0
                                      ? `${Number(
                                          completedData?.transferCalls
                                            ?.transfer_call_persantage
                                        ).toFixed(0)}%`
                                      : `+${Number(
                                          completedData?.transferCalls
                                            ?.transfer_call_persantage
                                        ).toFixed(0)}%`}
                                  </P>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: "#7366FF", // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />
                                    {/* Icon inside the circular progress */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: "green", // Match icon color with the path color
                                      }}
                                    >
                                      <img
                                        src={cusImg}
                                        alt="icon"
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color:
                                          theme === "dark-only"
                                            ? Color.DARK_THE_GREY_TEXT
                                            : "rgba(82,82, 108,0.8)",
                                      }}
                                    >
                                      Callers
                                    </p>
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <H5
                                          attrH5={{
                                            style: {
                                              marginTop: "-15px",
                                              paddingBottom: "0px",
                                              color:
                                                theme === "dark-only"
                                                  ? Color.DARK_THE_GREY_TEXT
                                                  : "",
                                            },
                                          }}
                                        >
                                          {
                                            completedData?.newTotalCalls
                                              .single_call
                                          }
                                        </H5>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            color: "#FC5455",
                                            marginTop: "-10px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          New
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          borderLeft:
                                            "1px solid rgba(82,82, 108,0.8)",
                                          height: "40px",
                                          margin: "-15px 15px 0px 15px",
                                        }}
                                      ></div>
                                      <div>
                                        <H5
                                          attrH5={{
                                            style: {
                                              marginTop: "-15px",
                                              paddingBottom: "0px",
                                              color:
                                                theme === "dark-only"
                                                  ? Color.DARK_THE_GREY_TEXT
                                                  : "",
                                            },
                                          }}
                                        >
                                          {Number(
                                            completedData?.newTotalCalls
                                              ?.multiple_calls
                                          ).toFixed(0)}
                                        </H5>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            color: "#FC5455",
                                            marginTop: "-10px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Existing
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <H5>Call Forwarding Summary</H5>
                      <Row sm={"9"} style={{ marginTop: "10px" }}>
                        <Col xs="12" sm="12" md="3">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <div
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: "#54BA4A", // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />
                                    {/* Icon inside the circular progress */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: "green", // Match icon color with the path color
                                      }}
                                    >
                                      <img
                                        src={tickSuc}
                                        alt="icon"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#54BA4A",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Successful
                                    </p>
                                    {/* <H5 attrH5={{ style: { marginTop: '-15px', fontSize: '16px' } }}>{forwardedCallLength ? forwardedCallLength : 0}</H5> */}
                                    <H5
                                      attrH5={{
                                        style: {
                                          marginTop: "-15px",
                                          color:
                                            theme === "dark-only"
                                              ? Color.DARK_THE_GREY_TEXT
                                              : "",
                                        },
                                      }}
                                    >
                                      {
                                        completedData?.transferCallComplted
                                          ?.transfer_call_completed
                                      }
                                    </H5>
                                  </div>
                                </div>
                                {/* <div
                                  style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20%",
                                  }}
                                >
                                  <img
                                    src={transPer}
                                    alt="icon"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                  <P
                                    attrPara={{
                                      style: {
                                        textAlign: "end",
                                        fontSize: "12px",
                                        color: "#54BA4A",
                                      },
                                    }}
                                  >
                                    +
                                    {Number(
                                      completedData?.transferCallComplted
                                        ?.transfer_call_completed_persantage
                                    ).toFixed(0)}
                                    %
                                  </P>
                                </div> */}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs="12" sm="12" md="3">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <div
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: "#FF3364", // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />
                                    {/* Icon inside the circular progress */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: "green", // Match icon color with the path color
                                      }}
                                    >
                                      <img
                                        src={busyIconImg}
                                        alt="icon"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#F92257",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Busy
                                    </p>
                                    <H5
                                      attrH5={{
                                        style: {
                                          marginTop: "-15px",
                                          color:
                                            theme === "dark-only"
                                              ? Color.DARK_THE_GREY_TEXT
                                              : "",
                                        },
                                      }}
                                    >
                                      {
                                        completedData?.transferCallsBusy
                                          ?.transfer_call_busy
                                      }
                                    </H5>
                                  </div>
                                </div>
                                {/* <div
                                  style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20%",
                                  }}
                                >
                                  <img
                                    src={inBoundPer}
                                    alt="icon"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                  <P
                                    attrPara={{
                                      style: {
                                        textAlign: "end",
                                        fontSize: "12px",
                                        color: "#FF3364",
                                      },
                                    }}
                                  >
                                    +
                                    {Number(
                                      completedData?.transferCallsBusy
                                        ?.transfer_call_busy_persantage
                                    ).toFixed(0)}
                                    %
                                  </P>
                                </div> */}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs="12" sm="12" md="3">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <div
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: "#C2363A", // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />
                                    {/* Icon inside the circular progress */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: "green", // Match icon color with the path color
                                      }}
                                    >
                                      <img
                                        src={failIcon}
                                        alt="icon"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#C2363A",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Failed
                                    </p>
                                    {/* <H5 attrH5={{ style: { marginTop: '-15px' } }}>{failedCalls}</H5> */}
                                    <H5
                                      attrH5={{
                                        style: {
                                          marginTop: "-15px",
                                          color:
                                            theme === "dark-only"
                                              ? Color.DARK_THE_GREY_TEXT
                                              : "",
                                        },
                                      }}
                                    >
                                      {
                                        completedData?.transferCallsFaild
                                          ?.transfer_call_Faild
                                      }
                                    </H5>
                                  </div>
                                </div>
                                {/* <div
                                  style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20%",
                                  }}
                                >
                                  <img
                                    src={inBoundPer}
                                    alt="icon"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                  <P
                                    attrPara={{
                                      style: {
                                        textAlign: "end",
                                        fontSize: "12px",
                                        color: "#FF3364",
                                      },
                                    }}
                                  >
                                    +{Number(failedCallsPer).toFixed(0)}%
                                  </P>
                                </div> */}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xs="12" sm="12" md="3">
                          <Card
                            style={{
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "0px",
                            }}
                          >
                            <CardBody style={{ padding: "20px 10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "-5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <div
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      position: "relative",
                                    }}
                                  >
                                    <CircularProgressbar
                                      value={25}
                                      styles={buildStyles({
                                        rotation: 0.5,
                                        strokeLinecap: "round",
                                        pathTransitionDuration: 0.5,
                                        pathColor: Color.TILES_ORANGE, // Dynamic color based on the metric
                                        trailColor: "#f5f5f5", // Light trail color to match background
                                        textColor: "transparent", // Hide percentage text
                                        backgroundColor: "#fff",
                                      })}
                                    />
                                    {/* Icon inside the circular progress */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "24px",
                                        color: Color.DARK_THE_GREY_TEXT, // Match icon color with the path color
                                      }}
                                    >
                                      {/* <img
                                        src={failIcon}
                                        alt="icon"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      /> */}
                                      <FaRegQuestionCircle
                                        size={20}
                                        color={Color.TILES_ORANGE}
                                        style={{
                                          marginTop: "10px",
                                          fontWeight: "500",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: Color.TILES_ORANGE,
                                        fontWeight: "500",
                                      }}
                                    >
                                      No Answer
                                    </p>
                                    {/* <H5 attrH5={{ style: { marginTop: '-15px' } }}>{failedCalls}</H5> */}
                                    <H5
                                      attrH5={{
                                        style: {
                                          marginTop: "-15px",
                                          color:
                                            theme === "dark-only"
                                              ? Color.DARK_THE_GREY_TEXT
                                              : "",
                                        },
                                      }}
                                    >
                                      {
                                        completedData?.transferCallsNoAnswer
                                          ?.transfer_call_NoAnswer
                                      }
                                    </H5>
                                  </div>
                                </div>
                                {/* <div
                                  style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20%",
                                  }}
                                >
                                  <img
                                    src={inBoundPer}
                                    alt="icon"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                  <P
                                    attrPara={{
                                      style: {
                                        textAlign: "end",
                                        fontSize: "12px",
                                        color: "#FF3364",
                                      },
                                    }}
                                  >
                                    +{Number(failedCallsPer).toFixed(0)}%
                                  </P>
                                </div> */}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={4} className={"dashboard-step3"}>
                      <Card style={{ padding: "0px", overflow: 'scroll' }}>
                        <CardBody style={{ height: "320px", padding: "0px" }}>
                          <div
                            style={{
                              padding: "20px 20px 0px 20px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <H6>Upcoming Bookings</H6>
                            <p
                              style={{
                                textDecorationLine: "underline",
                                color: Color.PRIMARY,
                                cursor: "pointer",
                                marginTop: "-5px",
                              }}
                              onClick={() =>
                                navigate(
                                  `${process.env.PUBLIC_URL}/app/calendar`
                                )
                              }
                            >
                              View All
                            </p>
                            {/* <Select
                              options={select}
                              styles={customStyles}
                              onChange={(e) => {
                                console.log("Selected Value", e.label);
                                // setSelectedData(e.label);
                                // // getCompanyStats(e.value);
                                // getDashboardData(e.value);
                              }}
                              defaultValue={select.find(
                                (option) => option.value === "today"
                              )}
                            /> */}
                          </div>
                          <div style={{ height: "270px", overflow: "hidden" }}>
                            {/* <ReactApexChart
                              options={pieChartData.options}
                              series={pieChartData.series}
                              type="donut"
                            /> */}
                            <UpcomingMeetings />
                          </div>

                          {/* <div>
                                <h4 style={{ color: '#FF3364', width: '200px' }}>Did You Know?</h4>
                              </div>
                              <div>
                                <p style={{ color: theme === 'dark-only' ? 'grey' : 'black', marginLeft: '5px' }}>The busiest hours where your assistant got most of the
                                  call every day is <strong>{highestCalls?.call_hour} am to 12:00 pm</strong> and it is more than <strong>{highestCalls?.call_count}</strong> calls</p>
                              </div> */}
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <H5>Department Summary</H5>
                    </div> */}
                    {/* <div>
                      <Card
                        style={{
                          height: '100px',
                          borderRadius: '10px',
                          marginTop: '10px'
                        }}
                      >
                        <Col xs="12" style={{ marginBottom: '5%', height: '100px', borderRadius: '5px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {departmentWiseSummary?.map((item, index) => {
                              console.log("🚀 ~ {departmentWiseSummary?.map ~ item:", item)
                              let percentage = item?.avg_status != null && !isNaN(item.avg_status) ? Number(item.avg_status).toFixed(2) : "0.00";
                              const colors = [`rgba(84, 186, 74, ${percentage / 100})`, `rgba(74, 85, 186, ${percentage / 100})`, `rgba(74, 85, 186, ${percentage / 100})`, `rgba(255, 51, 100, ${percentage / 100})`];
                              const clr = colors[index % colors?.length]
                              return (
                                <Card
                                  style={{
                                    height: '100px',
                                    borderRadius: '10px',
                                    width: '20%',
                                  }}
                                >
                                  <CardBody>
                                    <div style={{ display: 'flex', marginTop: '-5px', justifyContent: 'space-between' }}>
                                      <div style={{ marginLeft: '5px' }}>
                                        <p style={{ fontSize: '14px', color: 'rgba(82,82, 108,0.8)' }}>{item.department}</p>
                                        <h5 style={{ marginTop: '-15px' }}>{item?.total_calls}</h5>
                                      </div>
                                      <div style={{ height: '60px', width: '60px' }}>
                                        <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
                                          rotation: 0.25,
                                          strokeLinecap: 'butt',
                                          textSize: '22px',
                                          pathTransitionDuration: 0.5,
                                          pathColor: clr,
                                          textColor: '#2F2F3B',
                                          trailColor: '#d6d6d6',
                                          backgroundColor: '#3e98c7',
                                        })} />
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              )
                            })}
                          </div>
                        </Col>
                      </Card>
                    </div> */}

                    <Col sm="12">
                      <BasicAreaChartClass />
                    </Col>
                  </Row>
                )}
                {getCompanyInfo !== null && (
                  <Row>
                    <Col sm="12" md="12" className={"dashboard-step2"}>
                      <WeeklyCallsData />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Fragment>
        )}

        <Modal show={stripeModal} centered onHide={handleCloseStripeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StripePayment />
          </Modal.Body>
        </Modal>

        <Modal
          show={helpModal}
          centered
          size="lg"
          onHide={() => {}}
          backdropClassName="blur-backdrop"
        >
          <Modal.Body
            style={{ padding: "0px", overflow: "hidden", borderRadius: "10px" }}
          >
            <Row>
              <Col sm="4" style={{ padding: "0px" }}>
                <img
                  src={leftImg}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "-webkit-fill-available",
                    objectFit: "cover",
                  }}
                />
              </Col>

              <Col
                sm="8"
                style={{
                  padding: "10px 40px 0px 10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src={logoImg}
                    alt="Logo"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <RxCross2
                    color="black"
                    size={20}
                    cursor={"pointer"}
                    style={{ marginTop: "10px" }}
                    onClick={() => setHelpModal(false)}
                  />
                </div>
                <H4
                  attrH4={{
                    style: {
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#3666CC",
                      textAlign: "left",
                    },
                  }}
                >
                  Welcome To Fluten
                </H4>
                <P
                  attrPara={{
                    style: {
                      fontSize: "14px",
                      // color: "#4A4A4A",
                      paddingBottom: "0px",
                    },
                  }}
                >
                  Unlock the future with Fluten’s AI Assistant Service.
                </P>
                <P
                  attrPara={{
                    style: {
                      fontSize: "14px",
                      // color: "#4A4A4A",
                      paddingBottom: "0px",
                    },
                  }}
                >
                  Your comprehensive dashboard offers full visibility on
                  incoming business calls. Gain 360-degree insights to
                  understand customers, monetize calls, filter keywords, receive
                  notifications, and generate detailed reports. Enhance your AI
                  assistants’ performance, anytime from anywhere.
                </P>
                <P
                  attrPara={{
                    style: {
                      fontSize: "14px",
                      // color: "#4A4A4A",
                      paddingBottom: "0px",
                    },
                  }}
                >
                  Welcome to Fluten! To kick off your adventure, we're gifting
                  you $10.00 — just to say thanks for choosing us!
                </P>

                <div>
                  {/* <div>
                    <p
                      style={{
                        color: "#5679D5",
                        textDecorationLine: "underline",
                        lineHeight: 1.2,
                        cursor: "pointer",
                      }}
                      onClick={() => handleStartTour()}
                    >
                      Walk me the through dashboard
                    </p>
                    <p
                      style={{
                        color: "#5679D5",
                        textDecorationLine: "underline",
                        lineHeight: 1.2,
                        cursor: "pointer",
                      }}
                      onClick={() => handleUpdateAccount()}
                    >
                      Upgrade package
                    </p>
                    <p
                      style={{
                        color: "#5679D5",
                        textDecorationLine: "underline",
                        lineHeight: 1.2,
                        cursor: "pointer",
                      }}
                    >
                      AI Assistant Talk
                    </p>
                  </div> */}

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "5px",
                      // justifyContent: "space-between",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: Color.PRIMARY,
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "45%",
                      }}
                      onClick={handleStartTour}
                    >
                      Dashboard Tour
                    </button>
                    <button
                      style={{
                        backgroundColor: Color.SECONDARY,
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "45%",
                      }}
                      onClick={handleUpdateAccount}
                    >
                      Upgrade Package
                    </button>
                    {/* <button
                      style={{
                        backgroundColor: Color.DARK_THE_GREY_TEXT,
                        color: "#fff",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      AI Assistant Talk
                    </button> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        width: "17px",
                        height: "17px",
                        marginRight: "5px",
                      }}
                      checked={check}
                      onChange={() => {
                        if (!check) {
                          createCookie("showWelcomeDialog", "N", 30);
                        } else {
                          eraseCookie("showWelcomeDialog");
                        }
                        setCheck(!check);
                      }}
                    />
                    <label
                      onClick={() => setCheck(!check)}
                      style={{ fontSize: "14px", margin: 0 }}
                    >
                      Don’t show this again
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default DashboardContent;
