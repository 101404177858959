import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { notificationSliderOption } from './NotificationSliderOption';
import '../../../component2/ManageAssistant/ManageAssistant.css'
import defaultlogo from '../../../assets/images/defaultlogo.png'
import profileImage from '../../../assets/images/user/bot.png';
import ApiContext from '../../../ApiContext';
import { Color } from '../../../helpers/colors';
import { letterCase } from "../../../helpers/StringHelper";
import { useCompanyContext } from "../../../Store/providers/Company/CompanyProvider";
import { isEmpty } from "../../../helpers/utils";
import { TiTick } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { selectCompleteCompany } from '../../../Store/modules/company/companySelectors';
import './sliderStyle.css'
import { formatPhoneNumber } from '../../../Constant/GlobalFunctions';
import { H4, P } from '../../../AbstractElements';

const NotificationSlider = () => {
  const navigate = useNavigate()
  const { setUpdateStatus, companiesStatus, companyStatusData } = useContext(ApiContext);

  const completeCompany = useSelector(selectCompleteCompany);
  console.log("🚀 ~ NotificationSlider ~ completeCompany:", completeCompany)

  const companyStatus = completeCompany?.company?.companyStatus
  console.log("🚀 ~ companyStatus:", companyStatus)

  // let companyStatus = companyStatusData ? companyStatusData : JSON.parse(localStorage.getItem('companyStatus'))
  // console.log("🚀 ~ NotificationSlider ~ companyStatus:", companyStatusData)

  let getCompanyInfo = companiesStatus ? companiesStatus : JSON.parse(localStorage.getItem('companyData'))
  if (getCompanyInfo) {
    setUpdateStatus(true)
  } else {
    setUpdateStatus(false)
  }

  // const { completeCompany } = useCompanyContext();
  // console.log("🚀 ~ NotificationSlider ~ completeCompany:", completeCompany)

  // const companyStatus = completeCompany?.company?.companyStatus

  return (
    <div style={{ height: '30px', marginBottom: '10px', marginTop: '0px' }} className='notification-slider'>
      {getCompanyInfo !== null &&
        <Slider  {...notificationSliderOption}>
          <div className='d-flex' style={{ alignItems: 'center' }}>
            <div style={{ alignItems: 'center' }} className='d-flex'>
              <div className={companyStatus?.status === true ? 'company-img-wrap' : 'company-img-wrap-offline'}>
                <div className='social-img' style={{}}>
                  {getCompanyInfo.upload_logo ?
                    <img style={{ width: '45px', height: '40px', marginTop: '0px' }} src={`${process.env.REACT_APP_IMAGE_URL}${getCompanyInfo?.upload_logo}`} />
                    :
                    <img style={{ width: '45px', height: '45px', marginTop: '0px' }} src={defaultlogo} />
                  }
                </div>

                {completeCompany?.company?.company?.latest_package_payment?.current_balance < 5 && completeCompany?.company?.activeSubscription === 'payg' ?
                  <>
                    <div style={{ position: 'absolute', backgroundColor: Color.SECONDARY, display: 'flex', alignItems: 'center', justifyContent: 'center', right: '0px', bottom: '5px', borderRadius: '50px', width: '10px', height: '10px' }}></div>
                  </>
                  :
                  <>
                    {companyStatus?.status === true && <div style={{ position: 'absolute', backgroundColor: Color.GREEN, display: 'flex', alignItems: 'center', justifyContent: 'center', right: '-3px', bottom: '5px', borderRadius: '50px' }}><TiTick color='white' /></div>}
                  </>
                }

                {companyStatus?.status === false && <div style={{ position: 'absolute', backgroundColor: Color.SECONDARY, display: 'flex', alignItems: 'center', justifyContent: 'center', right: '0px', bottom: '5px', borderRadius: '50px', width: '10px', height: '10px' }}></div>}
              </div>

              <div style={{ marginLeft: '15px', marginTop: companyStatus?.status === true ? '0px' : '0px' }}>
                <div style={{ display: 'flex' }}>
                  <h6
                    style={{
                      fontWeight: '500',
                      fontSize: '20px',
                      lineHeight: 1,
                      marginTop: companyStatus?.status === true ? '0px' : '0px',
                      width: '450px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'inline-block',
                      color: Color.PRIMARY,
                    }}
                  >
                    {getCompanyInfo?.company_name}
                  </h6>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
                  {companyStatus?.status === true &&
                    <>
                      {completeCompany?.company?.company?.latest_package_payment?.current_balance < 2 && completeCompany?.company?.activeSubscription === 'payg' ?
                        <div style={{ backgroundColor: Color.SECONDARY, borderRadius: '10px', height: '17px', padding: '0px 5px', marginTop: '5px' }}>
                          <p style={{ color: Color.WHITE, padding: '0px', fontSize: '10px' }}>Offline - Low Balance</p>
                        </div>
                        :
                        <p style={{ backgroundColor: Color.GREEN, color: Color.WHITE, borderRadius: '10px', padding: '0px 5px', fontSize: "10px", marginBottom: '0px' }}>Online</p>
                      }
                      {/* <p style={{ backgroundColor: Color.GREEN, color: Color.WHITE, borderRadius: '10px', padding: '0px 5px', fontSize: "10px", marginBottom: '0px' }}>Online</p> */}
                    </>
                  }


                  {companyStatus?.status === false &&
                    <>
                      <div style={{ backgroundColor: Color.SECONDARY, borderRadius: '10px', height: '17px', padding: '0px 5px', marginTop: '5px' }}>
                        <p style={{ color: Color.WHITE, padding: '0px', fontSize: '10px' }}>
                          {!isEmpty(companyStatus?.message)
                            ? letterCase({ str: companyStatus?.message.replace(/([a-z])([A-Z])/g, '$1 $2') })
                            : 'Offline'}
                        </p>
                      </div>
                    </>
                  }
                  {/* <p style={{ color: Color.WHITE, padding: '0px', fontSize: '10px' }}>{!isEmpty(companyStatus?.message) ? letterCase({ str: companyStatus?.message }) : 'Offline'}</p> */}
                </div>
              </div>
            </div>
          </div>
        </Slider>
      }
    </div>
  );
};

export default NotificationSlider;
