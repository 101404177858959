import {Color} from "../../../../../helpers/colors";
import {P} from "../../../../../AbstractElements";

export const DetailsInfoTile = (props) => {
    const {heading, description} = props;
    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px'
            }}>
                <P attrPara={{
                    style: {
                        fontSize: '15px',
                        fontWeight: '500',
                        backgroundColor: Color.LIGHT_BLUE,
                        padding: '5px 10px',
                        borderRadius: '8px'
                    }
                }}>{heading}</P>
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginLeft: '10px'
            }}>
                <p style={{
                    marginTop: '5px',
                    fontSize: '15px',
                }}
                   dangerouslySetInnerHTML={{__html: description?.replace(/(\r?\n){2}/g, '<br />')}}></p>
            </div>
        </>
    );
}
