import React, { Fragment, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap'
import { Breadcrumbs, H4 } from '../../AbstractElements'
import rightUp from '../../assets/images/user/rightUp.png';
import payBack from '../../assets/images/user/payBack.png';
import { Modal } from 'react-bootstrap';
import StripePayment from '../Stripe/StripePayment';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from "react-toastify";
import '../ManageAssistant/ManageAssistant.css'

const Packages = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [stripeModal, setStripeModal] = useState(false)

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handlePaymentClick = () => {
        if (isChecked) {
            navigate(`${process.env.PUBLIC_URL}/app/billing`);
        } else {
            toast.error("Please agree to the Terms & Conditions", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
        }
    };

    const handleCloseStripeModal = () => {
        setStripeModal(false)
    }

    const data = [
        {
            img: rightUp,
            text: "Flexible pricing tiers"
        },
        {
            img: rightUp,
            text: "Real-time usage tracking"
        },
        {
            img: rightUp,
            text: "No hidden fees"
        },
        {
            img: rightUp,
            text: "Scalability"
        },
        {
            img: rightUp,
            text: "Access to advanced features"
        },
        {
            img: rightUp,
            text: "24/7 support"
        },
    ]

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Billing" />
            <Container fluid={true}>
                <Row>
                    <Col sm='5'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <H4>Pay As You Go</H4>
                            {/* <div style={{ marginTop: '10px' }}>
                                <p style={{ lineHeight: 0.5 }}>Minimum Balance</p>
                                <p style={{ lineHeight: 0.5 }}>$20</p>
                            </div> */}
                        </div>
                        {data.map((item, index) => {
                            return (
                                <div style={{ display: 'flex' }} key={index}>
                                    <img style={{ width: '25px', height: '25px', borderRadius: '10px' }} src={item.img} />
                                    <p style={{ marginLeft: '10px', fontSize: '16px', lineHeight: 1.2, marginTop: '2px' }}>{item.text}</p>
                                </div>
                            )
                        })}
                        <div className='checkbox' style={{ marginLeft: '5px' }}>
                            <Input
                                id='checkbox1'
                                type='checkbox'
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <Label className='text-muted' for='checkbox1'>
                                I agree to the <span style={{ textDecorationLine: 'underline', color: '#7064F5' }}>Terms & Conditions</span>
                            </Label>
                        </div>
                        <Button onClick={handlePaymentClick}>Make Payment</Button>
                        {/* <Button onClick={() => { navigate(`${process.env.PUBLIC_URL}/app/billing`) }}>Make Payment</Button> */}
                    </Col>
                    <Col sm='7'>
                        <Card style={{ padding: '0px' }}>
                            <CardBody style={{ padding: '0px' }}>
                                <img style={{ width: '100%', height: 'auto', borderRadius: '10px' }} src={payBack} />
                                <div style={{ padding: '5%' }}>
                                    <H4>Flexible Payment Plan For Your Business</H4>
                                    <ol style={{ marginTop: '10px', listStylePosition: 'outside', color: '#FF3364', display: 'block' }}>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>Cost savings:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> By only paying for what you use, you can save money compared to traditional subscription-based models.</span>
                                        </li>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>Flexibility:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> With pay-as-you-go, you can adjust your usage and costs to suit your changing business needs.</span>
                                        </li>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>No risk:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> With no minimum commitment or long-term contracts, you can try our services risk-free and cancel at any time.</span>
                                        </li>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>Easy to use:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> Our pay-as-you-go package is designed to be easy to use, with a simple and intuitive interface.</span>
                                        </li>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>Customizable:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> You can customize your package to suit your specific needs, with options for additional features and services.</span>
                                        </li>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>Security:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> Our pay-as-you-go package includes robust security measures to protect your data and ensure compliance with industry regulations.</span>
                                        </li>
                                        <li style={{ marginBottom: '10px', color: '#FF3364', fontWeight: '500' }}>
                                            <strong style={{ color: '#FF3364' }}>Transparency:</strong> <span style={{ color: 'black', fontWeight: 'initial' }}> We provide transparent pricing and clear billing, so you always know what you're paying for and why.</span>
                                        </li>
                                    </ol>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal show={stripeModal} centered onHide={handleCloseStripeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <StripePayment />
                    </Modal.Body>
                </Modal>
                <ToastContainer />
            </Container>
        </Fragment>
    )
}

export default Packages