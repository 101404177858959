import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, Row, Spinner } from 'reactstrap';
import './Stepper.css';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import roboImg from '../assets/images/logo/roboImg.png';
import solarArrow from '../assets/images/logo/solarArrow.png';
import tickCircle from '../assets/images/logo/tickCircle.png';
import botImage from '../assets/images/user/meh.png';
import expand from '../assets/images/user/expand.png';
import { H4, P, Btn, H6 } from '../AbstractElements';
import { ToastContainer, toast } from "react-toastify";
import 'react-phone-number-input/style.css';
import { TiTick } from "react-icons/ti";
import Select from 'react-select';
import { createManualBot, createSelectedBot, defaultBots, generateBotPersona, getAssistantVoice } from '../api/AuthIndex';
import '../component2/ManageAssistant/ManageAssistant.css'
import ApiContext from '../ApiContext';
import { FaArrowLeft, FaExpandArrowsAlt } from "react-icons/fa";
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import { IoPlayOutline, IoPlayCircleOutline, IoPauseCircleOutline } from "react-icons/io5";
import axios from 'axios';
import LanguageSelect from '../component2/ManageAssistant/LanguageSelect';
import { LANGUAGES_MAP } from '../helpers/constants';
import { Color } from '../helpers/colors';




const PrepareAssistant = ({ logoClassMain }) => {
    const { companyId, setBotData, industryName } = useContext(ApiContext);
    const theme = localStorage.getItem("mix_background_layout")
    const userInfo = JSON.parse(localStorage.getItem("register"))
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"))
    const location = useLocation()
    const ID = companyInfo?.domain
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [selectedBot, setSelectedBot] = useState(false);
    const [botLang, setBotLang] = useState(false);
    const [scanOption, setScanOption] = useState('');
    const [language, setLanguage] = useState(LANGUAGES_MAP?.[0]);
    const [ownLanguage, setOwnLanguage] = useState();
    const [botList, setBotList] = useState()
    const [submitLoader, setSubmitLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)



    const [name, setName] = useState('')
    const [gender, setGender] = useState('')
    const [assistantJob, setAssistantJob] = useState()
    const [assistantBio, setAssistantBio] = useState()
    const [firstMessage, setFirstMessage] = useState('Hi, how may I help you?')
    const [endCallMessage, setEndCallMessage] = useState('Good Bye, Bye')
    const [endCallPhrases, setEndCallPhrases] = useState('Have a nice day')
    const [backVoice, setBackVoice] = useState()
    const [assistantVoice, setAssistantVoice] = useState()
    const [botVoice, setBotVoice] = useState()
    const [keywords, setKeywords] = useState()
    const [audioSrc, setAudioSrc] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);
    const [renderValue, setRenderValue] = useState(null);
    const [agentData, setAgentData] = useState(null);
    const [audioLoader, setAudioLoader] = useState(false);

    const [errors, setErrors] = useState({});

    const [tags, setTags] = useState([]);

    const [error, setError] = useState([]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            const input = keywords.trim().replace(/,+$/, '').toLowerCase(); // Convert input to lowercase
            if (input) {
                const isDuplicate = tags.some(tag => tag.toLowerCase() === input); // Check case-insensitive duplicates
                if (isDuplicate) {
                    setError(`The keyword has already been submitted.`); // Set error message
                } else {
                    setTags([...tags, input]);
                    setKeywords(''); // Clear the input after adding
                    setError(''); // Clear any previous error
                }
            }
            e.preventDefault(); // Prevent default form submission
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };


    const parseLines = (value) => value.replace(/(\\n)/g, "\n");

    useEffect(() => {
        getAllDefaultBots()
    }, [language])

    useEffect(() => {
        if (botList?.length > 0) {
            // Set the first bot as the default selected bot
            handleSelectBot(botList[0]);
        }
    }, [botList]);


    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };
    const handleOwnLanguageChange = (lang) => {
        setOwnLanguage(lang);
        setErrors({ ownLanguage: '' })
    };

    const sentimentData = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
    ];

    const backSound = [
        { value: 'office', label: 'Office' },
        { value: 'off', label: 'Off' }
    ];

    const maleVoices = [
        { value: "29vD33N1CtxCmqQRPOHJ", label: "Alex", },
        { value: "TX3LPaxmHKxFdv7VOQHJ", label: "Brian", },
        { value: "ErXwobaYiN019PkySvjV", label: "Kim", },
        { value: "5Q0t7uMcjvnagumLfvZi", label: "Jim", },
        { value: "VR6AewLTigWG4xSOukaG", label: "Chris", }
    ]

    const femaleVoices = [
        { value: "AZnzlk1XvdvUeBnXmlld", label: "Nikki" },
        { value: "MF3mGyEYCl7XYWbV9V6O", label: "Olivia" },
        { value: "FGY2WhTYpPnrIDTdsKH5", label: "Hannah" },
        { value: "jsCqWAovK2LkecY7zXl4", label: "Chrissy" },
        // { value: "4Zz6vJeLG1xnOhlak6XR", label: "Maya" }
    ]

    const handleVoiceChange = (selectedOption) => {
        setAssistantVoice(selectedOption);
        setBotVoice(selectedOption.value)
        setErrors({})
    };

    useEffect(() => {
        if (audioSrc && audioElement) {
            // audioElement.play();
            // setIsPlaying(true);
        }
    }, [audioSrc, audioElement]);

    useEffect(() => {
        if (botVoice) {
            getSelectedAssistantVoice(); // Fetch audio once botVoice is updated
        }
    }, [botVoice]);

    const handlePlaySampleAudio = (voice_id) => {
        getSelectedAssistantVoice(voice_id); // Fetch and set the audioSrc for the selected bot's voice
    };

    const toggleAudioPlay = () => {
        if (isPlaying) {
            audioElement.pause(); // Pause the audio
            setIsPlaying(false);
        } else {
            audioElement.play(); // Play the audio
            setIsPlaying(true);
        }
    };

    // const togglePrecastBotAudioPlay = () => {
    //     if (audioElement) {
    //         if (isPlaying) {
    //             audioElement.pause();  // Pause the audio
    //             setIsPlaying(false);
    //         } else {
    //             audioElement.play().then(() => {
    //                 setIsPlaying(true);  // Start playing audio
    //             }).catch((error) => {
    //                 console.error('Error playing audio:', error);  // Handle any playback errors
    //             });
    //         }
    //     }
    // };

    const togglePrecastBotAudioPlay = (bot) => {
        if (isPlaying === bot.id) {
            // If the selected bot is already playing, pause it
            setIsPlaying(null);
            audioElement.pause();
        } else {
            // Play the new bot's audio
            setIsPlaying(bot.id);
            audioElement.play();
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
            fontWeight: '300',
            height: '45px',
            marginTop: '5px'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px',
            fontWeight: '300'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            '&:hover': {
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const customVoiceStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
            fontWeight: '300',
            width: '380px'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px',
            fontWeight: '300'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            '&:hover': {
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };


    const handleNext = (e) => {
        if (selectedBot == '' && scanOption == '') {
            toast.error("Please select anyone bot or create your own", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
        } else {
            if (selectedBot) {
                handleSelectedBotCreate()
            } else if (scanOption) {
                setAudioSrc(null)
                setStep(2);
            }
        }
    };

    const handleNextStep = () => {
        const newErrors = validateFormStep3();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setSubmitLoader(false);
            // return;
        } else {
            setStep(3);
        }
    }

    const handleBack = (e) => {
        e.preventDefault();
        if (step === 2) {
            setScanOption(null)
            setStep(1);
        } else if (step == 3) {
            setStep(2);
        } else {
            navigate(`${process.env.PUBLIC_URL}/business`);
        }
    };

    const handleScanOptionChange = (e) => {
        setScanOption(e.target.value);
        // setSelectedBot(null)
        setStep(2)
        setAudioSrc(null)
        // setFirstMessage(null)
    };

    const getAllDefaultBots = async () => {
        setBotList(null)
        setAgentData(null)
        setLoading(true)
        try {
            const res = await defaultBots(userInfo.token, ID, language.lang_name)
            console.log("🚀 ~ getAllDefaultBots ~ res:", res)
            setBotList(res.bots)
            setAgentData(res.bots[0])
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ getAllDefaultBots ~ error:", error)
            setLoading(false)
        }
    }

    const handleSelectedBotCreate = async () => {
        setSubmitLoader(true)
        try {
            const res = await createSelectedBot(userInfo.token, selectedBot)
            setBotData(res.bots)
            localStorage.setItem("bot_Data", JSON.stringify(res.bots));
            setSubmitLoader(false)
            if (res.bots) {
                navigate(`${process.env.PUBLIC_URL}/setup-phone`)
            }
        } catch (error) {
            console.log("🚀 ~ handleSelectedBotCreate ~ error:", error)
            setSubmitLoader(false)
        }
    }

    const handleSelectBot = (item) => {
        setSelectedBot(item.id);
        setBotLang(item.language);
        // setBotVoice(item.voice_id)
        // setFirstMessage(item.first_message)
        setAgentData(item);
    }

    const validateForm = () => {
        const newErrors = {};

        if (!name) newErrors.name = "Name is required.";
        if (!gender) newErrors.gender = "Gender is required.";
        if (!assistantBio) newErrors.assistantBio = "Assistant bio is required.";
        if (!firstMessage) newErrors.firstMessage = "First message is required.";
        if (!endCallMessage) newErrors.endCallMessage = "End call message is required.";
        if (!endCallPhrases) newErrors.endCallPhrases = "End call phrases are required.";
        if (!backVoice) newErrors.backVoice = "Background sound is required.";

        return newErrors;
    };


    const validateFormStep3 = () => {
        const newErrors = {};

        if (!ownLanguage) newErrors.ownLanguage = "Language is required.";
        if (!name) newErrors.name = "Name is required.";
        if (!gender) newErrors.gender = "Gender is required.";
        if (!assistantBio) newErrors.assistantBio = "Your AI Assistant Personality is required.";
        if (!firstMessage) newErrors.firstMessage = "Welcome Message To Customer is required.";
        if (!assistantVoice) newErrors.assistantVoice = "AI Assistant voice is required.";

        return newErrors;
    };

    const handleManualSetBot = async () => {
        setSubmitLoader(true)
        setErrors({})
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setSubmitLoader(false);
            return;
        }
        try {
            const formData = new FormData()
            formData.append("name", name)
            formData.append("gender", gender)
            formData.append("assist_job", 'incoming')
            formData.append("make_assist_bio", assistantBio)
            formData.append("first_message", firstMessage)
            formData.append("end_call_message", endCallPhrases)
            formData.append("end_call_phrases", endCallMessage)
            formData.append("background_sound", backVoice)
            // formData.append("keywords", keywords)

            const combinedKeywords = tags.join(', ');
            formData.append("keywords", combinedKeywords);
            formData.append("voice_id", botVoice)
            if (scanOption === 'scan') {
                formData.append("language", ownLanguage.lang_name)
            } else {
                formData.append("language", language.lang_name)
            }
            const res = await createManualBot(userInfo.token, formData)
            setBotData(res.bots)
            localStorage.setItem("bot_Data", JSON.stringify(res.bots));
            setSubmitLoader(false)
            if (res) {
                navigate(`${process.env.PUBLIC_URL}/setup-phone`)
                setName('')
                setGender('')
                setAssistantJob('')
                setAssistantBio('')
                setFirstMessage('')
                setEndCallMessage('')
                setEndCallPhrases('')
                setBackVoice('')
                setKeywords('')
            }
        } catch (error) {
            console.log("🚀 ~ handleManualSetBot ~ error:", error)
            setSubmitLoader(false)
        }

    }

    const generateBioPopover = (
        <Popover id="popover-generate-bio">
            <Popover.Body>Generate Bio Through AI</Popover.Body>
        </Popover>
    );

    const viewBioPopover = (
        <Popover id="popover-view-bio">
            <Popover.Body>View Bio</Popover.Body>
        </Popover>
    );

    const getSelectedAssistantVoice = async () => {
        setAudioLoader(true)
        setAudioSrc(null)
        const text = firstMessage;
        const voice = botVoice
        try {
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            const response = await axios.post(
                'https://elk.fluten.ai/api/getBotVoiceModel',
                {
                    text,
                    voice,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': `Bearer ${userInfo.token}`,
                    },
                    responseType: 'blob', // Set response type to blob for binary data
                }
            );

            // Create a URL for the blob response (audio file)
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(audioBlob);

            // Set the audio URL in state
            setAudioSrc(audioUrl);
            setAudioLoader(false)
        } catch (error) {
            console.error('Error fetching audio:', error);
            setAudioLoader(false)
        }
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleBotPersona = async () => {
        setLoader(true)
        if (name == '' || gender == '') {
            toast.error("Please select the name and gender.", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            })
            setLoader(false)
        } else {
            try {
                const formData = new FormData()
                // if (botInformation) {
                formData.append("name", name)
                formData.append("gender", gender)
                formData.append("role", 'incoming')
                // } else {
                //     formData.append("name", name)
                //     formData.append("gender", gender)
                //     formData.append("role", 'incoming')
                // }
                const res = await generateBotPersona(userInfo?.token, formData)
                // setPrompt(res[1])
                // setPrompt(parseLines(res[1]))
                setAssistantBio(res[1])
                setErrors({})
                // setAssistantBio(parseLines(res[1]))
                toast.info("Update the generated description as per your needs", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast',
                })
                setLoader(false)
            } catch (error) {
                console.log("🚀 ~ handleBotPersona ~ error:", error)
                setLoader(false)
            }
        }


    }

    return (
        <>
            <Fragment>
                <div
                    style={{
                        backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        display: 'block',
                        minHeight: '100vh',
                    }}>
                    <Container className='p-0 login-page' fluid={true}>
                        <Row className='m-0'>
                            <Link style={{ marginTop: '1%' }} className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                    <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                    <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                                </div>
                            </Link>
                            <Col className='p-0'>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '2%',
                                    }}>
                                    <Card style={{ width: '1000px', padding: '0px' }}>
                                        <CardBody style={{ padding: '0px' }}>

                                            <div>
                                                <Row>
                                                    <Col sm='12'>
                                                        <div className="stepper-container">
                                                            <div className={`step ${location.pathname === '/assistant' || location.pathname === '/step1' ? 'active' : ''}`}>
                                                                <div className="circle"> {location.pathname === '/assistant' || location.pathname === '/step1' ? <TiTick size={25} /> : ''}</div>
                                                                <span>Define Business</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/assistant' || location.pathname === '/step2' ? 'active' : ''}`}>
                                                                <div className="circle">02</div>
                                                                <span>Prepare Assistant</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/step3' ? 'active' : ''}`}>
                                                                <div className="circle">03</div>
                                                                <span>Phone Setup</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/step4' ? 'active' : ''}`}>
                                                                <div className="circle">04</div>
                                                                <span>Payment</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {scanOption === 'scan' ?
                                                        <Col sm='6' style={{ position: 'relative', overflow: 'hidden', overflowX: 'hidden', overflowY: 'auto', }}>
                                                            <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                                                            <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                                <div onClick={handleBack} style={{ cursor: 'pointer', backgroundColor: Color.SECONDARY, padding: '10px', width: '35px', height: '35px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <FaArrowLeft size={20} color={Color.WHITE} />
                                                                </div>
                                                                <div style={{ marginTop: '8px' }}>
                                                                    <H4 attrH4={{ style: { fontSize: '20px', color: Color.SECONDARY } }}>Define Your AI Assistant Personality</H4>
                                                                    <div style={{ marginTop: '8px', height: step === 3 ? '450px' : '520px', overflowX: 'hidden', overflowY: 'auto', }}>
                                                                        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { marginLeft: '10px', fontSize: '16px', fontWeight: '500' } }} >Choose an AI assistant from our existing collection or create a customized one for your business.</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { marginLeft: '10px', fontSize: '16px', } }} >These assistants are chosen based upon your company type. You can chose the language from the dropdown and see the assistant of that specific language.</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { marginLeft: '10px', fontSize: '16px', } }} >Alternatively, you can craft your own assistant by providing your choise of information about the assistant performance and behaviour over the phone.</P>
                                                                        </div> */}
                                                                        {step === 2 &&
                                                                            <>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Steps to Create your AI Assistant</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Provide all the required information to make an AI Assistant specially designed to deal with clients in your industry.</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Define Your AI Assistant Personality</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Select a name, gender, write a brief biography, and select a voice.  The biography can be AI generated for you that can be edited.</P>
                                                                                </div>

                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Welcome Message To Customer</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >The greeting message your AI Assistant will convey on picking up the customer phone.</P>
                                                                                </div>

                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >AI Assistant Voice Should Sound Like</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Choose a voice that will be associated with your AI Assistant.</P>
                                                                                </div>
                                                                            </>
                                                                        }

                                                                        {step === 3 &&
                                                                            <>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >End Call Phrase</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Include phrases to help the AI assistant recognize when a customer intends to conclude the call. For example, 'Goodbye.’ (Note: You can add multiple phrases, separated by comma).</P>
                                                                                </div>

                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >End Call Message</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Specify the message you want the Assistant to convey when concluding a call. For example, 'Have a great day' or 'Goodbye'.</P>
                                                                                </div>

                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Background Sound</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >For a more realistic experience, you can select a background office ambiance for your Assistant. Alternatively, you may choose to have no background sound at all.</P>
                                                                                </div>

                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Keywords</P>
                                                                                </div>
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                                    <P attrPara={{ style: { fontSize: '15px', marginLeft: '10px' } }} >Enter the keywords you wish to filter in conversations between the customer and the Assistant.(Note: You can add multiple keywords, separated by a comma or hitting the Enter key)</P>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        :
                                                        <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                            <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />

                                                            <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                                {/* <img className='for-light' src={solarArrow} alt='loginpage' style={{ width: '30px', height: '30px', marginTop: '8px', cursor: 'pointer' }} onClick={handleBack} /> */}
                                                                <div onClick={handleBack} style={{ cursor: 'pointer', backgroundColor: Color.SECONDARY, padding: '10px', width: '35px', height: '35px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <FaArrowLeft size={20} color={Color.WHITE} />
                                                                </div>

                                                                <div style={{ marginTop: '8px' }}>
                                                                    <H4 attrH4={{ style: { color: Color.SECONDARY } }}>AI Assistant Persona</H4>
                                                                    {loading ?
                                                                        <div style={{ width: '430px', height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <Spinner as="span" animation="grow" size="md" role="status" aria-hidden="true" />
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {agentData !== null ?
                                                                                <>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <img src={agentData?.picture ? agentData?.picture : roboImg} alt='loginpage' style={{ width: '80px', height: '80px', borderRadius: '50px' }} />
                                                                                        <div style={{ width: '100%' }}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                                                <P attrPara={{ style: { textAlign: 'left', fontWeight: 'bold', fontSize: '18px', marginTop: '10px' } }}>{agentData?.bot_name}</P>
                                                                                                {agentData?.audio_file !== null && (
                                                                                                    <>
                                                                                                        {/* {audioLoader ?
                                                                                                            <div style={{ display: 'flex', cursor: 'pointer', backgroundColor: '#DDE6FF', height: '30px', width: '80px', padding: '5px 10px', borderRadius: '50px', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" color='red' />
                                                                                                            </div>
                                                                                                            : */}
                                                                                                        <div style={{ display: 'flex', cursor: 'pointer', backgroundColor: '#DDE6FF', height: '30px', padding: '5px 10px', borderRadius: '50px' }}>
                                                                                                            <div onClick={() => togglePrecastBotAudioPlay(agentData)} style={{ cursor: 'pointer' }}>
                                                                                                                {isPlaying ? (
                                                                                                                    <IoPauseCircleOutline size={20} color={'#5679D5'} />
                                                                                                                ) : (
                                                                                                                    <IoPlayCircleOutline size={20} color={'#5679D5'} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <P attrPara={{ style: { color: '#5679D5', lineHeight: 1.2, fontSize: '16px', marginLeft: '5px' }, onClick: () => togglePrecastBotAudioPlay(agentData) }}>Play</P>
                                                                                                            <audio
                                                                                                                ref={(audio) => setAudioElement(audio)}
                                                                                                                src={agentData?.audio_file}
                                                                                                                type="audio/mpeg"
                                                                                                                onEnded={() => setIsPlaying(false)}
                                                                                                                style={{ display: 'none' }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {/* } */}
                                                                                                    </>
                                                                                                )}
                                                                                            </div>

                                                                                            <P attrPara={{ style: { textAlign: 'left', fontWeight: '500', fontSize: '16px', marginTop: '-10px', color: '#5679D5' } }}>{agentData?.gender === 'M' ? 'Male' : agentData?.gender === 'F' ? 'Female' : ''}</P>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginTop: '20px', height: '450px', overflow: 'scroll' }}>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Welcome Message To Customer</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px' } }} >{agentData?.first_message}</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Ending Last Message to Customer</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px' } }} >{agentData?.end_call_message}</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Ending the Phone Call Message</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px' } }} >{agentData?.end_call_phrases}</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                                            <P attrPara={{ style: { fontSize: '15px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Instruction</P>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                                                                                            <p style={{ marginTop: '5px', fontSize: '15px', }} dangerouslySetInnerHTML={{ __html: agentData?.prompt?.replace(/\n/g, '<br/>') }}></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <div style={{ width: '430px', height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }}>Assistant not found</P>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    }




                                                    <Col sm="6">
                                                        <div style={{ padding: '20px 20px 0px 0px' }}>
                                                            <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
                                                                <Form className="theme-form login-form" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                                                    <H4 attrH4={{ style: { color: '#FF5757', fontSize: '20px' } }}>Define Your AI Assistant Personality</H4>
                                                                    {scanOption !== 'scan' && <H6 attrH6={{ style: { color: '#FF5757', fontWeight: '500', fontSize: '14px' } }}>{botList?.length} Pre-trained AI Assistants tailored to your (<span style={{ color: Color.PRIMARY }}>{industryName}</span>) business profile, ready for seamless integration.</H6>}

                                                                    {step == 1 &&
                                                                        <>
                                                                            <Col sm="12">
                                                                                <LanguageSelect
                                                                                    languageMap={LANGUAGES_MAP}
                                                                                    selectedLanguage={language}
                                                                                    onLanguageChange={handleLanguageChange}
                                                                                />
                                                                            </Col>
                                                                            <div style={{ height: '380px', width: '100%', overflowY: 'scroll', overflowX: 'hidden', }}>
                                                                                {botList?.length > 0 ?
                                                                                    <>
                                                                                        {botList?.map((item) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={item.id}
                                                                                                    onClick={() => {
                                                                                                        handleSelectBot(item)
                                                                                                        setScanOption(null)
                                                                                                    }}
                                                                                                    style={{
                                                                                                        width: '96%',
                                                                                                        // height: '140px',
                                                                                                        border: selectedBot === item.id ? '2px solid #5679D5' : 'none', // Check if this is the selected bot
                                                                                                        // boxShadow: selectedBot === item.id ? '2px 2px 5px lightgrey' : 'none',
                                                                                                        cursor: 'pointer',
                                                                                                        display: 'flex',
                                                                                                        // alignItems: 'center',
                                                                                                        // justifyContent: 'center',
                                                                                                        borderRadius: '10px',
                                                                                                        margin: '5px 5px 10px 5px',
                                                                                                        padding: '15px 10px',
                                                                                                        backgroundColor: selectedBot === item.id ? 'rgba(236, 241, 254, 1)' : 'white'
                                                                                                    }}
                                                                                                >
                                                                                                    <div style={{ display: 'flex' }}>
                                                                                                        <div style={{ width: '80px', height: '80px', borderRadius: '50px', border: selectedBot === item.id ? '4px solid #5679D5' : 'none' }}>
                                                                                                            <img src={item.picture && !item.picture.includes('/tmp/') ? item.picture : roboImg} alt='loginpage' style={{ width: '71px', height: '71px', borderRadius: '50px', }} />
                                                                                                        </div>
                                                                                                        <div style={{ marginLeft: '5px' }}>
                                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                                                <P attrPara={{ style: { textAlign: 'left', fontWeight: 'bold', fontSize: '18px' } }}>{item?.bot_name}</P>
                                                                                                            </div>
                                                                                                            <P attrPara={{
                                                                                                                style: {
                                                                                                                    textAlign: 'left',
                                                                                                                    fontWeight: '300',
                                                                                                                    // marginTop: '-15px',
                                                                                                                    display: '-webkit-box',
                                                                                                                    WebkitLineClamp: 3,
                                                                                                                    WebkitBoxOrient: 'vertical',
                                                                                                                    overflow: 'hidden',
                                                                                                                    textOverflow: 'ellipsis',
                                                                                                                    lineHeight: 1.2,
                                                                                                                }
                                                                                                            }}>
                                                                                                                {item?.prompt}
                                                                                                            </P>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </>
                                                                                    :
                                                                                    <div style={{ width: '430px', height: '320px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }}>Assistant not found</P>
                                                                                    </div>
                                                                                }

                                                                            </div>

                                                                            <div style={{ marginLeft: '10px' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="scan"
                                                                                    checked={scanOption === 'scan'}
                                                                                    onChange={handleScanOptionChange}
                                                                                    className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                    style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                />
                                                                                <label style={{ marginTop: '5px', marginLeft: '5px' }} onClick={() => {
                                                                                    setScanOption('scan')
                                                                                    // setSelectedBot(null)
                                                                                    setStep(2)
                                                                                    setAudioSrc(null)
                                                                                    // setFirstMessage(null)
                                                                                }}>Create my own assistant</label>
                                                                            </div>

                                                                            <div style={{ marginTop: 'auto', width: '100%' }}>
                                                                                {submitLoader ?
                                                                                    <Button color='primary' style={{ width: '95%', }} disabled={submitLoader}>
                                                                                        Next...
                                                                                    </Button>
                                                                                    :
                                                                                    <Button color='primary' style={{ width: '95%', }} onClick={handleNext}>
                                                                                        {selectedBot ? "Next" : "Next"}
                                                                                    </Button>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    {step === 2 && (
                                                                        <>
                                                                            <Col sm="12">
                                                                                <label>Language</label>
                                                                                <LanguageSelect
                                                                                    languageMap={LANGUAGES_MAP}
                                                                                    selectedLanguage={ownLanguage}
                                                                                    onLanguageChange={handleOwnLanguageChange}
                                                                                />
                                                                                {errors.ownLanguage && <span style={{ color: 'red', fontSize: '12px' }}>{errors.ownLanguage}</span>}
                                                                            </Col>
                                                                            <Form>
                                                                                <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', marginTop: '10px' }}>
                                                                                    {/* Name Field on the Left */}
                                                                                    <div style={{ flex: 1 }}>
                                                                                        <label>AI Assistant Name</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Enter Name"
                                                                                            style={{ marginTop: '5px' }}
                                                                                            onChange={(e) => {
                                                                                                setName(e.target.value);
                                                                                                if (e.target.value) setErrors(prev => ({ ...prev, name: '' }));
                                                                                            }}
                                                                                            value={name}
                                                                                        />
                                                                                        {errors.name && <span style={{ color: 'red', fontSize: '12px' }}>{errors.name}</span>}
                                                                                    </div>

                                                                                    {/* Gender Dropdown on the Right */}
                                                                                    <div style={{ flex: 1 }}>
                                                                                        <label>Gender</label>
                                                                                        <Select
                                                                                            options={sentimentData}
                                                                                            styles={customStyles}
                                                                                            onChange={(e) => {
                                                                                                setGender(e.value);
                                                                                                if (e.value) setErrors(prev => ({ ...prev, gender: '' }));
                                                                                                setAssistantVoice('')
                                                                                                setBotVoice('')
                                                                                            }}
                                                                                            placeholder="Select Gender"
                                                                                            value={sentimentData?.find(option => option.value === gender)}
                                                                                        />
                                                                                        {errors.gender && <span style={{ color: 'red', fontSize: '12px' }}>{errors.gender}</span>}
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div style={{ marginBottom: '10px' }}>
                                                                                <label>Assistant Job</label>
                                                                                <Select
                                                                                    options={jobOptions}
                                                                                    styles={customStyles}
                                                                                    onChange={(e) => setAssistantJob(e.value)}
                                                                                    placeholder="Select Assistant Job"
                                                                                />
                                                                            </div> */}

                                                                                <div style={{ marginBottom: '10px' }}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '4%' }}>
                                                                                        <label>Your AI Assistant Personality</label>
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            {/* OverlayTrigger for the img "Generate Bio Through AI" */}
                                                                                            {loader ? (
                                                                                                <div className="loader" style={{ width: '20px', height: '20px', marginRight: '10px', marginTop: '2px' }}>
                                                                                                    <Spinner style={{ height: '18px', width: '18px' }} />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <img
                                                                                                    style={{ width: '20px', height: '20px', marginRight: '10px', cursor: 'pointer' }}
                                                                                                    src={botImage}
                                                                                                    alt="Generate Bio Through AI"
                                                                                                    onClick={handleBotPersona}
                                                                                                />
                                                                                            )}

                                                                                            {/* OverlayTrigger for the FaExpandArrowsAlt "View Bio" */}
                                                                                            <OverlayTrigger trigger="hover" placement="top" overlay={viewBioPopover}>
                                                                                                <img
                                                                                                    style={{ width: '15px', height: '15px', marginRight: '10px', cursor: 'pointer' }}
                                                                                                    src={expand}
                                                                                                    alt="View Bio"
                                                                                                    onClick={handleShow} // Open modal on click
                                                                                                />
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>
                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        rows="3"
                                                                                        placeholder="Enter Your AI Assistant Personality"
                                                                                        value={assistantBio}
                                                                                        onChange={(e) => {
                                                                                            setAssistantBio(e.target.value);
                                                                                            if (e.target.value) setErrors(prev => ({ ...prev, assistantBio: '' }));
                                                                                        }}
                                                                                        style={{ fontSize: '14px', fontWeight: '300' }}
                                                                                    />
                                                                                    {errors.assistantBio && <span style={{ color: 'red', fontSize: '12px' }}>{errors.assistantBio}</span>}
                                                                                </div>
                                                                                <div style={{ marginBottom: '10px' }}>
                                                                                    <label>Welcome Message To Customer</label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        placeholder="Hi, How may i help you."
                                                                                        value={firstMessage}
                                                                                        onChange={(e) => {
                                                                                            setFirstMessage(e.target.value);
                                                                                            if (e.target.value) setErrors(prev => ({ ...prev, firstMessage: '' }));
                                                                                        }}
                                                                                        style={{ fontSize: '14px', fontWeight: '300' }}
                                                                                    />
                                                                                    {errors.firstMessage && <span style={{ color: 'red', fontSize: '12px' }}>{errors.firstMessage}</span>}
                                                                                </div>
                                                                                <div style={{ marginBottom: '10px' }}>
                                                                                    <label>AI Assistant Voice Should Sound Like</label>
                                                                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                                                                        <Select
                                                                                            id="edit_voice"
                                                                                            name="voice_id"
                                                                                            // options={voiceOptions}
                                                                                            options={gender === 'M' ? maleVoices : femaleVoices}
                                                                                            value={assistantVoice}
                                                                                            onChange={handleVoiceChange}
                                                                                            placeholder={'Select voice'}
                                                                                            styles={customVoiceStyles}
                                                                                        />
                                                                                        {/* {audioSrc && */}
                                                                                        <div onClick={audioSrc ? toggleAudioPlay : () => { }} style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                                                                            {isPlaying ? (
                                                                                                <IoPauseCircleOutline size={25} style={{ marginTop: '5px' }} />
                                                                                            ) : (
                                                                                                <IoPlayCircleOutline size={25} style={{ marginTop: '5px' }} />
                                                                                            )}
                                                                                        </div>
                                                                                        {/* } */}
                                                                                    </div>
                                                                                    <audio
                                                                                        ref={(audio) => setAudioElement(audio)}
                                                                                        src={audioSrc}
                                                                                        type="audio/mpeg"
                                                                                        onEnded={() => setIsPlaying(false)}
                                                                                        style={{ display: 'none' }}
                                                                                    />
                                                                                    {errors.assistantVoice && <span style={{ color: 'red', fontSize: '12px' }}>{errors.assistantVoice}</span>}
                                                                                </div>
                                                                                <div style={{ marginTop: 'auto', width: '100%' }}>
                                                                                    <Button color='primary' style={{ width: '95%', }} onClick={handleNextStep}
                                                                                    >
                                                                                        Next
                                                                                    </Button>
                                                                                </div>
                                                                            </Form>
                                                                        </>
                                                                    )}
                                                                    {step === 3 && (
                                                                        <Form>
                                                                            <div style={{ marginBottom: '10px' }}>
                                                                                <label>End Call Phrases</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    placeholder="Goodbye, Take Care, Bye"
                                                                                    value={endCallMessage}
                                                                                    onChange={(e) => {
                                                                                        setEndCallMessage(e.target.value);
                                                                                        if (e.target.value) setErrors(prev => ({ ...prev, endCallMessage: '' }));
                                                                                    }}
                                                                                    style={{ fontSize: '14px', fontWeight: '300' }}
                                                                                />
                                                                                {errors.endCallPhrases && <span style={{ color: 'red', fontSize: '12px' }}>{errors.endCallPhrases}</span>}
                                                                            </div>
                                                                            <div style={{ marginBottom: '10px' }}>
                                                                                <label>End Call Message</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    placeholder="Have a nice day"
                                                                                    value={endCallPhrases}
                                                                                    onChange={(e) => {
                                                                                        setEndCallPhrases(e.target.value);
                                                                                        if (e.target.value) setErrors(prev => ({ ...prev, endCallPhrases: '' }));
                                                                                    }}

                                                                                    style={{ fontSize: '14px', fontWeight: '300' }}
                                                                                />
                                                                                {errors.endCallMessage && <span style={{ color: 'red', fontSize: '12px' }}>{errors.endCallMessage}</span>}
                                                                            </div>
                                                                            <div style={{ marginBottom: '10px' }}>
                                                                                <label>Background Sound</label>
                                                                                <Select
                                                                                    options={backSound}
                                                                                    styles={customStyles}
                                                                                    onChange={(e) => {
                                                                                        setBackVoice(e.value)
                                                                                        if (e.value) setErrors(prev => ({ ...prev, backVoice: '' }));
                                                                                    }}
                                                                                    placeholder="Select Background Sound"
                                                                                />
                                                                                {errors.backVoice && <span style={{ color: 'red', fontSize: '12px' }}>{errors.backVoice}</span>}
                                                                            </div>

                                                                            <div style={{ marginBottom: '10px' }}>
                                                                                <label>Keywords</label>
                                                                                <div className="tags-input-container">
                                                                                    <div className="tags-wrapper">
                                                                                        {tags.map((tag, index) => (
                                                                                            <div key={index} className="tag">
                                                                                                {tag}
                                                                                                <span className="tag-close-icon" onClick={() => removeTag(index)}>
                                                                                                    &times;
                                                                                                </span>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>

                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        rows="1"
                                                                                        placeholder="Enter keywords and press Enter or comma"
                                                                                        value={keywords}
                                                                                        onChange={(e) => setKeywords(e.target.value)}
                                                                                        onKeyPress={handleKeyPress}
                                                                                        maxLength={32}
                                                                                    />

                                                                                    <style>
                                                                                        {`
                                                                                        .tags-input-container {
                                                                                            display: flex;
                                                                                            flex-direction: column;
                                                                                            gap: 5px;
                                                                                            padding: 5px;
                                                                                            border: 1px solid #ccc;
                                                                                            border-radius: 4px;
                                                                                        }
                                                                                        .tags-wrapper {
                                                                                            display: flex;
                                                                                            flex-wrap: wrap;
                                                                                            gap: 5px;
                                                                                        }
                                                                                        .tag {
                                                                                            background-color: #5979d4;
                                                                                            color: #fff;
                                                                                            padding: 5px 10px;
                                                                                            border-radius: 3px;
                                                                                            display: flex;
                                                                                            align-items: center;
                                                                                        }
                                                                                        .tag-close-icon {
                                                                                            margin-left: 8px;
                                                                                            cursor: pointer;
                                                                                        }
                                                                                        .form-control {
                                                                                            outline: none;
                                                                                            width: 100%;
                                                                                            resize: none;
                                                                                            padding: 10px;
                                                                                        }
                                                                                        `}
                                                                                    </style>
                                                                                </div>
                                                                                {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
                                                                            </div>

                                                                            <div style={{ marginTop: 'auto', width: '100%' }}>
                                                                                <Button
                                                                                    color="primary"
                                                                                    style={{ width: '95%' }}
                                                                                    disabled={submitLoader}
                                                                                    onClick={handleManualSetBot}
                                                                                >
                                                                                    {submitLoader ? "Next..." : 'Next'}
                                                                                </Button>
                                                                            </div>
                                                                        </Form>
                                                                    )}
                                                                </Form>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Modal show={showModal} centered size='lg' onHide={handleClose}>
                        <Modal.Body>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Modal.Title style={{ textAlign: 'left' }}>Assistant Bio</Modal.Title>
                                <ImCross size={15} onClick={handleClose} style={{ cursor: 'pointer' }} />
                            </div>
                            <textarea
                                className="form-control"
                                rows="20"
                                placeholder="Enter Bio"
                                value={assistantBio}
                                onChange={(e) => setAssistantBio(e.target.value)}
                            />
                        </Modal.Body>
                    </Modal>
                    <ToastContainer />
                </div>
            </Fragment >
        </>
    )
}

export default PrepareAssistant