import {Color} from "../../../../../helpers/colors";
import {H4, P} from "../../../../../AbstractElements";

export const PhoneSetupDetails = () => {
    return (
        <div style={{position: 'relative', zIndex: 2}}>
            <div style={{marginTop: '8px'}}>
                <H4 attrH4={{style: {fontSize: '1.3rem', marginTop: '5px', color: Color.SECONDARY}}}>Phone Setup</H4>
                <div style={{marginTop: '8px', height: '420px', overflow: 'scroll'}}>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
                        <P attrPara={{style: {fontSize: '16px',}}}>Choose a new dedicated number for your AI assistant
                            or enter an existing Twilio phone number.</P>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <P attrPara={{
                                style: {
                                    fontSize: '15px',
                                    fontWeight: '500',
                                    backgroundColor: Color.LIGHT_BLUE,
                                    padding: '5px 10px',
                                    borderRadius: '8px'
                                }
                            }}>New Number Request</P>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                            <P attrPara={{style: {fontSize: '15px', marginLeft: '10px'}}}>Select a state and phone
                                prefix. Fluten will match or find a similar prefix. The phone number cost per month is
                                shown below:</P>
                        </div>
                        <ul style={{listStyleType: 'disc', paddingLeft: '25px'}}>
                            <li style={{fontSize: '15px', marginLeft: '10px'}}>Regular Phone Number = $1.50/Month</li>
                            <li style={{fontSize: '15px', marginLeft: '10px'}}>Toll-Free Phone Number = $2.50/Month</li>
                        </ul>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <P attrPara={{
                                style: {
                                    fontSize: '15px',
                                    fontWeight: '500',
                                    backgroundColor: Color.LIGHT_BLUE,
                                    padding: '5px 10px',
                                    borderRadius: '8px'
                                }
                            }}>Transfer Your Existing Twilio Number</P>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                            <P attrPara={{style: {fontSize: '15px', marginLeft: '10px'}}}>Move your current Twilio
                                number to Fluten’s platform. To complete onboarding, please provide:</P>
                        </div>
                        <ul style={{listStyleType: 'disc', paddingLeft: '25px'}}>
                            <li style={{fontSize: '15px', marginLeft: '10px'}}>Account SID</li>
                            <li style={{fontSize: '15px', marginLeft: '10px'}}>Auth Token</li>
                        </ul>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '12px'}}>
                        <P attrPara={{style: {fontSize: '15px',}}}>You can get this information on your Twilio
                            dashboard.</P>
                    </div>
                </div>
            </div>
        </div>
    );
}