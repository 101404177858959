import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { Row, Col, Card, CardBody, Button, } from 'reactstrap';
import { H4, Image } from '../../AbstractElements';
import { Modal } from 'react-bootstrap';
import profileImage from '../../assets/images/user/bot.png';

const NewAssistant = () => {
    const { state } = useLocation()
    const navigate = useNavigate()

    const [botData, setBotData] = useState(state)
    console.log("🚀 ~ NewAssistant ~ botData:", botData)

    const [callingModal, setCallingModal] = useState(false)

    return (
        <>
            <Row style={{ paddingTop: '2%' }}>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="7">
                                    {/* Make a table  */}
                                    <div style={{ padding: '20px' }}>
                                        <div>
                                            <div style={{ position: 'relative' }}>
                                                <Image attrImage={{ width: '100%', src: `${require('../../assets/images/user/botImage.png')}`, alt: 'looginpage' }} />
                                            </div>
                                            <div style={{ position: 'absolute', top: '13%', left: '38%' }}>
                                                <h4 style={{ color: 'white' }}>{botData?.params?.bot_name}</h4>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ borderRight: botData?.item?.jobs_to_do && '4px solid white', paddingRight: '10px' }}>
                                                        <h6 style={{ color: 'white' }}>{botData?.params?.gender == 'M' ? "Male" : botData?.params?.gender == "F" ? "Female" : ''}</h6>
                                                    </div>
                                                    <div style={{ marginLeft: '10px', }}>
                                                        <h6 style={{ color: 'white' }}>{botData?.item?.jobs_to_do}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 style={{ fontSize: '14px', color: '#52526C' }}>Bot bio</h6>
                                        <div style={{ height: '130px', overflow: 'scroll', border: ' 2px solid #EEE', padding: '10px', borderRadius: '10px', borderStyle: 'dashed' }}>
                                            <h6 style={{ fontWeight: '300', fontSize: '14px', color: '#727272', lineHeight: 1 }} dangerouslySetInnerHTML={{ __html: botData?.params?.prompt?.replaceAll('\\n', '<br />') }}></h6>
                                        </div>
                                        <div style={{ marginTop: '4%' }}>
                                            <Row>
                                                <Row>
                                                    <Col sm='6'>
                                                        <h6 style={{ fontSize: '14px', color: '#52526C' }}>First Message</h6>
                                                        <h6 style={{ fontWeight: '300', fontSize: '14px', color: '#727272' }} >{botData?.params?.first_message}</h6>
                                                    </Col>
                                                    <Col sm='6'>
                                                        <h6 style={{ fontSize: '14px', color: '#52526C' }}>End Call Trigger</h6>
                                                        <h6 style={{ fontWeight: '300', fontSize: '14px', color: '#727272' }} >{botData?.params?.end_call_phrases}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm='6'>
                                                        <h6 style={{ fontSize: '14px', color: '#52526C' }}>Background Voice</h6>
                                                        <h6 style={{ fontWeight: '300', fontSize: '14px', color: '#727272' }} >{botData?.params?.background_sound}</h6>
                                                    </Col>
                                                    <Col sm='6'>
                                                        <h6 style={{ fontSize: '14px', color: '#52526C' }}>End Call Message</h6>
                                                        <h6 style={{ fontWeight: '300', fontSize: '14px', color: '#727272' }} >{botData?.params?.end_call_message}</h6>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="5">
                                    <Card style={{ width: '300px', height: '400px', alignItems: 'center', justifyContent: 'center' }}>
                                        {botData?.item?.avatar ? (
                                            <img style={{ width: '60%', height: 'auto', boxShadow: '0 0 -5px rgba(0, 0, 0, 0.3)' }} src={process.env.REACT_APP_IMAGE_URL + botData?.item?.avatar} />
                                        ) : (
                                            <img style={{ width: '80%', height: 'auto', boxShadow: '0 0 -5px rgba(0, 0, 0, 0.3)' }} src={profileImage} />
                                        )}

                                        <Button onClick={() => { setCallingModal(true) }} style={{ width: '250px', textTransform: 'uppercase' }} color='primary' >TEST CALL WITH {botData?.params?.bot_name}</Button>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                show={callingModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => { setCallingModal(false) }}
            >
                <Modal.Body>
                    <h4 style={{ textAlign: 'center', fontWeight: '500' }}>{botData?.params?.bot_name} is ready to assist you on calls</h4>
                    <div>
                        <h5 style={{ textAlign: 'center', fontWeight: '400' }} > You can call {botData?.params?.bot_name} here to talk</h5>
                    </div>

                    <div style={{ paddingTop: '20px', paddingBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 style={{ textAlign: 'center', textDecorationLine: 'underline', cursor: 'pointer', color: '#FF5757', width: '150px' }} onClick={() => navigate(`${process.env.PUBLIC_URL}/app/manage-phone-numbers`)} > Add Phone </h5>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                            <Button style={{ width: '200px' }} color='primary' onClick={() => { setCallingModal(false) }} >Okay</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewAssistant