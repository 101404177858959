import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { getBotByCompany, getBotRaw } from '../../../api/AuthIndex';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import profileImage from '../../../assets/images/user/bot.png';
import botImageMale from '../../../assets/images/user/botImageMale.jpeg';
import botImageFemale from '../../../assets/images/user/botImageFemale.jpeg';
import botImage from '../../../assets/images/user/meh.png';
import startTalk from '../../../assets/images/user/startTalk.png';
import { H5 } from '../../../AbstractElements';
import Vapi from '@vapi-ai/web';
import { RxCross2 } from "react-icons/rx";
import ApiContext from '../../../ApiContext';
import { useSelector } from 'react-redux';
import { selectCompleteCompany } from '../../../Store/modules/company/companySelectors';

const Notificationbar = () => {
  const { isUpdated, setUpdateStatus, companyDetail } = useContext(ApiContext);
  const companies = JSON.parse(localStorage.getItem('companies'))
  const getCompanyInfo = JSON.parse(localStorage.getItem('companyData'));

  const completeCompany = useSelector(selectCompleteCompany);
  const allBots = completeCompany?.company?.company?.assistant
  console.log("🚀 ~ Notificationbar ~ allBots:", allBots)

  let token = localStorage?.getItem('token');
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [botList, setBotList] = useState([]);
  const [botModal, setBotModal] = useState(false);
  const [botData, setBotData] = useState(null);
  const [botRawData, setBotRawData] = useState(null);
  const [vapiModal, setVapiModal] = useState(false);

  const [loading, setLoading] = useState(false)

  const vapiRef = useRef(null);

  useEffect(() => {
    // getAllBots();
  }, [setUpdateStatus]);

  useEffect(() => {
    if (vapiModal && botRawData) {
      vapiRef.current = new Vapi("83e82473-a8f5-45a9-9607-8bb239c672c3");
      try {
        vapiRef.current.start(botRawData);
      } catch (error) {
        console.error('Error starting Vapi:', error);
      }
    }
  }, [vapiModal, botRawData]);

  const getAllBots = async () => {
    setBotList([]);
    try {
      const formData = new FormData();
      formData.append("company_id", getCompanyInfo?.id);
      const res = await getBotByCompany(formData, token);
      console.log("🚀 ~ getAllBots ~ res:", res)
      setBotList(res?.bots);
    } catch (error) {
      console.error('Error fetching bots:', error);
    }
  };

  const handleBotModal = (data) => {
    console.log("🚀 ~ handleBotModal ~ data:", data)
    setBotData(data);
    setBotModal(true);
  };

  const handleStartTalking = async (data) => {
    console.log("🚀 ~ handleStartTalking ~ data:", data)
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append("bot_id", data?.bot_id);
      formData.append("customer_phone", data?.phone_number);
      const res = await getBotRaw(formData, token);
      setBotRawData(res);
      setLoading(false)
      setBotModal(false);
      setVapiModal(true);
    } catch (error) {
      console.error('Error starting Vapi:', error);
      setLoading(false)
    }
  };

  const handleEndedCall = () => {
    if (vapiRef.current) {
      vapiRef.current.stop();
    }
    setVapiModal(false);
  }

  return (
    <>

      <li className='onhover-dropdown' id={'dashboard-step3'}>
        <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}>
          {/* <SvgIcon iconId='notification' /> */}
          <img style={{ width: '25px', height: '25px', borderRadius: '10px', position: 'relative' }} src={botImage} />
          <span style={{ position: 'absolute', right: '6px' }} className='badge rounded-pill badge-secondary'>{allBots?.length}</span>
        </div>
        <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
          <h6 style={{ textAlign: 'left' }} className='f-18 mb-0 dropdown-title'>Active Assistants</h6>
          {allBots?.map((item) => (
            <ul key={item.id}>
              <li className='b-l-primary border-4'>
                <p style={{ fontSize: '14px', fontWeight: '500', textTransform: 'capitalize' }} onClick={() => handleBotModal(item)}>
                  Talk to {item?.bot_name}
                </p>
              </li>
            </ul>
          ))}
        </div>


        <Modal
          show={botModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setBotModal(false)}
          dialogClassName="custom-modal-position"
          backdrop={false}
        >
          <Modal.Body style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#5679D5', padding: '10px 20px 0px 20px' }}>
              <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
              <RxCross2 color='white' size={20} cursor={'pointer'} onClick={() => setBotModal(false)} />
            </div>
            <div style={{ display: 'flex', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', padding: '10px 0px' }}>
              <img style={{ width: '250px', height: '250px', borderRadius: '50%', justifySelf: 'center' }} src={botData?.picture ? botData?.picture : botData?.gender === 'M' ? botImageMale : botImageFemale} />
              {/* <div style={{ marginLeft: '10px' }}>
                  <H5>{botData?.bot_name}</H5>
                  <p style={{ fontWeight: '500', fontSize: '12px', textAlign: 'left', lineHeight: 1.2, marginTop: '-5px' }}>
                    Once you click the button, the assistant will be available to talk to you
                  </p>
                </div> */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button disabled={loading} color='primary' style={{ height: '43px', width: '100%', fontSize: '16px' }} onClick={() => handleStartTalking(botData)}>
                Start talking
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={vapiModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setVapiModal(false)}
          dialogClassName="custom-modal-position"
          backdrop={false}
        >
          <Modal.Body style={{ backgroundColor: 'white', overflow: 'hidden', padding: '0px', borderRadius: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#5679D5', padding: '10px 20px 0px 20px' }}>
              <H5 attrH5={{ style: { color: 'white' } }}>Talk to {botData?.bot_name}</H5>
              <RxCross2 color='white' cursor={'pointer'} size={20} onClick={() => handleEndedCall()} />
            </div>
            <div style={{ display: 'flex', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', padding: '10px 0px' }}>
              <iframe
                src="https://gifer.com/embed/LCPT"
                width={450}
                height={300}
                frameBorder="0"
                style={{ pointerEvents: 'none' }} // Disable interaction
              ></iframe>
            </div>
            {/* <div style={{ display: 'flex' }}>
              <img style={{ width: '50px', height: '50px', borderRadius: '50px' }} src={profileImage} />
              <div style={{ marginLeft: '10px' }}>
                <H5>{botData?.bot_name}</H5>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontWeight: '500', fontSize: '12px', textAlign: 'left', lineHeight: 1.2, marginTop: '-5px' }}>
                    {botData?.bot_name} is talking now
                  </p>
                  <svg width="25px" height="23px" viewBox="0 0 10 8" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '8px', marginTop: '-20px' }}>
                    <g id="Audio" transform="translate(0.000000, 0.500000)" stroke="currentColor" stroke-width="1" fill-rule="evenodd" stroke-linecap="round">
                      <line x1="8.5" y1="0.493135" x2="8.5" y2="6.50687" id="Line-5">
                        <animate attributeType="XML" attributeName="y1" values="2;0;2" keyTimes="0;0.5;1" dur=".8s" repeatCount="indefinite"></animate>
                        <animate attributeType="XML" attributeName="y2" values="5;7;5" keyTimes="0;0.5;1" dur=".8s" repeatCount="indefinite"></animate>
                      </line>
                      <line x1="6.5" y1="0.789016" x2="6.5" y2="6.21098" id="Line-4">
                        <animate attributeType="XML" attributeName="y1" values="0;2;0" keyTimes="0;0.5;1" dur=".5s" repeatCount="indefinite"></animate>
                        <animate attributeType="XML" attributeName="y2" values="7;5;7" keyTimes="0;0.5;1" dur=".5s" repeatCount="indefinite"></animate>
                      </line>
                      <line x1="4.5" y1="1.67582" x2="4.5" y2="5.32418" id="Line-3">
                        <animate attributeType="XML" attributeName="y1" values="1;3;1" keyTimes="0;0.5;1" dur=".6s" repeatCount="indefinite"></animate>
                        <animate attributeType="XML" attributeName="y2" values="6;4;6" keyTimes="0;0.5;1" dur=".6s" repeatCount="indefinite"></animate>
                      </line>
                      <line x1="2.5" y1="1.14678" x2="2.5" y2="5.85322" id="Line-2">
                        <animate attributeType="XML" attributeName="y1" values="2;1;2" keyTimes="0;0.5;1" dur=".7s" repeatCount="indefinite"></animate>
                        <animate attributeType="XML" attributeName="y2" values="5;6;5" keyTimes="0;0.5;1" dur=".7s" repeatCount="indefinite"></animate>
                      </line>
                      <line x1="0.5" y1="1.67582" x2="0.5" y2="5.32418" id="Line-1">
                        <animate attributeType="XML" attributeName="y1" values="3;0;3" keyTimes="0;0.5;1" dur=".9s" repeatCount="indefinite"></animate>
                        <animate attributeType="XML" attributeName="y2" values="4;7;4" keyTimes="0;0.5;1" dur=".9s" repeatCount="indefinite"></animate>
                      </line>
                    </g>
                  </svg>
                </div>
              </div>
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button color='primary' style={{ height: '43px', width: '100%', fontSize: '16px' }} onClick={() => handleEndedCall()}>
                Stop talking
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </li>


    </>
  );
};

export default Notificationbar;
