import {Modal, Spinner} from 'react-bootstrap';
import phoneIcon from '../../../../../assets/images/user/phoneIcon.png';
import {H4, P} from '../../../../../AbstractElements';
import {Button} from 'reactstrap';
import {SlLocationPin} from "react-icons/sl";
import {AiOutlineDollar} from "react-icons/ai";

import {RxCross2} from "react-icons/rx";
import {Color} from "../../../../../Constant";
import {bookNumber} from "../../../../../api/Company";
import {getCurrentCompany} from "../../../../../helpers/LocalStorageHelper";
import {showToast} from "../../../../../helpers/ToastHelper";
import {TOAST_TYPES} from "../../../../../helpers/constants";
import {setFieldValues} from "../../../../../Store/modules/assistant/create/form/createAssistantFormSlice";
import {useDispatch} from 'react-redux';
import {ActionTypes} from "../../../../../Store/redux-store/ActionTypes";

export const BookNumberModal = (props) => {
    const {
        bookNumberModal,
        setBookNumberModal,
        bookNumberModalData,
        tollFree,
        tollButtonLoader
    } = props;
    const dispatch = useDispatch();
    const handleBookNumber = async (data) => {
        const company = getCurrentCompany();
        try {
            const formData = new FormData()
            formData.append('company_id', company?.id)
            formData.append('phone_number', data?.phone_number)
            const token = localStorage.getItem('token');
            const res = await bookNumber(token, formData);
            if (res?.status) {
                dispatch(setFieldValues({
                    phone_number: res?.phone,
                    provider_sid: res?.ACCOUNT_SID,
                    provider_auth_token: res?.AUTH_TOKEN,
                }));
                dispatch({type: ActionTypes.SETUP_ASSISTANT_PHONE});
            } else {
                throw Error('Unable to get this phone number');
            }
        } catch (error) {
            showToast({type: TOAST_TYPES.DANGER, message: error?.message ?? 'Unable to book the number.'});
        } finally {
            setBookNumberModal(false);
        }
    }

    return (
        <Modal
            show={bookNumberModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setBookNumberModal(false)}
            className='modal-adjust'

        >
            <Modal.Body style={{}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                            <RxCross2 size={20} cursor={'pointer'} onClick={() => {
                                setBookNumberModal(false)
                            }}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                            <img src={phoneIcon} style={{width: '100px', height: '100px',}} alt={'phone-icon'}/>
                        </div>
                        <H4 attrH4={{style: {textAlign: 'center', marginTop: '10px'}}}>Your Assigned Phone
                            Number</H4>
                        <P attrPara={{style: {textAlign: 'center', marginTop: '10px'}}}>Your chosen phone number
                            will be linked to your AI Assistant upon purchase.</P>
                        <div style={{marginLeft: '8px'}}>
                            <p style={{
                                fontWeight: '500',
                                fontSize: '22px',
                                textAlign: 'center',
                                marginTop: '10px',
                                color: Color.SECONDARY
                            }}>{bookNumberModalData?.friendly_name}</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                justifySelf: 'center'
                            }}>
                                <div style={{display: 'flex', width: '100%'}}>
                                    <SlLocationPin size={20} color={Color.SECONDARY}/>
                                    <p style={{
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        width: '200px',
                                        marginLeft: '5px',
                                        marginTop: '-5px'
                                    }}>{bookNumberModalData?.locality === null ? 'N/A' : bookNumberModalData?.locality}</p>
                                </div>
                                <div style={{display: 'flex', width: '600px',}}>
                                    <AiOutlineDollar size={20} color={Color.SECONDARY}/>
                                    <p style={{
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        marginLeft: '5px',
                                        marginTop: '-3px'
                                    }}>{tollFree ? 'Phone Number Fee: $2.50/Month' : 'Phone Number Fee: $1.50/Month'}</p>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {tollButtonLoader ?
                                    <P attrPara={{
                                        style: {
                                            color: Color.PRIMARY,
                                            textDecorationLine: 'underline',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            fontSize: '18px'
                                        }
                                    }}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Booking...</P>
                                    :
                                    <Button color="link" style={{
                                        color: Color.PRIMARY,
                                        textDecorationLine: 'underline',
                                        fontWeight: '500',
                                        textAlign: 'center',
                                        fontSize: '18px'
                                    }} onClick={() => handleBookNumber(bookNumberModalData)}>Book Number</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}
