import { createSlice } from "@reduxjs/toolkit";

const callLogSlice = createSlice({
  name: "callLog",
  initialState: {
    callLog: null,
    tableData: [],
    chartData: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchCallLog: (state) => {
      state.loading = true;
      state.callLogError = null;
    },
    fetchCallLogSuccess: (state, action) => {
      state.loading = false;
      state.callLog = action.payload;
    },
    setTableData: (state, action) => {
      state.loading = false;
      state.tableData = action.payload;
    },
    setChartData: (state, action) => {
      state.loading = false;
      state.chartData = action.payload;
    },
    fetchCallLogError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setTableData,
  setChartData,
  fetchCallLog,
  fetchCallLogSuccess,
  fetchCallLogError,
} = callLogSlice.actions;

export default callLogSlice.reducer;
