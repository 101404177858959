import { put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../redux-store/ActionTypes";
import {setRightSidebarContent, setSelectedAssistant} from "./assistantSlice";

function* setAssistantSidebarSaga(action) {
    yield put(setRightSidebarContent(action.payload));
}

function* setSetSelectedAssistantSaga(action) {
    yield put(setSelectedAssistant(action.payload));
}

export function* setAssistantSidebar() {
    yield takeLatest(ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR, setAssistantSidebarSaga);
}

export function* selectAssistant() {
    yield takeLatest(ActionTypes.SET_SELECTED_ASSISTANT, setSetSelectedAssistantSaga);
}


