import { call, put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../redux-store/ActionTypes";
import {getPaymentMethod} from "./getPaymentMethod";
import {
    gettingPaymentMethod,
    paymentMethodFetchError,
    paymentMethodSuccess, startUpdatingPaymentMethod,
    updatePaymentMethodSuccess
} from "./stripeSlice";
import {updateUserPaymentMethod} from "./updateUserPaymentMethod";
import {showToast} from "../../../helpers/ToastHelper";
import {TOAST_TYPES} from "../../../helpers/constants";

function* getPaymentMethodSaga(action) {
    try {
        yield put(gettingPaymentMethod());
        const data = yield call(getPaymentMethod, action.payload);
        yield put(paymentMethodSuccess(data));
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(paymentMethodFetchError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

function* updatePaymentMethodSaga(action) {
    try {
        yield put(startUpdatingPaymentMethod());
        const data = yield call(updateUserPaymentMethod, action.payload);
        yield put(updatePaymentMethodSuccess(data));
        yield put({ type: ActionTypes.GET_USER_PAYMENT_METHOD });
        showToast({message: data?.message});
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(paymentMethodFetchError(error.message));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* getPaymentMethodData() {
    yield takeLatest(ActionTypes.GET_USER_PAYMENT_METHOD, getPaymentMethodSaga);
}

export function* updatePaymentMethod() {
    yield takeLatest(ActionTypes.UPDATE_USER_PAYMENT_METHOD, updatePaymentMethodSaga);
}


