import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import { Breadcrumbs } from '../../AbstractElements'
import defaultlogo from '../../assets/images/defaultlogo.png'
import noCompany from '../../assets/images/noCompany.png'
import { formatMobileNumber } from '../../Constant/GlobalFunctions'
import ApiContext from '../../ApiContext';
import { getUserCompany } from '../../api/AuthIndex'

const MultipleCompanies = () => {
    const { isUpdated, setUpdateStatus, allCompanies, setAllCompanies, setCompanyDetail } = useContext(ApiContext);
    const navigate = useNavigate();

    const location = useLocation()
    const pathName = location.pathname;


    const getCompanyInfo = JSON.parse(localStorage.getItem("companyData"));
    const userData = JSON.parse(localStorage.getItem('auth0_profile'));
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (pathName === "/app/companies") {
            getUserCompanyInfo()
            console.log("🚀 ~ MultipleCompanies ~ pathName:", pathName)
        }
    }, [isUpdated]);

    const getUserCompanyInfo = async () => {
        let res = await getUserCompany(token, getCompanyInfo?.id);
        localStorage.setItem("companies", JSON.stringify(res.Company));
        setAllCompanies(res.Company)
    };

    const companies = allCompanies || []

    if (companies?.length === 1) {
        console.log("Running");
        setUpdateStatus(true);
        setCompanyDetail(localStorage.setItem("companyData", JSON.stringify(companies[0])));
        navigate('/app/dashboard');
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Companies" />
            <Container fluid={true} style={{ backgroundColor: '#f7f8f9', padding: '10px 20px', minHeight: '80vh', overflow: 'scroll' }}>
                {companies.length === 0 &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col sm='10'>
                            <Col sm='12' style={{ backgroundColor: '#F95A83' }}>
                                <div style={{ padding: '10px' }}>
                                    <p style={{ color: 'white', fontSize: '16px', fontWeight: '500' }}>Well done!</p>
                                    <p style={{ color: 'white', lineHeight: 0.5 }}>Company is the main identify in Fluten. Please click here to setup your company</p>
                                </div>
                            </Col>
                            <Col sm='12' style={{ padding: '10px' }}>
                                <img style={{ width: '90%' }} src={noCompany} />
                            </Col>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => navigate('/app/setup-company', { state: { goto: true } })}>Setup Company Now</Button>
                            </div>
                        </Col>
                    </div>
                }

                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    {companies?.map((item, index) => {
                        return (
                            <div key={index} style={{ width: '350px', height: '270px', margin: '5px' }}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                                <div className='social-img'>
                                                    {item?.upload_logo ?
                                                        <img style={{ width: '75px', height: '75px', borderRadius: '50px' }} src={`${process.env.REACT_APP_IMAGE_URL}${item?.upload_logo}`} alt="Company Logo" />
                                                        :
                                                        <img style={{ width: '75px', height: '75px', borderRadius: '50px' }} src={defaultlogo} alt="Default Logo" />
                                                    }
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', borderRadius: '10px', alignItems: 'center', width: '100%', marginTop: '20px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                    <div style={{ width: '100%', lineHeight: 0.5 }}>
                                                        <h4 style={{ marginBottom: '3px', textAlign: 'center', fontSize: '22px', color: 'black', fontWeight: 'bold', cursor: 'pointer' }}>{item?.company_name}</h4>
                                                        <div>
                                                            <p style={{ color: 'grey', lineHeight: 0.5, textAlign: 'center' }}>{item?.state}</p>
                                                            <p style={{ color: 'grey', lineHeight: 0.5, textAlign: 'center' }}>{formatMobileNumber(item?.official_phone)}</p>
                                                            <p style={{ color: 'grey', lineHeight: 0.5, textAlign: 'center' }}>{item?.email}</p>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Button style={{ marginTop: '20px', width: '150px' }}
                                                                onClick={() => {
                                                                    setUpdateStatus(!isUpdated);
                                                                    setCompanyDetail(localStorage.setItem("companyData", JSON.stringify(item)))
                                                                    navigate('/app/dashboard');
                                                                }}
                                                            >
                                                                Select
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </Fragment >
    )
}

export default MultipleCompanies;
