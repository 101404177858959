import React, { Fragment } from 'react'
import { Container } from 'reactstrap'
import { Breadcrumbs, H5 } from '../../AbstractElements'
import Calendar from './Calendar'

const ScheduleAssistant = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Schedule Assistant" />
            <Container fluid={true}>
                <Calendar />
            </Container>
        </Fragment>
    )
}

export default ScheduleAssistant