import React, { useState, useEffect, useRef } from "react";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import uuid from "react-uuid";
import { companySchedule, confirmBooking } from "../../api/AuthIndex";
import './Calendar.css'
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const Calendar = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    let token = localStorage?.getItem('token')
    const [calendarEvents, setCalendarEvents] = useState();
    const [modal, setModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(false)
    const [bookings, setBookings] = useState(false)

    useEffect(() => {
        getSchedule()
    }, [])


    const getSchedule = async () => {
        const endDate = moment().format('YYYY-MM-DD');
        const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');

        const formData = new FormData();
        formData.append('company_id', getCompanyInfo.id);
        formData.append('start_date', '2024-08-11');
        formData.append('end_date', '2024-09-11');

        let res = await companySchedule(token, formData);
        setCalendarEvents(res?.bookings?.map((item) => {
            return {
                booking_id: item.id,
                name: item?.customer_name,
                start: moment(item?.booking_date).format('YYYY-MM-DD'),
                id: uuid(),
                other_params: item?.other_params,
                confirmed: item?.confirmed,
                title: "1 Booking"
            }
        }))
    };

    const toggleModal = () => setModal(!modal);

    const handleUnconfirmedClick = (event) => {
        console.log("🚀 ~ handleUnconfirmedClick ~ event:", event)
        setSelectedEvent(event);
        toggleModal();
    };

    const handleYes = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('booking_id', selectedEvent?.booking_id)
            const res = await confirmBooking(token, formData)
            toast.info("Successfully logged in!..", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            setLoading(false)
            toggleModal();
        } catch (error) {
            console.log("🚀 ~ handleYes ~ error:", error)
            toast.error("Something went wrong", {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
            setLoading(false)
        }

    };

    const handleCloseModal = () => setModal(false);


    const events = [
        { title: "Birthday Party", id: "1", icon: "birthday-cake" },
        { title: "Meeting With Team.", id: "2", icon: "user" },
        { title: "Tour & Picnic", id: "3", icon: "plane" },
        { title: "Reporting Schedule", id: "4", icon: "file-text" },
        { title: "Lunch & Break", id: "5", icon: "briefcase" },
    ];

    const calendarComponentRef = useRef(null);

    useEffect(() => {
        // let draggableEl = document.getElementById("external-events");
        // new Draggable(draggableEl, {
        //     itemSelector: ".fc-event",
        //     eventData: function (eventEl) {
        //         let title = eventEl.getAttribute("title");
        //         let id = eventEl.getAttribute("data");
        //         return {
        //             title: title,
        //             id: id,
        //         };
        //     },
        // });
    }, []);

    const eventClick = (eventClick) => {
        Swal.fire({
            title: eventClick.event.title,
            html:
                `<div class="table-responsive">
                <table class="table">
                <tbody>
                <tr>
                <td>Title</td>
                <td><strong>` +
                eventClick.event.title +
                `</strong></td>
                </tr>
                <tr>
                <td>Start Time</td>
                <td><strong>
                `
                + moment(eventClick.event.start).format('MM-DD-YYYY hh:mm a') +
                `
                </strong></td>
                </tr>
                </tbody>
                </table>
                </div>`,
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove Event",
            cancelButtonText: "Close",
        }).then((result) => {
            if (result.value) {
                eventClick.event.remove(); // It will remove event from the calendar
                Swal.fire("Deleted!", "Your Event has been deleted.", "success");
            }
        });
    };

    const handleEventReceive = (info) => {
        const newEvent = {
            title: info.draggedEl.getAttribute("title"),
            start: info.date,
            end: new Date(moment(info.date).add(1, "hour").valueOf()),
            id: uuid(),
        };
        setCalendarEvents([...calendarEvents, newEvent]);
    };

    const renderEventContent = (eventInfo) => {
        console.log("🚀 ~ renderEventContent ~ eventInfo:", eventInfo)
        return (
            <div style={{ backgroundColor: '#F73164', padding: '2px 10px', borderRadius: '5px', marginLeft: '2px',cursor:'pointer' }} onClick={() => setBookings(true)}>
                <p style={{ color: 'white', fontSize: '12px', fontWeight: '500', textAlign: 'center' }}>{eventInfo.event.title}</p>
            </div>
        );
    };

    return (
        <Row>
            <Col sm={bookings ? "8" : "12"} className="box-col-12">
                <Card>
                    <CardBody>
                        <div className="demo-app-calendar" id="mycalendartest">
                            <FullCalendar
                                defaultView="dayGridMonth"
                                // header={{
                                //     left: "prev,next today",
                                //     center: "title",
                                //     right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                // }}
                                rerenderDelay={10}
                                eventDurationEditable={false}
                                editable={false}
                                droppable={false}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={calendarComponentRef}
                                weekends={true}
                                events={calendarEvents}
                                // drop={handleEventReceive}
                                // eventClick={eventClick}
                                eventOverlap={true}
                                eventContent={renderEventContent} // Custom event rendering
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            {bookings && 
            <Col sm="4" className="box-col-12">
            <Card style={{ padding: '0px' }}>
                <CardBody style={{ padding: '20px' }}>

                    {calendarEvents?.map((item, index) => {
                        const otherData = JSON.parse(item.other_params)
                        return (
                            <div
                                key={index}
                                style={{
                                    marginBottom: '10px',
                                    borderBottom: '1px solid lightgrey',
                                    padding: '0px 0px 10px 0px',
                                    borderRadius: '5px'
                                }}
                                onClick={() => {
                                    // handleBotClick(data);
                                }}
                            >
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <p style={{ lineHeight: 1, fontSize: '16px', fontWeight: '500' }}>Bookings</p>
                                    <p style={{ lineHeight: 1, marginTop: '-10px' }}>{moment(item.start).format('MMMM DD')}</p>
                                </div>
                                <p style={{ lineHeight: 1, fontSize: '16px', fontWeight: '500', color: '#7366FF' }}>{item.name}</p>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <p style={{ lineHeight: 1, fontSize: '16px', fontWeight: '500' }}>Time</p>
                                        <p style={{ lineHeight: 1, fontSize: '16px', fontWeight: '500' }}>Guests</p>
                                    </div>

                                    <div style={{ marginLeft: '50px', width: '100%' }}>
                                        <p style={{ lineHeight: 1 }}>{moment(item.start).format('hh:mm a')}</p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <p style={{ lineHeight: 1 }}>{otherData.Guests}</p>
                                            <div
                                                style={{
                                                    border: item.confirmed == 0 ? '1px solid #EFEDFF' : '1px solid #54BA4A',
                                                    backgroundColor: item.confirmed == 0 ? '#F7F6FF' : '#54BA4A',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '5px 10px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    if (item.confirmed == 0) {
                                                        handleUnconfirmedClick(item)
                                                    }
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: '500',
                                                        color: item.confirmed == 0 ? 'black' : 'white',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {item.confirmed == 0 ? 'Unconfirmed' : 'Confirmed'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </CardBody>
            </Card>
        </Col>
        }
            

            <Modal show={modal} centered onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col sm="12">
                        <CardBody>
                            <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '500' }}>Are you sure?</p>
                        </CardBody>
                    </Col>
                </Modal.Body>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                        {loading ?
                            <Button color="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </Button>
                            :
                            <Button color="primary" style={{ height: '40px' }} onClick={handleYes}>
                                Yes
                            </Button>
                        }
                        <Button variant="secondary" style={{ height: '40px' }} onClick={handleCloseModal}>
                            No
                        </Button>
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </Row>
    );
};

export default Calendar;
