export const genderRadioButtonsStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        color: '#5978d4',
        border: '0.5px solid lightgrey',
        boxShadow: 'none',
        '&:hover': {
            border: '0.5px solid lightgrey',
        },
        marginTop: '5px',
        padding: '5px',
        width: '100%'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected && '#5978d4',
        '&:hover': {
            backgroundColor: 'rgba(89, 120, 212, 0.2)',
        },
        fontSize: '11px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'black',
        '&:hover': {
            color: 'black',
        },
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
};
