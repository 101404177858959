import {Modal} from 'react-bootstrap';
import {ImCross} from "react-icons/im";
import {setFieldValue} from "../../../../Store/modules/assistant/create/form/createAssistantFormSlice";
import {useDispatch, useSelector} from 'react-redux';

export const AssistantBioModal = (props) => {
    const dispatch = useDispatch();
    const {showModal, setShowAssistantBioModal, assistantBio, setAssistantBio} = props;
    const {values, errors, isSubmitting} = useSelector((state) => state.createAssistantForm);

    const handleChange = (field, value) => {
        dispatch(setFieldValue({field, value}));
    };
    return (
        <Modal show={showModal} centered size='lg' onHide={() => setShowAssistantBioModal(false)}>
            <Modal.Body>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Modal.Title style={{textAlign: 'left'}}>Assistant Bio</Modal.Title>
                    <ImCross size={15} onClick={() => setShowAssistantBioModal(false)} style={{cursor: 'pointer'}}/>
                </div>
                <textarea
                    className="form-control"
                    rows="20"
                    placeholder="Enter Bio"
                    value={values?.prompt}
                    onChange={(e) => handleChange('prompt', e.target.value)}
                />
            </Modal.Body>
        </Modal>
    );
}
