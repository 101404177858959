import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Modal } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Color } from '../../helpers/colors';
import { useNavigate } from 'react-router-dom';
import rocket from '../../assets/images/user/rocket.png';
import combineShape from '../../assets/images/user/combineShape.png';

const UpgradePopup = ({
    isOpen,
    toggler,
    title = 'Upgrade Required',
    description = 'To unlock additional assistants, please upgrade your package for enhanced support and functionality',
    closeLabel = 'Upgrade',
    onClick = () => { },
    bodyClass = '',
    size = 'md',
}) => {
    const navigate = useNavigate();

    const handleUpgrade = () => {
        onClick();
        toggler(false);
        navigate('/app/settings?tab=subscription');
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={(val) => toggler(val)}
            size={size}
            centered
            backdrop="static"
            className="upgrade-popup-modal"
            style={{ position: 'relative', width: '380px' }}
        >
            <div style={{ width: '100%', backgroundColor: 'black', overflow: 'hidden', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', }}>
                <div style={styles.backgroundImage}></div>
            </div>
            <div style={styles.rocketContainer}>
                <img
                    src={rocket}
                    alt="Rocket"
                    style={styles.rocketImage}
                />
            </div>

            <div style={styles.header}>
                <h5 style={styles.title}>{title}</h5>
            </div>

            <div className={bodyClass} style={styles.body}>
                <p style={styles.description}>{description}</p>
            </div>
            <div className={bodyClass} style={styles.divider}>
                <hr style={{ padding: '0px 20px' }} />
            </div>

            <div style={styles.footer}>
                <button style={styles.discardButton} onClick={() => toggler(false)}>
                    Discard
                </button>
                {/* <Btn
                    attrBtn={{
                        coor: 'white',
                        onClick: () => toggler(false),
                        style: styles.discardButton,
                    }}
                >

                </Btn> */}
                <Btn
                    attrBtn={{
                        color: 'primary',
                        onClick: handleUpgrade,
                        style: styles.upgradeButton,
                    }}
                >
                    {closeLabel}
                </Btn>
            </div>
        </Modal>
    );
};

// Styles
const styles = {
    backgroundImage: {
        // position: 'absolute',
        top: 10,
        left: 0,
        width: '102%',
        height: '200px',
        backgroundImage: `url(${combineShape})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        overflow: 'hidden',
        marginLeft: '-5px'
    },
    rocketContainer: {
        position: 'absolute',
        top: '-100px', 
        right: '-55px',
        zIndex: 1051, 
    },
    rocketImage: {
        width: '240px', // Adjust size as necessary
        height: '250px',
        transform: 'rotate(-3deg)'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        position: 'relative',
        zIndex: 3,
    },
    title: {
        color: Color.BLACK,
        margin: 0,
    },
    body: {
        padding: '0rem 1rem',
        position: 'relative',
        zIndex: 3, // Ensures it's above the background
    },
    description: {
        color: '#667085',
        padding: '0px',
        fontSize: '20px',
        fontWeight:'300',
        marginBottom:'0px'
    },
    divider: {
        padding: '0px 1rem',
        position: 'relative',
        zIndex: 3,
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // gap: '1rem', 
        padding: '0px 5px 0px 0px',
        marginLeft: '15px'
    },
    discardButton: {
        backgroundColor: 'white',
        color: '#000',
        border: '1px solid #D0D5DD',
        padding: '0.5rem 1.5rem',
        borderRadius: '6px',
        // fontWeight: 'bold',
        cursor: 'pointer',
        height: '50px',
        width: '50%',
        fontSize:'18px'
    },
    upgradeButton: {
        backgroundColor: '#4E73DF', // Blue color
        color: '#fff',
        border: 'none',
        // padding: '0.5rem 1.5rem',
        borderRadius: '6px',
        // fontWeight: 'bold',
        cursor: 'pointer',
        height: '50px',
        width: '50%',
        fontSize:'18px'
    },
};

export default UpgradePopup;
