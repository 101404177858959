import React, {Fragment, useEffect} from 'react'
import {Card, Col, Row, Spinner} from 'reactstrap';
import './styles/Stepper.css';
import roboImg from '../../../assets/images/logo/roboImg.png';
import {H4, H6} from '../../../AbstractElements';
import {ToastContainer} from "react-toastify";
import 'react-phone-number-input/style.css';
import '../ManageAssistant.css'
import {FaArrowLeft} from "react-icons/fa";
import {AI_ASSISTANT_RIGHT_SIDEBAR, CREATE_ASSISTANT_SCREENS, LANGUAGES_MAP} from '../../../helpers/constants';
import {Color} from '../../../helpers/colors';
import {useDispatch, useSelector} from 'react-redux';
import {useCreateAssistantContext} from "./context/CreateAssistantContext";
import {PrecastBotDetail} from "./steps/components/PrecastBotDetail";
import {SelectPrecastBotStep1} from "./steps/SelectPrecastBotStep1";
import {SelectPrecastBotStep2} from "./steps/SelectPrecastBotStep2";
import {CustomPrecastBotStep3} from "./steps/SelectPrecastBotStep3";
import {AssistantBioModal} from "./modals/AssistantBioModal";
import {StepperContainer} from "./steps/StepperContainer";
import {selectSelectedPrecastBot} from "../../../Store/modules/precastBots/precastBotSelectors";
import {isEmpty} from "../../../helpers/utils";
import SetupPhone from "./steps/PhoneSetup/SetupPhone";
import {PhoneSetupDetails} from "./steps/components/PhoneSetupDetails";
import {ImCancelCircle} from "react-icons/im";

import {
    selectDefaultBots,
    selectGettingDefaultBots
} from "../../../Store/modules/precastBots/getDefaultBots/defaultBotsSelectors";
import {getCurrentCompany} from "../../../helpers/LocalStorageHelper";
import {ActionTypes} from "../../../Store/redux-store/ActionTypes";
import {BasicInfoDetails} from "./steps/components/BasicInfoDetails";
import {KeywordInfoDetails} from "./steps/components/KeywordInfoDetails";

export const PrepareAssistant = () => {
    const {currentScreen, handleBack} = useCreateAssistantContext();
    const dispatch = useDispatch();
    const selectedPrecastBot = useSelector(selectSelectedPrecastBot);
    const defaultBots = useSelector(selectDefaultBots);
    const isGettingBotList = useSelector(selectGettingDefaultBots);


    const getDefaultBots = async () => {
        const company = getCurrentCompany();
        const language = LANGUAGES_MAP?.[0]?.lang_name;
        dispatch({
            type: ActionTypes.GET_PRECAST_BOTS,
            payload: {companyTypeId: company?.company_type?.id, lang: language}
        });
    }

    useEffect(() => {
        if (isEmpty(defaultBots)) {
            getDefaultBots();
        }
    }, []);


    const PrecastBotSteps = () => {
        return (<Card style={{
            boxShadow: '1px 1px 5px 0px lightgrey',
            height: 'auto',
            padding: '20px',
            margin: '0',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <H4 attrH4={{
                style: {
                    color: '#FF5757',
                    fontSize: '20px'
                }
            }}>Define Your AI Assistant Personality</H4>
            {<H6 attrH6={{
                style: {
                    color: '#FF5757',
                    fontWeight: '500',
                    fontSize: '14px'
                }
            }}> Pre-trained AI Assistants tailored to
                your business profile, ready for seamless integration.</H6>}

            {currentScreen === CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST &&
                (<SelectPrecastBotStep1/>)
            }

            {currentScreen === CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO && (
                <SelectPrecastBotStep2/>
            )}
            {currentScreen === CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO && (
                <CustomPrecastBotStep3/>
            )}
        </Card>)
    }

    const AiAssistantPersona = () => {
        return (<div style={{marginTop: '8px',}}>
            <H4 attrH4={{style: {color: Color.SECONDARY, fontSize: '1.3rem'}}}>AI Assistant Persona</H4>
            {isGettingBotList && isEmpty(selectedPrecastBot) ?
                <div style={{
                    width: '430px',
                    height: '500px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Spinner color="primary">
                        Loading...
                    </Spinner>
                </div>
                :
                <PrecastBotDetail/>
            }
        </div>);
    }

    const LeftSidebar = () => {
        switch (currentScreen) {
            case CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST:
                return <AiAssistantPersona/>;
            case  CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO:
                return <BasicInfoDetails/>;
            case CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO:
                return <KeywordInfoDetails/>;
            case CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER:
                return <PhoneSetupDetails/>;
            default:
                return <BasicInfoDetails/>;
        }
    };

    const cancelCreation = () => {
        dispatch({
            type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
            payload: {sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL}
        });
    }

    return (
        <Fragment>
            <div style={{padding: '2em', paddingTop: '1em', paddingBottom: '1em'}}>
                <Row>
                    <Col sm='12'>
                        <div style={{float: 'right', cursor: 'pointer'}} onClick={cancelCreation}>
                            <ImCancelCircle size={25} color={'#797979'}  />
                        </div>
                    </Col>
                    <Col sm='12'>
                        {/*<StepperContainer/>*/}
                    </Col>
                    <Col sm="6" className="col-no-scroll" style={{
                        position: 'relative',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 13,
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${roboImg})`,
                            backgroundPosition: 'bottom left',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '80%',
                            opacity: 0.5,
                            zIndex: 1
                        }}/>

                        <div style={{
                            position: 'relative',
                            zIndex: 2,
                            marginTop: '20px',
                            marginLeft: '1em'
                        }}>
                            <div onClick={handleBack} style={{
                                cursor: 'pointer',
                                backgroundColor: Color.SECONDARY,
                                padding: '10px',
                                width: '35px',
                                height: '35px',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <FaArrowLeft size={20} color={Color.WHITE}/>
                            </div>

                            <LeftSidebar/>
                        </div>
                    </Col>


                    <Col sm="6" style={{marginTop: '20px'}}>
                        <div style={{marginRight: '1em', marginTop: '2em',}}>
                            {currentScreen === CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER ? (
                                <SetupPhone/>
                            ) : (<PrecastBotSteps/>)}

                        </div>
                    </Col>
                </Row>
            </div>
            <AssistantBioModal/>
            <ToastContainer/>
        </Fragment>

    )
}

export default PrepareAssistant