import {P} from '../../../../../AbstractElements';
import {useDispatch, useSelector} from 'react-redux';
import {ActionTypes} from "../../../../../Store/redux-store/ActionTypes";
import {selectSelectedPrecastBot} from "../../../../../Store/modules/precastBots/precastBotSelectors";
import roboImg from '../../../../../assets/images/logo/roboImg.png';

export const PrecastBotTile = (props) => {
    const dispatch = useDispatch();
    const selectedPrecastBot = useSelector(selectSelectedPrecastBot);

    const {precastBotData} = props;
    return (
        <div
            key={precastBotData.id}
            onClick={() => {
                dispatch({type: ActionTypes.SET_SELECTED_PRECAST_BOT, payload: {precastBot: precastBotData}})
                // handleSelectBot(item)
                // setScanOption(null)
            }}
            style={{
                width: '100%',
                border: selectedPrecastBot?.id === precastBotData?.id ? '2px solid #5679D5' : 'none',
                cursor: 'pointer',
                display: 'flex',
                borderRadius: '10px',
                marginTop: '1em',
                padding: '15px 10px',
                backgroundColor: selectedPrecastBot?.id === precastBotData?.id ? 'rgba(236, 241, 254, 1)' : 'white'
            }}
        >
            <div
                style={{display: 'flex'}}>
                <div style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50px',
                    border: selectedPrecastBot?.id === precastBotData.id ? '4px solid #5679D5' : 'none'
                }}>
                    <img
                        src={precastBotData.picture && !precastBotData.picture.includes('/tmp/') ? precastBotData.picture : roboImg}
                        alt='loginpage'
                        style={{
                            width: '71px',
                            height: '71px',
                            borderRadius: '50px',
                        }}/>
                </div>
                <div
                    style={{marginLeft: '5px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <P attrPara={{
                            style: {
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '18px'
                            }
                        }}>{precastBotData?.bot_name}</P>
                    </div>
                    <P attrPara={{
                        style: {
                            textAlign: 'left',
                            fontWeight: '300',
                            // marginTop: '-15px',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: 1.2,
                        }
                    }}>
                        {precastBotData?.prompt}
                    </P>
                </div>
            </div>
        </div>
    );
}
