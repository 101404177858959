import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card, CardBody, } from 'reactstrap';
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import DataTable from 'react-data-table-component';
import '../../ManageAssistant/ManageAssistant.css'
import {
    useSearchParams
} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import NoScheduleFound from "../NoScheduleFound/NoScheduleFound";
import { useNavigate } from "react-router";
import CreateScheduleBot from "../CreateScheduleBot/CreateScheduleBot";
import EditScheduleBot from "../EditScheduleBot/EditScheduleBot";
import {isEmpty} from "../../../helpers/utils";
import ScheduleDetail from "../ScheduleDetail/ScheduleDetail";
import {AxiosInstance} from "../../../api/AxiosInstance";
import {getCurrentUser} from "../../../helpers/LocalStorageHelper";

const Schedules = () => {
    const navigate = useNavigate()
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const theme = localStorage.getItem("mix_background_layout");
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');

    const nylasCallback = async (code) => {
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        const response = await AxiosInstance.get('/calender/callback?code=' + code);

        return response.data;
    }

    const googleCallback = async (code) => {
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        const response = await AxiosInstance.get('/google/callback?code=' + code);

        return response.data;
    }

    const onConnectAccount = () => {
        const user = getCurrentUser();
        const emailToAuthenticate = user?.email;
        const responseType = 'code';
        const scopes = 'calendar.read_only';

        const CLIENT_ID = '7951ee62-4526-4631-be85-b76fa582e48c';
        // const REDIRECT_URI = `${process.env.REACT_APP_API_URL}/calender/callback`;
        // http://127.0.0.1:3000/app/schedule-assistant
        const REDIRECT_URI = `http://127.0.0.1:3000/app/schedule-assistant`;

        window.location = `https://api.us.nylas.com/v3/connect/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${responseType}&login_hint=${emailToAuthenticate}&scopes=${scopes}`
    };

    if(!isEmpty(code)) {
        googleCallback(code);
    }

    const onConnectAccount1 = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/google/auth`;
    }

    useEffect(()=>{
        getSchedule();
    },[])

    const [loader, setLoader] = useState(false);
    const [createSchedule, setCreateSchedule] = useState()
    const [fullData, setFullData] = useState([]);
    const [googleData,setGoogleData] = useState()

    const getSchedule = async () =>{
        setLoader(true)
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;;
        const response = await AxiosInstance.get(`/schedules?company_id=${getCompanyInfo?.id}`);
        console.log('Response:', response?.data?.data[0]);
        setGoogleData(response?.data?.googleUser)
        if(response?.data?.data?.length !== 0){
            setFullData(response?.data?.data)
            setCreateSchedule(false)
            setLoader(false)
        }else if(response?.data?.data?.length === 0){
            setFullData(response?.data?.data)
            setCreateSchedule(true)
            setLoader(false)
        }
        
        
    }


    return (
        <Fragment>
            <Breadcrumbs mainTitle="Schedule Assistant" />
            <Container fluid={true} className="rubik-font">
                <Row>
                    {loader ? (
                        <Col md="12">
                            <Card>
                                <CardBody style={{ padding: '10px' }}>
                                    <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                        <Skeleton height={10} count={22} />
                                    </SkeletonTheme>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : (
                        <Col md="12">
                            <Card>
                                <CardBody className="custTableComp">

                                    {/* <ScheduleDetail /> */}
                                    {!createSchedule && <EditScheduleBot EditClick={() => setCreateSchedule(true)} formData={fullData} />}
                                    {createSchedule && <CreateScheduleBot formData={fullData} googleData={googleData}/>}
                                    {/* <NoScheduleFound message={"No schedule's found"} /> */}
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Container>
            {/*<button onClick={onConnectAccount1}>  </button>*/}
        </Fragment>
    )
}

export default Schedules