import React, { useContext, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { getUserCompany } from '../api/AuthIndex';
import ApiContext from '../ApiContext';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../Store/redux-store/ActionTypes';

const clientId = '776344292705-5ovlo12b4o89ubguj33f4qms8r05fre7.apps.googleusercontent.com'; // Replace this with your actual Google Client ID

export const GoogleSignIn = () => {
    const { setAllCompanies } = useContext(ApiContext)
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [companies, setCompanies] = useState([])

    const handleLoginSuccess = async (response) => {
        const googleToken = response.credential;

        try {
            // Send the Google token to your Laravel backend for validation and sign-in
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/google-auth`, {
                token: googleToken,
            });
            console.log("🚀 ~ handleLoginSuccess ~ res:", res)

            // Handle the response (e.g., save the JWT from your backend)
            const { token } = res.data;
            localStorage.setItem('authToken', token);

            console.log("Google Auth Company Data", res.data);

            if (res.data.user.steps === 'completed') {
                getUserCompanyInfo(res.data)
                dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: res.data.companies[0].id } });
                // localStorage.setItem("companies", JSON.stringify(res?.data?.companies))
                // localStorage.setItem("companyData", JSON.stringify(res?.data?.companies[0]))
                // // setCompanies(res?.data?.companies)
                // setAllCompanies(res?.data?.companies)

                // localStorage.setItem("Name", res?.data?.companies.user.name);
                // localStorage.setItem("token", res?.data?.companies.token);
                // localStorage.setItem("auth0_profile", JSON.stringify(res?.data?.companies.user));
                // localStorage.setItem("login", JSON.stringify(true));
                // localStorage.setItem("authenticated", JSON.stringify(true));
                // localStorage.setItem("tokken", res?.data?.companies.token);
                // localStorage.removeItem("companyInfo");
                // if (res?.data?.companyCount === 1) {
                //     navigate(`${process.env.PUBLIC_URL}/app/dashboard`);
                //     console.log("GoogleAuth Dashboard ---");
                // } else {
                //     navigate(`${process.env.PUBLIC_URL}/app/companies`);
                //     console.log("GoogleAuth Companies");
                // }
            } else {
                localStorage.setItem("register", JSON.stringify(res.data))
                navigate(`${process.env.PUBLIC_URL}/business`);
            }

            // Redirect or do something after successful login
            console.log('Login Success:', token);
        } catch (error) {
            console.error('Error during Google login', error);
        }
    };

    let getUserCompanyInfo = async (userInfo) => {
        try {
            let res = await getUserCompany(userInfo?.token, userInfo?.companies[0]?.id);
            console.log("🚀 ~ getUserCompanyInfo ~ res:", res)
            localStorage.setItem("companies", JSON.stringify(res.Company))
            localStorage.setItem("companyData", JSON.stringify(res?.Company[0]))
            setCompanies(res.Company)
            setAllCompanies(res.Company)

            localStorage.setItem("Name", userInfo.user.name);
            localStorage.setItem("token", userInfo.token);
            localStorage.setItem("auth0_profile", JSON.stringify(userInfo.user));
            localStorage.setItem("login", JSON.stringify(true));
            localStorage.setItem("authenticated", JSON.stringify(true));
            localStorage.setItem("tokken", userInfo.token);
            localStorage.removeItem("companyInfo");


            setTimeout(() => {
                if (res?.Company?.length === 1) {
                    navigate(`${process.env.PUBLIC_URL}/app/dashboard`);
                    console.log("GoogleAuth Dashboard");
                } else {
                    navigate(`${process.env.PUBLIC_URL}/app/companies`);
                    console.log("GoogleAuth Companies");
                }
            }, 1500);
        } catch (error) {
            console.log("🚀 ~ getUserCompanyInfo ~ error:", error)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                '& span': {
                    fontSize: '14px',
                    fontWeight: '400',
                },
                '& > div': {
                    height: '56px',
                },
            }}
        >
            <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={() => console.log('Login Failed')}
                />
            </GoogleOAuthProvider>
        </div>
    );
};
