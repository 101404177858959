import {useState} from "react";
import {AI_ASSISTANT_RIGHT_SIDEBAR, CREATE_ASSISTANT_SCREENS} from "../../../../helpers/constants";
import {ActionTypes} from "../../../../Store/redux-store/ActionTypes";
import {useDispatch, useSelector} from 'react-redux';
import {selectCustomPrecastBot} from "../../../../Store/modules/assistant/create/form/createAssistantFormSelectors";
import {setCustomPrecastBot} from "../../../../Store/modules/assistant/create/form/createAssistantFormSlice";

export const useCreateAssistant = () => {
    const dispatch = useDispatch();
    const customPrecastBot = useSelector(selectCustomPrecastBot);
    const [currentScreen, setCurrentScreen] = useState(CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST);

    const handleSetCustomPrecastBot = (value) => {
        dispatch(setCustomPrecastBot({customPrecastBot: value}))
    }

    const handleNext = () => {
        switch (currentScreen) {
            case CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST:
                dispatch({type: ActionTypes.GET_SELECTED_PRECAST_BOT});
                setCurrentScreen(CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER)
                break;
            case CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO:
                handleSetCustomPrecastBot(true);
                setCurrentScreen(CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO)
                break;
            case CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO:
                dispatch({type: ActionTypes.CREATE_PRECAST_BOT});
                handleSetCustomPrecastBot(true);
                setCurrentScreen(CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER)
                break;
            case CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER:
                dispatch({type: ActionTypes.SETUP_ASSISTANT_PHONE});
                // createBotApi Call here
                // setCurrentScreen(CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER)
                break;
            default:
                // do nothing
                break;
        }
    }

    const handleBack = () => {
        switch (currentScreen) {
            case CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST:
                dispatch({
                    type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
                    payload: {sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL}
                });
                break;
            case CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO:
                handleSetCustomPrecastBot(false);
                setCurrentScreen(CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST)
                break;
            case CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO:
                setCurrentScreen(CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_BASIC_INFO)
                break;
            case CREATE_ASSISTANT_SCREENS.CHOOSE_PHONE_PROVIDER:
                if (customPrecastBot) {
                    setCurrentScreen(CREATE_ASSISTANT_SCREENS.CUSTOM_PRECAST_ASSISTANT_KEYWORDS_INFO)
                } else {
                    setCurrentScreen(CREATE_ASSISTANT_SCREENS.PRECAST_ASSISTANTS_LIST)
                }
                break;
            default:
                // do nothing
                break;
        }
    }

    return {
        currentScreen,
        setCurrentScreen,
        handleNext,
        handleBack,
    }
};
