import {useCreateAssistant} from "./useCreateAssistant";
import {CreateAssistantContext} from "./CreateAssistantContext";

export const CreateAssistantProvider = ({children}) => {
    let values = useCreateAssistant();

    return (
        <CreateAssistantContext.Provider value={values}>
            {children}
        </CreateAssistantContext.Provider>
    );
};
