import LanguageSelect from "../../LanguageSelect";
import {
    FEMALE_VOICES,
    GENDER_ARR,
    LANGUAGES_MAP,
    MALE_VOICES,
    SHORT_GENDER,
    TOAST_TYPES
} from "../../../../helpers/constants";
import {Button, Col, Form, Spinner} from 'reactstrap';
import Select from 'react-select';
import {useCreateAssistantContext} from "../context/CreateAssistantContext";
import {customSelectStyles} from "../styles/customSelectStyles";
import botImage from '../../../../assets/images/user/meh.png';
import {IoPauseCircleOutline, IoPlayCircleOutline} from "react-icons/io5";
import {useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {selectBotVoice} from "../../../../Store/modules/precastBots/botVoice/botVoiceSelectors";
import {ActionTypes} from "../../../../Store/redux-store/ActionTypes";
import {customVoiceStyles} from "../styles/customVoiceStyles";
import {OverlayTrigger, Popover} from 'react-bootstrap';
import expand from '../../../../assets/images/user/expand.png';
import {AssistantBioModal} from "../modals/AssistantBioModal";
import {generateBotPersona} from "../../../../api/AuthIndex";
import {showToast} from "../../../../helpers/ToastHelper";
import {isEmpty} from "../../../../helpers/utils";
import {setFieldValue} from "../../../../Store/modules/assistant/create/form/createAssistantFormSlice";


export const SelectPrecastBotStep2 = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);
    const [showAssistantBioModal, setShowAssistantBioModal] = useState(false);
    const [promptSpinner, setPromptSpinner] = useState();
    const {handleNext} = useCreateAssistantContext();
    const dispatch = useDispatch();
    const {values, errors, isSubmitting} = useSelector((state) => state.createAssistantForm);
    const handleChange = (field, value) => {
        setFormErrors({});
        dispatch(setFieldValue({field, value}));
    };

    const [formErrors, setFormErrors] = useState({
        language: '',
        bot_name: '',
        gender: '',
        prompt: '',
        first_message: '',
        voice_id: '',
    });

    const getFormErrors = () => {
        let errors = {};

        if (isEmpty(values.language)) {
            errors.language = 'Language is required.';
        }

        if (isEmpty(values?.bot_name.trim())) {
            errors.bot_name = 'Name is required.';
        }

        if (isEmpty(values?.gender)) {
            errors.gender = 'Gender is required.';
        }

        if (isEmpty(values?.prompt)) {
            errors.prompt = 'Your AI Assistant Personality is required.';
        }

        if (isEmpty(values.first_message.trim())) {
            errors.first_message = 'Welcome Message is required.';
        }

        if (isEmpty(values.voice)) {
            errors.voice_id = 'AI Assistant Voice is required.';
        }

        setFormErrors({...formErrors, ...errors});

        return errors;
    }

    const audioSrc = useSelector(selectBotVoice);
    const audioRef = useRef();
    const toggleAudioPlay = () => {
        if (isPlaying) {
            audioRef?.current?.pause();
            setIsPlaying(false);
        } else {
            audioRef?.current?.play(); // Play the audio
            setIsPlaying(true);
        }
    };

    const viewBioPopover = (
        <Popover id="popover-view-bio">
            <Popover.Body>View Bio</Popover.Body>
        </Popover>
    );

    const handleBotPersona = async () => {
        setPromptSpinner(true);
        if (isEmpty(values?.bot_name) || isEmpty(values?.gender)) {
            showToast({type: TOAST_TYPES.DANGER, message: 'Update the generated description as per your needs'});
            setPromptSpinner(false);
        } else {
            const formData = new FormData()
            formData.append("name", values?.bot_name)
            formData.append("gender", values?.gender)
            formData.append("role", 'incoming')
            const token = localStorage.getItem('token');
            const res = await generateBotPersona(token, formData);
            handleChange('prompt', res[1]);
            setPromptSpinner(false);
            showToast({message: 'Update the generated description as per your needs'});
        }
    }


    return (
        <>
            <Col sm="12">
                <label>Language</label>
                <LanguageSelect
                    languageMap={LANGUAGES_MAP}
                    selectedLanguage={values?.language}
                    onLanguageChange={(lang) => {
                        handleChange('language', lang)
                    }}
                />
                {formErrors?.language && <span style={{
                    color: 'red',
                    fontSize: '12px'
                }}>{formErrors?.language}</span>}
            </Col>
            <Form>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '1em'
                }}>
                    <div style={{flex: 1}}>
                        <label>AI Assistant Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name={'bot_name'}
                            placeholder="Enter Name"
                            onChange={(e) => {
                                handleChange('bot_name', e.target.value)
                            }}
                            value={values?.bot_name}
                            style={{
                                fontSize: '14px',
                                fontWeight: '300'
                            }}
                        />
                        {formErrors?.bot_name && <span style={{
                            color: 'red',
                            fontSize: '12px'
                        }}>{formErrors?.bot_name}</span>}
                    </div>

                    <div style={{flex: 1}}>
                        <label>Gender</label>
                        <Select
                            options={GENDER_ARR}
                            styles={customSelectStyles}
                            onChange={(gender) => {
                                handleChange('gender', gender);
                                handleChange('voice', null);
                            }}
                            placeholder="Select Gender"
                            value={values?.gender}
                            name={'gender'}
                        />
                        {formErrors?.gender && <span style={{
                            color: 'red',
                            fontSize: '12px'
                        }}>{formErrors?.gender}</span>}
                    </div>
                </div>
                <div style={{marginBottom: '10px'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '1em'
                    }}>
                        <label>Your AI Assistant Personality</label>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            {promptSpinner ? (
                                <div className="loader"
                                     style={{
                                         width: '20px',
                                         height: '20px',
                                         marginRight: '10px',
                                         marginTop: '2px'
                                     }}>
                                    <Spinner style={{
                                        height: '18px',
                                        width: '18px'
                                    }}/>
                                </div>
                            ) : (
                                <img
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '10px',
                                        cursor: 'pointer'
                                    }}
                                    src={botImage}
                                    alt="Generate Bio Through AI"
                                    onClick={handleBotPersona}
                                />
                            )}

                            <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={viewBioPopover}>
                                <img
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        marginRight: '10px',
                                        cursor: 'pointer'
                                    }}
                                    src={expand}
                                    alt="View Bio"
                                    onClick={() => {
                                        setShowAssistantBioModal(true);
                                    }}
                                />
                            </OverlayTrigger>
                        </div>
                    </div>
                    <textarea
                        name={'prompt'}
                        className="form-control"
                        rows="3"
                        placeholder="Enter Your AI Assistant Personality"
                        value={values?.prompt}
                        onChange={(e) => {
                            handleChange('prompt', e.target.value)
                        }}
                        style={{
                            fontSize: '14px',
                            fontWeight: '300'
                        }}
                    />
                    {formErrors?.prompt && <span
                        style={{
                            color: 'red',
                            fontSize: '12px'
                        }}>{formErrors?.prompt}</span>}
                </div>
                <div style={{marginTop: '1em'}}>
                    <label>Welcome Message To Customer</label>
                    <input
                        className="form-control"
                        placeholder="Hi, how may I help you today?"
                        name={'first_message'}
                        value={values?.first_message}
                        onChange={(e) => {
                            handleChange('first_message', e.target.value)
                        }}
                        style={{
                            fontSize: '14px',
                            fontWeight: '300'
                        }}
                    />
                    {formErrors.first_message && <span
                        style={{
                            color: 'red',
                            fontSize: '12px'
                        }}>{formErrors.first_message}</span>}
                </div>
                <div style={{marginTop: '1em'}}>
                    <label>AI Assistant Voice Should Sound Like</label>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <Select
                            id="edit_voice"
                            className={'w-100'}
                            name="background_sound"
                            options={values.gender?.short_name === SHORT_GENDER.MALE ? MALE_VOICES : FEMALE_VOICES}
                            value={values?.voice}
                            onChange={(voice) => {
                                handleChange('voice', voice);
                                if (isEmpty(values?.first_message)) {
                                    setFormErrors({
                                        ...formErrors,
                                        voice_id: 'Please enter first message to preview bot voice',
                                        first_message: 'First message is required'
                                    });
                                    return;
                                }
                                dispatch({
                                    type: ActionTypes.GET_BOT_VOICE_MODEL,
                                    payload: {text: values.first_message, voice: voice?.value}
                                });
                            }}
                            placeholder={'Select Voice'}
                            styles={customVoiceStyles}
                        />
                        <div
                            onClick={toggleAudioPlay} style={{
                            marginLeft: '10px',
                            cursor: 'pointer'
                        }}>
                            {isPlaying ? (
                                <IoPauseCircleOutline
                                    size={25}
                                    style={{marginTop: '5px'}}/>
                            ) : (
                                <IoPlayCircleOutline
                                    size={25}
                                    style={{marginTop: '5px'}}/>
                            )}
                        </div>
                    </div>
                    <audio
                        ref={audioRef}
                        src={audioSrc}
                        type="audio/mpeg"
                        onEnded={() => setIsPlaying(false)}
                        style={{display: 'none'}}
                    />
                    {formErrors?.voice_id && <span
                        style={{
                            color: 'red',
                            fontSize: '12px'
                        }}>{formErrors?.voice_id}</span>}
                </div>
                <div style={{
                    marginTop: '1.5em',
                    width: '100%'
                }}>
                    <Button color='primary'
                            style={{width: '100%', margin: 0}}
                            onClick={() => {
                                const errors = getFormErrors();
                                if (!isEmpty(errors)) {
                                    return;
                                }
                                handleNext();
                            }
                            }
                    >
                        Next
                    </Button>
                </div>
            </Form>
            <AssistantBioModal showModal={showAssistantBioModal} setShowAssistantBioModal={setShowAssistantBioModal}/>
        </>);
}
