import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    values: {
        bot_id: '',
        bot_name: '',
        gender: '',
        background_sound: '',
        end_call_message: 'Good Bye, Bye',
        end_call_phrases: 'Have a nice day',
        first_message: 'Hi, how may I help you today?',
        keywords: '',
        language: '',
        picture: '',
        provider: '',
        purpose: '',
        prompt: '',
        voice: '',
        phone_number: '',
        provider_sid: '',
        provider_auth_token: '',
        provider_keys: '',
    },
    selectedBotData: null,
    errors: {},
    customPrecastBot: false,
    isSubmitting: false,
};

const createAssistantFormSlice = createSlice({
    name: 'createAssistantForm',
    initialState,
    reducers: {
        setFieldValue: (state, action) => {
            const { field, value } = action.payload;
            state.values[field] = value;
        },
        setFieldValues: (state, action) => {
            const updates = action.payload;
            Object.keys(updates).forEach((field) => {
                if (field in state.values) {
                    state.values[field] = updates[field];
                }
            });
        },
        setCustomPrecastBot: (state, action) => {
            const { customPrecastBot } = action.payload;
            state.customPrecastBot = customPrecastBot;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setSubmitting: (state, action) => {
            state.isSubmitting = action.payload;
        },
        setSelectedBotData: (state, action) => {
          state.selectedBotData = action.payload;
          state.values['bot_id'] = action.payload.bots?.id;
        },
        createAssistant: (state, action) => {

        },
        resetForm: (state) => {
            return initialState;
        },
    },
});

export const { setFieldValue,setFieldValues,setCustomPrecastBot, setSelectedBotData, setErrors, setSubmitting, resetForm } = createAssistantFormSlice.actions;
export default createAssistantFormSlice.reducer;
