import {H4} from "../../../AbstractElements";
import {Modal, Spinner} from 'react-bootstrap';
import {Button, Col, FormGroup, Input,} from 'reactstrap';
import {useState} from "react";
import {selectSelectedAssistant} from "../../../Store/modules/assistant/assistantSelectors";
import {useDispatch, useSelector} from 'react-redux';
import {selectDeletingAssistant} from "../../../Store/modules/assistant/delete/assistantSelectors";
import {ActionTypes} from "../../../Store/redux-store/ActionTypes";

export const DeleteAssistantModal = ({deleteBotModal, setDeleteBotModal}) => {
    const [deleteItem, setDeleteItem] = useState('');
    const dispatch = useDispatch();
    const botData = useSelector(selectSelectedAssistant);
    const isDeletingAssistant = useSelector(selectDeletingAssistant);

    const handleDelete = () => {
        dispatch({type: ActionTypes.DELETE_ASSISTANT, payload: {bot_id: botData?.bot_id}});
        setDeleteBotModal(false);
    }

    return <Modal
        show={deleteBotModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setDeleteBotModal(false)}
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body style={{marginTop: '-20px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <H4>Deleting your assistant {botData?.bot_name}?</H4>
            </div>
            <p style={{fontWeight: '300', fontSize: '14px', textAlign: 'center'}}>By removing your
                assistant {botData?.bot_name}, you will no longer be able to receive inbound and make outbound
                calls. To confirm the removal, please type "Delete" in the text box.</p>
            <FormGroup className="row">
                <Col sm="12">
                    <Input
                        type="text"
                        id="name"
                        value={deleteItem}
                        onChange={(e) => setDeleteItem(e.target.value)}
                        placeholder="Type delete here"
                        className='custom-placeholder'
                    />
                </Col>
            </FormGroup>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '60%'
                }}>
                    {isDeletingAssistant ?
                        <Button color="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Deleting...
                        </Button>
                        :
                        <Button style={{width: '100px'}} type="submit" color="primary"
                                onClick={handleDelete}>Delete</Button>
                    }
                    <Button style={{width: '100px', height: '43px'}}
                            onClick={() => setDeleteBotModal(false)}>Close</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}