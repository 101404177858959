import React, { createContext, useEffect, useState } from 'react';

// Create the context
const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [companiesStatus, setCompaniesStatus] = useState();
  const [allCompanies, setAllCompanies] = useState();
  const [companyDetail, setCompanyDetail] = useState();
  const [selectCompany, setSelectCompany] = useState();
  const [botList, setBotList] = useState();
  const [companyId, setCompanyId] = useState();
  const [botData, setBotData] = useState();
  const [timeZone, setTimeZone] = useState()
  const [industryName, setIndustyName] = useState()
  const [backNavigation, setBackNavigation] = useState(false)
  const [companyStatusData, setCompanyStatusData] = useState()
  useEffect(() => {
    const allcomp = JSON.parse(localStorage.getItem('companies'))
    if (!allCompanies) {
      setAllCompanies(allcomp);
    }
  }, [])

  // Function to set the boolean value
  const setUpdateStatus = (status) => {
    setIsUpdated(status);
  };

  return (
    <ApiContext.Provider
      value={{
        isUpdated,
        setUpdateStatus,
        companiesStatus,
        setCompaniesStatus,
        allCompanies,
        setAllCompanies,
        companyDetail,
        setCompanyDetail,
        selectCompany,
        setSelectCompany,
        botList,
        setBotList,
        companyId,
        setCompanyId,
        botData,
        setBotData,
        timeZone,
        setTimeZone,
        industryName,
        setIndustyName,
        backNavigation,
        setBackNavigation,
        companyStatusData,
        setCompanyStatusData,
      }}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
