import { createSlice } from '@reduxjs/toolkit';


const precastBotSlice = createSlice({
    name: 'precastBot',
    initialState: {
            selectedPrecastBot: null,
    },
    reducers: {
        setSelectedPrecastBot: (state, action) => {
            state.selectedPrecastBot = action.payload.precastBot;
        },
    },
});

export const {
    setSelectedPrecastBot
} = precastBotSlice.actions;

export default precastBotSlice.reducer;
