import {H4} from "../../../AbstractElements";
import noAssistant from "../../../assets/images/user/assistantBot.png";
import {Button,} from 'reactstrap';
import {useDispatch} from 'react-redux';
import {AI_ASSISTANT_RIGHT_SIDEBAR} from "../../../helpers/constants";
import {ActionTypes} from "../../../Store/redux-store/ActionTypes";

export const NoAssistants = () => {
    const dispatch = useDispatch();

    const openCreateAssistant = () => {
        dispatch({
            type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
            payload: {sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.CREATE_ASSISTANT}
        });
    };

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <H4>Automated Assistant Staff</H4>
                <Button
                    onClick={openCreateAssistant}>Add
                    Assistant</Button>
            </div>

            <div style={{
                width: '100%',
                padding: '10px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '3%'
            }}>
                <div style={{textAlign: 'center'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img style={{width: '250px', height: '250px'}}
                             src={noAssistant}/>
                    </div>
                    <h4 style={{
                        textAlign: 'center',
                        color: '#FF3364'
                    }}>OOPS!</h4>
                    <h4 style={{color: 'black', textAlign: 'center'}}>No
                        assistant yet</h4>
                    <p style={{
                        color: 'black',
                        textAlign: 'center',
                        width: '450px'
                    }}>Looks like no one is there to wow your customers
                        with AI magic. Create your agent to unleash the
                        full potential of AI!</p>
                    <Button onClick={openCreateAssistant}
                    >
                        Create Assistant
                    </Button>
                </div>
            </div>
        </div>

    );
}