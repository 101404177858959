import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { H4, H5, P } from '../../AbstractElements';
import Alerts from '../../CommonElements/Alert';
import { AdditionalContent } from '../../Constant';

const Alerting = () => {

    return (
        <Col sm="11">
            <Card>
                <CardBody>
                    <Alerts attrAlert={{ color: 'primary ' }} >
                        <H4 attrH4={{ className: 'alert-heading' }} >{'Please Add Company First'}</H4>
                        <P>{'Do you need help? Talk to Our help AI. Click Here'}</P>
                        {/* <hr /> */}
                        {/* <P attrPara={{ className: 'mb-0' }}>{'Whenever you need to, be sure to use margin utilities to keep things nice and tidy.'}</P> */}
                    </Alerts>
                </CardBody>
            </Card>
        </Col>
    );
};

export default Alerting;