import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import { Breadcrumbs, H5, Alerts, P, H4 } from "../../AbstractElements";
import GreetingCard from "./GreetingCard";
import { getAllCompanyData, getAllCompanyCustomers, getWeeksData, getDashboard } from "../../api/AuthIndex";
import totalMembers from '../../assets/images/user/totalMembers.png';
import cusImg from '../../assets/images/user/cusImg.png';
import transfer from '../../assets/images/user/transfer.png';
import transImg from '../../assets/images/user/transImg.png';
import transPer from '../../assets/images/user/transPer.png';
import tickSuc from '../../assets/images/user/tickSuc.png';
import { TourProvider, useTour } from "@reactour/tour";
import inboundCalls from '../../assets/images/user/inboundCalls.png';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import inBoundImg from '../../assets/images/user/inBoundImg.png';
import inBoundPer from '../../assets/images/user/inBoundPer.png';
import busyIconImg from '../../assets/images/user/busyIconImg.png';
import failIcon from '../../assets/images/user/failIcon.png';
import alertBot from '../../assets/images/user/alertBot.png';
import busyCir from '../../assets/images/user/busyCir.png';
import failedCir from '../../assets/images/user/failedCir.png';
import noAnsCir from '../../assets/images/user/noAnsCir.png';
import suc from '../../assets/images/user/suc.png';
import leftImg from '../../assets/images/logo/leftImg.jpeg';
import logoImg from '../../assets/images/logo/logoImg.png';
import moment from "moment";
import '../ManageAssistant/ManageAssistant.css'
import BasicAreaChartClass from "./BasicAreaChartClass";
import { useNavigate } from "react-router";
import { getCompanyCallStats, getCompanyDetails } from "../../api/Company";
import { getChildCallsStatusSummary } from '../../api/AuthIndex';
import WeeklyCallsData from "./WeeklyCallsData";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Select from 'react-select'
import { Modal } from "react-bootstrap";
import StripePayment from "../Stripe/StripePayment";
import { Bell, AlertCircle } from 'react-feather';
import ApiContext from "../../ApiContext";
import ReactApexChart from "react-apexcharts";
import './Dashboard.css'
import { RxCross2 } from "react-icons/rx";
import Widgets1 from "../../Components/Common/CommonWidgets/Widgets1";
import { WidgetsData } from "../../Data/DefaultDashboard";
import { createCookie, eraseCookie, readCookie } from "../../helpers/CookieHelper";
import { isEmpty } from "../../helpers/utils";
import DashboardContent from "./DashboardContent";
import { useSelector } from "react-redux";
import { selectCompleteCompany } from "../../Store/modules/company/companySelectors";

const Dashboard = ({ mainClass }) => {
  const { companyDetail } = useContext(ApiContext);
  let getCompanyInfo = companyDetail ? JSON.parse(companyDetail) : JSON.parse(localStorage.getItem('companyData'))
  let token = localStorage?.getItem('token')
  const theme = localStorage.getItem("mix_background_layout")

  const navigate = useNavigate()
  const completeCompany = useSelector(selectCompleteCompany)

  const [completedData, setCompletedData] = useState();
  const [topFiveCalls, setTopFiveCalls] = useState();
  const [modal, setModal] = useState(false)
  const [stripeModal, setStripeModal] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const [currentAudio, setCurrentAudio] = useState(null);
  const [customerList, setCustomerLIst] = useState()
  const [totalCustomer, setTotalCustomer] = useState()
  const audioRef = useRef(null);
  const [completeCompanyData, setCompleteCompanyData] = useState()
  const [staffData, setStaffData] = useState()
  const [receivedCallLength, setReceivedCallLength] = useState()
  const [forwardedCallLength, setForwardedCallLength] = useState()
  const [companyData, setCompanyData] = useState();
  const [callsStatsData, setCallsStatsData] = useState();
  const startOfWeek = moment().startOf('month').format('YYYY-MM-DD');
  const endOfWeek = moment().endOf('month').format('YYYY-MM-DD');
  const [dateRange, setDateRange] = useState({ startDate: startOfWeek, endDate: endOfWeek });
  const [chartData, setChartData] = useState()

  const [pieChartData, setPieChartData] = useState({
    series: [33, 33, 34], // Default values
    options: {
      chart: {
        type: 'donut',
        width: 400,
        height: 400,
      },
      labels: ['Successful', 'Busy', 'Failed'], // Legend labels
      colors: ['#62BD5B', '#FF0000', '#7E0000'], // Custom colors for each section
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: '',
                fontSize: '16px',
                color: '#263238',
                formatter: function () {
                  return ''; // Center text value (total)
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%"; // Percentage outside slices
        },
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff'], // White text on colored background
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        labels: {
          colors: '#000',
          useSeriesColors: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  const [completedCalls, setCompletedCalls] = useState(0);
  const [completedCallsPer, setCompletedCallsPer] = useState(0);
  const [noAnswerCalls, setNoAnswerCalls] = useState(0);
  const [noAnswerCallsPer, setNoAnswerCallsPer] = useState(0);
  const [inProgressCalls, setInProgressCalls] = useState(0);
  const [inProgressCallsper, setInProgressCallsPer] = useState(0);
  const [failedCalls, setFailedCalls] = useState(0);
  const [failedCallsPer, setFailedCallsPer] = useState(0);
  const [highestCalls, setHighestCalls] = useState(0);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [helpModal, setHelpModal] = useState(isEmpty(readCookie('showWelcomeDialog')));
  const [selectedData, setSelectedData] = useState('Last Week');

  const [departmentWiseSummary, setDepartmentWiseSummary] = useState()

  const [Open, setOpen] = useState(true);
  const Toggle = () => setOpen(!Open);

  const [series, setSeries] = useState([70]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        }
      },
    },
    labels: [''],
  });

  // useEffect(() => {
  //   // handleGetAllCompany()
  //   getWeeksDataInfo(token)
  //   getDashboardData('last-week')
  //   handleCompanyCustomers()
  //   getCompanyArray();
  //   if (getCompanyInfo?.id) {
  //     childSummry()
  //     // getCompanyStats()
  //   }
  // }, [])

  // useEffect(() => {
  //   getCompanyStats('last-week')

  //   const companyByUser = completeCompany; 

  //   setCompleteCompanyData(companyByUser);

  //   const assistants = Array.isArray(companyByUser)
  //     ? companyByUser.reduce((acc, company) => {
  //       if (company?.assistant) {
  //         return acc.concat(company?.assistant);
  //       }
  //       return acc;
  //     }, [])
  //     : [];

  //   localStorage.setItem("companyStatus", JSON.stringify(companyByUser));

  //   setStaffData(assistants);

  //   let forwardedCallCount = 0;
  //   let totalCallsCount = 0;

  //   assistants.forEach(assistant => {
  //     if (assistant.calls && Array.isArray(assistant.calls)) {
  //       assistant.calls.forEach(call => {
  //         if (call.end_reason) {
  //           forwardedCallCount++;
  //         }
  //         if (call.total_calls) {
  //           totalCallsCount += call.total_calls;
  //         }
  //       });
  //     }
  //   });

  //   setForwardedCallLength(forwardedCallCount);
  //   setReceivedCallLength(totalCallsCount);
  //   setLoading(false);

  // }, [])


  const convertData = (res2) => {
    const records = Object.values(res2.records).flat(2);
    return { records };
  };

  const childSummry = async () => {
    try {
      const formData = new FormData();
      formData.append("company_id", getCompanyInfo?.id);
      formData.append("start_date", dateRange.startDate);
      formData.append("end_date", dateRange.endDate);
      let res2 = await getChildCallsStatusSummary(token, formData);
      let res = convertData(res2);
      const data1 = res?.records?.map(item => item.keyword);
      const data2 = res?.records?.map(item => item.frequency);
      const filteredRecords = res?.records?.filter(record => record.status === "no-answer");
      let sumTotalCalls = 0;
      let sumThisStatus = 0;
      filteredRecords?.forEach(item => {
        sumTotalCalls += item.total_calls;
        sumThisStatus += item.this_status;
      });
      let percentage = (sumThisStatus / sumTotalCalls) * 100;
      percentage = isNaN(percentage) ? 0 : percentage;
      setSeries([percentage]);
      // setChartData(filteredRecords);
    } catch (error) {
      console.log("🚀 ~ childSummry ~ error:", error)
    }
  }


  const handleModal = (data) => {
    // console.log("🚀 ~ handleModal ~ data:", data)
    setUserInfo(data)
    setModal(true)
  }

  const getCompanyArray = async () => {
    let res = await getCompanyDetails(getCompanyInfo?.id, token)
    // console.log("🚀 ~ getCompanyArray ~ res:", res)
    setCompanyData(res?.company?.company)
  }

  // const getCompanyStats = async () => {
  //   const formData = new FormData();
  //   formData.append("company_id", getCompanyInfo?.id);
  //   formData.append("start_date", moment().subtract(1, 'month').format('YYYY-MM-DD'));
  //   formData.append("end_date", moment().format('YYYY-MM-DD'));

  //   let res = await getCompanyCallStats(token, formData);
  //   console.log("🚀 ~ getCompanyStats ~ res:", res);

  //   const departmentWiseSummary = res?.records?.department_wide_failed_summary || [];
  //   const filteredDepartmentWiseSummary = departmentWiseSummary.filter(item => item.status === 'no-answer');
  //   setDepartmentWiseSummary(filteredDepartmentWiseSummary);

  //   const statusCounts = res?.records?.company_wide_status_count || [];

  //   const highestCallsHours = res?.records?.highest_calls_hours[0]
  //   console.log("🚀 ~ getCompanyStats ~ highestCallsHours:", highestCallsHours)

  //   setHighestCalls(highestCallsHours)

  //   statusCounts.forEach(item => {
  //     switch (item.status) {
  //       case 'completed':
  //         setCompletedCalls(item.total_calls);
  //         setCompletedCallsPer(item.percentage_calls);
  //         break;
  //       case 'no-answer':
  //         setNoAnswerCalls(item.total_calls);
  //         setNoAnswerCallsPer(item.percentage_calls);
  //         break;
  //       case 'in-progress':
  //         setInProgressCalls(item.total_calls);
  //         setInProgressCallsPer(item.percentage_calls);
  //         break;
  //       case 'failed':
  //         setFailedCalls(item.total_calls);
  //         setFailedCallsPer(item.percentage_calls);
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  // };

  const getCompanyStats = async (selectedValue) => {
    setCompletedCalls(0)
    const formData = new FormData();
    const currentDate = moment().format('YYYY-MM-DD');
    let startDate;

    switch (selectedValue) {
      case 'today':
        startDate = currentDate;
        break;
      case 'last-week':
        startDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'last-month':
        startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
        break;
      case 'last-3-months':
        startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
        break;
      case 'last-year':
        startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
        break;
      default:
        return;
    }

    formData.append("company_id", getCompanyInfo?.id);
    formData.append("start_date", startDate);
    formData.append("end_date", currentDate);

    let res = await getCompanyCallStats(token, formData);

    setCompletedCalls(0);
    setCompletedCallsPer(0);
    setNoAnswerCalls(0);
    setNoAnswerCallsPer(0);
    setInProgressCalls(0);
    setInProgressCallsPer(0);
    setFailedCalls(0);
    setFailedCallsPer(0);

    const departmentWiseSummary = res?.records?.department_wide_failed_summary || [];
    const filteredDepartmentWiseSummary = departmentWiseSummary.filter(item => item.status === 'no-answer');
    setDepartmentWiseSummary(filteredDepartmentWiseSummary);

    const statusCounts = res?.records?.company_wide_status_count || [];

    const highestCallsHours = res?.records?.highest_calls_hours[0]

    setHighestCalls(highestCallsHours);

    statusCounts.forEach(item => {
      console.log("Total Calls", item);
      switch (item.status) {
        case 'completed':
          setCompletedCalls(item.total_calls);
          setCompletedCallsPer(item.percentage_calls);
          break;
        case 'no-answer':
          setNoAnswerCalls(item.total_calls);
          setNoAnswerCallsPer(item.percentage_calls);
          break;
        case 'in-progress':
          setInProgressCalls(item.total_calls);
          setInProgressCallsPer(item.percentage_calls);
          break;
        case 'failed':
          setFailedCalls(item.total_calls);
          setFailedCallsPer(item.percentage_calls);
          break;
        default:
          break;
      }
    });
  };

  const getWeeksDataInfo = async (token) => {
    const formData = new FormData()
    formData.append("company_id", getCompanyInfo?.id)
    // formData.append("company_id", 21)
    let res = await getWeeksData(token, formData);
    setTopFiveCalls(res.TopFiveCalls)
    // console.log("🚀 ~ getWeeksDataInfo ~ res:", res)
  }

  const getDashboardData = async (selectedValue) => {
    const formData = new FormData();
    const currentDate = moment().format('YYYY-MM-DD');
    let startDate;
    switch (selectedValue) {
      case 'today':
        startDate = currentDate;
        break;
      case 'last-week':
        startDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'last-month':
        startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
        break;
      case 'last-3-months':
        startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
        break;
      case 'last-year':
        startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
        break;
      default:
        return;
    }

    formData.append("company_id", getCompanyInfo?.id);
    formData.append("start_date", startDate);
    formData.append("end_date", currentDate);

    try {
      let res = await getDashboard(token, formData);

      const newSeries = [
        res[0].transferCallComplted.transfer_call_completed_persantage,
        res[0].transferCallsBusy.transfer_call_busy_persantage,
        res[0].transferCallsFaild.transfer_call_Faild_persantage
      ];

      setPieChartData((prevData) => ({
        ...prevData,
        series: newSeries,
      }));

      setCompletedData(res[0]);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const handleCompanyCustomers = async () => {
    try {
      const formData = new FormData();
      formData.append("company_id", getCompanyInfo?.id);

      const res = await getAllCompanyCustomers(token, formData);
      // console.log("🚀 ~ handleCompanyCustomers ~ res:", res);

      setTotalCustomer(res.customer)

      // Assuming res.customer is an array
      if (Array.isArray(res.customer)) {
        // Sort customers if necessary, e.g., by some 'score' property
        const sortedCustomers = res.customer.sort((a, b) => b.score - a.score);

        // Get the top 5 customers
        const topCustomers = sortedCustomers.slice(0, 5);

        setCustomerLIst(topCustomers);
      } else {
        // console.error("Expected res.customer to be an array");
      }
    } catch (error) {
      // console.error("Error handling company customers:", error);
    }
  };

  const handleAudioEnded = () => {
    setCurrentAudio('ended');
  };

  const handleCloseModal = () => {
    setModal(false)
    handleAudioEnded()
  }
  const handleCloseStripeModal = () => {
    setStripeModal(false)
  }

  const getRandomColor = () => {
    const colors = ['bg-color-1', 'bg-color-2', 'bg-color-3', 'bg-color-4', 'bg-color-5'];
    return colors[Math.floor(Math.random() * colors?.length)];
  };

  const getInitials = (name) => {
    if (!name) return '';
    const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
    return initials.slice(0, 2);
  };

  // const handleGetAllCompany = async () => {
  //   const formData = new FormData();
  //   formData.append("company_id", getCompanyInfo?.id);

  //   try {
  //     const res = await getAllCompanyData(token, formData);
  //     console.log("🚀 ~ handleGetAllCompany ~ res:", res.company.company)

  //     // const completeCompanyData = res.company.map((item) => item?.company);
  //     const completeCompanyData = res.company.company;

  //     setCompleteCompanyData(completeCompanyData);

  //     const assistants = completeCompanyData.reduce((acc, company) => {
  //       if (company.assistant) {
  //         return acc.concat(company.assistant);
  //       }
  //       return acc;
  //     }, []);

  //     localStorage.setItem("companyStatus", JSON.stringify(completeCompanyData))

  //     setStaffData(assistants);

  //     let forwardedCallCount = 0;
  //     let totalCallsCount = 0;

  //     assistants.forEach(assistant => {
  //       if (assistant.calls && Array.isArray(assistant.calls)) {
  //         assistant.calls.forEach(call => {
  //           if (call.end_reason) {
  //             forwardedCallCount++;
  //           }
  //           if (call.total_calls) {
  //             totalCallsCount += call.total_calls;
  //           }
  //         });
  //       }
  //     });

  //     setForwardedCallLength(forwardedCallCount);
  //     setReceivedCallLength(totalCallsCount);
  //     setLoading(false)

  //   } catch (error) {
  //     // console.error("Error fetching company data:", error);
  //     setLoading(false)
  //   }
  // };

  const handleActionSelect = (row, action) => {
    if (action === "Edit") {
      handleEdit(row);
    } else if (action === "View Detail") {
      navigate('/app/manage-assistants')
    }
  };

  const handleEdit = (data) => {
    // console.log("Edit", data);
  }

  const handleViewDetail = (data) => {
    // console.log("View Detail", data);
  }

  const select = [
    { value: 'today', label: 'Today' },
    { value: 'last-week', label: 'Last Week' },
    { value: 'last-month', label: 'Last Month' },
    { value: 'last-3-months', label: 'Last 3 Months' },
    { value: 'last-year', label: 'Last Year' },
  ]

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(89, 120, 212, 0.2)',
      color: '#5978d4',
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
      minWidth: '120px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#5978d4',
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected && '#5978d4',
      // color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(89, 120, 212, 0.2)',
      },
      fontSize: '11px'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#5978d4',
      '&:hover': {
        color: '#5978d4',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),

  };

  const handleHelpModalShow = () => setHelpModal(true);
  const handleHelpModalClose = () => setHelpModal(false);

  const handleUpdateAccount = () => {
    setHelpModal(false)
    navigate(`${process.env.PUBLIC_URL}/app/billing`)
  }
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);



  return (
    <Fragment>
      <DashboardContent />
    </Fragment >
  );
};

export default Dashboard;
