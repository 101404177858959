import profileImage from "../../../assets/images/user/bot.png";
import botImageMale from '../../../assets/images/user/botImageMale.jpeg';
import botImageFemale from '../../../assets/images/user/botImageFemale.jpeg';
import inCallsIcon from "../../../assets/images/logo/inCallsIcon.png";
import callIcon from "../../../assets/images/logo/callIcon.png";
import forwardCallIcon from "../../../assets/images/logo/forwardCallIcon.png";
import { getTitleByLangName } from "../../../helpers/common";
import { selectSelectedAssistant } from "../../../Store/modules/assistant/assistantSelectors";
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from "../../../Store/redux-store/ActionTypes";
import { AI_ASSISTANT_RIGHT_SIDEBAR } from "../../../helpers/constants";

export const AssistantTile = (props) => {
    const { assistant } = props;
    const selectedAssistant = useSelector(selectSelectedAssistant);
    const dispatch = useDispatch();

    const isSelected = assistant?.id === selectedAssistant?.id;
    const data = assistant;

    let forwardedCallCount = 0;
    let totalCallsCount = 0;
    if (assistant.calls && Array.isArray(assistant.calls)) {
        assistant.calls.forEach(call => {
            if (call.end_reason === "assistant-forwarded-call") {
                forwardedCallCount += call.total_calls;
            }

            if (call.total_calls) {
                totalCallsCount += call.total_calls;
            }
        });
    }

    return (
        <div
            style={{
                margin: '10px 10px 0px 10px',
                borderBottom: '2px solid #D3E9FF',
                backgroundColor: isSelected ? 'white' : 'transparent',
                cursor: 'pointer',
                padding: '10px 10px',
                borderRadius: '15px',
            }}
            onClick={() => {
                dispatch({ type: ActionTypes.SET_SELECTED_ASSISTANT, payload: { assistant: assistant } });
                dispatch({
                    type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR,
                    payload: { sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL }
                });
            }}
        >
            <div style={{ display: 'flex', }}>
                <div style={{
                    width: '18%',
                    marginTop: '10px',
                    position: 'relative'
                }}>
                    <div
                        className={data?.purpose === "Scheduling" ? 'social-img-scheduling' : 'social-img-in'}
                        style={{ position: 'relative' }}>
                        <img style={{
                            width: '52px',
                            height: '52px',
                            borderRadius: '50px',
                            position: 'relative',
                        }}
                            src={data.picture ? data.picture : data.gender === 'M' ? botImageMale : botImageFemale} />
                    </div>
                </div>

                <div style={{ marginLeft: '5px' }}>
                    <div style={{
                        justifyContent: 'space-between',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }} className='someMargin'>
                        <h4 style={{
                            marginBottom: '3px',
                            textAlign: 'left',
                            fontSize: '16px',
                            color: '#FF3464',
                            marginTop: '10px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            textTransform: 'capitalize'
                        }}>{data?.bot_name} </h4>
                        <p style={{
                            fontSize: '12px',
                            color: '#5679D5',
                            fontWeight: '500'
                        }}>
                            {getTitleByLangName(data?.language)}
                        </p>
                    </div>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <img style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50px'
                        }} src={inCallsIcon} />
                        <p style={{
                            color: '#FC5455',
                            fontWeight: '500',
                            fontSize: '12px',
                            marginLeft: '5px'
                        }}>{data?.purpose === "Scheduling" ? 'Scheduling' : 'Incoming'}</p>
                    </div>

                    <p style={{
                        fontSize: '14px',
                        fontWeight: '300',
                        textAlign: 'left',
                        height: '35px',
                        overflow: 'hidden',
                        color: 'grey',
                        marginTop: '5px',
                        lineHeight: 1.2
                    }}
                        dangerouslySetInnerHTML={{ __html: data?.prompt?.replaceAll('\\n', '<br />') }}></p>
                    <p style={{
                        fontSize: '12px',
                        textAlign: 'left',
                        fontWeight: '500',
                        textDecorationLine: 'underline',
                        color: 'grey',
                        cursor: 'pointer',
                        marginTop: '-15px'
                    }}
                        onClick={() => {
                            // handleReadBioModal(data)
                        }}>More...</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50px'
                            }} src={callIcon} />
                            <p style={{
                                textAlign: 'left',
                                color: '#5679D5',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>Total Calls :</p>
                            <p style={{
                                textAlign: 'left',
                                color: '#5679D5',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>{totalCallsCount}</p>
                        </div>
                        <div style={{
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '15px'
                        }}>
                            <img style={{
                                width: '15px',
                                height: '15px'
                            }} src={forwardCallIcon} />
                            <p style={{
                                textAlign: 'left',
                                color: '#FC5455',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>Forwarded :</p>
                            <p style={{
                                textAlign: 'left',
                                color: '#FC5455',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                marginLeft: '5px'
                            }}>{forwardedCallCount}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}