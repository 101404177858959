import React, { Fragment } from 'react'
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { H4, P } from '../../AbstractElements';
// import noDataFound from '../../assets/images/logo/noDataFound.jpg';
import noDataFound from '../../assets/images/logo/noDataFound1.png';
import { Color } from '../../helpers/colors';

const NoDataFound = (props) => {
    const {message} = props;
    const navigate = useNavigate()
    const theme = localStorage.getItem("mix_background_layout");
    return (
        <Fragment>
            {/* <Container className='p-0 login-page' fluid={true}> */}
            <Row className='m-0'>
                <Col className='p-0'>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // marginTop: '2%',
                        }}>
                        {/* <CardBody style={{ padding: '0px' }}> */}
                        <div>
                            <Row>
                                <Col sm='12' style={{ height: '500px', backgroundColor: theme === 'dark-only' ? 'transparent' : 'white', borderRadius: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', }}>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={noDataFound} alt='loginpage' style={{ width: '350px', height: '300px', borderRadius: '10px' }} />
                                            </div>
                                            {/* <H4 attrH4={{ style: { textAlign: 'center', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '' } }}>Oops Sorry!</H4> */}
                                            <P attrPara={{ style: { textAlign: 'center', color: theme === 'dark-only' ? Color.DARK_THE_GREY_TEXT : '#3C3C3C', fontSize: '16px', fontWeight:'500' } }}>{message ?? 'There Are No Records To Display'}</P>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button color='primary' style={{ width: '400px', fontSize: '16px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/app/dashboard`) }} >Back to my dashboard</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {/* </CardBody> */}
                    </div>
                </Col>
            </Row>
            {/* </Container> */}
        </Fragment >
    )
}

export default NoDataFound