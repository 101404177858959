import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Dropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import Alerting from '../ManageDirectory/Alert';
import ProfileCard from './ProfileCard';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { addConnectBotToPhone, deletePhoneNumber, editPhoneNumber, getAllCompanyData, getViewCompanyPhoneNumber } from '../../api/AuthIndex';
import { ToastContainer, toast } from "react-toastify";
import { Image, H5, LI, UL, H4 } from '../../AbstractElements';
import moment from 'moment';
import DropdownCommon from '../../Components/Common/Dropdown';
import profileImage from '../../assets/images/user/bot.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { Modal, Spinner } from 'react-bootstrap';
import { set } from 'date-fns/esm';
import '../ManageAssistant/ManageAssistant.css'

const ManagePhone = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    let getUserData = JSON.parse(localStorage.getItem('auth0_profile'))
    let token = localStorage?.getItem('token')

    const [phone, setPhone] = useState()
    const [bot, setBot] = useState()
    const [provider, setProvider] = useState()
    const [gender, setGender] = useState()
    const [accountSID, setAccountSID] = useState()
    const [authToken, setAuthToken] = useState();
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [phoneData, setPhoneData] = useState()
    const [editName, setEditName] = useState('');
    const [editModal, setEditModal] = useState(false)
    const [completeCompanyData, setCompleteCompanyData] = useState()


    const handleProviderChange = (e) => {
        setProvider(e.target.value);
    };

    const DropdownMenu = ["Edit", "Delete"];

    useEffect(() => {
        if (getCompanyInfo !== null) {
            handleViewCompanyPhoneNumber()
            handleGetAllCompany()
        }
    }, [])

    const handleViewCompanyPhoneNumber = async () => {
        const formData = new FormData()
        formData.append('company_id', getCompanyInfo?.id)
        const res = await getViewCompanyPhoneNumber(token, formData)
        setData(res.CompanyPhone)
    }

    const handleGetAllCompany = async () => {
        const formData = new FormData()
        formData.append("user_id", getUserData?.id)
        const res = await getAllCompanyData(token, formData)
        setCompleteCompanyData(res.company)
    }

    const handleSubmit = async () => {
        setLoader(true)
        try {
            const formData = new FormData()
            formData.append("bot_id", bot)
            formData.append("company_id", getCompanyInfo?.id)
            formData.append("phone_number", phone)
            formData.append("provider", provider == "Twilio" ? "Tw" : "Vo")
            formData.append("AccountSid", accountSID)
            formData.append("AuthToken", authToken)
            formData.append("status", 1)
            const res = await addConnectBotToPhone(token, formData)
            toast.info(res?.message, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            handleViewCompanyPhoneNumber()
            setBot('')
            setPhone('')
            setProvider('')
            setAccountSID('')
            setAuthToken('')
            setLoader(false)

        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error)
            toast.error(error?.response?.data?.Error[0], {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
            setLoader(false)
        }

    }

    const handleActionSelect = (row, action) => {
        if (action === "Edit") {
            handleEdit(row);
        } else if (action === "Delete") {
            handleDelete(row);
        }
    };

    const handleEdit = (data) => {
        console.log("🚀 ~ handleEdit ~ data:", data)
        setPhoneData(data)
        setEditName(data?.bot_name)
        setEditModal(true)
    }

    const handleDelete = (data) => {
        setPhoneData(data)
        setDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false)
    }

    const handleCloseEditModal = () => {
        setEditModal(false)
    }

    const handleDeleteNumber = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("phone_id", phoneData?.phone_id)
            const res = await deletePhoneNumber(token, formData)
            toast.info("Successfully deleted!..", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            handleViewCompanyPhoneNumber()
            setLoading(false)
            setDeleteModal(false)
        } catch (error) {
            console.log("🚀 ~ handleDeleteNumber ~ error:", error)
            toast.error(error?.response?.data?.error?.phone_id[0], {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
            setLoading(false)
        }
    }

    const handleEditNumber = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("phone_id", phoneData?.phone_id)
            formData.append("name", editName)
            const res = await editPhoneNumber(token, formData)
            toast.info("Successfully Edit!..", {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            // Clear the input fields after submission
            handleViewCompanyPhoneNumber()
            setEditName('');
            setEditModal(false)
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ handleEditSubmit ~ error:", error)
            setLoading(false)
        }
    };

    return (
        <>
            <Fragment>
                {/* <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" /> */}
                <Container fluid={true}>
                    {getCompanyInfo == null ?
                        <Row>
                            <Col sm='12'>
                                <Card style={{ height: '80vh', marginTop: '2%' }}>
                                    <CardBody>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {/* <H4>Automated Assistant Staff</H4> */}
                                                <Button>Add Assistant</Button>
                                            </div>
                                            <div style={{ backgroundColor: '#F95A83', width: '40%', padding: '10px', }}>
                                                <h4 style={{ color: 'white' }}>OOPS!</h4>
                                                <h4 style={{ color: 'white' }}>Your assistants are idling around</h4>
                                                <p style={{ color: 'white' }}>There is no phone number available for assistants.</p>
                                                <p style={{ color: 'white' }}>Add a phone number</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Row style={{ paddingTop: '2%' }}>
                            <Col sm="6">
                                <>
                                    {data?.length < 1 ? (
                                        // <Card>
                                        //     <CardBody>
                                        <div style={{ padding: '0px 10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {/* <H4>Automated Assistant Staff</H4> */}
                                                {/* <Button>Add Assistant</Button> */}
                                            </div>
                                            <div style={{ backgroundColor: '#F95A83', padding: '10px', borderRadius: '10px' }}>
                                                <h4 style={{ color: 'white' }}>OOPS!</h4>
                                                <h4 style={{ color: 'white' }}>Your assistants are idling around</h4>
                                                <p style={{ color: 'white' }}>There is no phone number available for assistants.</p>
                                                <p style={{ color: 'white' }}>Add a phone number</p>
                                            </div>
                                        </div>
                                        //     </CardBody>
                                        // </Card>

                                        // <Card className='social-profile' style={{ height: '275px' }}>
                                        //     <CardBody>
                                        //         <div style={{ height: '220px', justifyContent: 'center', alignContent: 'center', display: 'flex' }}>
                                        //             <Spinner
                                        //                 as="span"
                                        //                 animation="grow"
                                        //                 size="sm"
                                        //                 role="status"
                                        //                 aria-hidden="true"
                                        //                 style={{ alignSelf: 'center' }}
                                        //             />
                                        //         </div>
                                        //     </CardBody>
                                        // </Card>
                                    ) : (
                                        <>
                                            {!data ?
                                                (
                                                    <Card className='social-profile' style={{ height: '275px' }}>
                                                        <CardBody>
                                                            <div style={{ height: '220px', justifyContent: 'center', alignContent: 'center', display: 'flex' }}>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    style={{ alignSelf: 'center' }}
                                                                />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )
                                                :
                                                (
                                                    <>
                                                        {data?.map((item, index) => {
                                                            return (
                                                                <Card className='social-profile' key={index}>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '20px', paddingTop: '20px' }}>
                                                                        <DropdownCommon
                                                                            dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
                                                                            options={DropdownMenu}
                                                                            iconName='icon-more-alt'
                                                                            btn={{ tag: 'span' }}
                                                                            onSelect={(action) => handleActionSelect(item, action)}
                                                                        />
                                                                    </div>
                                                                    <CardBody>
                                                                        <div className='social-img-wrap'>
                                                                            <div className='social-img'>
                                                                                <img style={{ width: '70px', height: '70px' }} src={profileImage} />
                                                                                {/* <Image attrImage={{ src: profileImage, alt: 'profile' }} /> */}
                                                                            </div>
                                                                            {/* <div className='edit-icon'>
                                                                                <SvgIcon iconId='profile-check' />
                                                                            </div> */}
                                                                        </div>

                                                                        <div className='social-details'>
                                                                            <>
                                                                                <H5 attrH5={{ className: 'mb-1' }}>
                                                                                    {item?.bot_name}
                                                                                </H5>
                                                                                <span className='f-light'>{item?.phone_number}</span>
                                                                                <UL attrUL={{ className: 'social-follow' }}>
                                                                                    <LI>
                                                                                        <H5 attrH5={{ className: 'mb-0' }}>Provider</H5>
                                                                                        <span className='f-light'>{item?.provider === 'Tw' ? 'Twilio' : 'Vonage'}</span>
                                                                                    </LI>
                                                                                    <LI>
                                                                                        <H5 attrH5={{ className: 'mb-0' }}>Active Since</H5>
                                                                                        <span className='f-light'>{moment(item?.created_at).format('MMM DD, YY')}</span>
                                                                                    </LI>
                                                                                    <LI>
                                                                                        <H5 attrH5={{ className: 'mb-0' }}>Status</H5>
                                                                                        <span className='f-light'>{item?.phone_status === 1 ? 'Active' : 'Inactive'}</span>
                                                                                    </LI>
                                                                                </UL>
                                                                            </>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                </>
                            </Col>
                            <Col sm="5">
                                <Card>
                                    <HeaderCard title="Manage bot phone" />
                                    <CardBody>
                                        <Form >
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Phone</Label>
                                                <Col sm="9">
                                                    <div style={{ padding: '5px', border: '1px solid #DEE2E6', borderColor: '#DEE2E6', borderRadius: '5px' }}>
                                                        <PhoneInput
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="US"
                                                            value={phone}
                                                            onChange={(e) => {
                                                                setPhone(e)
                                                            }}
                                                            countries={['US', 'CA']}
                                                            limitMaxLength={12}
                                                        />
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label" htmlFor="provider">Provider</Label>
                                                <Col sm="9">
                                                    <Input
                                                        type="select"
                                                        value={provider}
                                                        onChange={handleProviderChange}
                                                        name="provider"
                                                        className="form-control input-air-primary digits"
                                                    >
                                                        {!provider && <option value="">Select Provider</option>}
                                                        {["Twilio", "Vonage"].map(option => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            </FormGroup>

                                            {provider === "Twilio" && (
                                                <>
                                                    <FormGroup className="row">
                                                        <Label className="col-sm-3 col-form-label" htmlFor="accountSID">Account SID</Label>
                                                        <Col sm="9">
                                                            <Input
                                                                type="text"
                                                                id="accountSID"
                                                                value={accountSID}
                                                                onChange={(e) => setAccountSID(e.target.value)}
                                                                placeholder="Enter Account SID"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="row">
                                                        <Label className="col-sm-3 col-form-label" htmlFor="authToken">AuthToken</Label>
                                                        <Col sm="9">
                                                            <Input
                                                                type="text"
                                                                id="authToken"
                                                                value={authToken}
                                                                onChange={(e) => setAuthToken(e.target.value)}
                                                                placeholder="Enter AuthToken"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </>
                                            )}
                                            {/* <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label" htmlFor="provider">Gender</Label>
                                                <Col sm="9">
                                                    <Input
                                                        type="select"
                                                        value={gender}
                                                        onChange={(e) => setGender(e)}
                                                        name="provider"
                                                        className="form-control input-air-primary digits"
                                                    >
                                                        {!gender && <option value="">Select Gender</option>}
                                                        {["Male", "Female"].map(option => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            </FormGroup> */}
                                            {/* <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Bot</Label>
                                                <Col sm="9">
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        value={bot}
                                                        onChange={(e) => setBot(e.target.value)}
                                                        placeholder="Enter bot id"
                                                    // required
                                                    />
                                                </Col>
                                            </FormGroup> */}
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label" htmlFor="provider">Bot</Label>
                                                <Col sm="9">
                                                    <Input
                                                        type="select"
                                                        value={bot}
                                                        // onChange={(e) => setBot(e.target.value)}
                                                        onChange={(e) => setBot(e.target.value)}
                                                        name="provider"
                                                        className="form-control input-air-primary digits"
                                                    >
                                                        {!bot && <option value="">Select Assitant</option>}
                                                        {completeCompanyData?.map(option => {
                                                            return (
                                                                <>
                                                                    <option key={option} value={option?.company?.assistant?.id}>{option?.company?.assistant?.bot_name}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            {loader ?
                                                <Button color="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Saving...
                                                </Button>
                                                :
                                                <Button onClick={handleSubmit} color="primary">Submit</Button>
                                            }
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <ToastContainer />

                    <Modal
                        show={deleteModal}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={handleCloseDeleteModal}
                    >
                        <Modal.Header closeButton>
                            {/* <H4> Delete Company Directory</H4> */}
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ fontWeight: '500', fontSize: '16px', textAlign: 'center' }}>Are you sure you want to delete this number?</p>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                                    {loading ?
                                        <Button color="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                        :
                                        <Button style={{ width: '100px' }} type="submit" color="primary" onClick={handleDeleteNumber}>Yes</Button>
                                    }
                                    <Button style={{ width: '100px', height: '43px' }} onClick={handleCloseDeleteModal}>Close</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={editModal}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={handleCloseEditModal}
                    >
                        <Modal.Header closeButton >
                            {/* <H4> Edit</H4> */}
                        </Modal.Header>
                        <Modal.Body>
                            <Col sm="12" style={{ padding: '10px' }}>
                                <CardBody>
                                    <Form onSubmit={handleEditNumber}>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Name</Label>
                                            <Col sm="9">
                                                <Input
                                                    type="text"
                                                    id="name"
                                                    value={editName}
                                                    onChange={(e) => setEditName(e.target.value)}
                                                    placeholder="Enter name"
                                                // required
                                                />
                                            </Col>
                                        </FormGroup>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                                                {loading ?
                                                    <Button color="primary" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Loading...
                                                    </Button>
                                                    :
                                                    <Button type="submit" color="primary">Submit</Button>
                                                }

                                                <Button style={{ height: '43px' }} onClick={handleCloseEditModal}>Close</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Col>
                        </Modal.Body>
                    </Modal>


                </Container>
            </Fragment >
        </>
    )
}

export default ManagePhone