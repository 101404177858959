import { createSlice } from '@reduxjs/toolkit';

const stripeSlice = createSlice({
    name: 'stripe',
    initialState: {
        paymentMethodData: null,
        updatePaymentData: null,
        clientSecret: null,
        cardInfo: null,
        updatePaymentDataError: null,
        updatingPaymentMethod: false,
        loadingPaymentMethod: false,
        loadingPaymentError: null,
    },
    reducers: {
        gettingPaymentMethod: (state) => {
            state.loadingPaymentMethod = true;
            state.loadingPaymentError = null;
        },
        paymentMethodSuccess: (state, action) => {
            state.loadingPaymentMethod = false;
            state.paymentMethodData = action.payload;
            if(action.payload?.status === 'existing') {
                state.cardInfo = action.payload;
                state.clientSecret = null;
            } else if(action.payload?.status === 'new')  {
                state.cardInfo = null;
                state.clientSecret = action.payload?.clientSecret;
            }
        },
        paymentMethodFetchError: (state, action) => {
            state.loadingPaymentMethod = false;
            state.loadingPaymentError = action.payload;
        },

        startUpdatingPaymentMethod: (state) => {
            state.updatingPaymentMethod = true;
        },
        updatePaymentMethodSuccess: (state, action) => {
            state.updatingPaymentMethod = false;
            state.updatePaymentData = action.payload;
        },
        updatePaymentMethodError: (state, action) => {
            state.updatingPaymentMethod = false;
            state.updatePaymentDataError = action.payload;
        },
    },
});

export const {
    gettingPaymentMethod,
    paymentMethodSuccess,
    paymentMethodFetchError,
    updatePaymentMethodSuccess,
    updatePaymentMethodError,
    startUpdatingPaymentMethod
} = stripeSlice.actions;

export default stripeSlice.reducer;
