import { createSlice } from '@reduxjs/toolkit';

const updateAssistantSlice = createSlice({
    name: 'updateAssistantSlice',
    initialState: {
        loading: false,
        error: null,
    },
    reducers: {
        startUpdatingAssistant: (state) => {
            state.loading = true;
            state.error = null;
        },
        updateAssistantSuccess: (state, action) => {
            state.loading = false;
             // action.payload;
        },
        updateAssistantError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    startUpdatingAssistant,
    updateAssistantSuccess,
    updateAssistantError,
} = updateAssistantSlice.actions;

export default updateAssistantSlice.reducer;