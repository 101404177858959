import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchCompany,
  fetchCompanyError,
  fetchCompanySuccess,
} from "./companySlice";
import { getCompleteCompanyByUser } from "./getCompleteCompanyByUser";
import { ActionTypes } from "../../redux-store/ActionTypes";
import { selectCompanyLoading } from "./companySelectors";
import {selectSelectedAssistant} from "../assistant/assistantSelectors";
import {isEmpty} from "../../../helpers/utils";

function* fetchUserCompleteCompanySaga(action) {
  const isLoading = yield select(selectCompanyLoading);
  if (isLoading) return;
  try {
    yield put(fetchCompany());
    const data = yield call(getCompleteCompanyByUser, action.payload);
    const selectedAssistant = yield select(selectSelectedAssistant);

    if(!isEmpty(selectedAssistant)) {
      const assistant = data?.company?.company?.assistant?.find(assistant => assistant?.bot_id === selectedAssistant?.bot_id);
      yield put({type: ActionTypes.SET_SELECTED_ASSISTANT, payload: {assistant: assistant}});
    }

    yield put(fetchCompanySuccess(data));
  } catch (error) {
    yield put(fetchCompanyError(error.message));
  }
}

export function* fetchUserCompleteCompany() {
  yield takeEvery(
    ActionTypes.FETCH_USER_COMPLETE_COMPANY,
    fetchUserCompleteCompanySaga
  );
}
