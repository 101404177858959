import React, { useState, Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Stepper.css';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Btn, H4, P } from '../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import roboImg from '../assets/images/logo/roboImg.png';
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';
import '../component2/ManageAssistant/ManageAssistant.css';
import tickCircle from '../assets/images/logo/tickCircle.png';
import { TiTick } from "react-icons/ti";
import InputMask from 'react-input-mask';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import PaymentStripe from './PaymentStripe';
import { Color } from '../helpers/colors';


const Payment = ({ logoClassMain }) => {
    const navigate = useNavigate();
    const location = useLocation()

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        card_number: Yup.string().required('Card number is required.'),
        expiry: Yup.string().required('Expiry is required.'),
        cvv: Yup.string().required('CVV is required.'),
    });

    const handlePaymentSubmit = async (values) => {
        navigate(`${process.env.PUBLIC_URL}/complete`)
    };

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                    minHeight: '100vh',
                }}>
                <div>
                    <Row>
                        <div style={{ marginTop: '1%' }}>
                            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                    <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                    <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                                </div>
                            </Link>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '2%',
                            }}>
                            <Card style={{ width: '1000px', padding: '0px' }}>
                                <CardBody style={{ padding: '0px' }}>
                                    <Row>
                                        <Col sm='12'>
                                            <div className="stepper-container">
                                                <div className={`step ${location.pathname === '/payment' || location.pathname === '/step1' ? 'active' : ''}`}>
                                                    <div className="circle"> {location.pathname === '/payment' || location.pathname === '/step1' ? <TiTick size={25} /> : ''}</div>
                                                    <span>Define Business</span>
                                                </div>
                                                <div className={`step ${location.pathname === '/payment' || location.pathname === '/step2' ? 'active' : ''}`}>
                                                    <div className="circle">{location.pathname === '/payment' || location.pathname === '/step1' ? <TiTick size={25} /> : ''}</div>
                                                    <span>Prepare Assistant</span>
                                                </div>
                                                <div className={`step ${location.pathname === '/payment' || location.pathname === '/step3' ? 'active' : ''}`}>
                                                    <div className="circle">{location.pathname === '/payment' || location.pathname === '/step1' ? <TiTick size={25} /> : ''}</div>
                                                    <span>Phone Setup</span>
                                                </div>
                                                <div className={`step ${location.pathname === '/payment' ? 'active' : ''}`}>
                                                    <div className="circle">04</div>
                                                    <span>Payment</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm='6' style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />
                                            <div style={{ position: 'relative', zIndex: 2, display: 'flex', padding: '20px 0px 0px 20px' }}>
                                                <div style={{ marginTop: '8px', padding: '10px 0px 0px 10px' }}>
                                                    <H4 attrH4={{ style: { color: Color.SECONDARY } }}>Prevent misuse of Fluten services</H4>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                        <P attrPara={{ style: { fontSize: '16px', } }} >No charges will be incurred, payment information is required for verification purpose and to prevent misuse of Fluten services.</P>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                        <P attrPara={{ style: { fontSize: '16px', } }} >As part of Fluten's commitment to excellence, we offer a $10 complimentary balance for you to explore our services. </P>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm='6'>
                                            <div style={{ padding: '20px 20px 0px 0px' }}>
                                                <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: '500px', display: 'flex', flexDirection: 'column' }}>
                                                    <PaymentStripe />
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    );
};

export default Payment;
