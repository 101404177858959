import { AxiosInstance, AxiosInstance2 } from './AxiosInstance';

let getuseInfo = JSON.parse(localStorage.getItem("auth0_profile"));
const getTokken = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem("login"));
let token = user ? getTokken : '';

export const createCompanyData = async (data, token) => {
    try {
        const headers = AxiosInstance2.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        const response = await AxiosInstance2.post('/createCompany', data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorDetails = error.response.data;
            if (errorDetails.error && errorDetails.error.website) {
                const errorMessage = errorDetails.error.website[0];
                console.error(`Error: ${errorMessage}`);
                // You can also throw the error or return it to be handled by the calling function
                throw new Error(`Validation Error: ${errorMessage}`);
            } else {
                console.error("Validation error occurred, but no specific details were provided.");
                throw new Error("Validation Error: An unknown validation error occurred.");
            }
        } else {
            console.error("An unexpected error occurred:", error);
            throw error;
        }
    }
};

export const getAllCountries = async (data) => {
    const response = await AxiosInstance2.get('/get_countries');
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const getAllCompanyTypes = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get('/getCompanyType');
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const getAllCompanyPackages = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get('/getPackages');
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};
export const closeCompany = async (token, data) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/close-company', data);
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const verifyPassword = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/confirnPassword', formData);
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const sendPayment = async (data) => {
    const response = await AxiosInstance2.post('/payment', data);
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const confirmPayment = async (data) => {
    const response = await AxiosInstance2.post('/paymentConfirmed', data);
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const addFundPayment = async (data) => {
    const headers = AxiosInstance2.defaults.headers;
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    const response = await AxiosInstance2.post('/chargeCustomer', data);
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const createCustomerAndSetupIntent = async (token, data) => {
    const headers = AxiosInstance2.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance2.post('/createCustomerAndSetupIntent', data);
    // console.log("🚀 ~ Create Company ~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const getColorsList = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get(`/getColorsList`);
    return response.data;
};
export const updateCustomerInfo = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/updateCustomer`, formData);
    return response.data;
};

export const getCompanyCallStats = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post(`/CompanyAllStats`, formData);
    return response.data;
};

export const getStates = async (data) => {
    const response = await AxiosInstance2.get(`/get_states?country_id=${data}`);
    // console.log("🚀 ~ get State~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const getTwilioNumbers = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance2.post(`/getTwilioNumbersList`, formData);
    // console.log("🚀 ~ get State~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const getPhoneNumbersList = async (token, formData) => {
    const headers = AxiosInstance2.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance2.post(`/getphoneList`, formData);
    return response.data;
};

export const bookNumber = async (token, formData) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance2.post(`/bookPhoneNumber`, formData);
    // console.log("🚀 ~ get State~ response:", response)
    // localStorage.setItem("login", JSON.stringify(response.data.data));
    return response.data;
};

export const updateCompanyData = async (token, data) => {
    try {
        const headers = AxiosInstance.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        headers["Content-Type"] = "multipart/form-data";
        const response = await AxiosInstance.post('/updateCompany', data);
        console.log("🚀 ~ Update Company ~ response:", response)
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorDetails = error.response.data;
            if (errorDetails.error && errorDetails.error.website) {
                const errorMessage = errorDetails.error.website[0];
                console.error(`Error: ${errorMessage}`);
                // You can also throw the error or return it to be handled by the calling function
                // throw new Error(`Validation Error: ${errorMessage}`);
                return { error: errorMessage }
            } else {
                console.error("Validation error occurred, but no specific details were provided.");
                return { error: "Validation error occurred, but no specific details were provided." }
                // throw new Error("Validation Error: An unknown validation error occurred.");
            }
        } else {
            console.error("An unexpected error occurred:", error);
            return { error: "An unexpected error occurred:" }
        }
    }

};

export const getCompanyDetails = async (company_id, token) => {
    try {
        let company = {
            company_id: company_id
        }
        const headers = AxiosInstance2.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        const response = await AxiosInstance2.post('/getCompleteCompanyByUser', company);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorDetails = error.response.data;
            if (errorDetails.error && errorDetails.error.website) {
                const errorMessage = errorDetails.error.website[0];
                console.error(`Error: ${errorMessage}`);
                // You can also throw the error or return it to be handled by the calling function
                throw new Error(`Validation Error: ${errorMessage}`);
            } else {
                console.error("Validation error occurred, but no specific details were provided.");
                throw new Error("Validation Error: An unknown validation error occurred.");
            }
        } else {
            console.error("An unexpected error occurred:", error);
            throw error;
        }
    }
};

export const getCompanyCustomers = async (id) => {
    try {
        let company_id = {
            company_id: id
        }
        const headers = AxiosInstance2.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        const response = await AxiosInstance2.post('/getCompanyCustomers', company_id);
        return response.data;
    } catch (error) {
        console.error("An unexpected error occurred:", error);
        throw error;
    }
}

export const createOpSetting = async (data, token) => {
    try {
        const headers = AxiosInstance2.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        const response = await AxiosInstance2.post('/operational-settings-add', data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorDetails = error.response.data;
            if (errorDetails.error && errorDetails.error.website) {
                const errorMessage = errorDetails.error.website[0];
                console.error(`Error: ${errorMessage}`);
                // You can also throw the error or return it to be handled by the calling function
                throw new Error(`Validation Error: ${errorMessage}`);
            } else {
                console.error("Validation error occurred, but no specific details were provided.");
                throw new Error("Validation Error: An unknown validation error occurred.");
            }
        } else {
            console.error("An unexpected error occurred:", error);
            throw error;
        }
    }
};


export const getOpSettingData = async (company_id, token) => {
    try {
        const headers = AxiosInstance2.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        const response = await AxiosInstance2.get(`/operational-settings-by-company/${company_id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorDetails = error.response.data;
            if (errorDetails.error && errorDetails.error.website) {
                const errorMessage = errorDetails.error.website[0];
                console.error(`Error: ${errorMessage}`);
                // You can also throw the error or return it to be handled by the calling function
                throw new Error(`Validation Error: ${errorMessage}`);
            } else {
                console.error("Validation error occurred, but no specific details were provided.");
                throw new Error("Validation Error: An unknown validation error occurred.");
            }
        } else {
            console.error("An unexpected error occurred:", error);
            throw error;
        }
    }
};

export const updateOpSetting = async (id, data, token) => {
    try {
        const headers = AxiosInstance2.defaults.headers;
        headers['Authorization'] = `Bearer ${token}`;
        const response = await AxiosInstance2.post(`/operational-settings`, data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const errorDetails = error.response.data;
            if (errorDetails.error && errorDetails.error.website) {
                const errorMessage = errorDetails.error.website[0];
                console.error(`Error: ${errorMessage}`);
                // You can also throw the error or return it to be handled by the calling function
                throw new Error(`Validation Error: ${errorMessage}`);
            } else {
                console.error("Validation error occurred, but no specific details were provided.");
                throw new Error("Validation Error: An unknown validation error occurred.");
            }
        } else {
            console.error("An unexpected error occurred:", error);
            throw error;
        }
    }
};