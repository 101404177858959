import {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {selectCompleteCompany} from "../../modules/company/companySelectors";
import {ActionTypes} from "../../redux-store/ActionTypes";
import {getCurrentCompany} from "../../../helpers/LocalStorageHelper";
import {isEmpty} from "../../../helpers/utils";
import {hasFeatureAccess} from "../../../helpers/PackagesHelper";
import {FEATURE} from "../../../helpers/constants";

export const useCompany = () => {
    const dispatch = useDispatch();
    const completeCompany = useSelector(selectCompleteCompany);
    const companyData = getCurrentCompany();

    const handleGetUserCompleteCompany = () => {
        const companyId = companyData?.id;
        if(!isEmpty(companyId)) {
            dispatch({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: companyId } });
        }
    };

    useEffect(() => {
        handleGetUserCompleteCompany();
    }, []);


    return {
        completeCompany,
        handleGetUserCompleteCompany
    }
}