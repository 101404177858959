import { call, put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../../redux-store/ActionTypes";
import { TOAST_TYPES} from "../../../../helpers/constants";
import {showToast} from "../../../../helpers/ToastHelper";
import {getCurrentCompany} from "../../../../helpers/LocalStorageHelper";
import {deleteAssistantError, deleteAssistantSuccess, startDeletingAssistant} from "./deleteAssistantSlice";
import {deleteAssistantApi} from "./deleteAssistantApi";

const companyData = getCurrentCompany();

function* deleteAssistantSaga(action) {
    try {
        yield put(startDeletingAssistant());
        const data = yield call(deleteAssistantApi, action.payload);
        yield put(deleteAssistantSuccess(data));
        yield put({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: companyData?.id } });
        showToast({message: data?.message});
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(deleteAssistantError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* deleteAssistant() {
    yield takeLatest(ActionTypes.DELETE_ASSISTANT, deleteAssistantSaga);
}

