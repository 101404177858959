import { call, put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../../redux-store/ActionTypes";
import {showToast} from "../../../../helpers/ToastHelper";
import {TOAST_TYPES} from "../../../../helpers/constants";
import {getBotVoiceModel} from "./getBotVoiceModel";
import {getBotVoiceModelError, getBotVoiceModelSuccess, startGettingBotVoiceModel} from "./botVoiceSlice";

function* getBotVoiceModelSaga(action) {
    try {
        yield put(startGettingBotVoiceModel());
        const data = yield call(getBotVoiceModel, action.payload);
        yield put(getBotVoiceModelSuccess(data));
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(getBotVoiceModelError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* getBotVoice() {
    yield takeLatest(ActionTypes.GET_BOT_VOICE_MODEL, getBotVoiceModelSaga);
}


