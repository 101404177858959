const assistants = {
  UPDATE_ASSISTANT: '/updateBot',
  CREATE_ASSISTANT: '/createBot',
  DELETE_ASSISTANT: '/deleteBot',
  SELECTED_BOT: '/SelectedBotNew',
  SET_UP_BOT: '/setUpBotNew',
  SET_UP_BOT_PHONE: '/setUpPhone',
}

const precastBots = {
  GET_BOT_VOICE_MODEL: "/getBotVoiceModel",
  GET_PRECAST_BOTS: "/getDefaultBots",
};

const packages = {
  GET_STRIPE_SESSION: '/getstripesession'
}

const operationSettings = {
  OPERATIONAL_SETTINGS_BY_COMPANY: 'operational-settings-by-company'
}

export const ApiEndpoints = {
  HELPDESK_CHAT: "/helpdesk/chat",
  UPDATE_USER: "/updateUser",
  GET_COMPLETE_COMPANY_BY_USER: "/getCompleteCompanyByUser",
  UPGRADE_PACKAGE: "/upgradePackage",
  GET_USER_PAYMENT_METHOD: "/user/payment-method",
  UPDATE_USER_PAYMENT_METHOD: "/user/payment-method/update",
  GET_CHILDCALLS_STATUSSUMMARY: "/getChildCallsStatusSummary",
  GET_KEYWORDS_ANALYSIS_REPORT: "/keywordsReport",
  ...operationSettings,
  ...packages,
  ...precastBots,
  ...assistants,
};