import React, { Fragment, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row, InputGroup, InputGroupText, Card, CardBody } from 'reactstrap';
import { Btn, H4, P, Image } from '../AbstractElements';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io'; // Import eye icons
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import { resetPassword } from '../api/AuthIndex';
import tickCircle from '../assets/images/logo/tickCircle.png';
import roboImg from '../assets/images/logo/roboImg.png';

const ChangePassword = ({ logoClassMain }) => {
    const navigate = useNavigate()
    const location = useLocation();  // Use useLocation to access state
    const token = location?.state?.res?.token;
    const theme = localStorage.getItem("mix_background_layout");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Check if password fields are empty
        if (password === '' || confirmPassword === '') {
            setError('Please fill in both fields.');
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        // Validate password length
        if (password.length < 8) {
            setError('Password must be at least 8 characters long.');
            return;
        }

        // Validate character mix (at least one uppercase, one lowercase, one number, and one special character)
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
            setError('Password should include uppercase, lowercase, numbers, and special characters.');
            return;
        }

        // Avoid common words and personal information
        const commonWords = ["password", "123456", "qwerty", "letmein", "admin"];
        if (commonWords.some(word => password.toLowerCase().includes(word))) {
            setError('Password should not contain common dictionary words.');
            return;
        }

        const userPersonalInfo = ["yourEmail", "yourPhoneNumber", "yourBirthday"]; // Replace with actual user's personal info if available
        if (userPersonalInfo.some(info => password.includes(info))) {
            setError('Password should not contain personal information.');
            return;
        }

        // Proceed with password reset if all checks pass
        setLoading(true);
        const formData = new FormData();
        formData.append('password', password);
        formData.append('password_confirmation', confirmPassword);

        try {
            const res = await resetPassword(token, formData);
            if (res) {
                setLoading(false);
                navigate(`${process.env.PUBLIC_URL}/login`);
            }
            console.log('Password changed successfully');
        } catch (error) {
            setError('Error resetting password');
        } finally {
            setLoading(false);
        }
    };


    return (

        <Fragment>
            <div
                style={{
                    backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                    minHeight: '100vh',
                }}>
                <section>
                    <Container className='p-0 login-page' fluid={true}>
                        <Row className='m-0'>
                            <Col className='p-0'>
                                <div>
                                    <div style={{ marginTop: '1%' }}>
                                        <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                                <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                                <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                                            </div>
                                        </Link>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '2%',
                                        }}>

                                        <Card style={{ width: '1000px', padding: '0px' }}>
                                            <CardBody style={{ padding: '0px' }}>
                                                <Row>
                                                    <Col sm='6' style={{ position: 'relative', overflow: 'hidden' }}>
                                                        <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />

                                                        <div style={{ position: 'relative', zIndex: 2, display: 'flex', padding: '20px 0px 0px 20px' }}>
                                                            <div style={{ marginTop: '8px', }}>
                                                                <H4>Change Password</H4>
                                                                <div style={{ display: 'flex', marginTop: '5px' }}>
                                                                    <P attrPara={{ style: { fontSize: '15px' } }} >Follow good security practices when creating your password.</P>
                                                                </div>
                                                                <div style={{ marginTop: '10px', fontSize: '16px', lineHeight: '1.6' }}>
                                                                    <P attrPara={{ style: { fontSize: '15px' } }} ><strong>Password length:</strong> Passwords should be at least 8 characters long, the longer the better.</P>
                                                                    <P attrPara={{ style: { fontSize: '15px' } }} ><strong>Character mix:</strong> Passwords should be a combination of uppercase and lowercase letters, numbers, and symbols.</P>
                                                                    <P attrPara={{ style: { fontSize: '15px' } }} ><strong>Uniqueness:</strong> Passwords should be unique and not used for multiple accounts.</P>
                                                                    <P attrPara={{ style: { fontSize: '15px' } }} ><strong>Avoid personal information:</strong> Passwords should not include personal information like your email, phone number, or birthday.</P>
                                                                    <P attrPara={{ style: { fontSize: '15px' } }} ><strong>Avoid reusing passwords:</strong> Passwords should be significantly different from your previous passwords.</P>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm='6'>
                                                        <div style={{ padding: '20px 20px 0px 0px' }}>
                                                            <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: '500px', display: 'flex', flexDirection: 'column' }}>
                                                                <Form className='theme-form login-form' onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                    <H4 attrH4={{ style: { color: '#FC5455' } }}>Change Password</H4>
                                                                    <P>Your new password must be different from previous used password.</P>
                                                                    <FormGroup>
                                                                        <Label className='m-0 col-form-label'>New Password</Label>
                                                                        <InputGroup>
                                                                            <Input
                                                                                className='form-control'
                                                                                type={showPassword ? 'text' : 'password'}
                                                                                placeholder='Enter new password'
                                                                                value={password}
                                                                                onChange={(e) => setPassword(e.target.value)}
                                                                            />
                                                                            <InputGroupText onClick={togglePasswordVisibility}>
                                                                                {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                                                                            </InputGroupText>
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label className='m-0 col-form-label'>Confirm New Password</Label>
                                                                        <InputGroup>
                                                                            <Input
                                                                                className='form-control'
                                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                                placeholder='Confirm new password'
                                                                                value={confirmPassword}
                                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                                            />
                                                                            <InputGroupText onClick={toggleConfirmPasswordVisibility}>
                                                                                {showConfirmPassword ? <IoIosEyeOff /> : <IoIosEye />}
                                                                            </InputGroupText>
                                                                        </InputGroup>
                                                                        {error && <span className="text-danger">{error}</span>}
                                                                    </FormGroup>
                                                                    <FormGroup style={{ marginTop: 'auto', marginLeft: '-15px' }}>
                                                                        {loading ?
                                                                            <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit', style: { width: '100%' }, disabled: loading }}>Changing...</Btn>
                                                                            :
                                                                            <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit', style: { width: '100%' } }}>Change Password</Btn>}
                                                                    </FormGroup>
                                                                </Form>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </Fragment>
    );
};

export default ChangePassword;
