import { createSlice } from '@reduxjs/toolkit';

const botVoiceSlice = createSlice({
    name: 'getBotVoiceModel',
    initialState: {
        botVoice: null,
        loading: false,
        error: null,
    },
    reducers: {
        startGettingBotVoiceModel: (state) => {
            state.loading = true;
            state.error = null;
        },
        getBotVoiceModelSuccess: (state, action) => {
            state.loading = false;
            let blobData = action.payload;
            const audioBlob = new Blob([blobData], { type: 'audio/mpeg' });
            state.botVoice = URL.createObjectURL(audioBlob);
        },
        getBotVoiceModelError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    startGettingBotVoiceModel,
    getBotVoiceModelSuccess,
    getBotVoiceModelError,
} = botVoiceSlice.actions;

export default botVoiceSlice.reducer;
