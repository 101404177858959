import { call, put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../../redux-store/ActionTypes";
import {AI_ASSISTANT_RIGHT_SIDEBAR, TOAST_TYPES} from "../../../../helpers/constants";
import {startUpdatingAssistant, updateAssistantError, updateAssistantSuccess} from "./updateAssistantSlice";
import {updateAssistantApi} from "./updateAssistantApi";
import {showToast} from "../../../../helpers/ToastHelper";
import {getCurrentCompany} from "../../../../helpers/LocalStorageHelper";

const companyData = getCurrentCompany();

function* updateAssistantSaga(action) {
    try {
        yield put(startUpdatingAssistant());
        const data = yield call(updateAssistantApi, action.payload);
        yield put(updateAssistantSuccess(data));
        yield put({ type: ActionTypes.FETCH_USER_COMPLETE_COMPANY, payload: { company_id: companyData?.id } });
        yield put({ type: ActionTypes.SET_ASSISTANT_RIGHT_SIDEBAR, payload:  { sidebar: AI_ASSISTANT_RIGHT_SIDEBAR.ASSISTANT_DETAIL }  });
        showToast({message: data?.message});
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(updateAssistantError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* updateAssistant() {
    yield takeLatest(ActionTypes.UPDATE_ASSISTANT, updateAssistantSaga);
}

