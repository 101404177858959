import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import './Stepper.css';
import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';
import roboImg from '../assets/images/logo/roboImg.png';
import solarArrow from '../assets/images/logo/solarArrow.png';
import leftImg from '../assets/images/logo/leftImg.jpeg';
import logoImg from '../assets/images/logo/logoImg.png';
import tickCircle from '../assets/images/logo/tickCircle.png';
import botImage from '../assets/images/user/meh.png';
import expand from '../assets/images/user/expand.png';
import { H4, P, Btn } from '../AbstractElements';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select'
import { FaGlobe } from 'react-icons/fa';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import LanguageSelect from '../component2/ManageAssistant/LanguageSelect';
import { getWebContent, setupBussiness } from '../api/AuthIndex';
import { createOpSetting, getAllCompanyTypes } from '../api/Company';
import ApiContext from '../ApiContext';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { ImCross } from "react-icons/im";
import { Color } from '../helpers/colors';
import { FaArrowLeft } from "react-icons/fa6";
import { formatProductsServices } from '../Constant/GlobalFunctions';


const StepDefineBusiness = ({ logoClassMain }) => {
    const { setCompanyId, setIndustyName } = useContext(ApiContext);
    const theme = localStorage.getItem("mix_background_layout")
    const userInfo = JSON.parse(localStorage.getItem("register"))
    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"))
    const location = useLocation()
    const navigate = useNavigate();
    const [selectedSize, setSelectedSize] = useState(companyInfo ? companyInfo?.selectedSize : null);
    const [renderValue, setRenderValue] = useState(companyInfo?.selectedSize == '1' ? '0-1' : companyInfo?.selectedSize == '10' ? '1-10' : companyInfo?.selectedSize == '50' ? '11-50' : companyInfo?.selectedSize == '200' ? '51-200' : companyInfo?.selectedSize == '500' ? '201-500' : companyInfo?.selectedSize == '1000' ? '501-1000' : companyInfo?.selectedSize == '5000' ? '1001-5000' : null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState(1); // Track the step of the form
    const [scanOption, setScanOption] = useState('scan');
    const [language, setLanguage] = useState('');
    const [emptyResError, setEmptyResError] = useState('');
    const [scanLoading, setScanLoading] = useState(false);
    const [check, setCheck] = useState(false);

    const [companyName, setCompanyName] = useState(companyInfo ? companyInfo?.companyName : '')
    const [domain, setDomain] = useState(companyInfo ? companyInfo?.domain : '')
    const [websiteUrl, setWebsiteUrl] = useState(companyInfo ? companyInfo?.websiteUrl : '');
    const [aboutCompany, setAboutCompany] = useState(companyInfo ? companyInfo?.aboutCompany : '');
    const [productsServices, setProductsServices] = useState(companyInfo ? companyInfo?.productsServices : '');
    const [phoneNumber, setPhoneNumber] = useState(companyInfo ? companyInfo?.phoneNumber : '');
    const [companyTypes, setCompanyTypes] = useState([]);
    const [heardAbout, setHeardAbout] = useState(companyInfo ? companyInfo?.heardAbout : '')
    const [currentPosition, setCurrentPosition] = useState(companyInfo ? companyInfo?.currentPosition : '')
    const [errors, setErrors] = useState({});
    const [webUrlErrors, setWebUrlErrors] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [productModal, setProductModal] = useState(false);
    const [contactDetailModal, setContactDetailModal] = useState(false);
    const [helpModal, setHelpModal] = useState(companyInfo?.checkBox === true ? false : true);
    const [receiveUpdates, setReceiveUpdates] = useState(false);
    const [opSetting, setOpSetting] = useState({
        id: 0,
        office_start_time: '00:00',
        office_end_time: '23:59',
        time_zone: 'Europe/Berlin',
        working_weekend: "0",
        message_not_available: "Hi, we are not available at this time please try later. Good bye",
        weekly_status_update: "1"
    });



    useEffect(() => {
        getCompanyTypes()
    }, [])

    useEffect(() => {
        // Get the user's current time zone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Set the user's time zone in the state
        setOpSetting(prevSettings => ({
            ...prevSettings,
            time_zone: userTimeZone
        }));
    }, []);

    const sizeOptions = [
        { value: '0-1', label: 'Self-employed' },
        { value: '1-10', label: '1-10' },
        { value: '11-50', label: '11-50' },
        { value: '51-200', label: '51-200' },
        { value: '201-500', label: '201-500' },
        { value: '501-1000', label: '501-1000' },
        { value: '1001-5000', label: '1001-5000' },
    ];

    const position = [
        { value: 'CEO/Founder', label: 'CEO/Founder' },
        { value: 'Manager/Director', label: 'Manager/Director' },
        { value: 'Team Lead/Supervisor', label: 'Team Lead/Supervisor' },
        { value: 'Individual Contributor/Employee', label: 'Individual Contributor/Employee' },
        { value: 'Consultant/Advisor', label: 'Consultant/Advisor' },
        { value: 'Sole Owner', label: 'Sole Owner' },
    ];

    const soleOwner = [
        { value: 'Sole Owner', label: 'Sole Owner' },
    ]

    const heard = [
        { value: 'Online Search (Google, Bing, etc.)', label: 'Online Search (Google, Bing, etc.)' },
        { value: 'Social Media(Facebook, Twitter, LinkedIn, etc.)', label: 'Social Media(Facebook, Twitter, LinkedIn, etc.)' },
        { value: 'Friends and Colleagues', label: 'Friends and Colleagues' },
        { value: 'Industry Event or Conference', label: 'Industry Event or Conference' },
        { value: 'Online Advertisement(Google Ads, Facebook Ads, etc.)', label: 'Online Advertisement(Google Ads, Facebook Ads, etc.)' },
        { value: 'Email Marketing Campaign', label: 'Email Marketing Campaign' },
        { value: 'Trade Publication or Magazine', label: 'Trade Publication or Magazine' },
    ];

    const handleNext = () => {
        const validationErrors = {};

        if (!companyName) {
            validationErrors.companyName = 'Company Name is required.';
        }
        if (!selectedSize) {
            validationErrors.selectedSize = 'Size is required.';
        }
        if (!domain) {
            validationErrors.domain = 'Industry is required.';
        }
        if (!currentPosition) {
            validationErrors.currentPosition = 'Current position is required.';
        }
        // if (!heardAbout) {
        //     validationErrors.heardAbout = 'Field is required.';
        // }
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setStep(2);
            setErrors({});
        }
    };

    const getCompanyTypes = async () => {
        try {
            let res = await getAllCompanyTypes(userInfo.token);
            setCompanyTypes(res?.cType.map((item) => {
                return {
                    value: item.id,
                    label: item.type.split(" ")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(" ")
                }
            }));
        } catch (error) {
            console.log("🚀 ~ getCompanyTypes ~ error:", error)
        }
    };

    const handleBack = () => {
        setStep(1);
    };

    const handleSubmit = async () => {
        const validationErrors = {};

        if (!aboutCompany) {
            validationErrors.aboutCompany = 'About Company is required.';
        }
        if (!productsServices) {
            validationErrors.productsServices = 'Products and Services are required.';
        }
        if (!phoneNumber) {
            validationErrors.phoneNumber = 'Contact Detail is required.';
        }

        if (scanOption === 'scan' && (!websiteUrl || websiteUrl.trim() === '')) {
            validationErrors.websiteUrl = 'Website URL is required when scanning the business website.';
        }

        setWebUrlErrors('')

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setIsSubmitting(true)
            try {
                setErrors({});
                const formData = new FormData()
                formData.append("bussiness_name", companyName)
                formData.append("bussiness_size", selectedSize)
                formData.append("bussines_domain", domain)
                formData.append("pref_lang", 'en-US')
                // formData.append("offical_phone", phoneNumber)
                formData.append("location", phoneNumber)
                formData.append("content_type", scanOption)

                let formattedUrl = websiteUrl.trim();

                // Check if URL starts with "https://"
                if (!formattedUrl.startsWith('https://')) {
                    formattedUrl = `https://${formattedUrl}`;
                }

                // Check if URL contains "www." after "https://"
                if (!formattedUrl.includes('www.', 8)) {
                    const urlWithoutProtocol = formattedUrl.replace('https://', '');
                    formattedUrl = `https://www.${urlWithoutProtocol}`;
                }

                // Append to formData
                formData.append("website_url", formattedUrl === 'https://www.' ? "" : formattedUrl);

                formData.append("about_company", aboutCompany)
                formData.append("product_company", productsServices)
                formData.append("current_position", currentPosition)
                formData.append("hear_about", heardAbout)
                formData.append("updates_system", receiveUpdates == true ? 1 : 0)
                const res = await setupBussiness(userInfo?.token, formData)
                console.log("🚀 ~ handleSubmit ~ res:", res)
                localStorage.setItem("getCompanyId", JSON.stringify(res.company.id))

                const companyInfo = {
                    companyName,
                    selectedSize,
                    domain,
                    pref_lang: 'en-US',
                    phoneNumber,
                    websiteUrl: formattedUrl === 'https://www.' ? "" : formattedUrl,
                    aboutCompany,
                    productsServices,
                    currentPosition,
                    heardAbout,
                    receiveUpdates: receiveUpdates == true ? 1 : 0,
                    checkBox: check
                };

                localStorage.setItem("companyInfo", JSON.stringify(companyInfo));

                localStorage.setItem("signup_company", JSON.stringify(res.company));
                handleOtperationalSetting(res?.company?.id)
                if (res) {
                    navigate(`${process.env.PUBLIC_URL}/assistant`, { state: { domain } });
                }
                setIsSubmitting(false)
            } catch (error) {
                setIsSubmitting(false)
                console.log("🚀 ~ handleSubmit ~ error:", error)
                toast.error(error.response.data.error.bussiness_name[0], {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast-error',
                })
                setIsSubmitting(false)
            }
        }
    };

    const handleOtperationalSetting = async (id) => {
        const formData = new FormData();
        formData.append('company_id', id)
        formData.append('office_start_time', opSetting?.office_start_time + ':00');
        formData.append('office_end_time', opSetting?.office_end_time + ':00');
        formData.append('time_zone', opSetting?.time_zone);
        formData.append('working_weekend', opSetting?.working_weekend);
        formData.append('message_not_available', opSetting?.message_not_available)
        formData.append('weekly_status_update', opSetting?.weekly_status_update);
        const res = await createOpSetting(formData, userInfo?.token);
    }

    const handleScanOptionChange = (e) => {
        setScanOption(e.target.value);
        setErrors({})
        // setAboutCompany('')
        // setProductsServices('')
        // setPhoneNumber('')
    };


    const handleWebContent = async () => {
        const urlPattern = /^(https?:\/\/)/;
        setErrors({})
        setEmptyResError('')
        if (websiteUrl !== '') {
            setScanLoading(true)
            try {
                const formData = new FormData();
                let formattedUrl = websiteUrl.trim();

                // Check if URL starts with "https://"
                if (!formattedUrl.startsWith('https://')) {
                    formattedUrl = `https://${formattedUrl}`;
                }

                // Check if the URL has a subdomain (by detecting if there are more than two dots, i.e., "subdomain.domain.com")
                const domainParts = formattedUrl.replace('https://', '').split('.');
                const hasSubdomain = domainParts.length > 2;

                // Add "www." only if there is no subdomain
                if (!hasSubdomain && !formattedUrl.includes('www.', 8)) {
                    const urlWithoutProtocol = formattedUrl.replace('https://', '');
                    formattedUrl = `https://www.${urlWithoutProtocol}`;
                }

                // Append to formData
                formData.append("url", formattedUrl === 'https://www.' ? "" : formattedUrl);

                const res = await getWebContent(userInfo?.token, formData);

                if (res === null || res === 'website does not exist') {
                    setEmptyResError('Website does not exist.')
                }

                const contactDetails = `${res.contact?.address1 || ''}\n${res.contact?.address2 || ''}\n${res.contact?.address || ''}\n${res.contact?.email || ''}\n${res.contact?.phone || ''}`.trim();

                setAboutCompany(res.about);
                setProductsServices(res.products_services);

                setPhoneNumber(contactDetails || '');
                setScanLoading(false)
            } catch (error) {
                console.error("Error fetching web content:", error);
                setScanLoading(false)
            }
        } else {
            setWebUrlErrors('Please enter you website.')
        }
    };

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
        // if (errors.companyName) {
        //     setErrors((prevErrors) => ({ ...prevErrors, companyName: null }));
        // }
    };

    const handleCurrentPositionChange = (e) => {
        setCurrentPosition(e.value);
        setErrors({})
        // if (errors.currentPosition) {
        //     setErrors((prevErrors) => ({ ...prevErrors, currentPosition: null }));
        // }
    };

    const handleHeardAboutChange = (e) => {
        setHeardAbout(e.value);
        // if (errors.heardAbout) {
        //     setErrors((prevErrors) => ({ ...prevErrors, heardAbout: null }));
        // }
    };

    const handleSizeChange = (e) => {
        setCurrentPosition(null)
        const value = e.value;
        const largerValue = value.split('-')[1];
        setSelectedSize(largerValue);
        setRenderValue(value)

        // if (errors.selectedSize) {
        //     setErrors((prevErrors) => ({ ...prevErrors, selectedSize: null }));
        // }
    };

    const handleDomainChange = (e) => {
        setDomain(e.value);
        setCompanyId(e.value)
        setIndustyName(e.label)
        // if (errors.domain) {
        //     setErrors((prevErrors) => ({ ...prevErrors, domain: null }))
        // }
    };

    const handleCheckboxChange = () => {
        setReceiveUpdates(!receiveUpdates)
    }

    const handleLanguageChange = (selectedOption) => {
        setLanguage(selectedOption);
        if (errors.language) {
            setErrors((prevErrors) => ({ ...prevErrors, language: null }));
        }
    };


    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleProductShow = () => setProductModal(true);
    const handleProductClose = () => setProductModal(false);

    const handleContactShow = () => setContactDetailModal(true);
    const handleContactClose = () => setContactDetailModal(false);

    const handleHelpModalShow = () => setHelpModal(true);
    const handleHelpModalClose = () => setHelpModal(false);


    const viewBioPopover = (
        <Popover id="popover-view-bio">
            <Popover.Body>Full View</Popover.Body>
        </Popover>
    );

    const handleWebUrl = (e) => {
        setWebUrlErrors('')
        setEmptyResError('')
        setErrors({})
        setWebsiteUrl(e.target.value)
    }


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#efefef' : '#efefef', // Red border
            boxShadow: state.isFocused ? '#efefef' : 'none',
            '&:hover': {
                borderColor: '#efefef', // Red border on hover
            },
            color: '#efefef', // Text color for control
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'gray', // Text color for the selected value
            fontWeight: '300',
        }),
        menu: (provided) => ({
            ...provided,
            color: '#efefef', // Text color for the dropdown options
            backgroundColor: theme === 'dark-only' ? 'white' : 'white', // Optional: Dark background for dropdown (to match dark themes)
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'white' : 'white', // Background for selected and non-selected
            color: 'gray', // Text color for options
            '&:hover': {
                backgroundColor: 'rgba(66, 103, 245,0.7)',
                color: 'white'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'gray',
            fontWeight: 300,
        })
    };


    return (
        <>
            <Fragment>
                <div
                    style={{
                        backgroundImage: `url(${require('../assets/images/login/dottedBackImg.jpg')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        display: 'block',
                        minHeight: '100vh',
                    }}>
                    <Container className='p-0 login-page' fluid={true}>
                        <Row className='m-0'>
                            <Link style={{ marginTop: '1%' }} className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px 0px 10px 0px' }}>
                                    <img className='for-light' src={logoWhite} alt='loginpage' style={{ width: '20%' }} />
                                    <img className='for-dark' src={logoDark} alt='loginpage' style={{ width: '20%' }} />
                                </div>
                            </Link>
                            <Col className='p-0'>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '2%',
                                    }}>
                                    <Card style={{ width: '1000px', padding: '0px' }}>
                                        <CardBody style={{ padding: '0px' }}>

                                            <div>
                                                <Row>
                                                    <Col sm='12'>
                                                        <div className="stepper-container">
                                                            <div className={`step ${location.pathname === '/business' ? 'active' : ''}`}>
                                                                <div className="circle">01</div>
                                                                <span>Define Business</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/assistant' ? 'active' : ''}`}>
                                                                <div className="circle">02</div>
                                                                <span>Prepare Assistant</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/setup-phone' ? 'active' : ''}`}>
                                                                <div className="circle">03</div>
                                                                <span>Phone Setup</span>
                                                            </div>
                                                            <div className={`step ${location.pathname === '/payment' ? 'active' : ''}`}>
                                                                <div className="circle">04</div>
                                                                <span>Payment</span>
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    <Col sm="6" className="col-no-scroll" style={{ position: 'relative', overflowX: 'hidden', overflowY: 'auto', }}>
                                                        <div style={{ position: 'absolute', top: 0, left: 13, width: '100%', height: '100%', backgroundImage: `url(${roboImg})`, backgroundPosition: 'bottom left', backgroundRepeat: 'no-repeat', backgroundSize: '80%', opacity: 0.5, zIndex: 1 }} />

                                                        <div style={{ position: 'relative', zIndex: 2, padding: '20px 0px 0px 20px' }}>
                                                            {/* {step == 2 && <img className='for-light' src={solarArrow} alt='loginpage' style={{ width: '30px', height: '30px', marginTop: '18px', cursor: 'pointer', marginRight: '10px' }} onClick={handleBack} />} */}
                                                            {step == 2 &&
                                                                <div onClick={handleBack} style={{ cursor: 'pointer', backgroundColor: Color.SECONDARY, padding: '10px', width: '35px', height: '35px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <FaArrowLeft size={20} color={Color.WHITE} />
                                                                </div>
                                                            }

                                                            <div style={{ marginTop: '20px' }}>
                                                                {step === 1 && <H4 attrH4={{ style: { color: '#FF5757' } }}>Define Your Business</H4>}
                                                                {step === 2 && <H4 attrH4={{ style: { color: '#FF5757', fontSize: '20px' } }}>Add website URL or add details manually.</H4>}
                                                                {step === 1 &&
                                                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                        <P attrPara={{ style: { fontSize: '18px' } }} >This information will help your AI assistant understand your business, so it can communicate the basic details to your customers.</P>
                                                                    </div>
                                                                }

                                                                {step === 2 &&
                                                                    <>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '16px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Scan my Business Website</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '16px',marginLeft: '10px' } }} >Your AI assistant will scan your website to gather important information in order to fully understand your business. You can edit the information once it’s generated to your liking.</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '16px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Manually Enter Your Business</P>
                                                                        </div>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '16px',marginLeft: '10px' } }} >Enter all your company details, such as vision and mission, products and services, and location, in order to provide data to your AI assistant.</P>
                                                                        </div>
                                                                        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '16px', fontWeight: '500', backgroundColor: Color.LIGHT_BLUE, padding: '5px 10px', borderRadius: '8px' } }} >Why Fluten Needs This Information?</P>
                                                                        </div> */}
                                                                        <div style={{ marginTop: '15px' }}>
                                                                            <P attrPara={{ style: { fontSize: '16px', fontWeight: '500' } }} >With this information, your AI assistant can answer customer questions such as:</P>
                                                                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                                                                <li style={{ fontSize: '16px' }}>What does this company do?</li>
                                                                                <li style={{ fontSize: '16px' }}>What products and services does the company offer?</li>
                                                                                <li style={{ fontSize: '16px' }}>Where is your business located?</li>
                                                                            </ul>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div style={{ padding: '20px 20px 0px 0px' }}>
                                                            <Card style={{ boxShadow: '1px 1px 10px 0px lightgrey', padding: '20px', height: step === 2 ? 'auto' : 'auto', display: 'flex', flexDirection: 'column' }}>
                                                                <Form className="theme-form login-form" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                                                    <H4 attrH4={{ style: { color: '#FF5757' } }}>Define Your Business</H4>

                                                                    {step === 1 && (
                                                                        <>
                                                                            <FormGroup>
                                                                                <label>Company Name</label>
                                                                                <input type="text" className="form-control" value={companyName} placeholder="Enter Company Name" maxLength={30} onChange={handleCompanyNameChange} />
                                                                                {errors.companyName && (
                                                                                    <div className="text-danger">{errors.companyName}</div>
                                                                                )}
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <label>Size (in terms of employees)</label>
                                                                                <Select
                                                                                    options={sizeOptions}
                                                                                    placeholder="e.g 1-9"
                                                                                    onChange={handleSizeChange}
                                                                                    value={sizeOptions.find(option => option.value === renderValue)}
                                                                                    styles={customStyles}
                                                                                />
                                                                                {errors.selectedSize && <div className="text-danger">{errors.selectedSize}</div>}
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <label>Industry</label>
                                                                                <Select
                                                                                    options={companyTypes} // Replace this with your industry options array
                                                                                    placeholder="e.g Bakery, Real estate"
                                                                                    onChange={handleDomainChange}
                                                                                    value={Array.isArray(companyTypes) ? companyTypes.find(option => option.value === domain) : null}
                                                                                    styles={customStyles}
                                                                                />
                                                                                {errors.domain && (
                                                                                    <div className="text-danger">{errors.domain}</div>
                                                                                )}
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <label>Your current position</label>
                                                                                <Select
                                                                                    options={selectedSize === '1' ? soleOwner : position} // Replace this with your industry options array
                                                                                    placeholder="Current position"
                                                                                    onChange={handleCurrentPositionChange}
                                                                                    value={currentPosition && position.find(option => option.value === currentPosition)}
                                                                                    styles={customStyles}
                                                                                />
                                                                                {errors.currentPosition && (
                                                                                    <div className="text-danger">{errors.currentPosition}</div>
                                                                                )}
                                                                            </FormGroup>

                                                                            {/* <FormGroup>
                                                                                <label>Where did you heard about Fluten.ai</label>
                                                                                <Select
                                                                                    options={heard} // Replace this with your industry options array
                                                                                    placeholder="Heard about"
                                                                                    onChange={handleHeardAboutChange}
                                                                                    value={heard.find(option => option.value === heardAbout)}
                                                                                    styles={customStyles}
                                                                                />
                                                                                {errors.heardAbout && (
                                                                                    <div className="text-danger">{errors.heardAbout}</div>
                                                                                )}
                                                                            </FormGroup> */}



                                                                            {/* <FormGroup>
                                                                                <label>Where did you heard about Fluten.ai</label>
                                                                                <input type="text" className="form-control" placeholder="Where did you heard about Fluten.ai" onChange={handleHeardAboutChange} />
                                                                                {errors.heardAbout && (
                                                                                    <div className="text-danger">{errors.heardAbout}</div>
                                                                                )}
                                                                            </FormGroup> */}

                                                                            {/* <FormGroup>
                                                                                <label>
                                                                                    <input type="checkbox" onChange={handleCheckboxChange} style={{ backgroundColor: 'red', width: '15px', height: '15px' }} />
                                                                                    {' '}Would you like to receive updates about our system
                                                                                </label>

                                                                                {errors.checkbox && (
                                                                                    <div className="text-danger">{errors.checkbox}</div>
                                                                                )}
                                                                            </FormGroup> */}

                                                                            <FormGroup style={{ marginTop: 'auto', width: '100%' }}>
                                                                                <Button
                                                                                    color='primary'
                                                                                    disabled={isSubmitting}
                                                                                    onClick={handleNext}
                                                                                    style={{ width: '95%' }}
                                                                                >
                                                                                    Next
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </>
                                                                    )}

                                                                    {step === 2 && (
                                                                        <>
                                                                            <FormGroup tag="fieldset">
                                                                                <FormGroup check>
                                                                                    <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <input
                                                                                            type="radio"
                                                                                            value="scan"
                                                                                            checked={scanOption === 'scan'}
                                                                                            onChange={handleScanOptionChange}
                                                                                            className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                        />
                                                                                        <span style={{ marginTop: '5px', marginLeft: '5px' }}>Scan my business website</span>
                                                                                    </label>
                                                                                </FormGroup>
                                                                                <FormGroup check>
                                                                                    <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <input
                                                                                            type="radio"
                                                                                            value="manual"
                                                                                            checked={scanOption === 'manual'}
                                                                                            onChange={handleScanOptionChange}
                                                                                            className={`form-check-input ${theme === 'dark-only' ? 'dark-theme-field' : ""}`}
                                                                                            style={{ backgroundColor: theme === 'dark-only' ? '#1D1E26' : 'white' }}
                                                                                        />
                                                                                        <span style={{ marginTop: '5px', marginLeft: '5px' }}>Manually enter your business </span>
                                                                                    </label>
                                                                                </FormGroup>
                                                                            </FormGroup>

                                                                            {scanOption === 'scan' && (
                                                                                <>
                                                                                    <FormGroup>
                                                                                        <label>Website URL</label>
                                                                                        <div className="position-relative">
                                                                                            <input
                                                                                                type="url"
                                                                                                className="form-control"
                                                                                                placeholder="https://"
                                                                                                value={websiteUrl}
                                                                                                style={{ paddingRight: '80px', paddingLeft: '35px' }}
                                                                                                onChange={(e) => { handleWebUrl(e) }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === 'Enter') {
                                                                                                        e.preventDefault(); // Prevent default Enter behavior
                                                                                                        handleWebContent(); // Call the function to scan the website content
                                                                                                    }
                                                                                                }}
                                                                                            />

                                                                                            <div className="input-group-prepend" style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                                                                                <span className="input-group-text bg-transparent border-0 p-0">
                                                                                                    <FaGlobe />
                                                                                                </span>
                                                                                            </div>

                                                                                            <Button
                                                                                                // className="btn btn-primary"
                                                                                                type="button"
                                                                                                style={{
                                                                                                    position: 'absolute',
                                                                                                    top: '29%',
                                                                                                    right: '-9px',
                                                                                                    transform: 'translateY(-50%)',
                                                                                                    height: '100%',
                                                                                                    borderTopLeftRadius: '0',
                                                                                                    borderBottomLeftRadius: '0',
                                                                                                    width: '100px'
                                                                                                }}
                                                                                                color={'primary'}
                                                                                                disabled={scanLoading}
                                                                                                onClick={handleWebContent}
                                                                                            >
                                                                                                {scanLoading ? 'Scan...' : 'Scan'}
                                                                                            </Button>
                                                                                        </div>

                                                                                        {emptyResError && <div className="text-danger">{emptyResError}</div>}
                                                                                        {webUrlErrors && <div className="text-danger">{webUrlErrors}</div>}
                                                                                        {errors.websiteUrl && <div className="text-danger">{errors.websiteUrl}</div>}
                                                                                    </FormGroup>
                                                                                </>
                                                                            )}

                                                                            <FormGroup>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <label>About Company</label>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                                        {/* OverlayTrigger for the FaExpandArrowsAlt "View Bio" */}
                                                                                        <OverlayTrigger trigger="hover" placement="top" overlay={viewBioPopover}>
                                                                                            <img
                                                                                                style={{ width: '15px', height: '15px', marginRight: '10px', cursor: 'pointer' }}
                                                                                                src={expand}
                                                                                                alt="View About Company"
                                                                                                onClick={handleShow} // Open modal on click
                                                                                            />
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <textarea className="form-control" rows="2" placeholder="Enter details about your company"
                                                                                    value={aboutCompany ? aboutCompany : ''}
                                                                                    onChange={(e) => {
                                                                                        setAboutCompany(e.target.value);
                                                                                        if (errors.aboutCompany) {
                                                                                            setErrors((prevErrors) => ({ ...prevErrors, aboutCompany: null }));
                                                                                        }
                                                                                    }} />
                                                                                {errors.aboutCompany && <div className="text-danger">{errors.aboutCompany}</div>}
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <label>Products And Services</label>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                                        {/* OverlayTrigger for the FaExpandArrowsAlt "View Bio" */}
                                                                                        <OverlayTrigger trigger="hover" placement="top" overlay={viewBioPopover}>
                                                                                            <img
                                                                                                style={{ width: '15px', height: '15px', marginRight: '10px', cursor: 'pointer' }}
                                                                                                src={expand}
                                                                                                alt="Full View"
                                                                                                onClick={handleProductShow} // Open modal on click
                                                                                            />
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <textarea className="form-control" rows="2" placeholder="Enter product and services"
                                                                                    value={productsServices ? productsServices : ''}
                                                                                    onChange={(e) => {
                                                                                        setProductsServices(e.target.value);
                                                                                        if (errors.productsServices) {
                                                                                            setErrors((prevErrors) => ({ ...prevErrors, productsServices: null }));
                                                                                        }
                                                                                    }}
                                                                                    onKeyPress={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            e.preventDefault();
                                                                                            setProductsServices((prev) => prev + '\n');
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {errors.productsServices && <div className="text-danger">{errors.productsServices}</div>}
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <label>Location</label>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                                        <OverlayTrigger trigger="hover" placement="top" overlay={viewBioPopover}>
                                                                                            <img
                                                                                                style={{ width: '15px', height: '15px', marginRight: '10px', cursor: 'pointer' }}
                                                                                                src={expand}
                                                                                                alt="Full View"
                                                                                                onClick={handleContactShow} // Open modal on click
                                                                                            />
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    rows="2"
                                                                                    placeholder="Enter location details"
                                                                                    value={phoneNumber ? phoneNumber : ''}
                                                                                    onChange={(e) => {
                                                                                        setPhoneNumber(e.target.value);
                                                                                        if (errors.phoneNumber) {
                                                                                            setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: null }));
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                                                                            </FormGroup>

                                                                            <FormGroup style={{ marginTop: 'auto', width: '100%' }}>
                                                                                <Button
                                                                                    color='primary'
                                                                                    disabled={isSubmitting}
                                                                                    onClick={handleSubmit}
                                                                                    style={{ width: '95%' }}
                                                                                >
                                                                                    {isSubmitting ? 'Next...' : 'Next'}
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </>
                                                                    )}
                                                                </Form>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Modal show={showModal} centered size='lg' onHide={handleClose}>
                        {/* <Modal.Header closeButton>

                        </Modal.Header> */}
                        <Modal.Body>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Modal.Title style={{ textAlign: 'left' }}>About Company</Modal.Title>
                                <ImCross size={15} onClick={handleClose} style={{ cursor: 'pointer' }} />
                            </div>
                            <textarea
                                className="form-control"
                                rows="20"
                                placeholder="Enter details about your company"
                                value={aboutCompany}
                                onChange={(e) => setAboutCompany(e.target.value)}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal show={productModal} centered size='lg' onHide={handleProductClose}>
                        <Modal.Body>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Modal.Title style={{ textAlign: 'left' }}>Products And Services</Modal.Title>
                                <ImCross size={15} onClick={handleProductClose} style={{ cursor: 'pointer' }} />
                            </div>
                            <textarea
                                className="form-control"
                                rows="20"
                                placeholder="Enter product and services"
                                value={productsServices}
                                onChange={(e) => setProductsServices(e.target.value)}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal show={contactDetailModal} centered size='lg' onHide={handleContactClose}>
                        <Modal.Body>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Modal.Title style={{ textAlign: 'left' }}>Contact Detail</Modal.Title>
                                <ImCross size={15} onClick={handleContactClose} style={{ cursor: 'pointer' }} />
                            </div>
                            <textarea
                                className="form-control"
                                rows="20"
                                placeholder="Enter contact detail"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </Modal.Body>
                    </Modal>
                    <ToastContainer />
                </div>
            </Fragment >
        </>
    )
}

export default StepDefineBusiness