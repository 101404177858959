import React, { useState } from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router";
import { confirmPayment, sendPayment } from "../../api/Company";
import { STRIPE_PUBLIC_KEY } from "../../helpers/constants";

const CheckoutForm = ({ amount, id }) => {
  console.log("🚀 ~ CheckoutForm ~ id:", id);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const { error: submitError } = await elements.submit();
  //     if (submitError) {
  //       setErrorMessage(submitError.message);
  //       return;
  //     }

  //     let price = {
  //       amount: amount,
  //     };

  //     const res = await sendPayment(price);
  //     const data = await res.client_secret;

  //     let abc = { client_secret: data };
  //     const { client_secret: clientSecret } = abc;

  //     if (!stripe || !elements || !clientSecret) {
  //       return;
  //     }

  //     const baseUrl = "https://yoda.fluten.ai";
  //     const localUrl = "http://127.0.0.1:3000";

  //     const { error, paymentIntent } = await stripe.confirmPayment({
  //       elements,
  //       clientSecret,
  //       redirect: "if_required",
  //     });

  //     if (error) {
  //       setErrorMessage(error.message);
  //     } else {
  //       if (paymentIntent.status === "succeeded") {
  //         console.log("Payment successful, Transaction ID:", paymentIntent.id);
  //       } else if (paymentIntent.status === "requires_action") {
  //         // Handle the additional action required
  //         console.log("Additional action required:", paymentIntent);
  //       }
  //       // Handle other statuses as needed
  //     }
  //   } catch (error) {
  //     console.error("Error in handleSubmit:", error);
  //     setErrorMessage("An error occurred while processing your payment.");
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    try {
      // Submitting the PaymentElement if it contains inputs that require submission
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        return;
      }

      // Prepare the payment data
      let price = {
        company_id: id,
        amount: amount,
      };

      // Send payment data to your backend to create a PaymentIntent and get the client_secret
      const res = await sendPayment(price);
      const data = await res.client_secret;
      const clientSecret = data;

      if (!stripe || !elements || !clientSecret) {
        return;
      }

      const baseUrl = "https://yoda.fluten.ai";
      const localUrl = "http://127.0.0.1:3000";

      // Confirm the payment without redirection
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${localUrl}/app/thankyou`,
        },
        redirect: "if_required", // Ensures the page won't redirect unless necessary
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        if (paymentIntent?.status === "succeeded") {
          let formData = {
            company_id: id,
            amount: amount,
            transaction_id: paymentIntent.id,
          };
          const res = await confirmPayment(formData);
          if (res) {
            setIsProcessing(false);
            navigate(`${process.env.PUBLIC_URL}/app/thankyou`);
          }
        } else if (paymentIntent?.status === "requires_action") {
          alert(
            "Additional authentication is required. Please follow the instructions."
          );
          setIsProcessing(false);
        } else {
          setIsProcessing(false);
          alert(`Payment status: ${paymentIntent?.status}`);
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setErrorMessage("An error occurred while processing your payment.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          style={{
            backgroundColor: "#5978D4",
            color: "white",
            borderRadius: "5px",
            marginTop: "40px",
            width: "100%",
            height: "40px",
            border: "1px solid #5978D4",
          }}
          type="submit"
          disabled={!stripe || !elements || isProcessing} // Disable based on isProcessing
        >
          {isProcessing ? "Processing..." : "Pay Now"}
        </button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

console.log(process.env.STRIPE_KEY);
// debugger;

const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  appearance: {},
};

const StripePayment = ({ price, companyId }) => {
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm amount={price} id={companyId} />
    </Elements>
  );
};

export default StripePayment;
