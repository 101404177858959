import { call, put, takeLatest } from 'redux-saga/effects';
import {ActionTypes} from "../../../redux-store/ActionTypes";
import {TOAST_TYPES} from "../../../../helpers/constants";
import {showToast} from "../../../../helpers/ToastHelper";
import {getDefaultBotsError, getDefaultBotsSuccess, startGettingDefaultBots} from "./defaultBotsSlice";
import {getDefaultBotsApi} from "./getDefaultBotsApi";

function* getDefaultBotsSaga(action) {
    try {
        yield put(startGettingDefaultBots());
        const data = yield call(getDefaultBotsApi, action.payload);
        yield put(getDefaultBotsSuccess(data));
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Something went wrong.";
        yield put(getDefaultBotsError(errorMessage));
        showToast({type: TOAST_TYPES.DANGER, message: errorMessage});
    }
}

export function* getDefaultBots() {
    yield takeLatest(ActionTypes.GET_PRECAST_BOTS, getDefaultBotsSaga);
}

