import {
  call,
  put,
  select,
  debounce,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import {
  fetchCallLog,
  fetchCallLogError,
  fetchCallLogSuccess,
  setChartData,
  setTableData,
} from "./callLogSlice";
import { ActionTypes } from "../../redux-store/ActionTypes";
import { getChildCallsStatusSummary } from "./getChildCallsStatusSummary";
import { selectCallLogCompanyLoading } from "./callLogSelectors";
import { showToast } from "../../../helpers/ToastHelper";

function* fetchCallLogSaga(action) {
  const isLoading = yield select(selectCallLogCompanyLoading);
  if (isLoading) return;

  try {
    yield put(fetchCallLog());
    const data = yield call(getChildCallsStatusSummary, action.payload);
    yield put(fetchCallLogSuccess(data));
    const convertData = () => {
      const records = Object.values(data.records).flat(2);
      return { records };
    };

    let resTableData = convertData(data);

    const data1 = resTableData?.records.map((item) => item.keyword);
    const data2 = resTableData?.records.map((item) => item.frequency);
    const filteredRecords = resTableData?.records.filter(
      (record) => record.status === "no-answer"
    );

    const departments = [
      ...new Set(resTableData?.records.map((record) => record.department)),
    ];

    const setDaepartmentData = departments.map((department) => {
      const filteredRecords = resTableData?.records.filter(
        (record) => record.department === department
      );

      const series = filteredRecords.map((record) =>
        parseFloat(record.this_status)
      );
      const labels = filteredRecords.map((record) => record.status);

      return {
        department,
        series,
        options: {
          chart: {
            width: 200,
            type: "donut",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "50%",
              },
            },
          },
          labels: labels,
          responsive: [
            {
              breakpoint: 280,
              options: {
                chart: {
                  width: 100,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    });
    if (data?.records?.length === 0) {
      showToast({ message: data?.message });
    }

    yield put(setTableData(filteredRecords));
    yield put(setChartData(setDaepartmentData));
  } catch (error) {
    yield put(fetchCallLogError(error.message));
  }
}

export function* fetchCallLogCompany() {
  yield takeEvery(ActionTypes.FETCH_CALL_LOG_COMPANY, fetchCallLogSaga);
}
