import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { deletePhoneDirectory, deleteSingleItemDirectory, getCreateDepartment, getDataTable, getDepartments, getDepartmentsWiseStaff, singleItemDirectory, updateSingleItemDirectory } from '../../api/AuthIndex';
import DataTable from 'react-data-table-component';
import { H4, H5, Progressbar } from '../../AbstractElements';
import '../ManageDirectory/styles.css'
import DropdownCommon from '../../Components/Common/Dropdown';
import { Modal, OverlayTrigger, Popover, ProgressBar, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import Alerting from './Alert';
import '../ManageAssistant/ManageAssistant.css'
import SweetAlert from 'sweetalert2';
import { formatMobileNumber } from '../../Constant/GlobalFunctions';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Select from 'react-select'
import NoDataFound from '../NoDataFound/NoDataFound';
import { hasFeatureAccess } from "../../helpers/PackagesHelper";
import { debounceTime, FEATURE } from "../../helpers/constants";
import { useCompanyContext } from "../../Store/providers/Company/CompanyProvider";
import { LOGIN } from "../../Constant";
import UpgradePopup from "../../Components/UpgradePopup/UpgradePopup";
import { useSelector } from 'react-redux';
import { selectCompleteCompany } from '../../Store/modules/company/companySelectors';


const DataTables = () => {
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    const theme = localStorage.getItem("mix_background_layout")
    let token = localStorage?.getItem('token')

    const companyData = useSelector(selectCompleteCompany);
    console.log("🚀 ~ DataTables ~ companyData:", companyData)


    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [editName, setEditName] = useState('');
    const [deleteItem, setDeleteItem] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [userId, setUserId] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [directoryModal, setDirectoryModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [deparments, setDeparments] = useState()
    const [deparmentsDropdown, setDeparmentsDropdown] = useState()
    const [deparmentsWiseData, setDeparmentsWiseData] = useState()
    const [selectDepartment, setSelectDepartment] = useState()
    const [selectEditDepartment, setSelectEditDepartment] = useState('')
    const [selectedEditDepartmentId, setSelectedEditDepartmentId] = useState('')
    const [departmentName, setDepartmentName] = useState()
    const [departmentModal, setDepartmentModal] = useState(false)
    const [deparmentsTableModal, setDeparmentsTableModal] = useState(false)
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [addPhoneModal, setAddPhoneModal] = useState()
    const [spinner, setSpinner] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [directoryData, setDirectoryData] = useState([]);

    const isFirstLoad = useRef(true);

    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
    };

    const validateForm = () => {
        let valid = true;
        if (!name) {
            setNameError('Name is required');
            valid = false;
        }
        if (!phone) {
            setPhoneError('Phone number is required');
            valid = false;
        } else if (!/^\+[1-9]\d{1,14}$/.test(phone)) {
            setPhoneError('Invalid phone number format');
            valid = false;
        }
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setLoader(true)

        if (
            companyData?.company?.company?.assistant &&
            Array.isArray(companyData.company.company.assistant)
        ) {
            const isPhoneExist = companyData.company.company.assistant.some(
                (assistant) => assistant.phone_number === phone
            );

            if (isPhoneExist) {
                toast.error("This number cannot be used as Forwarding Number.", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast-error',
                });
                setLoader(false)
                return;
            }
        }

        try {
            const formData = new FormData()

            formData.append("company_id", getCompanyInfo?.id)
            formData.append("title", name)
            formData.append("phone_number", phone)
            formData.append("fallback", 0)
            formData.append("department_id", selectDepartment ? selectDepartment : "")
            const res = await singleItemDirectory(token, formData)
            toast.info("Successfully number added!..", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            handleTableData()
            handleGetDepartmentsWiseData()
            setName('');
            setPhone('');
            setSelectDepartment('')
            setLoader(false)
            setAddPhoneModal(false)
        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error)
            setLoader(false)
            toast.error("Invalid phone number.", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            });
            // setAddPhoneModal(false)
        }


    };

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("id", userId)
            formData.append("company_id", getCompanyInfo?.id)

            const res = await deleteSingleItemDirectory(token, formData)
            toast.info("Successfully deleted!..", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            handleTableData()
            setEditName('');
            setEditPhone('');
            setDeleteModal(false)
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ handleDeleteSubmit ~ error:", error)
            setLoading(false)
        }

    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("id", userId);
            formData.append("title", editName);
            formData.append("phone_number", editPhone);
            formData.append("fallback", 0);
            formData.append("department_id", selectedEditDepartmentId ? selectedEditDepartmentId : '');

            const res = await updateSingleItemDirectory(token, formData);
            toast.info("Update was successful", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            });
            handleTableData();

            setEditName('');
            setEditPhone('');
            setEditModal(false);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ handleEditSubmit ~ error:", error);
            setLoading(false);
        }
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const { completeCompany } = useCompanyContext();

    const [tableData, setTableData] = useState()
    console.log("🚀 ~ DataTables ~ tableData:", tableData)

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleTableData()
        }, debounceTime);
        return () => clearTimeout(delayDebounceFn);

    }, [selectedId])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleGetDepartments()
            handleGetDepartmentsWiseData()
        }, debounceTime);
        return () => clearTimeout(delayDebounceFn);
    }, [])

    const handleTableData = async () => {
        if (isFirstLoad.current) {
            setSpinner(true); // Only set spinner true on the first load
            isFirstLoad.current = false; // Mark the first load as complete
        }

        try {
            const formData = new FormData();
            formData.append("company_id", getCompanyInfo?.id);

            if (selectedId == Number(selectedId)) {
                formData.append("department_id", selectedId);
            }

            const res = await getDataTable(token, formData);
            setDirectoryData(res?.Directory);
            setTableData(res?.Directory?.map((item) => {
                return {
                    person: item.title,
                    phone: formatMobileNumber(item.phone_number),
                    item: item,
                    daprtmentName: item.department,
                    departmentId: item.department_id

                }
            }));

        } catch (error) {
            console.log("🚀 ~ handleTableData ~ error:", error);
        } finally {
            setSpinner(false); // Ensure spinner is hidden after data load
        }
    };

    const DropdownMenu = ["Edit", "Delete"];


    const handleEdit = (row) => {
        console.log("🚀 ~ handleEdit ~ row:", row)
        setEditName(row?.person);
        setEditPhone(row?.phone);
        setUserId(row?.item?.id);
        setSelectEditDepartment(row?.item?.department_id); // Corrected to row?.departmentName
        setSelectedEditDepartmentId(row?.item?.department_id);
        setEditModal(true);
        // Add your edit logic here
    };

    const handleCloseditModal = () => {
        setEditModal(false)
    }

    const handleDirectoryModal = () => {
        setDirectoryModal(true)
    }

    const handleCloseDirectoryModal = () => {
        setDirectoryModal(false)
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false)
    }

    const handleDelete = (row) => {
        setUserId(row?.item?.id)
        setDeleteModal(true)
        // Add your delete logic here
    };

    const handleActionSelect = (row, action) => {
        if (action === "Edit") {
            handleEdit(row);
        } else if (action === "Delete") {
            handleDelete(row);
        }
    };

    const tableColumns = [
        {
            name: 'Date',
            // selector: row => row['person'],
            sortable: true,
            center: false,
            cell: row => {
                console.log("🚀 ~ DataTables ~ row:", row)
                const date = moment(row?.item?.created_at).format('MM-DD-YYYY');
                const formattedDate = moment(date, 'MMM-DD-YYYY').format('MMMM Do, YYYY');
                return (
                    <div style={{ marginLeft: '5px' }}>
                        <p style={{ fontSize: '14px', lineHeight: 1 }}>{date}</p>
                    </div>
                )
            }
        },
        {
            name: 'Person/Department',
            // selector: row => row['person'],
            sortable: true,
            center: false,
            cell: row => {
                return (
                    <div style={{ marginLeft: '5px' }}>
                        <p style={{ fontSize: '14px', lineHeight: 1 }}>{row?.person}</p>
                        <p style={{ fontSize: '11px', lineHeight: 0, color: '#757575' }}>{row?.daprtmentName?.name ? row?.daprtmentName?.name : row?.daprtmentName}</p>
                    </div>
                )
            }
        },
        {
            name: 'Phone Number',
            selector: row => row['phone'],
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            cell: row => (
                <DropdownCommon
                    dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
                    options={DropdownMenu}
                    iconName='icon-more-alt'
                    btn={{ tag: 'span' }}
                    onSelect={(action) => handleActionSelect(row, action)}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        }
    ];

    const deparmentColumns = [
        {
            name: 'Department Name',
            // selector: row => row['person'],
            sortable: true,
            center: false,
            cell: row => {
                return (
                    <div>
                        <p style={{ fontSize: '14px', marginLeft: '5px' }}>{row.deptName}</p>
                    </div>
                )
            }
        },
        {
            name: 'Members',
            sortable: true,
            center: true,
            cell: row => {
                return (
                    <div style={{ width: '60px' }}>
                        <p style={{ fontSize: '14px', textAlign: 'center' }}>{row.members}</p>
                    </div>
                )
            }
        },
        {
            name: 'Action',
            cell: row => (
                <DropdownCommon
                    dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
                    options={DropdownMenu}
                    iconName='icon-more-alt'
                    btn={{ tag: 'span' }}
                    // onSelect={(action) => handleActionSelect(row, action)}
                    onSelect={(action) => console.log(row, action)}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        }
    ];

    const handleDeleteDirectory = async () => {
        if (deleteItem == "Delete" || deleteItem == "delete") {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append("company_id", getCompanyInfo?.id)
                const res = await deletePhoneDirectory(token, formData)
                toast.info("Successfully Deleted!..", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'custom-toast',
                });
                handleTableData()
                setLoading(false)
                setDeleteItem('')
                setDirectoryModal(false)
            } catch (error) {
                console.log("🚀 ~ handleDeleteDirectory ~ error:", error)
                setLoading(false)
                setDirectoryModal(false)
            }
        } else {
            setDirectoryModal(false)
        }

    }

    const handleGetDepartments = async () => {
        const formData = new FormData()
        formData.append('company_id', getCompanyInfo?.id)
        const res = await getDepartments(token, formData)
        setDeparments(res.departments)

        if (res.departments !== "Null") {
            const departmentOptions = [
                { value: 'all', label: 'All' },
                ...res.departments.map(department => ({
                    value: department.id,
                    label: department.name,
                })),
            ];
            setDeparmentsDropdown(departmentOptions)
        }

    }

    const handleGetDepartmentsWiseData = async () => {
        const formData = new FormData()
        formData.append('company_id', getCompanyInfo?.id)
        const res = await getDepartmentsWiseStaff(token, formData)
        setDeparmentsWiseData(res.departments.map((item) => {
            return {
                deptName: item.department,
                members: item.num_of_employees,
                deptID: item.department_id
            }
        }))
    }

    const handleDepartment = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('company_id', getCompanyInfo?.id)
            formData.append('name', departmentName)
            const res = await getCreateDepartment(token, formData)
            toast.info("Department Created Successfully!..", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast',
            })
            setLoading(false)
            handleGetDepartments()
            handleGetDepartmentsWiseData()
            setTimeout(() => {
                setDepartmentModal(false)
                setAddPhoneModal(true)
            }, 2000);
            setDepartmentName('')
        } catch (error) {
            console.log("🚀 ~ handleDepartment ~ error:", error)
            toast.error(error?.response?.data?.error, {
                position: 'top-left',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'custom-toast-error',
            })
            setLoading(false)
        }

    }

    const handleSelectDepartment = (e) => {
        const value = e.target.value;
        setSelectDepartment(value);
        if (value === '') {
            setDepartmentModal(true);
            setAddPhoneModal(false)
        }
    };

    const handleEditDepartment = (e) => {
        console.log("🚀 ~ handleEditDepartment ~ e:", e)
        const value = e.target.value;
        setSelectEditDepartment(value);
        setSelectedEditDepartmentId(value)
    };

    if (deleteAlert === true) {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    setDeleteAlert(false);  // Set deleteAlert to false here
                } else {
                    SweetAlert.fire(
                        'Your imaginary file is safe!'
                    );
                }
            });
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            // backgroundColor: 'rgba(89, 120, 212, 0.2)',
            backgroundColor: 'white',
            color: '#5978d4',
            border: '0.5px solid lightgrey',
            boxShadow: 'none',
            '&:hover': {
                // border: 'none',
                border: '0.5px solid lightgrey',
            },
            width: '220px'
        }),
        singleValue: (provided) => ({
            ...provided,
            // color: '#5978d4',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            // color: 'white',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            // color: '#5978d4',
            color: 'black',
            '&:hover': {
                // color: '#5978d4',
                color: 'black',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),

    };

    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const UpgradeRequiredPopup = () => (
        <UpgradePopup
            isOpen={showUpgradePopup}
            toggler={setShowUpgradePopup}
            title="Upgrade Required"
            description="You need to upgrade your package to add more phone numbers to the directory"
        />
    )

    const canAddMobile = hasFeatureAccess({ featureName: FEATURE.CALLS_TRANSFER_TO_OTHER_NUMBERS, packageType: completeCompany?.company?.activeSubscription, existingQuantity: directoryData?.length });

    return (
        <Fragment>
            {/* <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" /> */}
            <Container fluid={true}>
                {getCompanyInfo === null ?
                    <Alerting />
                    :
                    <Row style={{ paddingTop: '2%' }}>
                        {spinner ? (
                            <Row style={{ marginTop: '3%' }}>
                                <Col sm='12'>
                                    <Card>
                                        <CardBody style={{ padding: '10px' }}>
                                            <SkeletonTheme baseColor={theme == 'dark-only' ? "#202020" : ""} highlightColor={theme == 'dark-only' ? "#444" : ""}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Skeleton height={30} style={{ width: '150px' }} />
                                                    <div style={{ display: 'flex' }}>
                                                        <Skeleton height={30} style={{ width: '150px', marginRight: '10px' }} />
                                                        <Skeleton height={30} style={{ width: '150px' }} />
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '10px' }}>
                                                    <Skeleton height={20} count={22} />
                                                </div>
                                            </SkeletonTheme>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            <Col sm="12" style={{ marginTop: '3%' }}>
                                <Card>
                                    {/* <HeaderCard title={`${getCompanyInfo?.company_name} Phone Directory`}/> */}
                                    <div style={{ paddingTop: '20px', paddingRight: '20px', paddingLeft: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <H5>Phone Directory</H5>

                                        {/* <ProgressBar>
                                            {(() => {
                                                const totalMembers = deparmentsWiseData?.reduce((acc, item) => acc + item.members, 0);

                                                return deparmentsWiseData?.map((item, index) => {
                                                    const colors = ['primary', 'secondary', 'info', 'danger', 'warning', 'success', 'light'];
                                                    const color = colors[index % colors.length];

                                                    const percentage = totalMembers > 0 ? ((item.members / totalMembers) * 100).toFixed(2) : 0;

                                                    const popover = (
                                                        <Popover id={`popover-${index}`}>
                                                            <Popover.Body>
                                                                {item.deptName}: {item.members} Members
                                                            </Popover.Body>
                                                        </Popover>
                                                    );

                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement="top"
                                                            overlay={popover}
                                                        >
                                                            <ProgressBar variant={color} now={100} />
                                                        </OverlayTrigger>
                                                    );
                                                });
                                            })()}
                                        </ProgressBar> */}

                                        {/* <Progressbar attrProgress={{ multi: true }}>
                                            {(() => {
                                                const totalMembers = deparmentsWiseData?.reduce((acc, item) => acc + item.members, 0);

                                                return deparmentsWiseData?.map((item, index) => {
                                                    const colors = ['primary', 'secondary', 'info', 'danger', 'warning', 'success', 'light'];
                                                    const color = colors[index % colors.length];

                                                    const percentage = totalMembers > 0 ? (item.members / totalMembers) * 100 : 0;

                                                    const popover = (
                                                        <Popover id={`popover-${index}`}>
                                                            <Popover.Body>
                                                                Depart: {item.deptName} <br />
                                                                Members: {item.members}
                                                            </Popover.Body>
                                                        </Popover>
                                                    );

                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement="top"
                                                            overlay={popover}
                                                        >
                                                            <Progressbar
                                                                attrProgress={{
                                                                    bar: true,
                                                                    color: color,
                                                                    value: percentage,
                                                                    // title: `${item.deptName}: ${item.members > 1 ? `${item.members} Members` : `${item.members} Member` || '0'} `
                                                                }}
                                                            />
                                                        </OverlayTrigger>
                                                    );
                                                });
                                            })()}
                                        </Progressbar> */}


                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Select
                                                options={deparmentsDropdown}
                                                styles={customStyles}
                                                onChange={(e) => {
                                                    setSelectedData(e.label)
                                                    setSelectedId(e.value)
                                                }}
                                                placeholder={'Select Department...'}
                                            // defaultValue={deparmentsDropdown?.find(option => option.value === option.value)}
                                            />
                                            <Button
                                                color='primary'
                                                style={{ backgroundColor: 'red', height: '40px' }}
                                                onClick={() => {
                                                    if (!canAddMobile) {
                                                        setShowUpgradePopup(true);
                                                    } else {
                                                        setAddPhoneModal(true);
                                                    }
                                                }}
                                            // onClick={() => setDeleteAlert(true)}
                                            >
                                                Add Phone Number
                                            </Button>
                                            {tableData?.length > 0 &&
                                                <Button
                                                    style={{ backgroundColor: 'red', height: '40px' }}
                                                    onClick={handleDirectoryModal}
                                                // onClick={() => setDeleteAlert(true)}
                                                >
                                                    Delete Directory
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                    <CardBody>
                                        {tableData?.length > 0 ?
                                            <>
                                                <DataTable
                                                    data={tableData}
                                                    columns={tableColumns}
                                                    // columns={[]}
                                                    striped={true}
                                                    center={true}
                                                    pagination
                                                    paginationPerPage={50}
                                                    paginationRowsPerPageOptions={[50]}
                                                    // selectableRows
                                                    onSelectedRowsChange={handleRowSelected}
                                                    clearSelectedRows={toggleDelet}
                                                    noDataComponent={
                                                        <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                            There are no records to display
                                                        </div>
                                                    }
                                                />
                                                {deparmentsWiseData?.length > 0 &&
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginTop: 'auto' }}>
                                                        <p
                                                            style={{
                                                                textDecorationLine: 'underline',
                                                                textAlign: 'end',
                                                                fontSize: '14px',
                                                                fontWeight: '500',
                                                                cursor: 'pointer',
                                                                width: '170px',
                                                            }}
                                                            onClick={() => setDeparmentsTableModal(true)}
                                                        >
                                                            View All Departments
                                                        </p>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <NoDataFound />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        )}

                    </Row>
                }

            </Container >

            <Modal
                show={editModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleCloseditModal}
            >
                <Modal.Header closeButton>
                    {/* <H4> Edit</H4> */}
                </Modal.Header>
                <Modal.Body>
                    <Col sm="12" style={{ padding: '10px' }}>
                        <CardBody>
                            <Form onSubmit={handleEditSubmit}>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Name</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={editName}
                                            onChange={(e) => setEditName(e.target.value)}
                                            placeholder="Enter name"
                                            className={theme === 'dark-only' ? 'dark-theme-field' : ''}
                                        />
                                        {nameError && <div className="text-danger">{nameError}</div>}
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="provider">Department</Label>
                                    <Col sm="9">
                                        <Input
                                            type="select"
                                            value={selectedEditDepartmentId || selectEditDepartment}
                                            onChange={(e) => handleEditDepartment(e)}
                                            name="provider"
                                            className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                        >
                                            {!selectEditDepartment && <option value="">Select Department</option>}
                                            {Array.isArray(deparments) && deparments.length > 0 ? (
                                                deparments.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Phone</Label>
                                    <Col sm="9">
                                        <div className={`phone-input-container ${theme === 'dark-only' ? 'dark-theme-phone-input' : ''}`}>
                                            <PhoneInput
                                                international
                                                countryCallingCodeEditable={false}
                                                defaultCountry="US"
                                                value={editPhone}
                                                onChange={(e) => setEditPhone(e)}
                                                className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                countries={['US', 'CA']}
                                                limitMaxLength={12}
                                            />
                                        </div>
                                        {phoneError && <div className="text-danger">{phoneError}</div>}
                                    </Col>
                                </FormGroup>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                                        {loading ?
                                            <Button color="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                            :
                                            <Button type="submit" color="primary">Submit</Button>
                                        }
                                        <Button onClick={handleCloseditModal}>Close</Button>
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                    </Col>
                </Modal.Body>
            </Modal>


            {/* Delete Modal */}
            <Modal
                show={deleteModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleCloseDeleteModal}
            >
                <Modal.Header closeButton>
                    {/* <H4> Delete Company Directory</H4> */}
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: '500', fontSize: '16px', textAlign: 'center' }}>Are you sure you want to delete this number from directory?</p>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                            {loading ?
                                <Button color="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                                :
                                <Button style={{ width: '100px' }} type="submit" color="primary" onClick={handleDeleteSubmit}>Yes</Button>
                            }
                            <Button style={{ width: '100px' }} onClick={handleCloseDeleteModal}>Close</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={directoryModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleCloseDirectoryModal}
            >
                <Modal.Header closeButton>
                    <H4>Delete The Whole Directory</H4>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontWeight: '300', fontSize: '14px', textAlign: 'center' }}>Once you hit the delete button, you will not be able to recover this data again. Besides, any phone call data associated with these numbers will also be removed</p>
                    <p style={{ fontWeight: '300', fontSize: '14px', textAlign: 'center' }}>If you are sure to proceed, enter word 'Delete' here</p>
                    <FormGroup className="row">
                        {/* <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Password</Label> */}
                        <Col sm="12">
                            <Input
                                type="text"
                                id="name"
                                value={deleteItem}
                                onChange={(e) => setDeleteItem(e.target.value)}
                                placeholder="Type delete here"
                            />
                        </Col>
                    </FormGroup>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                            {loading ?
                                <Button color="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                                :
                                <Button style={{ width: '100px' }} type="submit" color="primary" onClick={handleDeleteDirectory}>Delete</Button>
                            }
                            <Button style={{ width: '100px', height: '43px' }} onClick={handleCloseDirectoryModal}>Close</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={departmentModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setDepartmentModal(false)}
            >
                <Modal.Header closeButton >
                    <H4> Create Department</H4>
                </Modal.Header>
                <Modal.Body>
                    <Col sm="12" style={{ padding: '10px' }}>
                        <CardBody>
                            <Form>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Department</Label>
                                    <Col sm="9">
                                        <Input
                                            type="text"
                                            id="name"
                                            value={departmentName}
                                            onChange={(e) => setDepartmentName(e.target.value)}
                                            placeholder="Enter department name"
                                        // required
                                        />
                                    </Col>
                                </FormGroup>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '60%' }}>
                                        {loading ?
                                            <Button color="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                            :
                                            <Button onClick={handleDepartment} color="primary">Submit</Button>
                                        }

                                        <Button onClick={() => {
                                            setDepartmentModal(false)
                                            setAddPhoneModal(true)
                                        }}>Close</Button>
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                    </Col>
                </Modal.Body>
            </Modal>

            <Modal
                show={deparmentsTableModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setDeparmentsTableModal(false)}
            >
                <Modal.Header closeButton >
                    <H4>Departments</H4>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginTop: '-7%' }}>
                        <DataTable
                            data={deparmentsWiseData}
                            columns={deparmentColumns}
                            striped={true}
                            center={true}
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[50]}
                            // selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleDelet}
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={addPhoneModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddPhoneModal(false)}
                className={theme === 'dark-only' ? 'dark-theme-modal' : ''}
            >
                <Modal.Header closeButton>
                    <H4>Add Phone number in the directory</H4>
                </Modal.Header>
                <Modal.Body>
                    <Col sm="12">
                        <Card className={theme === 'dark-only' ? 'dark-theme-card' : ''}>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className={`col-sm-3 col-form-label ${theme === 'dark-only' ? 'dark-theme-label' : ''}`} htmlFor="name">Name</Label>
                                        <Col sm="9">
                                            <Input
                                                type="text"
                                                id="name"
                                                value={name}
                                                onChange={handleNameChange}
                                                placeholder="Enter name"
                                                className={`form-control ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            />
                                            {nameError && <div className="text-danger">{nameError}</div>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className={`col-sm-3 col-form-label ${theme === 'dark-only' ? 'dark-theme-label' : ''}`} htmlFor="provider">Department</Label>
                                        <Col sm="9">
                                            <Input
                                                type="select"
                                                value={selectDepartment}
                                                onChange={handleSelectDepartment}
                                                name="provider"
                                                className={`form-control input-air-primary digits ${theme === 'dark-only' ? 'dark-theme-field' : ''}`}
                                            >
                                                {!selectDepartment && <option value="">Select Department</option>}
                                                {Array.isArray(deparments) && deparments.length > 0 ? (
                                                    deparments.map(option => (
                                                        <option key={option.id} value={option.id}>{option.name}</option>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                                <option value="">Create Department</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className={`col-sm-3 col-form-label ${theme === 'dark-only' ? 'dark-theme-label' : ''}`} htmlFor="phone">Phone</Label>
                                        <Col sm="9">
                                            <div className={`phone-input-container ${theme === 'dark-only' ? 'dark-theme-phone-input' : ''}`}>
                                                <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="US"
                                                    value={phone}
                                                    onChange={(e) => {
                                                        setPhone(e);
                                                        setPhoneError('');
                                                    }}
                                                    className={theme === 'dark-only' ? 'dark-theme-phone-input' : ''}
                                                    countries={['US', 'CA']}
                                                    limitMaxLength={12}
                                                />
                                            </div>
                                            {phoneError && <div className="text-danger">{phoneError}</div>}
                                        </Col>
                                    </FormGroup>
                                    {loader ?
                                        <Button color="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                        :
                                        <Button type="submit" color="primary">Submit</Button>
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Modal.Body>
            </Modal>
            <ToastContainer />

            <UpgradeRequiredPopup />
        </Fragment >
    );

};

export default DataTables;