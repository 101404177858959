import React, { Fragment, useState, forwardRef, useContext, useEffect } from 'react'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import { Breadcrumbs, Progressbar, H5, P } from '../../AbstractElements'
import { FaMinusCircle, FaChevronDown } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import StripePayment from '../Stripe/StripePayment';
import ApiContext from '../../ApiContext';
import '../ManageDirectory/styles.css'
import '../ManageAssistant/ManageAssistant.css'
import { ToastContainer, toast } from "react-toastify";
import { getPaymentHistory, getUsageSummary } from '../../api/AuthIndex';
import { formatDuration } from '../../Constant/GlobalFunctions'
import Select from 'react-select'
import { IoDocumentTextOutline } from "react-icons/io5";
import { selectCompleteCompany } from "../../Store/modules/company/companySelectors";
import { useDispatch, useSelector } from 'react-redux';
import { PACKAGE_TYPE } from "../../helpers/constants";
import { Color } from '../../helpers/colors';
import { addFundPayment, confirmPayment } from '../../api/Company';
import { useNavigate } from 'react-router';
import { formatServerDate } from "../../helpers/DateHelper";
import { isEmpty } from "../../helpers/utils";
import { ActionTypes } from '../../Store/redux-store/ActionTypes';
import paymentLink from '../../assets/images/logo/paymentLink.jpg';


const Billing = () => {
    const { companyDetail } = useContext(ApiContext);
    const navigate = useNavigate();
    let token = localStorage?.getItem('token')
    let getCompanyInfo = JSON.parse(localStorage.getItem('companyData'))
    console.log("🚀 ~ Billing ~ getCompanyInfo:", getCompanyInfo)
    const theme = localStorage.getItem("mix_background_layout")
    const [startDate, setStartDate] = useState(new Date());
    const [date, setDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [amount, setAmount] = useState()
    const [amountError, setAmountError] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [usageSummary, setUsageSummary] = useState([]);
    const [selectedData, setSelectedData] = useState('Last Week');
    const completeCompany = useSelector(selectCompleteCompany);
    console.log("🚀 ~ Billing ~ completeCompany:", completeCompany)

    const [stripeLoading, setStripeLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()

    const formatDate = (date) => {
        return moment(date).format('MMMM YYYY');
    }

    useEffect(() => {
        getPaymentsHistroy()
        getUsageHistroy('last-week')
    }, [])


    // Custom input component for DatePicker
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <input
                type="text"
                value={value}
                readOnly
                style={{
                    border: '1px solid #ced4da',
                    borderRadius: '4px',
                    padding: '8px',
                    width: '100%',
                    marginRight: '10px',
                    position: 'relative'
                }}
            />
            <FaChevronDown color="#6c757d" size={20} style={{ position: 'absolute', right: 20, backgroundColor: 'lightgray', padding: '5px', borderRadius: '5px' }} />
        </div>
    ));

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    ];

    const formatCallDate = (dateString) => {
        return moment(dateString).format('MM-DD-YYYY');
    };

    const tableColumns = [
        {
            name: 'Date',
            sortable: true,
            center: false,  // Aligns to the left
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '10px', }}>{formatCallDate(row.createdAt)}</p>
                    </>
                );
            },
        },
        {
            name: 'Invoice No.',
            sortable: true,
            center: false,  // Aligns to the left
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '20px', }}>{row.invoiceNo}</p>
                    </>
                );
            },
        },
        {
            name: 'Amount Charged',
            sortable: true,
            center: false,
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '20px', }}>$ {`${Number(row.amount).toFixed(2)}`}</p>
                    </>
                );
            },
        },
        {
            name: 'Status',
            sortable: true,
            center: true,  // Aligns to the right
            flex: 1,
            // width: '100px',
            cell: (row) => {
                return (
                    <>
                        <p >{row.status}</p>
                    </>
                );
            },
        },
        {
            name: '',
            sortable: true,
            center: true,  // Aligns to the right
            flex: 1,
            // width: '100px',
            cell: (row) => {
                return (
                    <>
                        {row.invoice_link !== null &&
                            <IoDocumentTextOutline
                                size={25}
                                style={{ cursor: 'pointer', marginLeft: '20px' }}
                                onClick={() => window.open(`${row?.invoice_link}`, '_blank')}
                            />
                        }
                    </>
                );
            },
        },
    ];

    const usageColumns = [
        {
            name: 'Date',
            sortable: true,
            center: false,  // Aligns to the left
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '10px' }}>{formatCallDate(row.date)}</p>
                    </>
                );
            },
        },
        {
            name: 'Total Calls',
            sortable: true,
            center: false,  // Aligns to the left
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '40px' }}>{row.totalCalls}</p>
                    </>
                );
            },
        },
        {
            name: 'Duration',
            sortable: true,
            center: false,  // Aligns to the left
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '20px' }}>{row?.duration ? `${formatDuration(row?.duration)}s` : row?.duration}</p>
                    </>
                );
            },
        },
        {
            name: 'Total Cost',
            sortable: true,
            center: false,  // Aligns to the left
            flex: 1,
            // width: '85%',
            cell: (row) => {
                return (
                    <>
                        <p style={{ marginLeft: '20px' }}>$ {Number(row.totalCost).toFixed(2)}</p>
                    </>
                );
            },
        },

    ];

    const [stripeModal, setStripeModal] = useState(false)

    const handleCloseStripeModal = () => {
        setStripeModal(false)
    }

    const handlePaymentModal = () => {
        setAmountError(''); // Reset the error message

        if (!amount) {
            setAmountError("Please enter an amount.");
            return;
        }

        if (amount < 10 || amount > 1000) {
            setAmountError("The amount must be between $10 - $1000.");
            return;
        }

        setStripeModal(true);
    };

    const getPaymentsHistroy = async () => {
        try {
            const formData = new FormData()
            formData.append('company_id', getCompanyInfo?.id)
            formData.append('startDate', date)
            formData.append('endDate', endDate)
            const res = await getPaymentHistory(token, formData)
            if (res?.payment_histry) {
                const formattedHistory = res?.payment_histry?.map(item => ({
                    amount: item.amount,
                    createdAt: item.created_at,
                    description: item.description,
                    invoiceNo: item.invoice_no,
                    packageName: item.pasckage_name || 'N/A',
                    invoice_link: item.invoice_link,
                    status: item.status,
                }));

                setPaymentHistory(formattedHistory);
            }
        } catch (error) {
            console.log("🚀 ~ getPaymentsHistroy ~ error:", error)
        }
    }

    const getUsageHistroy = async (selectedValue) => {
        try {
            const currentDate = moment().format('YYYY-MM-DD');
            let startDate;

            switch (selectedValue) {
                case 'today':
                    startDate = currentDate;
                    break;
                case 'last-week':
                    startDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
                    break;
                case 'last-month':
                    startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
                    break;
                case 'last-3-months':
                    startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
                    break;
                case 'last-year':
                    startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
                    break;
                default:
                    return;
            }

            const formData = new FormData()
            formData.append('company_id', getCompanyInfo.id)
            formData.append('start_date', startDate)
            formData.append('end_date', currentDate)
            const res = await getUsageSummary(token, formData)
            setUsageSummary(res.calls.map((item) => {
                return {
                    date: item.call_date,
                    totalCalls: item.calls,
                    duration: item.total_duration,
                    totalCost: item.total_cost,
                }
            }))

        } catch (error) {
            console.log("🚀 ~ getUsageHistroy ~ error:", error)
        }
    }

    const select = [
        { value: 'today', label: 'Today' },
        { value: 'last-week', label: 'Last Week' },
        { value: 'last-month', label: 'Last Month' },
        { value: 'last-3-months', label: 'Last 3 Months' },
        { value: 'last-year', label: 'Last Year' },
    ]

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(89, 120, 212, 0.2)',
            color: '#5978d4',
            border: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
            },
            minWidth: '120px'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#5978d4',
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && '#5978d4',
            // color: 'white',
            '&:hover': {
                backgroundColor: 'rgba(89, 120, 212, 0.2)',
            },
            fontSize: '11px'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#5978d4',
            '&:hover': {
                color: '#5978d4',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),

    };

    let packText = 'Pay as you go';
    const currentPackage = completeCompany?.company?.activeSubscription;
    const packageSubscribeDate = formatServerDate(completeCompany?.company?.company?.latest_package_payment?.subscribed_at);
    if (currentPackage === PACKAGE_TYPE.STARTUP) {
        packText = 'Startup';
    } else if (currentPackage === PACKAGE_TYPE.PRO) {
        packText = 'Pro';
    }

    const handleConfirmAddFunds = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('company_id', getCompanyInfo?.id)
            formData.append('amount', amount)
            const res = await addFundPayment(formData)
            console.log("🚀 ~ handleConfirmAddFunds ~ res:", res)


            if (res?.status === "success") {
                let formData = {
                    company_id: getCompanyInfo.id,
                    amount: amount,
                    transaction_id: res?.paymentIntent?.id,
                };
                const response = await confirmPayment(formData);
                if (response) {
                    setLoading(false)
                    navigate(`${process.env.PUBLIC_URL}/app/thankyou`);
                }
            } else if (res?.paymentIntent?.status === "requires_action") {
                alert(
                    "Additional authentication is required. Please follow the instructions."
                );

            } else {
            }
            // if (res.status === "success") {
            //     navigate(`${process.env.PUBLIC_URL}/app/thankyou`);
            // }
        } catch (error) {
            console.log("🚀 ~ handleConfirmAddFunds ~ error:", error)
            setLoading(false)
        }
    }

    const getStripeSession = async () => {
        setStripeLoading(true);
        try {
            // Dispatch action or API call
            await dispatch({ type: ActionTypes.GET_STRIPE_SESSION });
        } catch (error) {
            console.error("Error in getStripeSession:", error);
        } finally {
            setStripeLoading(false);
        }
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Billing" />
            <Container fluid={true}>
                <Row>
                    <Col sm='6'>
                        <Col sm='12'>
                            <Card style={{ padding: '0px' }}>
                                <CardBody style={{ padding: '20px' }}>
                                    <Col sm='12'>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <h5 style={{ marginBottom: '20px' }}>{currentPackage !== PACKAGE_TYPE.PAY_AS_YOU_GO ? 'Call Count' : 'Billing Information'}</h5>
                                            <div>
                                                {currentPackage === PACKAGE_TYPE.PAY_AS_YOU_GO && (
                                                    <Progressbar attrProgress={{ color: completeCompany?.company?.company?.latest_package_payment?.min_balance < completeCompany?.company?.company?.latest_package_payment?.current_balance ? 'primary' : 'secondary', value: completeCompany?.company?.company?.latest_package_payment?.current_balance, width: '300px' }} />
                                                )}
                                                {currentPackage !== PACKAGE_TYPE.PAY_AS_YOU_GO && (
                                                    <Progressbar attrProgress={{ color: completeCompany?.company?.company?.latest_package_payment?.min_balance < completeCompany?.company?.company?.latest_package_payment?.current_balance ? 'primary' : 'secondary', value: 10, width: '300px' }} />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Row>
                                        <Col sm='6'>
                                            {currentPackage !== PACKAGE_TYPE.PAY_AS_YOU_GO && (
                                                <div>
                                                    <p style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform: 'uppercase',
                                                        lineHeight: 0.5
                                                    }}>Current Package</p>
                                                    <p style={{
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color: 'green',
                                                        lineHeight: 0.5
                                                    }}>
                                                        {packText}
                                                    </p>
                                                </div>
                                            )}
                                            {currentPackage === PACKAGE_TYPE.PAY_AS_YOU_GO && (<div>
                                                <p style={{
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    textTransform: 'uppercase',
                                                    lineHeight: 0.5
                                                }}>Current Balance</p>
                                                <p style={{
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    color: 'green',
                                                    lineHeight: 0.5
                                                }}>{completeCompany?.company?.company?.latest_package_payment?.current_balance ? `$${Number(completeCompany?.company?.company?.latest_package_payment?.current_balance)?.toFixed(2)}` : '$0.00'}</p>
                                                <div style={{ position: 'relative', marginTop: '10px', width: '80%' }}>
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            left: '10px',
                                                            top: '51%',
                                                            transform: 'translateY(-50%)',
                                                            color: '#555',
                                                        }}
                                                    >
                                                        $
                                                    </span>
                                                    <Input
                                                        type="number"
                                                        placeholder="Enter amount"
                                                        style={{
                                                            paddingLeft: '25px', // Add padding to make space for the icon
                                                            width: '100%',
                                                        }}
                                                        onChange={(e) => {
                                                            setAmount(e.target.value);
                                                            if (e.target.value) {
                                                                setAmountError(false);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {amountError && (
                                                    <p style={{
                                                        color: 'red',
                                                        fontSize: '12px',
                                                        marginTop: '5px'
                                                    }}>{amountError}</p>
                                                )}
                                                <Button color='primary' style={{
                                                    marginLeft: '-2px',
                                                    marginTop: amountError ? '-10px' : '32px'
                                                }} onClick={handlePaymentModal}>Add Funds</Button>
                                            </div>)}

                                        </Col>
                                        <Col sm='6'>
                                            {currentPackage !== PACKAGE_TYPE.PAY_AS_YOU_GO && !isEmpty(packageSubscribeDate) && (
                                                <>
                                                    <p style={{
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform: 'uppercase',
                                                        lineHeight: 0.5
                                                    }}>Subscription Start
                                                    </p>
                                                    <p style={{
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color: '#8c8c8c',
                                                        lineHeight: 0.5
                                                    }}>
                                                        {packageSubscribeDate}
                                                    </p>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col sm='12'>
                            <Card style={{ padding: '0px', }}>
                                <CardBody style={{ padding: '20px', height: paymentHistory?.length > 0 ? '500px' : '350px' }}>
                                    <Col sm='12'>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <h5 style={{ marginBottom: '20px' }}>Payment History</h5>
                                        </div>
                                        <DataTable
                                            className='reportsDT'
                                            data={paymentHistory || []}
                                            columns={tableColumns}
                                            striped={true}
                                            center={true}
                                            pagination
                                            paginationPerPage={20}
                                            noDataComponent={
                                                <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                    There are no records to display
                                                </div>
                                            }
                                        />
                                    </Col>
                                </CardBody>

                            </Card>
                        </Col> */}
                        <Col sm="12">
                            <Card style={{ padding: '0px' }}>
                                <CardBody
                                    style={{
                                        padding: '0px 20px 20px 20px',
                                        // height: paymentHistory?.length > 0 ? '530px' : '350px',
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img style={{ width: '60%', height: 'auto', boxShadow: '0 0 -5px rgba(0, 0, 0, 0.3)' }} src={paymentLink} />
                                    </div>
                                    <p style={{ textAlign: 'center', paddingBottom:'0px', marginBottom:'0px' }}>All your company payments are managed through stripe. Click on the link below to open payment portal on stripe.</p>
                                    <p style={{ textDecorationLine: 'underline', color: Color.PRIMARY, cursor: 'pointer', textAlign: 'center' }}
                                        onClick={() => stripeLoading ? "" : getStripeSession()}
                                    >Payment Portal</p>
                                    {/* <Col sm="12">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <h5 style={{ marginBottom: '20px' }}>Payment History</h5>
                                        </div>

                                        <div
                                            style={{
                                                maxHeight: '400px',
                                                overflowY: 'auto',
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none'
                                            }}
                                        >
                                            <DataTable
                                                className="reportsDT"
                                                data={paymentHistory || []}
                                                columns={tableColumns}
                                                striped={true}
                                                center={true}
                                                pagination
                                                paginationPerPage={20}
                                                noDataComponent={
                                                    <div
                                                        style={{
                                                            padding: '10px',
                                                            color: theme === 'dark-only' ? '#fff' : '#000',
                                                            backgroundColor: theme === 'dark-only' ? '#262932' : '#fff',
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: 'none',
                                                        }}
                                                    >
                                                        There are no records to display
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </Col> */}
                                </CardBody>
                            </Card>
                        </Col>

                    </Col>
                    <Col sm='6'>
                        <Col sm='12'>
                            <Card style={{ padding: '0px' }}>
                                <CardBody style={{ padding: '20px', minHeight: '300px' }}>
                                    <Col sm='12'>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <h5>Usage Summary</h5>
                                            <Select
                                                options={select}
                                                styles={customStyles}
                                                onChange={(e) => {
                                                    setSelectedData(e.label);
                                                    getUsageHistroy(e.value)
                                                    // getCompanyStats(e.value); // Call the function with the selected value
                                                }}
                                                defaultValue={select.find(option => option.value === 'last-week')}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                maxHeight: '800px',
                                                overflowY: 'auto',
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none'
                                            }}
                                        >
                                            <DataTable
                                                className='reportsDT'
                                                data={usageSummary || []}
                                                columns={usageColumns}
                                                striped={true}
                                                center={true}
                                                pagination
                                                paginationPerPage={10}
                                                noDataComponent={
                                                    <div style={{ padding: '10px', color: theme === 'dark-only' ? '#fff' : '#000', backgroundColor: theme === 'dark-only' ? '#262932' : '#fff', textAlign: 'center', width: '100%', border: 'none' }}>
                                                        There are no records to display
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>
                    </Col>
                </Row>
                <Modal show={stripeModal} centered onHide={handleCloseStripeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <P attrPara={{ style: { textAlign: 'center', fontSize: '16px' } }}>Are you sure you want to add <span style={{ color: Color.PRIMARY, fontWeight: '500' }}>${amount}</span> funds to your account?</P>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={loading} color='primary' style={{ height: '40px' }} onClick={handleConfirmAddFunds}>
                            {loading ? 'Confirming' : 'Confirm'}
                        </Button>
                        <Button color='secondary' style={{ height: '40px' }} onClick={handleCloseStripeModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* <Modal show={stripeModal} centered onHide={handleCloseStripeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <StripePayment price={amount} companyId={getCompanyInfo?.id} />
                    </Modal.Body>
                </Modal> */}
                <ToastContainer />
            </Container>
        </Fragment >
    )
}

export default Billing
