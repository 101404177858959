import React, { useState } from 'react'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, } from 'reactstrap';
import { H4, H6, Image, H5, LI, UL } from '../../AbstractElements';
import { deleteBot, deletePhoneNumber, editPhoneNumber, editUpdateBot, generateBotPersona, getAllCompanyData, getAllPersonas, getCreateBot, getViewCompanyPhoneNumber } from '../../api/AuthIndex';
import { Form, Modal, Spinner, Offcanvas, OffcanvasHeader, OffcanvasBody, OffcanvasTitle } from 'react-bootstrap';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import './styles.css'
import FileContent from './FileContent';
import { Breadcrumbs } from '../../AbstractElements';
import UpgradePopup from '../../Components/UpgradePopup/UpgradePopup';

const KnowledgeBase = () => {
    const [forLoader, setForLoader] = useState(true)

    return (
        <>
            <Breadcrumbs parent="Forms" mainTitle="Knowlegde Base" subParent="Company" title="Knowlegde Base" />
            {!forLoader ? (
                <Row style={{ paddingTop: '2%' }}>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <div style={{ height: '60vh', justifyContent: 'center', alignContent: 'center', display: 'flex' }}>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ alignSelf: 'center' }}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col md="12">
                        <div className='file-content'>
                            {/* <Card style={{ backgroundColor: 'transparent' }}> */}
                            <FileContent />
                            {/* </Card> */}
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default KnowledgeBase