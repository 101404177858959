import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Form, FormGroup, Label, Button, Card, CardBody, CardTitle, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {ActionTypes} from "../../Store/redux-store/ActionTypes";
import {
    selectCardInfo, selectClientSecret, selectLoadingPaymentMethod,
    selectPaymentMethodData,
    selectUpdatePaymentMethodData, selectUpdatingPaymentMethod
} from "../../Store/modules/stripe/paymentSelectors";
import {showToast} from "../../helpers/ToastHelper";
import {TOAST_TYPES} from "../../helpers/constants";

const stripePromise = loadStripe('pk_test_51Q5LPmFvMLQywX8LkHhigcl4fNU6pbTp5hwNsHqjWkWid9j9QHUg4rztSSTFzk6K7HGgrgNwsvT2OJKwG3EMldoD00OE5KjCTb');

const PaymentForm = () => {
    const [alertMessage, setAlertMessage] = useState(null);
    const [isStripeConfirming, setIsStripeConfirming] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const paymentMethodData = useSelector(selectPaymentMethodData);
    const cardInfo = useSelector(selectCardInfo);
    const clientSecret = useSelector(selectClientSecret);
    const updatePaymentMethodData = useSelector(selectUpdatePaymentMethodData);
    const isUpdatingPaymentMethod = useSelector(selectUpdatingPaymentMethod);
    const isLoadingPaymentMethod = useSelector(selectLoadingPaymentMethod);
    useEffect(() => {
        const fetchPaymentInfo = async () => {
            dispatch({ type: ActionTypes.GET_USER_PAYMENT_METHOD });
        };
        fetchPaymentInfo();
    }, []);

    const handleDeAttach = async () => {
        await dispatch({ type: ActionTypes.UPDATE_USER_PAYMENT_METHOD,  payload: { deAttach: 'Y' }});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);
        setIsStripeConfirming(true);
        if (clientSecret) {
            const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: 'User Name', // Adjust to dynamically pull the user's name
                    },
                },
            });
            setIsStripeConfirming(false);

            if (error) {
                console.error('Error setting up card:', error.message);
                setAlertMessage(error.message);
            } else {
                dispatch({ type: ActionTypes.UPDATE_USER_PAYMENT_METHOD,  payload: { paymentMethodId: setupIntent.payment_method }});
            }
        } else {
            showToast({type: TOAST_TYPES.DANGER, message: 'Something went wrong when tried to retrieve client secret from stripe.'});
            setIsStripeConfirming(false);
        }
    };

    return (
        <Card className="p-4">
            <CardBody>
                <CardTitle tag="h5">Manage Your Payment Method</CardTitle>
                {alertMessage && <Alert color="info">{alertMessage}</Alert>}

                {cardInfo ? (
                    <div>
                        <p><strong>Card:</strong> {cardInfo.brand} ending in {cardInfo.last4}</p>
                        <p><strong>Expires:</strong> {cardInfo.exp_month}/{cardInfo.exp_year}</p>
                        {isUpdatingPaymentMethod || isLoadingPaymentMethod || isStripeConfirming ? (<p>Loading...</p>) : (
                            <Button color="primary" onClick={() => {
                                handleDeAttach();
                            }}>Replace</Button>
                        )}
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="cardDetails">Card Details</Label>
                            {!stripe || !elements ? (<p>Loading</p>) : (
                                <CardElement id="cardDetails" />
                            )}
                        </FormGroup>
                        {isUpdatingPaymentMethod || isLoadingPaymentMethod || isStripeConfirming ? (<p>Loading...</p>) : (
                            <Button color="primary" type="submit" disabled={!stripe}>Save Card</Button>
                        )}
                    </Form>
                )}

                {/*{updating && (*/}
                {/*    <Form onSubmit={handleSubmit} className="mt-4">*/}
                {/*        <FormGroup>*/}
                {/*            <Label for="cardUpdateDetails">Update Card Details</Label>*/}
                {/*            <CardElement id="cardUpdateDetails" />*/}
                {/*        </FormGroup>*/}
                {/*        <Button color="success" type="submit" disabled={!stripe}>Update Card</Button>*/}
                {/*    </Form>*/}
                {/*)}*/}
            </CardBody>
        </Card>
    );
};

export const UpdateCard = () => (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
);
